const Cid10 = () => {

    const cids = [
        {
            cid10: "A000",
            descricao: "Cólera devida a Vibrio cholerae 01, biótipo cholerae",
            favorito: false
        },
        {
            cid10: "A001",
            descricao: "Cólera devida a Vibrio cholerae 01, biótipo El Tor",
            favorito: false
        },
        {
            cid10: "A009",
            descricao: "Cólera não especificada",
            favorito: false
        },
        {
            cid10: "A010",
            descricao: "Febre tifóide",
            favorito: false
        },
        {
            cid10: "A011",
            descricao: "Febre paratifóide A",
            favorito: false
        },
        {
            cid10: "A012",
            descricao: "Febre paratifóide B",
            favorito: false
        },
        {
            cid10: "A013",
            descricao: "Febre paratifóide C",
            favorito: false
        },
        {
            cid10: "A014",
            descricao: "Febre paratifóide não especificada",
            favorito: false
        },
        {
            cid10: "A020",
            descricao: "Enterite por salmonela",
            favorito: false
        },
        {
            cid10: "A021",
            descricao: "Septicemia por salmonela",
            favorito: false
        },
        {
            cid10: "A022",
            descricao: "Infecções localizadas por salmonela",
            favorito: false
        },
        {
            cid10: "A028",
            descricao: "Outras infecções especificadas por salmonela",
            favorito: false
        },
        {
            cid10: "A029",
            descricao: "Infecção não especificada por salmonela",
            favorito: false
        },
        {
            cid10: "A030",
            descricao: "Shiguelose devida a Shigella dysenteriae",
            favorito: false
        },
        {
            cid10: "A031",
            descricao: "Shiguelose devida a Shigella flexneri",
            favorito: false
        },
        {
            cid10: "A032",
            descricao: "Shiguelose devida a Shigella boydii",
            favorito: false
        },
        {
            cid10: "A033",
            descricao: "Shiguelose devida a Shigella sonnei",
            favorito: false
        },
        {
            cid10: "A038",
            descricao: "Outras shigueloses",
            favorito: false
        },
        {
            cid10: "A039",
            descricao: "Shiguelose não especificada",
            favorito: false
        },
        {
            cid10: "A040",
            descricao: "Infecção por Escherichia coli enteropatogênica",
            favorito: false
        },
        {
            cid10: "A041",
            descricao: "Infecção por Escherichia coli enterotoxigênica",
            favorito: false
        },
        {
            cid10: "A042",
            descricao: "Infecção por Escherichia coli enteroinvasiva",
            favorito: false
        },
        {
            cid10: "A043",
            descricao: "Infecção por Escherichia coli enterohemorrágica",
            favorito: false
        },
        {
            cid10: "A044",
            descricao: "Outras infecções intestinais por Escherichia coli",
            favorito: false
        },
        {
            cid10: "A045",
            descricao: "Enterite por Campylobacter",
            favorito: false
        },
        {
            cid10: "A046",
            descricao: "Enterite devida a Yersinia enterocolítica",
            favorito: false
        },
        {
            cid10: "A047",
            descricao: "Enterocolite devida a Clostridium difficile",
            favorito: false
        },
        {
            cid10: "A048",
            descricao: "Outras infecções bacterianas intestinais especificadas",
            favorito: false
        },
        {
            cid10: "A049",
            descricao: "Infecção intestinal bacteriana não especificada",
            favorito: false
        },
        {
            cid10: "A050",
            descricao: "Intoxicação alimentar estafilocócica",
            favorito: false
        },
        {
            cid10: "A051",
            descricao: "Botulismo",
            favorito: false
        },
        {
            cid10: "A052",
            descricao: "Intoxicação alimentar devida a Clostridium perfringens [Clostridium welchii]",
            favorito: false
        },
        {
            cid10: "A053",
            descricao: "Intoxicação alimentar devida a Vibrio parahemolyticus",
            favorito: false
        },
        {
            cid10: "A054",
            descricao: "Intoxicação alimentar devida a Bacillus cereus",
            favorito: false
        },
        {
            cid10: "A058",
            descricao: "Outras intoxicações alimentares bacterianas especificadas",
            favorito: false
        },
        {
            cid10: "A059",
            descricao: "Intoxicação alimentar bacteriana não especificada",
            favorito: false
        },
        {
            cid10: "A060",
            descricao: "Disenteria amebiana aguda",
            favorito: false
        },
        {
            cid10: "A061",
            descricao: "Amebíase intestinal crônica",
            favorito: false
        },
        {
            cid10: "A062",
            descricao: "Colite amebiana não-disentérica",
            favorito: false
        },
        {
            cid10: "A063",
            descricao: "Ameboma intestinal",
            favorito: false
        },
        {
            cid10: "A064",
            descricao: "Abscesso amebiano do fígado",
            favorito: false
        },
        {
            cid10: "A065",
            descricao: "Abscesso amebiano do pulmão",
            favorito: false
        },
        {
            cid10: "A066",
            descricao: "Abscesso amebiano do cérebro",
            favorito: false
        },
        {
            cid10: "A067",
            descricao: "Amebíase cutânea",
            favorito: false
        },
        {
            cid10: "A068",
            descricao: "Infecção amebiana de outras localizações",
            favorito: false
        },
        {
            cid10: "A069",
            descricao: "Amebíase não especificada",
            favorito: false
        },
        {
            cid10: "A070",
            descricao: "Balantidíase",
            favorito: false
        },
        {
            cid10: "A071",
            descricao: "Giardíase [lamblíase]",
            favorito: false
        },
        {
            cid10: "A072",
            descricao: "Criptosporidiose",
            favorito: false
        },
        {
            cid10: "A073",
            descricao: "Isosporíase",
            favorito: false
        },
        {
            cid10: "A078",
            descricao: "Outras doenças intestinais especificadas por protozoários",
            favorito: false
        },
        {
            cid10: "A079",
            descricao: "Doença intestinal não especificada por protozoários",
            favorito: false
        },
        {
            cid10: "A080",
            descricao: "Enterite por rotavírus",
            favorito: false
        },
        {
            cid10: "A081",
            descricao: "Gastroenteropatia aguda pelo agente de Norwalk",
            favorito: false
        },
        {
            cid10: "A082",
            descricao: "Enterite por adenovírus",
            favorito: false
        },
        {
            cid10: "A083",
            descricao: "Outras enterites virais",
            favorito: false
        },
        {
            cid10: "A084",
            descricao: "Infecção intestinal devida a vírus não especificado",
            favorito: false
        },
        {
            cid10: "A085",
            descricao: "Outras infecções intestinais especificadas",
            favorito: false
        },
        {
            cid10: "A09",
            descricao: "Diarréia e gastroenterite de origem infecciosa presumível",
            favorito: false
        },
        {
            cid10: "A150",
            descricao: "Tuberculose pulmonar, com confirmação por exame microscópico da expectoração, com ou sem cultura",
            favorito: false
        },
        {
            cid10: "A151",
            descricao: "Tuberculose pulmonar, com confirmação somente por cultura",
            favorito: false
        },
        {
            cid10: "A152",
            descricao: "Tuberculose pulmonar, com confirmação histológica",
            favorito: false
        },
        {
            cid10: "A153",
            descricao: "Tuberculose pulmonar, com confirmação por meio não especificado",
            favorito: false
        },
        {
            cid10: "A154",
            descricao: "Tuberculose dos gânglios intratorácicos, com confirmação bacteriológica e histológica",
            favorito: false
        },
        {
            cid10: "A155",
            descricao: "Tuberculose da laringe, da traquéia e dos brônquios, com confirmação bacteriológica e histológica",
            favorito: false
        },
        {
            cid10: "A156",
            descricao: "Pleuris tuberculoso, com confirmação bacteriológica e histológica",
            favorito: false
        },
        {
            cid10: "A157",
            descricao: "Tuberculose primária das vias respiratórias, com confirmação bacteriológica e histológica",
            favorito: false
        },
        {
            cid10: "A158",
            descricao: "Outras formas de tuberculose das vias respiratórias, com confirmação bacteriológica e histológica",
            favorito: false
        },
        {
            cid10: "A159",
            descricao: "Tuberculose não especificada das vias respiratórias, com confirmação bacteriológica e histológica",
            favorito: false
        },
        {
            cid10: "A160",
            descricao: "Tuberculose pulmonar com exames bacteriológico e histológico negativos",
            favorito: false
        },
        {
            cid10: "A161",
            descricao: "Tuberculose pulmonar, sem realização de exame bacteriológico ou histológico",
            favorito: false
        },
        {
            cid10: "A162",
            descricao: "Tuberculose pulmonar, sem menção de confirmação bacteriológica ou histológica",
            favorito: false
        },
        {
            cid10: "A163",
            descricao: "Tuberculose dos gânglios intratorácicos, sem menção de confirmação bacteriológica ou histológica",
            favorito: false
        },
        {
            cid10: "A164",
            descricao: "Tuberculose da laringe, da traquéia e dos brônquios, sem menção de confirmação bacteriológica ou histológica",
            favorito: false
        },
        {
            cid10: "A165",
            descricao: "Pleurisia tuberculosa, sem menção de confirmação bacteriológica ou histológica",
            favorito: false
        },
        {
            cid10: "A167",
            descricao: "Tuberculosa respiratória primária sem menção de confirmação bacteriológica ou histológica",
            favorito: false
        },
        {
            cid10: "A168",
            descricao: "Outras formas de tuberculose das vias respiratórias, sem menção de confirmação bacteriológica ou histológica",
            favorito: false
        },
        {
            cid10: "A169",
            descricao: "Tuberculose respiratória, não especificada, sem menção de confirmação bacteriológica ou histológica",
            favorito: false
        },
        {
            cid10: "A170",
            descricao: "Meningite tuberculosa",
            favorito: false
        },
        {
            cid10: "A171",
            descricao: "Tuberculoma meníngeo",
            favorito: false
        },
        {
            cid10: "A178",
            descricao: "Outras tuberculoses do sistema nervoso",
            favorito: false
        },
        {
            cid10: "A179",
            descricao: "Tuberculose não especificada do sistema nervoso",
            favorito: false
        },
        {
            cid10: "A180",
            descricao: "Tuberculose óssea e das articulações",
            favorito: false
        },
        {
            cid10: "A181",
            descricao: "Tuberculose do aparelho geniturinário",
            favorito: false
        },
        {
            cid10: "A182",
            descricao: "Linfadenopatia tuberculosa periférica",
            favorito: false
        },
        {
            cid10: "A183",
            descricao: "Tuberculose do intestino, do peritônio e dos gânglios mesentéricos",
            favorito: false
        },
        {
            cid10: "A184",
            descricao: "Tuberculose de pele e do tecido celular subcutâneo",
            favorito: false
        },
        {
            cid10: "A185",
            descricao: "Tuberculose do olho",
            favorito: false
        },
        {
            cid10: "A186",
            descricao: "Tuberculose do ouvido",
            favorito: false
        },
        {
            cid10: "A187",
            descricao: "Tuberculose das supra-renais",
            favorito: false
        },
        {
            cid10: "A188",
            descricao: "Tuberculose de outros órgãos especificados",
            favorito: false
        },
        {
            cid10: "A190",
            descricao: "Tuberculose miliar aguda de localização única e especificada",
            favorito: false
        },
        {
            cid10: "A191",
            descricao: "Tuberculose miliar aguda de múltiplas localizações",
            favorito: false
        },
        {
            cid10: "A192",
            descricao: "Tuberculose miliar aguda não especificada",
            favorito: false
        },
        {
            cid10: "A198",
            descricao: "Outras tuberculoses miliares",
            favorito: false
        },
        {
            cid10: "A199",
            descricao: "Tuberculose miliar não especificada",
            favorito: false
        },
        {
            cid10: "A200",
            descricao: "Peste bubônica",
            favorito: false
        },
        {
            cid10: "A201",
            descricao: "Peste celulocutânea",
            favorito: false
        },
        {
            cid10: "A202",
            descricao: "Peste pneumônica",
            favorito: false
        },
        {
            cid10: "A203",
            descricao: "Peste meníngea",
            favorito: false
        },
        {
            cid10: "A207",
            descricao: "Peste septicêmica",
            favorito: false
        },
        {
            cid10: "A208",
            descricao: "Outras formas de peste",
            favorito: false
        },
        {
            cid10: "A209",
            descricao: "Peste, forma não especificada",
            favorito: false
        },
        {
            cid10: "A210",
            descricao: "Tularemia ulceroglandular",
            favorito: false
        },
        {
            cid10: "A211",
            descricao: "Tularemia oculoglandular",
            favorito: false
        },
        {
            cid10: "A212",
            descricao: "Tularemia pulmonar",
            favorito: false
        },
        {
            cid10: "A213",
            descricao: "Tularemia gastrointestinal",
            favorito: false
        },
        {
            cid10: "A217",
            descricao: "Tularemia generalizada",
            favorito: false
        },
        {
            cid10: "A218",
            descricao: "Outras formas de tularemia",
            favorito: false
        },
        {
            cid10: "A219",
            descricao: "Tularemia, forma não especificada",
            favorito: false
        },
        {
            cid10: "A220",
            descricao: "Carbúnculo cutâneo",
            favorito: false
        },
        {
            cid10: "A221",
            descricao: "Carbúnculo pulmonar",
            favorito: false
        },
        {
            cid10: "A222",
            descricao: "Carbúnculo gastrointestinal",
            favorito: false
        },
        {
            cid10: "A227",
            descricao: "Septicemia carbunculosa",
            favorito: false
        },
        {
            cid10: "A228",
            descricao: "Outras formas de carbúnculo",
            favorito: false
        },
        {
            cid10: "A229",
            descricao: "Carbúnculo, forma não especificada",
            favorito: false
        },
        {
            cid10: "A230",
            descricao: "Brucelose por Brucella melitensis",
            favorito: false
        },
        {
            cid10: "A231",
            descricao: "Brucelose por Brucella abortus",
            favorito: false
        },
        {
            cid10: "A232",
            descricao: "Brucelose por Brucella suis",
            favorito: false
        },
        {
            cid10: "A233",
            descricao: "Brucelose por Brucella canis",
            favorito: false
        },
        {
            cid10: "A238",
            descricao: "Outras bruceloses",
            favorito: false
        },
        {
            cid10: "A239",
            descricao: "Brucelose não especificada",
            favorito: false
        },
        {
            cid10: "A240",
            descricao: "Mormo",
            favorito: false
        },
        {
            cid10: "A241",
            descricao: "Melioidose aguda e fulminante",
            favorito: false
        },
        {
            cid10: "A242",
            descricao: "Melioidose subaguda e crônica",
            favorito: false
        },
        {
            cid10: "A243",
            descricao: "Outras melioidoses",
            favorito: false
        },
        {
            cid10: "A244",
            descricao: "Melioidose não especificada",
            favorito: false
        },
        {
            cid10: "A250",
            descricao: "Espirilose",
            favorito: false
        },
        {
            cid10: "A251",
            descricao: "Estreptobacilose",
            favorito: false
        },
        {
            cid10: "A259",
            descricao: "Febre transmitida por mordedura de rato, tipo não especificado",
            favorito: false
        },
        {
            cid10: "A260",
            descricao: "Erisipelóide cutâneo",
            favorito: false
        },
        {
            cid10: "A267",
            descricao: "Septicemia por Erysipelothrix",
            favorito: false
        },
        {
            cid10: "A268",
            descricao: "Outras formas de erisipelóide",
            favorito: false
        },
        {
            cid10: "A269",
            descricao: "Erisipelóide não especificado",
            favorito: false
        },
        {
            cid10: "A270",
            descricao: "Leptopirose icterohemorrágica",
            favorito: false
        },
        {
            cid10: "A278",
            descricao: "Outras formas de leptospirose",
            favorito: false
        },
        {
            cid10: "A279",
            descricao: "Leptospirose não especificada",
            favorito: false
        },
        {
            cid10: "A280",
            descricao: "Pasteurelose",
            favorito: false
        },
        {
            cid10: "A281",
            descricao: "Doença por arranhadura do gato",
            favorito: false
        },
        {
            cid10: "A282",
            descricao: "Yersiniose extra-intestinal",
            favorito: false
        },
        {
            cid10: "A288",
            descricao: "Outras doenças bacterianas zoonóticas especificadas não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "A289",
            descricao: "Doença bacteriana zoonótica não especificada",
            favorito: false
        },
        {
            cid10: "A300",
            descricao: "Hanseníase [lepra] indeterminada",
            favorito: false
        },
        {
            cid10: "A301",
            descricao: "Hanseníase [lepra] tuberculóide",
            favorito: false
        },
        {
            cid10: "A302",
            descricao: "Hanseníase [lepra] tuberculóide borderline",
            favorito: false
        },
        {
            cid10: "A303",
            descricao: "Hanseníase [lepra] dimorfa",
            favorito: false
        },
        {
            cid10: "A304",
            descricao: "Hanseníase [lepra] lepromatosa borderline",
            favorito: false
        },
        {
            cid10: "A305",
            descricao: "Hanseníase [lepra] lepromatosa",
            favorito: false
        },
        {
            cid10: "A308",
            descricao: "Outras formas de hanseníase [lepra]",
            favorito: false
        },
        {
            cid10: "A309",
            descricao: "Hanseníase [lepra] não especificada",
            favorito: false
        },
        {
            cid10: "A310",
            descricao: "Infecção pulmonar micobacteriana",
            favorito: false
        },
        {
            cid10: "A311",
            descricao: "Infecção cutânea micobacteriana",
            favorito: false
        },
        {
            cid10: "A318",
            descricao: "Outras infecções micobacterianas",
            favorito: false
        },
        {
            cid10: "A319",
            descricao: "Infecção micobacteriana não especificada",
            favorito: false
        },
        {
            cid10: "A320",
            descricao: "Listeriose cutânea",
            favorito: false
        },
        {
            cid10: "A321",
            descricao: "Meningite e meningoencefalite por listéria",
            favorito: false
        },
        {
            cid10: "A327",
            descricao: "Septicemia listeriótica",
            favorito: false
        },
        {
            cid10: "A328",
            descricao: "Outras formas de listeriose",
            favorito: false
        },
        {
            cid10: "A329",
            descricao: "Listeriose não especificada",
            favorito: false
        },
        {
            cid10: "A33",
            descricao: "Tétano do recém-nascido [neonatal]",
            favorito: false
        },
        {
            cid10: "A34",
            descricao: "Tétano obstétrico",
            favorito: false
        },
        {
            cid10: "A35",
            descricao: "Outros tipos de tétano",
            favorito: false
        },
        {
            cid10: "A360",
            descricao: "Difteria faríngea",
            favorito: false
        },
        {
            cid10: "A361",
            descricao: "Difteria nasofaríngea",
            favorito: false
        },
        {
            cid10: "A362",
            descricao: "Difteria laríngea",
            favorito: false
        },
        {
            cid10: "A363",
            descricao: "Difteria cutânea",
            favorito: false
        },
        {
            cid10: "A368",
            descricao: "Outras formas de difteria",
            favorito: false
        },
        {
            cid10: "A369",
            descricao: "Difteria não especificada",
            favorito: false
        },
        {
            cid10: "A370",
            descricao: "Coqueluche por Bordetella pertussis",
            favorito: false
        },
        {
            cid10: "A371",
            descricao: "Coqueluche por Bordetella parapertussis",
            favorito: false
        },
        {
            cid10: "A378",
            descricao: "Coqueluche por outras espécies da Bordetella",
            favorito: false
        },
        {
            cid10: "A379",
            descricao: "Coqueluche não especificada",
            favorito: false
        },
        {
            cid10: "A38",
            descricao: "Escarlatina",
            favorito: false
        },
        {
            cid10: "A390",
            descricao: "Meningite meningocócica",
            favorito: false
        },
        {
            cid10: "A391",
            descricao: "Síndrome de Waterhouse-Friderichsen",
            favorito: false
        },
        {
            cid10: "A392",
            descricao: "Meningococcemia aguda",
            favorito: false
        },
        {
            cid10: "A393",
            descricao: "Meningococcemia crônica",
            favorito: false
        },
        {
            cid10: "A394",
            descricao: "Meningococcemia não especificada",
            favorito: false
        },
        {
            cid10: "A395",
            descricao: "Cardite por meningococos",
            favorito: false
        },
        {
            cid10: "A398",
            descricao: "Outras infecções por meningococos",
            favorito: false
        },
        {
            cid10: "A399",
            descricao: "Infecção meningocócica não especificada",
            favorito: false
        },
        {
            cid10: "A400",
            descricao: "Septicemia por Streptococcus do grupo A",
            favorito: false
        },
        {
            cid10: "A401",
            descricao: "Septicemia por Streptococcus do grupo B",
            favorito: false
        },
        {
            cid10: "A402",
            descricao: "Septicemia por Streptococcus do grupo D",
            favorito: false
        },
        {
            cid10: "A403",
            descricao: "Septicemia por Streptococcus pneumonia",
            favorito: false
        },
        {
            cid10: "A408",
            descricao: "Outras septicemias estreptocócicas",
            favorito: false
        },
        {
            cid10: "A409",
            descricao: "Septicemia estreptocócica não especificada",
            favorito: false
        },
        {
            cid10: "A410",
            descricao: "Septicemia por Staphylococcus aureus",
            favorito: false
        },
        {
            cid10: "A411",
            descricao: "Septicemia por outros estafilococos especificados",
            favorito: false
        },
        {
            cid10: "A412",
            descricao: "Septicemia por estafilococos não especificados",
            favorito: false
        },
        {
            cid10: "A413",
            descricao: "Septicemia por Haemophilus influenzae",
            favorito: false
        },
        {
            cid10: "A414",
            descricao: "Septicemia por anaeróbios",
            favorito: false
        },
        {
            cid10: "A415",
            descricao: "Septicemia por outros microorganismos gram-negativos",
            favorito: false
        },
        {
            cid10: "A418",
            descricao: "Outras septicemias especificadas",
            favorito: false
        },
        {
            cid10: "A419",
            descricao: "Septicemia não especificada",
            favorito: false
        },
        {
            cid10: "A420",
            descricao: "Actinomicose pulmonar",
            favorito: false
        },
        {
            cid10: "A421",
            descricao: "Actinomicose abdominal",
            favorito: false
        },
        {
            cid10: "A422",
            descricao: "Actinomicose cervicofacial",
            favorito: false
        },
        {
            cid10: "A427",
            descricao: "Septicemia actinomicótica",
            favorito: false
        },
        {
            cid10: "A428",
            descricao: "Outras formas de actinomicose",
            favorito: false
        },
        {
            cid10: "A429",
            descricao: "Actinomicose não especificada",
            favorito: false
        },
        {
            cid10: "A430",
            descricao: "Nocardiose pulmonar",
            favorito: false
        },
        {
            cid10: "A431",
            descricao: "Nocardiose cutânea",
            favorito: false
        },
        {
            cid10: "A438",
            descricao: "Outras formas de nocardiose",
            favorito: false
        },
        {
            cid10: "A439",
            descricao: "Nocardiose não especificada",
            favorito: false
        },
        {
            cid10: "A440",
            descricao: "Bartonelose sistêmica",
            favorito: false
        },
        {
            cid10: "A441",
            descricao: "Bartonelose cutânea e cutâneo-mucosa",
            favorito: false
        },
        {
            cid10: "A448",
            descricao: "Outras formas de bartonelose",
            favorito: false
        },
        {
            cid10: "A449",
            descricao: "Bartonelose não especificada",
            favorito: false
        },
        {
            cid10: "A46",
            descricao: "Erisipela",
            favorito: false
        },
        {
            cid10: "A480",
            descricao: "Gangrena gasosa",
            favorito: false
        },
        {
            cid10: "A481",
            descricao: "Doença dos legionários",
            favorito: false
        },
        {
            cid10: "A482",
            descricao: "Doença dos legionários não-pneumônica [febre de Pontiac]",
            favorito: false
        },
        {
            cid10: "A483",
            descricao: "Síndrome do choque tóxico",
            favorito: false
        },
        {
            cid10: "A484",
            descricao: "Febre purpúrica do Brasil",
            favorito: false
        },
        {
            cid10: "A488",
            descricao: "Outras doenças bacterianas especificadas",
            favorito: false
        },
        {
            cid10: "A490",
            descricao: "Infecção estafilocócica de localização não especificada",
            favorito: false
        },
        {
            cid10: "A491",
            descricao: "Infecção estreptocócica de localização não especificada",
            favorito: false
        },
        {
            cid10: "A492",
            descricao: "Infecção por Haemophilus influenzae de localização não especificada",
            favorito: false
        },
        {
            cid10: "A493",
            descricao: "Infecção por Mycoplasma de localização não especificada",
            favorito: false
        },
        {
            cid10: "A498",
            descricao: "Outras infecções bacterianas de localização não especificada",
            favorito: false
        },
        {
            cid10: "A499",
            descricao: "Infecção bacteriana não especificada",
            favorito: false
        },
        {
            cid10: "A500",
            descricao: "Sífilis congênita precoce sintomática",
            favorito: false
        },
        {
            cid10: "A501",
            descricao: "Sífilis congênita precoce, forma latente",
            favorito: false
        },
        {
            cid10: "A502",
            descricao: "Sífilis congênita precoce não especificada",
            favorito: false
        },
        {
            cid10: "A503",
            descricao: "Oculopatia sifilítica congênita tardia",
            favorito: false
        },
        {
            cid10: "A504",
            descricao: "Neurossífilis congênita tardia [neurossífilis juvenil]",
            favorito: false
        },
        {
            cid10: "A505",
            descricao: "Outras formas tardias e sintomáticas da sífilis congênita",
            favorito: false
        },
        {
            cid10: "A506",
            descricao: "Sífilis congênita tardia latente",
            favorito: false
        },
        {
            cid10: "A507",
            descricao: "Sífilis congênita tardia não especificada",
            favorito: false
        },
        {
            cid10: "A509",
            descricao: "Sífilis congênita não especificada",
            favorito: false
        },
        {
            cid10: "A510",
            descricao: "Sífilis genital primária",
            favorito: false
        },
        {
            cid10: "A511",
            descricao: "Sífilis anal primária",
            favorito: false
        },
        {
            cid10: "A512",
            descricao: "Sífilis primária de outras localizações",
            favorito: false
        },
        {
            cid10: "A513",
            descricao: "Sífilis secundária da pele e das mucosas",
            favorito: false
        },
        {
            cid10: "A514",
            descricao: "Outras formas de sífilis secundária",
            favorito: false
        },
        {
            cid10: "A515",
            descricao: "Sífilis precoce latente",
            favorito: false
        },
        {
            cid10: "A519",
            descricao: "Sífilis precoce não especificada",
            favorito: false
        },
        {
            cid10: "A520",
            descricao: "Sífilis cardiovascular",
            favorito: false
        },
        {
            cid10: "A521",
            descricao: "Neurossífilis sintomática",
            favorito: false
        },
        {
            cid10: "A522",
            descricao: "Neurossífilis assintomática",
            favorito: false
        },
        {
            cid10: "A523",
            descricao: "Neurossífilis não especificada",
            favorito: false
        },
        {
            cid10: "A527",
            descricao: "Outras formas de sífilis tardia sintomática",
            favorito: false
        },
        {
            cid10: "A528",
            descricao: "Sífilis tardia latente",
            favorito: false
        },
        {
            cid10: "A529",
            descricao: "Sífilis tardia não especificada",
            favorito: false
        },
        {
            cid10: "A530",
            descricao: "Sífilis latente, não especificada se recente ou tardia",
            favorito: false
        },
        {
            cid10: "A539",
            descricao: "Sífilis não especificada",
            favorito: false
        },
        {
            cid10: "A540",
            descricao: "Infecção gonocócica do trato geniturinário inferior, sem abscesso periuretral ou das glândulas acessórias",
            favorito: false
        },
        {
            cid10: "A541",
            descricao: "Infecção gonocócica do trato geniturinário inferior, com abscesso periuretral ou das glândulas acessórias",
            favorito: false
        },
        {
            cid10: "A542",
            descricao: "Pelviperitonite gonocócica e outras infecções geniturinárias gonocócicas",
            favorito: false
        },
        {
            cid10: "A543",
            descricao: "Infecção gonocócica do olho",
            favorito: false
        },
        {
            cid10: "A544",
            descricao: "Infecção gonocócica do sistema músculo-esquelético",
            favorito: false
        },
        {
            cid10: "A545",
            descricao: "Faringite gonocócica",
            favorito: false
        },
        {
            cid10: "A546",
            descricao: "Infecção gonocócica do ânus ou do reto",
            favorito: false
        },
        {
            cid10: "A548",
            descricao: "Outras infecções gonocócicas",
            favorito: false
        },
        {
            cid10: "A549",
            descricao: "Infecção gonocócica não especificada",
            favorito: false
        },
        {
            cid10: "A55",
            descricao: "Linfogranuloma (venéreo) por clamídia",
            favorito: false
        },
        {
            cid10: "A560",
            descricao: "Infecções por clamídias do trato geniturinário inferior",
            favorito: false
        },
        {
            cid10: "A561",
            descricao: "Infecção por clamídias, pelviperitonial e de outros órgãos geniturinários",
            favorito: false
        },
        {
            cid10: "A562",
            descricao: "Infecção por clamídias do trato geniturinário, localização não especificada",
            favorito: false
        },
        {
            cid10: "A563",
            descricao: "Infecção do ânus e do reto por clamídias",
            favorito: false
        },
        {
            cid10: "A564",
            descricao: "Infecção da faringe por clamídias",
            favorito: false
        },
        {
            cid10: "A568",
            descricao: "Infecção por clamídias transmitida por via sexual, de outras localizações",
            favorito: false
        },
        {
            cid10: "A57",
            descricao: "Cancro mole",
            favorito: false
        },
        {
            cid10: "A58",
            descricao: "Granuloma inguinal",
            favorito: false
        },
        {
            cid10: "A590",
            descricao: "Tricomoníase urogenital",
            favorito: false
        },
        {
            cid10: "A598",
            descricao: "Outras localizações de tricomoníase",
            favorito: false
        },
        {
            cid10: "A599",
            descricao: "Tricomoníase não especificada",
            favorito: false
        },
        {
            cid10: "A600",
            descricao: "Infecção dos órgãos genitais e do trato geniturinário pelo vírus do herpes",
            favorito: false
        },
        {
            cid10: "A601",
            descricao: "Infecção da margem cutânea do ânus e do reto pelo vírus do herpes",
            favorito: false
        },
        {
            cid10: "A609",
            descricao: "Infecção anogenital não especificada pelo vírus do herpes",
            favorito: false
        },
        {
            cid10: "A630",
            descricao: "Verrugas anogenitais (venéreas)",
            favorito: false
        },
        {
            cid10: "A638",
            descricao: "Outras doenças especificadas de transmissão predominantemente sexual",
            favorito: false
        },
        {
            cid10: "A64",
            descricao: "Doenças sexualmente transmitidas, não especificadas",
            favorito: false
        },
        {
            cid10: "A65",
            descricao: "Sífilis não-venérea",
            favorito: false
        },
        {
            cid10: "A660",
            descricao: "Lesões iniciais da bouba",
            favorito: false
        },
        {
            cid10: "A661",
            descricao: "Papilomas múltiplos e bouba plantar úmida (cravo de bouba)",
            favorito: false
        },
        {
            cid10: "A662",
            descricao: "Outras lesões cutâneas precoces da bouba",
            favorito: false
        },
        {
            cid10: "A663",
            descricao: "Hiperceratose devida a bouba",
            favorito: false
        },
        {
            cid10: "A664",
            descricao: "Gomas e úlceras devidas à bouba",
            favorito: false
        },
        {
            cid10: "A665",
            descricao: "Gangosa",
            favorito: false
        },
        {
            cid10: "A666",
            descricao: "Lesões osteoarticulares devidas à bouba",
            favorito: false
        },
        {
            cid10: "A667",
            descricao: "Outras manifestações da bouba",
            favorito: false
        },
        {
            cid10: "A668",
            descricao: "Bouba latente",
            favorito: false
        },
        {
            cid10: "A669",
            descricao: "Bouba não especificada",
            favorito: false
        },
        {
            cid10: "A670",
            descricao: "Lesões primárias da pinta",
            favorito: false
        },
        {
            cid10: "A671",
            descricao: "Lesões intermediárias da pinta",
            favorito: false
        },
        {
            cid10: "A672",
            descricao: "Lesões tardias da pinta",
            favorito: false
        },
        {
            cid10: "A673",
            descricao: "Lesões mistas da pinta",
            favorito: false
        },
        {
            cid10: "A679",
            descricao: "Pinta não especificada",
            favorito: false
        },
        {
            cid10: "A680",
            descricao: "Febre recorrente transmitida por piolhos",
            favorito: false
        },
        {
            cid10: "A681",
            descricao: "Febre recorrente transmitida por carrapatos",
            favorito: false
        },
        {
            cid10: "A689",
            descricao: "Febre recorrente não especificada",
            favorito: false
        },
        {
            cid10: "A690",
            descricao: "Estomatite ulcerativa necrotizante",
            favorito: false
        },
        {
            cid10: "A691",
            descricao: "Outras infecções de Vincent",
            favorito: false
        },
        {
            cid10: "A692",
            descricao: "Doença de Lyme",
            favorito: false
        },
        {
            cid10: "A698",
            descricao: "Outras infecções especificadas por espiroquetas",
            favorito: false
        },
        {
            cid10: "A699",
            descricao: "Infecção por espiroqueta, não especificada",
            favorito: false
        },
        {
            cid10: "A70",
            descricao: "Infecções causadas por Clamídia psittaci",
            favorito: false
        },
        {
            cid10: "A710",
            descricao: "Fase inicial do tracoma",
            favorito: false
        },
        {
            cid10: "A711",
            descricao: "Fase ativa do tracoma",
            favorito: false
        },
        {
            cid10: "A719",
            descricao: "Tracoma não especificado",
            favorito: false
        },
        {
            cid10: "A740",
            descricao: "Conjuntivite causada por clamídias",
            favorito: false
        },
        {
            cid10: "A748",
            descricao: "Outras doenças causadas por clamídias",
            favorito: false
        },
        {
            cid10: "A749",
            descricao: "Infecção causada por clamídias não especificada",
            favorito: false
        },
        {
            cid10: "A750",
            descricao: "Tifo epidêmico transmitido por piolhos devido a Rickettsia prowazekii",
            favorito: false
        },
        {
            cid10: "A751",
            descricao: "Tifo recrudescente [doença de Brill]",
            favorito: false
        },
        {
            cid10: "A752",
            descricao: "Tifo por Rickettsia typhi",
            favorito: false
        },
        {
            cid10: "A753",
            descricao: "Tifo por Rickettsia tsutsugamuchi",
            favorito: false
        },
        {
            cid10: "A759",
            descricao: "Tifo não especificado",
            favorito: false
        },
        {
            cid10: "A770",
            descricao: "Febre maculosa por Rickettsia richettsii",
            favorito: false
        },
        {
            cid10: "A771",
            descricao: "Febre maculosa por Rickettsia conorii",
            favorito: false
        },
        {
            cid10: "A772",
            descricao: "Febre maculosa devida à Rickettsia siberica",
            favorito: false
        },
        {
            cid10: "A773",
            descricao: "Febre maculosa devida à Rickettsia australis",
            favorito: false
        },
        {
            cid10: "A778",
            descricao: "Outras febres maculosas",
            favorito: false
        },
        {
            cid10: "A779",
            descricao: "Febre maculosa não especificada",
            favorito: false
        },
        {
            cid10: "A78",
            descricao: "Febre Q",
            favorito: false
        },
        {
            cid10: "A790",
            descricao: "Febre das trincheiras",
            favorito: false
        },
        {
            cid10: "A791",
            descricao: "Rickettsiose variceliforme devida à Rickettsia akari",
            favorito: false
        },
        {
            cid10: "A798",
            descricao: "Outros tipos de rickettsioses especificadas",
            favorito: false
        },
        {
            cid10: "A799",
            descricao: "Rickettsiose não especificada",
            favorito: false
        },
        {
            cid10: "A800",
            descricao: "Poliomielite paralítica aguda, associada ao vírus vacinal",
            favorito: false
        },
        {
            cid10: "A801",
            descricao: "Poliomielite paralítica aguda, vírus selvagem importado",
            favorito: false
        },
        {
            cid10: "A802",
            descricao: "Poliomielite paralítica aguda, vírus selvagem indígena",
            favorito: false
        },
        {
            cid10: "A803",
            descricao: "Poliomielites paralíticas agudas, outras e não especificadas",
            favorito: false
        },
        {
            cid10: "A804",
            descricao: "Poliomielite aguda não-paralítica",
            favorito: false
        },
        {
            cid10: "A809",
            descricao: "Poliomielite aguda não especificada",
            favorito: false
        },
        {
            cid10: "A810",
            descricao: "Doença de Creutzfeldt-Jakob",
            favorito: false
        },
        {
            cid10: "A811",
            descricao: "Panencefalite esclerosante subaguda",
            favorito: false
        },
        {
            cid10: "A812",
            descricao: "Leucoencefalopatia multifocal progressiva",
            favorito: false
        },
        {
            cid10: "A818",
            descricao: "Outras infecções por vírus atípicos do sistema nervoso central",
            favorito: false
        },
        {
            cid10: "A819",
            descricao: "Infecção não especificada do sistema nervosos central por vírus atípicos",
            favorito: false
        },
        {
            cid10: "A820",
            descricao: "Raiva silvestre",
            favorito: false
        },
        {
            cid10: "A821",
            descricao: "Raiva urbana",
            favorito: false
        },
        {
            cid10: "A829",
            descricao: "Raiva não especificada",
            favorito: false
        },
        {
            cid10: "A830",
            descricao: "Encefalite japonesa",
            favorito: false
        },
        {
            cid10: "A831",
            descricao: "Encefalite eqüina ocidental",
            favorito: false
        },
        {
            cid10: "A832",
            descricao: "Encefalite eqüina oriental",
            favorito: false
        },
        {
            cid10: "A833",
            descricao: "Encefalite de St. Louis",
            favorito: false
        },
        {
            cid10: "A834",
            descricao: "Encefalite australiana",
            favorito: false
        },
        {
            cid10: "A835",
            descricao: "Encefalite da Califórnia",
            favorito: false
        },
        {
            cid10: "A836",
            descricao: "Doença pelo vírus de Rocio",
            favorito: false
        },
        {
            cid10: "A838",
            descricao: "Outras encefalites por vírus transmitidas por mosquitos",
            favorito: false
        },
        {
            cid10: "A839",
            descricao: "Encefalite não especificada por vírus transmitida por mosquitos",
            favorito: false
        },
        {
            cid10: "A840",
            descricao: "Encefalite da taiga [encefalite vernoestival russa]",
            favorito: false
        },
        {
            cid10: "A841",
            descricao: "Encefalite da Europa Central transmitida por carrapatos",
            favorito: false
        },
        {
            cid10: "A848",
            descricao: "Outras encefalites por vírus transmitidas por carrapatos",
            favorito: false
        },
        {
            cid10: "A849",
            descricao: "Encefalite não especificada por vírus transmitida por carrapatos",
            favorito: false
        },
        {
            cid10: "A850",
            descricao: "Encefalite por enterovírus",
            favorito: false
        },
        {
            cid10: "A851",
            descricao: "Encefalite por adenovírus",
            favorito: false
        },
        {
            cid10: "A852",
            descricao: "Encefalite por vírus transmitido por artrópodes, não especificada",
            favorito: false
        },
        {
            cid10: "A858",
            descricao: "Outras encefalites virais especificadas",
            favorito: false
        },
        {
            cid10: "A86",
            descricao: "Encefalite viral, não especificada",
            favorito: false
        },
        {
            cid10: "A870",
            descricao: "Meningite por enterovírus",
            favorito: false
        },
        {
            cid10: "A871",
            descricao: "Meningite por adenovírus",
            favorito: false
        },
        {
            cid10: "A872",
            descricao: "Coriomeningite linfocitária",
            favorito: false
        },
        {
            cid10: "A878",
            descricao: "Outras meningites virais",
            favorito: false
        },
        {
            cid10: "A879",
            descricao: "Meningite viral não especificada",
            favorito: false
        },
        {
            cid10: "A880",
            descricao: "Febre exantemática por enterovírus [exantema de Boston]",
            favorito: false
        },
        {
            cid10: "A881",
            descricao: "Vertigem epidêmica",
            favorito: false
        },
        {
            cid10: "A888",
            descricao: "Outras infecções virais especificadas do sistema nervoso central",
            favorito: false
        },
        {
            cid10: "A89",
            descricao: "Infecções virais não especificadas do sistema nervoso central",
            favorito: false
        },
        {
            cid10: "A90",
            descricao: "Dengue [dengue clássico]",
            favorito: false
        },
        {
            cid10: "A91",
            descricao: "Febre hemorrágica devida ao vírus do dengue",
            favorito: false
        },
        {
            cid10: "A920",
            descricao: "Febre de Chikungunya",
            favorito: false
        },
        {
            cid10: "A921",
            descricao: "Febre de Onyong-nyong",
            favorito: false
        },
        {
            cid10: "A922",
            descricao: "Febre eqüina venezuelana",
            favorito: false
        },
        {
            cid10: "A923",
            descricao: "Infecção pelo vírus West Nile",
            favorito: false
        },
        {
            cid10: "A924",
            descricao: "Febre do vale do Rift",
            favorito: false
        },
        {
            cid10: "A928",
            descricao: "Outras febres virais especificadas transmitidas por mosquitos",
            favorito: false
        },
        {
            cid10: "A929",
            descricao: "Febre viral transmitida por mosquitos, não especificada",
            favorito: false
        },
        {
            cid10: "A930",
            descricao: "Febre de Oropouche",
            favorito: false
        },
        {
            cid10: "A931",
            descricao: "Febre por flebótomos",
            favorito: false
        },
        {
            cid10: "A932",
            descricao: "Febre do Colorado transmitida por carrapatos",
            favorito: false
        },
        {
            cid10: "A938",
            descricao: "Outras febres virais especificadas transmitidas por artrópodes",
            favorito: false
        },
        {
            cid10: "A94",
            descricao: "Febre viral transmitida por artrópodes, não especificada",
            favorito: false
        },
        {
            cid10: "A950",
            descricao: "Febre amarela silvestre",
            favorito: false
        },
        {
            cid10: "A951",
            descricao: "Febre amarela urbana",
            favorito: false
        },
        {
            cid10: "A959",
            descricao: "Febre amarela não especificada",
            favorito: false
        },
        {
            cid10: "A960",
            descricao: "Febre hemorrágica de Junin",
            favorito: false
        },
        {
            cid10: "A961",
            descricao: "Febre hemorrágica de Machupo",
            favorito: false
        },
        {
            cid10: "A962",
            descricao: "Febre de Lassa",
            favorito: false
        },
        {
            cid10: "A968",
            descricao: "Outras febres hemorrágicas por arenavírus",
            favorito: false
        },
        {
            cid10: "A969",
            descricao: "Febre hemorrágica por arenavírus, não especificada",
            favorito: false
        },
        {
            cid10: "A980",
            descricao: "Febre hemorrágica da Criméia (do Congo)",
            favorito: false
        },
        {
            cid10: "A981",
            descricao: "Febre hemorrágica de Omsk",
            favorito: false
        },
        {
            cid10: "A982",
            descricao: "Doença da floresta de Kyasanur",
            favorito: false
        },
        {
            cid10: "A983",
            descricao: "Doença de Marburg",
            favorito: false
        },
        {
            cid10: "A984",
            descricao: "Doença pelo vírus Ebola",
            favorito: false
        },
        {
            cid10: "A985",
            descricao: "Febre hemorrágica com síndrome renal",
            favorito: false
        },
        {
            cid10: "A988",
            descricao: "Outras febres hemorrágicas especificadas por vírus",
            favorito: false
        },
        {
            cid10: "A99",
            descricao: "Febres hemorrágicas virais não especificadas",
            favorito: false
        },
        {
            cid10: "B000",
            descricao: "Eczema herpético",
            favorito: false
        },
        {
            cid10: "B001",
            descricao: "Dermatite vesicular devido ao vírus do herpes",
            favorito: false
        },
        {
            cid10: "B002",
            descricao: "Gengivoestomatite e faringoamigdalite devida ao vírus do herpes",
            favorito: false
        },
        {
            cid10: "B003",
            descricao: "Meningite devida ao vírus do herpes",
            favorito: false
        },
        {
            cid10: "B004",
            descricao: "Encefalite devida ao vírus do herpes",
            favorito: false
        },
        {
            cid10: "B005",
            descricao: "Afecções oculares devidas ao vírus do herpes",
            favorito: false
        },
        {
            cid10: "B007",
            descricao: "Doença disseminada devida ao vírus do herpes",
            favorito: false
        },
        {
            cid10: "B008",
            descricao: "Outras formas de infecção devida ao vírus do herpes",
            favorito: false
        },
        {
            cid10: "B009",
            descricao: "Infecção não especificada devida ao vírus do herpes",
            favorito: false
        },
        {
            cid10: "B010",
            descricao: "Meningite por varicela",
            favorito: false
        },
        {
            cid10: "B011",
            descricao: "Encefalite por varicela",
            favorito: false
        },
        {
            cid10: "B012",
            descricao: "Pneumopatia varicelosa",
            favorito: false
        },
        {
            cid10: "B018",
            descricao: "Varicela com outras complicações",
            favorito: false
        },
        {
            cid10: "B019",
            descricao: "Varicela sem complicação",
            favorito: false
        },
        {
            cid10: "B020",
            descricao: "Encefalite pelo vírus do herpes zoster",
            favorito: false
        },
        {
            cid10: "B021",
            descricao: "Meningite pelo vírus do herpes zoster",
            favorito: false
        },
        {
            cid10: "B022",
            descricao: "Herpes zoster acompanhado de outras manifestações neurológicas",
            favorito: false
        },
        {
            cid10: "B023",
            descricao: "Herpes zoster oftálmico",
            favorito: false
        },
        {
            cid10: "B027",
            descricao: "Herpes zoster disseminado",
            favorito: false
        },
        {
            cid10: "B028",
            descricao: "Herpes zoster com outras complicações",
            favorito: false
        },
        {
            cid10: "B029",
            descricao: "Herpes zoster sem complicação",
            favorito: false
        },
        {
            cid10: "B03",
            descricao: "Varíola",
            favorito: false
        },
        {
            cid10: "B04",
            descricao: "Varíola dos macacos [Monkeypox]",
            favorito: false
        },
        {
            cid10: "B050",
            descricao: "Sarampo complicado por encefalite",
            favorito: false
        },
        {
            cid10: "B051",
            descricao: "Sarampo complicado por meningite",
            favorito: false
        },
        {
            cid10: "B052",
            descricao: "Sarampo complicado por pneumonia",
            favorito: false
        },
        {
            cid10: "B053",
            descricao: "Sarampo complicado por otite média",
            favorito: false
        },
        {
            cid10: "B054",
            descricao: "Sarampo com complicações intestinais",
            favorito: false
        },
        {
            cid10: "B058",
            descricao: "Sarampo com outras complicações",
            favorito: false
        },
        {
            cid10: "B059",
            descricao: "Sarampo sem complicação",
            favorito: false
        },
        {
            cid10: "B060",
            descricao: "Rubéola com complicações neurológicas",
            favorito: false
        },
        {
            cid10: "B068",
            descricao: "Rubéola com outras complicações",
            favorito: false
        },
        {
            cid10: "B069",
            descricao: "Rubéola sem complicação",
            favorito: false
        },
        {
            cid10: "B07",
            descricao: "Verrugas de origem viral",
            favorito: false
        },
        {
            cid10: "B080",
            descricao: "Outras infecções por ortopoxvírus",
            favorito: false
        },
        {
            cid10: "B081",
            descricao: "Molusco contagioso",
            favorito: false
        },
        {
            cid10: "B082",
            descricao: "Exantema súbito [sexta doença]",
            favorito: false
        },
        {
            cid10: "B083",
            descricao: "Eritema infeccioso [quinta doença]",
            favorito: false
        },
        {
            cid10: "B084",
            descricao: "Estomatite vesicular devida a enterovírus com exantema",
            favorito: false
        },
        {
            cid10: "B085",
            descricao: "Faringite vesicular devida a enterovírus",
            favorito: false
        },
        {
            cid10: "B088",
            descricao: "Outras infecções virais especificadas caracterizadas por lesões de pele e das membranas mucosas",
            favorito: false
        },
        {
            cid10: "B09",
            descricao: "Infecção viral não especificada caracterizada por lesões da pele e membranas mucosas",
            favorito: false
        },
        {
            cid10: "B150",
            descricao: "Hepatite A com coma hepático",
            favorito: false
        },
        {
            cid10: "B159",
            descricao: "Hepatite A sem coma hepático",
            favorito: false
        },
        {
            cid10: "B160",
            descricao: "Hepatite aguda B com agente Delta (co-infecção), com coma hepático",
            favorito: false
        },
        {
            cid10: "B161",
            descricao: "Hepatite aguda B com agente Delta, (co-infecção), sem coma hepático",
            favorito: false
        },
        {
            cid10: "B162",
            descricao: "Hepatite aguda B sem agente Delta, com coma hepático",
            favorito: false
        },
        {
            cid10: "B169",
            descricao: "Hepatite aguda B sem agente Delta e sem coma hepático",
            favorito: false
        },
        {
            cid10: "B170",
            descricao: "(Super)infecção Delta aguda de portador de hepatite B",
            favorito: false
        },
        {
            cid10: "B171",
            descricao: "Hepatite aguda C",
            favorito: false
        },
        {
            cid10: "B172",
            descricao: "Hepatite aguda E",
            favorito: false
        },
        {
            cid10: "B178",
            descricao: "Outras hepatites virais agudas especificadas",
            favorito: false
        },
        {
            cid10: "B180",
            descricao: "Hepatite viral crônica B com agente Delta",
            favorito: false
        },
        {
            cid10: "B181",
            descricao: "Hepatite crônica viral B sem agente Delta",
            favorito: false
        },
        {
            cid10: "B182",
            descricao: "Hepatite viral crônica C",
            favorito: false
        },
        {
            cid10: "B188",
            descricao: "Outras hepatites crônicas virais",
            favorito: false
        },
        {
            cid10: "B189",
            descricao: "Hepatite viral crônica não especificada",
            favorito: false
        },
        {
            cid10: "B190",
            descricao: "Hepatite viral, não especificada, com coma",
            favorito: false
        },
        {
            cid10: "B199",
            descricao: "Hepatite viral, não especificada, sem coma",
            favorito: false
        },
        {
            cid10: "B200",
            descricao: "Doença pelo HIV resultando em infecções micobacterianas",
            favorito: false
        },
        {
            cid10: "B201",
            descricao: "Doença pelo HIV resultando em outras infecções bacterianas",
            favorito: false
        },
        {
            cid10: "B202",
            descricao: "Doença pelo HIV resultando em doença citomegálica",
            favorito: false
        },
        {
            cid10: "B203",
            descricao: "Doença pelo HIV resultando em outras infecções virais",
            favorito: false
        },
        {
            cid10: "B204",
            descricao: "Doença pelo HIV resultando em candidíase",
            favorito: false
        },
        {
            cid10: "B205",
            descricao: "Doença pelo HIV resultando em outras micoses",
            favorito: false
        },
        {
            cid10: "B206",
            descricao: "Doença pelo HIV resultando em pneumonia por Pneumocystis jirovecii",
            favorito: false
        },
        {
            cid10: "B207",
            descricao: "Doença pelo HIV resultando em infecções múltiplas",
            favorito: false
        },
        {
            cid10: "B208",
            descricao: "Doença pelo HIV resultando em outras doenças infecciosas e parasitárias",
            favorito: false
        },
        {
            cid10: "B209",
            descricao: "Doença pelo HIV resultando em doença infecciosa ou parasitária não especificada",
            favorito: false
        },
        {
            cid10: "B210",
            descricao: "Doença pelo HIV resultando em sarcoma de Kaposi",
            favorito: false
        },
        {
            cid10: "B211",
            descricao: "Doença pelo HIV resultando em linfoma de Burkitt",
            favorito: false
        },
        {
            cid10: "B212",
            descricao: "Doença pelo HIV resultando em outros tipos de linfoma não-Hodgkin",
            favorito: false
        },
        {
            cid10: "B213",
            descricao: "Doença pelo HIV resultando em outras neoplasias malignas dos tecidos linfático, hematopoético e correlatos",
            favorito: false
        },
        {
            cid10: "B217",
            descricao: "Doença pelo HIV resultando em múltiplas neoplasias malignas",
            favorito: false
        },
        {
            cid10: "B218",
            descricao: "Doença pelo HIV resultando em outras neoplasias malignas",
            favorito: false
        },
        {
            cid10: "B219",
            descricao: "Doença pelo HIV resultando em neoplasia maligna não especificada",
            favorito: false
        },
        {
            cid10: "B220",
            descricao: "Doença pelo HIV resultando em encefalopatia",
            favorito: false
        },
        {
            cid10: "B221",
            descricao: "Doença pelo HIV resultando em pneumonite intersticial linfática",
            favorito: false
        },
        {
            cid10: "B222",
            descricao: "Doença pelo HIV resultando em síndrome de emaciação",
            favorito: false
        },
        {
            cid10: "B227",
            descricao: "Doença pelo HIV resultando em doenças múltiplas classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "B230",
            descricao: "Síndrome de infecção aguda pelo HIV",
            favorito: false
        },
        {
            cid10: "B231",
            descricao: "Doença pelo HIV resultando em linfadenopatias generalizadas (persistentes)",
            favorito: false
        },
        {
            cid10: "B232",
            descricao: "Doença pelo HIV resultando em anomalias hematológicas e imunológicas não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "B238",
            descricao: "Doença pelo HIV resultando em outra afecções especificadas",
            favorito: false
        },
        {
            cid10: "B24",
            descricao: "Doença pelo vírus da imunodeficiência humana [HIV] não especificada",
            favorito: false
        },
        {
            cid10: "B250",
            descricao: "Pneumonite por citomegalovírus ",
            favorito: false
        },
        {
            cid10: "B251",
            descricao: "Hepatite por citomegalovírus",
            favorito: false
        },
        {
            cid10: "B252",
            descricao: "Pancreatite por citomegalovírus",
            favorito: false
        },
        {
            cid10: "B258",
            descricao: "Outras doenças por citomegalovírus",
            favorito: false
        },
        {
            cid10: "B259",
            descricao: "Doença não especificada por citomegalovírus",
            favorito: false
        },
        {
            cid10: "B260",
            descricao: "Orquite por caxumba [parotidite epidêmica]",
            favorito: false
        },
        {
            cid10: "B261",
            descricao: "Meningite por caxumba [parotidite epidêmica]",
            favorito: false
        },
        {
            cid10: "B262",
            descricao: "Encefalite por caxumba [parotidite epidêmica]",
            favorito: false
        },
        {
            cid10: "B263",
            descricao: "Pancreatite por caxumba [parotidite epidêmica]",
            favorito: false
        },
        {
            cid10: "B268",
            descricao: "Caxumba [parotidite epidêmica] com outras complicações",
            favorito: false
        },
        {
            cid10: "B269",
            descricao: "Caxumba [parotidite epidêmica] sem complicações",
            favorito: false
        },
        {
            cid10: "B270",
            descricao: "Mononucleose pelo vírus herpes gama",
            favorito: false
        },
        {
            cid10: "B271",
            descricao: "Mononucleose por citomegalovírus",
            favorito: false
        },
        {
            cid10: "B278",
            descricao: "Outras mononucleoses infecciosas",
            favorito: false
        },
        {
            cid10: "B279",
            descricao: "Mononucleose infecciosa não especificada",
            favorito: false
        },
        {
            cid10: "B300",
            descricao: "Ceratoconjuntivite devida a adenovírus",
            favorito: false
        },
        {
            cid10: "B301",
            descricao: "Conjuntivite devida a adenovírus",
            favorito: false
        },
        {
            cid10: "B302",
            descricao: "Faringoconjuntivite viral",
            favorito: false
        },
        {
            cid10: "B303",
            descricao: "Conjuntivite hemorrágica aguda endêmica (por enterovírus)",
            favorito: false
        },
        {
            cid10: "B308",
            descricao: "Outras conjuntivites virais",
            favorito: false
        },
        {
            cid10: "B309",
            descricao: "Conjuntivite viral não especificada",
            favorito: false
        },
        {
            cid10: "B330",
            descricao: "Mialgia epidêmica",
            favorito: false
        },
        {
            cid10: "B331",
            descricao: "Doença de Ross River",
            favorito: false
        },
        {
            cid10: "B332",
            descricao: "Cardite viral",
            favorito: false
        },
        {
            cid10: "B333",
            descricao: "Infecção por retrovírus não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "B334",
            descricao: "Síndrome (cardio-)pulmonar por Hantavírus [SPH] [SCPH]",
            favorito: false
        },
        {
            cid10: "B338",
            descricao: "Outras doenças especificadas por vírus",
            favorito: false
        },
        {
            cid10: "B340",
            descricao: "Infecção por adenovírus de localização não especificada",
            favorito: false
        },
        {
            cid10: "B341",
            descricao: "Infecção por enterovírus de localização não especificada",
            favorito: false
        },
        {
            cid10: "B342",
            descricao: "Infecção por coronavírus de localização não especificada",
            favorito: false
        },
        {
            cid10: "B343",
            descricao: "Infecção por parvovírus de localização não especificada",
            favorito: false
        },
        {
            cid10: "B344",
            descricao: "Infecção por papovavírus de localização não especificada",
            favorito: false
        },
        {
            cid10: "B348",
            descricao: "Outras infecções por vírus de localização não especificada",
            favorito: false
        },
        {
            cid10: "B349",
            descricao: "Infecção viral não especificada",
            favorito: false
        },
        {
            cid10: "B350",
            descricao: "Tinha da barba e do couro cabeludo",
            favorito: false
        },
        {
            cid10: "B351",
            descricao: "Tinha das unhas",
            favorito: false
        },
        {
            cid10: "B352",
            descricao: "Tinha da mão",
            favorito: false
        },
        {
            cid10: "B353",
            descricao: "Tinha dos pés",
            favorito: false
        },
        {
            cid10: "B354",
            descricao: "Tinha do corpo",
            favorito: false
        },
        {
            cid10: "B355",
            descricao: "Tinha imbricada",
            favorito: false
        },
        {
            cid10: "B356",
            descricao: "Tinea cruris",
            favorito: false
        },
        {
            cid10: "B358",
            descricao: "Outras dermatofitoses",
            favorito: false
        },
        {
            cid10: "B359",
            descricao: "Dermatofitose não especificada",
            favorito: false
        },
        {
            cid10: "B360",
            descricao: "Pitiríase versicolor",
            favorito: false
        },
        {
            cid10: "B361",
            descricao: "Tinha negra",
            favorito: false
        },
        {
            cid10: "B362",
            descricao: "Piedra branca",
            favorito: false
        },
        {
            cid10: "B363",
            descricao: "Piedra negra",
            favorito: false
        },
        {
            cid10: "B368",
            descricao: "Outras micoses superficiais especificadas",
            favorito: false
        },
        {
            cid10: "B369",
            descricao: "Micose superficial não especificada",
            favorito: false
        },
        {
            cid10: "B370",
            descricao: "Estomatite por Candida",
            favorito: false
        },
        {
            cid10: "B371",
            descricao: "Candidíase pulmonar",
            favorito: false
        },
        {
            cid10: "B372",
            descricao: "Candidíase da pele e das unhas",
            favorito: false
        },
        {
            cid10: "B373",
            descricao: "Candidíase da vulva e da vagina",
            favorito: false
        },
        {
            cid10: "B374",
            descricao: "Candidíase de outras localizações urogenitais",
            favorito: false
        },
        {
            cid10: "B375",
            descricao: "Meningite por Candida",
            favorito: false
        },
        {
            cid10: "B376",
            descricao: "Endocardite por Candida",
            favorito: false
        },
        {
            cid10: "B377",
            descricao: "Septicemia por Candida",
            favorito: false
        },
        {
            cid10: "B378",
            descricao: "Candidíase de outras localizações",
            favorito: false
        },
        {
            cid10: "B379",
            descricao: "Candidíase não especificada",
            favorito: false
        },
        {
            cid10: "B380",
            descricao: "Coccidioidomicose pulmonar aguda",
            favorito: false
        },
        {
            cid10: "B381",
            descricao: "Coccidioidomicose pulmonar crônica",
            favorito: false
        },
        {
            cid10: "B382",
            descricao: "Coccidioidomicose pulmonar não especificada",
            favorito: false
        },
        {
            cid10: "B383",
            descricao: "Coccidioidomicose cutânea",
            favorito: false
        },
        {
            cid10: "B384",
            descricao: "Meningite por coccidioidomicose",
            favorito: false
        },
        {
            cid10: "B387",
            descricao: "Coccidioidomicose disseminada",
            favorito: false
        },
        {
            cid10: "B388",
            descricao: "Outras formas de coccidioidomicose",
            favorito: false
        },
        {
            cid10: "B389",
            descricao: "Coccidioidomicose não especificada",
            favorito: false
        },
        {
            cid10: "B390",
            descricao: "Histoplasmose pulmonar aguda por Histoplasma capsulatum",
            favorito: false
        },
        {
            cid10: "B391",
            descricao: "Histoplasmose pulmonar crônica por Histoplasma capsulatum",
            favorito: false
        },
        {
            cid10: "B392",
            descricao: "Histoplasmose pulmonar não especificada por Histoplasma capsulatum",
            favorito: false
        },
        {
            cid10: "B393",
            descricao: "Histoplasmose disseminada por Histoplasma capsulatum",
            favorito: false
        },
        {
            cid10: "B394",
            descricao: "Histoplasmose não especificada por Histoplasma capsulatum",
            favorito: false
        },
        {
            cid10: "B395",
            descricao: "Histoplasmose por Histoplasma duboisii",
            favorito: false
        },
        {
            cid10: "B399",
            descricao: "Histoplasmose não especificada",
            favorito: false
        },
        {
            cid10: "B400",
            descricao: "Blastomicose pulmonar aguda",
            favorito: false
        },
        {
            cid10: "B401",
            descricao: "Blastomicose pulmonar crônica",
            favorito: false
        },
        {
            cid10: "B402",
            descricao: "Blastomicose pulmonar não especificada",
            favorito: false
        },
        {
            cid10: "B403",
            descricao: "Blastomicose cutânea",
            favorito: false
        },
        {
            cid10: "B407",
            descricao: "Blastomicose disseminada",
            favorito: false
        },
        {
            cid10: "B408",
            descricao: "Outras formas de blastomicose",
            favorito: false
        },
        {
            cid10: "B409",
            descricao: "Blastomicose não especificada",
            favorito: false
        },
        {
            cid10: "B410",
            descricao: "Paracoccidioidomicose pulmonar",
            favorito: false
        },
        {
            cid10: "B417",
            descricao: "Paracoccidioidomicose disseminada",
            favorito: false
        },
        {
            cid10: "B418",
            descricao: "Outras formas de paracoccidioidomicose",
            favorito: false
        },
        {
            cid10: "B419",
            descricao: "Paracoccidioidomicose não especificada",
            favorito: false
        },
        {
            cid10: "B420",
            descricao: "Esporotricose pulmonar",
            favorito: false
        },
        {
            cid10: "B421",
            descricao: "Esporotricose linfocutânea",
            favorito: false
        },
        {
            cid10: "B427",
            descricao: "Esporotricose disseminada",
            favorito: false
        },
        {
            cid10: "B428",
            descricao: "Outras formas de esporotricose",
            favorito: false
        },
        {
            cid10: "B429",
            descricao: "Esporotricose não especificada",
            favorito: false
        },
        {
            cid10: "B430",
            descricao: "Cromomicose cutânea",
            favorito: false
        },
        {
            cid10: "B431",
            descricao: "Abscesso cerebral feomicótico",
            favorito: false
        },
        {
            cid10: "B432",
            descricao: "Abscesso e cisto feomicótico subcutâneos",
            favorito: false
        },
        {
            cid10: "B438",
            descricao: "Outras formas de cromomicose",
            favorito: false
        },
        {
            cid10: "B439",
            descricao: "Cromomicose não especificada",
            favorito: false
        },
        {
            cid10: "B440",
            descricao: "Aspergilose pulmonar invasiva",
            favorito: false
        },
        {
            cid10: "B441",
            descricao: "Outras aspergiloses pulmonares",
            favorito: false
        },
        {
            cid10: "B442",
            descricao: "Aspergilose amigdaliana",
            favorito: false
        },
        {
            cid10: "B447",
            descricao: "Aspergilose disseminada",
            favorito: false
        },
        {
            cid10: "B448",
            descricao: "Outras formas de aspergilose",
            favorito: false
        },
        {
            cid10: "B449",
            descricao: "Aspergilose não especificada",
            favorito: false
        },
        {
            cid10: "B450",
            descricao: "Criptococose pulmonar",
            favorito: false
        },
        {
            cid10: "B451",
            descricao: "Criptococose cerebral",
            favorito: false
        },
        {
            cid10: "B452",
            descricao: "Criptococose cutânea",
            favorito: false
        },
        {
            cid10: "B453",
            descricao: "Criptococose óssea",
            favorito: false
        },
        {
            cid10: "B457",
            descricao: "Criptococose disseminada",
            favorito: false
        },
        {
            cid10: "B458",
            descricao: "Outras formas de criptococose",
            favorito: false
        },
        {
            cid10: "B459",
            descricao: "Criptococose não especificada",
            favorito: false
        },
        {
            cid10: "B460",
            descricao: "Mucormicose pulmonar",
            favorito: false
        },
        {
            cid10: "B461",
            descricao: "Mucormicose rinocerebral",
            favorito: false
        },
        {
            cid10: "B462",
            descricao: "Mucormicose gastrointestinal",
            favorito: false
        },
        {
            cid10: "B463",
            descricao: "Mucormicose cutânea",
            favorito: false
        },
        {
            cid10: "B464",
            descricao: "Mucormicose disseminada",
            favorito: false
        },
        {
            cid10: "B465",
            descricao: "Mucormicose não especificada",
            favorito: false
        },
        {
            cid10: "B468",
            descricao: "Outras zigomicoses",
            favorito: false
        },
        {
            cid10: "B469",
            descricao: "Zigomicose não especificada",
            favorito: false
        },
        {
            cid10: "B470",
            descricao: "Eumicetoma",
            favorito: false
        },
        {
            cid10: "B471",
            descricao: "Actinomicetoma",
            favorito: false
        },
        {
            cid10: "B479",
            descricao: "Micetoma não especificado",
            favorito: false
        },
        {
            cid10: "B480",
            descricao: "Lobomicose",
            favorito: false
        },
        {
            cid10: "B481",
            descricao: "Rinosporidiose",
            favorito: false
        },
        {
            cid10: "B482",
            descricao: "Alesqueriose",
            favorito: false
        },
        {
            cid10: "B483",
            descricao: "Geotricose",
            favorito: false
        },
        {
            cid10: "B484",
            descricao: "Penicilose",
            favorito: false
        },
        {
            cid10: "B487",
            descricao: "Micoses oportunistas",
            favorito: false
        },
        {
            cid10: "B488",
            descricao: "Outras micoses especificadas",
            favorito: false
        },
        {
            cid10: "B49",
            descricao: "Micose não especificada",
            favorito: false
        },
        {
            cid10: "B500",
            descricao: "Malária por Plasmodium falciparum com complicações cerebrais",
            favorito: false
        },
        {
            cid10: "B508",
            descricao: "Outras formas graves e complicadas de malária por Plasmodium falciparum",
            favorito: false
        },
        {
            cid10: "B509",
            descricao: "Malária não especificada por Plasmodium falciparum",
            favorito: false
        },
        {
            cid10: "B510",
            descricao: "Malária por Plasmodium vivax com rotura do baço",
            favorito: false
        },
        {
            cid10: "B518",
            descricao: "Malária por Plasmodium vivax com outras complicações",
            favorito: false
        },
        {
            cid10: "B519",
            descricao: "Malária por Plasmodium vivax sem complicações",
            favorito: false
        },
        {
            cid10: "B520",
            descricao: "Malária por Plasmodium malariae com nefropatia",
            favorito: false
        },
        {
            cid10: "B528",
            descricao: "Malária por Plasmodium malariae com outras complicações",
            favorito: false
        },
        {
            cid10: "B529",
            descricao: "Malária por Plasmodium malariae sem complicações",
            favorito: false
        },
        {
            cid10: "B530",
            descricao: "Malária por Plasmodium ovale",
            favorito: false
        },
        {
            cid10: "B531",
            descricao: "Malária por plasmódios de macacos",
            favorito: false
        },
        {
            cid10: "B538",
            descricao: "Outras formas de malárias com confirmação parasitológica, não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "B54",
            descricao: "Malária não especificada",
            favorito: false
        },
        {
            cid10: "B550",
            descricao: "Leishmaniose visceral",
            favorito: false
        },
        {
            cid10: "B551",
            descricao: "Leishmaniose cutânea",
            favorito: false
        },
        {
            cid10: "B552",
            descricao: "Leishmaniose cutâneo-mucosa",
            favorito: false
        },
        {
            cid10: "B559",
            descricao: "Leishmaniose não especificada",
            favorito: false
        },
        {
            cid10: "B560",
            descricao: "Tripanossomíase por Trypanosoma gambiense",
            favorito: false
        },
        {
            cid10: "B561",
            descricao: "Tripanossomíase por Trypanosoma rhodesiense",
            favorito: false
        },
        {
            cid10: "B569",
            descricao: "Tripanossomíase africana não especificada",
            favorito: false
        },
        {
            cid10: "B570",
            descricao: "Forma aguda da doença de Chagas, com comprometimento cardíaco",
            favorito: false
        },
        {
            cid10: "B571",
            descricao: "Forma aguda da doença de Chagas, sem comprometimento cardíaco",
            favorito: false
        },
        {
            cid10: "B572",
            descricao: "Doença de Chagas (crônica) com comprometimento cardíaco",
            favorito: false
        },
        {
            cid10: "B573",
            descricao: "Doença de Chagas (crônica) com comprometimento do aparelho digestivo",
            favorito: false
        },
        {
            cid10: "B574",
            descricao: "Doença de Chagas (crônica) com comprometimento do sistema nervoso",
            favorito: false
        },
        {
            cid10: "B575",
            descricao: "Doença de Chagas (crônica) com comprometimento de outros órgãos",
            favorito: false
        },
        {
            cid10: "B580",
            descricao: "Oculopatia por Toxoplasma",
            favorito: false
        },
        {
            cid10: "B581",
            descricao: "Hepatite por Toxoplasma",
            favorito: false
        },
        {
            cid10: "B582",
            descricao: "Meningoencefalite por Toxoplasma",
            favorito: false
        },
        {
            cid10: "B583",
            descricao: "Toxoplasmose pulmonar",
            favorito: false
        },
        {
            cid10: "B588",
            descricao: "Toxoplasmose com comprometimento de outros órgãos",
            favorito: false
        },
        {
            cid10: "B589",
            descricao: "Toxoplasmose não especificada",
            favorito: false
        },
        {
            cid10: "B59",
            descricao: "Pneumocistose",
            favorito: false
        },
        {
            cid10: "B600",
            descricao: "Babesiose",
            favorito: false
        },
        {
            cid10: "B601",
            descricao: "Acantamebíase",
            favorito: false
        },
        {
            cid10: "B602",
            descricao: "Naegleríase",
            favorito: false
        },
        {
            cid10: "B608",
            descricao: "Outras doenças especificadas devidas a protozoários",
            favorito: false
        },
        {
            cid10: "B64",
            descricao: "Doença não especificada devida a protozoários",
            favorito: false
        },
        {
            cid10: "B650",
            descricao: "Esquistossomose devida ao Schistosoma haematobium [esquistossomose urinária]",
            favorito: false
        },
        {
            cid10: "B651",
            descricao: "Esquistossomose devida ao Schistosoma mansoni [esquistossomose intestinal]",
            favorito: false
        },
        {
            cid10: "B652",
            descricao: "Esquistossomose devida ao Schistosoma japonicum",
            favorito: false
        },
        {
            cid10: "B653",
            descricao: "Dermatite por cercárias",
            favorito: false
        },
        {
            cid10: "B658",
            descricao: "Outras esquistossomoses",
            favorito: false
        },
        {
            cid10: "B659",
            descricao: "Esquistossomose não especificada",
            favorito: false
        },
        {
            cid10: "B660",
            descricao: "Opistorquíase",
            favorito: false
        },
        {
            cid10: "B661",
            descricao: "Clonorquíase",
            favorito: false
        },
        {
            cid10: "B662",
            descricao: "Dicrocelíase",
            favorito: false
        },
        {
            cid10: "B663",
            descricao: "Fasciolíase",
            favorito: false
        },
        {
            cid10: "B664",
            descricao: "Paragonimíase",
            favorito: false
        },
        {
            cid10: "B665",
            descricao: "Fasciolopsíase",
            favorito: false
        },
        {
            cid10: "B668",
            descricao: "Outras infestações por trematódeos especificados",
            favorito: false
        },
        {
            cid10: "B669",
            descricao: "Infecção não especificada por trematódeo",
            favorito: false
        },
        {
            cid10: "B670",
            descricao: "Infestação hepática por Echinococcus granulosus",
            favorito: false
        },
        {
            cid10: "B671",
            descricao: "Infestação pulmonar por Echinococcus granulosus",
            favorito: false
        },
        {
            cid10: "B672",
            descricao: "Infestação óssea por Echinococcus granulosus",
            favorito: false
        },
        {
            cid10: "B673",
            descricao: "Infestações por Echinmococcus granulosus, outras e de localizações múltiplas",
            favorito: false
        },
        {
            cid10: "B674",
            descricao: "Infestação não especificada por Echinococcus granulosus",
            favorito: false
        },
        {
            cid10: "B675",
            descricao: "Infestação hepática por Echinococcus multilocularis",
            favorito: false
        },
        {
            cid10: "B676",
            descricao: "Infecções por Echinococcus multilocularis, outras e de localizações múltiplas",
            favorito: false
        },
        {
            cid10: "B677",
            descricao: "Infestação não especificada por Echinococcus multilocularis",
            favorito: false
        },
        {
            cid10: "B678",
            descricao: "Infestação hepática não especificada, por Echinococcus",
            favorito: false
        },
        {
            cid10: "B679",
            descricao: "Infestações por Echinococcus, outras e as não especificadas",
            favorito: false
        },
        {
            cid10: "B680",
            descricao: "Infestação por Taenia solium",
            favorito: false
        },
        {
            cid10: "B681",
            descricao: "Infestação por Taenia saginata",
            favorito: false
        },
        {
            cid10: "B689",
            descricao: "Infestação não especificada por Taenia",
            favorito: false
        },
        {
            cid10: "B690",
            descricao: "Cisticercose do sistema nervoso central",
            favorito: false
        },
        {
            cid10: "B691",
            descricao: "Cisticercose do olho",
            favorito: false
        },
        {
            cid10: "B698",
            descricao: "Cisticercose de outras localizações",
            favorito: false
        },
        {
            cid10: "B699",
            descricao: "Cisticercose não especificada",
            favorito: false
        },
        {
            cid10: "B700",
            descricao: "Difilobotríase",
            favorito: false
        },
        {
            cid10: "B701",
            descricao: "Esparganose",
            favorito: false
        },
        {
            cid10: "B710",
            descricao: "Infestação por Hymenolepis",
            favorito: false
        },
        {
            cid10: "B711",
            descricao: "Infestação por Dipylidium",
            favorito: false
        },
        {
            cid10: "B718",
            descricao: "Outras infestações especificadas por cestóides",
            favorito: false
        },
        {
            cid10: "B719",
            descricao: "Infestação não especificada por cestóides",
            favorito: false
        },
        {
            cid10: "B72",
            descricao: "Dracontíase",
            favorito: false
        },
        {
            cid10: "B73",
            descricao: "Oncocercose",
            favorito: false
        },
        {
            cid10: "B740",
            descricao: "Filariose por Wuchereria bancrofti",
            favorito: false
        },
        {
            cid10: "B741",
            descricao: "Filariose por Brugia malayi",
            favorito: false
        },
        {
            cid10: "B742",
            descricao: "Filariose por Brugia timori",
            favorito: false
        },
        {
            cid10: "B743",
            descricao: "Loaíase",
            favorito: false
        },
        {
            cid10: "B744",
            descricao: "Mansonelose",
            favorito: false
        },
        {
            cid10: "B748",
            descricao: "Outras filarioses",
            favorito: false
        },
        {
            cid10: "B749",
            descricao: "Filariose não especificada",
            favorito: false
        },
        {
            cid10: "B75",
            descricao: "Triquinose",
            favorito: false
        },
        {
            cid10: "B760",
            descricao: "Ancilostomose",
            favorito: false
        },
        {
            cid10: "B761",
            descricao: "Necatoríase",
            favorito: false
        },
        {
            cid10: "B768",
            descricao: "Outras ancilostomíases",
            favorito: false
        },
        {
            cid10: "B769",
            descricao: "Ancilostomíase não especificada",
            favorito: false
        },
        {
            cid10: "B770",
            descricao: "Ascaridíase com complicações intestinais",
            favorito: false
        },
        {
            cid10: "B778",
            descricao: "Ascaridíase com outras complicações",
            favorito: false
        },
        {
            cid10: "B779",
            descricao: "Ascaridíase não especificada",
            favorito: false
        },
        {
            cid10: "B780",
            descricao: "Estrongiloidíase intestinal",
            favorito: false
        },
        {
            cid10: "B781",
            descricao: "Estrongiloidíase cutânea",
            favorito: false
        },
        {
            cid10: "B787",
            descricao: "Estrongiloidíase disseminada",
            favorito: false
        },
        {
            cid10: "B789",
            descricao: "Estrongiloidíase não especificada",
            favorito: false
        },
        {
            cid10: "B79",
            descricao: "Tricuríase",
            favorito: false
        },
        {
            cid10: "B80",
            descricao: "Oxiuríase",
            favorito: false
        },
        {
            cid10: "B810",
            descricao: "Anisaquíase",
            favorito: false
        },
        {
            cid10: "B811",
            descricao: "Capilaríase intestinal",
            favorito: false
        },
        {
            cid10: "B812",
            descricao: "Tricostrongilose",
            favorito: false
        },
        {
            cid10: "B813",
            descricao: "Angiostrongilíase intestinal",
            favorito: false
        },
        {
            cid10: "B814",
            descricao: "Helmintíases intestinais mistas",
            favorito: false
        },
        {
            cid10: "B818",
            descricao: "Outras helmintíases intestinais especificadas",
            favorito: false
        },
        {
            cid10: "B820",
            descricao: "Helmintíase intestinal não especificada",
            favorito: false
        },
        {
            cid10: "B829",
            descricao: "Parasitose intestinal não especificada",
            favorito: false
        },
        {
            cid10: "B830",
            descricao: "Larva migrans visceral",
            favorito: false
        },
        {
            cid10: "B831",
            descricao: "Gnatostomíase",
            favorito: false
        },
        {
            cid10: "B832",
            descricao: "Angrostrongilíase devida a Parastrongylus cantonensis",
            favorito: false
        },
        {
            cid10: "B833",
            descricao: "Singamose",
            favorito: false
        },
        {
            cid10: "B834",
            descricao: "Hirudiníase interna",
            favorito: false
        },
        {
            cid10: "B838",
            descricao: "Outras helmintíases especificadas",
            favorito: false
        },
        {
            cid10: "B839",
            descricao: "Helmintíase não especificada",
            favorito: false
        },
        {
            cid10: "B850",
            descricao: "Pediculose devida a Pediculus humanus capitis",
            favorito: false
        },
        {
            cid10: "B851",
            descricao: "Pediculose devida a Pediculus humanus corporis",
            favorito: false
        },
        {
            cid10: "B852",
            descricao: "Pediculose não especificada",
            favorito: false
        },
        {
            cid10: "B853",
            descricao: "Ftiríase",
            favorito: false
        },
        {
            cid10: "B854",
            descricao: "Pediculose e ftiríase mista",
            favorito: false
        },
        {
            cid10: "B86",
            descricao: "Escabiose [sarna]",
            favorito: false
        },
        {
            cid10: "B870",
            descricao: "Miíase cutânea",
            favorito: false
        },
        {
            cid10: "B871",
            descricao: "Miíase das feridas",
            favorito: false
        },
        {
            cid10: "B872",
            descricao: "Miíase ocular",
            favorito: false
        },
        {
            cid10: "B873",
            descricao: "Miíase nasofaríngea",
            favorito: false
        },
        {
            cid10: "B874",
            descricao: "Miíase auricular",
            favorito: false
        },
        {
            cid10: "B878",
            descricao: "Miíase de outras localizações",
            favorito: false
        },
        {
            cid10: "B879",
            descricao: "Miíase não especificada",
            favorito: false
        },
        {
            cid10: "B880",
            descricao: "Outras acaríases",
            favorito: false
        },
        {
            cid10: "B881",
            descricao: "Tungíase [infestação pela pulga da areia]",
            favorito: false
        },
        {
            cid10: "B882",
            descricao: "Outras infestações por artrópodos",
            favorito: false
        },
        {
            cid10: "B883",
            descricao: "Hirudiníase externa",
            favorito: false
        },
        {
            cid10: "B888",
            descricao: "Outras infestações especificadas",
            favorito: false
        },
        {
            cid10: "B889",
            descricao: "Infestação não especificada",
            favorito: false
        },
        {
            cid10: "B89",
            descricao: "Doença parasitária não especificada",
            favorito: false
        },
        {
            cid10: "B900",
            descricao: "Seqüelas de tuberculose do sistema nervoso central",
            favorito: false
        },
        {
            cid10: "B901",
            descricao: "Seqüelas de tuberculose geniturinária",
            favorito: false
        },
        {
            cid10: "B902",
            descricao: "Seqüelas de tuberculose óssea e das articulações",
            favorito: false
        },
        {
            cid10: "B908",
            descricao: "Seqüelas de tuberculose de outros órgãos",
            favorito: false
        },
        {
            cid10: "B909",
            descricao: "Seqüelas de tuberculose das vias respiratórias e de órgãos não especificados",
            favorito: false
        },
        {
            cid10: "B91",
            descricao: "Seqüelas de poliomielite",
            favorito: false
        },
        {
            cid10: "B92",
            descricao: "Seqüelas de hanseníase [lepra]",
            favorito: false
        },
        {
            cid10: "B940",
            descricao: "Seqüelas de tracoma",
            favorito: false
        },
        {
            cid10: "B941",
            descricao: "Seqüelas de encefalite viral",
            favorito: false
        },
        {
            cid10: "B942",
            descricao: "Seqüelas de hepatite viral",
            favorito: false
        },
        {
            cid10: "B948",
            descricao: "Seqüelas de outras doenças infecciosas e parasitárias especificadas",
            favorito: false
        },
        {
            cid10: "B949",
            descricao: "Seqüelas de doença infecciosa ou parasitária não especificada",
            favorito: false
        },
        {
            cid10: "B950",
            descricao: "Estreptococo do grupo A, como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B951",
            descricao: "Estreptococo do grupo B, como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B952",
            descricao: "Estreptococo do grupo D, como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B953",
            descricao: "Streptococcus pneumoniae, como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B954",
            descricao: "Outros estreptococos, como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B955",
            descricao: "Estreptococo não especificado, como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B956",
            descricao: "Staphylococcus aureus, como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B957",
            descricao: "Outros estafilococos como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B958",
            descricao: "Estafilococo não especificado, como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B960",
            descricao: "Mycoplasma pneumoniae [M. pneumoniae], como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B961",
            descricao: "Klebsiella pneumoniae [M. pneumoniae], como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B962",
            descricao: "Escherichia coli [E. Coli], como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B963",
            descricao: "Haemophilus influenzae [H. influenzae], como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B964",
            descricao: "Proteus (mirabilis) (morganii), como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B965",
            descricao: "Pseudomonas (aeruginosa) (mallei) (pseudomallei), como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B966",
            descricao: "Bacillus fragilis [B. fragilis], como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B967",
            descricao: "Clostridium perfringens [C. perfringens], como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B968",
            descricao: "Outros agentes bacterianos especificados, como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B970",
            descricao: "Adenovírus, como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B971",
            descricao: "Enterovírus, como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B972",
            descricao: "Coronavírus, como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B973",
            descricao: "Retrovírus, como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B974",
            descricao: "Vírus sincicial respiratório, como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B975",
            descricao: "Reovírus, como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B976",
            descricao: "Parvovírus, como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B977",
            descricao: "Papilomavírus, como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B978",
            descricao: "Outros agentes virais, como causa de doenças classificadas em outros capítulos",
            favorito: false
        },
        {
            cid10: "B99",
            descricao: "Doenças infecciosas, outras e as não especificadas",
            favorito: false
        },
        {
            cid10: "C000",
            descricao: "Neoplasia maligna do lábio superior externo",
            favorito: false
        },
        {
            cid10: "C001",
            descricao: "Neoplasia maligna do lábio inferior externo",
            favorito: false
        },
        {
            cid10: "C002",
            descricao: "Neoplasia maligna do lábio externo, não especificado",
            favorito: false
        },
        {
            cid10: "C003",
            descricao: "Neoplasia maligna do lábio superior, face interna",
            favorito: false
        },
        {
            cid10: "C004",
            descricao: "Neoplasia maligna do lábio inferior, face interna",
            favorito: false
        },
        {
            cid10: "C005",
            descricao: "Neoplasia maligna do lábio, sem especificação, face interna",
            favorito: false
        },
        {
            cid10: "C006",
            descricao: "Neoplasia maligna da comissura labial",
            favorito: false
        },
        {
            cid10: "C008",
            descricao: "Neoplasia maligna do lábio com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C009",
            descricao: "Neoplasia maligna do lábio, não especificado",
            favorito: false
        },
        {
            cid10: "C01",
            descricao: "Neoplasia maligna da base da língua",
            favorito: false
        },
        {
            cid10: "C020",
            descricao: "Neoplasia maligna da face dorsal da língua",
            favorito: false
        },
        {
            cid10: "C021",
            descricao: "Neoplasia maligna da borda da língua",
            favorito: false
        },
        {
            cid10: "C022",
            descricao: "Neoplasia maligna da face ventral da língua",
            favorito: false
        },
        {
            cid10: "C023",
            descricao: "Neoplasia maligna de dois terços anteriores da língua, parte não especificada",
            favorito: false
        },
        {
            cid10: "C024",
            descricao: "Neoplasia maligna da amígdala lingual",
            favorito: false
        },
        {
            cid10: "C028",
            descricao: "Neoplasia maligna da língua com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C029",
            descricao: "Neoplasia maligna da língua, não especificada",
            favorito: false
        },
        {
            cid10: "C030",
            descricao: "Neoplasia maligna da gengiva superior",
            favorito: false
        },
        {
            cid10: "C031",
            descricao: "Neoplasia maligna da gengiva inferior",
            favorito: false
        },
        {
            cid10: "C039",
            descricao: "Neoplasia maligna da gengiva, não especificada",
            favorito: false
        },
        {
            cid10: "C040",
            descricao: "Neoplasia maligna do assoalho anterior da boca",
            favorito: false
        },
        {
            cid10: "C041",
            descricao: "Neoplasia maligna do assoalho lateral da boca",
            favorito: false
        },
        {
            cid10: "C048",
            descricao: "Neoplasia maligna do assoalho da boca com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C049",
            descricao: "Neoplasia maligna do assoalho da boca, não especificado",
            favorito: false
        },
        {
            cid10: "C050",
            descricao: "Neoplasia maligna do palato duro",
            favorito: false
        },
        {
            cid10: "C051",
            descricao: "Neoplasia maligna do palato mole",
            favorito: false
        },
        {
            cid10: "C052",
            descricao: "Neoplasia maligna da úvula",
            favorito: false
        },
        {
            cid10: "C058",
            descricao: "Neoplasia maligna do palato com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C059",
            descricao: "Neoplasia maligna do palato, não especificado",
            favorito: false
        },
        {
            cid10: "C060",
            descricao: "Neoplasia maligna da mucosa oral",
            favorito: false
        },
        {
            cid10: "C061",
            descricao: "Neoplasia maligna do vestíbulo da boca",
            favorito: false
        },
        {
            cid10: "C062",
            descricao: "Neoplasia maligna da área retromolar",
            favorito: false
        },
        {
            cid10: "C068",
            descricao: "Neoplasia maligna de outras partes e de partes não especificadas da boca com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C069",
            descricao: "Neoplasia maligna da boca, não especificada",
            favorito: false
        },
        {
            cid10: "C07",
            descricao: "Neoplasia maligna da glândula parótida",
            favorito: false
        },
        {
            cid10: "C080",
            descricao: "Neoplasia maligna da glândula submandibular",
            favorito: false
        },
        {
            cid10: "C081",
            descricao: "Neoplasia maligna da glândula sublingual",
            favorito: false
        },
        {
            cid10: "C088",
            descricao: "Neoplasia maligna das glândulas salivares maiores com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C089",
            descricao: "Neoplasia maligna da glândula salivar maior, não especificada",
            favorito: false
        },
        {
            cid10: "C090",
            descricao: "Neoplasia maligna da fossa amigdaliana",
            favorito: false
        },
        {
            cid10: "C091",
            descricao: "Neoplasia maligna do pilar amigdaliano (anterior) (posterior)",
            favorito: false
        },
        {
            cid10: "C098",
            descricao: "Neoplasia maligna da amígdala com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C099",
            descricao: "Neoplasia maligna da amígdala, não especificada",
            favorito: false
        },
        {
            cid10: "C100",
            descricao: "Neoplasia maligna da valécula",
            favorito: false
        },
        {
            cid10: "C101",
            descricao: "Neoplasia maligna da face anterior da epiglote",
            favorito: false
        },
        {
            cid10: "C102",
            descricao: "Neoplasia maligna da parede lateral da orofaringe",
            favorito: false
        },
        {
            cid10: "C103",
            descricao: "Neoplasia maligna da parede posterior da orofaringe",
            favorito: false
        },
        {
            cid10: "C104",
            descricao: "Neoplasia maligna da fenda branquial",
            favorito: false
        },
        {
            cid10: "C108",
            descricao: "Neoplasia maligna da orofaringe com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C109",
            descricao: "Neoplasia maligna da orofaringe, não especificada",
            favorito: false
        },
        {
            cid10: "C110",
            descricao: "Neoplasia maligna da parede superior da nasofaringe",
            favorito: false
        },
        {
            cid10: "C111",
            descricao: "Neoplasia maligna da parede posterior da nasofaringe",
            favorito: false
        },
        {
            cid10: "C112",
            descricao: "Neoplasia maligna da parede lateral da nasofaringe",
            favorito: false
        },
        {
            cid10: "C113",
            descricao: "Neoplasia maligna da parede anterior da nasofaringe",
            favorito: false
        },
        {
            cid10: "C118",
            descricao: "Neoplasia maligna da nasofaringe com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C119",
            descricao: "Neoplasia maligna da nasofaringe, não especificada",
            favorito: false
        },
        {
            cid10: "C12",
            descricao: "Neoplasia maligna do seio piriforme",
            favorito: false
        },
        {
            cid10: "C130",
            descricao: "Neoplasia maligna da região pós-cricóidea",
            favorito: false
        },
        {
            cid10: "C131",
            descricao: "Neoplasia maligna da prega ariepiglótica, face hipofaríngea",
            favorito: false
        },
        {
            cid10: "C132",
            descricao: "Neoplasia maligna da parede posterior da hipofaringe",
            favorito: false
        },
        {
            cid10: "C138",
            descricao: "Neoplasia maligna da hipofaringe com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C139",
            descricao: "Neoplasia maligna da hipofaringe, não especificada",
            favorito: false
        },
        {
            cid10: "C140",
            descricao: "Neoplasia maligna da faringe, não especificada",
            favorito: false
        },
        {
            cid10: "C142",
            descricao: "Neoplasia maligna do anel de Waldeyer",
            favorito: false
        },
        {
            cid10: "C148",
            descricao: "Neoplasia maligna do lábio, cavidade oral e faringe com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C150",
            descricao: "Neoplasia maligna da porção cervical do esôfago (esôfago cervical)",
            favorito: false
        },
        {
            cid10: "C151",
            descricao: "Neoplasia maligna da porção torácica do esôfago (esôfago torácico)",
            favorito: false
        },
        {
            cid10: "C152",
            descricao: "Neoplasia maligna da porção abdominal do esôfago (esôfago abdominal)",
            favorito: false
        },
        {
            cid10: "C153",
            descricao: "Neoplasia maligna do terço superior do esôfago",
            favorito: false
        },
        {
            cid10: "C154",
            descricao: "Neoplasia maligna do terço médio do esôfago",
            favorito: false
        },
        {
            cid10: "C155",
            descricao: "Neoplasia maligna do terço inferior do esôfago",
            favorito: false
        },
        {
            cid10: "C158",
            descricao: "Neoplasia maligna do esôfago com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C159",
            descricao: "Neoplasia maligna do esôfago, não especificado",
            favorito: false
        },
        {
            cid10: "C160",
            descricao: "Neoplasia maligna da cárdia",
            favorito: false
        },
        {
            cid10: "C161",
            descricao: "Neoplasia maligna do fundo do estômago",
            favorito: false
        },
        {
            cid10: "C162",
            descricao: "Neoplasia maligna do corpo do estômago",
            favorito: false
        },
        {
            cid10: "C163",
            descricao: "Neoplasia maligna do antro pilórico",
            favorito: false
        },
        {
            cid10: "C164",
            descricao: "Neoplasia maligna do piloro",
            favorito: false
        },
        {
            cid10: "C165",
            descricao: "Neoplasia maligna da pequena curvatura do estômago, não especificada",
            favorito: false
        },
        {
            cid10: "C166",
            descricao: "Neoplasia maligna da grande curvatura do estômago, não especificada",
            favorito: false
        },
        {
            cid10: "C168",
            descricao: "Neoplasia maligna do estômago com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C169",
            descricao: "Neoplasia maligna do estômago, não especificado",
            favorito: false
        },
        {
            cid10: "C170",
            descricao: "Neoplasia maligna do duodeno",
            favorito: false
        },
        {
            cid10: "C171",
            descricao: "Neoplasia maligna do jejuno",
            favorito: false
        },
        {
            cid10: "C172",
            descricao: "Neoplasia maligna do íleo",
            favorito: false
        },
        {
            cid10: "C173",
            descricao: "Neoplasia maligna do divertículo de Meckel",
            favorito: false
        },
        {
            cid10: "C178",
            descricao: "Neoplasia maligna do intestino delgado com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C179",
            descricao: "Neoplasia maligna do intestino delgado, não especificado",
            favorito: false
        },
        {
            cid10: "C180",
            descricao: "Neoplasia maligna do ceco",
            favorito: false
        },
        {
            cid10: "C181",
            descricao: "Neoplasia maligna do apêndice (vermiforme)",
            favorito: false
        },
        {
            cid10: "C182",
            descricao: "Neoplasia maligna do cólon ascendente",
            favorito: false
        },
        {
            cid10: "C183",
            descricao: "Neoplasia maligna da flexura (ângulo) hepática(o)",
            favorito: false
        },
        {
            cid10: "C184",
            descricao: "Neoplasia maligna do cólon transverso",
            favorito: false
        },
        {
            cid10: "C185",
            descricao: "Neoplasia maligna da flexura (ângulo) esplênica(o)",
            favorito: false
        },
        {
            cid10: "C186",
            descricao: "Neoplasia maligna do cólon descendente",
            favorito: false
        },
        {
            cid10: "C187",
            descricao: "Neoplasia maligna do cólon sigmóide",
            favorito: false
        },
        {
            cid10: "C188",
            descricao: "Neoplasia maligna do cólon com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C189",
            descricao: "Neoplasia maligna do cólon, não especificado",
            favorito: false
        },
        {
            cid10: "C19",
            descricao: "Neoplasia maligna da junção retossigmóide",
            favorito: false
        },
        {
            cid10: "C20",
            descricao: "Neoplasia maligna do reto",
            favorito: false
        },
        {
            cid10: "C210",
            descricao: "Neoplasia maligna do ânus, não especificado",
            favorito: false
        },
        {
            cid10: "C211",
            descricao: "Neoplasia maligna do canal anal",
            favorito: false
        },
        {
            cid10: "C212",
            descricao: "Neoplasia maligna da zona cloacogênica",
            favorito: false
        },
        {
            cid10: "C218",
            descricao: "Neoplasia maligna do reto, ânus e do canal anal com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C220",
            descricao: "Carcinoma de células hepáticas",
            favorito: false
        },
        {
            cid10: "C221",
            descricao: "Carcinoma de vias biliares intra-hepáticas",
            favorito: false
        },
        {
            cid10: "C222",
            descricao: "Hepatoblastoma",
            favorito: false
        },
        {
            cid10: "C223",
            descricao: "Angiossarcoma do fígado",
            favorito: false
        },
        {
            cid10: "C224",
            descricao: "Outros sarcomas do fígado",
            favorito: false
        },
        {
            cid10: "C227",
            descricao: "Outros carcinomas especificados do fígado",
            favorito: false
        },
        {
            cid10: "C229",
            descricao: "Neoplasia maligna do fígado, não especificada",
            favorito: false
        },
        {
            cid10: "C23",
            descricao: "Neoplasia maligna da vesícula biliar",
            favorito: false
        },
        {
            cid10: "C240",
            descricao: "Neoplasia maligna das vias biliares extra-hepáticas",
            favorito: false
        },
        {
            cid10: "C241",
            descricao: "Neoplasia maligna da ampola de Vater",
            favorito: false
        },
        {
            cid10: "C248",
            descricao: "Neoplasia maligna das vias biliares com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C249",
            descricao: "Neoplasia maligna da via biliar, não especificada",
            favorito: false
        },
        {
            cid10: "C250",
            descricao: "Neoplasia maligna da cabeça do pâncreas",
            favorito: false
        },
        {
            cid10: "C251",
            descricao: "Neoplasia maligna do corpo do pâncreas",
            favorito: false
        },
        {
            cid10: "C252",
            descricao: "Neoplasia maligna da cauda do pâncreas",
            favorito: false
        },
        {
            cid10: "C253",
            descricao: "Neoplasia maligna do canal pancreático",
            favorito: false
        },
        {
            cid10: "C254",
            descricao: "Neoplasia maligna do pâncreas endócrino",
            favorito: false
        },
        {
            cid10: "C257",
            descricao: "Neoplasia maligna de outras partes do pâncreas",
            favorito: false
        },
        {
            cid10: "C258",
            descricao: "Neoplasia maligna do pâncreas com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C259",
            descricao: "Neoplasia maligna do pâncreas, não especificado",
            favorito: false
        },
        {
            cid10: "C260",
            descricao: "Neoplasia maligna do trato intestinal, parte não especificada",
            favorito: false
        },
        {
            cid10: "C261",
            descricao: "Neoplasia maligna do baço",
            favorito: false
        },
        {
            cid10: "C268",
            descricao: "Neoplasia maligna do aparelho digestivo com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C269",
            descricao: "Neoplasia maligna de localizações mal definidas dentro do aparelho digestivo",
            favorito: false
        },
        {
            cid10: "C300",
            descricao: "Neoplasia maligna da cavidade nasal",
            favorito: false
        },
        {
            cid10: "C301",
            descricao: "Neoplasia maligna do ouvido médio",
            favorito: false
        },
        {
            cid10: "C310",
            descricao: "Neoplasia maligna do seio maxilar",
            favorito: false
        },
        {
            cid10: "C311",
            descricao: "Neoplasia maligna do seio etmoidal",
            favorito: false
        },
        {
            cid10: "C312",
            descricao: "Neoplasia maligna do seio frontal",
            favorito: false
        },
        {
            cid10: "C313",
            descricao: "Neoplasia maligna do seio esfenoidal",
            favorito: false
        },
        {
            cid10: "C318",
            descricao: "Neoplasia maligna dos seios da face com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C319",
            descricao: "Neoplasia maligna do seio da face, não especificado",
            favorito: false
        },
        {
            cid10: "C320",
            descricao: "Neoplasia maligna da glote",
            favorito: false
        },
        {
            cid10: "C321",
            descricao: "Neoplasia maligna da região supraglótica",
            favorito: false
        },
        {
            cid10: "C322",
            descricao: "Neoplasia maligna da região subglótica",
            favorito: false
        },
        {
            cid10: "C323",
            descricao: "Neoplasia maligna das cartilagens da laringe",
            favorito: false
        },
        {
            cid10: "C328",
            descricao: "Neoplasia maligna da laringe com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C329",
            descricao: "Neoplasia maligna da laringe, não especificada",
            favorito: false
        },
        {
            cid10: "C33",
            descricao: "Neoplasia maligna da traquéia",
            favorito: false
        },
        {
            cid10: "C340",
            descricao: "Neoplasia maligna do brônquio principal",
            favorito: false
        },
        {
            cid10: "C341",
            descricao: "Neoplasia maligna do lobo superior, brônquio ou pulmão",
            favorito: false
        },
        {
            cid10: "C342",
            descricao: "Neoplasia maligna do lobo médio, brônquio ou pulmão",
            favorito: false
        },
        {
            cid10: "C343",
            descricao: "Neoplasia maligna do lobo inferior, brônquio ou pulmão",
            favorito: false
        },
        {
            cid10: "C348",
            descricao: "Neoplasia maligna dos brônquios e dos pulmões com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C349",
            descricao: "Neoplasia maligna dos brônquios ou pulmões, não especificado",
            favorito: false
        },
        {
            cid10: "C37",
            descricao: "Neoplasia maligna do timo",
            favorito: false
        },
        {
            cid10: "C380",
            descricao: "Neoplasia maligna do coração",
            favorito: false
        },
        {
            cid10: "C381",
            descricao: "Neoplasia maligna do mediastino anterior",
            favorito: false
        },
        {
            cid10: "C382",
            descricao: "Neoplasia maligna do mediastino posterior",
            favorito: false
        },
        {
            cid10: "C383",
            descricao: "Neoplasia maligna do mediastino, porção não especificada",
            favorito: false
        },
        {
            cid10: "C384",
            descricao: "Neoplasia maligna da pleura",
            favorito: false
        },
        {
            cid10: "C388",
            descricao: "Neoplasia maligna do coração, mediastino e pleura com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C390",
            descricao: "Neoplasia maligna do trato respiratório superior, porção não especificada",
            favorito: false
        },
        {
            cid10: "C398",
            descricao: "Neoplasia maligna do aparelho respiratório e dos órgãos intratorácicos com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C399",
            descricao: "Neoplasia maligna de localizações mal definidas do aparelho respiratório",
            favorito: false
        },
        {
            cid10: "C400",
            descricao: "Neoplasia maligna da omoplata [escápula] e ossos longos dos membros superiores",
            favorito: false
        },
        {
            cid10: "C401",
            descricao: "Neoplasia maligna dos ossos curtos dos membros superiores",
            favorito: false
        },
        {
            cid10: "C402",
            descricao: "Neoplasia maligna dos ossos longos dos membros inferiores",
            favorito: false
        },
        {
            cid10: "C403",
            descricao: "Neoplasia maligna dos ossos curtos dos membros inferiores",
            favorito: false
        },
        {
            cid10: "C408",
            descricao: "Neoplasia maligna dos ossos e cartilagens articulares dos membros com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C409",
            descricao: "Neoplasia maligna dos ossos e cartilagens articulares de membro não especificado",
            favorito: false
        },
        {
            cid10: "C410",
            descricao: "Neoplasia maligna dos ossos do crânio e da face",
            favorito: false
        },
        {
            cid10: "C411",
            descricao: "Neoplasia maligna da mandíbula",
            favorito: false
        },
        {
            cid10: "C412",
            descricao: "Neoplasia maligna da coluna vertebral",
            favorito: false
        },
        {
            cid10: "C413",
            descricao: "Neoplasia maligna das costelas, esterno e clavícula",
            favorito: false
        },
        {
            cid10: "C414",
            descricao: "Neoplasia maligna dos ossos da pelve, sacro e cóccix",
            favorito: false
        },
        {
            cid10: "C418",
            descricao: "Neoplasia maligna dos ossos e das cartilagens articulares com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C419",
            descricao: "Neoplasia maligna dos ossos e cartilagens articulares, não especificados",
            favorito: false
        },
        {
            cid10: "C430",
            descricao: "Melanoma maligno do lábio",
            favorito: false
        },
        {
            cid10: "C431",
            descricao: "Melanoma maligno da pálpebra, incluindo as comissuras palpebrais",
            favorito: false
        },
        {
            cid10: "C432",
            descricao: "Melanoma maligno da orelha e do conduto auditivo externo",
            favorito: false
        },
        {
            cid10: "C433",
            descricao: "Melanoma maligno de outras partes e partes não especificadas da face",
            favorito: false
        },
        {
            cid10: "C434",
            descricao: "Melanoma maligno do couro cabeludo e do pescoço",
            favorito: false
        },
        {
            cid10: "C435",
            descricao: "Melanoma maligno do tronco",
            favorito: false
        },
        {
            cid10: "C436",
            descricao: "Melanoma maligno do membro superior, incluindo ombro",
            favorito: false
        },
        {
            cid10: "C437",
            descricao: "Melanoma maligno do membro inferior, incluindo quadril",
            favorito: false
        },
        {
            cid10: "C438",
            descricao: "Melanoma maligno invasivo da pele",
            favorito: false
        },
        {
            cid10: "C439",
            descricao: "Melanoma maligno de pele, não especificado",
            favorito: false
        },
        {
            cid10: "C440",
            descricao: "Neoplasia maligna da pele do lábio",
            favorito: false
        },
        {
            cid10: "C441",
            descricao: "Neoplasia maligna da pele da pálpebra, incluindo o canto",
            favorito: false
        },
        {
            cid10: "C442",
            descricao: "Neoplasia maligna da pele da orelha e do conduto auditivo externo",
            favorito: false
        },
        {
            cid10: "C443",
            descricao: "Neoplasia maligna da pele de outras partes e de partes não especificadas da face",
            favorito: false
        },
        {
            cid10: "C444",
            descricao: "Neoplasia maligna da pele do couro cabeludo e do pescoço",
            favorito: false
        },
        {
            cid10: "C445",
            descricao: "Neoplasia maligna da pele do tronco",
            favorito: false
        },
        {
            cid10: "C446",
            descricao: "Neoplasia maligna da pele do membro superior, incluindo ombro",
            favorito: false
        },
        {
            cid10: "C447",
            descricao: "Neoplasia maligna da pele do membro inferior, incluindo quadril",
            favorito: false
        },
        {
            cid10: "C448",
            descricao: "Neoplasia maligna da pele com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C449",
            descricao: "Neoplasia maligna da pele, não especificada",
            favorito: false
        },
        {
            cid10: "C450",
            descricao: "Mesotelioma da pleura",
            favorito: false
        },
        {
            cid10: "C451",
            descricao: "Mesotelioma do peritônio",
            favorito: false
        },
        {
            cid10: "C452",
            descricao: "Mesotelioma do pericárdio",
            favorito: false
        },
        {
            cid10: "C457",
            descricao: "Mesotelioma de outras localizações",
            favorito: false
        },
        {
            cid10: "C459",
            descricao: "Mesotelioma, não especificado",
            favorito: false
        },
        {
            cid10: "C460",
            descricao: "Sarcoma de Kaposi da pele",
            favorito: false
        },
        {
            cid10: "C461",
            descricao: "Sarcoma de Kaposi de tecidos moles",
            favorito: false
        },
        {
            cid10: "C462",
            descricao: "Sarcoma de Kaposi do palato",
            favorito: false
        },
        {
            cid10: "C463",
            descricao: "Sarcoma de Kaposi dos gânglios linfáticos",
            favorito: false
        },
        {
            cid10: "C467",
            descricao: "Sarcoma de Kaposi de outras localizações",
            favorito: false
        },
        {
            cid10: "C468",
            descricao: "Sarcoma de Kaposi de múltiplos órgãos",
            favorito: false
        },
        {
            cid10: "C469",
            descricao: "Sarcoma de Kaposi, não especificado",
            favorito: false
        },
        {
            cid10: "C470",
            descricao: "Neoplasia maligna dos nervos periféricos da cabeça, face e pescoço",
            favorito: false
        },
        {
            cid10: "C471",
            descricao: "Neoplasia maligna dos nervos periféricos dos membros superiores, incluindo ombro",
            favorito: false
        },
        {
            cid10: "C472",
            descricao: "Neoplasia maligna dos nervos periféricos dos membros inferiores, incluindo quadril",
            favorito: false
        },
        {
            cid10: "C473",
            descricao: "Neoplasia maligna dos nervos periféricos do tórax",
            favorito: false
        },
        {
            cid10: "C474",
            descricao: "Neoplasia maligna dos nervos periféricos do abdome",
            favorito: false
        },
        {
            cid10: "C475",
            descricao: "Neoplasia maligna dos nervos periféricos da pelve",
            favorito: false
        },
        {
            cid10: "C476",
            descricao: "Neoplasia maligna dos nervos periféricos do tronco",
            favorito: false
        },
        {
            cid10: "C478",
            descricao: "Neoplasia maligna dos nervos periféricos e do sistema nervoso autônomo com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C479",
            descricao: "Neoplasia maligna dos nervos periféricos e sistema nervoso autônomo, não especificados",
            favorito: false
        },
        {
            cid10: "C480",
            descricao: "Neoplasia maligna do retroperitônio",
            favorito: false
        },
        {
            cid10: "C481",
            descricao: "Neoplasia maligna de partes especificadas do peritônio",
            favorito: false
        },
        {
            cid10: "C482",
            descricao: "Neoplasia maligna do peritônio",
            favorito: false
        },
        {
            cid10: "C488",
            descricao: "Neoplasia maligna dos tecidos moles do retroperitônio e do peritônio com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C490",
            descricao: "Neoplasia maligna do tecido conjuntivo e tecidos moles da cabeça, face e pescoço",
            favorito: false
        },
        {
            cid10: "C491",
            descricao: "Neoplasia maligna do tecido conjuntivo e tecidos moles dos membros superiores, incluindo ombro",
            favorito: false
        },
        {
            cid10: "C492",
            descricao: "Neoplasia maligna do tecido conjuntivo e tecidos moles dos membros inferiores, incluindo quadril",
            favorito: false
        },
        {
            cid10: "C493",
            descricao: "Neoplasia maligna do tecido conjuntivo e tecidos moles do tórax",
            favorito: false
        },
        {
            cid10: "C494",
            descricao: "Neoplasia maligna do tecido conjuntivo e tecidos moles do abdome",
            favorito: false
        },
        {
            cid10: "C495",
            descricao: "Neoplasia maligna do tecido conjuntivo e tecidos moles da pelve",
            favorito: false
        },
        {
            cid10: "C496",
            descricao: "Neoplasia maligna do tecido conjuntivo e tecidos moles do tronco",
            favorito: false
        },
        {
            cid10: "C498",
            descricao: "Neoplasia maligna do tecido conjuntivo e dos tecidos moles com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C499",
            descricao: "Neoplasia maligna do tecido conjuntivo e tecidos moles, não especificados",
            favorito: false
        },
        {
            cid10: "C500",
            descricao: "Neoplasia maligna do mamilo e aréola",
            favorito: false
        },
        {
            cid10: "C501",
            descricao: "Neoplasia maligna da porção central da mama",
            favorito: false
        },
        {
            cid10: "C502",
            descricao: "Neoplasia maligna do quadrante superior interno da mama",
            favorito: false
        },
        {
            cid10: "C503",
            descricao: "Neoplasia maligna do quadrante inferior interno da mama",
            favorito: false
        },
        {
            cid10: "C504",
            descricao: "Neoplasia maligna do quadrante superior externo da mama",
            favorito: false
        },
        {
            cid10: "C505",
            descricao: "Neoplasia maligna do quadrante inferior externo da mama",
            favorito: false
        },
        {
            cid10: "C506",
            descricao: "Neoplasia maligna da porção axilar da mama",
            favorito: false
        },
        {
            cid10: "C508",
            descricao: "Neoplasia maligna da mama com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C509",
            descricao: "Neoplasia maligna da mama, não especificada",
            favorito: false
        },
        {
            cid10: "C510",
            descricao: "Neoplasia maligna dos grandes lábios",
            favorito: false
        },
        {
            cid10: "C511",
            descricao: "Neoplasia maligna dos pequenos lábios",
            favorito: false
        },
        {
            cid10: "C512",
            descricao: "Neoplasia maligna do clitóris",
            favorito: false
        },
        {
            cid10: "C518",
            descricao: "Neoplasia maligna da vulva com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C519",
            descricao: "Neoplasia maligna da vulva, não especificada",
            favorito: false
        },
        {
            cid10: "C52",
            descricao: "Neoplasia maligna da vagina",
            favorito: false
        },
        {
            cid10: "C530",
            descricao: "Neoplasia maligna do endocérvix",
            favorito: false
        },
        {
            cid10: "C531",
            descricao: "Neoplasia maligna do exocérvix",
            favorito: false
        },
        {
            cid10: "C538",
            descricao: "Neoplasia maligna do colo do útero com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C539",
            descricao: "Neoplasia maligna do colo do útero, não especificado",
            favorito: false
        },
        {
            cid10: "C540",
            descricao: "Neoplasia maligna do istmo do útero",
            favorito: false
        },
        {
            cid10: "C541",
            descricao: "Neoplasia maligna do endométrio",
            favorito: false
        },
        {
            cid10: "C542",
            descricao: "Neoplasia maligna do miométrio",
            favorito: false
        },
        {
            cid10: "C543",
            descricao: "Neoplasia maligna do fundo do útero",
            favorito: false
        },
        {
            cid10: "C548",
            descricao: "Neoplasia maligna do corpo do útero com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C549",
            descricao: "Neoplasia maligna do corpo do útero, não especificado",
            favorito: false
        },
        {
            cid10: "C55",
            descricao: "Neoplasia maligna do útero, porção não especificada",
            favorito: false
        },
        {
            cid10: "C56",
            descricao: "Neoplasia maligna do ovário",
            favorito: false
        },
        {
            cid10: "C570",
            descricao: "Neoplasia maligna da trompa de Falópio",
            favorito: false
        },
        {
            cid10: "C571",
            descricao: "Neoplasia maligna do ligamento largo",
            favorito: false
        },
        {
            cid10: "C572",
            descricao: "Neoplasia maligna do ligamento redondo",
            favorito: false
        },
        {
            cid10: "C573",
            descricao: "Neoplasia maligna do paramétrio",
            favorito: false
        },
        {
            cid10: "C574",
            descricao: "Neoplasia maligna dos anexos uterinos",
            favorito: false
        },
        {
            cid10: "C577",
            descricao: "Neoplasia maligna de outras partes especificadas dos órgãos genitais femininos",
            favorito: false
        },
        {
            cid10: "C578",
            descricao: "Neoplasia maligna dos órgãos genitais femininos com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C579",
            descricao: "Neoplasia maligna de órgão genital feminino, não especificado",
            favorito: false
        },
        {
            cid10: "C58",
            descricao: "Neoplasia maligna da placenta",
            favorito: false
        },
        {
            cid10: "C600",
            descricao: "Neoplasia maligna do prepúcio",
            favorito: false
        },
        {
            cid10: "C601",
            descricao: "Neoplasia maligna da glande",
            favorito: false
        },
        {
            cid10: "C602",
            descricao: "Neoplasia maligna do corpo do pênis",
            favorito: false
        },
        {
            cid10: "C608",
            descricao: "Neoplasia maligna do pênis com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C609",
            descricao: "Neoplasia maligna do pênis, não especificado",
            favorito: false
        },
        {
            cid10: "C61",
            descricao: "Neoplasia maligna da próstata",
            favorito: false
        },
        {
            cid10: "C620",
            descricao: "Neoplasia maligna do testículo criptorquídico",
            favorito: false
        },
        {
            cid10: "C621",
            descricao: "Neoplasia maligna do testículo tópico",
            favorito: false
        },
        {
            cid10: "C629",
            descricao: "Neoplasia maligna do testículo, sem outras especificações",
            favorito: false
        },
        {
            cid10: "C630",
            descricao: "Neoplasia maligna do epidídimo",
            favorito: false
        },
        {
            cid10: "C631",
            descricao: "Neoplasia maligna do cordão espermático",
            favorito: false
        },
        {
            cid10: "C632",
            descricao: "Neoplasia maligna do escroto",
            favorito: false
        },
        {
            cid10: "C637",
            descricao: "Neoplasia maligna de outros órgãos genitais masculinos especificados",
            favorito: false
        },
        {
            cid10: "C638",
            descricao: "Neoplasia maligna dos órgãos genitais masculinos com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C639",
            descricao: "Neoplasia maligna de órgão genital masculino, não especificado",
            favorito: false
        },
        {
            cid10: "C64",
            descricao: "Neoplasia maligna do rim, exceto pelve renal",
            favorito: false
        },
        {
            cid10: "C65",
            descricao: "Neoplasia maligna da pelve renal",
            favorito: false
        },
        {
            cid10: "C66",
            descricao: "Neoplasia maligna dos ureteres",
            favorito: false
        },
        {
            cid10: "C670",
            descricao: "Neoplasia maligna do trígono da bexiga",
            favorito: false
        },
        {
            cid10: "C671",
            descricao: "Neoplasia maligna da cúpula da bexiga",
            favorito: false
        },
        {
            cid10: "C672",
            descricao: "Neoplasia maligna da parede lateral da bexiga",
            favorito: false
        },
        {
            cid10: "C673",
            descricao: "Neoplasia maligna da parede anterior da bexiga",
            favorito: false
        },
        {
            cid10: "C674",
            descricao: "Neoplasia maligna da parede posterior da bexiga",
            favorito: false
        },
        {
            cid10: "C675",
            descricao: "Neoplasia maligna do colo da bexiga",
            favorito: false
        },
        {
            cid10: "C676",
            descricao: "Neoplasia maligna do orifício uretérico",
            favorito: false
        },
        {
            cid10: "C677",
            descricao: "Neoplasia maligna do úraco",
            favorito: false
        },
        {
            cid10: "C678",
            descricao: "Neoplasia maligna da bexiga com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C679",
            descricao: "Neoplasia maligna da bexiga, sem outra especificações",
            favorito: false
        },
        {
            cid10: "C680",
            descricao: "Neoplasia maligna da uretra",
            favorito: false
        },
        {
            cid10: "C681",
            descricao: "Neoplasia maligna da glândula parauretral",
            favorito: false
        },
        {
            cid10: "C688",
            descricao: "Neoplasia maligna dos órgãos urinários com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C689",
            descricao: "Neoplasia maligna de órgão urinário, não especificado",
            favorito: false
        },
        {
            cid10: "C690",
            descricao: "Neoplasia maligna da conjuntiva",
            favorito: false
        },
        {
            cid10: "C691",
            descricao: "Neoplasia maligna da córnea",
            favorito: false
        },
        {
            cid10: "C692",
            descricao: "Neoplasia maligna da retina",
            favorito: false
        },
        {
            cid10: "C693",
            descricao: "Neoplasia maligna da coróide",
            favorito: false
        },
        {
            cid10: "C694",
            descricao: "Neoplasia maligna do corpo ciliar",
            favorito: false
        },
        {
            cid10: "C695",
            descricao: "Neoplasia maligna da glândula e canal lacrimal",
            favorito: false
        },
        {
            cid10: "C696",
            descricao: "Neoplasia maligna da órbita",
            favorito: false
        },
        {
            cid10: "C698",
            descricao: "Neoplasia maligna do olho e anexos com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C699",
            descricao: "Neoplasia maligna do olho, não especificado",
            favorito: false
        },
        {
            cid10: "C700",
            descricao: "Neoplasia maligna das meninges cerebrais",
            favorito: false
        },
        {
            cid10: "C701",
            descricao: "Neoplasia maligna das meninges espinhais",
            favorito: false
        },
        {
            cid10: "C709",
            descricao: "Neoplasia maligna da meninge, não especificada",
            favorito: false
        },
        {
            cid10: "C710",
            descricao: "Neoplasia maligna do cérebro, exceto lobos e ventrículos",
            favorito: false
        },
        {
            cid10: "C711",
            descricao: "Neoplasia maligna do lobo frontal",
            favorito: false
        },
        {
            cid10: "C712",
            descricao: "Neoplasia maligna do lobo temporal",
            favorito: false
        },
        {
            cid10: "C713",
            descricao: "Neoplasia maligna do lobo parietal",
            favorito: false
        },
        {
            cid10: "C714",
            descricao: "Neoplasia maligna do lobo occipital",
            favorito: false
        },
        {
            cid10: "C715",
            descricao: "Neoplasia maligna do ventrículo cerebral",
            favorito: false
        },
        {
            cid10: "C716",
            descricao: "Neoplasia maligna do cerebelo",
            favorito: false
        },
        {
            cid10: "C717",
            descricao: "Neoplasia maligna do tronco cerebral",
            favorito: false
        },
        {
            cid10: "C718",
            descricao: "Neoplasia maligna do encéfalo com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C719",
            descricao: "Neoplasia maligna do encéfalo, não especificado",
            favorito: false
        },
        {
            cid10: "C720",
            descricao: "Neoplasia maligna da medula espinhal",
            favorito: false
        },
        {
            cid10: "C721",
            descricao: "Neoplasia maligna da cauda eqüina",
            favorito: false
        },
        {
            cid10: "C722",
            descricao: "Neoplasia maligna do nervo olfativo",
            favorito: false
        },
        {
            cid10: "C723",
            descricao: "Neoplasia maligna do nervo óptico",
            favorito: false
        },
        {
            cid10: "C724",
            descricao: "Neoplasia maligna do nervo acústico",
            favorito: false
        },
        {
            cid10: "C725",
            descricao: "Neoplasia maligna de outros nervos cranianos e os não especificados",
            favorito: false
        },
        {
            cid10: "C728",
            descricao: "Neoplasia maligna do encéfalo e de outras partes do sistema nervoso central com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C729",
            descricao: "Neoplasia maligna do sistema nervoso central, não especificado",
            favorito: false
        },
        {
            cid10: "C73",
            descricao: "Neoplasia maligna da glândula tireóide",
            favorito: false
        },
        {
            cid10: "C740",
            descricao: "Neoplasia maligna do córtex da supra-renal",
            favorito: false
        },
        {
            cid10: "C741",
            descricao: "Neoplasia maligna da medula da supra-renal",
            favorito: false
        },
        {
            cid10: "C749",
            descricao: "Neoplasia maligna da glândula supra-renal, não especificada",
            favorito: false
        },
        {
            cid10: "C750",
            descricao: "Neoplasia maligna da glândula paratireóide",
            favorito: false
        },
        {
            cid10: "C751",
            descricao: "Neoplasia maligna da glândula hipófise (pituitária)",
            favorito: false
        },
        {
            cid10: "C752",
            descricao: "Neoplasia maligna do conduto craniofaríngeo",
            favorito: false
        },
        {
            cid10: "C753",
            descricao: "Neoplasia maligna da glândula pineal",
            favorito: false
        },
        {
            cid10: "C754",
            descricao: "Neoplasia maligna do corpo carotídeo",
            favorito: false
        },
        {
            cid10: "C755",
            descricao: "Neoplasia maligna do corpo aórtico e outros paragânglios",
            favorito: false
        },
        {
            cid10: "C758",
            descricao: "Neoplasia maligna com comprometimento pluriglandular, sem outra especificação",
            favorito: false
        },
        {
            cid10: "C759",
            descricao: "Neoplasia maligna de glândula endócrina, não especificada",
            favorito: false
        },
        {
            cid10: "C760",
            descricao: "Neoplasia maligna da cabeça, face e pescoço",
            favorito: false
        },
        {
            cid10: "C761",
            descricao: "Neoplasia maligna do tórax",
            favorito: false
        },
        {
            cid10: "C762",
            descricao: "Neoplasia maligna do abdome",
            favorito: false
        },
        {
            cid10: "C763",
            descricao: "Neoplasia maligna da pelve",
            favorito: false
        },
        {
            cid10: "C764",
            descricao: "Neoplasia maligna do membro superior",
            favorito: false
        },
        {
            cid10: "C765",
            descricao: "Neoplasia maligna do membro inferior",
            favorito: false
        },
        {
            cid10: "C767",
            descricao: "Neoplasia maligna de outras localizações mal definidas",
            favorito: false
        },
        {
            cid10: "C768",
            descricao: "Neoplasia maligna de outras localizações e das mal definidas com lesão invasiva",
            favorito: false
        },
        {
            cid10: "C770",
            descricao: "Neoplasia maligna secundária e não especificada dos gânglios linfáticos da cabeça, face e pescoço",
            favorito: false
        },
        {
            cid10: "C771",
            descricao: "Neoplasia maligna secundária e não especificada dos gânglios linfáticos intratorácicos",
            favorito: false
        },
        {
            cid10: "C772",
            descricao: "Neoplasia maligna secundária e não especificada dos gânglios linfáticos intra-abdominais",
            favorito: false
        },
        {
            cid10: "C773",
            descricao: "Neoplasia maligna secundária e não especificada dos gânglios linfáticos axilares e dos membros superiores",
            favorito: false
        },
        {
            cid10: "C774",
            descricao: "Neoplasia maligna secundária e não especificada dos gânglios linfáticos inguinais e dos membros inferiores",
            favorito: false
        },
        {
            cid10: "C775",
            descricao: "Neoplasia maligna secundária e não especificada dos gânglios linfáticos intrapélvicos",
            favorito: false
        },
        {
            cid10: "C778",
            descricao: "Neoplasia maligna secundária e não especificada dos gânglios linfáticos de múltiplas regiões",
            favorito: false
        },
        {
            cid10: "C779",
            descricao: "Neoplasia maligna secundária e não especificada de gânglio linfático, não especificado",
            favorito: false
        },
        {
            cid10: "C780",
            descricao: "Neoplasia maligna secundária dos pulmões",
            favorito: false
        },
        {
            cid10: "C781",
            descricao: "Neoplasia maligna secundária do mediastino",
            favorito: false
        },
        {
            cid10: "C782",
            descricao: "Neoplasia maligna secundária da pleura",
            favorito: false
        },
        {
            cid10: "C783",
            descricao: "Neoplasia maligna secundária de outros órgãos respiratórios e não especificados",
            favorito: false
        },
        {
            cid10: "C784",
            descricao: "Neoplasia maligna secundária do intestino delgado",
            favorito: false
        },
        {
            cid10: "C785",
            descricao: "Neoplasia maligna secundária do intestino grosso e do reto",
            favorito: false
        },
        {
            cid10: "C786",
            descricao: "Neoplasia maligna secundária do retroperitônio e do peritônio",
            favorito: false
        },
        {
            cid10: "C787",
            descricao: "Neoplasia maligna secundária do fígado",
            favorito: false
        },
        {
            cid10: "C788",
            descricao: "Neoplasia maligna secundária de outros órgãos digestivos e não especificados",
            favorito: false
        },
        {
            cid10: "C790",
            descricao: "Neoplasia maligna secundária do rim e da pelve renal",
            favorito: false
        },
        {
            cid10: "C791",
            descricao: "Neoplasia maligna secundária da bexiga, de outro órgão urinário e não especificado",
            favorito: false
        },
        {
            cid10: "C792",
            descricao: "Neoplasia maligna secundária da pele",
            favorito: false
        },
        {
            cid10: "C793",
            descricao: "Neoplasia maligna secundária do encéfalo e das meninges cerebrais",
            favorito: false
        },
        {
            cid10: "C794",
            descricao: "Neoplasia maligna secundária de outras partes do sistema nervoso e não especificadas",
            favorito: false
        },
        {
            cid10: "C795",
            descricao: "Neoplasia maligna secundária dos ossos e da medula óssea",
            favorito: false
        },
        {
            cid10: "C796",
            descricao: "Neoplasia maligna secundária do ovário",
            favorito: false
        },
        {
            cid10: "C797",
            descricao: "Neoplasia maligna secundária das glândulas supra-renais (adrenais)",
            favorito: false
        },
        {
            cid10: "C798",
            descricao: "Neoplasia maligna secundária de outra localização especificada",
            favorito: false
        },
        {
            cid10: "C80",
            descricao: "Neoplasia maligna, sem especificação de localização",
            favorito: false
        },
        {
            cid10: "C810",
            descricao: "Doença de Hodgkin, predominância linfocítica",
            favorito: false
        },
        {
            cid10: "C811",
            descricao: "Doença de Hodgkin, esclerose nodular",
            favorito: false
        },
        {
            cid10: "C812",
            descricao: "Doença de Hodgkin, celularidade mista",
            favorito: false
        },
        {
            cid10: "C813",
            descricao: "Doença de Hodgkin, depleção linfocítica",
            favorito: false
        },
        {
            cid10: "C817",
            descricao: "Outra forma da doença de Hodgkin",
            favorito: false
        },
        {
            cid10: "C819",
            descricao: "Doença de Hodgkin, não especificada",
            favorito: false
        },
        {
            cid10: "C820",
            descricao: "Linfoma não-Hodgkin, pequenas células clivadas, folicular",
            favorito: false
        },
        {
            cid10: "C821",
            descricao: "Linfoma não-Hodgkin, misto, de pequenas e grandes células clivadas, folicular",
            favorito: false
        },
        {
            cid10: "C822",
            descricao: "Linfoma não-Hodgkin, grandes células, folicular",
            favorito: false
        },
        {
            cid10: "C827",
            descricao: "Outros tipos de linfoma não-Hodgkin, folicular",
            favorito: false
        },
        {
            cid10: "C829",
            descricao: "Linfoma não-Hodgkin, folicular, não especificado",
            favorito: false
        },
        {
            cid10: "C830",
            descricao: "Linfoma não-Hodgkin difuso, pequenas células (difuso)",
            favorito: false
        },
        {
            cid10: "C831",
            descricao: "Linfoma não-Hodgkin difuso, pequenas células clivadas (difuso)",
            favorito: false
        },
        {
            cid10: "C832",
            descricao: "Linfoma não-Hodgkin difuso, misto, de pequenas e grandes células (difuso)",
            favorito: false
        },
        {
            cid10: "C833",
            descricao: "Linfoma não-Hodgkin difuso, grandes células (difuso)",
            favorito: false
        },
        {
            cid10: "C834",
            descricao: "Linfoma não-Hodgkin difuso, imunoblástico (difuso)",
            favorito: false
        },
        {
            cid10: "C835",
            descricao: "Linfoma não-Hodgkin difuso, linfoblástico (difuso)",
            favorito: false
        },
        {
            cid10: "C836",
            descricao: "Linfoma não-Hodgkin difuso, indiferenciado (difuso)",
            favorito: false
        },
        {
            cid10: "C837",
            descricao: "Tumor de Burkitt",
            favorito: false
        },
        {
            cid10: "C838",
            descricao: "Outros tipos de linfoma não-Hodgkin difuso",
            favorito: false
        },
        {
            cid10: "C839",
            descricao: "Linfoma não-Hodgkin difuso, não especificado",
            favorito: false
        },
        {
            cid10: "C840",
            descricao: "Micose fungóide",
            favorito: false
        },
        {
            cid10: "C841",
            descricao: "Doença de Sézary",
            favorito: false
        },
        {
            cid10: "C842",
            descricao: "Linfoma da zona T",
            favorito: false
        },
        {
            cid10: "C843",
            descricao: "Linfoma linfoepitelióide",
            favorito: false
        },
        {
            cid10: "C844",
            descricao: "Linfoma de células T, periférico",
            favorito: false
        },
        {
            cid10: "C845",
            descricao: "Outros linfomas de células T e os não especificados",
            favorito: false
        },
        {
            cid10: "C850",
            descricao: "Linfossarcoma",
            favorito: false
        },
        {
            cid10: "C851",
            descricao: "Linfoma de células B, não especificado",
            favorito: false
        },
        {
            cid10: "C857",
            descricao: "Outros tipos especificados de linfoma não-Hodgkin",
            favorito: false
        },
        {
            cid10: "C859",
            descricao: "Linfoma não-Hodgkin de tipo não especificado",
            favorito: false
        },
        {
            cid10: "C880",
            descricao: "Macroglobulinemia de Waldenström",
            favorito: false
        },
        {
            cid10: "C881",
            descricao: "Doença de cadeia pesada alfa",
            favorito: false
        },
        {
            cid10: "C882",
            descricao: "Doença de cadeia pesada gama",
            favorito: false
        },
        {
            cid10: "C883",
            descricao: "Doença imunoproliferativa do intestino delgado",
            favorito: false
        },
        {
            cid10: "C887",
            descricao: "Outras doenças imunoproliferativas malignas",
            favorito: false
        },
        {
            cid10: "C889",
            descricao: "Doença imunoproliferativa maligna, não especificada",
            favorito: false
        },
        {
            cid10: "C900",
            descricao: "Mieloma múltiplo",
            favorito: false
        },
        {
            cid10: "C901",
            descricao: "Leucemia plasmocitária",
            favorito: false
        },
        {
            cid10: "C902",
            descricao: "Plasmocitoma extramedular",
            favorito: false
        },
        {
            cid10: "C910",
            descricao: "Leucemia linfoblástica aguda",
            favorito: false
        },
        {
            cid10: "C911",
            descricao: "Leucemia linfocítica crônica",
            favorito: false
        },
        {
            cid10: "C912",
            descricao: "Leucemia linfocítica subaguda",
            favorito: false
        },
        {
            cid10: "C913",
            descricao: "Leucemia pró-linfocítica",
            favorito: false
        },
        {
            cid10: "C914",
            descricao: "Leucemia de células pilosas",
            favorito: false
        },
        {
            cid10: "C915",
            descricao: "Leucemia de células T do adulto",
            favorito: false
        },
        {
            cid10: "C917",
            descricao: "Outras leucemias linfóides",
            favorito: false
        },
        {
            cid10: "C919",
            descricao: "Leucemia linfóide, não especificada",
            favorito: false
        },
        {
            cid10: "C920",
            descricao: "Leucemia mielóide aguda",
            favorito: false
        },
        {
            cid10: "C921",
            descricao: "Leucemia mielóide crônica",
            favorito: false
        },
        {
            cid10: "C922",
            descricao: "Leucemia mielóide subaguda",
            favorito: false
        },
        {
            cid10: "C923",
            descricao: "Sarcoma mielóide",
            favorito: false
        },
        {
            cid10: "C924",
            descricao: "Leucemia pró-mielocítica aguda",
            favorito: false
        },
        {
            cid10: "C925",
            descricao: "Leucemia mielomonocítica aguda",
            favorito: false
        },
        {
            cid10: "C927",
            descricao: "Outras leucemias mielóides",
            favorito: false
        },
        {
            cid10: "C929",
            descricao: "Leucemia mielóide, não especificada",
            favorito: false
        },
        {
            cid10: "C930",
            descricao: "Leucemia monocítica aguda",
            favorito: false
        },
        {
            cid10: "C931",
            descricao: "Leucemia monocítica crônica",
            favorito: false
        },
        {
            cid10: "C932",
            descricao: "Leucemia monocítica subaguda",
            favorito: false
        },
        {
            cid10: "C937",
            descricao: "Outras leucemias monocíticas",
            favorito: false
        },
        {
            cid10: "C939",
            descricao: "Leucemia monocítica, não especificada",
            favorito: false
        },
        {
            cid10: "C940",
            descricao: "Eritremia e eritroleucemia agudas",
            favorito: false
        },
        {
            cid10: "C941",
            descricao: "Eritremia crônica",
            favorito: false
        },
        {
            cid10: "C942",
            descricao: "Leucemia megacarioblástica aguda",
            favorito: false
        },
        {
            cid10: "C943",
            descricao: "Leucemia de mastócitos",
            favorito: false
        },
        {
            cid10: "C944",
            descricao: "Panmielose aguda",
            favorito: false
        },
        {
            cid10: "C945",
            descricao: "Mielofibrose aguda",
            favorito: false
        },
        {
            cid10: "C947",
            descricao: "Outras leucemias especificadas",
            favorito: false
        },
        {
            cid10: "C950",
            descricao: "Leucemia aguda de tipo celular não especificado",
            favorito: false
        },
        {
            cid10: "C951",
            descricao: "Leucemia crônica de tipo celular não especificado",
            favorito: false
        },
        {
            cid10: "C952",
            descricao: "Leucemia subaguda de tipo celular não especificado",
            favorito: false
        },
        {
            cid10: "C957",
            descricao: "Outras leucemias de tipo celular não especificado",
            favorito: false
        },
        {
            cid10: "C959",
            descricao: "Leucemia não especificada",
            favorito: false
        },
        {
            cid10: "C960",
            descricao: "Doença de Letterer-Siwe",
            favorito: false
        },
        {
            cid10: "C961",
            descricao: "Histiocitose maligna",
            favorito: false
        },
        {
            cid10: "C962",
            descricao: "Tumor maligno de mastócitos",
            favorito: false
        },
        {
            cid10: "C963",
            descricao: "Linfoma histiocítico verdadeiro",
            favorito: false
        },
        {
            cid10: "C967",
            descricao: "Outras neoplasias malignas especificadas dos tecidos linfático, hematopoético e tecidos correlatos",
            favorito: false
        },
        {
            cid10: "C969",
            descricao: "Neoplasia maligna dos tecidos linfático, hematopoético e tecidos correlatos, não especificada",
            favorito: false
        },
        {
            cid10: "C97",
            descricao: "Neoplasias malignas de localizações múltiplas independentes (primárias)",
            favorito: false
        },
        {
            cid10: "D000",
            descricao: "Carcinoma in situ dos lábios, cavidade oral e faringe",
            favorito: false
        },
        {
            cid10: "D001",
            descricao: "Carcinoma in situ do esôfago",
            favorito: false
        },
        {
            cid10: "D002",
            descricao: "Carcinoma in situ do estômago",
            favorito: false
        },
        {
            cid10: "D010",
            descricao: "Carcinoma in situ do cólon",
            favorito: false
        },
        {
            cid10: "D011",
            descricao: "Carcinoma in situ da junção retossigmóide",
            favorito: false
        },
        {
            cid10: "D012",
            descricao: "Carcinoma in situ do reto",
            favorito: false
        },
        {
            cid10: "D013",
            descricao: "Carcinoma in situ do ânus e canal anal",
            favorito: false
        },
        {
            cid10: "D014",
            descricao: "Carcinoma in situ de outras partes do intestino e as não especificadas",
            favorito: false
        },
        {
            cid10: "D015",
            descricao: "Carcinoma in situ do fígado, vesícula biliar e vias biliares",
            favorito: false
        },
        {
            cid10: "D017",
            descricao: "Carcinoma in situ de outros órgãos especificados do aparelho digestivo",
            favorito: false
        },
        {
            cid10: "D019",
            descricao: "Carcinoma in situ de órgãos digestivos, não especificado",
            favorito: false
        },
        {
            cid10: "D020",
            descricao: "Carcinoma in situ da laringe",
            favorito: false
        },
        {
            cid10: "D021",
            descricao: "Carcinoma in situ da traquéia",
            favorito: false
        },
        {
            cid10: "D022",
            descricao: "Carcinoma in situ dos brônquios e pulmões",
            favorito: false
        },
        {
            cid10: "D023",
            descricao: "Carcinoma in situ de outras partes do aparelho respiratório",
            favorito: false
        },
        {
            cid10: "D029",
            descricao: "Carcinoma in situ do aparelho respiratório, não especificado",
            favorito: false
        },
        {
            cid10: "D030",
            descricao: "Melanoma in situ do lábio",
            favorito: false
        },
        {
            cid10: "D031",
            descricao: "Melanoma in situ da pálpebra, incluindo o canto",
            favorito: false
        },
        {
            cid10: "D032",
            descricao: "Melanoma in situ da orelha e do conduto auditivo externo",
            favorito: false
        },
        {
            cid10: "D033",
            descricao: "Melanoma in situ de outras partes, e de partes não especificadas da face",
            favorito: false
        },
        {
            cid10: "D034",
            descricao: "Melanoma in situ do couro cabeludo e do pescoço",
            favorito: false
        },
        {
            cid10: "D035",
            descricao: "Melanoma in situ do tronco",
            favorito: false
        },
        {
            cid10: "D036",
            descricao: "Melanoma in situ dos membros superiores, incluindo ombro",
            favorito: false
        },
        {
            cid10: "D037",
            descricao: "Melanoma in situ dos membros inferiores, incluindo quadril",
            favorito: false
        },
        {
            cid10: "D038",
            descricao: "Melanoma in situ de outras localizações",
            favorito: false
        },
        {
            cid10: "D039",
            descricao: "Melanoma in situ, não especificado",
            favorito: false
        },
        {
            cid10: "D040",
            descricao: "Carcinoma in situ da pele do lábio",
            favorito: false
        },
        {
            cid10: "D041",
            descricao: "Carcinoma in situ da pele da pálpebra, incluindo o canto",
            favorito: false
        },
        {
            cid10: "D042",
            descricao: "Carcinoma in situ da pele da orelha e do conduto auditivo externo",
            favorito: false
        },
        {
            cid10: "D043",
            descricao: "Carcinoma in situ da pele de outras partes e de partes não especificadas da face",
            favorito: false
        },
        {
            cid10: "D044",
            descricao: "Carcinoma in situ da pele do couro cabeludo e do pescoço",
            favorito: false
        },
        {
            cid10: "D045",
            descricao: "Carcinoma in situ da pele do tronco",
            favorito: false
        },
        {
            cid10: "D046",
            descricao: "Carcinoma in situ da pele dos membros superiores, incluindo ombro",
            favorito: false
        },
        {
            cid10: "D047",
            descricao: "Carcinoma in situ da pele dos membros inferiores, incluindo quadril",
            favorito: false
        },
        {
            cid10: "D048",
            descricao: "Carcinoma in situ da pele de outras localizações",
            favorito: false
        },
        {
            cid10: "D049",
            descricao: "Carcinoma in situ da pele, não especificada",
            favorito: false
        },
        {
            cid10: "D050",
            descricao: "Carcinoma lobular in situ",
            favorito: false
        },
        {
            cid10: "D051",
            descricao: "Carcinoma intraductal in situ",
            favorito: false
        },
        {
            cid10: "D057",
            descricao: "Outros carcinomas in situ",
            favorito: false
        },
        {
            cid10: "D059",
            descricao: "Carcinoma in situ da mama, não especificado",
            favorito: false
        },
        {
            cid10: "D060",
            descricao: "Carcinoma in situ do endocérvix",
            favorito: false
        },
        {
            cid10: "D061",
            descricao: "Carcinoma in situ do exocérvix",
            favorito: false
        },
        {
            cid10: "D067",
            descricao: "Carcinoma in situ de outras partes do colo do útero",
            favorito: false
        },
        {
            cid10: "D069",
            descricao: "Carcinoma in situ do colo do útero, não especificado",
            favorito: false
        },
        {
            cid10: "D070",
            descricao: "Carcinoma in situ do endométrio",
            favorito: false
        },
        {
            cid10: "D071",
            descricao: "Carcinoma in situ da vulva",
            favorito: false
        },
        {
            cid10: "D072",
            descricao: "Carcinoma in situ da vagina",
            favorito: false
        },
        {
            cid10: "D073",
            descricao: "Carcinoma in situ de outros órgãos genitais femininos e os não especificados",
            favorito: false
        },
        {
            cid10: "D074",
            descricao: "Carcinoma in situ do pênis",
            favorito: false
        },
        {
            cid10: "D075",
            descricao: "Carcinoma in situ da próstata",
            favorito: false
        },
        {
            cid10: "D076",
            descricao: "Carcinoma in situ de outros órgãos genitais masculinos e os não especificados",
            favorito: false
        },
        {
            cid10: "D090",
            descricao: "Carcinoma in situ da bexiga",
            favorito: false
        },
        {
            cid10: "D091",
            descricao: "Carcinoma in situ de outros órgãos urinários e os não especificados",
            favorito: false
        },
        {
            cid10: "D092",
            descricao: "Carcinoma in situ do olho",
            favorito: false
        },
        {
            cid10: "D093",
            descricao: "Carcinoma in situ da tireóide e de outras glândulas endócrinas",
            favorito: false
        },
        {
            cid10: "D097",
            descricao: "Carcinoma in situ de outras localizações especificadas",
            favorito: false
        },
        {
            cid10: "D099",
            descricao: "Carcinoma in situ, não especificado",
            favorito: false
        },
        {
            cid10: "D100",
            descricao: "Neoplasia benigna dos lábios",
            favorito: false
        },
        {
            cid10: "D101",
            descricao: "Neoplasia benigna da língua",
            favorito: false
        },
        {
            cid10: "D102",
            descricao: "Neoplasia benigna do assoalho da boca",
            favorito: false
        },
        {
            cid10: "D103",
            descricao: "Neoplasia benigna de outras partes da boca e as não especificadas",
            favorito: false
        },
        {
            cid10: "D104",
            descricao: "Neoplasia benigna da amígdala",
            favorito: false
        },
        {
            cid10: "D105",
            descricao: "Neoplasia benigna de outras partes da orofaringe",
            favorito: false
        },
        {
            cid10: "D106",
            descricao: "Neoplasia benigna da nasofaringe",
            favorito: false
        },
        {
            cid10: "D107",
            descricao: "Neoplasia benigna da hipofaringe",
            favorito: false
        },
        {
            cid10: "D109",
            descricao: "Neoplasia benigna da faringe, não especificada",
            favorito: false
        },
        {
            cid10: "D110",
            descricao: "Neoplasia benigna da glândula parótida",
            favorito: false
        },
        {
            cid10: "D117",
            descricao: "Neoplasia benigna de outras glândulas salivares maiores",
            favorito: false
        },
        {
            cid10: "D119",
            descricao: "Neoplasia benigna da glândula salivar maior, não especificada",
            favorito: false
        },
        {
            cid10: "D120",
            descricao: "Neoplasia benigna do ceco",
            favorito: false
        },
        {
            cid10: "D121",
            descricao: "Neoplasia benigna do apêndice (vermiforme)",
            favorito: false
        },
        {
            cid10: "D122",
            descricao: "Neoplasia benigna do cólon ascendente",
            favorito: false
        },
        {
            cid10: "D123",
            descricao: "Neoplasia benigna do cólon transverso",
            favorito: false
        },
        {
            cid10: "D124",
            descricao: "Neoplasia benigna do cólon descendente",
            favorito: false
        },
        {
            cid10: "D125",
            descricao: "Neoplasia benigna do cólon sigmóide",
            favorito: false
        },
        {
            cid10: "D126",
            descricao: "Neoplasia benigna do cólon, não especificada",
            favorito: false
        },
        {
            cid10: "D127",
            descricao: "Neoplasia benigna da junção retossigmóide",
            favorito: false
        },
        {
            cid10: "D128",
            descricao: "Neoplasia benigna do reto",
            favorito: false
        },
        {
            cid10: "D129",
            descricao: "Neoplasia benigna do canal anal e ânus",
            favorito: false
        },
        {
            cid10: "D130",
            descricao: "Neoplasia benigna do esôfago",
            favorito: false
        },
        {
            cid10: "D131",
            descricao: "Neoplasia benigna do estômago",
            favorito: false
        },
        {
            cid10: "D132",
            descricao: "Neoplasia benigna do duodeno",
            favorito: false
        },
        {
            cid10: "D133",
            descricao: "Neoplasia benigna de outras partes e partes não especificadas do intestino delgado",
            favorito: false
        },
        {
            cid10: "D134",
            descricao: "Neoplasia benigna do fígado",
            favorito: false
        },
        {
            cid10: "D135",
            descricao: "Neoplasia benigna das vias biliares extra-hepáticas",
            favorito: false
        },
        {
            cid10: "D136",
            descricao: "Neoplasia benigna do pâncreas",
            favorito: false
        },
        {
            cid10: "D137",
            descricao: "Neoplasia benigna do pâncreas endócrino",
            favorito: false
        },
        {
            cid10: "D139",
            descricao: "Neoplasia benigna de localizações mal definidas do aparelho digestivo",
            favorito: false
        },
        {
            cid10: "D140",
            descricao: "Neoplasia benigna do ouvido médio, cavidade nasal e seios paranasais",
            favorito: false
        },
        {
            cid10: "D141",
            descricao: "Neoplasia benigna da laringe",
            favorito: false
        },
        {
            cid10: "D142",
            descricao: "Neoplasia benigna da traquéia",
            favorito: false
        },
        {
            cid10: "D143",
            descricao: "Neoplasia benigna dos brônquios e pulmão",
            favorito: false
        },
        {
            cid10: "D144",
            descricao: "Neoplasia benigna do aparelho respiratório, não especificado",
            favorito: false
        },
        {
            cid10: "D150",
            descricao: "Neoplasia benigna do timo",
            favorito: false
        },
        {
            cid10: "D151",
            descricao: "Neoplasia benigna do coração",
            favorito: false
        },
        {
            cid10: "D152",
            descricao: "Neoplasia benigna do mediastino",
            favorito: false
        },
        {
            cid10: "D157",
            descricao: "Neoplasia benigna de outros órgãos intratorácicos especificados",
            favorito: false
        },
        {
            cid10: "D159",
            descricao: "Neoplasia benigna de órgão intratorácico, não especificado",
            favorito: false
        },
        {
            cid10: "D160",
            descricao: "Neoplasia benigna da omoplata [escápula] e ossos longos dos membros superiores",
            favorito: false
        },
        {
            cid10: "D161",
            descricao: "Neoplasia benigna dos ossos curtos dos membros superiores",
            favorito: false
        },
        {
            cid10: "D162",
            descricao: "Neoplasia benigna dos ossos longos dos membros inferiores",
            favorito: false
        },
        {
            cid10: "D163",
            descricao: "Neoplasia benigna dos ossos curtos dos membros inferiores",
            favorito: false
        },
        {
            cid10: "D164",
            descricao: "Neoplasia benigna dos ossos do crânio e da face",
            favorito: false
        },
        {
            cid10: "D165",
            descricao: "Neoplasia benigna do osso da mandíbula",
            favorito: false
        },
        {
            cid10: "D166",
            descricao: "Neoplasia benigna da coluna vertebral",
            favorito: false
        },
        {
            cid10: "D167",
            descricao: "Neoplasia benigna das costelas, do esterno e da clavícula",
            favorito: false
        },
        {
            cid10: "D168",
            descricao: "Neoplasia benigna dos ossos pélvicos, sacro e cóccix",
            favorito: false
        },
        {
            cid10: "D169",
            descricao: "Neoplasia benigna do osso e cartilagem articular, não especificado",
            favorito: false
        },
        {
            cid10: "D170",
            descricao: "Neoplasia lipomatosa benigna da pele e do tecido subcutâneo da cabeça, face e pescoço",
            favorito: false
        },
        {
            cid10: "D171",
            descricao: "Neoplasia lipomatosa benigna da pele e tecido subcutâneo do tronco",
            favorito: false
        },
        {
            cid10: "D172",
            descricao: "Neoplasia lipomatosa benigna da pele e tecido subcutâneo dos membros",
            favorito: false
        },
        {
            cid10: "D173",
            descricao: "Neoplasia lipomatosa benigna da pele e tecido subcutâneo de outras localizações e de localizações não especificadas",
            favorito: false
        },
        {
            cid10: "D174",
            descricao: "Neoplasia lipomatosa benigna de órgãos intratorácicos",
            favorito: false
        },
        {
            cid10: "D175",
            descricao: "Neoplasia lipomatosa benigna de órgãos intra-abdominais",
            favorito: false
        },
        {
            cid10: "D176",
            descricao: "Neoplasia lipomatosa benigna do cordão espermático",
            favorito: false
        },
        {
            cid10: "D177",
            descricao: "Neoplasia lipomatosa benigna de outras localizações",
            favorito: false
        },
        {
            cid10: "D179",
            descricao: "Neoplasia lipomatosa benigna de localização não especificada",
            favorito: false
        },
        {
            cid10: "D180",
            descricao: "Hemangioma de qualquer localização",
            favorito: false
        },
        {
            cid10: "D181",
            descricao: "Linfangioma de qualquer localização",
            favorito: false
        },
        {
            cid10: "D190",
            descricao: "Neoplasia benigna do tecido mesotelial da pleura",
            favorito: false
        },
        {
            cid10: "D191",
            descricao: "Neoplasia benigna do tecido mesotelial do peritônio",
            favorito: false
        },
        {
            cid10: "D197",
            descricao: "Neoplasia benigna do tecido mesotelial de outras localizações",
            favorito: false
        },
        {
            cid10: "D199",
            descricao: "Neoplasia benigna do tecido mesotelial, não especificado",
            favorito: false
        },
        {
            cid10: "D200",
            descricao: "Neoplasia benigna do retroperitônio",
            favorito: false
        },
        {
            cid10: "D201",
            descricao: "Neoplasia benigna do peritônio",
            favorito: false
        },
        {
            cid10: "D210",
            descricao: "Neoplasia benigna do tecido conjuntivo e outros tecidos moles da cabeça, face e pescoço",
            favorito: false
        },
        {
            cid10: "D211",
            descricao: "Neoplasia benigna do tecido conjuntivo e outros tecidos moles dos membros superiores, incluindo ombro",
            favorito: false
        },
        {
            cid10: "D212",
            descricao: "Neoplasia benigna do tecido conjuntivo e outros tecidos moles dos membros inferiores, incluindo quadril",
            favorito: false
        },
        {
            cid10: "D213",
            descricao: "Neoplasia benigna do tecido conjuntivo e outros tecidos moles do tórax",
            favorito: false
        },
        {
            cid10: "D214",
            descricao: "Neoplasia benigna do tecido conjuntivo e outros tecidos moles do abdome",
            favorito: false
        },
        {
            cid10: "D215",
            descricao: "Neoplasia benigna do tecido conjuntivo e outros tecidos moles da pelve",
            favorito: false
        },
        {
            cid10: "D216",
            descricao: "Neoplasia benigna do tecido conjuntivo e outros tecidos moles do tronco, não especificado",
            favorito: false
        },
        {
            cid10: "D219",
            descricao: "Neoplasia benigna do tecido conjuntivo e outros tecidos moles, sem outra especificação",
            favorito: false
        },
        {
            cid10: "D220",
            descricao: "Nevo melanocítico do lábio",
            favorito: false
        },
        {
            cid10: "D221",
            descricao: "Nevo melanocítico da pálpebra, incluindo o canto",
            favorito: false
        },
        {
            cid10: "D222",
            descricao: "Nevo melanocítico da orelha e do conduto auditivo externo",
            favorito: false
        },
        {
            cid10: "D223",
            descricao: "Nevo melanocítico de outras partes e de partes não especificadas da face",
            favorito: false
        },
        {
            cid10: "D224",
            descricao: "Nevo melanocítico do couro cabeludo e do pescoço",
            favorito: false
        },
        {
            cid10: "D225",
            descricao: "Nevo melanocítico do tronco",
            favorito: false
        },
        {
            cid10: "D226",
            descricao: "Nevo melanocítico dos membros superiores, incluindo ombro",
            favorito: false
        },
        {
            cid10: "D227",
            descricao: "Nevo melanocítico dos membros inferiores, incluindo quadril",
            favorito: false
        },
        {
            cid10: "D229",
            descricao: "Nevo melanocítico, não especificado",
            favorito: false
        },
        {
            cid10: "D230",
            descricao: "Neoplasia benigna da pele dos lábios",
            favorito: false
        },
        {
            cid10: "D231",
            descricao: "Neoplasia benigna da pele da pálpebra, incluindo o canto",
            favorito: false
        },
        {
            cid10: "D232",
            descricao: "Neoplasia benigna da pele da orelha e do conduto auditivo externo",
            favorito: false
        },
        {
            cid10: "D233",
            descricao: "Neoplasia benigna da pele de outras partes e de partes não especificadas da face",
            favorito: false
        },
        {
            cid10: "D234",
            descricao: "Neoplasia benigna da pele do couro cabeludo e do pescoço",
            favorito: false
        },
        {
            cid10: "D235",
            descricao: "Neoplasia benigna da pele do tronco",
            favorito: false
        },
        {
            cid10: "D236",
            descricao: "Neoplasia benigna da pele dos membros superiores, incluindo o ombro",
            favorito: false
        },
        {
            cid10: "D237",
            descricao: "Neoplasia benigna da pele dos membros inferiores, incluindo o quadril",
            favorito: false
        },
        {
            cid10: "D239",
            descricao: "Neoplasia benigna da pele, não especificada",
            favorito: false
        },
        {
            cid10: "D24",
            descricao: "Neoplasia benigna da mama",
            favorito: false
        },
        {
            cid10: "D250",
            descricao: "Leiomioma submucoso do útero",
            favorito: false
        },
        {
            cid10: "D251",
            descricao: "Leiomioma intramural do útero",
            favorito: false
        },
        {
            cid10: "D252",
            descricao: "Leiomioma subseroso do útero",
            favorito: false
        },
        {
            cid10: "D259",
            descricao: "Leiomioma do útero, não especificado",
            favorito: false
        },
        {
            cid10: "D260",
            descricao: "Neoplasia benigna do colo do útero",
            favorito: false
        },
        {
            cid10: "D261",
            descricao: "Neoplasia benigna do corpo do útero",
            favorito: false
        },
        {
            cid10: "D267",
            descricao: "Neoplasia benigna de outras partes do útero",
            favorito: false
        },
        {
            cid10: "D269",
            descricao: "Neoplasia benigna do útero, não especificado",
            favorito: false
        },
        {
            cid10: "D27",
            descricao: "Neoplasia benigna do ovário",
            favorito: false
        },
        {
            cid10: "D280",
            descricao: "Neoplasia benigna da vulva",
            favorito: false
        },
        {
            cid10: "D281",
            descricao: "Neoplasia benigna da vagina",
            favorito: false
        },
        {
            cid10: "D282",
            descricao: "Neoplasia benigna das trompas e ligamentos uterinos",
            favorito: false
        },
        {
            cid10: "D287",
            descricao: "Neoplasia benigna de outros órgãos genitais femininos especificados",
            favorito: false
        },
        {
            cid10: "D289",
            descricao: "Neoplasia benigna de órgão genital feminino, não especificado",
            favorito: false
        },
        {
            cid10: "D290",
            descricao: "Neoplasia benigna do pênis",
            favorito: false
        },
        {
            cid10: "D291",
            descricao: "Neoplasia benigna da próstata",
            favorito: false
        },
        {
            cid10: "D292",
            descricao: "Neoplasia benigna dos testículos",
            favorito: false
        },
        {
            cid10: "D293",
            descricao: "Neoplasia benigna do epidídimo",
            favorito: false
        },
        {
            cid10: "D294",
            descricao: "Neoplasia benigna do escroto",
            favorito: false
        },
        {
            cid10: "D297",
            descricao: "Neoplasia benigna de outros órgãos genitais masculinos",
            favorito: false
        },
        {
            cid10: "D299",
            descricao: "Neoplasia benigna de órgão genital masculino, não especificado",
            favorito: false
        },
        {
            cid10: "D300",
            descricao: "Neoplasia benigna do rim",
            favorito: false
        },
        {
            cid10: "D301",
            descricao: "Neoplasia benigna da pelve renal",
            favorito: false
        },
        {
            cid10: "D302",
            descricao: "Neoplasia benigna do ureter",
            favorito: false
        },
        {
            cid10: "D303",
            descricao: "Neoplasia benigna da bexiga",
            favorito: false
        },
        {
            cid10: "D304",
            descricao: "Neoplasia benigna da uretra",
            favorito: false
        },
        {
            cid10: "D307",
            descricao: "Neoplasia benigna de outros órgãos urinários",
            favorito: false
        },
        {
            cid10: "D309",
            descricao: "Neoplasia benigna de órgão urinário, não especificado",
            favorito: false
        },
        {
            cid10: "D310",
            descricao: "Neoplasia benigna da conjuntiva",
            favorito: false
        },
        {
            cid10: "D311",
            descricao: "Neoplasia benigna da córnea",
            favorito: false
        },
        {
            cid10: "D312",
            descricao: "Neoplasia benigna da retina",
            favorito: false
        },
        {
            cid10: "D313",
            descricao: "Neoplasia benigna da coróide",
            favorito: false
        },
        {
            cid10: "D314",
            descricao: "Neoplasia benigna do corpo ciliar",
            favorito: false
        },
        {
            cid10: "D315",
            descricao: "Neoplasia benigna das glândulas e dos canais lacrimais",
            favorito: false
        },
        {
            cid10: "D316",
            descricao: "Neoplasia benigna da órbita, não especificada",
            favorito: false
        },
        {
            cid10: "D319",
            descricao: "Neoplasia benigna do olho, não especificado",
            favorito: false
        },
        {
            cid10: "D320",
            descricao: "Neoplasia benigna das meninges cerebrais",
            favorito: false
        },
        {
            cid10: "D321",
            descricao: "Neoplasia benigna das meninges espinhais",
            favorito: false
        },
        {
            cid10: "D329",
            descricao: "Neoplasia benigna das meninges, não especificada",
            favorito: false
        },
        {
            cid10: "D330",
            descricao: "Neoplasia benigna do encéfalo, supratentorial",
            favorito: false
        },
        {
            cid10: "D331",
            descricao: "Neoplasia benigna do encéfalo, infratentorial",
            favorito: false
        },
        {
            cid10: "D332",
            descricao: "Neoplasia benigna do encéfalo, não especificado",
            favorito: false
        },
        {
            cid10: "D333",
            descricao: "Neoplasia benigna dos nervos cranianos",
            favorito: false
        },
        {
            cid10: "D334",
            descricao: "Neoplasia benigna da medula espinhal",
            favorito: false
        },
        {
            cid10: "D337",
            descricao: "Neoplasia benigna de outras partes especificadas do sistema nervoso central",
            favorito: false
        },
        {
            cid10: "D339",
            descricao: "Neoplasia benigna do sistema nervoso central, não especificado",
            favorito: false
        },
        {
            cid10: "D34",
            descricao: "Neoplasia benigna da glândula tireóide",
            favorito: false
        },
        {
            cid10: "D350",
            descricao: "Neoplasia benigna da glândula supra-renal (adrenal)",
            favorito: false
        },
        {
            cid10: "D351",
            descricao: "Neoplasia benigna da glândula paratireóide",
            favorito: false
        },
        {
            cid10: "D352",
            descricao: "Neoplasia benigna da glândula hipófise (pituitária)",
            favorito: false
        },
        {
            cid10: "D353",
            descricao: "Neoplasia benigna do conduto craniofaríngeo",
            favorito: false
        },
        {
            cid10: "D354",
            descricao: "Neoplasia benigna da glândula pineal",
            favorito: false
        },
        {
            cid10: "D355",
            descricao: "Neoplasia benigna do corpo carotídeo",
            favorito: false
        },
        {
            cid10: "D356",
            descricao: "Neoplasia benigna dos corpos aórticos e outros paragânglios",
            favorito: false
        },
        {
            cid10: "D357",
            descricao: "Neoplasia benigna de outras glândulas endócrinas especificadas",
            favorito: false
        },
        {
            cid10: "D358",
            descricao: "Neoplasia benigna com comprometimento pluriglandular",
            favorito: false
        },
        {
            cid10: "D359",
            descricao: "Neoplasia benigna de glândula endócrina, não especificada",
            favorito: false
        },
        {
            cid10: "D360",
            descricao: "Neoplasia benigna dos gânglios linfáticos (linfonodos)",
            favorito: false
        },
        {
            cid10: "D361",
            descricao: "Neoplasia benigna dos nervos periféricos e sistema nervoso autônomo",
            favorito: false
        },
        {
            cid10: "D367",
            descricao: "Neoplasia benigna de outras localizações especificadas",
            favorito: false
        },
        {
            cid10: "D369",
            descricao: "Neoplasia benigna de localização não especificada",
            favorito: false
        },
        {
            cid10: "D370",
            descricao: "Neoplasia de comportamento incerto ou desconhecido do lábio, cavidade oral e faringe",
            favorito: false
        },
        {
            cid10: "D371",
            descricao: "Neoplasia de comportamento incerto ou desconhecido do estômago",
            favorito: false
        },
        {
            cid10: "D372",
            descricao: "Neoplasia de comportamento incerto ou desconhecido do intestino delgado",
            favorito: false
        },
        {
            cid10: "D373",
            descricao: "Neoplasia de comportamento incerto ou desconhecido do apêndice",
            favorito: false
        },
        {
            cid10: "D374",
            descricao: "Neoplasia de comportamento incerto ou desconhecido dos cólons",
            favorito: false
        },
        {
            cid10: "D375",
            descricao: "Neoplasia de comportamento incerto ou desconhecido do reto",
            favorito: false
        },
        {
            cid10: "D376",
            descricao: "Neoplasia de comportamento incerto ou desconhecido do fígado, vesícula biliar e vias biliares",
            favorito: false
        },
        {
            cid10: "D377",
            descricao: "Neoplasia de comportamento incerto ou desconhecido de outros órgãos digestivos",
            favorito: false
        },
        {
            cid10: "D379",
            descricao: "Neoplasia de comportamento incerto ou desconhecido de órgão digestivo, não especificado",
            favorito: false
        },
        {
            cid10: "D380",
            descricao: "Neoplasia de comportamento incerto ou desconhecido da laringe",
            favorito: false
        },
        {
            cid10: "D381",
            descricao: "Neoplasia de comportamento incerto ou desconhecido da traquéia, brônquios e pulmão",
            favorito: false
        },
        {
            cid10: "D382",
            descricao: "Neoplasia de comportamento incerto ou desconhecido da pleura",
            favorito: false
        },
        {
            cid10: "D383",
            descricao: "Neoplasia de comportamento incerto ou desconhecido do mediastino",
            favorito: false
        },
        {
            cid10: "D384",
            descricao: "Neoplasia de comportamento incerto ou desconhecido do timo",
            favorito: false
        },
        {
            cid10: "D385",
            descricao: "Neoplasia de comportamento incerto ou desconhecido de outros órgãos respiratórios",
            favorito: false
        },
        {
            cid10: "D386",
            descricao: "Neoplasia de comportamento incerto ou desconhecido de órgão respiratório, não especificado",
            favorito: false
        },
        {
            cid10: "D390",
            descricao: "Neoplasia de comportamento incerto ou desconhecido do útero",
            favorito: false
        },
        {
            cid10: "D391",
            descricao: "Neoplasia de comportamento incerto ou desconhecido do ovário",
            favorito: false
        },
        {
            cid10: "D392",
            descricao: "Neoplasia de comportamento incerto ou desconhecido da placenta",
            favorito: false
        },
        {
            cid10: "D397",
            descricao: "Neoplasia de comportamento incerto ou desconhecido de outros órgãos genitais femininos",
            favorito: false
        },
        {
            cid10: "D399",
            descricao: "Neoplasia de comportamento incerto ou desconhecido de órgão genital feminino, não especificado",
            favorito: false
        },
        {
            cid10: "D400",
            descricao: "Neoplasia de comportamento incerto ou desconhecido da próstata",
            favorito: false
        },
        {
            cid10: "D401",
            descricao: "Neoplasia de comportamento incerto ou desconhecido do testículo",
            favorito: false
        },
        {
            cid10: "D407",
            descricao: "Neoplasia de comportamento incerto ou desconhecido de outros órgãos genitais masculinos",
            favorito: false
        },
        {
            cid10: "D409",
            descricao: "Neoplasia de comportamento incerto ou desconhecido de órgão genital masculino, não especificado",
            favorito: false
        },
        {
            cid10: "D410",
            descricao: "Neoplasia de comportamento incerto ou desconhecido do rim",
            favorito: false
        },
        {
            cid10: "D411",
            descricao: "Neoplasia de comportamento incerto ou desconhecido da pelve renal",
            favorito: false
        },
        {
            cid10: "D412",
            descricao: "Neoplasia de comportamento incerto ou desconhecido do ureter",
            favorito: false
        },
        {
            cid10: "D413",
            descricao: "Neoplasia de comportamento incerto ou desconhecido da uretra",
            favorito: false
        },
        {
            cid10: "D414",
            descricao: "Neoplasia de comportamento incerto ou desconhecido da bexiga",
            favorito: false
        },
        {
            cid10: "D417",
            descricao: "Neoplasia de comportamento incerto ou desconhecido de outros órgãos urinários",
            favorito: false
        },
        {
            cid10: "D419",
            descricao: "Neoplasia de comportamento incerto ou desconhecido de órgão urinário, não especificado",
            favorito: false
        },
        {
            cid10: "D420",
            descricao: "Neoplasia de comportamento incerto ou desconhecido das meninges cerebrais",
            favorito: false
        },
        {
            cid10: "D421",
            descricao: "Neoplasia de comportamento incerto ou desconhecido das meninges espinhais",
            favorito: false
        },
        {
            cid10: "D429",
            descricao: "Neoplasia de comportamento incerto ou desconhecido de meninges não especificadas",
            favorito: false
        },
        {
            cid10: "D430",
            descricao: "Neoplasia de comportamento incerto ou desconhecido do encéfalo, supratentorial",
            favorito: false
        },
        {
            cid10: "D431",
            descricao: "Neoplasia de comportamento incerto ou desconhecido do encéfalo, infratentorial",
            favorito: false
        },
        {
            cid10: "D432",
            descricao: "Neoplasia de comportamento incerto ou desconhecido do encéfalo, não especificado",
            favorito: false
        },
        {
            cid10: "D433",
            descricao: "Neoplasia de comportamento incerto ou desconhecido dos nervos cranianos",
            favorito: false
        },
        {
            cid10: "D434",
            descricao: "Neoplasia de comportamento incerto ou desconhecido da medula espinhal",
            favorito: false
        },
        {
            cid10: "D437",
            descricao: "Neoplasia de comportamento incerto ou desconhecido de outras partes do sistema nervoso central",
            favorito: false
        },
        {
            cid10: "D439",
            descricao: "Neoplasia de comportamento incerto ou desconhecido do sistema nervoso central, não especificado",
            favorito: false
        },
        {
            cid10: "D440",
            descricao: "Neoplasia de comportamento incerto ou desconhecido da glândula tireóide",
            favorito: false
        },
        {
            cid10: "D441",
            descricao: "Neoplasia de comportamento incerto ou desconhecido da glândula supra-renal (adrenal)",
            favorito: false
        },
        {
            cid10: "D442",
            descricao: "Neoplasia de comportamento incerto ou desconhecido da glândula paratireóide",
            favorito: false
        },
        {
            cid10: "D443",
            descricao: "Neoplasia de comportamento incerto ou desconhecido da glândula hipófise (pituitária)",
            favorito: false
        },
        {
            cid10: "D444",
            descricao: "Neoplasia de comportamento incerto ou desconhecido do conduto craniofaríngeo",
            favorito: false
        },
        {
            cid10: "D445",
            descricao: "Neoplasia de comportamento incerto ou desconhecido da glândula pineal",
            favorito: false
        },
        {
            cid10: "D446",
            descricao: "Neoplasia de comportamento incerto ou desconhecido do corpo carotídeo",
            favorito: false
        },
        {
            cid10: "D447",
            descricao: "Neoplasia de comportamento incerto ou desconhecido dos corpos aórticos e outros paragânglios",
            favorito: false
        },
        {
            cid10: "D448",
            descricao: "Neoplasia de comportamento incerto ou desconhecido com comprometimento pluriglandular",
            favorito: false
        },
        {
            cid10: "D449",
            descricao: "Neoplasia de comportamento incerto ou desconhecido de glândula endócrina, não especificada",
            favorito: false
        },
        {
            cid10: "D45",
            descricao: "Policitemia vera",
            favorito: false
        },
        {
            cid10: "D460",
            descricao: "Anemia refratária sem sideroblastos",
            favorito: false
        },
        {
            cid10: "D461",
            descricao: "Anemia refratária com sideroblastos",
            favorito: false
        },
        {
            cid10: "D462",
            descricao: "Anemia refratária com excesso de blastos",
            favorito: false
        },
        {
            cid10: "D463",
            descricao: "Anemia refratária com excesso de blastos com transformação",
            favorito: false
        },
        {
            cid10: "D464",
            descricao: "Anemia refratária, não especificada",
            favorito: false
        },
        {
            cid10: "D467",
            descricao: "Outras síndromes mielodisplásicas",
            favorito: false
        },
        {
            cid10: "D469",
            descricao: "Síndrome mielodisplásica, não especificada",
            favorito: false
        },
        {
            cid10: "D470",
            descricao: "Tumores de comportamento incerto ou desconhecido de mastócitos e células histiocíticas",
            favorito: false
        },
        {
            cid10: "D471",
            descricao: "Doença mieloproliferativa crônica",
            favorito: false
        },
        {
            cid10: "D472",
            descricao: "Gamopatia monoclonal",
            favorito: false
        },
        {
            cid10: "D473",
            descricao: "Trombocitemia essencial (hemorrágica)",
            favorito: false
        },
        {
            cid10: "D477",
            descricao: "Outras neoplasias especificadas de comportamento incerto ou desconhecido dos tecidos linfático, hematopoético e tecidos relacionados",
            favorito: false
        },
        {
            cid10: "D479",
            descricao: "Neoplasia de comportamento incerto ou desconhecido dos tecidos linfático, hematopoético e tecidos correlatos, não especificada",
            favorito: false
        },
        {
            cid10: "D480",
            descricao: "Neoplasia de comportamento incerto ou desconhecido dos ossos e cartilagens articulares",
            favorito: false
        },
        {
            cid10: "D481",
            descricao: "Neoplasia de comportamento incerto ou desconhecido do tecido conjuntivo e outros tecidos moles",
            favorito: false
        },
        {
            cid10: "D482",
            descricao: "Neoplasia de comportamento incerto ou desconhecido dos nervos periféricos e do sistema nervoso periférico",
            favorito: false
        },
        {
            cid10: "D483",
            descricao: "Neoplasia de comportamento incerto ou desconhecido do retroperitônio",
            favorito: false
        },
        {
            cid10: "D484",
            descricao: "Neoplasia de comportamento incerto ou desconhecido do peritônio",
            favorito: false
        },
        {
            cid10: "D485",
            descricao: "Neoplasia de comportamento incerto ou desconhecido da pele",
            favorito: false
        },
        {
            cid10: "D486",
            descricao: "Neoplasia de comportamento incerto ou desconhecido da mama",
            favorito: false
        },
        {
            cid10: "D487",
            descricao: "Neoplasia de comportamento incerto ou desconhecido de outras localizações especificadas",
            favorito: false
        },
        {
            cid10: "D489",
            descricao: "Neoplasia de comportamento incerto ou desconhecido sem outra especificação",
            favorito: false
        },
        {
            cid10: "D500",
            descricao: "Anemia por deficiência de ferro secundária à perda de sangue (crônica)",
            favorito: false
        },
        {
            cid10: "D501",
            descricao: "Disfagia sideropênica",
            favorito: false
        },
        {
            cid10: "D508",
            descricao: "Outras anemias por deficiência de ferro",
            favorito: false
        },
        {
            cid10: "D509",
            descricao: "Anemia por deficiência de ferro não especificada",
            favorito: false
        },
        {
            cid10: "D510",
            descricao: "Anemia por deficiência de vitamina B12 devida à deficiência de fator intrínseco",
            favorito: false
        },
        {
            cid10: "D511",
            descricao: "Anemia por deficiência de vitamina B12 devida à má-absorção seletiva de vitamina B12 com proteinúria",
            favorito: false
        },
        {
            cid10: "D512",
            descricao: "Deficiência de transcobalamina II",
            favorito: false
        },
        {
            cid10: "D513",
            descricao: "Outras anemias por deficiência de vitamina B12 na dieta",
            favorito: false
        },
        {
            cid10: "D518",
            descricao: "Outras anemias por deficiência de vitamina B12",
            favorito: false
        },
        {
            cid10: "D519",
            descricao: "Anemia por deficiência de vitamina B12 não especificada",
            favorito: false
        },
        {
            cid10: "D520",
            descricao: "Anemia por deficiência de folato na dieta",
            favorito: false
        },
        {
            cid10: "D521",
            descricao: "Anemia por deficiência de folato induzida por drogas",
            favorito: false
        },
        {
            cid10: "D528",
            descricao: "Outras anemias por deficiência de folato",
            favorito: false
        },
        {
            cid10: "D529",
            descricao: "Anemia por deficiência de folato não especificada",
            favorito: false
        },
        {
            cid10: "D530",
            descricao: "Anemia por deficiência de proteínas",
            favorito: false
        },
        {
            cid10: "D531",
            descricao: "Outras anemias megaloblásticas não classificadas em outras partes",
            favorito: false
        },
        {
            cid10: "D532",
            descricao: "Anemia escorbútica",
            favorito: false
        },
        {
            cid10: "D538",
            descricao: "Outras anemias nutricionais especificadas",
            favorito: false
        },
        {
            cid10: "D539",
            descricao: "Anemia nutricional não especificada",
            favorito: false
        },
        {
            cid10: "D550",
            descricao: "Anemia devida à deficiência de glicose-6-fosfato-desidrogenase [G-6-PD]",
            favorito: false
        },
        {
            cid10: "D551",
            descricao: "Anemia devida a outros transtornos do metabolismo do glutation",
            favorito: false
        },
        {
            cid10: "D552",
            descricao: "Anemia devida a transtornos das enzimas glicolíticas",
            favorito: false
        },
        {
            cid10: "D553",
            descricao: "Anemia devida a transtornos do metabolismo de nucleotídios",
            favorito: false
        },
        {
            cid10: "D558",
            descricao: "Outras anemias devidas a transtornos enzimáticos",
            favorito: false
        },
        {
            cid10: "D559",
            descricao: "Anemia devida a transtorno enzimático não especificada",
            favorito: false
        },
        {
            cid10: "D560",
            descricao: "Talassemia alfa",
            favorito: false
        },
        {
            cid10: "D561",
            descricao: "Talassemia beta",
            favorito: false
        },
        {
            cid10: "D562",
            descricao: "Talassemia delta-beta",
            favorito: false
        },
        {
            cid10: "D563",
            descricao: "Estigma talassêmico",
            favorito: false
        },
        {
            cid10: "D564",
            descricao: "Persistência hereditária de hemoglobina fetal",
            favorito: false
        },
        {
            cid10: "D568",
            descricao: "Outras talassemias",
            favorito: false
        },
        {
            cid10: "D569",
            descricao: "Talassemia não especificada",
            favorito: false
        },
        {
            cid10: "D570",
            descricao: "Anemia falciforme com crise",
            favorito: false
        },
        {
            cid10: "D571",
            descricao: "Anemia falciforme sem crise",
            favorito: false
        },
        {
            cid10: "D572",
            descricao: "Transtornos falciformes heterozigóticos duplos",
            favorito: false
        },
        {
            cid10: "D573",
            descricao: "Estigma falciforme",
            favorito: false
        },
        {
            cid10: "D578",
            descricao: "Outros transtornos falciformes",
            favorito: false
        },
        {
            cid10: "D580",
            descricao: "Esferocitose hereditária",
            favorito: false
        },
        {
            cid10: "D581",
            descricao: "Eliptocitose hereditária",
            favorito: false
        },
        {
            cid10: "D582",
            descricao: "Outras hemoglobinopatias",
            favorito: false
        },
        {
            cid10: "D588",
            descricao: "Outras anemias hemolíticas hereditárias especificadas",
            favorito: false
        },
        {
            cid10: "D589",
            descricao: "Anemia hemolítica hereditária não especificada",
            favorito: false
        },
        {
            cid10: "D590",
            descricao: "Anemia hemolítica auto-imune induzida por droga",
            favorito: false
        },
        {
            cid10: "D591",
            descricao: "Outras anemias hemolíticas auto-imunes",
            favorito: false
        },
        {
            cid10: "D592",
            descricao: "Anemia hemolítica não-auto-imune induzida por drogas",
            favorito: false
        },
        {
            cid10: "D593",
            descricao: "Síndrome hemolítico-urêmica",
            favorito: false
        },
        {
            cid10: "D594",
            descricao: "Outras anemias hemolíticas não-autoimunes",
            favorito: false
        },
        {
            cid10: "D595",
            descricao: "Hemoglobinúria paroxística noturna [Marchiafava-Micheli]",
            favorito: false
        },
        {
            cid10: "D596",
            descricao: "Hemoglobinúria devida à hemólise por outras causas externas",
            favorito: false
        },
        {
            cid10: "D598",
            descricao: "Outras anemias hemolíticas adquiridas",
            favorito: false
        },
        {
            cid10: "D599",
            descricao: "Anemia hemolítica adquirida não especificada",
            favorito: false
        },
        {
            cid10: "D600",
            descricao: "Aplasia pura adquirida crônica da série vermelha",
            favorito: false
        },
        {
            cid10: "D601",
            descricao: "Aplasia pura adquirida transitória da série vermelha",
            favorito: false
        },
        {
            cid10: "D608",
            descricao: "Outras aplasias puras adquiridas da série vermelha",
            favorito: false
        },
        {
            cid10: "D609",
            descricao: "Aplasia pura adquirida, não especificada, da série vermelha",
            favorito: false
        },
        {
            cid10: "D610",
            descricao: "Anemia aplástica constitucional",
            favorito: false
        },
        {
            cid10: "D611",
            descricao: "Anemia aplástica induzida por drogas",
            favorito: false
        },
        {
            cid10: "D612",
            descricao: "Anemia aplástica devida a outros agentes externos",
            favorito: false
        },
        {
            cid10: "D613",
            descricao: "Anemia aplástica idiopática",
            favorito: false
        },
        {
            cid10: "D618",
            descricao: "Outras anemias aplásticas especificadas",
            favorito: false
        },
        {
            cid10: "D619",
            descricao: "Anemia aplástica não especificada",
            favorito: false
        },
        {
            cid10: "D62",
            descricao: "Anemia aguda pós-hemorrágica",
            favorito: false
        },
        {
            cid10: "D630",
            descricao: "Anemia em neoplasias",
            favorito: false
        },
        {
            cid10: "D638",
            descricao: "Anemia em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "D640",
            descricao: "Anemia sideroblástica hereditária",
            favorito: false
        },
        {
            cid10: "D641",
            descricao: "Anemia sideroblástica secundária a doença",
            favorito: false
        },
        {
            cid10: "D642",
            descricao: "Anemia sideroblástica secundária ao uso de drogas e a toxinas",
            favorito: false
        },
        {
            cid10: "D643",
            descricao: "Outras anemias sideroblásticas",
            favorito: false
        },
        {
            cid10: "D644",
            descricao: "Anemia diseritropoética congênita",
            favorito: false
        },
        {
            cid10: "D648",
            descricao: "Outras anemias especificadas",
            favorito: false
        },
        {
            cid10: "D649",
            descricao: "Anemia não especificada",
            favorito: false
        },
        {
            cid10: "D65",
            descricao: "Coagulação intravascular disseminada [síndrome de desfibrinação]",
            favorito: false
        },
        {
            cid10: "D66",
            descricao: "Deficiência hereditária do fator VIII",
            favorito: false
        },
        {
            cid10: "D67",
            descricao: "Deficiência hereditária do fator IX",
            favorito: false
        },
        {
            cid10: "D680",
            descricao: "Doença de Von Willebrand",
            favorito: false
        },
        {
            cid10: "D681",
            descricao: "Deficiência hereditária de fator XI",
            favorito: false
        },
        {
            cid10: "D682",
            descricao: "Deficiência hereditária de outros fatores de coagulação",
            favorito: false
        },
        {
            cid10: "D683",
            descricao: "Transtorno hemorrágico devido a anticoagulantes circulantes",
            favorito: false
        },
        {
            cid10: "D684",
            descricao: "Deficiência adquirida de fator de coagulação",
            favorito: false
        },
        {
            cid10: "D688",
            descricao: "Outros defeitos especificados da coagulação",
            favorito: false
        },
        {
            cid10: "D689",
            descricao: "Defeito de coagulação não especificado",
            favorito: false
        },
        {
            cid10: "D690",
            descricao: "Púrpura alérgica",
            favorito: false
        },
        {
            cid10: "D691",
            descricao: "Defeitos qualitativos das plaquetas",
            favorito: false
        },
        {
            cid10: "D692",
            descricao: "Outras púrpuras não-trombocitopênicas",
            favorito: false
        },
        {
            cid10: "D693",
            descricao: "Púrpura trombocitopênica idiopática",
            favorito: false
        },
        {
            cid10: "D694",
            descricao: "Outra trombocitopenia primária",
            favorito: false
        },
        {
            cid10: "D695",
            descricao: "Trombocitopenia secundária",
            favorito: false
        },
        {
            cid10: "D696",
            descricao: "Trombocitopenia não especificada",
            favorito: false
        },
        {
            cid10: "D698",
            descricao: "Outras afecções hemorrágicas especificadas",
            favorito: false
        },
        {
            cid10: "D699",
            descricao: "Afecção hemorrágica não especificada",
            favorito: false
        },
        {
            cid10: "D70",
            descricao: "Agranulocitose",
            favorito: false
        },
        {
            cid10: "D71",
            descricao: "Transtornos funcionais dos neutrófilos polimorfonucleares",
            favorito: false
        },
        {
            cid10: "D720",
            descricao: "Anomalias genéticas dos leucócitos",
            favorito: false
        },
        {
            cid10: "D721",
            descricao: "Eosinofilia",
            favorito: false
        },
        {
            cid10: "D728",
            descricao: "Outros transtornos especificados dos glóbulos brancos",
            favorito: false
        },
        {
            cid10: "D729",
            descricao: "Transtornos não especificados dos glóbulos brancos",
            favorito: false
        },
        {
            cid10: "D730",
            descricao: "Hipoesplenismo",
            favorito: false
        },
        {
            cid10: "D731",
            descricao: "Hiperesplenismo",
            favorito: false
        },
        {
            cid10: "D732",
            descricao: "Esplenomegalia congestiva crônica",
            favorito: false
        },
        {
            cid10: "D733",
            descricao: "Abscesso do baço",
            favorito: false
        },
        {
            cid10: "D734",
            descricao: "Cisto do baço",
            favorito: false
        },
        {
            cid10: "D735",
            descricao: "Infarto do baço",
            favorito: false
        },
        {
            cid10: "D738",
            descricao: "Outras doenças do baço",
            favorito: false
        },
        {
            cid10: "D739",
            descricao: "Doença não especificada do baço",
            favorito: false
        },
        {
            cid10: "D740",
            descricao: "Metemoglobinemia congênita",
            favorito: false
        },
        {
            cid10: "D748",
            descricao: "Outras metemoglobinemias",
            favorito: false
        },
        {
            cid10: "D749",
            descricao: "Metemoglobinemia não especificada",
            favorito: false
        },
        {
            cid10: "D750",
            descricao: "Eritrocitose familiar",
            favorito: false
        },
        {
            cid10: "D751",
            descricao: "Policitemia secundária",
            favorito: false
        },
        {
            cid10: "D752",
            descricao: "Trombocitose essencial",
            favorito: false
        },
        {
            cid10: "D758",
            descricao: "Outras doenças especificadas do sangue e dos órgãos hematopoéticos",
            favorito: false
        },
        {
            cid10: "D759",
            descricao: "Doença não especificada do sangue e dos órgãos hematopoéticos",
            favorito: false
        },
        {
            cid10: "D760",
            descricao: "Histiocitose das células de Langerhans não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "D761",
            descricao: "Linfohistiocitose hemofagocítica",
            favorito: false
        },
        {
            cid10: "D762",
            descricao: "Síndrome hematofagocítica associada à infecção",
            favorito: false
        },
        {
            cid10: "D763",
            descricao: "Outras síndromes histiocíticas",
            favorito: false
        },
        {
            cid10: "D77",
            descricao: "Outros transtornos do sangue e dos órgãos hematopoéticos em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "D800",
            descricao: "Hipogamaglobulinemia hereditária",
            favorito: false
        },
        {
            cid10: "D801",
            descricao: "Hipogamaglobulinemia não familiar",
            favorito: false
        },
        {
            cid10: "D802",
            descricao: "Deficiência seletiva de imunoglobulina A [IgA]",
            favorito: false
        },
        {
            cid10: "D803",
            descricao: "Deficiência seletiva de subclasses de imunoglobulina G [IgG]",
            favorito: false
        },
        {
            cid10: "D804",
            descricao: "Deficiência seletiva de imunoglobulina M [IgM]",
            favorito: false
        },
        {
            cid10: "D805",
            descricao: "Imunodeficiência com aumento de imunoglobulina M [IgM]",
            favorito: false
        },
        {
            cid10: "D806",
            descricao: "Deficiência de anticorpos com imunoglobulinas próximas do normal ou com hiperimunoglobulinemia",
            favorito: false
        },
        {
            cid10: "D807",
            descricao: "Hipogamaglobulinemia transitória da infância",
            favorito: false
        },
        {
            cid10: "D808",
            descricao: "Outras imunodeficiências com predominância de defeitos de anticorpos",
            favorito: false
        },
        {
            cid10: "D809",
            descricao: "Imunodeficiência não especificada com predominância de defeitos de anticorpos",
            favorito: false
        },
        {
            cid10: "D810",
            descricao: "Imunodeficiência combinada grave [SCID] com disgenesia reticular",
            favorito: false
        },
        {
            cid10: "D811",
            descricao: "Imunodeficiência combinada grave [SCID] com números baixos de células T e B",
            favorito: false
        },
        {
            cid10: "D812",
            descricao: "Imunodeficiência combinada grave [SCID] com números baixos ou normais de células B",
            favorito: false
        },
        {
            cid10: "D813",
            descricao: "Deficiência de adenosina-deaminase [ADA]",
            favorito: false
        },
        {
            cid10: "D814",
            descricao: "Síndrome de Nezelof",
            favorito: false
        },
        {
            cid10: "D815",
            descricao: "Deficiência de purina-nucleosídeo fosforilase [PNP]",
            favorito: false
        },
        {
            cid10: "D816",
            descricao: "Deficiência major classe I do complexo de histocompatibilidade",
            favorito: false
        },
        {
            cid10: "D817",
            descricao: "Deficiência major classe II do complexo de histocompatibilidade",
            favorito: false
        },
        {
            cid10: "D818",
            descricao: "Outras deficiências imunitárias combinadas",
            favorito: false
        },
        {
            cid10: "D819",
            descricao: "Deficiências imunitárias combinadas não especificadas",
            favorito: false
        },
        {
            cid10: "D820",
            descricao: "Síndrome de Wiskott-Aldrich",
            favorito: false
        },
        {
            cid10: "D821",
            descricao: "Síndrome de Di George",
            favorito: false
        },
        {
            cid10: "D822",
            descricao: "Imunodeficiência com encurtamento de membros",
            favorito: false
        },
        {
            cid10: "D823",
            descricao: "Imunodeficiência que se segue à resposta hereditária defeituosa ao vírus de Epstein-Barr (EB)",
            favorito: false
        },
        {
            cid10: "D824",
            descricao: "Síndrome da hiperimunoglobulina E [IgE]",
            favorito: false
        },
        {
            cid10: "D828",
            descricao: "Imunodeficiências associadas com outros defeitos ",
            favorito: false
        },
        {
            cid10: "D829",
            descricao: "Imunodeficiência associada com defeitos major especificados",
            favorito: false
        },
        {
            cid10: "D830",
            descricao: "Imunodeficiência comum variável com predominância de anormalidades do número e da função das células B",
            favorito: false
        },
        {
            cid10: "D831",
            descricao: "Imunodeficiência comum variável com predominância de transtornos imunorregulatórios de células T",
            favorito: false
        },
        {
            cid10: "D832",
            descricao: "Imunodeficiência comum variável com auto-anticorpos às células B ou T",
            favorito: false
        },
        {
            cid10: "D838",
            descricao: "Outras imunodeficiências comuns variáveis",
            favorito: false
        },
        {
            cid10: "D839",
            descricao: "Imunodeficiência comum variável não especificada",
            favorito: false
        },
        {
            cid10: "D840",
            descricao: "Defeito do antígeno-1 da função de linfócito [LFA-1]",
            favorito: false
        },
        {
            cid10: "D841",
            descricao: "Defeitos no sistema complemento",
            favorito: false
        },
        {
            cid10: "D848",
            descricao: "Outras imunodeficiências especificadas",
            favorito: false
        },
        {
            cid10: "D849",
            descricao: "Imunodeficiência não especificada",
            favorito: false
        },
        {
            cid10: "D860",
            descricao: "Sarcoidose do pulmão",
            favorito: false
        },
        {
            cid10: "D861",
            descricao: "Sarcoidose dos gânglios linfáticos",
            favorito: false
        },
        {
            cid10: "D862",
            descricao: "Sarcoidose do pulmão com sarcoidose dos gânglios linfáticos",
            favorito: false
        },
        {
            cid10: "D863",
            descricao: "Sarcoidose da pele",
            favorito: false
        },
        {
            cid10: "D868",
            descricao: "Sarcoidose de outros locais especificados e locais combinados",
            favorito: false
        },
        {
            cid10: "D869",
            descricao: "Sarcoidose não especificada",
            favorito: false
        },
        {
            cid10: "D890",
            descricao: "Hipergamaglobulinemia policlonal",
            favorito: false
        },
        {
            cid10: "D891",
            descricao: "Crioglobulinemia",
            favorito: false
        },
        {
            cid10: "D892",
            descricao: "Hipergamaglobulinemia não especificada",
            favorito: false
        },
        {
            cid10: "D898",
            descricao: "Outros transtornos especificados que comprometem o mecanismo imunitário não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "D899",
            descricao: "Transtornos não especificados que comprometem o mecanismo imunitário",
            favorito: false
        },
        {
            cid10: "E000",
            descricao: "Síndrome da deficiência congênita de iodo do tipo neurológico",
            favorito: false
        },
        {
            cid10: "E001",
            descricao: "Síndrome da deficiência congênita de iodo do tipo mixedematoso",
            favorito: false
        },
        {
            cid10: "E002",
            descricao: "Síndrome da deficiência congênita de iodo do tipo misto",
            favorito: false
        },
        {
            cid10: "E009",
            descricao: "Síndrome da deficiência congênita de iodo, não especificada",
            favorito: false
        },
        {
            cid10: "E010",
            descricao: "Bócio (endêmico) difuso por deficiência de iodo",
            favorito: false
        },
        {
            cid10: "E011",
            descricao: "Bócio (endêmico) multinodular por deficiência de iodo",
            favorito: false
        },
        {
            cid10: "E012",
            descricao: "Bócio (endêmico) não especificado, por deficiência de iodo",
            favorito: false
        },
        {
            cid10: "E018",
            descricao: "Outros transtornos tireoidianos e afecções associadas, relacionados com a deficiência de iodo",
            favorito: false
        },
        {
            cid10: "E02",
            descricao: "Hipotireoidismo subclínico por deficiência de iodo",
            favorito: false
        },
        {
            cid10: "E030",
            descricao: "Hipotireoidismo congênito com bócio difuso",
            favorito: false
        },
        {
            cid10: "E031",
            descricao: "Hipotireoidismo congênito sem bócio",
            favorito: false
        },
        {
            cid10: "E032",
            descricao: "Hipotireoidismo devido a medicamentos e outras substâncias exógenas",
            favorito: false
        },
        {
            cid10: "E033",
            descricao: "Hipotireoidismo pós-infeccioso",
            favorito: false
        },
        {
            cid10: "E034",
            descricao: "Atrofia (adquirida) da tireóide",
            favorito: false
        },
        {
            cid10: "E035",
            descricao: "Coma mixedematoso",
            favorito: false
        },
        {
            cid10: "E038",
            descricao: "Outros hipotireoidismos especificados",
            favorito: false
        },
        {
            cid10: "E039",
            descricao: "Hipotireoidismo não especificado",
            favorito: false
        },
        {
            cid10: "E040",
            descricao: "Bócio não-tóxico difuso",
            favorito: false
        },
        {
            cid10: "E041",
            descricao: "Bócio não-tóxico uninodular",
            favorito: false
        },
        {
            cid10: "E042",
            descricao: "Bócio não-tóxico multinodular",
            favorito: false
        },
        {
            cid10: "E048",
            descricao: "Outro bócio não-tóxico especificado",
            favorito: false
        },
        {
            cid10: "E049",
            descricao: "Bócio não-tóxico, não especificado",
            favorito: false
        },
        {
            cid10: "E050",
            descricao: "Tireotoxicose com bócio difuso",
            favorito: false
        },
        {
            cid10: "E051",
            descricao: "Tireotoxicose com bócio tóxico uninodular",
            favorito: false
        },
        {
            cid10: "E052",
            descricao: "Tireotoxicose com bócio tóxico multinodular",
            favorito: false
        },
        {
            cid10: "E053",
            descricao: "Tireotoxicose causada por tecido tireoidiano ectópico",
            favorito: false
        },
        {
            cid10: "E054",
            descricao: "Tireotoxicose factícia",
            favorito: false
        },
        {
            cid10: "E055",
            descricao: "Crise ou tempestade tireotóxica",
            favorito: false
        },
        {
            cid10: "E058",
            descricao: "Outras tireotoxicoses",
            favorito: false
        },
        {
            cid10: "E059",
            descricao: "Tireotoxicose não especificada",
            favorito: false
        },
        {
            cid10: "E060",
            descricao: "Tireoidite aguda",
            favorito: false
        },
        {
            cid10: "E061",
            descricao: "Tireoidite subaguda",
            favorito: false
        },
        {
            cid10: "E062",
            descricao: "Tireoidite crônica com tireotoxicose transitória",
            favorito: false
        },
        {
            cid10: "E063",
            descricao: "Tireoidite auto-imune",
            favorito: false
        },
        {
            cid10: "E064",
            descricao: "Tireoidite induzida por droga",
            favorito: false
        },
        {
            cid10: "E065",
            descricao: "Outras tireoidites crônicas",
            favorito: false
        },
        {
            cid10: "E069",
            descricao: "Tireoidite não especificada",
            favorito: false
        },
        {
            cid10: "E070",
            descricao: "Hipersecreção de calcitonina",
            favorito: false
        },
        {
            cid10: "E071",
            descricao: "Bócio disormonogênico",
            favorito: false
        },
        {
            cid10: "E078",
            descricao: "Outros transtornos especificados da tireóide",
            favorito: false
        },
        {
            cid10: "E079",
            descricao: "Transtorno não especificado da tireóide",
            favorito: false
        },
        {
            cid10: "E100",
            descricao: "Diabetes mellitus insulino-dependente - com coma",
            favorito: false
        },
        {
            cid10: "E101",
            descricao: "Diabetes mellitus insulino-dependente - com cetoacidose",
            favorito: false
        },
        {
            cid10: "E102",
            descricao: "Diabetes mellitus insulino-dependente - com complicações renais",
            favorito: false
        },
        {
            cid10: "E103",
            descricao: "Diabetes mellitus insulino-dependente - com complicações oftálmicas",
            favorito: false
        },
        {
            cid10: "E104",
            descricao: "Diabetes mellitus insulino-dependente - com complicações neurológicas",
            favorito: false
        },
        {
            cid10: "E105",
            descricao: "Diabetes mellitus insulino-dependente - com complicações circulatórias periféricas",
            favorito: false
        },
        {
            cid10: "E106",
            descricao: "Diabetes mellitus insulino-dependente - com outras complicações especificadas",
            favorito: false
        },
        {
            cid10: "E107",
            descricao: "Diabetes mellitus insulino-dependente - com complicações múltiplas",
            favorito: false
        },
        {
            cid10: "E108",
            descricao: "Diabetes mellitus insulino-dependente - com complicações não especificadas",
            favorito: false
        },
        {
            cid10: "E109",
            descricao: "Diabetes mellitus insulino-dependente - sem complicações",
            favorito: false
        },
        {
            cid10: "E110",
            descricao: "Diabetes mellitus não-insulino-dependente - com coma",
            favorito: false
        },
        {
            cid10: "E111",
            descricao: "Diabetes mellitus não-insulino-dependente - com cetoacidose",
            favorito: false
        },
        {
            cid10: "E112",
            descricao: "Diabetes mellitus não-insulino-dependente - com complicações renais",
            favorito: false
        },
        {
            cid10: "E113",
            descricao: "Diabetes mellitus não-insulino-dependente - com complicações oftálmicas",
            favorito: false
        },
        {
            cid10: "E114",
            descricao: "Diabetes mellitus não-insulino-dependente - com complicações neurológicas",
            favorito: false
        },
        {
            cid10: "E115",
            descricao: "Diabetes mellitus não-insulino-dependente - com complicações circulatórias periféricas",
            favorito: false
        },
        {
            cid10: "E116",
            descricao: "Diabetes mellitus não-insulino-dependente - com outras complicações especificadas",
            favorito: false
        },
        {
            cid10: "E117",
            descricao: "Diabetes mellitus não-insulino-dependente - com complicações múltiplas",
            favorito: false
        },
        {
            cid10: "E118",
            descricao: "Diabetes mellitus não-insulino-dependente - com complicações não especificadas",
            favorito: false
        },
        {
            cid10: "E119",
            descricao: "Diabetes mellitus não-insulino-dependente - sem complicações",
            favorito: false
        },
        {
            cid10: "E120",
            descricao: "Diabetes mellitus relacionado com a desnutrição - com coma",
            favorito: false
        },
        {
            cid10: "E121",
            descricao: "Diabetes mellitus relacionado com a desnutrição - com cetoacidose",
            favorito: false
        },
        {
            cid10: "E122",
            descricao: "Diabetes mellitus relacionado com a desnutrição - com complicações renais",
            favorito: false
        },
        {
            cid10: "E123",
            descricao: "Diabetes mellitus relacionado com a desnutrição - com complicações oftálmicas",
            favorito: false
        },
        {
            cid10: "E124",
            descricao: "Diabetes mellitus relacionado com a desnutrição - com complicações neurológicas",
            favorito: false
        },
        {
            cid10: "E125",
            descricao: "Diabetes mellitus relacionado com a desnutrição - com complicações circulatórias periféricas",
            favorito: false
        },
        {
            cid10: "E126",
            descricao: "Diabetes mellitus relacionado com a desnutrição - com outras complicações especificadas",
            favorito: false
        },
        {
            cid10: "E127",
            descricao: "Diabetes mellitus relacionado com a desnutrição - com complicações múltiplas",
            favorito: false
        },
        {
            cid10: "E128",
            descricao: "Diabetes mellitus relacionado com a desnutrição - com complicações não especificadas",
            favorito: false
        },
        {
            cid10: "E129",
            descricao: "Diabetes mellitus relacionado com a desnutrição - sem complicações",
            favorito: false
        },
        {
            cid10: "E130",
            descricao: "Outros tipos especificados de diabetes mellitus - com coma",
            favorito: false
        },
        {
            cid10: "E131",
            descricao: "Outros tipos especificados de diabetes mellitus - com cetoacidose",
            favorito: false
        },
        {
            cid10: "E132",
            descricao: "Outros tipos especificados de diabetes mellitus - com complicações renais",
            favorito: false
        },
        {
            cid10: "E133",
            descricao: "Outros tipos especificados de diabetes mellitus - com complicações oftálmicas",
            favorito: false
        },
        {
            cid10: "E134",
            descricao: "Outros tipos especificados de diabetes mellitus - com complicações neurológicas",
            favorito: false
        },
        {
            cid10: "E135",
            descricao: "Outros tipos especificados de diabetes mellitus - com complicações circulatórias periféricas",
            favorito: false
        },
        {
            cid10: "E136",
            descricao: "Outros tipos especificados de diabetes mellitus - com outras complicações especificadas",
            favorito: false
        },
        {
            cid10: "E137",
            descricao: "Outros tipos especificados de diabetes mellitus - com complicações múltiplas",
            favorito: false
        },
        {
            cid10: "E138",
            descricao: "Outros tipos especificados de diabetes mellitus - com complicações não especificadas",
            favorito: false
        },
        {
            cid10: "E139",
            descricao: "Outros tipos especificados de diabetes mellitus - sem complicações",
            favorito: false
        },
        {
            cid10: "E140",
            descricao: "Diabetes mellitus não especificado - com coma",
            favorito: false
        },
        {
            cid10: "E141",
            descricao: "Diabetes mellitus não especificado - com cetoacidose",
            favorito: false
        },
        {
            cid10: "E142",
            descricao: "Diabetes mellitus não especificado - com complicações renais",
            favorito: false
        },
        {
            cid10: "E143",
            descricao: "Diabetes mellitus não especificado - com complicações oftálmicas",
            favorito: false
        },
        {
            cid10: "E144",
            descricao: "Diabetes mellitus não especificado - com complicações neurológicas",
            favorito: false
        },
        {
            cid10: "E145",
            descricao: "Diabetes mellitus não especificado - com complicações circulatórias periféricas",
            favorito: false
        },
        {
            cid10: "E146",
            descricao: "Diabetes mellitus não especificado - com outras complicações especificadas",
            favorito: false
        },
        {
            cid10: "E147",
            descricao: "Diabetes mellitus não especificado - com complicações múltiplas",
            favorito: false
        },
        {
            cid10: "E148",
            descricao: "Diabetes mellitus não especificado - com complicações não especificadas",
            favorito: false
        },
        {
            cid10: "E149",
            descricao: "Diabetes mellitus não especificado - sem complicações",
            favorito: false
        },
        {
            cid10: "E15",
            descricao: "Coma hipoglicêmico não-diabético",
            favorito: false
        },
        {
            cid10: "E160",
            descricao: "Hipoglicemia induzida por droga sem coma",
            favorito: false
        },
        {
            cid10: "E161",
            descricao: "Outra hipoglicemia",
            favorito: false
        },
        {
            cid10: "E162",
            descricao: "Hipoglicemia não especificada",
            favorito: false
        },
        {
            cid10: "E163",
            descricao: "Aumento da secreção de glucagon",
            favorito: false
        },
        {
            cid10: "E164",
            descricao: "Secreção anormal de gastrina",
            favorito: false
        },
        {
            cid10: "E168",
            descricao: "Outros transtornos especificados da secreção pancreática interna",
            favorito: false
        },
        {
            cid10: "E169",
            descricao: "Transtorno não especificado da secreção pancreática interna",
            favorito: false
        },
        {
            cid10: "E200",
            descricao: "Hipoparatireoidismo idiopático",
            favorito: false
        },
        {
            cid10: "E201",
            descricao: "Pseudohipoparatireoidismo",
            favorito: false
        },
        {
            cid10: "E208",
            descricao: "Outro hipoparatireoidismo",
            favorito: false
        },
        {
            cid10: "E209",
            descricao: "Hipoparatireoidismo não especificado",
            favorito: false
        },
        {
            cid10: "E210",
            descricao: "Hiperparatireoidismo primário",
            favorito: false
        },
        {
            cid10: "E211",
            descricao: "Hiperparatireoidismo secundário não classificado em outra parte",
            favorito: false
        },
        {
            cid10: "E212",
            descricao: "Outro hiperparatireoidismo",
            favorito: false
        },
        {
            cid10: "E213",
            descricao: "Hiperparatireoidismo não especificado",
            favorito: false
        },
        {
            cid10: "E214",
            descricao: "Outros transtornos especificados da glândula paratireóide",
            favorito: false
        },
        {
            cid10: "E215",
            descricao: "Transtorno não especificado da glândula paratireóide",
            favorito: false
        },
        {
            cid10: "E220",
            descricao: "Acromegalia e gigantismo hipofisário",
            favorito: false
        },
        {
            cid10: "E221",
            descricao: "Hiperprolactinemia",
            favorito: false
        },
        {
            cid10: "E222",
            descricao: "Síndrome da secreção inadequada de hormônio antidiurético",
            favorito: false
        },
        {
            cid10: "E228",
            descricao: "Outras hiperfunções da hipófise",
            favorito: false
        },
        {
            cid10: "E229",
            descricao: "Hiperfunção não especificada da hipófise",
            favorito: false
        },
        {
            cid10: "E230",
            descricao: "Hipopituitarismo",
            favorito: false
        },
        {
            cid10: "E231",
            descricao: "Hipopituitarismo induzido por droga",
            favorito: false
        },
        {
            cid10: "E232",
            descricao: "Diabetes insípido",
            favorito: false
        },
        {
            cid10: "E233",
            descricao: "Disfunção hipotalâmica não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "E236",
            descricao: "Outros transtornos da hipófise",
            favorito: false
        },
        {
            cid10: "E237",
            descricao: "Transtorno não especificado da hipófise",
            favorito: false
        },
        {
            cid10: "E240",
            descricao: "Síndrome de Cushing dependente da hipófise",
            favorito: false
        },
        {
            cid10: "E241",
            descricao: "Síndrome de Nelson",
            favorito: false
        },
        {
            cid10: "E242",
            descricao: "Síndrome de Cushing induzida por droga",
            favorito: false
        },
        {
            cid10: "E243",
            descricao: "Síndrome do ACTH ectópico",
            favorito: false
        },
        {
            cid10: "E244",
            descricao: "Síndrome de pseudo-Cushing induzida pelo álcool",
            favorito: false
        },
        {
            cid10: "E248",
            descricao: "Outra síndrome de Cushing",
            favorito: false
        },
        {
            cid10: "E249",
            descricao: "Síndrome de Cushing não especificada",
            favorito: false
        },
        {
            cid10: "E250",
            descricao: "Transtornos adrenogenitais congênitos associados à deficiência enzimática",
            favorito: false
        },
        {
            cid10: "E258",
            descricao: "Outros transtornos adrenogenitais",
            favorito: false
        },
        {
            cid10: "E259",
            descricao: "Transtorno adrenogenital não especificado",
            favorito: false
        },
        {
            cid10: "E260",
            descricao: "Hiperaldosteronismo primário",
            favorito: false
        },
        {
            cid10: "E261",
            descricao: "Hiperaldosteronismo secundário",
            favorito: false
        },
        {
            cid10: "E268",
            descricao: "Outro hiperaldosteronismo",
            favorito: false
        },
        {
            cid10: "E269",
            descricao: "Hiperaldosteronismo não especificado",
            favorito: false
        },
        {
            cid10: "E270",
            descricao: "Outros excessos de atividade adrenocortical",
            favorito: false
        },
        {
            cid10: "E271",
            descricao: "Insuficiência adrenocortical primária",
            favorito: false
        },
        {
            cid10: "E272",
            descricao: "Crise addisoniana",
            favorito: false
        },
        {
            cid10: "E273",
            descricao: "Insuficiência adrenocortical induzida por drogas",
            favorito: false
        },
        {
            cid10: "E274",
            descricao: "Outras insuficiências adrenocorticais e as não especificadas",
            favorito: false
        },
        {
            cid10: "E275",
            descricao: "Hiperfunção adrenomedular",
            favorito: false
        },
        {
            cid10: "E278",
            descricao: "Outros transtornos especificados da supra-renal",
            favorito: false
        },
        {
            cid10: "E279",
            descricao: "Transtorno não especificado da supra-renal",
            favorito: false
        },
        {
            cid10: "E280",
            descricao: "Excesso de estrógeno",
            favorito: false
        },
        {
            cid10: "E281",
            descricao: "Excesso de andrógenos",
            favorito: false
        },
        {
            cid10: "E282",
            descricao: "Síndrome do ovário policístico",
            favorito: false
        },
        {
            cid10: "E283",
            descricao: "Insuficiência ovariana primária",
            favorito: false
        },
        {
            cid10: "E288",
            descricao: "Outra disfunção ovariana",
            favorito: false
        },
        {
            cid10: "E289",
            descricao: "Disfunção ovariana não especificada",
            favorito: false
        },
        {
            cid10: "E290",
            descricao: "Hiperfunção testicular",
            favorito: false
        },
        {
            cid10: "E291",
            descricao: "Hipofunção testicular",
            favorito: false
        },
        {
            cid10: "E298",
            descricao: "Outra disfunção testicular",
            favorito: false
        },
        {
            cid10: "E299",
            descricao: "Disfunção testicular não especificada",
            favorito: false
        },
        {
            cid10: "E300",
            descricao: "Puberdade retardada",
            favorito: false
        },
        {
            cid10: "E301",
            descricao: "Puberdade precoce",
            favorito: false
        },
        {
            cid10: "E308",
            descricao: "Outros transtornos da puberdade",
            favorito: false
        },
        {
            cid10: "E309",
            descricao: "Transtorno não especificado da puberdade",
            favorito: false
        },
        {
            cid10: "E310",
            descricao: "Insuficiência poliglandular auto-imune",
            favorito: false
        },
        {
            cid10: "E311",
            descricao: "Hiperfunção poliglandular",
            favorito: false
        },
        {
            cid10: "E318",
            descricao: "Outra disfunção poliglandular",
            favorito: false
        },
        {
            cid10: "E319",
            descricao: "Disfunção poliglandular não especificada",
            favorito: false
        },
        {
            cid10: "E320",
            descricao: "Hiperplasia persistente do timo",
            favorito: false
        },
        {
            cid10: "E321",
            descricao: "Abscesso do timo",
            favorito: false
        },
        {
            cid10: "E328",
            descricao: "Outras doenças do timo",
            favorito: false
        },
        {
            cid10: "E329",
            descricao: "Doença do timo não especificada",
            favorito: false
        },
        {
            cid10: "E340",
            descricao: "Síndrome carcinóide",
            favorito: false
        },
        {
            cid10: "E341",
            descricao: "Outra hipersecreção de hormônios intestinais",
            favorito: false
        },
        {
            cid10: "E342",
            descricao: "Secreção hormonal ectópica, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "E343",
            descricao: "Nanismo, não classificado em outra parte",
            favorito: false
        },
        {
            cid10: "E344",
            descricao: "Alta estatura constitucional",
            favorito: false
        },
        {
            cid10: "E345",
            descricao: "Síndrome de resistência a andrógenos",
            favorito: false
        },
        {
            cid10: "E348",
            descricao: "Outros transtornos endócrinos especificados",
            favorito: false
        },
        {
            cid10: "E349",
            descricao: "Transtorno endócrino não especificado",
            favorito: false
        },
        {
            cid10: "E350",
            descricao: "Transtornos da glândula tireóide em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "E351",
            descricao: "Transtornos das supra-renais em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "E358",
            descricao: "Transtornos de outras glândulas endócrinas em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "E40",
            descricao: "Kwashiorkor",
            favorito: false
        },
        {
            cid10: "E41",
            descricao: "Marasmo nutricional",
            favorito: false
        },
        {
            cid10: "E42",
            descricao: "Kwashiorkor marasmático",
            favorito: false
        },
        {
            cid10: "E43",
            descricao: "Desnutrição protéico-calórica grave não especificada",
            favorito: false
        },
        {
            cid10: "E440",
            descricao: "Desnutrição protéico-calórica moderada",
            favorito: false
        },
        {
            cid10: "E441",
            descricao: "Desnutrição protéico-calórica leve",
            favorito: false
        },
        {
            cid10: "E45",
            descricao: "Atraso do desenvolvimento devido à desnutrição protéico-calórica",
            favorito: false
        },
        {
            cid10: "E46",
            descricao: "Desnutrição protéico-calórica não especificada",
            favorito: false
        },
        {
            cid10: "E500",
            descricao: "Deficiência de vitamina A com xerose conjuntival",
            favorito: false
        },
        {
            cid10: "E501",
            descricao: "Deficiência de vitamina A com mancha de Bitot e xerose conjuntival",
            favorito: false
        },
        {
            cid10: "E502",
            descricao: "Deficiência de vitamina A com xerose da córnea",
            favorito: false
        },
        {
            cid10: "E503",
            descricao: "Deficiência de vitamina A com ulceração e xerose da córnea",
            favorito: false
        },
        {
            cid10: "E504",
            descricao: "Deficiência de vitamina A com ceratomalácia",
            favorito: false
        },
        {
            cid10: "E505",
            descricao: "Deficiência de vitamina A com cegueira noturna",
            favorito: false
        },
        {
            cid10: "E506",
            descricao: "Deficiência de vitamina A com cicatrizes xeroftálmicas da córnea",
            favorito: false
        },
        {
            cid10: "E507",
            descricao: "Outras manifestações oculares devidas a deficiência de vitamina A",
            favorito: false
        },
        {
            cid10: "E508",
            descricao: "Outras manifestações devidas a deficiência de vitamina A",
            favorito: false
        },
        {
            cid10: "E509",
            descricao: "Deficiência não especificada de vitamina A",
            favorito: false
        },
        {
            cid10: "E511",
            descricao: "Beribéri",
            favorito: false
        },
        {
            cid10: "E512",
            descricao: "Encefalopatia de Wernicke",
            favorito: false
        },
        {
            cid10: "E518",
            descricao: "Outras manifestações da deficiência de tiamina",
            favorito: false
        },
        {
            cid10: "E519",
            descricao: "Deficiência não especificada de tiamina",
            favorito: false
        },
        {
            cid10: "E52",
            descricao: "Deficiência de niacina [pelagra]",
            favorito: false
        },
        {
            cid10: "E530",
            descricao: "Deficiência de riboflavina",
            favorito: false
        },
        {
            cid10: "E531",
            descricao: "Deficiência de piridoxina",
            favorito: false
        },
        {
            cid10: "E538",
            descricao: "Deficiência de outras vitaminas especificadas do grupo B",
            favorito: false
        },
        {
            cid10: "E539",
            descricao: "Deficiência não especificada de vitamina B",
            favorito: false
        },
        {
            cid10: "E54",
            descricao: "Deficiência de ácido ascórbico",
            favorito: false
        },
        {
            cid10: "E550",
            descricao: "Raquitismo ativo",
            favorito: false
        },
        {
            cid10: "E559",
            descricao: "Deficiência não especificada de vitamina D",
            favorito: false
        },
        {
            cid10: "E560",
            descricao: "Deficiência de vitamina E",
            favorito: false
        },
        {
            cid10: "E561",
            descricao: "Deficiência de vitamina K",
            favorito: false
        },
        {
            cid10: "E568",
            descricao: "Deficiência de outras vitaminas",
            favorito: false
        },
        {
            cid10: "E569",
            descricao: "Deficiência vitamínica não especificada",
            favorito: false
        },
        {
            cid10: "E58",
            descricao: "Deficiência de cálcio da dieta",
            favorito: false
        },
        {
            cid10: "E59",
            descricao: "Deficiência de selênio da dieta",
            favorito: false
        },
        {
            cid10: "E60",
            descricao: "Deficiência de zinco da dieta",
            favorito: false
        },
        {
            cid10: "E610",
            descricao: "Deficiência de cobre",
            favorito: false
        },
        {
            cid10: "E611",
            descricao: "Deficiência de ferro",
            favorito: false
        },
        {
            cid10: "E612",
            descricao: "Deficiência de magnésio",
            favorito: false
        },
        {
            cid10: "E613",
            descricao: "Deficiência de manganês",
            favorito: false
        },
        {
            cid10: "E614",
            descricao: "Deficiência de cromo",
            favorito: false
        },
        {
            cid10: "E615",
            descricao: "Deficiência de molibdênio",
            favorito: false
        },
        {
            cid10: "E616",
            descricao: "Deficiência de vanádio",
            favorito: false
        },
        {
            cid10: "E617",
            descricao: "Deficiência de múltiplos elementos nutrientes",
            favorito: false
        },
        {
            cid10: "E618",
            descricao: "Deficiência de outros elementos nutrientes especificados",
            favorito: false
        },
        {
            cid10: "E619",
            descricao: "Deficiência de elementos nutrientes não especificados",
            favorito: false
        },
        {
            cid10: "E630",
            descricao: "Deficiência de ácido graxo essencial",
            favorito: false
        },
        {
            cid10: "E631",
            descricao: "Desequilíbrio de constituintes da ingestão de alimentos",
            favorito: false
        },
        {
            cid10: "E638",
            descricao: "Outras deficiências nutricionais especificadas",
            favorito: false
        },
        {
            cid10: "E639",
            descricao: "Deficiência nutricional não especificada",
            favorito: false
        },
        {
            cid10: "E640",
            descricao: "Seqüelas de desnutrição protéico-calórica",
            favorito: false
        },
        {
            cid10: "E641",
            descricao: "Seqüelas da deficiência de vitamina A",
            favorito: false
        },
        {
            cid10: "E642",
            descricao: "Seqüelas da deficiência de vitamina C",
            favorito: false
        },
        {
            cid10: "E643",
            descricao: "Seqüelas do raquitismo",
            favorito: false
        },
        {
            cid10: "E648",
            descricao: "Seqüelas de outras deficiências nutricionais",
            favorito: false
        },
        {
            cid10: "E649",
            descricao: "Seqüelas de deficiência nutricional não especificada",
            favorito: false
        },
        {
            cid10: "E65",
            descricao: "Adiposidade localizada",
            favorito: false
        },
        {
            cid10: "E660",
            descricao: "Obesidade devida a excesso de calorias",
            favorito: false
        },
        {
            cid10: "E661",
            descricao: "Obesidade induzida por drogas",
            favorito: false
        },
        {
            cid10: "E662",
            descricao: "Obesidade extrema com hipoventilação alveolar",
            favorito: false
        },
        {
            cid10: "E668",
            descricao: "Outra obesidade",
            favorito: false
        },
        {
            cid10: "E669",
            descricao: "Obesidade não especificada",
            favorito: false
        },
        {
            cid10: "E670",
            descricao: "Hipervitaminose A",
            favorito: false
        },
        {
            cid10: "E671",
            descricao: "Hipercarotenemia",
            favorito: false
        },
        {
            cid10: "E672",
            descricao: "Síndrome de megavitamina B6",
            favorito: false
        },
        {
            cid10: "E673",
            descricao: "Hipervitaminose D",
            favorito: false
        },
        {
            cid10: "E678",
            descricao: "Outras formas especificadas de hiperalimentação",
            favorito: false
        },
        {
            cid10: "E68",
            descricao: "Seqüelas de hiperalimentação",
            favorito: false
        },
        {
            cid10: "E700",
            descricao: "Fenilcetonúria clássica",
            favorito: false
        },
        {
            cid10: "E701",
            descricao: "Outras hiperfenilalaninemias",
            favorito: false
        },
        {
            cid10: "E702",
            descricao: "Distúrbios do metabolismo da tirosina",
            favorito: false
        },
        {
            cid10: "E703",
            descricao: "Albinismo",
            favorito: false
        },
        {
            cid10: "E708",
            descricao: "Outros distúrbios do metabolismo de aminoácidos aromáticos",
            favorito: false
        },
        {
            cid10: "E709",
            descricao: "Distúrbio não especificado do metabolismo de aminoácidos aromáticos",
            favorito: false
        },
        {
            cid10: "E710",
            descricao: "Doença da urina em xarope de ácer (ou bordo) (Maple-syrup urine disease)",
            favorito: false
        },
        {
            cid10: "E711",
            descricao: "Outros distúrbios do metabolismo de aminoácidos de cadeia ramificada",
            favorito: false
        },
        {
            cid10: "E712",
            descricao: "Distúrbio não especificado do metabolismo de aminoácidos de cadeia ramificada",
            favorito: false
        },
        {
            cid10: "E713",
            descricao: "Distúrbios do metabolismo de ácidos graxos",
            favorito: false
        },
        {
            cid10: "E720",
            descricao: "Distúrbios do transporte de aminoácidos",
            favorito: false
        },
        {
            cid10: "E721",
            descricao: "Distúrbios do metabolismo dos aminoácidos que contêm enxofre",
            favorito: false
        },
        {
            cid10: "E722",
            descricao: "Distúrbios do metabolismo do ciclo da uréia",
            favorito: false
        },
        {
            cid10: "E723",
            descricao: "Distúrbios do metabolismo da lisina e da hidroxilisina",
            favorito: false
        },
        {
            cid10: "E724",
            descricao: "Distúrbios do metabolismo da ornitina",
            favorito: false
        },
        {
            cid10: "E725",
            descricao: "Distúrbios do metabolismo da glicina",
            favorito: false
        },
        {
            cid10: "E728",
            descricao: "Outros distúrbios especificados do metabolismo dos aminoácidos",
            favorito: false
        },
        {
            cid10: "E729",
            descricao: "Distúrbio não especificado do metabolismo dos aminoácidos",
            favorito: false
        },
        {
            cid10: "E730",
            descricao: "Deficiência congênita de lactase",
            favorito: false
        },
        {
            cid10: "E731",
            descricao: "Deficiência secundária de lactase",
            favorito: false
        },
        {
            cid10: "E738",
            descricao: "Outras intolerâncias à lactose",
            favorito: false
        },
        {
            cid10: "E739",
            descricao: "Intolerância à lactose, não especificada",
            favorito: false
        },
        {
            cid10: "E740",
            descricao: "Doença de depósito de glicogênio",
            favorito: false
        },
        {
            cid10: "E741",
            descricao: "Distúrbios do metabolismo da frutose",
            favorito: false
        },
        {
            cid10: "E742",
            descricao: "Distúrbios do metabolismo da galactose",
            favorito: false
        },
        {
            cid10: "E743",
            descricao: "Outros distúrbios da absorção intestinal de carboidratos",
            favorito: false
        },
        {
            cid10: "E744",
            descricao: "Distúrbios do metabolismo do piruvato e da gliconeogênese",
            favorito: false
        },
        {
            cid10: "E748",
            descricao: "Outros distúrbios especificados do metabolismo de carboidratos",
            favorito: false
        },
        {
            cid10: "E749",
            descricao: "Distúrbio não especificado do metabolismo de carboidratos",
            favorito: false
        },
        {
            cid10: "E750",
            descricao: "Gangliosidose GM2",
            favorito: false
        },
        {
            cid10: "E751",
            descricao: "Outras gangliosidoses",
            favorito: false
        },
        {
            cid10: "E752",
            descricao: "Outras esfingolipidoses",
            favorito: false
        },
        {
            cid10: "E753",
            descricao: "Esfingolipidose não especificada",
            favorito: false
        },
        {
            cid10: "E754",
            descricao: "Lipofuscinose neuronal ceróide",
            favorito: false
        },
        {
            cid10: "E755",
            descricao: "Outros distúrbios do depósito de lípides",
            favorito: false
        },
        {
            cid10: "E756",
            descricao: "Distúrbio não especificado do depósito de lípides",
            favorito: false
        },
        {
            cid10: "E760",
            descricao: "Mucopolissacaridose do tipo I",
            favorito: false
        },
        {
            cid10: "E761",
            descricao: "Mucopolissacaridose do tipo II",
            favorito: false
        },
        {
            cid10: "E762",
            descricao: "Outras mucopolissacaridoses",
            favorito: false
        },
        {
            cid10: "E763",
            descricao: "Mucopolissacaridose não especificada",
            favorito: false
        },
        {
            cid10: "E768",
            descricao: "Outros distúrbios do metabolismo do glicosaminoglicano",
            favorito: false
        },
        {
            cid10: "E769",
            descricao: "Distúrbio não especificado do metabolismo do glicosaminoglicano",
            favorito: false
        },
        {
            cid10: "E770",
            descricao: "Defeitos na modificação pós-translacional de enzimas lisossômicas",
            favorito: false
        },
        {
            cid10: "E771",
            descricao: "Defeitos na degradação das glicoproteínas",
            favorito: false
        },
        {
            cid10: "E778",
            descricao: "Outros distúrbios do metabolismo de glicoproteínas",
            favorito: false
        },
        {
            cid10: "E779",
            descricao: "Distúrbio não especificado do metabolismo de glicoproteínas",
            favorito: false
        },
        {
            cid10: "E780",
            descricao: "Hipercolesterolemia pura",
            favorito: false
        },
        {
            cid10: "E781",
            descricao: "Hipergliceridemia pura",
            favorito: false
        },
        {
            cid10: "E782",
            descricao: "Hiperlipidemia mista",
            favorito: false
        },
        {
            cid10: "E783",
            descricao: "Hiperquilomicronemia",
            favorito: false
        },
        {
            cid10: "E784",
            descricao: "Outras hiperlipidemias",
            favorito: false
        },
        {
            cid10: "E785",
            descricao: "Hiperlipidemia não especificada",
            favorito: false
        },
        {
            cid10: "E786",
            descricao: "Deficiências de lipoproteínas",
            favorito: false
        },
        {
            cid10: "E788",
            descricao: "Outros distúrbios do metabolismo de lipoproteínas",
            favorito: false
        },
        {
            cid10: "E789",
            descricao: "Distúrbio não especificado do metabolismo de lipoproteínas",
            favorito: false
        },
        {
            cid10: "E790",
            descricao: "Hiperuricemia sem sinais de artrite inflamatória e de doença com tofos",
            favorito: false
        },
        {
            cid10: "E791",
            descricao: "Síndrome de Lesch-Nyhan",
            favorito: false
        },
        {
            cid10: "E798",
            descricao: "Outros distúrbios do metabolismo da purina e pirimidina",
            favorito: false
        },
        {
            cid10: "E799",
            descricao: "Distúrbio não especificado do metabolismo da purina e pirimidina",
            favorito: false
        },
        {
            cid10: "E800",
            descricao: "Porfiria hereditária eritropoética",
            favorito: false
        },
        {
            cid10: "E801",
            descricao: "Porfiria cutânea tardia",
            favorito: false
        },
        {
            cid10: "E802",
            descricao: "Outras porfirias",
            favorito: false
        },
        {
            cid10: "E803",
            descricao: "Defeitos da catalase e da peroxidase",
            favorito: false
        },
        {
            cid10: "E804",
            descricao: "Síndrome de Gilbert",
            favorito: false
        },
        {
            cid10: "E805",
            descricao: "Síndrome da Crigler-Najjar",
            favorito: false
        },
        {
            cid10: "E806",
            descricao: "Outros distúrbios do metabolismo da bilirrubina",
            favorito: false
        },
        {
            cid10: "E807",
            descricao: "Distúrbio não especificado do metabolismo da bilirrubina",
            favorito: false
        },
        {
            cid10: "E830",
            descricao: "Distúrbios do metabolismo do cobre",
            favorito: false
        },
        {
            cid10: "E831",
            descricao: "Doença do metabolismo do ferro",
            favorito: false
        },
        {
            cid10: "E832",
            descricao: "Distúrbios do metabolismo do zinco",
            favorito: false
        },
        {
            cid10: "E833",
            descricao: "Distúrbios do metabolismo do fósforo",
            favorito: false
        },
        {
            cid10: "E834",
            descricao: "Distúrbios do metabolismo do magnésio",
            favorito: false
        },
        {
            cid10: "E835",
            descricao: "Distúrbios do metabolismo do cálcio",
            favorito: false
        },
        {
            cid10: "E838",
            descricao: "Outros distúrbios do metabolismo mineral",
            favorito: false
        },
        {
            cid10: "E839",
            descricao: "Distúrbio não especificado do metabolismo mineral",
            favorito: false
        },
        {
            cid10: "E840",
            descricao: "Fibrose cística com manifestações pulmonares",
            favorito: false
        },
        {
            cid10: "E841",
            descricao: "Fibrose cística com manifestações intestinais",
            favorito: false
        },
        {
            cid10: "E848",
            descricao: "Fibrose cística com outras manifestações",
            favorito: false
        },
        {
            cid10: "E849",
            descricao: "Fibrose cística não especificada",
            favorito: false
        },
        {
            cid10: "E850",
            descricao: "Amiloidose heredofamiliar não-neuropática",
            favorito: false
        },
        {
            cid10: "E851",
            descricao: "Amiloidose heredofamiliar neuropática",
            favorito: false
        },
        {
            cid10: "E852",
            descricao: "Amiloidose heredofamiliar não especificada",
            favorito: false
        },
        {
            cid10: "E853",
            descricao: "Amiloidose sistêmica secundária",
            favorito: false
        },
        {
            cid10: "E854",
            descricao: "Amiloidose limitada a órgãos",
            favorito: false
        },
        {
            cid10: "E858",
            descricao: "Outras amiloidoses",
            favorito: false
        },
        {
            cid10: "E859",
            descricao: "Amiloidose não especificada",
            favorito: false
        },
        {
            cid10: "E86",
            descricao: "Depleção de volume",
            favorito: false
        },
        {
            cid10: "E870",
            descricao: "Hiperosmolaridade e hipernatremia",
            favorito: false
        },
        {
            cid10: "E871",
            descricao: "Hiposmolaridade e hiponatremia",
            favorito: false
        },
        {
            cid10: "E872",
            descricao: "Acidose",
            favorito: false
        },
        {
            cid10: "E873",
            descricao: "Alcalose",
            favorito: false
        },
        {
            cid10: "E874",
            descricao: "Distúrbio misto do equilíbrio ácido-básico",
            favorito: false
        },
        {
            cid10: "E875",
            descricao: "Hiperpotassemia",
            favorito: false
        },
        {
            cid10: "E876",
            descricao: "Hipopotassemia",
            favorito: false
        },
        {
            cid10: "E877",
            descricao: "Sobrecarga de líquidos",
            favorito: false
        },
        {
            cid10: "E878",
            descricao: "Outros transtornos do equilíbrio hidroeletrolítico não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "E880",
            descricao: "Distúrbios do metabolismo das proteínas plasmáticas não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "E881",
            descricao: "Lipodistrofia não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "E882",
            descricao: "Lipomatose não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "E888",
            descricao: "Outros distúrbios especificados do metabolismo",
            favorito: false
        },
        {
            cid10: "E889",
            descricao: "Distúrbio metabólico não especificado",
            favorito: false
        },
        {
            cid10: "E890",
            descricao: "Hipotireoidismo pós-procedimento",
            favorito: false
        },
        {
            cid10: "E891",
            descricao: "Hipoinsulinemia pós-procedimento",
            favorito: false
        },
        {
            cid10: "E892",
            descricao: "Hipoparatireoidismo pós-procedimento",
            favorito: false
        },
        {
            cid10: "E893",
            descricao: "Hipopituitarismo pós-procedimento",
            favorito: false
        },
        {
            cid10: "E894",
            descricao: "Insuficiência ovariana pós-procedimento",
            favorito: false
        },
        {
            cid10: "E895",
            descricao: "Hipofunção testicular pós-procedimento",
            favorito: false
        },
        {
            cid10: "E896",
            descricao: "Hipofunção adrenocortical(-medular) pós-procedimento",
            favorito: false
        },
        {
            cid10: "E898",
            descricao: "Outros transtornos endócrinos e metabólicos pós-procedimento",
            favorito: false
        },
        {
            cid10: "E899",
            descricao: "Transtornos endócrinos e metabólicos não especificados pós-procedimento",
            favorito: false
        },
        {
            cid10: "E90",
            descricao: "Transtornos nutricionais e metabólicos em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "F000",
            descricao: "Demência na doença de Alzheimer de início precoce",
            favorito: false
        },
        {
            cid10: "F001",
            descricao: "Demência na doença de Alzheimer de início tardio",
            favorito: false
        },
        {
            cid10: "F002",
            descricao: "Demência na doença de Alzheimer, forma atípica ou mista",
            favorito: false
        },
        {
            cid10: "F009",
            descricao: "Demência não especificada na doença de Alzheimer",
            favorito: false
        },
        {
            cid10: "F010",
            descricao: "Demência vascular de início agudo",
            favorito: false
        },
        {
            cid10: "F011",
            descricao: "Demência por infartos múltiplos",
            favorito: false
        },
        {
            cid10: "F012",
            descricao: "Demência vascular subcortical",
            favorito: false
        },
        {
            cid10: "F013",
            descricao: "Demência vascular mista, cortical e subcortical",
            favorito: false
        },
        {
            cid10: "F018",
            descricao: "Outra demência vascular",
            favorito: false
        },
        {
            cid10: "F019",
            descricao: "Demência vascular não especificada",
            favorito: false
        },
        {
            cid10: "F020",
            descricao: "Demência da doença de Pick",
            favorito: false
        },
        {
            cid10: "F021",
            descricao: "Demência na doença de Creutzfeldt-Jakob",
            favorito: false
        },
        {
            cid10: "F022",
            descricao: "Demência na doença de Huntington",
            favorito: false
        },
        {
            cid10: "F023",
            descricao: "Demência na doença de Parkinson",
            favorito: false
        },
        {
            cid10: "F024",
            descricao: "Demência na doença pelo vírus da imunodeficiência humana [HIV]",
            favorito: false
        },
        {
            cid10: "F028",
            descricao: "Demência em outras doenças especificadas classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "F03",
            descricao: "Demência não especificada",
            favorito: false
        },
        {
            cid10: "F04",
            descricao: "Síndrome amnésica orgânica não induzida pelo álcool ou por outras substâncias psicoativas",
            favorito: false
        },
        {
            cid10: "F050",
            descricao: "Delirium não superposto a uma demência, assim descrito",
            favorito: false
        },
        {
            cid10: "F051",
            descricao: "Delirium superposto a uma demência",
            favorito: false
        },
        {
            cid10: "F058",
            descricao: "Outro delirium",
            favorito: false
        },
        {
            cid10: "F059",
            descricao: "Delirium não especificado",
            favorito: false
        },
        {
            cid10: "F060",
            descricao: "Alucinose orgânica",
            favorito: false
        },
        {
            cid10: "F061",
            descricao: "Estado catatônico orgânico",
            favorito: false
        },
        {
            cid10: "F062",
            descricao: "Transtorno delirante orgânico [tipo esquizofrênico]",
            favorito: false
        },
        {
            cid10: "F063",
            descricao: "Transtornos do humor [afetivos] orgânicos",
            favorito: false
        },
        {
            cid10: "F064",
            descricao: "Transtornos da ansiedade orgânicos",
            favorito: false
        },
        {
            cid10: "F065",
            descricao: "Transtorno dissociativo orgânico",
            favorito: false
        },
        {
            cid10: "F066",
            descricao: "Transtorno de labilidade emocional [astênico] orgânico",
            favorito: false
        },
        {
            cid10: "F067",
            descricao: "Transtorno cognitivo leve",
            favorito: false
        },
        {
            cid10: "F068",
            descricao: "Outros transtornos mentais especificados devidos a uma lesão e disfunção cerebral e a uma doença física",
            favorito: false
        },
        {
            cid10: "F069",
            descricao: "Transtorno mental não especificado devido a uma lesão e disfunção cerebral e a uma doença física",
            favorito: false
        },
        {
            cid10: "F070",
            descricao: "Transtorno orgânico da personalidade",
            favorito: false
        },
        {
            cid10: "F071",
            descricao: "Síndrome pós-encefalítica",
            favorito: false
        },
        {
            cid10: "F072",
            descricao: "Síndrome pós-traumática",
            favorito: false
        },
        {
            cid10: "F078",
            descricao: "Outros transtornos orgânicos da personalidade e do comportamento devidos a doença cerebral, lesão e disfunção",
            favorito: false
        },
        {
            cid10: "F079",
            descricao: "Transtorno orgânico não especificado da personalidade e do comportamento devido a doença cerebral, lesão e disfunção",
            favorito: false
        },
        {
            cid10: "F09",
            descricao: "Transtorno mental orgânico ou sintomático não especificado",
            favorito: false
        },
        {
            cid10: "F100",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de álcool - intoxicação aguda",
            favorito: false
        },
        {
            cid10: "F101",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de álcool - uso nocivo para a saúde",
            favorito: false
        },
        {
            cid10: "F102",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de álcool - síndrome de dependência",
            favorito: false
        },
        {
            cid10: "F103",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de álcool - síndrome [estado] de abstinência",
            favorito: false
        },
        {
            cid10: "F104",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de álcool - síndrome de abstinência com delirium",
            favorito: false
        },
        {
            cid10: "F105",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de álcool - transtorno psicótico",
            favorito: false
        },
        {
            cid10: "F106",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de álcool - síndrome amnésica",
            favorito: false
        },
        {
            cid10: "F107",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de álcool - transtorno psicótico residual ou de instalação tardia",
            favorito: false
        },
        {
            cid10: "F108",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de álcool - outros transtornos mentais ou comportamentais",
            favorito: false
        },
        {
            cid10: "F109",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de álcool - transtorno mental ou comportamental não especificado",
            favorito: false
        },
        {
            cid10: "F110",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de opiáceos - intoxicação aguda",
            favorito: false
        },
        {
            cid10: "F111",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de opiáceos - uso nocivo para a saúde",
            favorito: false
        },
        {
            cid10: "F112",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de opiáceos - síndrome de dependência",
            favorito: false
        },
        {
            cid10: "F113",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de opiáceos - síndrome [estado] de abstinência",
            favorito: false
        },
        {
            cid10: "F114",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de opiáceos - síndrome de abstinência com delirium",
            favorito: false
        },
        {
            cid10: "F115",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de opiáceos - transtorno psicótico",
            favorito: false
        },
        {
            cid10: "F116",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de opiáceos - síndrome amnésica",
            favorito: false
        },
        {
            cid10: "F117",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de opiáceos - transtorno psicótico residual ou de instalação tardia",
            favorito: false
        },
        {
            cid10: "F118",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de opiáceos - outros transtornos mentais ou comportamentais",
            favorito: false
        },
        {
            cid10: "F119",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de opiáceos - transtorno mental ou comportamental não especificado",
            favorito: false
        },
        {
            cid10: "F120",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de canabinóides - intoxicação aguda",
            favorito: false
        },
        {
            cid10: "F121",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de canabinóides - uso nocivo para a saúde",
            favorito: false
        },
        {
            cid10: "F122",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de canabinóides - síndrome de dependência",
            favorito: false
        },
        {
            cid10: "F123",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de canabinóides - síndrome [estado] de abstinência",
            favorito: false
        },
        {
            cid10: "F124",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de canabinóides - síndrome de abstinência com delirium",
            favorito: false
        },
        {
            cid10: "F125",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de canabinóides - transtorno psicótico",
            favorito: false
        },
        {
            cid10: "F126",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de canabinóides - síndrome amnésica",
            favorito: false
        },
        {
            cid10: "F127",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de canabinóides - transtorno psicótico residual ou de instalação tardia",
            favorito: false
        },
        {
            cid10: "F128",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de canabinóides - outros transtornos mentais ou comportamentais",
            favorito: false
        },
        {
            cid10: "F129",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de canabinóides - transtorno mental ou comportamental não especificado",
            favorito: false
        },
        {
            cid10: "F130",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de sedativos e hipnóticos - intoxicação aguda",
            favorito: false
        },
        {
            cid10: "F131",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de sedativos e hipnóticos - uso nocivo para a saúde",
            favorito: false
        },
        {
            cid10: "F132",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de sedativos e hipnóticos - síndrome de dependência",
            favorito: false
        },
        {
            cid10: "F133",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de sedativos e hipnóticos - síndrome [estado] de abstinência",
            favorito: false
        },
        {
            cid10: "F134",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de sedativos e hipnóticos - síndrome de abstinência com delirium",
            favorito: false
        },
        {
            cid10: "F135",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de sedativos e hipnóticos - transtorno psicótico",
            favorito: false
        },
        {
            cid10: "F136",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de sedativos e hipnóticos - síndrome amnésica",
            favorito: false
        },
        {
            cid10: "F137",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de sedativos e hipnóticos - transtorno psicótico residual ou de instalação tardia",
            favorito: false
        },
        {
            cid10: "F138",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de sedativos e hipnóticos - outros transtornos mentais ou comportamentais",
            favorito: false
        },
        {
            cid10: "F139",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de sedativos e hipnóticos - transtorno mental ou comportamental não especificado",
            favorito: false
        },
        {
            cid10: "F140",
            descricao: "Transtornos mentais e comportamentais devidos ao uso da cocaína - intoxicação aguda",
            favorito: false
        },
        {
            cid10: "F141",
            descricao: "Transtornos mentais e comportamentais devidos ao uso da cocaína - uso nocivo para a saúde",
            favorito: false
        },
        {
            cid10: "F142",
            descricao: "Transtornos mentais e comportamentais devidos ao uso da cocaína - síndrome de dependência",
            favorito: false
        },
        {
            cid10: "F143",
            descricao: "Transtornos mentais e comportamentais devidos ao uso da cocaína - síndrome [estado] de abstinência",
            favorito: false
        },
        {
            cid10: "F144",
            descricao: "Transtornos mentais e comportamentais devidos ao uso da cocaína - síndrome de abstinência com delirium",
            favorito: false
        },
        {
            cid10: "F145",
            descricao: "Transtornos mentais e comportamentais devidos ao uso da cocaína - transtorno psicótico",
            favorito: false
        },
        {
            cid10: "F146",
            descricao: "Transtornos mentais e comportamentais devidos ao uso da cocaína - síndrome amnésica",
            favorito: false
        },
        {
            cid10: "F147",
            descricao: "Transtornos mentais e comportamentais devidos ao uso da cocaína - transtorno psicótico residual ou de instalação tardia",
            favorito: false
        },
        {
            cid10: "F148",
            descricao: "Transtornos mentais e comportamentais devidos ao uso da cocaína - outros transtornos mentais ou comportamentais",
            favorito: false
        },
        {
            cid10: "F149",
            descricao: "Transtornos mentais e comportamentais devidos ao uso da cocaína - transtorno mental ou comportamental não especificado",
            favorito: false
        },
        {
            cid10: "F150",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de outros estimulantes, inclusive a cafeína - intoxicação aguda",
            favorito: false
        },
        {
            cid10: "F151",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de outros estimulantes, inclusive a cafeína - uso nocivo para a saúde",
            favorito: false
        },
        {
            cid10: "F152",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de outros estimulantes, inclusive a cafeína - síndrome de dependência",
            favorito: false
        },
        {
            cid10: "F153",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de outros estimulantes, inclusive a cafeína - síndrome [estado] de abstinência",
            favorito: false
        },
        {
            cid10: "F154",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de outros estimulantes, inclusive a cafeína - síndrome de abstinência com delirium",
            favorito: false
        },
        {
            cid10: "F155",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de outros estimulantes, inclusive a cafeína - transtorno psicótico",
            favorito: false
        },
        {
            cid10: "F156",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de outros estimulantes, inclusive a cafeína - síndrome amnésica",
            favorito: false
        },
        {
            cid10: "F157",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de outros estimulantes, inclusive a cafeína - transtorno psicótico residual ou de instalação tardia",
            favorito: false
        },
        {
            cid10: "F158",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de outros estimulantes, inclusive a cafeína - outros transtornos mentais ou comportamentais",
            favorito: false
        },
        {
            cid10: "F159",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de outros estimulantes, inclusive a cafeína - transtorno mental ou comportamental não especificado",
            favorito: false
        },
        {
            cid10: "F160",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de alucinógenos - intoxicação aguda",
            favorito: false
        },
        {
            cid10: "F161",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de alucinógenos - uso nocivo para a saúde",
            favorito: false
        },
        {
            cid10: "F162",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de alucinógenos - síndrome de dependência",
            favorito: false
        },
        {
            cid10: "F163",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de alucinógenos - síndrome [estado] de abstinência",
            favorito: false
        },
        {
            cid10: "F164",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de alucinógenos - síndrome de abstinência com delirium",
            favorito: false
        },
        {
            cid10: "F165",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de alucinógenos - transtorno psicótico",
            favorito: false
        },
        {
            cid10: "F166",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de alucinógenos - síndrome amnésica",
            favorito: false
        },
        {
            cid10: "F167",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de alucinógenos - transtorno psicótico residual ou de instalação tardia",
            favorito: false
        },
        {
            cid10: "F168",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de alucinógenos - outros transtornos mentais ou comportamentais",
            favorito: false
        },
        {
            cid10: "F169",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de alucinógenos - transtorno mental ou comportamental não especificado",
            favorito: false
        },
        {
            cid10: "F170",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de fumo - intoxicação aguda",
            favorito: false
        },
        {
            cid10: "F171",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de fumo - uso nocivo para a saúde",
            favorito: false
        },
        {
            cid10: "F172",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de fumo - síndrome de dependência",
            favorito: false
        },
        {
            cid10: "F173",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de fumo - síndrome [estado] de abstinência",
            favorito: false
        },
        {
            cid10: "F174",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de fumo - síndrome de abstinência com delirium",
            favorito: false
        },
        {
            cid10: "F175",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de fumo - transtorno psicótico",
            favorito: false
        },
        {
            cid10: "F176",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de fumo - síndrome amnésica",
            favorito: false
        },
        {
            cid10: "F177",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de fumo - transtorno psicótico residual ou de instalação tardia",
            favorito: false
        },
        {
            cid10: "F178",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de fumo - outros transtornos mentais ou comportamentais",
            favorito: false
        },
        {
            cid10: "F179",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de fumo - transtorno mental ou comportamental não especificado",
            favorito: false
        },
        {
            cid10: "F180",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de solventes voláteis - intoxicação aguda",
            favorito: false
        },
        {
            cid10: "F181",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de solventes voláteis - uso nocivo para a saúde",
            favorito: false
        },
        {
            cid10: "F182",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de solventes voláteis - síndrome de dependência",
            favorito: false
        },
        {
            cid10: "F183",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de solventes voláteis - síndrome [estado] de abstinência",
            favorito: false
        },
        {
            cid10: "F184",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de solventes voláteis - síndrome de abstinência com delirium",
            favorito: false
        },
        {
            cid10: "F185",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de solventes voláteis - transtorno psicótico",
            favorito: false
        },
        {
            cid10: "F186",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de solventes voláteis - síndrome amnésica",
            favorito: false
        },
        {
            cid10: "F187",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de solventes voláteis - transtorno psicótico residual ou de instalação tardia",
            favorito: false
        },
        {
            cid10: "F188",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de solventes voláteis - outros transtornos mentais ou comportamentais",
            favorito: false
        },
        {
            cid10: "F189",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de solventes voláteis - transtorno mental ou comportamental não especificado",
            favorito: false
        },
        {
            cid10: "F190",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de múltiplas drogas e ao uso de outras substâncias psicoativas - intoxicação aguda",
            favorito: false
        },
        {
            cid10: "F191",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de múltiplas drogas e ao uso de outras substâncias psicoativas - uso nocivo para a saúde",
            favorito: false
        },
        {
            cid10: "F192",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de múltiplas drogas e ao uso de outras substâncias psicoativas - síndrome de dependência",
            favorito: false
        },
        {
            cid10: "F193",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de múltiplas drogas e ao uso de outras substâncias psicoativas - síndrome [estado] de abstinência",
            favorito: false
        },
        {
            cid10: "F194",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de múltiplas drogas e ao uso de outras substâncias psicoativas - síndrome de abstinência com delirium",
            favorito: false
        },
        {
            cid10: "F195",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de múltiplas drogas e ao uso de outras substâncias psicoativas - transtorno psicótico",
            favorito: false
        },
        {
            cid10: "F196",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de múltiplas drogas e ao uso de outras substâncias psicoativas - síndrome amnésica",
            favorito: false
        },
        {
            cid10: "F197",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de múltiplas drogas e ao uso de outras substâncias psicoativas - transtorno psicótico residual ou de instalação tardia",
            favorito: false
        },
        {
            cid10: "F198",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de múltiplas drogas e ao uso de outras substâncias psicoativas - outros transtornos mentais ou comportamentais",
            favorito: false
        },
        {
            cid10: "F199",
            descricao: "Transtornos mentais e comportamentais devidos ao uso de múltiplas drogas e ao uso de outras substâncias psicoativas - transtorno mental ou comportamental não especificado",
            favorito: false
        },
        {
            cid10: "F200",
            descricao: "Esquizofrenia paranóide",
            favorito: false
        },
        {
            cid10: "F201",
            descricao: "Esquizofrenia hebefrênica",
            favorito: false
        },
        {
            cid10: "F202",
            descricao: "Esquizofrenia catatônica",
            favorito: false
        },
        {
            cid10: "F203",
            descricao: "Esquizofrenia indiferenciada",
            favorito: false
        },
        {
            cid10: "F204",
            descricao: "Depressão pós-esquizofrênica",
            favorito: false
        },
        {
            cid10: "F205",
            descricao: "Esquizofrenia residual",
            favorito: false
        },
        {
            cid10: "F206",
            descricao: "Esquizofrenia simples",
            favorito: false
        },
        {
            cid10: "F208",
            descricao: "Outras esquizofrenias",
            favorito: false
        },
        {
            cid10: "F209",
            descricao: "Esquizofrenia não especificada",
            favorito: false
        },
        {
            cid10: "F21",
            descricao: "Transtorno esquizotípico",
            favorito: false
        },
        {
            cid10: "F220",
            descricao: "Transtorno delirante",
            favorito: false
        },
        {
            cid10: "F228",
            descricao: "Outros transtornos delirantes persistentes",
            favorito: false
        },
        {
            cid10: "F229",
            descricao: "Transtorno delirante persistente não especificado",
            favorito: false
        },
        {
            cid10: "F230",
            descricao: "Transtorno psicótico agudo polimorfo, sem sintomas esquizofrênicos",
            favorito: false
        },
        {
            cid10: "F231",
            descricao: "Transtorno psicótico agudo polimorfo, com sintomas esquizofrênicos",
            favorito: false
        },
        {
            cid10: "F232",
            descricao: "Transtorno psicótico agudo de tipo esquizofrênico (schizophrenia-like)",
            favorito: false
        },
        {
            cid10: "F233",
            descricao: "Outros transtornos psicóticos agudos, essencialmente delirantes",
            favorito: false
        },
        {
            cid10: "F238",
            descricao: "Outros transtornos psicóticos agudos e transitórios",
            favorito: false
        },
        {
            cid10: "F239",
            descricao: "Transtorno psicótico agudo e transitório não especificado",
            favorito: false
        },
        {
            cid10: "F24",
            descricao: "Transtorno delirante induzido",
            favorito: false
        },
        {
            cid10: "F250",
            descricao: "Transtorno esquizoafetivo do tipo maníaco",
            favorito: false
        },
        {
            cid10: "F251",
            descricao: "Transtorno esquizoafetivo do tipo depressivo",
            favorito: false
        },
        {
            cid10: "F252",
            descricao: "Transtorno esquizoafetivo do tipo misto",
            favorito: false
        },
        {
            cid10: "F258",
            descricao: "Outros transtornos esquizoafetivos",
            favorito: false
        },
        {
            cid10: "F259",
            descricao: "Transtorno esquizoafetivo não especificado",
            favorito: false
        },
        {
            cid10: "F28",
            descricao: "Outros transtornos psicóticos não-orgânicos",
            favorito: false
        },
        {
            cid10: "F29",
            descricao: "Psicose não-orgânica não especificada",
            favorito: false
        },
        {
            cid10: "F300",
            descricao: "Hipomania",
            favorito: false
        },
        {
            cid10: "F301",
            descricao: "Mania sem sintomas psicóticos",
            favorito: false
        },
        {
            cid10: "F302",
            descricao: "Mania com sintomas psicóticos",
            favorito: false
        },
        {
            cid10: "F308",
            descricao: "Outros episódios maníacos",
            favorito: false
        },
        {
            cid10: "F309",
            descricao: "Episódio maníaco não especificado",
            favorito: false
        },
        {
            cid10: "F310",
            descricao: "Transtorno afetivo bipolar, episódio atual hipomaníaco",
            favorito: false
        },
        {
            cid10: "F311",
            descricao: "Transtorno afetivo bipolar, episódio atual maníaco sem sintomas psicóticos",
            favorito: false
        },
        {
            cid10: "F312",
            descricao: "Transtorno afetivo bipolar, episódio atual maníaco com sintomas psicóticos",
            favorito: false
        },
        {
            cid10: "F313",
            descricao: "Transtorno afetivo bipolar, episódio atual depressivo leve ou moderado",
            favorito: false
        },
        {
            cid10: "F314",
            descricao: "Transtorno afetivo bipolar, episódio atual depressivo grave sem sintomas psicóticos",
            favorito: false
        },
        {
            cid10: "F315",
            descricao: "Transtorno afetivo bipolar, episódio atual depressivo grave com sintomas psicóticos",
            favorito: false
        },
        {
            cid10: "F316",
            descricao: "Transtorno afetivo bipolar, episódio atual misto",
            favorito: false
        },
        {
            cid10: "F317",
            descricao: "Transtorno afetivo bipolar, atualmente em remissão",
            favorito: false
        },
        {
            cid10: "F318",
            descricao: "Outros transtornos afetivos bipolares",
            favorito: false
        },
        {
            cid10: "F319",
            descricao: "Transtorno afetivo bipolar não especificado",
            favorito: false
        },
        {
            cid10: "F320",
            descricao: "Episódio depressivo leve",
            favorito: false
        },
        {
            cid10: "F321",
            descricao: "Episódio depressivo moderado",
            favorito: false
        },
        {
            cid10: "F322",
            descricao: "Episódio depressivo grave sem sintomas psicóticos",
            favorito: false
        },
        {
            cid10: "F323",
            descricao: "Episódio depressivo grave com sintomas psicóticos",
            favorito: false
        },
        {
            cid10: "F328",
            descricao: "Outros episódios depressivos",
            favorito: false
        },
        {
            cid10: "F329",
            descricao: "Episódio depressivo não especificado",
            favorito: false
        },
        {
            cid10: "F330",
            descricao: "Transtorno depressivo recorrente, episódio atual leve",
            favorito: false
        },
        {
            cid10: "F331",
            descricao: "Transtorno depressivo recorrente, episódio atual moderado",
            favorito: false
        },
        {
            cid10: "F332",
            descricao: "Transtorno depressivo recorrente, episódio atual grave sem sintomas psicóticos",
            favorito: false
        },
        {
            cid10: "F333",
            descricao: "Transtorno depressivo recorrente, episódio atual grave com sintomas psicóticos",
            favorito: false
        },
        {
            cid10: "F334",
            descricao: "Transtorno depressivo recorrente, atualmente em remissão",
            favorito: false
        },
        {
            cid10: "F338",
            descricao: "Outros transtornos depressivos recorrentes",
            favorito: false
        },
        {
            cid10: "F339",
            descricao: "Transtorno depressivo recorrente sem especificação",
            favorito: false
        },
        {
            cid10: "F340",
            descricao: "Ciclotimia",
            favorito: false
        },
        {
            cid10: "F341",
            descricao: "Distimia",
            favorito: false
        },
        {
            cid10: "F348",
            descricao: "Outros transtornos do humor [afetivos] persistentes",
            favorito: false
        },
        {
            cid10: "F349",
            descricao: "Transtorno do humor [afetivo] persistente não especificado",
            favorito: false
        },
        {
            cid10: "F380",
            descricao: "Outros transtornos do humor [afetivos] isolados",
            favorito: false
        },
        {
            cid10: "F381",
            descricao: "Outros transtornos do humor [afetivos] recorrentes",
            favorito: false
        },
        {
            cid10: "F388",
            descricao: "Outros transtornos especificados do humor [afetivos]",
            favorito: false
        },
        {
            cid10: "F39",
            descricao: "Transtorno do humor [afetivo] não especificado",
            favorito: false
        },
        {
            cid10: "F400",
            descricao: "Agorafobia",
            favorito: false
        },
        {
            cid10: "F401",
            descricao: "Fobias sociais",
            favorito: false
        },
        {
            cid10: "F402",
            descricao: "Fobias específicas (isoladas)",
            favorito: false
        },
        {
            cid10: "F408",
            descricao: "Outros transtornos fóbico-ansiosos",
            favorito: false
        },
        {
            cid10: "F409",
            descricao: "Transtorno fóbico-ansioso não especificado",
            favorito: false
        },
        {
            cid10: "F410",
            descricao: "Transtorno de pânico [ansiedade paroxística episódica]",
            favorito: false
        },
        {
            cid10: "F411",
            descricao: "Ansiedade generalizada",
            favorito: false
        },
        {
            cid10: "F412",
            descricao: "Transtorno misto ansioso e depressivo",
            favorito: false
        },
        {
            cid10: "F413",
            descricao: "Outros transtornos ansiosos mistos",
            favorito: false
        },
        {
            cid10: "F418",
            descricao: "Outros transtornos ansiosos especificados",
            favorito: false
        },
        {
            cid10: "F419",
            descricao: "Transtorno ansioso não especificado",
            favorito: false
        },
        {
            cid10: "F420",
            descricao: "Transtorno obsessivo-compulsivo com predominância de idéias ou de ruminações obsessivas",
            favorito: false
        },
        {
            cid10: "F421",
            descricao: "Transtorno obsessivo-compulsivo com predominância de comportamentos compulsivos [rituais obsessivos]",
            favorito: false
        },
        {
            cid10: "F422",
            descricao: "Transtorno obsessivo-compulsivo, forma mista, com idéias obsessivas e comportamentos compulsivos",
            favorito: false
        },
        {
            cid10: "F428",
            descricao: "Outros transtornos obsessivo-compulsivos",
            favorito: false
        },
        {
            cid10: "F429",
            descricao: "Transtorno obsessivo-compulsivo não especificado",
            favorito: false
        },
        {
            cid10: "F430",
            descricao: "Reação aguda ao stress",
            favorito: false
        },
        {
            cid10: "F431",
            descricao: "Estado de stress pós-traumático",
            favorito: false
        },
        {
            cid10: "F432",
            descricao: "Transtornos de adaptação",
            favorito: false
        },
        {
            cid10: "F438",
            descricao: "Outras reações ao stress grave",
            favorito: false
        },
        {
            cid10: "F439",
            descricao: "Reação não especificada a um stress grave",
            favorito: false
        },
        {
            cid10: "F440",
            descricao: "Amnésia dissociativa",
            favorito: false
        },
        {
            cid10: "F441",
            descricao: "Fuga dissociativa",
            favorito: false
        },
        {
            cid10: "F442",
            descricao: "Estupor dissociativo",
            favorito: false
        },
        {
            cid10: "F443",
            descricao: "Estados de transe e de possessão",
            favorito: false
        },
        {
            cid10: "F444",
            descricao: "Transtornos dissociativos do movimento",
            favorito: false
        },
        {
            cid10: "F445",
            descricao: "Convulsões dissociativas",
            favorito: false
        },
        {
            cid10: "F446",
            descricao: "Anestesia e perda sensorial dissociativas",
            favorito: false
        },
        {
            cid10: "F447",
            descricao: "Transtorno dissociativo misto [de conversão]",
            favorito: false
        },
        {
            cid10: "F448",
            descricao: "Outros transtornos dissociativos [de conversão]",
            favorito: false
        },
        {
            cid10: "F449",
            descricao: "Transtorno dissociativo [de conversão] não especificado",
            favorito: false
        },
        {
            cid10: "F450",
            descricao: "Transtorno de somatização",
            favorito: false
        },
        {
            cid10: "F451",
            descricao: "Transtorno somatoforme indiferenciado",
            favorito: false
        },
        {
            cid10: "F452",
            descricao: "Transtorno hipocondríaco",
            favorito: false
        },
        {
            cid10: "F453",
            descricao: "Transtorno neurovegetativo somatoforme",
            favorito: false
        },
        {
            cid10: "F454",
            descricao: "Transtorno doloroso somatoforme persistente",
            favorito: false
        },
        {
            cid10: "F458",
            descricao: "Outros transtornos somatoformes",
            favorito: false
        },
        {
            cid10: "F459",
            descricao: "Transtorno somatoforme não especificado",
            favorito: false
        },
        {
            cid10: "F480",
            descricao: "Neurastenia",
            favorito: false
        },
        {
            cid10: "F481",
            descricao: "Síndrome de despersonalização-desrealização",
            favorito: false
        },
        {
            cid10: "F488",
            descricao: "Outros transtornos neuróticos especificados",
            favorito: false
        },
        {
            cid10: "F489",
            descricao: "Transtorno neurótico não especificado",
            favorito: false
        },
        {
            cid10: "F500",
            descricao: "Anorexia nervosa",
            favorito: false
        },
        {
            cid10: "F501",
            descricao: "Anorexia nervosa atípica",
            favorito: false
        },
        {
            cid10: "F502",
            descricao: "Bulimia nervosa",
            favorito: false
        },
        {
            cid10: "F503",
            descricao: "Bulimia nervosa atípica",
            favorito: false
        },
        {
            cid10: "F504",
            descricao: "Hiperfagia associada a outros distúrbios psicológicos",
            favorito: false
        },
        {
            cid10: "F505",
            descricao: "Vômitos associados a outros distúrbios psicológicos",
            favorito: false
        },
        {
            cid10: "F508",
            descricao: "Outros transtornos da alimentação",
            favorito: false
        },
        {
            cid10: "F509",
            descricao: "Transtorno de alimentação não especificado",
            favorito: false
        },
        {
            cid10: "F510",
            descricao: "Insônia não-orgânica",
            favorito: false
        },
        {
            cid10: "F511",
            descricao: "Hipersonia não-orgânica",
            favorito: false
        },
        {
            cid10: "F512",
            descricao: "Transtorno do ciclo vigília-sono devido a fatores não-orgânicos",
            favorito: false
        },
        {
            cid10: "F513",
            descricao: "Sonambulismo",
            favorito: false
        },
        {
            cid10: "F514",
            descricao: "Terrores noturnos",
            favorito: false
        },
        {
            cid10: "F515",
            descricao: "Pesadelos",
            favorito: false
        },
        {
            cid10: "F518",
            descricao: "Outros transtornos do sono devidos a fatores não-orgânicos",
            favorito: false
        },
        {
            cid10: "F519",
            descricao: "Transtorno do sono devido a fatores não-orgânicos não especificados",
            favorito: false
        },
        {
            cid10: "F520",
            descricao: "Ausência ou perda do desejo sexual",
            favorito: false
        },
        {
            cid10: "F521",
            descricao: "Aversão sexual e ausência de prazer sexual",
            favorito: false
        },
        {
            cid10: "F522",
            descricao: "Falha de resposta genital",
            favorito: false
        },
        {
            cid10: "F523",
            descricao: "Disfunção orgásmica",
            favorito: false
        },
        {
            cid10: "F524",
            descricao: "Ejaculação precoce",
            favorito: false
        },
        {
            cid10: "F525",
            descricao: "Vaginismo não-orgânico",
            favorito: false
        },
        {
            cid10: "F526",
            descricao: "Dispareunia não-orgânica",
            favorito: false
        },
        {
            cid10: "F527",
            descricao: "Apetite sexual excessivo",
            favorito: false
        },
        {
            cid10: "F528",
            descricao: "Outras disfunções sexuais não devidas a transtorno ou à doença orgânica",
            favorito: false
        },
        {
            cid10: "F529",
            descricao: "Disfunção sexual não devida a transtorno ou à doença orgânica, não especificada",
            favorito: false
        },
        {
            cid10: "F530",
            descricao: "Transtornos mentais e comportamentais leves associados ao puerpério não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "F531",
            descricao: "Transtornos mentais e comportamentais graves associados ao puerpério não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "F538",
            descricao: "Outros transtornos mentais e comportamentais associados ao puerpério não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "F539",
            descricao: "Transtorno mental e comportamental associado ao puerpério, não especificado",
            favorito: false
        },
        {
            cid10: "F54",
            descricao: "Fatores psicológicos ou comportamentais associados a doença ou a transtornos classificados em outra parte",
            favorito: false
        },
        {
            cid10: "F55",
            descricao: "Abuso de substâncias que não produzem dependência",
            favorito: false
        },
        {
            cid10: "F59",
            descricao: "Síndromes comportamentais associados a transtornos das funções fisiológicas e a fatores físicos, não especificadas",
            favorito: false
        },
        {
            cid10: "F600",
            descricao: "Personalidade paranóica",
            favorito: false
        },
        {
            cid10: "F601",
            descricao: "Personalidade esquizóide",
            favorito: false
        },
        {
            cid10: "F602",
            descricao: "Personalidade dissocial",
            favorito: false
        },
        {
            cid10: "F603",
            descricao: "Transtorno de personalidade com instabilidade emocional",
            favorito: false
        },
        {
            cid10: "F604",
            descricao: "Personalidade histriônica",
            favorito: false
        },
        {
            cid10: "F605",
            descricao: "Personalidade anancástica",
            favorito: false
        },
        {
            cid10: "F606",
            descricao: "Personalidade ansiosa [esquiva]",
            favorito: false
        },
        {
            cid10: "F607",
            descricao: "Personalidade dependente",
            favorito: false
        },
        {
            cid10: "F608",
            descricao: "Outros transtornos específicos da personalidade",
            favorito: false
        },
        {
            cid10: "F609",
            descricao: "Transtorno não especificado da personalidade",
            favorito: false
        },
        {
            cid10: "F61",
            descricao: "Transtornos mistos da personalidade e outros transtornos da personalidade",
            favorito: false
        },
        {
            cid10: "F620",
            descricao: "Modificação duradoura da personalidade após uma experiência catastrófica",
            favorito: false
        },
        {
            cid10: "F621",
            descricao: "Modificação duradoura da personalidade após doença psiquiátrica",
            favorito: false
        },
        {
            cid10: "F628",
            descricao: "Outras modificações duradouras da personalidade",
            favorito: false
        },
        {
            cid10: "F629",
            descricao: "Modificação duradoura da personalidade, não especificada",
            favorito: false
        },
        {
            cid10: "F630",
            descricao: "Jogo patológico",
            favorito: false
        },
        {
            cid10: "F631",
            descricao: "Piromania",
            favorito: false
        },
        {
            cid10: "F632",
            descricao: "Roubo patológico [cleptomania]",
            favorito: false
        },
        {
            cid10: "F633",
            descricao: "Tricotilomania",
            favorito: false
        },
        {
            cid10: "F638",
            descricao: "Outros transtornos dos hábitos e dos impulsos",
            favorito: false
        },
        {
            cid10: "F639",
            descricao: "Transtorno dos hábitos e impulsos, não especificado",
            favorito: false
        },
        {
            cid10: "F640",
            descricao: "Transexualismo",
            favorito: false
        },
        {
            cid10: "F641",
            descricao: "Travestismo bivalente",
            favorito: false
        },
        {
            cid10: "F642",
            descricao: "Transtorno de identidade sexual na infância",
            favorito: false
        },
        {
            cid10: "F648",
            descricao: "Outros transtornos da identidade sexual",
            favorito: false
        },
        {
            cid10: "F649",
            descricao: "Transtorno não especificado da identidade sexual",
            favorito: false
        },
        {
            cid10: "F650",
            descricao: "Fetichismo",
            favorito: false
        },
        {
            cid10: "F651",
            descricao: "Travestismo fetichista",
            favorito: false
        },
        {
            cid10: "F652",
            descricao: "Exibicionismo",
            favorito: false
        },
        {
            cid10: "F653",
            descricao: "Voyeurismo",
            favorito: false
        },
        {
            cid10: "F654",
            descricao: "Pedofilia",
            favorito: false
        },
        {
            cid10: "F655",
            descricao: "Sadomasoquismo",
            favorito: false
        },
        {
            cid10: "F656",
            descricao: "Transtornos múltiplos da preferência sexual",
            favorito: false
        },
        {
            cid10: "F658",
            descricao: "Outros transtornos da preferência sexual",
            favorito: false
        },
        {
            cid10: "F659",
            descricao: "Transtorno da preferência sexual, não especificado",
            favorito: false
        },
        {
            cid10: "F660",
            descricao: "Transtorno da maturação sexual",
            favorito: false
        },
        {
            cid10: "F661",
            descricao: "Orientação sexual egodistônica",
            favorito: false
        },
        {
            cid10: "F662",
            descricao: "Transtorno do relacionamento sexual",
            favorito: false
        },
        {
            cid10: "F668",
            descricao: "Outros transtornos do desenvolvimento psicossexual",
            favorito: false
        },
        {
            cid10: "F669",
            descricao: "Transtorno do desenvolvimento sexual, não especificado",
            favorito: false
        },
        {
            cid10: "F680",
            descricao: "Sintomas físicos aumentados por fatores psicológicos",
            favorito: false
        },
        {
            cid10: "F681",
            descricao: "Produção deliberada ou simulação de sintomas ou de incapacidades, físicas ou psicológicas [transtorno fictício]",
            favorito: false
        },
        {
            cid10: "F688",
            descricao: "Outros transtornos especificados da personalidade e do comportamento do adulto",
            favorito: false
        },
        {
            cid10: "F69",
            descricao: "Transtorno da personalidade e do comportamento do adulto, não especificado",
            favorito: false
        },
        {
            cid10: "F700",
            descricao: "Retardo mental leve - menção de ausência de ou de comprometimento mínimo do comportamento",
            favorito: false
        },
        {
            cid10: "F701",
            descricao: "Retardo mental leve - comprometimento significativo do comportamento, requerendo vigilância ou tratamento",
            favorito: false
        },
        {
            cid10: "F708",
            descricao: "Retardo mental leve - outros comprometimentos do comportamento",
            favorito: false
        },
        {
            cid10: "F709",
            descricao: "Retardo mental leve - sem menção de comprometimento do comportamento",
            favorito: false
        },
        {
            cid10: "F710",
            descricao: "Retardo mental moderado - menção de ausência de ou de comprometimento mínimo do comportamento",
            favorito: false
        },
        {
            cid10: "F711",
            descricao: "Retardo mental moderado - comprometimento significativo do comportamento, requerendo vigilância ou tratamento",
            favorito: false
        },
        {
            cid10: "F718",
            descricao: "Retardo mental moderado - outros comprometimentos do comportamento",
            favorito: false
        },
        {
            cid10: "F719",
            descricao: "Retardo mental moderado - sem menção de comprometimento do comportamento",
            favorito: false
        },
        {
            cid10: "F720",
            descricao: "Retardo mental grave - menção de ausência de ou de comprometimento mínimo do comportamento",
            favorito: false
        },
        {
            cid10: "F721",
            descricao: "Retardo mental grave - comprometimento significativo do comportamento, requerendo vigilância ou tratamento",
            favorito: false
        },
        {
            cid10: "F728",
            descricao: "Retardo mental grave - outros comprometimentos do comportamento",
            favorito: false
        },
        {
            cid10: "F729",
            descricao: "Retardo mental grave - sem menção de comprometimento do comportamento",
            favorito: false
        },
        {
            cid10: "F730",
            descricao: "Retardo mental profundo - menção de ausência de ou de comprometimento mínimo do comportamento",
            favorito: false
        },
        {
            cid10: "F731",
            descricao: "Retardo mental profundo - comprometimento significativo do comportamento, requerendo vigilância ou tratamento",
            favorito: false
        },
        {
            cid10: "F738",
            descricao: "Retardo mental profundo - outros comprometimentos do comportamento",
            favorito: false
        },
        {
            cid10: "F739",
            descricao: "Retardo mental profundo - sem menção de comprometimento do comportamento",
            favorito: false
        },
        {
            cid10: "F780",
            descricao: "Outro retardo mental - menção de ausência de ou de comprometimento mínimo do comportamento",
            favorito: false
        },
        {
            cid10: "F781",
            descricao: "Outro retardo mental - comprometimento significativo do comportamento, requerendo vigilância ou tratamento",
            favorito: false
        },
        {
            cid10: "F788",
            descricao: "Outro retardo mental - outros comprometimentos do comportamento",
            favorito: false
        },
        {
            cid10: "F789",
            descricao: "Outro retardo mental - sem menção de comprometimento do comportamento",
            favorito: false
        },
        {
            cid10: "F790",
            descricao: "Retardo mental não especificado - menção de ausência de ou de comprometimento mínimo do comportamento",
            favorito: false
        },
        {
            cid10: "F791",
            descricao: "Retardo mental não especificado - comprometimento significativo do comportamento, requerendo vigilância ou tratamento",
            favorito: false
        },
        {
            cid10: "F798",
            descricao: "Retardo mental não especificado - outros comprometimentos do comportamento",
            favorito: false
        },
        {
            cid10: "F799",
            descricao: "Retardo mental não especificado - sem menção de comprometimento do comportamento",
            favorito: false
        },
        {
            cid10: "F800",
            descricao: "Transtorno específico da articulação da fala",
            favorito: false
        },
        {
            cid10: "F801",
            descricao: "Transtorno expressivo de linguagem",
            favorito: false
        },
        {
            cid10: "F802",
            descricao: "Transtorno receptivo da linguagem",
            favorito: false
        },
        {
            cid10: "F803",
            descricao: "Afasia adquirida com epilepsia [síndrome de Landau-Kleffner]",
            favorito: false
        },
        {
            cid10: "F808",
            descricao: "Outros transtornos de desenvolvimento da fala ou da linguagem",
            favorito: false
        },
        {
            cid10: "F809",
            descricao: "Transtorno não especificado do desenvolvimento da fala ou da linguagem",
            favorito: false
        },
        {
            cid10: "F810",
            descricao: "Transtorno específico de leitura",
            favorito: false
        },
        {
            cid10: "F811",
            descricao: "Transtorno específico da soletração",
            favorito: false
        },
        {
            cid10: "F812",
            descricao: "Transtorno específico da habilidade em aritmética",
            favorito: false
        },
        {
            cid10: "F813",
            descricao: "Transtorno misto de habilidades escolares",
            favorito: false
        },
        {
            cid10: "F818",
            descricao: "Outros transtornos do desenvolvimento das habilidades escolares",
            favorito: false
        },
        {
            cid10: "F819",
            descricao: "Transtorno não especificado do desenvolvimento das habilidades escolares",
            favorito: false
        },
        {
            cid10: "F82",
            descricao: "Transtorno específico do desenvolvimento motor",
            favorito: false
        },
        {
            cid10: "F83",
            descricao: "Transtornos específicos misto do desenvolvimento",
            favorito: false
        },
        {
            cid10: "F840",
            descricao: "Autismo infantil",
            favorito: false
        },
        {
            cid10: "F841",
            descricao: "Autismo atípico",
            favorito: false
        },
        {
            cid10: "F842",
            descricao: "Síndrome de Rett",
            favorito: false
        },
        {
            cid10: "F843",
            descricao: "Outro transtorno desintegrativo da infância",
            favorito: false
        },
        {
            cid10: "F844",
            descricao: "Transtorno com hipercinesia associada a retardo mental e a movimentos estereotipados",
            favorito: false
        },
        {
            cid10: "F845",
            descricao: "Síndrome de Asperger",
            favorito: false
        },
        {
            cid10: "F848",
            descricao: "Outros transtornos globais do desenvolvimento",
            favorito: false
        },
        {
            cid10: "F849",
            descricao: "Transtornos globais não especificados do desenvolvimento",
            favorito: false
        },
        {
            cid10: "F88",
            descricao: "Outros transtornos do desenvolvimento psicológico",
            favorito: false
        },
        {
            cid10: "F89",
            descricao: "Transtorno do desenvolvimento psicológico não especificado",
            favorito: false
        },
        {
            cid10: "F900",
            descricao: "Distúrbios da atividade e da atenção",
            favorito: false
        },
        {
            cid10: "F901",
            descricao: "Transtorno hipercinético de conduta",
            favorito: false
        },
        {
            cid10: "F908",
            descricao: "Outros transtornos hipercinéticos",
            favorito: false
        },
        {
            cid10: "F909",
            descricao: "Transtorno hipercinético não especificado",
            favorito: false
        },
        {
            cid10: "F910",
            descricao: "Distúrbio de conduta restrito ao contexto familiar",
            favorito: false
        },
        {
            cid10: "F911",
            descricao: "Distúrbio de conduta não-socializado",
            favorito: false
        },
        {
            cid10: "F912",
            descricao: "Distúrbio de conduta do tipo socializado",
            favorito: false
        },
        {
            cid10: "F913",
            descricao: "Distúrbio desafiador e de oposição",
            favorito: false
        },
        {
            cid10: "F918",
            descricao: "Outros transtornos de conduta",
            favorito: false
        },
        {
            cid10: "F919",
            descricao: "Transtorno de conduta não especificado",
            favorito: false
        },
        {
            cid10: "F920",
            descricao: "Distúrbio depressivo de conduta",
            favorito: false
        },
        {
            cid10: "F928",
            descricao: "Outros transtornos mistos da conduta e das emoções",
            favorito: false
        },
        {
            cid10: "F929",
            descricao: "Transtorno misto da conduta e das emoções não especificado",
            favorito: false
        },
        {
            cid10: "F930",
            descricao: "Transtorno ligado à angústia de separação",
            favorito: false
        },
        {
            cid10: "F931",
            descricao: "Transtorno fóbico ansioso da infância",
            favorito: false
        },
        {
            cid10: "F932",
            descricao: "Distúrbio de ansiedade social da infância",
            favorito: false
        },
        {
            cid10: "F933",
            descricao: "Transtorno de rivalidade entre irmãos",
            favorito: false
        },
        {
            cid10: "F938",
            descricao: "Outros transtornos emocionais da infância",
            favorito: false
        },
        {
            cid10: "F939",
            descricao: "Transtorno emocional da infância não especificado",
            favorito: false
        },
        {
            cid10: "F940",
            descricao: "Mutismo eletivo",
            favorito: false
        },
        {
            cid10: "F941",
            descricao: "Distúrbio reativo de vinculação da infância",
            favorito: false
        },
        {
            cid10: "F942",
            descricao: "Transtorno de fixação da infância, com desinibição",
            favorito: false
        },
        {
            cid10: "F948",
            descricao: "Outros transtornos do funcionamento social na infância",
            favorito: false
        },
        {
            cid10: "F949",
            descricao: "Transtorno do funcionamento social da infância não especificado",
            favorito: false
        },
        {
            cid10: "F950",
            descricao: "Tique transitório",
            favorito: false
        },
        {
            cid10: "F951",
            descricao: "Tique motor ou vocal crônico",
            favorito: false
        },
        {
            cid10: "F952",
            descricao: "Tiques vocais e motores múltiplos combinados [doença de Gilles de la Tourette]",
            favorito: false
        },
        {
            cid10: "F958",
            descricao: "Outros tiques",
            favorito: false
        },
        {
            cid10: "F959",
            descricao: "Tique não especificado",
            favorito: false
        },
        {
            cid10: "F980",
            descricao: "Enurese de origem não-orgânica",
            favorito: false
        },
        {
            cid10: "F981",
            descricao: "Encoprese de origem não-orgânica",
            favorito: false
        },
        {
            cid10: "F982",
            descricao: "Transtorno de alimentação na infância",
            favorito: false
        },
        {
            cid10: "F983",
            descricao: "Pica do lactente ou da criança",
            favorito: false
        },
        {
            cid10: "F984",
            descricao: "Estereotipias motoras",
            favorito: false
        },
        {
            cid10: "F985",
            descricao: "Gagueira [tartamudez]",
            favorito: false
        },
        {
            cid10: "F986",
            descricao: "Linguagem precipitada",
            favorito: false
        },
        {
            cid10: "F988",
            descricao: "Outros transtornos comportamentais e emocionais especificados com início habitualmente na infância ou adolescência",
            favorito: false
        },
        {
            cid10: "F989",
            descricao: "Transtornos comportamentais e emocionais não especificados com início habitualmente na infância ou adolescência",
            favorito: false
        },
        {
            cid10: "F99",
            descricao: "Transtorno mental não especificado em outra parte",
            favorito: false
        },
        {
            cid10: "G000",
            descricao: "Meningite por Haemophilus",
            favorito: false
        },
        {
            cid10: "G001",
            descricao: "Meningite pneumocócica",
            favorito: false
        },
        {
            cid10: "G002",
            descricao: "Meningite estreptocócica",
            favorito: false
        },
        {
            cid10: "G003",
            descricao: "Meningite estafilocócica",
            favorito: false
        },
        {
            cid10: "G008",
            descricao: "Outras meningites bacterianas",
            favorito: false
        },
        {
            cid10: "G009",
            descricao: "Meningite bacteriana não especificada",
            favorito: false
        },
        {
            cid10: "G01",
            descricao: "Meningite em doenças bacterianas classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G020",
            descricao: "Meningite em doenças virais classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G021",
            descricao: "Meningite em micoses",
            favorito: false
        },
        {
            cid10: "G028",
            descricao: "Meningite em outras doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G030",
            descricao: "Meningite não-piogênica",
            favorito: false
        },
        {
            cid10: "G031",
            descricao: "Meningite crônica",
            favorito: false
        },
        {
            cid10: "G032",
            descricao: "Meningite recorrente benigna [Mollaret]",
            favorito: false
        },
        {
            cid10: "G038",
            descricao: "Meningite devida a outras causas especificadas",
            favorito: false
        },
        {
            cid10: "G039",
            descricao: "Meningite não especificada",
            favorito: false
        },
        {
            cid10: "G040",
            descricao: "Encefalite aguda disseminada",
            favorito: false
        },
        {
            cid10: "G041",
            descricao: "Paraplegia espástica tropical",
            favorito: false
        },
        {
            cid10: "G042",
            descricao: "Meningoencefalite e meningomielite bacterianas não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G048",
            descricao: "Outras encefalites, mielites e encefalomielites",
            favorito: false
        },
        {
            cid10: "G049",
            descricao: "Encefalite, mielite e encefalomielite não especificada",
            favorito: false
        },
        {
            cid10: "G050",
            descricao: "Encefalite, mielite e encefalomielite em doenças bacterianas classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G051",
            descricao: "Encefalite, mielite e encefalomielite em doenças virais classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G052",
            descricao: "Encefalite, mielite e encefalomielite em outras doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G058",
            descricao: "Encefalite, mielite e encefalomielite em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G060",
            descricao: "Abscesso e granuloma intracranianos",
            favorito: false
        },
        {
            cid10: "G061",
            descricao: "Abscesso e granuloma intra-raquidianos",
            favorito: false
        },
        {
            cid10: "G062",
            descricao: "Abscesso extradural e subdural não especificados",
            favorito: false
        },
        {
            cid10: "G07",
            descricao: "Abscesso e granuloma intracranianos e intraspinais em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G08",
            descricao: "Flebite e tromboflebite intracranianas e intra-raquidianas",
            favorito: false
        },
        {
            cid10: "G09",
            descricao: "Seqüelas de doenças inflamatórias do sistema nervoso central",
            favorito: false
        },
        {
            cid10: "G10",
            descricao: "Doença de Huntington",
            favorito: false
        },
        {
            cid10: "G110",
            descricao: "Ataxia congênita não-progressiva",
            favorito: false
        },
        {
            cid10: "G111",
            descricao: "Ataxia cerebelar de início precoce",
            favorito: false
        },
        {
            cid10: "G112",
            descricao: "Ataxia cerebelar de início tardio",
            favorito: false
        },
        {
            cid10: "G113",
            descricao: "Ataxia cerebelar com déficit na reparação do DNA",
            favorito: false
        },
        {
            cid10: "G114",
            descricao: "Paraplegia espástica hereditária",
            favorito: false
        },
        {
            cid10: "G118",
            descricao: "Outras ataxias hereditárias",
            favorito: false
        },
        {
            cid10: "G119",
            descricao: "Ataxia hereditária não especificada",
            favorito: false
        },
        {
            cid10: "G120",
            descricao: "Atrofia muscular espinal infantil tipo I [Werdnig-Hoffman]",
            favorito: false
        },
        {
            cid10: "G121",
            descricao: "Outras atrofias musculares espinais hereditárias",
            favorito: false
        },
        {
            cid10: "G122",
            descricao: "Doença do neurônio motor",
            favorito: false
        },
        {
            cid10: "G128",
            descricao: "Outras atrofias musculares espinais e síndromes musculares correlatas",
            favorito: false
        },
        {
            cid10: "G129",
            descricao: "Atrofia muscular espinal não especificada",
            favorito: false
        },
        {
            cid10: "G130",
            descricao: "Neuromiopatia e neuropatia paraneoplásicas",
            favorito: false
        },
        {
            cid10: "G131",
            descricao: "Outra atrofia sistêmica que afeta primariamente o sistema nervoso central em doenças neoplásicas",
            favorito: false
        },
        {
            cid10: "G132",
            descricao: "Atrofia sistêmica que afeta primariamente o sistema nervoso central no mixedema",
            favorito: false
        },
        {
            cid10: "G138",
            descricao: "Atrofia sistêmica que afeta primariamente o sistema nervoso central em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G20",
            descricao: "Doença de Parkinson",
            favorito: false
        },
        {
            cid10: "G210",
            descricao: "Síndrome maligna dos neurolépticos",
            favorito: false
        },
        {
            cid10: "G211",
            descricao: "Outras formas de parkinsonismo secundário induzido por drogas",
            favorito: false
        },
        {
            cid10: "G212",
            descricao: "Parkinsonismo secundário devido a outros agentes externos",
            favorito: false
        },
        {
            cid10: "G213",
            descricao: "Parkinsonismo pós-encefalítico",
            favorito: false
        },
        {
            cid10: "G218",
            descricao: "Outras formas de parkinsonismo secundário",
            favorito: false
        },
        {
            cid10: "G219",
            descricao: "Parkinsonismo secundário não especificado",
            favorito: false
        },
        {
            cid10: "G22",
            descricao: "Parkinsonismo em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G230",
            descricao: "Doença de Hallervorden-Spatz",
            favorito: false
        },
        {
            cid10: "G231",
            descricao: "Oftalmoplegia supranuclear progressiva [Steele-Richardson-Olszewski]",
            favorito: false
        },
        {
            cid10: "G232",
            descricao: "Degeneração estrionígrica",
            favorito: false
        },
        {
            cid10: "G238",
            descricao: "Outras doenças degenerativas especificadas dos gânglios da base",
            favorito: false
        },
        {
            cid10: "G239",
            descricao: "Doença degenerativa dos gânglios da base, não especificada",
            favorito: false
        },
        {
            cid10: "G240",
            descricao: "Distonia induzida por drogas",
            favorito: false
        },
        {
            cid10: "G241",
            descricao: "Distonia familiar idiopática",
            favorito: false
        },
        {
            cid10: "G242",
            descricao: "Distonia não-familiar idiopática",
            favorito: false
        },
        {
            cid10: "G243",
            descricao: "Torcicolo espasmódico",
            favorito: false
        },
        {
            cid10: "G244",
            descricao: "Distonia orofacial idiopática",
            favorito: false
        },
        {
            cid10: "G245",
            descricao: "Blefaroespasmo",
            favorito: false
        },
        {
            cid10: "G248",
            descricao: "Outras distonias",
            favorito: false
        },
        {
            cid10: "G249",
            descricao: "Distonia não especificada",
            favorito: false
        },
        {
            cid10: "G250",
            descricao: "Tremor essencial",
            favorito: false
        },
        {
            cid10: "G251",
            descricao: "Tremor induzido por drogas",
            favorito: false
        },
        {
            cid10: "G252",
            descricao: "Outras formas especificadas de tremor",
            favorito: false
        },
        {
            cid10: "G253",
            descricao: "Mioclonia",
            favorito: false
        },
        {
            cid10: "G254",
            descricao: "Coréia induzida por droga",
            favorito: false
        },
        {
            cid10: "G255",
            descricao: "Outras formas de coréia",
            favorito: false
        },
        {
            cid10: "G256",
            descricao: "Tiques induzidos por droga e outros tipos de origem orgânica",
            favorito: false
        },
        {
            cid10: "G258",
            descricao: "Outras doenças extrapiramidais e transtornos dos movimentos, especificados",
            favorito: false
        },
        {
            cid10: "G259",
            descricao: "Doenças extrapiramidais e transtornos dos movimentos, não especificados",
            favorito: false
        },
        {
            cid10: "G26",
            descricao: "Doenças extrapiramidais e transtornos dos movimentos em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G300",
            descricao: "Doença de Alzheimer de início precoce",
            favorito: false
        },
        {
            cid10: "G301",
            descricao: "Doença de Alzheimer de início tardio",
            favorito: false
        },
        {
            cid10: "G308",
            descricao: "Outras formas de doença de Alzheimer",
            favorito: false
        },
        {
            cid10: "G309",
            descricao: "Doença de Alzheimer não especificada",
            favorito: false
        },
        {
            cid10: "G310",
            descricao: "Atrofia cerebral circunscrita",
            favorito: false
        },
        {
            cid10: "G311",
            descricao: "Degeneração cerebral senil, não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G312",
            descricao: "Degeneração do sistema nervoso devida ao álcool",
            favorito: false
        },
        {
            cid10: "G318",
            descricao: "Outras doenças degenerativas especificadas do sistema nervoso",
            favorito: false
        },
        {
            cid10: "G319",
            descricao: "Doença degenerativa do sistema nervoso, não especificada",
            favorito: false
        },
        {
            cid10: "G320",
            descricao: "Degeneração combinada subaguda da medula espinal em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G328",
            descricao: "Outros transtornos degenerativos especificados do sistema nervoso em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G35",
            descricao: "Esclerose múltipla",
            favorito: false
        },
        {
            cid10: "G360",
            descricao: "Neuromielite óptica [doença de Devic]",
            favorito: false
        },
        {
            cid10: "G361",
            descricao: "Leucoencefalite hemorrágica aguda e subaguda [Hurst]",
            favorito: false
        },
        {
            cid10: "G368",
            descricao: "Outras desmielinizações disseminadas agudas especificadas",
            favorito: false
        },
        {
            cid10: "G369",
            descricao: "Desmielinização disseminada aguda não especificada",
            favorito: false
        },
        {
            cid10: "G370",
            descricao: "Esclerose difusa",
            favorito: false
        },
        {
            cid10: "G371",
            descricao: "Desmielinização central do corpo caloso",
            favorito: false
        },
        {
            cid10: "G372",
            descricao: "Mielinólise central da ponte",
            favorito: false
        },
        {
            cid10: "G373",
            descricao: "Mielite transversa aguda em doenças desmielinizantes do sistema nervoso central",
            favorito: false
        },
        {
            cid10: "G374",
            descricao: "Mielite subaguda necrótica",
            favorito: false
        },
        {
            cid10: "G375",
            descricao: "Esclerose concêntrica [Baló]",
            favorito: false
        },
        {
            cid10: "G378",
            descricao: "Outras doenças desmielinizantes especificadas do sistema nervoso central",
            favorito: false
        },
        {
            cid10: "G379",
            descricao: "Doença desmielinizante do sistema nervoso central, não especificada",
            favorito: false
        },
        {
            cid10: "G400",
            descricao: "Epilepsia e síndromes epilépticas idiopáticas definidas por sua localização (focal) (parcial) com crises de início focal",
            favorito: false
        },
        {
            cid10: "G401",
            descricao: "Epilepsia e síndromes epilépticas sintomáticas definidas por sua localização (focal) (parcial) com crises parciais simples",
            favorito: false
        },
        {
            cid10: "G402",
            descricao: "Epilepsia e síndromes epilépticas sintomáticas definidas por sua localização (focal) (parcial) com crises parciais complexas",
            favorito: false
        },
        {
            cid10: "G403",
            descricao: "Epilepsia e síndromes epilépticas generalizadas idiopáticas",
            favorito: false
        },
        {
            cid10: "G404",
            descricao: "Outras epilepsias e síndromes epilépticas generalizadas",
            favorito: false
        },
        {
            cid10: "G405",
            descricao: "Síndromes epilépticas especiais",
            favorito: false
        },
        {
            cid10: "G406",
            descricao: "Crise de grande mal, não especificada (com ou sem pequeno mal)",
            favorito: false
        },
        {
            cid10: "G407",
            descricao: "Pequeno mal não especificado, sem crises de grande mal",
            favorito: false
        },
        {
            cid10: "G408",
            descricao: "Outras epilepsias",
            favorito: false
        },
        {
            cid10: "G409",
            descricao: "Epilepsia, não especificada",
            favorito: false
        },
        {
            cid10: "G410",
            descricao: "Estado de grande mal epiléptico",
            favorito: false
        },
        {
            cid10: "G411",
            descricao: "Estado de pequeno mal epiléptico",
            favorito: false
        },
        {
            cid10: "G412",
            descricao: "Estado de mal epiléptico parcial complexo",
            favorito: false
        },
        {
            cid10: "G418",
            descricao: "Outros estados de mal epiléptico",
            favorito: false
        },
        {
            cid10: "G419",
            descricao: "Estado de mal epiléptico, não especificado",
            favorito: false
        },
        {
            cid10: "G430",
            descricao: "Enxaqueca sem aura [enxaqueca comum]",
            favorito: false
        },
        {
            cid10: "G431",
            descricao: "Enxaqueca com aura [enxaqueca clássica]",
            favorito: false
        },
        {
            cid10: "G432",
            descricao: "Estado de mal enxaquecoso",
            favorito: false
        },
        {
            cid10: "G433",
            descricao: "Enxaqueca complicada",
            favorito: false
        },
        {
            cid10: "G438",
            descricao: "Outras formas de enxaqueca",
            favorito: false
        },
        {
            cid10: "G439",
            descricao: "Enxaqueca, sem especificação",
            favorito: false
        },
        {
            cid10: "G440",
            descricao: "Síndrome de cluster-headache",
            favorito: false
        },
        {
            cid10: "G441",
            descricao: "Cefaléia vascular, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "G442",
            descricao: "Cefaléia tensional",
            favorito: false
        },
        {
            cid10: "G443",
            descricao: "Cefaléia crônica pós-traumática",
            favorito: false
        },
        {
            cid10: "G444",
            descricao: "Cefaléia induzida por drogas, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "G448",
            descricao: "Outras síndromes de cefaléia especificadas",
            favorito: false
        },
        {
            cid10: "G450",
            descricao: "Síndrome da artéria vértebro-basilar",
            favorito: false
        },
        {
            cid10: "G451",
            descricao: "Síndrome da artéria carotídea (hemisférica)",
            favorito: false
        },
        {
            cid10: "G452",
            descricao: "Síndrome das artérias pré-cerebrais, múltiplas e bilaterais",
            favorito: false
        },
        {
            cid10: "G453",
            descricao: "Amaurose fugaz",
            favorito: false
        },
        {
            cid10: "G454",
            descricao: "Amnésia global transitória",
            favorito: false
        },
        {
            cid10: "G458",
            descricao: "Outros acidentes isquêmicos cerebrais transitórios e síndromes correlatas",
            favorito: false
        },
        {
            cid10: "G459",
            descricao: "Isquemia cerebral transitória não especificada",
            favorito: false
        },
        {
            cid10: "G460",
            descricao: "Síndrome da artéria cerebral média",
            favorito: false
        },
        {
            cid10: "G461",
            descricao: "Síndrome da artéria cerebral anterior",
            favorito: false
        },
        {
            cid10: "G462",
            descricao: "Síndrome da artéria cerebral posterior",
            favorito: false
        },
        {
            cid10: "G463",
            descricao: "Síndromes vasculares do tronco cerebral",
            favorito: false
        },
        {
            cid10: "G464",
            descricao: "Síndrome vascular cerebelar",
            favorito: false
        },
        {
            cid10: "G465",
            descricao: "Síndrome lacunar motora pura",
            favorito: false
        },
        {
            cid10: "G466",
            descricao: "Síndrome lacunar sensorial pura",
            favorito: false
        },
        {
            cid10: "G467",
            descricao: "Outras síndromes lacunares",
            favorito: false
        },
        {
            cid10: "G468",
            descricao: "Outras síndromes vasculares cerebrais em doenças cerebrovasculares",
            favorito: false
        },
        {
            cid10: "G470",
            descricao: "Distúrbios do início e da manutenção do sono [insônias]",
            favorito: false
        },
        {
            cid10: "G471",
            descricao: "Distúrbios do sono por sonolência excessiva [hipersonia]",
            favorito: false
        },
        {
            cid10: "G472",
            descricao: "Distúrbios do ciclo vigília-sono",
            favorito: false
        },
        {
            cid10: "G473",
            descricao: "Apnéia de sono",
            favorito: false
        },
        {
            cid10: "G474",
            descricao: "Narcolepsia e cataplexia",
            favorito: false
        },
        {
            cid10: "G478",
            descricao: "Outros distúrbios do sono",
            favorito: false
        },
        {
            cid10: "G479",
            descricao: "Distúrbio do sono, não especificado",
            favorito: false
        },
        {
            cid10: "G500",
            descricao: "Nevralgia do trigêmeo",
            favorito: false
        },
        {
            cid10: "G501",
            descricao: "Dor facial atípica",
            favorito: false
        },
        {
            cid10: "G508",
            descricao: "Outros transtornos do nervo trigêmeo",
            favorito: false
        },
        {
            cid10: "G509",
            descricao: "Transtorno não especificado do nervo trigêmeo",
            favorito: false
        },
        {
            cid10: "G510",
            descricao: "Paralisia de Bell",
            favorito: false
        },
        {
            cid10: "G511",
            descricao: "Ganglionite geniculada",
            favorito: false
        },
        {
            cid10: "G512",
            descricao: "Síndrome de Melkersson",
            favorito: false
        },
        {
            cid10: "G513",
            descricao: "Espasmo hemifacial clônico",
            favorito: false
        },
        {
            cid10: "G514",
            descricao: "Mioquimia facial",
            favorito: false
        },
        {
            cid10: "G518",
            descricao: "Outros transtornos do nervo facial",
            favorito: false
        },
        {
            cid10: "G519",
            descricao: "Transtorno não especificado do nervo facial",
            favorito: false
        },
        {
            cid10: "G520",
            descricao: "Transtornos do nervo olfatório",
            favorito: false
        },
        {
            cid10: "G521",
            descricao: "Transtornos do nervo glossofaríngeo",
            favorito: false
        },
        {
            cid10: "G522",
            descricao: "Transtornos do nervo vago",
            favorito: false
        },
        {
            cid10: "G523",
            descricao: "Transtornos do nervo hipoglosso",
            favorito: false
        },
        {
            cid10: "G527",
            descricao: "Transtornos de múltiplos nervos cranianos",
            favorito: false
        },
        {
            cid10: "G528",
            descricao: "Transtornos de outros nervos cranianos especificados",
            favorito: false
        },
        {
            cid10: "G529",
            descricao: "Transtorno de nervo craniano não especificado",
            favorito: false
        },
        {
            cid10: "G530",
            descricao: "Nevralgia pós-zoster",
            favorito: false
        },
        {
            cid10: "G531",
            descricao: "Paralisias de múltiplos nervos cranianos em doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G532",
            descricao: "Paralisias de múltiplos nervos cranianos na sarcoidose",
            favorito: false
        },
        {
            cid10: "G533",
            descricao: "Paralisias de múltiplos nervos cranianos em doenças neoplásicas",
            favorito: false
        },
        {
            cid10: "G538",
            descricao: "Outros transtornos de nervos cranianos em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G540",
            descricao: "Transtornos do plexo braquial",
            favorito: false
        },
        {
            cid10: "G541",
            descricao: "Transtornos do plexo lombossacral",
            favorito: false
        },
        {
            cid10: "G542",
            descricao: "Transtornos das raízes cervicais não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G543",
            descricao: "Transtornos das raízes torácicas não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G544",
            descricao: "Transtornos das raízes lombossacras não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G545",
            descricao: "Amiotrofia nevrálgica",
            favorito: false
        },
        {
            cid10: "G546",
            descricao: "Síndrome dolorosa do membro fantasma",
            favorito: false
        },
        {
            cid10: "G547",
            descricao: "Síndrome do membro fantasma sem manifestação dolorosa",
            favorito: false
        },
        {
            cid10: "G548",
            descricao: "Outros transtornos das raízes e dos plexos nervosos",
            favorito: false
        },
        {
            cid10: "G549",
            descricao: "Transtorno não especificado das raízes e dos plexos nervosos",
            favorito: false
        },
        {
            cid10: "G550",
            descricao: "Compressões das raízes e dos plexos nervosos em doenças neoplásicas",
            favorito: false
        },
        {
            cid10: "G551",
            descricao: "Compressões das raízes e dos plexos nervosos em transtornos dos discos intervertebrais",
            favorito: false
        },
        {
            cid10: "G552",
            descricao: "Compressões das raízes e dos plexos nervosos na espondilose",
            favorito: false
        },
        {
            cid10: "G553",
            descricao: "Compressões das raízes e dos plexos nervosos em outras dorsopatias",
            favorito: false
        },
        {
            cid10: "G558",
            descricao: "Compressões das raízes e dos plexos nervosos em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G560",
            descricao: "Síndrome do túnel do carpo",
            favorito: false
        },
        {
            cid10: "G561",
            descricao: "Outras lesões do nervo mediano",
            favorito: false
        },
        {
            cid10: "G562",
            descricao: "Lesões do nervo cubital [ulnar]",
            favorito: false
        },
        {
            cid10: "G563",
            descricao: "Lesão do nervo radial",
            favorito: false
        },
        {
            cid10: "G564",
            descricao: "Causalgia",
            favorito: false
        },
        {
            cid10: "G568",
            descricao: "Outras mononeuropatias dos membros superiores",
            favorito: false
        },
        {
            cid10: "G569",
            descricao: "Mononeuropatia dos membros superiores, não especificada",
            favorito: false
        },
        {
            cid10: "G570",
            descricao: "Lesão do nervo ciático",
            favorito: false
        },
        {
            cid10: "G571",
            descricao: "Meralgia parestésica",
            favorito: false
        },
        {
            cid10: "G572",
            descricao: "Lesão do nervo femoral",
            favorito: false
        },
        {
            cid10: "G573",
            descricao: "Lesão do nervo poplíteo lateral",
            favorito: false
        },
        {
            cid10: "G574",
            descricao: "Lesão do nervo poplíteo medial",
            favorito: false
        },
        {
            cid10: "G575",
            descricao: "Síndrome do túnel do tarso",
            favorito: false
        },
        {
            cid10: "G576",
            descricao: "Lesão do nervo plantar",
            favorito: false
        },
        {
            cid10: "G578",
            descricao: "Outras mononeuropatias dos membros inferiores",
            favorito: false
        },
        {
            cid10: "G579",
            descricao: "Mononeuropatia dos membros inferiores, não especificada",
            favorito: false
        },
        {
            cid10: "G580",
            descricao: "Neuropatia intercostal",
            favorito: false
        },
        {
            cid10: "G587",
            descricao: "Mononeurite múltipla",
            favorito: false
        },
        {
            cid10: "G588",
            descricao: "Outras mononeuropatias especificadas",
            favorito: false
        },
        {
            cid10: "G589",
            descricao: "Mononeuropatia não especificada",
            favorito: false
        },
        {
            cid10: "G590",
            descricao: "Mononeuropatia diabética",
            favorito: false
        },
        {
            cid10: "G598",
            descricao: "Outras mononeuropatias em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G600",
            descricao: "Neuropatia hereditária motora e sensorial",
            favorito: false
        },
        {
            cid10: "G601",
            descricao: "Doença de Refsum",
            favorito: false
        },
        {
            cid10: "G602",
            descricao: "Neuropatia associada a ataxia hereditária",
            favorito: false
        },
        {
            cid10: "G603",
            descricao: "Neuropatia progressiva idiopática",
            favorito: false
        },
        {
            cid10: "G608",
            descricao: "Outras neuropatias hereditárias e idiopáticas",
            favorito: false
        },
        {
            cid10: "G609",
            descricao: "Neuropatia hereditária e idiopática não especificada",
            favorito: false
        },
        {
            cid10: "G610",
            descricao: "Síndrome de Guillain-Barré",
            favorito: false
        },
        {
            cid10: "G611",
            descricao: "Neuropatia sérica",
            favorito: false
        },
        {
            cid10: "G618",
            descricao: "Outras polineuropatias inflamatórias",
            favorito: false
        },
        {
            cid10: "G619",
            descricao: "Polineuropatia inflamatória não especificada",
            favorito: false
        },
        {
            cid10: "G620",
            descricao: "Polineuropatia induzida por drogas",
            favorito: false
        },
        {
            cid10: "G621",
            descricao: "Polineuropatia alcoólica",
            favorito: false
        },
        {
            cid10: "G622",
            descricao: "Polineuropatia devida a outros agentes tóxicos",
            favorito: false
        },
        {
            cid10: "G628",
            descricao: "Outras polineuropatias especificadas",
            favorito: false
        },
        {
            cid10: "G629",
            descricao: "Polineuropatia não especificada",
            favorito: false
        },
        {
            cid10: "G630",
            descricao: "Polineuropatia em doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G631",
            descricao: "Polineuropatia em doenças neoplásicas",
            favorito: false
        },
        {
            cid10: "G632",
            descricao: "Polineuropatia diabética",
            favorito: false
        },
        {
            cid10: "G633",
            descricao: "Polineuropatia em outras doenças endócrinas e metabólicas",
            favorito: false
        },
        {
            cid10: "G634",
            descricao: "Polineuropatia em deficiências nutricionais",
            favorito: false
        },
        {
            cid10: "G635",
            descricao: "Polineuropatia em doenças sistêmicas do tecido conjuntivo",
            favorito: false
        },
        {
            cid10: "G636",
            descricao: "Polineuropatia em outros transtornos osteomusculares",
            favorito: false
        },
        {
            cid10: "G638",
            descricao: "Polineuropatia em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G64",
            descricao: "Outros transtornos do sistema nervoso periférico",
            favorito: false
        },
        {
            cid10: "G700",
            descricao: "Miastenia gravis",
            favorito: false
        },
        {
            cid10: "G701",
            descricao: "Transtornos mioneurais tóxicos",
            favorito: false
        },
        {
            cid10: "G702",
            descricao: "Miastenia congênita e do desenvolvimento",
            favorito: false
        },
        {
            cid10: "G708",
            descricao: "Outros transtornos mioneurais especificados",
            favorito: false
        },
        {
            cid10: "G709",
            descricao: "Transtorno mioneural não especificado",
            favorito: false
        },
        {
            cid10: "G710",
            descricao: "Distrofia muscular",
            favorito: false
        },
        {
            cid10: "G711",
            descricao: "Transtornos miotônicos",
            favorito: false
        },
        {
            cid10: "G712",
            descricao: "Miopatias congênitas",
            favorito: false
        },
        {
            cid10: "G713",
            descricao: "Miopatia mitocondrial não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "G718",
            descricao: "Outros transtornos primários dos músculos",
            favorito: false
        },
        {
            cid10: "G719",
            descricao: "Transtorno muscular primário não especificado",
            favorito: false
        },
        {
            cid10: "G720",
            descricao: "Miopatia induzida por drogas",
            favorito: false
        },
        {
            cid10: "G721",
            descricao: "Miopatia alcoólica",
            favorito: false
        },
        {
            cid10: "G722",
            descricao: "Miopatia devida a outros agentes tóxicos",
            favorito: false
        },
        {
            cid10: "G723",
            descricao: "Paralisia periódica",
            favorito: false
        },
        {
            cid10: "G724",
            descricao: "Miopatia inflamatória não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "G728",
            descricao: "Outras miopatias especificadas",
            favorito: false
        },
        {
            cid10: "G729",
            descricao: "Miopatia não especificada",
            favorito: false
        },
        {
            cid10: "G730",
            descricao: "Síndromes miastênicas em doenças endócrinas",
            favorito: false
        },
        {
            cid10: "G731",
            descricao: "Síndrome de Eaton-Lambert",
            favorito: false
        },
        {
            cid10: "G732",
            descricao: "Outras síndromes miastênicas em doenças neoplásicas",
            favorito: false
        },
        {
            cid10: "G733",
            descricao: "Síndromes miastênicas em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G734",
            descricao: "Miopatia em doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G735",
            descricao: "Miopatia em doenças endócrinas",
            favorito: false
        },
        {
            cid10: "G736",
            descricao: "Miopatia em doenças metabólicas",
            favorito: false
        },
        {
            cid10: "G737",
            descricao: "Miopatia em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G800",
            descricao: "Paralisia cerebral quadriplágica espástica",
            favorito: false
        },
        {
            cid10: "G801",
            descricao: "Paralisia cerebral diplégica espástica",
            favorito: false
        },
        {
            cid10: "G802",
            descricao: "Paralisia cerebral hemiplégica espástica",
            favorito: false
        },
        {
            cid10: "G803",
            descricao: "Paralisia cerebral discinética",
            favorito: false
        },
        {
            cid10: "G804",
            descricao: "Paralisia cerebral atáxica",
            favorito: false
        },
        {
            cid10: "G808",
            descricao: "Outras formas de paralisia cerebral",
            favorito: false
        },
        {
            cid10: "G809",
            descricao: "Paralisia cerebral não especificada",
            favorito: false
        },
        {
            cid10: "G810",
            descricao: "Hemiplegia flácida",
            favorito: false
        },
        {
            cid10: "G811",
            descricao: "Hemiplegia espástica",
            favorito: false
        },
        {
            cid10: "G819",
            descricao: "Hemiplegia não especificada",
            favorito: false
        },
        {
            cid10: "G820",
            descricao: "Paraplegia flácida",
            favorito: false
        },
        {
            cid10: "G821",
            descricao: "Paraplegia espástica",
            favorito: false
        },
        {
            cid10: "G822",
            descricao: "Paraplegia não especificada",
            favorito: false
        },
        {
            cid10: "G823",
            descricao: "Tetraplegia flácida",
            favorito: false
        },
        {
            cid10: "G824",
            descricao: "Tetraplegia espástica",
            favorito: false
        },
        {
            cid10: "G825",
            descricao: "Tetraplegia não especificada",
            favorito: false
        },
        {
            cid10: "G830",
            descricao: "Diplegia dos membros superiores",
            favorito: false
        },
        {
            cid10: "G831",
            descricao: "Monoplegia do membro inferior",
            favorito: false
        },
        {
            cid10: "G832",
            descricao: "Monoplegia do membro superior",
            favorito: false
        },
        {
            cid10: "G833",
            descricao: "Monoplegia não especificada",
            favorito: false
        },
        {
            cid10: "G834",
            descricao: "Síndrome da cauda eqüina",
            favorito: false
        },
        {
            cid10: "G838",
            descricao: "Outras síndromes paralíticas especificadas",
            favorito: false
        },
        {
            cid10: "G839",
            descricao: "Síndrome paralítica não especificada",
            favorito: false
        },
        {
            cid10: "G900",
            descricao: "Neuropatia autonômica periférica idiopática",
            favorito: false
        },
        {
            cid10: "G901",
            descricao: "Disautonomia familiar [Síndrome de Riley-Day]",
            favorito: false
        },
        {
            cid10: "G902",
            descricao: "Síndrome de Horner",
            favorito: false
        },
        {
            cid10: "G903",
            descricao: "Degeneração multissistêmica",
            favorito: false
        },
        {
            cid10: "G904",
            descricao: "Disreflexia autonômica",
            favorito: false
        },
        {
            cid10: "G908",
            descricao: "Outros transtornos do sistema nervoso autônomo",
            favorito: false
        },
        {
            cid10: "G909",
            descricao: "Transtorno não especificado do sistema nervoso autônomo",
            favorito: false
        },
        {
            cid10: "G910",
            descricao: "Hidrocefalia comunicante",
            favorito: false
        },
        {
            cid10: "G911",
            descricao: "Hidrocefalia obstrutiva",
            favorito: false
        },
        {
            cid10: "G912",
            descricao: "Hidrocefalia de pressão normal",
            favorito: false
        },
        {
            cid10: "G913",
            descricao: "Hidrocefalia pós-traumática não especificada",
            favorito: false
        },
        {
            cid10: "G918",
            descricao: "Outras formas de hidrocefalia",
            favorito: false
        },
        {
            cid10: "G919",
            descricao: "Hidrocefalia não especificada",
            favorito: false
        },
        {
            cid10: "G92",
            descricao: "Encefalopatia tóxica",
            favorito: false
        },
        {
            cid10: "G930",
            descricao: "Cistos cerebrais",
            favorito: false
        },
        {
            cid10: "G931",
            descricao: "Lesão encefálica anóxica, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "G932",
            descricao: "Hipertensão intracraniana benigna",
            favorito: false
        },
        {
            cid10: "G933",
            descricao: "Síndrome da fadiga pós-viral",
            favorito: false
        },
        {
            cid10: "G934",
            descricao: "Encefalopatia não especificada",
            favorito: false
        },
        {
            cid10: "G935",
            descricao: "Compressão do encéfalo",
            favorito: false
        },
        {
            cid10: "G936",
            descricao: "Edema cerebral",
            favorito: false
        },
        {
            cid10: "G937",
            descricao: "Síndrome de Reye",
            favorito: false
        },
        {
            cid10: "G938",
            descricao: "Outros transtornos especificados do encéfalo",
            favorito: false
        },
        {
            cid10: "G939",
            descricao: "Transtorno não especificado do encéfalo",
            favorito: false
        },
        {
            cid10: "G940",
            descricao: "Hidrocefalia em doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G941",
            descricao: "Hidrocefalia em doenças neoplásicas",
            favorito: false
        },
        {
            cid10: "G942",
            descricao: "Hidrocefalia em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G948",
            descricao: "Outros transtornos encefálicos especificados em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G950",
            descricao: "Siringomielia e siringobulbia",
            favorito: false
        },
        {
            cid10: "G951",
            descricao: "Mielopatias vasculares",
            favorito: false
        },
        {
            cid10: "G952",
            descricao: "Compressão não especificada de medula espinal",
            favorito: false
        },
        {
            cid10: "G958",
            descricao: "Outras doenças especificadas da medula espinal",
            favorito: false
        },
        {
            cid10: "G959",
            descricao: "Doença não especificada da medula espinal",
            favorito: false
        },
        {
            cid10: "G960",
            descricao: "Fístula liquórica",
            favorito: false
        },
        {
            cid10: "G961",
            descricao: "Transtornos das meninges não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "G968",
            descricao: "Outros transtornos especificados do sistema nervoso central",
            favorito: false
        },
        {
            cid10: "G969",
            descricao: "Transtorno não especificado do sistema nervoso central",
            favorito: false
        },
        {
            cid10: "G970",
            descricao: "Perda de líquor resultante de punção espinal",
            favorito: false
        },
        {
            cid10: "G971",
            descricao: "Outra reação à punção espinal e lombar",
            favorito: false
        },
        {
            cid10: "G972",
            descricao: "Hipotensão intracraniana conseqüente à derivação ventricular",
            favorito: false
        },
        {
            cid10: "G978",
            descricao: "Outros transtornos pós-procedimento do sistema nervoso",
            favorito: false
        },
        {
            cid10: "G979",
            descricao: "Transtorno pós-procedimento do sistema nervoso, não especificado",
            favorito: false
        },
        {
            cid10: "G98",
            descricao: "Outros transtornos do sistema nervoso não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "G990",
            descricao: "Neuropatia autonômica em doenças endócrinas e metabólicas",
            favorito: false
        },
        {
            cid10: "G991",
            descricao: "Outros transtornos do sistema nervoso autônomo em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G992",
            descricao: "Mielopatia em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "G998",
            descricao: "Outros transtornos especificados do sistema nervoso em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H000",
            descricao: "Hordéolo e outras inflamações profundas das pálpebras",
            favorito: false
        },
        {
            cid10: "H001",
            descricao: "Calázio",
            favorito: false
        },
        {
            cid10: "H010",
            descricao: "Blefarite",
            favorito: false
        },
        {
            cid10: "H011",
            descricao: "Dermatoses não infecciosas da pálpebra",
            favorito: false
        },
        {
            cid10: "H018",
            descricao: "Outras inflamações especificadas da pálpebra",
            favorito: false
        },
        {
            cid10: "H019",
            descricao: "Inflamação não especificada da pálpebra",
            favorito: false
        },
        {
            cid10: "H020",
            descricao: "Entrópio e triquíase da pálpebra",
            favorito: false
        },
        {
            cid10: "H021",
            descricao: "Ectrópio da pálpebra",
            favorito: false
        },
        {
            cid10: "H022",
            descricao: "Lagoftalmo",
            favorito: false
        },
        {
            cid10: "H023",
            descricao: "Blefarocalásia",
            favorito: false
        },
        {
            cid10: "H024",
            descricao: "Ptose da pálpebra",
            favorito: false
        },
        {
            cid10: "H025",
            descricao: "Outros transtornos que afetam a função da pálpebra",
            favorito: false
        },
        {
            cid10: "H026",
            descricao: "Xantelasma da pálpebra",
            favorito: false
        },
        {
            cid10: "H027",
            descricao: "Outros transtornos degenerativos da pálpebra e da área periocular",
            favorito: false
        },
        {
            cid10: "H028",
            descricao: "Outros transtornos especificados das pálpebras",
            favorito: false
        },
        {
            cid10: "H029",
            descricao: "Transtorno não especificado da pálpebra",
            favorito: false
        },
        {
            cid10: "H030",
            descricao: "Infestação parasitária da pálpebra em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H031",
            descricao: "Comprometimento da pálpebra em outras doenças infecciosas classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H038",
            descricao: "Comprometimento da pálpebra em outras doenças classificadas em outras partes",
            favorito: false
        },
        {
            cid10: "H040",
            descricao: "Dacrioadenite",
            favorito: false
        },
        {
            cid10: "H041",
            descricao: "Outros transtornos da glândula lacrimal",
            favorito: false
        },
        {
            cid10: "H042",
            descricao: "Epífora",
            favorito: false
        },
        {
            cid10: "H043",
            descricao: "Inflamação aguda e não especificada dos canais lacrimais",
            favorito: false
        },
        {
            cid10: "H044",
            descricao: "Inflamação crônica dos canais lacrimais",
            favorito: false
        },
        {
            cid10: "H045",
            descricao: "Estenose e insuficiência dos canais lacrimais",
            favorito: false
        },
        {
            cid10: "H046",
            descricao: "Outras alterações nos canais lacrimais",
            favorito: false
        },
        {
            cid10: "H048",
            descricao: "Outros transtornos do aparelho lacrimal",
            favorito: false
        },
        {
            cid10: "H049",
            descricao: "Transtorno não especificado do aparelho lacrimal",
            favorito: false
        },
        {
            cid10: "H050",
            descricao: "Inflamação aguda da órbita",
            favorito: false
        },
        {
            cid10: "H051",
            descricao: "Transtornos inflamatórios crônicos da órbita",
            favorito: false
        },
        {
            cid10: "H052",
            descricao: "Afecções exoftálmicas",
            favorito: false
        },
        {
            cid10: "H053",
            descricao: "Deformidade da órbita",
            favorito: false
        },
        {
            cid10: "H054",
            descricao: "Enoftalmia",
            favorito: false
        },
        {
            cid10: "H055",
            descricao: "Corpo estranho (antigo) retido conseqüente a ferimento perfurante da órbita",
            favorito: false
        },
        {
            cid10: "H058",
            descricao: "Outros transtornos da órbita",
            favorito: false
        },
        {
            cid10: "H059",
            descricao: "Transtorno não especificado da órbita",
            favorito: false
        },
        {
            cid10: "H060",
            descricao: "Transtornos do aparelho lacrimal em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H061",
            descricao: "Infestação parasitária da órbita em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H062",
            descricao: "Exoftalmo distireoídeo",
            favorito: false
        },
        {
            cid10: "H063",
            descricao: "Outros transtornos da órbita em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H100",
            descricao: "Conjuntivite mucopurulenta",
            favorito: false
        },
        {
            cid10: "H101",
            descricao: "Conjuntivite aguda atópica",
            favorito: false
        },
        {
            cid10: "H102",
            descricao: "Outras conjuntivites agudas",
            favorito: false
        },
        {
            cid10: "H103",
            descricao: "Conjuntivite aguda não especificada",
            favorito: false
        },
        {
            cid10: "H104",
            descricao: "Conjuntivite crônica",
            favorito: false
        },
        {
            cid10: "H105",
            descricao: "Blefaroconjuntivite",
            favorito: false
        },
        {
            cid10: "H108",
            descricao: "Outras conjuntivites",
            favorito: false
        },
        {
            cid10: "H109",
            descricao: "Conjuntivite não especificada",
            favorito: false
        },
        {
            cid10: "H110",
            descricao: "Pterígio",
            favorito: false
        },
        {
            cid10: "H111",
            descricao: "Degenerações e depósitos da conjuntiva",
            favorito: false
        },
        {
            cid10: "H112",
            descricao: "Cicatrizes da conjuntiva",
            favorito: false
        },
        {
            cid10: "H113",
            descricao: "Hemorragia conjuntival",
            favorito: false
        },
        {
            cid10: "H114",
            descricao: "Outros transtornos vasculares e cistos conjuntivais",
            favorito: false
        },
        {
            cid10: "H118",
            descricao: "Outros transtornos especificados da conjuntiva",
            favorito: false
        },
        {
            cid10: "H119",
            descricao: "Transtorno não especificado da conjuntiva",
            favorito: false
        },
        {
            cid10: "H130",
            descricao: "Infestação por filárias na conjuntiva",
            favorito: false
        },
        {
            cid10: "H131",
            descricao: "Conjuntivite em doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H132",
            descricao: "Conjuntivite em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H133",
            descricao: "Penfigóide ocular",
            favorito: false
        },
        {
            cid10: "H138",
            descricao: "Outros transtornos da conjuntiva em doença classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H150",
            descricao: "Esclerite",
            favorito: false
        },
        {
            cid10: "H151",
            descricao: "Episclerite",
            favorito: false
        },
        {
            cid10: "H158",
            descricao: "Outros transtornos da esclerótica",
            favorito: false
        },
        {
            cid10: "H159",
            descricao: "Transtorno não especificado da esclerótica",
            favorito: false
        },
        {
            cid10: "H160",
            descricao: "Úlcera de córnea",
            favorito: false
        },
        {
            cid10: "H161",
            descricao: "Outras ceratites superficiais sem conjuntivite",
            favorito: false
        },
        {
            cid10: "H162",
            descricao: "Ceratoconjuntivite",
            favorito: false
        },
        {
            cid10: "H163",
            descricao: "Ceratites intersticial e profunda",
            favorito: false
        },
        {
            cid10: "H164",
            descricao: "Neovascularização da córnea",
            favorito: false
        },
        {
            cid10: "H168",
            descricao: "Outras ceratites",
            favorito: false
        },
        {
            cid10: "H169",
            descricao: "Ceratite não especificada",
            favorito: false
        },
        {
            cid10: "H170",
            descricao: "Leucoma aderente",
            favorito: false
        },
        {
            cid10: "H171",
            descricao: "Outra opacidade central da córnea",
            favorito: false
        },
        {
            cid10: "H178",
            descricao: "Outras cicatrizes e opacidades da córnea",
            favorito: false
        },
        {
            cid10: "H179",
            descricao: "Cicatriz e opacidade não especificadas da córnea",
            favorito: false
        },
        {
            cid10: "H180",
            descricao: "Pigmentações e depósitos da córnea",
            favorito: false
        },
        {
            cid10: "H181",
            descricao: "Ceratopatia bolhosa",
            favorito: false
        },
        {
            cid10: "H182",
            descricao: "Outros edemas da córnea",
            favorito: false
        },
        {
            cid10: "H183",
            descricao: "Alterações nas membranas da córnea",
            favorito: false
        },
        {
            cid10: "H184",
            descricao: "Degenerações da córnea",
            favorito: false
        },
        {
            cid10: "H185",
            descricao: "Distrofias hereditárias da córnea",
            favorito: false
        },
        {
            cid10: "H186",
            descricao: "Ceratocone",
            favorito: false
        },
        {
            cid10: "H187",
            descricao: "Outras deformidades da córnea",
            favorito: false
        },
        {
            cid10: "H188",
            descricao: "Outros transtornos especificados da córnea",
            favorito: false
        },
        {
            cid10: "H189",
            descricao: "Transtorno não especificado da córnea",
            favorito: false
        },
        {
            cid10: "H190",
            descricao: "Esclerite e episclerite em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H191",
            descricao: "Ceratite e ceratoconjuntivite pelo vírus do herpes simples",
            favorito: false
        },
        {
            cid10: "H192",
            descricao: "Ceratite e ceratoconjuntivite em outras doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H193",
            descricao: "Ceratite e ceratoconjuntivite em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H198",
            descricao: "Outros transtornos de esclerótica e da córnea em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H200",
            descricao: "Iridociclite aguda e subaguda",
            favorito: false
        },
        {
            cid10: "H201",
            descricao: "Iridociclite crônica",
            favorito: false
        },
        {
            cid10: "H202",
            descricao: "Iridociclite induzida pelo cristalino",
            favorito: false
        },
        {
            cid10: "H208",
            descricao: "Outras iridociclites",
            favorito: false
        },
        {
            cid10: "H209",
            descricao: "Iridociclite não especificada",
            favorito: false
        },
        {
            cid10: "H210",
            descricao: "Hifema",
            favorito: false
        },
        {
            cid10: "H211",
            descricao: "Outros transtornos vasculares da íris e do corpo ciliar",
            favorito: false
        },
        {
            cid10: "H212",
            descricao: "Degenerações da íris e do corpo ciliar",
            favorito: false
        },
        {
            cid10: "H213",
            descricao: "Cistos da íris, do corpo ciliar e da câmara anterior",
            favorito: false
        },
        {
            cid10: "H214",
            descricao: "Membranas pupilares",
            favorito: false
        },
        {
            cid10: "H215",
            descricao: "Outras aderências e roturas da íris e do corpo ciliar",
            favorito: false
        },
        {
            cid10: "H218",
            descricao: "Outros transtornos especificados da íris e do corpo ciliar",
            favorito: false
        },
        {
            cid10: "H219",
            descricao: "Transtorno não especificado da íris e do corpo ciliar",
            favorito: false
        },
        {
            cid10: "H220",
            descricao: "Iridociclite em doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H221",
            descricao: "Iridociclite em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H228",
            descricao: "Outros transtornos da íris e do corpo ciliar em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H250",
            descricao: "Catarata senil incipiente",
            favorito: false
        },
        {
            cid10: "H251",
            descricao: "Catarata senil nuclear",
            favorito: false
        },
        {
            cid10: "H252",
            descricao: "Catarata senil tipo Morgagni",
            favorito: false
        },
        {
            cid10: "H258",
            descricao: "Outras cataratas senis",
            favorito: false
        },
        {
            cid10: "H259",
            descricao: "Catarata senil não especificada",
            favorito: false
        },
        {
            cid10: "H260",
            descricao: "Catarata infantil, juvenil e pré-senil",
            favorito: false
        },
        {
            cid10: "H261",
            descricao: "Catarata traumática",
            favorito: false
        },
        {
            cid10: "H262",
            descricao: "Catarata complicada",
            favorito: false
        },
        {
            cid10: "H263",
            descricao: "Catarata induzida por drogas",
            favorito: false
        },
        {
            cid10: "H264",
            descricao: "Pós-catarata",
            favorito: false
        },
        {
            cid10: "H268",
            descricao: "Outras cataratas especificadas",
            favorito: false
        },
        {
            cid10: "H269",
            descricao: "Catarata não especificada",
            favorito: false
        },
        {
            cid10: "H270",
            descricao: "Afacia",
            favorito: false
        },
        {
            cid10: "H271",
            descricao: "Deslocamento do cristalino",
            favorito: false
        },
        {
            cid10: "H278",
            descricao: "Outros transtornos especificados do cristalino",
            favorito: false
        },
        {
            cid10: "H279",
            descricao: "Transtorno não especificado do cristalino",
            favorito: false
        },
        {
            cid10: "H280",
            descricao: "Catarata diabética",
            favorito: false
        },
        {
            cid10: "H281",
            descricao: "Catarata em outras doenças endócrinas, nutricionais e metabólicas",
            favorito: false
        },
        {
            cid10: "H282",
            descricao: "Catarata em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H288",
            descricao: "Outros transtorno do cristalino em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H300",
            descricao: "Inflamação coriorretiniana focal",
            favorito: false
        },
        {
            cid10: "H301",
            descricao: "Inflamação corrorretiniana disseminada",
            favorito: false
        },
        {
            cid10: "H302",
            descricao: "Ciclite posterior",
            favorito: false
        },
        {
            cid10: "H308",
            descricao: "Outras inflamações coriorretinianas",
            favorito: false
        },
        {
            cid10: "H309",
            descricao: "Inflamação não especificada da coróide e da retina",
            favorito: false
        },
        {
            cid10: "H310",
            descricao: "Cicatrizes coriorretinianas",
            favorito: false
        },
        {
            cid10: "H311",
            descricao: "Degeneração da coróide",
            favorito: false
        },
        {
            cid10: "H312",
            descricao: "Distrofia hereditária da coróide",
            favorito: false
        },
        {
            cid10: "H313",
            descricao: "Hemorragia e rotura da coróide",
            favorito: false
        },
        {
            cid10: "H314",
            descricao: "Descolamento da coróide",
            favorito: false
        },
        {
            cid10: "H318",
            descricao: "Outros transtornos especificados da coróide",
            favorito: false
        },
        {
            cid10: "H319",
            descricao: "Transtorno não especificado da coróide",
            favorito: false
        },
        {
            cid10: "H320",
            descricao: "Inflamação coriorretiniana em doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H328",
            descricao: "Outros transtornos coriorretinianos em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H330",
            descricao: "Descolamento da retina com defeito retiniano",
            favorito: false
        },
        {
            cid10: "H331",
            descricao: "Retinosquise e cistos da retina",
            favorito: false
        },
        {
            cid10: "H332",
            descricao: "Descolamento seroso da retina",
            favorito: false
        },
        {
            cid10: "H333",
            descricao: "Defeitos da retina sem descolamento",
            favorito: false
        },
        {
            cid10: "H334",
            descricao: "Descolamento da retina por tração",
            favorito: false
        },
        {
            cid10: "H335",
            descricao: "Outros descolamentos da retina",
            favorito: false
        },
        {
            cid10: "H340",
            descricao: "Oclusão arterial retiniana transitória",
            favorito: false
        },
        {
            cid10: "H341",
            descricao: "Oclusão da artéria retiniana central",
            favorito: false
        },
        {
            cid10: "H342",
            descricao: "Outras oclusões da artéria retiniana",
            favorito: false
        },
        {
            cid10: "H348",
            descricao: "Outras oclusões vasculares retinianas",
            favorito: false
        },
        {
            cid10: "H349",
            descricao: "Oclusão vascular retiniana não especificada",
            favorito: false
        },
        {
            cid10: "H350",
            descricao: "Retinopatias de fundo e alterações vasculares da retina",
            favorito: false
        },
        {
            cid10: "H351",
            descricao: "Retinopatia da prematuridade",
            favorito: false
        },
        {
            cid10: "H352",
            descricao: "Outras retinopatias proliferativas",
            favorito: false
        },
        {
            cid10: "H353",
            descricao: "Degeneração da mácula e do pólo posterior",
            favorito: false
        },
        {
            cid10: "H354",
            descricao: "Degenerações periféricas da retina",
            favorito: false
        },
        {
            cid10: "H355",
            descricao: "Distrofias hereditárias da retina",
            favorito: false
        },
        {
            cid10: "H356",
            descricao: "Hemorragia retiniana",
            favorito: false
        },
        {
            cid10: "H357",
            descricao: "Separação das camadas da retina",
            favorito: false
        },
        {
            cid10: "H358",
            descricao: "Outros transtornos especificados da retina",
            favorito: false
        },
        {
            cid10: "H359",
            descricao: "Transtorno da retina não especificado",
            favorito: false
        },
        {
            cid10: "H360",
            descricao: "Retinopatia diabética",
            favorito: false
        },
        {
            cid10: "H368",
            descricao: "Outros transtornos retinianos em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H400",
            descricao: "Suspeita de glaucoma",
            favorito: false
        },
        {
            cid10: "H401",
            descricao: "Glaucoma primário de ângulo aberto",
            favorito: false
        },
        {
            cid10: "H402",
            descricao: "Glaucoma primário de ângulo fechado",
            favorito: false
        },
        {
            cid10: "H403",
            descricao: "Glaucoma secundário a traumatismo ocular",
            favorito: false
        },
        {
            cid10: "H404",
            descricao: "Glaucoma secundário a inflamação ocular",
            favorito: false
        },
        {
            cid10: "H405",
            descricao: "Glaucoma secundário a outros transtornos do olho",
            favorito: false
        },
        {
            cid10: "H406",
            descricao: "Glaucoma secundário a drogas",
            favorito: false
        },
        {
            cid10: "H408",
            descricao: "Outro glaucoma",
            favorito: false
        },
        {
            cid10: "H409",
            descricao: "Glaucoma não especificado",
            favorito: false
        },
        {
            cid10: "H420",
            descricao: "Glaucoma em doenças endócrinas, nutricionais e metabólicas classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H428",
            descricao: "Glaucoma em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H430",
            descricao: "Prolapso do humor vítreo",
            favorito: false
        },
        {
            cid10: "H431",
            descricao: "Hemorragia do humor vítreo",
            favorito: false
        },
        {
            cid10: "H432",
            descricao: "Depósitos cristalinos no humor vítreo",
            favorito: false
        },
        {
            cid10: "H433",
            descricao: "Outras opacidades do vítreo",
            favorito: false
        },
        {
            cid10: "H438",
            descricao: "Outros transtornos do humor vítreo",
            favorito: false
        },
        {
            cid10: "H439",
            descricao: "Transtorno não especificado do humor vítreo",
            favorito: false
        },
        {
            cid10: "H440",
            descricao: "Endoftalmite purulenta",
            favorito: false
        },
        {
            cid10: "H441",
            descricao: "Outras endoftalmites",
            favorito: false
        },
        {
            cid10: "H442",
            descricao: "Miopia degenerativa",
            favorito: false
        },
        {
            cid10: "H443",
            descricao: "Outros transtornos degenerativos do globo ocular",
            favorito: false
        },
        {
            cid10: "H444",
            descricao: "Hipotonia do olho",
            favorito: false
        },
        {
            cid10: "H445",
            descricao: "Afecções degenerativas do globo ocular",
            favorito: false
        },
        {
            cid10: "H446",
            descricao: "Corpo estranho retido (antigo) intra-ocular de natureza magnética",
            favorito: false
        },
        {
            cid10: "H447",
            descricao: "Corpo estranho retido (antigo) intra-ocular de natureza não-magnética",
            favorito: false
        },
        {
            cid10: "H448",
            descricao: "Outros transtornos do globo ocular",
            favorito: false
        },
        {
            cid10: "H449",
            descricao: "Transtorno não especificado do globo ocular",
            favorito: false
        },
        {
            cid10: "H450",
            descricao: "Hemorragia vítrea em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H451",
            descricao: "Endoftalmite em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H458",
            descricao: "Outros transtornos do humor vítreo e do globo ocular em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H46",
            descricao: "Neurite óptica",
            favorito: false
        },
        {
            cid10: "H470",
            descricao: "Transtornos do nervo óptico não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "H471",
            descricao: "Papiledema não especificado",
            favorito: false
        },
        {
            cid10: "H472",
            descricao: "Atrofia óptica",
            favorito: false
        },
        {
            cid10: "H473",
            descricao: "Outros transtornos do disco óptico",
            favorito: false
        },
        {
            cid10: "H474",
            descricao: "Transtornos do quiasma óptico",
            favorito: false
        },
        {
            cid10: "H475",
            descricao: "Transtornos de outras vias ópticas",
            favorito: false
        },
        {
            cid10: "H476",
            descricao: "Transtornos do córtex visual",
            favorito: false
        },
        {
            cid10: "H477",
            descricao: "Transtornos não especificados das vias ópticas",
            favorito: false
        },
        {
            cid10: "H480",
            descricao: "Atrofia óptica em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H481",
            descricao: "Neurite retrobulbar em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H488",
            descricao: "Outros transtornos do nervo óptico e das vias ópticas em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H490",
            descricao: "Paralisia do terceiro par [oculomotor]",
            favorito: false
        },
        {
            cid10: "H491",
            descricao: "Paralisia do quarto par [troclear]",
            favorito: false
        },
        {
            cid10: "H492",
            descricao: "Paralisia do sexto par [abducente]",
            favorito: false
        },
        {
            cid10: "H493",
            descricao: "Oftalmoplegia total (externa)",
            favorito: false
        },
        {
            cid10: "H494",
            descricao: "Oftalmoplegia externa progressiva",
            favorito: false
        },
        {
            cid10: "H498",
            descricao: "Outros estrabismos paralíticos",
            favorito: false
        },
        {
            cid10: "H499",
            descricao: "Estrabismo paralítico não especificado",
            favorito: false
        },
        {
            cid10: "H500",
            descricao: "Estrabismo convergente concomitante",
            favorito: false
        },
        {
            cid10: "H501",
            descricao: "Estrabismo divergente concomitante",
            favorito: false
        },
        {
            cid10: "H502",
            descricao: "Estrabismo vertical",
            favorito: false
        },
        {
            cid10: "H503",
            descricao: "Heterotropia intermitente",
            favorito: false
        },
        {
            cid10: "H504",
            descricao: "Outras heterotropias e as não especificadas",
            favorito: false
        },
        {
            cid10: "H505",
            descricao: "Heteroforia",
            favorito: false
        },
        {
            cid10: "H506",
            descricao: "Estrabismo mecânico",
            favorito: false
        },
        {
            cid10: "H508",
            descricao: "Outros estrabismos especificados",
            favorito: false
        },
        {
            cid10: "H509",
            descricao: "Estrabismo não especificado",
            favorito: false
        },
        {
            cid10: "H510",
            descricao: "Paralisia do olhar conjugado",
            favorito: false
        },
        {
            cid10: "H511",
            descricao: "Insuficiência ou excesso de convergência",
            favorito: false
        },
        {
            cid10: "H512",
            descricao: "Oftalmoplegia internuclear",
            favorito: false
        },
        {
            cid10: "H518",
            descricao: "Outros transtornos especificados do movimento binocular",
            favorito: false
        },
        {
            cid10: "H519",
            descricao: "Transtorno não especificado do movimento binocular",
            favorito: false
        },
        {
            cid10: "H520",
            descricao: "Hipermetropia",
            favorito: false
        },
        {
            cid10: "H521",
            descricao: "Miopia",
            favorito: false
        },
        {
            cid10: "H522",
            descricao: "Astigmatismo",
            favorito: false
        },
        {
            cid10: "H523",
            descricao: "Anisometropia e aniseiconia",
            favorito: false
        },
        {
            cid10: "H524",
            descricao: "Presbiopia",
            favorito: false
        },
        {
            cid10: "H525",
            descricao: "Transtornos da acomodação",
            favorito: false
        },
        {
            cid10: "H526",
            descricao: "Outros transtornos da refração",
            favorito: false
        },
        {
            cid10: "H527",
            descricao: "Transtorno não especificado da refração",
            favorito: false
        },
        {
            cid10: "H530",
            descricao: "Ambliopia por anopsia",
            favorito: false
        },
        {
            cid10: "H531",
            descricao: "Distúrbios visuais subjetivos",
            favorito: false
        },
        {
            cid10: "H532",
            descricao: "Diplopia",
            favorito: false
        },
        {
            cid10: "H533",
            descricao: "Outros transtornos da visão binocular",
            favorito: false
        },
        {
            cid10: "H534",
            descricao: "Defeitos do campo visual",
            favorito: false
        },
        {
            cid10: "H535",
            descricao: "Deficiências da visão cromática",
            favorito: false
        },
        {
            cid10: "H536",
            descricao: "Cegueira noturna",
            favorito: false
        },
        {
            cid10: "H538",
            descricao: "Outros distúrbios visuais",
            favorito: false
        },
        {
            cid10: "H539",
            descricao: "Distúrbio visual não especificado",
            favorito: false
        },
        {
            cid10: "H540",
            descricao: "Cegueira, ambos os olhos",
            favorito: false
        },
        {
            cid10: "H541",
            descricao: "Cegueira em um olho e visão subnormal em outro",
            favorito: false
        },
        {
            cid10: "H542",
            descricao: "Visão subnormal de ambos os olhos",
            favorito: false
        },
        {
            cid10: "H543",
            descricao: "Perda não qualificada da visão em ambos os olhos",
            favorito: false
        },
        {
            cid10: "H544",
            descricao: "Cegueira em um olho",
            favorito: false
        },
        {
            cid10: "H545",
            descricao: "Visão subnormal em um olho",
            favorito: false
        },
        {
            cid10: "H546",
            descricao: "Perda não qualificada da visão em um olho",
            favorito: false
        },
        {
            cid10: "H547",
            descricao: "Perda não especificada da visão",
            favorito: false
        },
        {
            cid10: "H55",
            descricao: "Nistagmo e outros movimentos irregulares do olho",
            favorito: false
        },
        {
            cid10: "H570",
            descricao: "Anomalias da função pupilar",
            favorito: false
        },
        {
            cid10: "H571",
            descricao: "Dor ocular",
            favorito: false
        },
        {
            cid10: "H578",
            descricao: "Outros transtornos especificados do olho e anexos",
            favorito: false
        },
        {
            cid10: "H579",
            descricao: "Transtorno não especificado do olho e anexos",
            favorito: false
        },
        {
            cid10: "H580",
            descricao: "Anomalias da função pupilar em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H581",
            descricao: "Distúrbios visuais em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H588",
            descricao: "Outros transtornos especificados do olho e anexos em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H590",
            descricao: "Ceropatia (bolhosa afácica) subseqüente a cirurgia de catarata",
            favorito: false
        },
        {
            cid10: "H598",
            descricao: "Outros transtornos do olho e anexos pós-procedimentos",
            favorito: false
        },
        {
            cid10: "H599",
            descricao: "Transtorno não especificado do olho e anexos pós-procedimento",
            favorito: false
        },
        {
            cid10: "H600",
            descricao: "Abscesso do ouvido externo",
            favorito: false
        },
        {
            cid10: "H601",
            descricao: "Celulite do ouvido externo",
            favorito: false
        },
        {
            cid10: "H602",
            descricao: "Otite externa maligna",
            favorito: false
        },
        {
            cid10: "H603",
            descricao: "Outras otites externas infecciosas",
            favorito: false
        },
        {
            cid10: "H604",
            descricao: "Colesteatoma do ouvido externo",
            favorito: false
        },
        {
            cid10: "H605",
            descricao: "Otite externa aguda não-infecciosa",
            favorito: false
        },
        {
            cid10: "H608",
            descricao: "Outras otites externas",
            favorito: false
        },
        {
            cid10: "H609",
            descricao: "Otite externa não especificada",
            favorito: false
        },
        {
            cid10: "H610",
            descricao: "Pericondrite do pavilhão da orelha",
            favorito: false
        },
        {
            cid10: "H611",
            descricao: "Transtornos não-infecciosos do pavilhão da orelha",
            favorito: false
        },
        {
            cid10: "H612",
            descricao: "Cerume impactado",
            favorito: false
        },
        {
            cid10: "H613",
            descricao: "Estenose adquirida do conduto auditivo externo",
            favorito: false
        },
        {
            cid10: "H618",
            descricao: "Outros transtornos especificados do ouvido externo",
            favorito: false
        },
        {
            cid10: "H619",
            descricao: "Transtorno não especificado do ouvido externo",
            favorito: false
        },
        {
            cid10: "H620",
            descricao: "Otite externa em doenças bacterianas classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H621",
            descricao: "Otite externa em doenças virais classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H622",
            descricao: "Otite externa em micoses",
            favorito: false
        },
        {
            cid10: "H623",
            descricao: "Otite externa em outras doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H624",
            descricao: "Otite externa em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H628",
            descricao: "Outros transtornos do ouvido externo em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H650",
            descricao: "Otite média aguda serosa",
            favorito: false
        },
        {
            cid10: "H651",
            descricao: "Outras otites médias agudas não-supurativas",
            favorito: false
        },
        {
            cid10: "H652",
            descricao: "Otite média serosa crônica",
            favorito: false
        },
        {
            cid10: "H653",
            descricao: "Otite média mucóide crônica",
            favorito: false
        },
        {
            cid10: "H654",
            descricao: "Outras otites médias crônicas não-supurativas",
            favorito: false
        },
        {
            cid10: "H659",
            descricao: "Otite média não-supurativa, não especificada",
            favorito: false
        },
        {
            cid10: "H660",
            descricao: "Otite média aguda supurativa",
            favorito: false
        },
        {
            cid10: "H661",
            descricao: "Otite média tubotimpânica supurativa crônica",
            favorito: false
        },
        {
            cid10: "H662",
            descricao: "Otite média ático-antral supurativa crônica",
            favorito: false
        },
        {
            cid10: "H663",
            descricao: "Outras otites médias supurativas crônicas",
            favorito: false
        },
        {
            cid10: "H664",
            descricao: "Otite média supurativa não especificada",
            favorito: false
        },
        {
            cid10: "H669",
            descricao: "Otite média não especificada",
            favorito: false
        },
        {
            cid10: "H670",
            descricao: "Otite média em doenças bacterianas classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H671",
            descricao: "Otite média em doenças virais classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H678",
            descricao: "Otite média em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H680",
            descricao: "Salpingite da trompa de Eustáquio",
            favorito: false
        },
        {
            cid10: "H681",
            descricao: "Obstrução da trompa de Eustáquio",
            favorito: false
        },
        {
            cid10: "H690",
            descricao: "Trompa de Eustáquio aberta (patente) (patulosa)",
            favorito: false
        },
        {
            cid10: "H698",
            descricao: "Outros transtornos especificados da trompa de Eustáquio",
            favorito: false
        },
        {
            cid10: "H699",
            descricao: "Transtorno não especificado da trompa de Eustáquio",
            favorito: false
        },
        {
            cid10: "H700",
            descricao: "Mastoidite aguda",
            favorito: false
        },
        {
            cid10: "H701",
            descricao: "Mastoidite crônica",
            favorito: false
        },
        {
            cid10: "H702",
            descricao: "Petrosite",
            favorito: false
        },
        {
            cid10: "H708",
            descricao: "Outras mastoidites e afecções relacionadas com a mastoidite",
            favorito: false
        },
        {
            cid10: "H709",
            descricao: "Mastoidite não especificada",
            favorito: false
        },
        {
            cid10: "H71",
            descricao: "Colesteatoma do ouvido médio",
            favorito: false
        },
        {
            cid10: "H720",
            descricao: "Perfuração central da membrana do tímpano",
            favorito: false
        },
        {
            cid10: "H721",
            descricao: "Perfuração ática da membrana do tímpano",
            favorito: false
        },
        {
            cid10: "H722",
            descricao: "Outras perfurações marginais da membrana do tímpano",
            favorito: false
        },
        {
            cid10: "H728",
            descricao: "Outras perfurações da membrana do tímpano",
            favorito: false
        },
        {
            cid10: "H729",
            descricao: "Perfuração não especificada da membrana do tímpano",
            favorito: false
        },
        {
            cid10: "H730",
            descricao: "Miringite aguda",
            favorito: false
        },
        {
            cid10: "H731",
            descricao: "Miringite crônica",
            favorito: false
        },
        {
            cid10: "H738",
            descricao: "Outros transtornos especificados da membrana do tímpano",
            favorito: false
        },
        {
            cid10: "H739",
            descricao: "Transtorno não especificado da membrana do tímpano",
            favorito: false
        },
        {
            cid10: "H740",
            descricao: "Timpanosclerose",
            favorito: false
        },
        {
            cid10: "H741",
            descricao: "Doença adesiva do ouvido médio",
            favorito: false
        },
        {
            cid10: "H742",
            descricao: "Descontinuidade e deslocamento dos ossículos do ouvido",
            favorito: false
        },
        {
            cid10: "H743",
            descricao: "Outras anormalidades adquiridas dos ossículos do ouvido",
            favorito: false
        },
        {
            cid10: "H744",
            descricao: "Pólipo do ouvido médio",
            favorito: false
        },
        {
            cid10: "H748",
            descricao: "Outros transtornos especificados do ouvido médio e da mastóide",
            favorito: false
        },
        {
            cid10: "H749",
            descricao: "Transtorno não especificado do ouvido médio e da mastóide",
            favorito: false
        },
        {
            cid10: "H750",
            descricao: "Mastoidite em doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H758",
            descricao: "Outros transtornos especificados do ouvido médio e da mastóide em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H800",
            descricao: "Otosclerose que compromete a janela oval, não-obliterante",
            favorito: false
        },
        {
            cid10: "H801",
            descricao: "Otosclerose que compromete a janela oval, obliterante",
            favorito: false
        },
        {
            cid10: "H802",
            descricao: "Otosclerose da cóclea",
            favorito: false
        },
        {
            cid10: "H808",
            descricao: "Outras otoscleroses",
            favorito: false
        },
        {
            cid10: "H809",
            descricao: "Otosclerose não especificada",
            favorito: false
        },
        {
            cid10: "H810",
            descricao: "Doença de Ménière",
            favorito: false
        },
        {
            cid10: "H811",
            descricao: "Vertigem paroxística benigna",
            favorito: false
        },
        {
            cid10: "H812",
            descricao: "Neuronite vestibular",
            favorito: false
        },
        {
            cid10: "H813",
            descricao: "Outras vertigens periféricas",
            favorito: false
        },
        {
            cid10: "H814",
            descricao: "Vertigem de origem central",
            favorito: false
        },
        {
            cid10: "H818",
            descricao: "Outros transtornos da função vestibular",
            favorito: false
        },
        {
            cid10: "H819",
            descricao: "Transtornos não especificados da função vestibular",
            favorito: false
        },
        {
            cid10: "H82",
            descricao: "Síndromes vertiginosas em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H830",
            descricao: "Labirintite",
            favorito: false
        },
        {
            cid10: "H831",
            descricao: "Fístula do labirinto",
            favorito: false
        },
        {
            cid10: "H832",
            descricao: "Disfunção do labirinto",
            favorito: false
        },
        {
            cid10: "H833",
            descricao: "Efeitos do ruído sobre o ouvido interno",
            favorito: false
        },
        {
            cid10: "H838",
            descricao: "Outros transtornos especificados do ouvido interno",
            favorito: false
        },
        {
            cid10: "H839",
            descricao: "Transtorno não especificado do ouvido interno",
            favorito: false
        },
        {
            cid10: "H900",
            descricao: "Perda de audição bilateral devida a transtorno de condução",
            favorito: false
        },
        {
            cid10: "H901",
            descricao: "Perda de audição unilateral por transtorno de condução, sem restrição de audição contralateral",
            favorito: false
        },
        {
            cid10: "H902",
            descricao: "Perda não especificada de audição devida a transtorno de condução",
            favorito: false
        },
        {
            cid10: "H903",
            descricao: "Perda de audição bilateral neuro-sensorial",
            favorito: false
        },
        {
            cid10: "H904",
            descricao: "Perda de audição unilateral neuro-sensorial, sem restrição de audição contralateral",
            favorito: false
        },
        {
            cid10: "H905",
            descricao: "Perda de audição neuro-sensorial não especificada",
            favorito: false
        },
        {
            cid10: "H906",
            descricao: "Perda de audição bilateral mista, de condução e neuro-sensorial",
            favorito: false
        },
        {
            cid10: "H907",
            descricao: "Perda de audição unilateral mista, de condução e neuro-sensorial, sem restrição de audição contralateral",
            favorito: false
        },
        {
            cid10: "H908",
            descricao: "Perda de audição mista, de condução e neuro-sensorial, não especificada",
            favorito: false
        },
        {
            cid10: "H910",
            descricao: "Perda de audição ototóxica",
            favorito: false
        },
        {
            cid10: "H911",
            descricao: "Presbiacusia",
            favorito: false
        },
        {
            cid10: "H912",
            descricao: "Perda de audição súbita idiopática",
            favorito: false
        },
        {
            cid10: "H913",
            descricao: "Surdo-mudez não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "H918",
            descricao: "Outras perdas de audição especificadas",
            favorito: false
        },
        {
            cid10: "H919",
            descricao: "Perda não especificada de audição",
            favorito: false
        },
        {
            cid10: "H920",
            descricao: "Otalgia",
            favorito: false
        },
        {
            cid10: "H921",
            descricao: "Otorréia",
            favorito: false
        },
        {
            cid10: "H922",
            descricao: "Otorragia",
            favorito: false
        },
        {
            cid10: "H930",
            descricao: "Transtornos degenerativos e vasculares do ouvido",
            favorito: false
        },
        {
            cid10: "H931",
            descricao: "Tinnitus",
            favorito: false
        },
        {
            cid10: "H932",
            descricao: "Outras percepções auditivas anormais",
            favorito: false
        },
        {
            cid10: "H933",
            descricao: "Transtornos do nervo acústico",
            favorito: false
        },
        {
            cid10: "H938",
            descricao: "Outros transtornos especificados do ouvido",
            favorito: false
        },
        {
            cid10: "H939",
            descricao: "Transtorno não especificado do ouvido",
            favorito: false
        },
        {
            cid10: "H940",
            descricao: "Neurite acústica em doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H948",
            descricao: "Outros transtornos especificados do ouvido em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "H950",
            descricao: "Colesteatoma recorrente da cavidade pós-mastoidectomia",
            favorito: false
        },
        {
            cid10: "H951",
            descricao: "Outros transtornos conseqüentes à mastoidectomia",
            favorito: false
        },
        {
            cid10: "H958",
            descricao: "Outros transtornos do ouvido e da apófise mastóide pós-procedimento",
            favorito: false
        },
        {
            cid10: "H959",
            descricao: "Transtorno não especificado do ouvido e da apófise mastóide pós-procedimento",
            favorito: false
        },
        {
            cid10: "I00",
            descricao: "Febre reumática sem menção de comprometimento do coração",
            favorito: false
        },
        {
            cid10: "I010",
            descricao: "Pericardite reumática aguda",
            favorito: false
        },
        {
            cid10: "I011",
            descricao: "Endocardite reumática aguda",
            favorito: false
        },
        {
            cid10: "I012",
            descricao: "Miocardite reumática aguda",
            favorito: false
        },
        {
            cid10: "I018",
            descricao: "Outras formas de doença cardíaca reumática aguda",
            favorito: false
        },
        {
            cid10: "I019",
            descricao: "Doença cardíaca reumática aguda não especificada",
            favorito: false
        },
        {
            cid10: "I020",
            descricao: "Coréia reumática com comprometimento cardíaco",
            favorito: false
        },
        {
            cid10: "I029",
            descricao: "Coréia reumática sem comprometimento cardíaco",
            favorito: false
        },
        {
            cid10: "I050",
            descricao: "Estenose mitral",
            favorito: false
        },
        {
            cid10: "I051",
            descricao: "Insuficiência mitral reumática",
            favorito: false
        },
        {
            cid10: "I052",
            descricao: "Estenose mitral com insuficiência",
            favorito: false
        },
        {
            cid10: "I058",
            descricao: "Outras doenças da valva mitral",
            favorito: false
        },
        {
            cid10: "I059",
            descricao: "Doença não especificada da valva mitral",
            favorito: false
        },
        {
            cid10: "I060",
            descricao: "Estenose aórtica reumática",
            favorito: false
        },
        {
            cid10: "I061",
            descricao: "Insuficiência aórtica reumática",
            favorito: false
        },
        {
            cid10: "I062",
            descricao: "Estenose aórtica reumática com insuficiência",
            favorito: false
        },
        {
            cid10: "I068",
            descricao: "Outras doenças reumáticas da valva aórtica",
            favorito: false
        },
        {
            cid10: "I069",
            descricao: "Doença reumática da valva aórtica, não especificada",
            favorito: false
        },
        {
            cid10: "I070",
            descricao: "Estenose tricúspide",
            favorito: false
        },
        {
            cid10: "I071",
            descricao: "Insuficiência tricúspide",
            favorito: false
        },
        {
            cid10: "I072",
            descricao: "Estenose tricúspide com insuficiência",
            favorito: false
        },
        {
            cid10: "I078",
            descricao: "Outras doenças da valva tricúspide",
            favorito: false
        },
        {
            cid10: "I079",
            descricao: "Doença não especificada da valva tricúspide",
            favorito: false
        },
        {
            cid10: "I080",
            descricao: "Transtornos de valvas mitral e aórtica",
            favorito: false
        },
        {
            cid10: "I081",
            descricao: "Transtornos de ambas as valvas mitral e tricúspide",
            favorito: false
        },
        {
            cid10: "I082",
            descricao: "Transtornos de ambas as valvas aórtica e tricúspide",
            favorito: false
        },
        {
            cid10: "I083",
            descricao: "Transtornos das valvas mitral, aórtica e tricúspide",
            favorito: false
        },
        {
            cid10: "I088",
            descricao: "Outras doenças de múltiplas valvas",
            favorito: false
        },
        {
            cid10: "I089",
            descricao: "Doença não especificada de múltiplas valvas",
            favorito: false
        },
        {
            cid10: "I090",
            descricao: "Miocardite reumática",
            favorito: false
        },
        {
            cid10: "I091",
            descricao: "Doenças reumáticas do endocárdio, valva não especificada",
            favorito: false
        },
        {
            cid10: "I092",
            descricao: "Pericardite reumática crônica",
            favorito: false
        },
        {
            cid10: "I098",
            descricao: "Outras doenças reumáticas especificadas do coração",
            favorito: false
        },
        {
            cid10: "I099",
            descricao: "Doença cardíaca reumática não especificada",
            favorito: false
        },
        {
            cid10: "I10",
            descricao: "Hipertensão essencial (primária)",
            favorito: false
        },
        {
            cid10: "I110",
            descricao: "Doença cardíaca hipertensiva com insuficiência cardíaca (congestiva)",
            favorito: false
        },
        {
            cid10: "I119",
            descricao: "Doença cardíaca hipertensiva sem insuficiência cardíaca (congestiva)",
            favorito: false
        },
        {
            cid10: "I120",
            descricao: "Doença renal hipertensiva com insuficiência renal",
            favorito: false
        },
        {
            cid10: "I129",
            descricao: "Doença renal hipertensiva sem insuficiência renal",
            favorito: false
        },
        {
            cid10: "I130",
            descricao: "Doença cardíaca e renal hipertensiva com insuficiência cardíaca (congestiva)",
            favorito: false
        },
        {
            cid10: "I131",
            descricao: "Doença cardíaca e renal hipertensiva com insuficiência renal",
            favorito: false
        },
        {
            cid10: "I132",
            descricao: "Doença cardíaca e renal hipertensiva com insuficiência cardíaca (congestiva) e insuficiência renal",
            favorito: false
        },
        {
            cid10: "I139",
            descricao: "Doença cardíaca e renal hipertensiva, não especificada",
            favorito: false
        },
        {
            cid10: "I150",
            descricao: "Hipertensão renovascular",
            favorito: false
        },
        {
            cid10: "I151",
            descricao: "Hipertensão secundária a outras afecções renais",
            favorito: false
        },
        {
            cid10: "I152",
            descricao: "Hipertensão secundária a afecções endócrinas",
            favorito: false
        },
        {
            cid10: "I158",
            descricao: "Outras formas de hipertensão secundária",
            favorito: false
        },
        {
            cid10: "I159",
            descricao: "Hipertensão secundária, não especificada",
            favorito: false
        },
        {
            cid10: "I200",
            descricao: "Angina instável",
            favorito: false
        },
        {
            cid10: "I201",
            descricao: "Angina pectoris com espasmo documentado",
            favorito: false
        },
        {
            cid10: "I208",
            descricao: "Outras formas de angina pectoris",
            favorito: false
        },
        {
            cid10: "I209",
            descricao: "Angina pectoris, não especificada",
            favorito: false
        },
        {
            cid10: "I210",
            descricao: "Infarto agudo transmural da parede anterior do miocárdio",
            favorito: false
        },
        {
            cid10: "I211",
            descricao: "Infarto agudo transmural da parede inferior do miocárdio",
            favorito: false
        },
        {
            cid10: "I212",
            descricao: "Infarto agudo transmural do miocárdio de outras localizações",
            favorito: false
        },
        {
            cid10: "I213",
            descricao: "Infarto agudo transmural do miocárdio, de localização não especificada",
            favorito: false
        },
        {
            cid10: "I214",
            descricao: "Infarto agudo subendocárdico do miocárdio",
            favorito: false
        },
        {
            cid10: "I219",
            descricao: "Infarto agudo do miocárdio não especificado",
            favorito: false
        },
        {
            cid10: "I220",
            descricao: "Infarto do miocárdio recorrente da parede anterior",
            favorito: false
        },
        {
            cid10: "I221",
            descricao: "Infarto do miocárdio recorrente da parede inferior",
            favorito: false
        },
        {
            cid10: "I228",
            descricao: "Infarto do miocárdio recorrente de outras localizações",
            favorito: false
        },
        {
            cid10: "I229",
            descricao: "Infarto do miocárdio recorrente de localização não especificada",
            favorito: false
        },
        {
            cid10: "I230",
            descricao: "Hemopericárdio como complicação atual subseqüente ao infarto agudo do miocárdio",
            favorito: false
        },
        {
            cid10: "I231",
            descricao: "Comunicação interatrial como complicação atual subseqüente ao infarto agudo do miocárdio",
            favorito: false
        },
        {
            cid10: "I232",
            descricao: "Comunicação interventricular como complicação atual subseqüente ao infarto agudo do miocárdio",
            favorito: false
        },
        {
            cid10: "I233",
            descricao: "Ruptura da parede do coração sem ocorrência de hemopericárdio como complicação atual subseqüente ao infarto agudo do miocárdio",
            favorito: false
        },
        {
            cid10: "I234",
            descricao: "Ruptura de cordoalhas tendíneas como complicação atual subseqüente ao infarto agudo do miocárdio",
            favorito: false
        },
        {
            cid10: "I235",
            descricao: "Ruptura de músculos papilares como complicação atual subseqüente ao infarto agudo do miocárdio",
            favorito: false
        },
        {
            cid10: "I236",
            descricao: "Trombose de átrio, aurícula e ventrículo como complicação atual subseqüente ao infarto agudo do miocárdio",
            favorito: false
        },
        {
            cid10: "I238",
            descricao: "Outras complicações atuais subseqüentes ao infarto agudo do miocárdio",
            favorito: false
        },
        {
            cid10: "I240",
            descricao: "Trombose coronária que não resulta em infarto do miocárdio",
            favorito: false
        },
        {
            cid10: "I241",
            descricao: "Síndrome de Dressler",
            favorito: false
        },
        {
            cid10: "I248",
            descricao: "Outras formas de doença isquêmica aguda do coração",
            favorito: false
        },
        {
            cid10: "I249",
            descricao: "Doença isquêmica aguda do coração não especificada",
            favorito: false
        },
        {
            cid10: "I250",
            descricao: "Doença cardiovascular aterosclerótica, descrita desta maneira",
            favorito: false
        },
        {
            cid10: "I251",
            descricao: "Doença aterosclerótica do coração",
            favorito: false
        },
        {
            cid10: "I252",
            descricao: "Infarto antigo do miocárdio",
            favorito: false
        },
        {
            cid10: "I253",
            descricao: "Aneurisma cardíaco",
            favorito: false
        },
        {
            cid10: "I254",
            descricao: "Aneurisma de artéria coronária",
            favorito: false
        },
        {
            cid10: "I255",
            descricao: "Miocardiopatia isquêmica",
            favorito: false
        },
        {
            cid10: "I256",
            descricao: "Isquemia miocárdica silenciosa",
            favorito: false
        },
        {
            cid10: "I258",
            descricao: "Outras formas de doença isquêmica crônica do coração",
            favorito: false
        },
        {
            cid10: "I259",
            descricao: "Doença isquêmica crônica do coração não especificada",
            favorito: false
        },
        {
            cid10: "I260",
            descricao: "Embolia pulmonar com menção de cor pulmonale agudo",
            favorito: false
        },
        {
            cid10: "I269",
            descricao: "Embolia pulmonar sem menção de cor pulmonale agudo",
            favorito: false
        },
        {
            cid10: "I270",
            descricao: "Hipertensão pulmonar primária",
            favorito: false
        },
        {
            cid10: "I271",
            descricao: "Cardiopatia cifoescoliótica",
            favorito: false
        },
        {
            cid10: "I272",
            descricao: "Outra hipertensão pulmonar secundária",
            favorito: false
        },
        {
            cid10: "I278",
            descricao: "Outras doenças pulmonares do coração especificadas",
            favorito: false
        },
        {
            cid10: "I279",
            descricao: "Cardiopatia pulmonar não especificada",
            favorito: false
        },
        {
            cid10: "I280",
            descricao: "Fístula arteriovenosa de vasos pulmonares",
            favorito: false
        },
        {
            cid10: "I281",
            descricao: "Aneurisma da artéria pulmonar",
            favorito: false
        },
        {
            cid10: "I288",
            descricao: "Outras doenças especificadas dos vasos pulmonares",
            favorito: false
        },
        {
            cid10: "I289",
            descricao: "Doença não especificada dos vasos pulmonares",
            favorito: false
        },
        {
            cid10: "I300",
            descricao: "Pericardite aguda idiopática não específica",
            favorito: false
        },
        {
            cid10: "I301",
            descricao: "Pericardite infecciosa",
            favorito: false
        },
        {
            cid10: "I308",
            descricao: "Outras formas de pericardite aguda",
            favorito: false
        },
        {
            cid10: "I309",
            descricao: "Pericardite aguda não especificada",
            favorito: false
        },
        {
            cid10: "I310",
            descricao: "Pericardite adesiva crônica",
            favorito: false
        },
        {
            cid10: "I311",
            descricao: "Pericardite constritiva crônica",
            favorito: false
        },
        {
            cid10: "I312",
            descricao: "Hemopericárdio não classificado em outra parte",
            favorito: false
        },
        {
            cid10: "I313",
            descricao: "Derrame pericárdico (não-inflamatório)",
            favorito: false
        },
        {
            cid10: "I318",
            descricao: "Outras doenças especificadas do pericárdio",
            favorito: false
        },
        {
            cid10: "I319",
            descricao: "Doença não especificada do pericárdio",
            favorito: false
        },
        {
            cid10: "I320",
            descricao: "Pericardite em doenças bacterianas classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I321",
            descricao: "Pericardite em outras doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I328",
            descricao: "Pericardite em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I330",
            descricao: "Endocardite infecciosa aguda e subaguda",
            favorito: false
        },
        {
            cid10: "I339",
            descricao: "Endocardite aguda não especificada",
            favorito: false
        },
        {
            cid10: "I340",
            descricao: "Insuficiência (da valva) mitral",
            favorito: false
        },
        {
            cid10: "I341",
            descricao: "Prolapso (da valva) mitral",
            favorito: false
        },
        {
            cid10: "I342",
            descricao: "Estenose (da valva) mitral, não-reumática",
            favorito: false
        },
        {
            cid10: "I348",
            descricao: "Outros transtornos não-reumáticos da valva mitral",
            favorito: false
        },
        {
            cid10: "I349",
            descricao: "Transtornos não-reumáticos da valva mitral, não especificados",
            favorito: false
        },
        {
            cid10: "I350",
            descricao: "Estenose (da valva) aórtica",
            favorito: false
        },
        {
            cid10: "I351",
            descricao: "Insuficiência (da valva) aórtica",
            favorito: false
        },
        {
            cid10: "I352",
            descricao: "Estenose (da valva) aórtica com insuficiência",
            favorito: false
        },
        {
            cid10: "I358",
            descricao: "Outros transtornos da valva aórtica",
            favorito: false
        },
        {
            cid10: "I359",
            descricao: "Transtornos não especificados da valva aórtica",
            favorito: false
        },
        {
            cid10: "I360",
            descricao: "Estenose (da valva) tricúspide não-reumática",
            favorito: false
        },
        {
            cid10: "I361",
            descricao: "Insuficiência (da valva) tricúspide não-reumática",
            favorito: false
        },
        {
            cid10: "I362",
            descricao: "Estenose (da valva) tricúspide com insuficiência, não-reumática",
            favorito: false
        },
        {
            cid10: "I368",
            descricao: "Outros transtornos não-reumáticos da valva tricúspide",
            favorito: false
        },
        {
            cid10: "I369",
            descricao: "Transtornos não-reumáticos da valva tricúspide, não especificados",
            favorito: false
        },
        {
            cid10: "I370",
            descricao: "Estenose da valva pulmonar",
            favorito: false
        },
        {
            cid10: "I371",
            descricao: "Insuficiência da valva pulmonar",
            favorito: false
        },
        {
            cid10: "I372",
            descricao: "Estenose da valva pulmonar com insuficiência",
            favorito: false
        },
        {
            cid10: "I378",
            descricao: "Outros transtornos da valva pulmonar",
            favorito: false
        },
        {
            cid10: "I379",
            descricao: "Transtornos não especificados da valva pulmonar",
            favorito: false
        },
        {
            cid10: "I38",
            descricao: "Endocardite de valva não especificada",
            favorito: false
        },
        {
            cid10: "I390",
            descricao: "Transtornos da valva mitral em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I391",
            descricao: "Transtornos da valva aórtica em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I392",
            descricao: "Transtornos da valva tricúspide em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I393",
            descricao: "Transtornos da valva pulmonar em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I394",
            descricao: "Transtornos de múltiplas valvas em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I398",
            descricao: "Endocardite, de valva não especificada, em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I400",
            descricao: "Miocardite infecciosa",
            favorito: false
        },
        {
            cid10: "I401",
            descricao: "Miocardite isolada",
            favorito: false
        },
        {
            cid10: "I408",
            descricao: "Outras miocardites agudas",
            favorito: false
        },
        {
            cid10: "I409",
            descricao: "Miocardite aguda não especificada",
            favorito: false
        },
        {
            cid10: "I410",
            descricao: "Miocardite em doenças bacterianas classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I411",
            descricao: "Miocardite em doenças virais classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I412",
            descricao: "Miocardite em outras doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I418",
            descricao: "Miocardite em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I420",
            descricao: "Cardiomiopatia dilatada",
            favorito: false
        },
        {
            cid10: "I421",
            descricao: "Cardiomiopatia obstrutiva hipertrófica",
            favorito: false
        },
        {
            cid10: "I422",
            descricao: "Outras cardiomiopatias hipertróficas",
            favorito: false
        },
        {
            cid10: "I423",
            descricao: "Doença endomiocárdica (eosinofílica)",
            favorito: false
        },
        {
            cid10: "I424",
            descricao: "Fibroelastose endocárdica",
            favorito: false
        },
        {
            cid10: "I425",
            descricao: "Outras cardiomiopatias restritivas",
            favorito: false
        },
        {
            cid10: "I426",
            descricao: "Cardiomiopatia alcoólica",
            favorito: false
        },
        {
            cid10: "I427",
            descricao: "Cardiomiopatia devida a drogas e outros agentes externos",
            favorito: false
        },
        {
            cid10: "I428",
            descricao: "Outras cardiomiopatias",
            favorito: false
        },
        {
            cid10: "I429",
            descricao: "Cardiomiopatia não especificada",
            favorito: false
        },
        {
            cid10: "I430",
            descricao: "Cardiomiopatia em doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I431",
            descricao: "Cardiomiopatia em doenças metabólicas",
            favorito: false
        },
        {
            cid10: "I432",
            descricao: "Cardiomiopatia em doenças nutricionais",
            favorito: false
        },
        {
            cid10: "I438",
            descricao: "Cardiomiopatia em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I440",
            descricao: "Bloqueio atrioventricular de primeiro grau",
            favorito: false
        },
        {
            cid10: "I441",
            descricao: "Bloqueio atrioventricular de segundo grau",
            favorito: false
        },
        {
            cid10: "I442",
            descricao: "Bloqueio atrioventricular total",
            favorito: false
        },
        {
            cid10: "I443",
            descricao: "Outras formas de bloqueio atrioventricular e as não especificadas",
            favorito: false
        },
        {
            cid10: "I444",
            descricao: "Bloqueio do fascículo anterior esquerdo",
            favorito: false
        },
        {
            cid10: "I445",
            descricao: "Bloqueio do fascículo posterior esquerdo",
            favorito: false
        },
        {
            cid10: "I446",
            descricao: "Outras formas de bloqueio fascicular e as não especificadas",
            favorito: false
        },
        {
            cid10: "I447",
            descricao: "Bloqueio de ramo esquerdo não especificado",
            favorito: false
        },
        {
            cid10: "I450",
            descricao: "Bloqueio fascicular direito",
            favorito: false
        },
        {
            cid10: "I451",
            descricao: "Outras formas de bloqueio de ramo direito e as não especificadas",
            favorito: false
        },
        {
            cid10: "I452",
            descricao: "Bloqueio bifascicular",
            favorito: false
        },
        {
            cid10: "I453",
            descricao: "Bloqueio trifascicular",
            favorito: false
        },
        {
            cid10: "I454",
            descricao: "Bloqueio intraventricular não específico",
            favorito: false
        },
        {
            cid10: "I455",
            descricao: "Outras formas especificadas de bloqueio cardíaco",
            favorito: false
        },
        {
            cid10: "I456",
            descricao: "Síndrome de pré-excitação",
            favorito: false
        },
        {
            cid10: "I458",
            descricao: "Outros transtornos especificados da condução",
            favorito: false
        },
        {
            cid10: "I459",
            descricao: "Transtorno de condução não especificado",
            favorito: false
        },
        {
            cid10: "I460",
            descricao: "Parada cardíaca com ressuscitação bem sucedida",
            favorito: false
        },
        {
            cid10: "I461",
            descricao: "Morte súbita (de origem) cardíaca, descrita desta forma",
            favorito: false
        },
        {
            cid10: "I469",
            descricao: "Parada cardíaca não especificada",
            favorito: false
        },
        {
            cid10: "I470",
            descricao: "Arritmia ventricular por reentrada",
            favorito: false
        },
        {
            cid10: "I471",
            descricao: "Taquicardia supraventricular",
            favorito: false
        },
        {
            cid10: "I472",
            descricao: "Taquicardia ventricular",
            favorito: false
        },
        {
            cid10: "I479",
            descricao: "Taquicardia paroxística não especificada",
            favorito: false
        },
        {
            cid10: "I48",
            descricao: "Flutter e fibrilação atrial",
            favorito: false
        },
        {
            cid10: "I490",
            descricao: "Flutter e fibrilação ventricular",
            favorito: false
        },
        {
            cid10: "I491",
            descricao: "Despolarização atrial prematura",
            favorito: false
        },
        {
            cid10: "I492",
            descricao: "Despolarização juncional prematura",
            favorito: false
        },
        {
            cid10: "I493",
            descricao: "Despolarização ventricular prematura",
            favorito: false
        },
        {
            cid10: "I494",
            descricao: "Outras formas de despolarização prematura e as não especificadas",
            favorito: false
        },
        {
            cid10: "I495",
            descricao: "Síndrome do nó sinusal",
            favorito: false
        },
        {
            cid10: "I498",
            descricao: "Outras arritmias cardíacas especificadas",
            favorito: false
        },
        {
            cid10: "I499",
            descricao: "Arritmia cardíaca não especificada",
            favorito: false
        },
        {
            cid10: "I500",
            descricao: "Insuficiência cardíaca congestiva",
            favorito: false
        },
        {
            cid10: "I501",
            descricao: "Insuficiência ventricular esquerda",
            favorito: false
        },
        {
            cid10: "I509",
            descricao: "Insuficiência cardíaca não especificada",
            favorito: false
        },
        {
            cid10: "I510",
            descricao: "Defeito adquirido de septo cardíaco",
            favorito: false
        },
        {
            cid10: "I511",
            descricao: "Ruptura de cordoalha tendínea, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "I512",
            descricao: "Ruptura de músculo papilar, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "I513",
            descricao: "Trombose intracardíaca, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "I514",
            descricao: "Miocardite não especificada",
            favorito: false
        },
        {
            cid10: "I515",
            descricao: "Degeneração miocárdica",
            favorito: false
        },
        {
            cid10: "I516",
            descricao: "Doença cardiovascular não especificada",
            favorito: false
        },
        {
            cid10: "I517",
            descricao: "Cardiomegalia",
            favorito: false
        },
        {
            cid10: "I518",
            descricao: "Outras doenças mal definidas do coração",
            favorito: false
        },
        {
            cid10: "I519",
            descricao: "Doença não especificada do coração",
            favorito: false
        },
        {
            cid10: "I520",
            descricao: "Outras afecções cardíacas em doenças bacterianas classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I521",
            descricao: "Outras afecções cardíacas em outras doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I528",
            descricao: "Outras afecções cardíacas em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I600",
            descricao: "Hemorragia subaracnóide proveniente do sifão e da bifurcação da carótida",
            favorito: false
        },
        {
            cid10: "I601",
            descricao: "Hemorragia subaracnóide proveniente da artéria cerebral média",
            favorito: false
        },
        {
            cid10: "I602",
            descricao: "Hemorragia subaracnóide proveniente da artéria comunicante anterior",
            favorito: false
        },
        {
            cid10: "I603",
            descricao: "Hemorragia subaracnóide proveniente da artéria comunicante posterior",
            favorito: false
        },
        {
            cid10: "I604",
            descricao: "Hemorragia subaracnóide proveniente da artéria basilar",
            favorito: false
        },
        {
            cid10: "I605",
            descricao: "Hemorragia subaracnóide proveniente da artéria vertebral",
            favorito: false
        },
        {
            cid10: "I606",
            descricao: "Hemorragia subaracnóide proveniente de outras artérias intracranianas",
            favorito: false
        },
        {
            cid10: "I607",
            descricao: "Hemorragia subaracnóide proveniente de artéria intracraniana não especificada",
            favorito: false
        },
        {
            cid10: "I608",
            descricao: "Outras hemorragias subaracnóides",
            favorito: false
        },
        {
            cid10: "I609",
            descricao: "Hemorragia subaracnóide não especificada",
            favorito: false
        },
        {
            cid10: "I610",
            descricao: "Hemorragia intracerebral hemisférica subcortical",
            favorito: false
        },
        {
            cid10: "I611",
            descricao: "Hemorragia intracerebral hemisférica cortical",
            favorito: false
        },
        {
            cid10: "I612",
            descricao: "Hemorragia intracerebral hemisférica não especificada",
            favorito: false
        },
        {
            cid10: "I613",
            descricao: "Hemorragia intracerebral do tronco cerebral",
            favorito: false
        },
        {
            cid10: "I614",
            descricao: "Hemorragia intracerebral cerebelar",
            favorito: false
        },
        {
            cid10: "I615",
            descricao: "Hemorragia intracerebral intraventricular",
            favorito: false
        },
        {
            cid10: "I616",
            descricao: "Hemorragia intracerebral de múltiplas localizações",
            favorito: false
        },
        {
            cid10: "I618",
            descricao: "Outras hemorragias intracerebrais",
            favorito: false
        },
        {
            cid10: "I619",
            descricao: "Hemorragia intracerebral não especificada",
            favorito: false
        },
        {
            cid10: "I620",
            descricao: "Hemorragia subdural (aguda) (não-traumática)",
            favorito: false
        },
        {
            cid10: "I621",
            descricao: "Hemorragia extradural não-traumática",
            favorito: false
        },
        {
            cid10: "I629",
            descricao: "Hemorragia intracraniana (não-traumática) não especificada",
            favorito: false
        },
        {
            cid10: "I630",
            descricao: "Infarto cerebral devido a trombose de artérias pré-cerebrais",
            favorito: false
        },
        {
            cid10: "I631",
            descricao: "Infarto cerebral devido a embolia de artérias pré-cerebrais",
            favorito: false
        },
        {
            cid10: "I632",
            descricao: "Infarto cerebral devido a oclusão ou estenose não especificadas de artérias pré-cerebrais",
            favorito: false
        },
        {
            cid10: "I633",
            descricao: "Infarto cerebral devido a trombose de artérias cerebrais",
            favorito: false
        },
        {
            cid10: "I634",
            descricao: "Infarto cerebral devido a embolia de artérias cerebrais",
            favorito: false
        },
        {
            cid10: "I635",
            descricao: "Infarto cerebral devido a oclusão ou estenose não especificadas de artérias cerebrais",
            favorito: false
        },
        {
            cid10: "I636",
            descricao: "Infarto cerebral devido a trombose venosa cerebral não-piogênica",
            favorito: false
        },
        {
            cid10: "I638",
            descricao: "Outros infartos cerebrais",
            favorito: false
        },
        {
            cid10: "I639",
            descricao: "Infarto cerebral não especificado",
            favorito: false
        },
        {
            cid10: "I64",
            descricao: "Acidente vascular cerebral, não especificado como hemorrágico ou isquêmico",
            favorito: false
        },
        {
            cid10: "I650",
            descricao: "Oclusão e estenose da artéria vertebral",
            favorito: false
        },
        {
            cid10: "I651",
            descricao: "Oclusão e estenose da artéria basilar",
            favorito: false
        },
        {
            cid10: "I652",
            descricao: "Oclusão e estenose da artéria carótida",
            favorito: false
        },
        {
            cid10: "I653",
            descricao: "Oclusão e estenose de artérias pré-cerebrais múltiplas e bilaterais",
            favorito: false
        },
        {
            cid10: "I658",
            descricao: "Oclusão e estenose de outra artéria pré-cerebral",
            favorito: false
        },
        {
            cid10: "I659",
            descricao: "Oclusão e estenose de artérias pré-cerebrais não especificadas",
            favorito: false
        },
        {
            cid10: "I660",
            descricao: "Oclusão e estenose da artéria cerebral média",
            favorito: false
        },
        {
            cid10: "I661",
            descricao: "Oclusão e estenose da artéria cerebral anterior",
            favorito: false
        },
        {
            cid10: "I662",
            descricao: "Oclusão e estenose da artéria cerebral posterior",
            favorito: false
        },
        {
            cid10: "I663",
            descricao: "Oclusão e estenose de artérias cerebelares",
            favorito: false
        },
        {
            cid10: "I664",
            descricao: "Oclusão e estenose de artérias cerebrais, múltiplas e bilaterais",
            favorito: false
        },
        {
            cid10: "I668",
            descricao: "Oclusão e estenose de outra artéria cerebral",
            favorito: false
        },
        {
            cid10: "I669",
            descricao: "Oclusão e estenose de artéria cerebral não especificada",
            favorito: false
        },
        {
            cid10: "I670",
            descricao: "Dissecção de artérias cerebrais, sem ruptura",
            favorito: false
        },
        {
            cid10: "I671",
            descricao: "Aneurisma cerebral não-roto",
            favorito: false
        },
        {
            cid10: "I672",
            descricao: "Aterosclerose cerebral",
            favorito: false
        },
        {
            cid10: "I673",
            descricao: "Leucoencefalopatia vascular progressiva",
            favorito: false
        },
        {
            cid10: "I674",
            descricao: "Encefalopatia hipertensiva",
            favorito: false
        },
        {
            cid10: "I675",
            descricao: "Doença de Moyamoya",
            favorito: false
        },
        {
            cid10: "I676",
            descricao: "Trombose não-piogênica do sistema venoso intracraniano",
            favorito: false
        },
        {
            cid10: "I677",
            descricao: "Arterite cerebral não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "I678",
            descricao: "Outras doenças cerebrovasculares especificadas",
            favorito: false
        },
        {
            cid10: "I679",
            descricao: "Doença cerebrovascular não especificada",
            favorito: false
        },
        {
            cid10: "I680",
            descricao: "Angiopatia cerebral amiloidótica",
            favorito: false
        },
        {
            cid10: "I681",
            descricao: "Arterite cerebral em doenças infecciosas e parasitárias",
            favorito: false
        },
        {
            cid10: "I682",
            descricao: "Arterite cerebral em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I688",
            descricao: "Outros transtornos cerebrovasculares em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I690",
            descricao: "Seqüelas de hemorragia subaracnoídea",
            favorito: false
        },
        {
            cid10: "I691",
            descricao: "Seqüelas de hemorragia intracerebral",
            favorito: false
        },
        {
            cid10: "I692",
            descricao: "Seqüelas de outras hemorragias intracranianas não traumáticas",
            favorito: false
        },
        {
            cid10: "I693",
            descricao: "Seqüelas de infarto cerebral",
            favorito: false
        },
        {
            cid10: "I694",
            descricao: "Seqüelas de acidente vascular cerebral não especificado como hemorrágico ou isquêmico",
            favorito: false
        },
        {
            cid10: "I698",
            descricao: "Seqüelas de outras doenças cerebrovasculares e das não especificadas",
            favorito: false
        },
        {
            cid10: "I700",
            descricao: "Aterosclerose da aorta",
            favorito: false
        },
        {
            cid10: "I701",
            descricao: "Aterosclerose da artéria renal",
            favorito: false
        },
        {
            cid10: "I702",
            descricao: "Aterosclerose das artérias das extremidades",
            favorito: false
        },
        {
            cid10: "I708",
            descricao: "Aterosclerose de outras artérias",
            favorito: false
        },
        {
            cid10: "I709",
            descricao: "Aterosclerose generalizada e a não especificada",
            favorito: false
        },
        {
            cid10: "I710",
            descricao: "Aneurisma dissecante da aorta [qualquer porção]",
            favorito: false
        },
        {
            cid10: "I711",
            descricao: "Aneurisma da aorta torácica, roto",
            favorito: false
        },
        {
            cid10: "I712",
            descricao: "Aneurisma da aorta torácica, sem menção de ruptura",
            favorito: false
        },
        {
            cid10: "I713",
            descricao: "Aneurisma da aorta abdominal, roto",
            favorito: false
        },
        {
            cid10: "I714",
            descricao: "Aneurisma da aorta abdominal, sem menção de ruptura",
            favorito: false
        },
        {
            cid10: "I715",
            descricao: "Aneurisma da aorta tóraco-abdominal, roto",
            favorito: false
        },
        {
            cid10: "I716",
            descricao: "Aneurisma da aorta toráco-abdominal, sem menção de ruptura",
            favorito: false
        },
        {
            cid10: "I718",
            descricao: "Aneurisma da aorta de localização não especificada, roto",
            favorito: false
        },
        {
            cid10: "I719",
            descricao: "Aneurisma aórtico de localização não especificada, sem menção de ruptura",
            favorito: false
        },
        {
            cid10: "I720",
            descricao: "Aneurisma da artéria carótida",
            favorito: false
        },
        {
            cid10: "I721",
            descricao: "Aneurisma de artéria dos membros superiores",
            favorito: false
        },
        {
            cid10: "I722",
            descricao: "Aneurisma da artéria renal",
            favorito: false
        },
        {
            cid10: "I723",
            descricao: "Aneurisma de artéria ilíaca",
            favorito: false
        },
        {
            cid10: "I724",
            descricao: "Aneurisma de artéria dos membros inferiores",
            favorito: false
        },
        {
            cid10: "I728",
            descricao: "Aneurisma de outras artérias especificadas",
            favorito: false
        },
        {
            cid10: "I729",
            descricao: "Aneurisma de localização não especificada",
            favorito: false
        },
        {
            cid10: "I730",
            descricao: "Síndrome de Raynaud",
            favorito: false
        },
        {
            cid10: "I731",
            descricao: "Tromboangeíte obliterante [doença de Buerger]",
            favorito: false
        },
        {
            cid10: "I738",
            descricao: "Outras doenças vasculares periféricas especificadas",
            favorito: false
        },
        {
            cid10: "I739",
            descricao: "Doenças vasculares periféricas não especificada",
            favorito: false
        },
        {
            cid10: "I740",
            descricao: "Embolia e trombose da aorta abdominal",
            favorito: false
        },
        {
            cid10: "I741",
            descricao: "Embolia e trombose de outras porções da aorta e das não especificadas",
            favorito: false
        },
        {
            cid10: "I742",
            descricao: "Embolia e trombose de artérias dos membros superiores",
            favorito: false
        },
        {
            cid10: "I743",
            descricao: "Embolia e trombose de artérias dos membros inferiores",
            favorito: false
        },
        {
            cid10: "I744",
            descricao: "Embolia e trombose de artérias dos membros não especificadas",
            favorito: false
        },
        {
            cid10: "I745",
            descricao: "Embolia e trombose da artéria ilíaca",
            favorito: false
        },
        {
            cid10: "I748",
            descricao: "Embolia e trombose de outras artérias",
            favorito: false
        },
        {
            cid10: "I749",
            descricao: "Embolia e trombose de artéria não especificada",
            favorito: false
        },
        {
            cid10: "I770",
            descricao: "Fístula arteriovenosa adquirida",
            favorito: false
        },
        {
            cid10: "I771",
            descricao: "Estenose de artéria",
            favorito: false
        },
        {
            cid10: "I772",
            descricao: "Ruptura de artéria",
            favorito: false
        },
        {
            cid10: "I773",
            descricao: "Displasia fibromuscular arterial",
            favorito: false
        },
        {
            cid10: "I774",
            descricao: "Síndrome de compressão da artéria celíaca",
            favorito: false
        },
        {
            cid10: "I775",
            descricao: "Necrose de artéria",
            favorito: false
        },
        {
            cid10: "I776",
            descricao: "Arterite não especificada",
            favorito: false
        },
        {
            cid10: "I778",
            descricao: "Outras afecções especificadas das artérias e das arteríolas",
            favorito: false
        },
        {
            cid10: "I779",
            descricao: "Afecções de artérias e arteríolas, não especificadas",
            favorito: false
        },
        {
            cid10: "I780",
            descricao: "Telangiectasia hemorrágica hereditária",
            favorito: false
        },
        {
            cid10: "I781",
            descricao: "Nevo não-neoplásico",
            favorito: false
        },
        {
            cid10: "I788",
            descricao: "Outras doenças dos capilares",
            favorito: false
        },
        {
            cid10: "I789",
            descricao: "Doenças não especificadas dos capilares",
            favorito: false
        },
        {
            cid10: "I790",
            descricao: "Aneurisma da aorta em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I791",
            descricao: "Aortite em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I792",
            descricao: "Angiopatia periférica em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I798",
            descricao: "Outros transtornos das artérias, das arteríolas e dos capilares em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I800",
            descricao: "Flebite e tromboflebite dos vasos superficiais dos membros inferiores",
            favorito: false
        },
        {
            cid10: "I801",
            descricao: "Flebite e tromboflebite da veia femural",
            favorito: false
        },
        {
            cid10: "I802",
            descricao: "Flebite e tromboflebite de outros vasos profundos dos membros inferiores",
            favorito: false
        },
        {
            cid10: "I803",
            descricao: "Flebite e tromboflebite dos membros inferiores, não especificada",
            favorito: false
        },
        {
            cid10: "I808",
            descricao: "Flebite e tromboflebite de outras localizações",
            favorito: false
        },
        {
            cid10: "I809",
            descricao: "Flebite e tromboflebite de localização não especificada",
            favorito: false
        },
        {
            cid10: "I81",
            descricao: "Trombose da veia porta",
            favorito: false
        },
        {
            cid10: "I820",
            descricao: "Síndrome de Budd-Chiari",
            favorito: false
        },
        {
            cid10: "I821",
            descricao: "Tromboflebite migratória",
            favorito: false
        },
        {
            cid10: "I822",
            descricao: "Embolia e trombose de veia cava",
            favorito: false
        },
        {
            cid10: "I823",
            descricao: "Embolia e trombose de veia renal",
            favorito: false
        },
        {
            cid10: "I828",
            descricao: "Embolia e trombose de outras veias especificadas",
            favorito: false
        },
        {
            cid10: "I829",
            descricao: "Embolia e trombose venosas de veia não especificada",
            favorito: false
        },
        {
            cid10: "I830",
            descricao: "Varizes dos membros inferiores com úlcera",
            favorito: false
        },
        {
            cid10: "I831",
            descricao: "Varizes dos membros inferiores com inflamação",
            favorito: false
        },
        {
            cid10: "I832",
            descricao: "Varizes dos membros inferiores com úlcera e inflamação",
            favorito: false
        },
        {
            cid10: "I839",
            descricao: "Varizes dos membros inferiores sem úlcera ou inflamação",
            favorito: false
        },
        {
            cid10: "I840",
            descricao: "Hemorróidas internas trombosadas",
            favorito: false
        },
        {
            cid10: "I841",
            descricao: "Hemorróidas internas com outras complicações",
            favorito: false
        },
        {
            cid10: "I842",
            descricao: "Hemorróidas internas sem complicações",
            favorito: false
        },
        {
            cid10: "I843",
            descricao: "Hemorróidas externas trombosadas",
            favorito: false
        },
        {
            cid10: "I844",
            descricao: "Hemorróidas externas com outras complicações",
            favorito: false
        },
        {
            cid10: "I845",
            descricao: "Hemorróidas externas sem complicação",
            favorito: false
        },
        {
            cid10: "I846",
            descricao: "Plicomas hemorroidários residuais",
            favorito: false
        },
        {
            cid10: "I847",
            descricao: "Hemorróidas trombosadas, não especificadas",
            favorito: false
        },
        {
            cid10: "I848",
            descricao: "Hemorróidas não especificadas com outras complicações",
            favorito: false
        },
        {
            cid10: "I849",
            descricao: "Hemorróidas sem complicações, não especificadas",
            favorito: false
        },
        {
            cid10: "I850",
            descricao: "Varizes esofagianas sangrantes",
            favorito: false
        },
        {
            cid10: "I859",
            descricao: "Varizes esofagianas sem sangramento",
            favorito: false
        },
        {
            cid10: "I860",
            descricao: "Varizes sublinguais",
            favorito: false
        },
        {
            cid10: "I861",
            descricao: "Varizes escrotais",
            favorito: false
        },
        {
            cid10: "I862",
            descricao: "Varizes pélvicas",
            favorito: false
        },
        {
            cid10: "I863",
            descricao: "Varizes da vulva",
            favorito: false
        },
        {
            cid10: "I864",
            descricao: "Varizes gástricas",
            favorito: false
        },
        {
            cid10: "I868",
            descricao: "Varizes de outras localizações especificadas",
            favorito: false
        },
        {
            cid10: "I870",
            descricao: "Síndrome pós-flebite",
            favorito: false
        },
        {
            cid10: "I871",
            descricao: "Compressão venosa",
            favorito: false
        },
        {
            cid10: "I872",
            descricao: "Insuficiência venosa (crônica) (periférica)",
            favorito: false
        },
        {
            cid10: "I878",
            descricao: "Outros transtornos venosos especificados",
            favorito: false
        },
        {
            cid10: "I879",
            descricao: "Transtorno venoso não especificado",
            favorito: false
        },
        {
            cid10: "I880",
            descricao: "Linfadenite mesentérica não específica",
            favorito: false
        },
        {
            cid10: "I881",
            descricao: "Linfadenite crônica, exceto a mesentérica",
            favorito: false
        },
        {
            cid10: "I888",
            descricao: "Outras linfadenites inespecíficas",
            favorito: false
        },
        {
            cid10: "I889",
            descricao: "Linfadenite não especificada",
            favorito: false
        },
        {
            cid10: "I890",
            descricao: "Linfedema não classificado em outra parte",
            favorito: false
        },
        {
            cid10: "I891",
            descricao: "Linfangite",
            favorito: false
        },
        {
            cid10: "I898",
            descricao: "Outros transtornos não-infecciosos, especificados, dos vasos linfáticos e dos gânglios linfáticos",
            favorito: false
        },
        {
            cid10: "I899",
            descricao: "Transtornos não-infecciosos dos vasos linfáticos e dos gânglios linfáticos, não especificados",
            favorito: false
        },
        {
            cid10: "I950",
            descricao: "Hipotensão idiopática",
            favorito: false
        },
        {
            cid10: "I951",
            descricao: "Hipotensão ortostática",
            favorito: false
        },
        {
            cid10: "I952",
            descricao: "Hipotensão devida a drogas",
            favorito: false
        },
        {
            cid10: "I958",
            descricao: "Outros tipos de hipotensão",
            favorito: false
        },
        {
            cid10: "I959",
            descricao: "Hipotensão não especificada",
            favorito: false
        },
        {
            cid10: "I970",
            descricao: "Síndrome pós-cardiotomia",
            favorito: false
        },
        {
            cid10: "I971",
            descricao: "Outros distúrbios funcionais subseqüentes à cirurgia cardíaca",
            favorito: false
        },
        {
            cid10: "I972",
            descricao: "Síndrome do linfedema pós-mastectomia",
            favorito: false
        },
        {
            cid10: "I978",
            descricao: "Outros transtornos do aparelho circulatório subseqüentes a procedimentos não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "I979",
            descricao: "Transtornos do aparelho circulatório, subseqüentes a procedimento não especificado",
            favorito: false
        },
        {
            cid10: "I980",
            descricao: "Sífilis cardiovascular",
            favorito: false
        },
        {
            cid10: "I981",
            descricao: "Transtornos cardiovasculares em outras doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I982",
            descricao: "Varizes esofagianas em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I988",
            descricao: "Outros transtornos especificados do aparelho circulatório em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "I99",
            descricao: "Outros transtornos do aparelho circulatório e os não especificados",
            favorito: false
        },
        {
            cid10: "J00",
            descricao: "Nasofaringite aguda [resfriado comum]",
            favorito: false
        },
        {
            cid10: "J010",
            descricao: "Sinusite maxilar aguda",
            favorito: false
        },
        {
            cid10: "J011",
            descricao: "Sinusite frontal aguda",
            favorito: false
        },
        {
            cid10: "J012",
            descricao: "Sinusite etmoidal aguda",
            favorito: false
        },
        {
            cid10: "J013",
            descricao: "Sinusite esfenoidal aguda",
            favorito: false
        },
        {
            cid10: "J014",
            descricao: "Pansinusite aguda",
            favorito: false
        },
        {
            cid10: "J018",
            descricao: "Outras sinusites agudas",
            favorito: false
        },
        {
            cid10: "J019",
            descricao: "Sinusite aguda não especificada",
            favorito: false
        },
        {
            cid10: "J020",
            descricao: "Faringite estreptocócica",
            favorito: false
        },
        {
            cid10: "J028",
            descricao: "Faringite aguda devida a outros microorganismos especificados",
            favorito: false
        },
        {
            cid10: "J029",
            descricao: "Faringite aguda não especificada",
            favorito: false
        },
        {
            cid10: "J030",
            descricao: "Amigdalite estreptocócica",
            favorito: false
        },
        {
            cid10: "J038",
            descricao: "Amigdalite aguda devida a outros microorganismos especificados",
            favorito: false
        },
        {
            cid10: "J039",
            descricao: "Amigdalite aguda não especificada",
            favorito: false
        },
        {
            cid10: "J040",
            descricao: "Laringite aguda",
            favorito: false
        },
        {
            cid10: "J041",
            descricao: "Traqueíte aguda",
            favorito: false
        },
        {
            cid10: "J042",
            descricao: "Laringotraqueíte aguda",
            favorito: false
        },
        {
            cid10: "J050",
            descricao: "Laringite obstrutiva aguda [crupe]",
            favorito: false
        },
        {
            cid10: "J051",
            descricao: "Epiglotite aguda",
            favorito: false
        },
        {
            cid10: "J060",
            descricao: "Laringofaringite aguda",
            favorito: false
        },
        {
            cid10: "J068",
            descricao: "Outras infecções agudas das vias aéreas superiores de localizações múltiplas",
            favorito: false
        },
        {
            cid10: "J069",
            descricao: "Infecção aguda das vias aéreas superiores não especificada",
            favorito: false
        },
        {
            cid10: "J09",
            descricao: "Influenza [gripe] devida a vírus identificado da gripe aviária",
            favorito: false
        },
        {
            cid10: "J100",
            descricao: "Influenza com pneumonia devida a outro vírus da influenza [gripe] identificado",
            favorito: false
        },
        {
            cid10: "J101",
            descricao: "Influenza com outras manifestações respiratórias, devida a outro vírus da influenza [gripe] identificado",
            favorito: false
        },
        {
            cid10: "J108",
            descricao: "Influenza com outras manifestações, devida a outro vírus da influenza [gripe] identificado",
            favorito: false
        },
        {
            cid10: "J110",
            descricao: "Influenza [gripe] com pneumonia, devida a vírus não identificado",
            favorito: false
        },
        {
            cid10: "J111",
            descricao: "Influenza [gripe] com outras manifestações respiratórias, devida a vírus não identificado",
            favorito: false
        },
        {
            cid10: "J118",
            descricao: "Influenza [gripe] com outras manifestações, devida a vírus não identificado",
            favorito: false
        },
        {
            cid10: "J120",
            descricao: "Pneumonia devida a adenovírus",
            favorito: false
        },
        {
            cid10: "J121",
            descricao: "Pneumonia devida a vírus respiratório sincicial",
            favorito: false
        },
        {
            cid10: "J122",
            descricao: "Pneumonia devida à parainfluenza",
            favorito: false
        },
        {
            cid10: "J128",
            descricao: "Outras pneumonias virais",
            favorito: false
        },
        {
            cid10: "J129",
            descricao: "Pneumonia viral não especificada",
            favorito: false
        },
        {
            cid10: "J13",
            descricao: "Pneumonia devida a Streptococcus pneumoniae",
            favorito: false
        },
        {
            cid10: "J14",
            descricao: "Pneumonia devida a Haemophilus infuenzae",
            favorito: false
        },
        {
            cid10: "J150",
            descricao: "Pneumonia devida à Klebsiella pneumoniae",
            favorito: false
        },
        {
            cid10: "J151",
            descricao: "Pneumonia devida a Pseudomonas",
            favorito: false
        },
        {
            cid10: "J152",
            descricao: "Pneumonia devida a Staphylococcus",
            favorito: false
        },
        {
            cid10: "J153",
            descricao: "Pneumonia devida a Streptococcus do grupo B",
            favorito: false
        },
        {
            cid10: "J154",
            descricao: "Pneumonia devida a outros estreptococos",
            favorito: false
        },
        {
            cid10: "J155",
            descricao: "Pneumonia devida a Escherichia coli",
            favorito: false
        },
        {
            cid10: "J156",
            descricao: "Pneumonia devida a outras bactérias aeróbicas gram-negativas",
            favorito: false
        },
        {
            cid10: "J157",
            descricao: "Pneumonia devida a Mycoplasma pneumoniae",
            favorito: false
        },
        {
            cid10: "J158",
            descricao: "Outras pneumonias bacterianas",
            favorito: false
        },
        {
            cid10: "J159",
            descricao: "Pneumonia bacteriana não especificada",
            favorito: false
        },
        {
            cid10: "J160",
            descricao: "Pneumonia devida a clamídias",
            favorito: false
        },
        {
            cid10: "J168",
            descricao: "Pneumonia devida a outros microorganismos infecciosos especificados",
            favorito: false
        },
        {
            cid10: "J170",
            descricao: "Pneumonia em doenças bacterianas classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "J171",
            descricao: "Pneumonia em doenças virais classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "J172",
            descricao: "Pneumonia em micoses classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "J173",
            descricao: "Pneumonia em doenças parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "J178",
            descricao: "Pneumonia em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "J180",
            descricao: "Broncopneumonia não especificada",
            favorito: false
        },
        {
            cid10: "J181",
            descricao: "Pneumonia lobar não especificada",
            favorito: false
        },
        {
            cid10: "J182",
            descricao: "Pneumonia hipostática não especificada",
            favorito: false
        },
        {
            cid10: "J188",
            descricao: "Outras pneumonias devidas a microorganismos não especificados",
            favorito: false
        },
        {
            cid10: "J189",
            descricao: "Pneumonia não especificada",
            favorito: false
        },
        {
            cid10: "J200",
            descricao: "Bronquite aguda devida a Mycoplasma pneumoniae",
            favorito: false
        },
        {
            cid10: "J201",
            descricao: "Bronquite aguda devida a Haemophilus influenzae",
            favorito: false
        },
        {
            cid10: "J202",
            descricao: "Bronquite aguda devida a estreptococos",
            favorito: false
        },
        {
            cid10: "J203",
            descricao: "Bronquite aguda devida a vírus Coxsackie",
            favorito: false
        },
        {
            cid10: "J204",
            descricao: "Bronquite aguda devida a vírus parainfluenza",
            favorito: false
        },
        {
            cid10: "J205",
            descricao: "Bronquite aguda devida a vírus sincicial respiratório",
            favorito: false
        },
        {
            cid10: "J206",
            descricao: "Bronquite aguda devida a rinovírus",
            favorito: false
        },
        {
            cid10: "J207",
            descricao: "Bronquite aguda devida a echovírus",
            favorito: false
        },
        {
            cid10: "J208",
            descricao: "Bronquite aguda devida a outros microorganismos especificados",
            favorito: false
        },
        {
            cid10: "J209",
            descricao: "Bronquite aguda não especificada",
            favorito: false
        },
        {
            cid10: "J210",
            descricao: "Bronquiolite aguda devida a vírus sincicial respiratório",
            favorito: false
        },
        {
            cid10: "J218",
            descricao: "Bronquiolite aguda devida a outros microorganismos especificados",
            favorito: false
        },
        {
            cid10: "J219",
            descricao: "Bronquite aguda não especificada",
            favorito: false
        },
        {
            cid10: "J22",
            descricao: "Infecções agudas não especificada das vias aéreas inferiores",
            favorito: false
        },
        {
            cid10: "J300",
            descricao: "Rinite vasomotora",
            favorito: false
        },
        {
            cid10: "J301",
            descricao: "Rinite alérgica devida a pólen",
            favorito: false
        },
        {
            cid10: "J302",
            descricao: "Outras rinites alérgicas sazonais",
            favorito: false
        },
        {
            cid10: "J303",
            descricao: "Outras rinites alérgicas",
            favorito: false
        },
        {
            cid10: "J304",
            descricao: "Rinite alérgica não especificada",
            favorito: false
        },
        {
            cid10: "J310",
            descricao: "Rinite crônica",
            favorito: false
        },
        {
            cid10: "J311",
            descricao: "Nasofaringite crônica",
            favorito: false
        },
        {
            cid10: "J312",
            descricao: "Faringite crônica",
            favorito: false
        },
        {
            cid10: "J320",
            descricao: "Sinusite maxilar crônica",
            favorito: false
        },
        {
            cid10: "J321",
            descricao: "Sinusite frontal crônica",
            favorito: false
        },
        {
            cid10: "J322",
            descricao: "Sinusite etmoidal crônica",
            favorito: false
        },
        {
            cid10: "J323",
            descricao: "Sinusite esfenoidal crônica",
            favorito: false
        },
        {
            cid10: "J324",
            descricao: "Pansinusite crônica",
            favorito: false
        },
        {
            cid10: "J328",
            descricao: "Outras sinusites crônicas",
            favorito: false
        },
        {
            cid10: "J329",
            descricao: "Sinusite crônica não especificada",
            favorito: false
        },
        {
            cid10: "J330",
            descricao: "Pólipo da cavidade nasal",
            favorito: false
        },
        {
            cid10: "J331",
            descricao: "Degeneração polipóide do seio paranasal",
            favorito: false
        },
        {
            cid10: "J338",
            descricao: "Outros pólipos do seio paranasal",
            favorito: false
        },
        {
            cid10: "J339",
            descricao: "Pólipo nasal não especificado",
            favorito: false
        },
        {
            cid10: "J340",
            descricao: "Abscesso, furúnculo e antraz do nariz",
            favorito: false
        },
        {
            cid10: "J341",
            descricao: "Cisto e mucocele do nariz e do seio paranasal",
            favorito: false
        },
        {
            cid10: "J342",
            descricao: "Desvio do septo nasal",
            favorito: false
        },
        {
            cid10: "J343",
            descricao: "Hipertrofia dos cornetos nasais",
            favorito: false
        },
        {
            cid10: "J348",
            descricao: "Outros transtornos especificados do nariz e dos seios paranasais",
            favorito: false
        },
        {
            cid10: "J350",
            descricao: "Amigdalite crônica",
            favorito: false
        },
        {
            cid10: "J351",
            descricao: "Hipertrofia das amígdalas",
            favorito: false
        },
        {
            cid10: "J352",
            descricao: "Hipertrofia das adenóides",
            favorito: false
        },
        {
            cid10: "J353",
            descricao: "Hipertrofia das amígdalas com hipertrofia das adenóides",
            favorito: false
        },
        {
            cid10: "J358",
            descricao: "Outras doenças crônicas das amígdalas e das adenóides",
            favorito: false
        },
        {
            cid10: "J359",
            descricao: "Doenças das amígdalas e das adenóides não especificadas",
            favorito: false
        },
        {
            cid10: "J36",
            descricao: "Abscesso periamigdaliano",
            favorito: false
        },
        {
            cid10: "J370",
            descricao: "Laringite crônica",
            favorito: false
        },
        {
            cid10: "J371",
            descricao: "Laringotraqueíte crônica",
            favorito: false
        },
        {
            cid10: "J380",
            descricao: "Paralisia das cordas vocais e da laringe",
            favorito: false
        },
        {
            cid10: "J381",
            descricao: "Pólipo das cordas vocais e da laringe",
            favorito: false
        },
        {
            cid10: "J382",
            descricao: "Nódulos das cordas vocais",
            favorito: false
        },
        {
            cid10: "J383",
            descricao: "Outras doenças das cordas vocais",
            favorito: false
        },
        {
            cid10: "J384",
            descricao: "Edema da laringe",
            favorito: false
        },
        {
            cid10: "J385",
            descricao: "Espasmo da laringe",
            favorito: false
        },
        {
            cid10: "J386",
            descricao: "Estenose da laringe",
            favorito: false
        },
        {
            cid10: "J387",
            descricao: "Outras doenças da laringe",
            favorito: false
        },
        {
            cid10: "J390",
            descricao: "Abscesso retrofaríngeo e parafaríngeo",
            favorito: false
        },
        {
            cid10: "J391",
            descricao: "Outros abscessos da faringe",
            favorito: false
        },
        {
            cid10: "J392",
            descricao: "Outras doenças da faringe",
            favorito: false
        },
        {
            cid10: "J393",
            descricao: "Reação de hipersensibilidade das vias aéreas superiores de localização não especificada",
            favorito: false
        },
        {
            cid10: "J398",
            descricao: "Outras doenças especificadas das vias aéreas superiores",
            favorito: false
        },
        {
            cid10: "J399",
            descricao: "Doença não especificada das vias aéreas superiores",
            favorito: false
        },
        {
            cid10: "J40",
            descricao: "Bronquite não especificada como aguda ou crônica",
            favorito: false
        },
        {
            cid10: "J410",
            descricao: "Bronquite crônica simples",
            favorito: false
        },
        {
            cid10: "J411",
            descricao: "Bronquite crônica mucopurulenta",
            favorito: false
        },
        {
            cid10: "J418",
            descricao: "Bronquite crônica mista, simples e mucopurulenta",
            favorito: false
        },
        {
            cid10: "J42",
            descricao: "Bronquite crônica não especificada",
            favorito: false
        },
        {
            cid10: "J430",
            descricao: "Síndrome de MacLeod",
            favorito: false
        },
        {
            cid10: "J431",
            descricao: "Enfisema panlobular",
            favorito: false
        },
        {
            cid10: "J432",
            descricao: "Enfisema centrolobular",
            favorito: false
        },
        {
            cid10: "J438",
            descricao: "Outras formas de enfisema",
            favorito: false
        },
        {
            cid10: "J439",
            descricao: "Enfisema não especificado",
            favorito: false
        },
        {
            cid10: "J440",
            descricao: "Doença pulmonar obstrutiva crônica com infecção respiratória aguda do trato respiratório inferior",
            favorito: false
        },
        {
            cid10: "J441",
            descricao: "Doença pulmonar obstrutiva crônica com exacerbação aguda não especificada",
            favorito: false
        },
        {
            cid10: "J448",
            descricao: "Outras formas especificadas de doença pulmonar obstrutiva crônica",
            favorito: false
        },
        {
            cid10: "J449",
            descricao: "Doença pulmonar obstrutiva crônica não especificada",
            favorito: false
        },
        {
            cid10: "J450",
            descricao: "Asma predominantemente alérgica",
            favorito: false
        },
        {
            cid10: "J451",
            descricao: "Asma não-alérgica",
            favorito: false
        },
        {
            cid10: "J458",
            descricao: "Asma mista",
            favorito: false
        },
        {
            cid10: "J459",
            descricao: "Asma não especificada",
            favorito: false
        },
        {
            cid10: "J46",
            descricao: "Estado de mal asmático",
            favorito: false
        },
        {
            cid10: "J47",
            descricao: "Bronquectasia",
            favorito: false
        },
        {
            cid10: "J60",
            descricao: "Pneumoconiose dos mineiros de carvão",
            favorito: false
        },
        {
            cid10: "J61",
            descricao: "Pneumoconiose devida a amianto [asbesto] e outras fibras minerais",
            favorito: false
        },
        {
            cid10: "J620",
            descricao: "Pneumoconiose devida a pó de talco",
            favorito: false
        },
        {
            cid10: "J628",
            descricao: "Pneumoconiose devida a outras poeiras que contenham sílica",
            favorito: false
        },
        {
            cid10: "J630",
            descricao: "Aluminose (do pulmão)",
            favorito: false
        },
        {
            cid10: "J631",
            descricao: "Fibrose (do pulmão) causada por bauxita",
            favorito: false
        },
        {
            cid10: "J632",
            descricao: "Beriliose",
            favorito: false
        },
        {
            cid10: "J633",
            descricao: "Fibrose (do pulmão) causada por grafite",
            favorito: false
        },
        {
            cid10: "J634",
            descricao: "Siderose",
            favorito: false
        },
        {
            cid10: "J635",
            descricao: "Estanose",
            favorito: false
        },
        {
            cid10: "J638",
            descricao: "Pneumoconiose devida a outras poeira inorgânicas especificados",
            favorito: false
        },
        {
            cid10: "J64",
            descricao: "Pneumoconiose não especificada",
            favorito: false
        },
        {
            cid10: "J65",
            descricao: "Pneumoconiose associada com tuberculose",
            favorito: false
        },
        {
            cid10: "J660",
            descricao: "Bissinose",
            favorito: false
        },
        {
            cid10: "J661",
            descricao: "Doença dos cardadores de linho",
            favorito: false
        },
        {
            cid10: "J662",
            descricao: "Canabinose",
            favorito: false
        },
        {
            cid10: "J668",
            descricao: "Doenças das vias aéreas devida a outras poeiras orgânicas específicas",
            favorito: false
        },
        {
            cid10: "J670",
            descricao: "Pulmão de fazendeiro",
            favorito: false
        },
        {
            cid10: "J671",
            descricao: "Bagaçose",
            favorito: false
        },
        {
            cid10: "J672",
            descricao: "Pulmão dos criadores de pássaros",
            favorito: false
        },
        {
            cid10: "J673",
            descricao: "Suberose",
            favorito: false
        },
        {
            cid10: "J674",
            descricao: "Pulmão dos trabalhadores do malte",
            favorito: false
        },
        {
            cid10: "J675",
            descricao: "Pulmão dos que trabalham com cogumelos",
            favorito: false
        },
        {
            cid10: "J676",
            descricao: "Pulmão dos cortadores de casca do bordo",
            favorito: false
        },
        {
            cid10: "J677",
            descricao: "Doença pulmonar devida aos sistemas de ar condicionado e de umidificação do ar",
            favorito: false
        },
        {
            cid10: "J678",
            descricao: "Pneumonites de hipersensibilidade, devidas a outras poeiras orgânicas",
            favorito: false
        },
        {
            cid10: "J679",
            descricao: "Pneumonite de hipersensibilidade devida a poeira orgânica não especificada",
            favorito: false
        },
        {
            cid10: "J680",
            descricao: "Bronquite e pneumonite devida a produtos químicos, gases, fumaças e vapores",
            favorito: false
        },
        {
            cid10: "J681",
            descricao: "Edema pulmonar devido a produtos químicos, gases, fumaças e vapores",
            favorito: false
        },
        {
            cid10: "J682",
            descricao: "Inflamação das vias aéreas superiores devida a produtos químicos, gases, fumaças e vapores, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "J683",
            descricao: "Outras afecções respiratórias agudas e subagudas devidas a produtos químicos, gases, fumaças e vapores",
            favorito: false
        },
        {
            cid10: "J684",
            descricao: "Afecções respiratórias crônicas devidas a produtos químicos, gases, fumaças e vapores",
            favorito: false
        },
        {
            cid10: "J688",
            descricao: "Outras afecções respiratórias devida a produtos químicos, gases, fumaças e vapores",
            favorito: false
        },
        {
            cid10: "J689",
            descricao: "Afecção respiratória não especificada devida a produtos químicos, gases, fumaça e vapores",
            favorito: false
        },
        {
            cid10: "J690",
            descricao: "Pneumonite devida a alimento ou vômito",
            favorito: false
        },
        {
            cid10: "J691",
            descricao: "Pneumonite devida a óleos e essências",
            favorito: false
        },
        {
            cid10: "J698",
            descricao: "Pneumonite devida a outros sólidos e líquidos",
            favorito: false
        },
        {
            cid10: "J700",
            descricao: "Manifestações pulmonares agudas devidas à radiação",
            favorito: false
        },
        {
            cid10: "J701",
            descricao: "Manifestações pulmonares crônicas e outras, devidas a radiação",
            favorito: false
        },
        {
            cid10: "J702",
            descricao: "Transtornos pulmonares intersticiais agudos, induzidos por droga",
            favorito: false
        },
        {
            cid10: "J703",
            descricao: "Transtornos pulmonares intersticiais crônicos induzidos por droga",
            favorito: false
        },
        {
            cid10: "J704",
            descricao: "Transtornos pulmonar intersticial não especificado induzido por droga",
            favorito: false
        },
        {
            cid10: "J708",
            descricao: "Afecções respiratórias devidas a outros agentes externos especificados",
            favorito: false
        },
        {
            cid10: "J709",
            descricao: "Afecções respiratórias devidas a agentes externos não especificados",
            favorito: false
        },
        {
            cid10: "J80",
            descricao: "Síndrome do desconforto respiratório do adulto",
            favorito: false
        },
        {
            cid10: "J81",
            descricao: "Edema pulmonar, não especificado de outra forma",
            favorito: false
        },
        {
            cid10: "J82",
            descricao: "Eosinofilia pulmonar, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "J840",
            descricao: "Afecções alveolares e parieto-alveolares",
            favorito: false
        },
        {
            cid10: "J841",
            descricao: "Outras doenças pulmonares intersticiais com fibrose",
            favorito: false
        },
        {
            cid10: "J848",
            descricao: "Outras doenças pulmonares intersticiais especificadas",
            favorito: false
        },
        {
            cid10: "J849",
            descricao: "Doença pulmonar intersticial não especificadas",
            favorito: false
        },
        {
            cid10: "J850",
            descricao: "Gangrena e necrose do pulmão",
            favorito: false
        },
        {
            cid10: "J851",
            descricao: "Abscesso do pulmão com pneumonia",
            favorito: false
        },
        {
            cid10: "J852",
            descricao: "Abscesso do pulmão sem pneumonia",
            favorito: false
        },
        {
            cid10: "J853",
            descricao: "Abscesso do mediastino",
            favorito: false
        },
        {
            cid10: "J860",
            descricao: "Piotórax com fístula",
            favorito: false
        },
        {
            cid10: "J869",
            descricao: "Piotórax sem fístula",
            favorito: false
        },
        {
            cid10: "J90",
            descricao: "Derrame pleural não classificado em outra parte",
            favorito: false
        },
        {
            cid10: "J91",
            descricao: "Derrame pleural em afecções classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "J920",
            descricao: "Placas pleurais com presença de amianto [asbesto]",
            favorito: false
        },
        {
            cid10: "J929",
            descricao: "Placas pleurais sem presença de amianto [asbesto]",
            favorito: false
        },
        {
            cid10: "J930",
            descricao: "Pneumotórax de tensão, espontâneo",
            favorito: false
        },
        {
            cid10: "J931",
            descricao: "Outras formas de pneumotórax espontâneo",
            favorito: false
        },
        {
            cid10: "J938",
            descricao: "Outros tipos de pneumotórax, especificados",
            favorito: false
        },
        {
            cid10: "J939",
            descricao: "Pneumotórax não especificado",
            favorito: false
        },
        {
            cid10: "J940",
            descricao: "Derrame quiloso ou quiliforme",
            favorito: false
        },
        {
            cid10: "J941",
            descricao: "Fibrotórax",
            favorito: false
        },
        {
            cid10: "J942",
            descricao: "Hemotórax",
            favorito: false
        },
        {
            cid10: "J948",
            descricao: "Outras afecções pleurais especificadas",
            favorito: false
        },
        {
            cid10: "J949",
            descricao: "Afecção pleural não especificada",
            favorito: false
        },
        {
            cid10: "J950",
            descricao: "Mau funcionamento de traqueostomia",
            favorito: false
        },
        {
            cid10: "J951",
            descricao: "Insuficiência pulmonar aguda subsequente a cirurgia torácica",
            favorito: false
        },
        {
            cid10: "J952",
            descricao: "Insuficiência pulmonar aguda subsequente a cirurgia não torácica",
            favorito: false
        },
        {
            cid10: "J953",
            descricao: "Insuficiência pulmonar crônica pós-cirúrgica",
            favorito: false
        },
        {
            cid10: "J954",
            descricao: "Síndrome de Mendelson",
            favorito: false
        },
        {
            cid10: "J955",
            descricao: "Estenose subglótica pós-procedimento",
            favorito: false
        },
        {
            cid10: "J958",
            descricao: "Outros transtornos respiratórios pós-procedimentos",
            favorito: false
        },
        {
            cid10: "J959",
            descricao: "Transtornos respiratórios pós-procedimentos não especificados",
            favorito: false
        },
        {
            cid10: "J960",
            descricao: "Insuficiência respiratória aguda",
            favorito: false
        },
        {
            cid10: "J961",
            descricao: "Insuficiência respiratória crônica",
            favorito: false
        },
        {
            cid10: "J969",
            descricao: "Insuficiência respiratória não especificada",
            favorito: false
        },
        {
            cid10: "J980",
            descricao: "Outras doenças dos brônquios não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "J981",
            descricao: "Colapso pulmonar",
            favorito: false
        },
        {
            cid10: "J982",
            descricao: "Enfisema intersticial",
            favorito: false
        },
        {
            cid10: "J983",
            descricao: "Enfisema compensatório",
            favorito: false
        },
        {
            cid10: "J984",
            descricao: "Outros transtornos pulmonares",
            favorito: false
        },
        {
            cid10: "J985",
            descricao: "Doenças do mediastino não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "J986",
            descricao: "Transtornos do diafragma",
            favorito: false
        },
        {
            cid10: "J988",
            descricao: "Outros transtornos respiratórios especificados",
            favorito: false
        },
        {
            cid10: "J989",
            descricao: "Transtorno respiratório não especificados",
            favorito: false
        },
        {
            cid10: "J990",
            descricao: "Doença pulmonar reumatóide",
            favorito: false
        },
        {
            cid10: "J991",
            descricao: "Transtornos respiratórios em outras doenças sistêmicas do tecido conjuntivo classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "J998",
            descricao: "Transtornos respiratórios em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "K000",
            descricao: "Anodontia",
            favorito: false
        },
        {
            cid10: "K001",
            descricao: "Dentes supranumerários",
            favorito: false
        },
        {
            cid10: "K002",
            descricao: "Anomalias do tamanho e da forma dos dentes",
            favorito: false
        },
        {
            cid10: "K003",
            descricao: "Dentes manchados",
            favorito: false
        },
        {
            cid10: "K004",
            descricao: "Distúrbios na formação dos dentes",
            favorito: false
        },
        {
            cid10: "K005",
            descricao: "Anomalias hereditárias da estrutura dentária não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "K006",
            descricao: "Distúrbios da erupção dentária",
            favorito: false
        },
        {
            cid10: "K007",
            descricao: "Síndrome da erupção dentária",
            favorito: false
        },
        {
            cid10: "K008",
            descricao: "Outros distúrbios do desenvolvimento dos dentes",
            favorito: false
        },
        {
            cid10: "K009",
            descricao: "Distúrbio não especificado do desenvolvimento dentário",
            favorito: false
        },
        {
            cid10: "K010",
            descricao: "Dentes inclusos",
            favorito: false
        },
        {
            cid10: "K011",
            descricao: "Dentes impactados",
            favorito: false
        },
        {
            cid10: "K020",
            descricao: "Cáries limitadas ao esmalte",
            favorito: false
        },
        {
            cid10: "K021",
            descricao: "Cáries da dentina",
            favorito: false
        },
        {
            cid10: "K022",
            descricao: "Cárie do cemento",
            favorito: false
        },
        {
            cid10: "K023",
            descricao: "Cáries dentárias estáveis",
            favorito: false
        },
        {
            cid10: "K024",
            descricao: "Odontoclasia",
            favorito: false
        },
        {
            cid10: "K028",
            descricao: "Outras cáries dentárias",
            favorito: false
        },
        {
            cid10: "K029",
            descricao: "Cárie dentária, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K030",
            descricao: "Atrito dentário excessivo",
            favorito: false
        },
        {
            cid10: "K031",
            descricao: "Abrasão dentária",
            favorito: false
        },
        {
            cid10: "K032",
            descricao: "Erosão dentária",
            favorito: false
        },
        {
            cid10: "K033",
            descricao: "Reabsorção patológica dos dentes",
            favorito: false
        },
        {
            cid10: "K034",
            descricao: "Hipercementose",
            favorito: false
        },
        {
            cid10: "K035",
            descricao: "Ancilose dentária",
            favorito: false
        },
        {
            cid10: "K036",
            descricao: "Depósitos nos dentes",
            favorito: false
        },
        {
            cid10: "K037",
            descricao: "Alterações pós-eruptivas da cor dos tecidos duros dos dentes",
            favorito: false
        },
        {
            cid10: "K038",
            descricao: "Outras doenças especificadas dos tecidos duros dos dentes",
            favorito: false
        },
        {
            cid10: "K039",
            descricao: "Doença dos tecidos duros dos dentes, não especificada",
            favorito: false
        },
        {
            cid10: "K040",
            descricao: "Pulpite",
            favorito: false
        },
        {
            cid10: "K041",
            descricao: "Necrose da polpa",
            favorito: false
        },
        {
            cid10: "K042",
            descricao: "Degeneração da polpa",
            favorito: false
        },
        {
            cid10: "K043",
            descricao: "Formação anormal de tecidos duros na polpa",
            favorito: false
        },
        {
            cid10: "K044",
            descricao: "Periodontite apical aguda de origem pulpar",
            favorito: false
        },
        {
            cid10: "K045",
            descricao: "Periodontite apical crônica",
            favorito: false
        },
        {
            cid10: "K046",
            descricao: "Abscesso periapical com fístula",
            favorito: false
        },
        {
            cid10: "K047",
            descricao: "Abscesso periapical sem fístula",
            favorito: false
        },
        {
            cid10: "K048",
            descricao: "Cisto radicular",
            favorito: false
        },
        {
            cid10: "K049",
            descricao: "Outras doenças da polpa e dos tecidos periapicais e as não especificadas",
            favorito: false
        },
        {
            cid10: "K050",
            descricao: "Gengivite aguda",
            favorito: false
        },
        {
            cid10: "K051",
            descricao: "Gengivite crônica",
            favorito: false
        },
        {
            cid10: "K052",
            descricao: "Periodontite aguda",
            favorito: false
        },
        {
            cid10: "K053",
            descricao: "Periodontite crônica",
            favorito: false
        },
        {
            cid10: "K054",
            descricao: "Periodontose",
            favorito: false
        },
        {
            cid10: "K055",
            descricao: "Outras doenças periodontais",
            favorito: false
        },
        {
            cid10: "K056",
            descricao: "Doença periodontal, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K060",
            descricao: "Retração gengival",
            favorito: false
        },
        {
            cid10: "K061",
            descricao: "Hiperplasia gengival",
            favorito: false
        },
        {
            cid10: "K062",
            descricao: "Lesões da gengiva e do rebordo alveolar sem dentes, associadas a traumatismos",
            favorito: false
        },
        {
            cid10: "K068",
            descricao: "Outros transtornos especificados da gengiva e do rebordo alveolar sem dentes",
            favorito: false
        },
        {
            cid10: "K069",
            descricao: "Transtorno da gengiva e do rebordo alveolar sem dentes sem outra especificação",
            favorito: false
        },
        {
            cid10: "K070",
            descricao: "Anomalias importantes (major) do tamanho da mandíbula",
            favorito: false
        },
        {
            cid10: "K071",
            descricao: "Anomalias da relação entre a mandíbula com a base do crânio",
            favorito: false
        },
        {
            cid10: "K072",
            descricao: "Anomalias da relação entre as arcadas dentárias",
            favorito: false
        },
        {
            cid10: "K073",
            descricao: "Anomalias da posição dos dentes",
            favorito: false
        },
        {
            cid10: "K074",
            descricao: "Maloclusão, não especificada",
            favorito: false
        },
        {
            cid10: "K075",
            descricao: "Anormalidades dentofaciais funcionais",
            favorito: false
        },
        {
            cid10: "K076",
            descricao: "Transtornos da articulação temporomandibular",
            favorito: false
        },
        {
            cid10: "K078",
            descricao: "Outras anomalias dentofaciais",
            favorito: false
        },
        {
            cid10: "K079",
            descricao: "Anomalia dentofacial, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K080",
            descricao: "Exfoliação dos dentes devida a causas sistêmicas",
            favorito: false
        },
        {
            cid10: "K081",
            descricao: "Perda de dentes devida a acidente, extração ou a doenças periodontais localizadas",
            favorito: false
        },
        {
            cid10: "K082",
            descricao: "Atrofia do rebordo alveolar sem dentes",
            favorito: false
        },
        {
            cid10: "K083",
            descricao: "Raiz dentária retida",
            favorito: false
        },
        {
            cid10: "K088",
            descricao: "Outros transtornos especificados dos dentes e das estruturas de sustentação",
            favorito: false
        },
        {
            cid10: "K089",
            descricao: "Transtorno dos dentes e de suas estruturas de sustentação, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K090",
            descricao: "Cistos odontogênicos de desenvolvimento",
            favorito: false
        },
        {
            cid10: "K091",
            descricao: "Cistos de desenvolvimento (não-odontogênicos) da região bucal",
            favorito: false
        },
        {
            cid10: "K092",
            descricao: "Outros cistos das mandíbulas",
            favorito: false
        },
        {
            cid10: "K098",
            descricao: "Outros cistos da região oral não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "K099",
            descricao: "Cistos da região oral, sem outras especificações",
            favorito: false
        },
        {
            cid10: "K100",
            descricao: "Transtornos do desenvolvimento dos maxilares",
            favorito: false
        },
        {
            cid10: "K101",
            descricao: "Granuloma central de células gigantes",
            favorito: false
        },
        {
            cid10: "K102",
            descricao: "Afecções inflamatórias dos maxilares",
            favorito: false
        },
        {
            cid10: "K103",
            descricao: "Alveolite maxilar",
            favorito: false
        },
        {
            cid10: "K108",
            descricao: "Outras doenças especificadas dos maxilares",
            favorito: false
        },
        {
            cid10: "K109",
            descricao: "Doença dos maxilares, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K110",
            descricao: "Atrofia de glândula salivar",
            favorito: false
        },
        {
            cid10: "K111",
            descricao: "Hipertrofia de glândula salivar",
            favorito: false
        },
        {
            cid10: "K112",
            descricao: "Sialadenite",
            favorito: false
        },
        {
            cid10: "K113",
            descricao: "Abscesso de glândula salivar",
            favorito: false
        },
        {
            cid10: "K114",
            descricao: "Fístula de glândula salivar",
            favorito: false
        },
        {
            cid10: "K115",
            descricao: "Sialolitíase",
            favorito: false
        },
        {
            cid10: "K116",
            descricao: "Mucocele de glândula salivar",
            favorito: false
        },
        {
            cid10: "K117",
            descricao: "Alterações da secreção salivar",
            favorito: false
        },
        {
            cid10: "K118",
            descricao: "Outras doenças das glândulas salivares",
            favorito: false
        },
        {
            cid10: "K119",
            descricao: "Doença de glândula salivar, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K120",
            descricao: "Aftas bucais recidivantes",
            favorito: false
        },
        {
            cid10: "K121",
            descricao: "Outras formas de estomatite",
            favorito: false
        },
        {
            cid10: "K122",
            descricao: "Celulite e abscesso da boca",
            favorito: false
        },
        {
            cid10: "K130",
            descricao: "Doenças dos lábios",
            favorito: false
        },
        {
            cid10: "K131",
            descricao: "Mordedura da mucosa das bochechas e dos lábios",
            favorito: false
        },
        {
            cid10: "K132",
            descricao: "Leucoplasia e outras afecções do epitélio oral, inclusive da língua",
            favorito: false
        },
        {
            cid10: "K133",
            descricao: "Leucoplasia pilosa",
            favorito: false
        },
        {
            cid10: "K134",
            descricao: "Lesões granulomatosas e granulomatóides da mucosa oral",
            favorito: false
        },
        {
            cid10: "K135",
            descricao: "Fibrose oral submucosa",
            favorito: false
        },
        {
            cid10: "K136",
            descricao: "Hiperplasia irritativa da mucosa oral",
            favorito: false
        },
        {
            cid10: "K137",
            descricao: "Outras lesões e as não especificadas da mucosa oral",
            favorito: false
        },
        {
            cid10: "K140",
            descricao: "Glossite",
            favorito: false
        },
        {
            cid10: "K141",
            descricao: "Língua geográfica",
            favorito: false
        },
        {
            cid10: "K142",
            descricao: "Glossite rombóide mediana",
            favorito: false
        },
        {
            cid10: "K143",
            descricao: "Hipertrofia das papilas linguais",
            favorito: false
        },
        {
            cid10: "K144",
            descricao: "Atrofia das papilas linguais",
            favorito: false
        },
        {
            cid10: "K145",
            descricao: "Língua escrotal",
            favorito: false
        },
        {
            cid10: "K146",
            descricao: "Glossodínia",
            favorito: false
        },
        {
            cid10: "K148",
            descricao: "Outras doenças da língua",
            favorito: false
        },
        {
            cid10: "K149",
            descricao: "Doença da língua, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K20",
            descricao: "Esofagite",
            favorito: false
        },
        {
            cid10: "K210",
            descricao: "Doença de refluxo gastroesofágico com esofagite",
            favorito: false
        },
        {
            cid10: "K219",
            descricao: "Doença de refluxo gastroesofágico sem esofagite",
            favorito: false
        },
        {
            cid10: "K220",
            descricao: "Acalásia do cárdia",
            favorito: false
        },
        {
            cid10: "K221",
            descricao: "Úlcera do esôfago",
            favorito: false
        },
        {
            cid10: "K222",
            descricao: "Obstrução do esôfago",
            favorito: false
        },
        {
            cid10: "K223",
            descricao: "Perfuração do esôfago",
            favorito: false
        },
        {
            cid10: "K224",
            descricao: "Discinesia do esôfago",
            favorito: false
        },
        {
            cid10: "K225",
            descricao: "Divertículo do esôfago adquirido",
            favorito: false
        },
        {
            cid10: "K226",
            descricao: "Síndrome da laceração hemorrágica gastroesofágica",
            favorito: false
        },
        {
            cid10: "K227",
            descricao: "Esôfago de Barret",
            favorito: false
        },
        {
            cid10: "K228",
            descricao: "Outras doenças especificadas do esôfago",
            favorito: false
        },
        {
            cid10: "K229",
            descricao: "Doença do esôfago, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K230",
            descricao: "Esofagite tuberculosa",
            favorito: false
        },
        {
            cid10: "K231",
            descricao: "Megaesôfago na doença de Chagas",
            favorito: false
        },
        {
            cid10: "K238",
            descricao: "Transtornos do esôfago em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "K250",
            descricao: "Úlcera gástrica - aguda com hemorragia",
            favorito: false
        },
        {
            cid10: "K251",
            descricao: "Úlcera gástrica - aguda com perfuração",
            favorito: false
        },
        {
            cid10: "K252",
            descricao: "Úlcera gástrica - aguda com hemorragia e perfuração",
            favorito: false
        },
        {
            cid10: "K253",
            descricao: "Úlcera gástrica - aguda sem hemorragia ou perfuração",
            favorito: false
        },
        {
            cid10: "K254",
            descricao: "Úlcera gástrica - crônica ou não especificada com hemorragia",
            favorito: false
        },
        {
            cid10: "K255",
            descricao: "Úlcera gástrica - crônica ou não especificada com perfuração",
            favorito: false
        },
        {
            cid10: "K256",
            descricao: "Úlcera gástrica - crônica ou não especificada com hemorragia e perfuração",
            favorito: false
        },
        {
            cid10: "K257",
            descricao: "Úlcera gástrica - crônica sem hemorragia ou perfuração",
            favorito: false
        },
        {
            cid10: "K259",
            descricao: "Úlcera gástrica - não especificada como aguda ou crônica, sem hemorragia ou perfuração",
            favorito: false
        },
        {
            cid10: "K260",
            descricao: "Úlcera duodenal - aguda com hemorragia",
            favorito: false
        },
        {
            cid10: "K261",
            descricao: "Úlcera duodenal - aguda com perfuração",
            favorito: false
        },
        {
            cid10: "K262",
            descricao: "Úlcera duodenal - aguda com hemorragia e perfuração",
            favorito: false
        },
        {
            cid10: "K263",
            descricao: "Úlcera duodenal - aguda sem hemorragia ou perfuração",
            favorito: false
        },
        {
            cid10: "K264",
            descricao: "Úlcera duodenal - crônica ou não especificada com hemorragia",
            favorito: false
        },
        {
            cid10: "K265",
            descricao: "Úlcera duodenal - crônica ou não especificada com perfuração",
            favorito: false
        },
        {
            cid10: "K266",
            descricao: "Úlcera duodenal - crônica ou não especificada com hemorragia e perfuração",
            favorito: false
        },
        {
            cid10: "K267",
            descricao: "Úlcera duodenal - crônica sem hemorragia ou perfuração",
            favorito: false
        },
        {
            cid10: "K269",
            descricao: "Úlcera duodenal - não especificada como aguda ou crônica, sem hemorragia ou perfuração",
            favorito: false
        },
        {
            cid10: "K270",
            descricao: "Úlcera péptica de localização não especificada - aguda com hemorragia",
            favorito: false
        },
        {
            cid10: "K271",
            descricao: "Úlcera péptica de localização não especificada - aguda com perfuração",
            favorito: false
        },
        {
            cid10: "K272",
            descricao: "Úlcera péptica de localização não especificada - aguda com hemorragia e perfuração",
            favorito: false
        },
        {
            cid10: "K273",
            descricao: "Úlcera péptica de localização não especificada - aguda sem hemorragia ou perfuração",
            favorito: false
        },
        {
            cid10: "K274",
            descricao: "Úlcera péptica de localização não especificada - crônica ou não especificada com hemorragia",
            favorito: false
        },
        {
            cid10: "K275",
            descricao: "Úlcera péptica de localização não especificada - crônica ou não especificada com perfuração",
            favorito: false
        },
        {
            cid10: "K276",
            descricao: "Úlcera péptica de localização não especificada - crônica ou não especificada com hemorragia e perfuração",
            favorito: false
        },
        {
            cid10: "K277",
            descricao: "Úlcera péptica de localização não especificada - crônica sem hemorragia ou perfuração",
            favorito: false
        },
        {
            cid10: "K279",
            descricao: "Úlcera péptica de localização não especificada - não especificada como aguda ou crônica, sem hemorragia ou perfuração",
            favorito: false
        },
        {
            cid10: "K280",
            descricao: "Úlcera gastrojejunal - aguda com hemorragia",
            favorito: false
        },
        {
            cid10: "K281",
            descricao: "Úlcera gastrojejunal - aguda com perfuração",
            favorito: false
        },
        {
            cid10: "K282",
            descricao: "Úlcera gastrojejunal - aguda com hemorragia e perfuração",
            favorito: false
        },
        {
            cid10: "K283",
            descricao: "Úlcera gastrojejunal - aguda sem hemorragia ou perfuração",
            favorito: false
        },
        {
            cid10: "K284",
            descricao: "Úlcera gastrojejunal - crônica ou não especificada com hemorragia",
            favorito: false
        },
        {
            cid10: "K285",
            descricao: "Úlcera gastrojejunal - crônica ou não especificada com perfuração",
            favorito: false
        },
        {
            cid10: "K286",
            descricao: "Úlcera gastrojejunal - crônica ou não especificada com hemorragia e perfuração",
            favorito: false
        },
        {
            cid10: "K287",
            descricao: "Úlcera gastrojejunal - crônica sem hemorragia ou perfuração",
            favorito: false
        },
        {
            cid10: "K289",
            descricao: "Úlcera gastrojejunal - não especificada como aguda ou crônica, sem hemorragia ou perfuração",
            favorito: false
        },
        {
            cid10: "K290",
            descricao: "Gastrite hemorrágica aguda",
            favorito: false
        },
        {
            cid10: "K291",
            descricao: "Outras gastrites agudas",
            favorito: false
        },
        {
            cid10: "K292",
            descricao: "Gastrite alcoólica",
            favorito: false
        },
        {
            cid10: "K293",
            descricao: "Gastrite superficial crônica",
            favorito: false
        },
        {
            cid10: "K294",
            descricao: "Gastrite atrófica crônica",
            favorito: false
        },
        {
            cid10: "K295",
            descricao: "Gastrite crônica, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K296",
            descricao: "Outras gastrites",
            favorito: false
        },
        {
            cid10: "K297",
            descricao: "Gastrite não especificada",
            favorito: false
        },
        {
            cid10: "K298",
            descricao: "Duodenite",
            favorito: false
        },
        {
            cid10: "K299",
            descricao: "Gastroduodenite, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K30",
            descricao: "Dispepsia",
            favorito: false
        },
        {
            cid10: "K310",
            descricao: "Dilatação aguda do estômago",
            favorito: false
        },
        {
            cid10: "K311",
            descricao: "Estenose pilórica hipertrófica do adulto",
            favorito: false
        },
        {
            cid10: "K312",
            descricao: "Estenose e estreitamento em ampulheta do estômago",
            favorito: false
        },
        {
            cid10: "K313",
            descricao: "Espasmo do piloro não classificado em outra parte",
            favorito: false
        },
        {
            cid10: "K314",
            descricao: "Divertículo gástrico",
            favorito: false
        },
        {
            cid10: "K315",
            descricao: "Obstrução do duodeno",
            favorito: false
        },
        {
            cid10: "K316",
            descricao: "Fístula do estômago e do duodeno",
            favorito: false
        },
        {
            cid10: "K317",
            descricao: "Pólipo do estômago e do duodeno",
            favorito: false
        },
        {
            cid10: "K318",
            descricao: "Outras doenças especificadas do estômago e do duodeno",
            favorito: false
        },
        {
            cid10: "K319",
            descricao: "Doenças do estômago e do duodeno, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K350",
            descricao: "Apendicite aguda com peritonite generalizada",
            favorito: false
        },
        {
            cid10: "K351",
            descricao: "Apendicite aguda com abscesso peritonial",
            favorito: false
        },
        {
            cid10: "K359",
            descricao: "Apendicite aguda sem outra especificação",
            favorito: false
        },
        {
            cid10: "K36",
            descricao: "Outras formas de apendicite",
            favorito: false
        },
        {
            cid10: "K37",
            descricao: "Apendicite, sem outras especificações",
            favorito: false
        },
        {
            cid10: "K380",
            descricao: "Hiperplasia do apêndice",
            favorito: false
        },
        {
            cid10: "K381",
            descricao: "Concreções apendiculares",
            favorito: false
        },
        {
            cid10: "K382",
            descricao: "Divertículo do apêndice",
            favorito: false
        },
        {
            cid10: "K383",
            descricao: "Fístula do apêndice",
            favorito: false
        },
        {
            cid10: "K388",
            descricao: "Outras doenças especificadas do apêndice",
            favorito: false
        },
        {
            cid10: "K389",
            descricao: "Doença do apêndice, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K400",
            descricao: "Hérnia inguinal bilateral, com obstrução, sem gangrena",
            favorito: false
        },
        {
            cid10: "K401",
            descricao: "Hérnia inguinal bilateral, com gangrena",
            favorito: false
        },
        {
            cid10: "K402",
            descricao: "Hérnia inguinal bilateral, sem obstrução ou gangrena",
            favorito: false
        },
        {
            cid10: "K403",
            descricao: "Hérnia inguinal unilateral ou não especificada, com obstrução sem gangrena",
            favorito: false
        },
        {
            cid10: "K404",
            descricao: "Hérnia inguinal unilateral ou não especificada, com gangrena",
            favorito: false
        },
        {
            cid10: "K409",
            descricao: "Hérnia inguinal unilateral ou não especificada, sem obstrução ou gangrena",
            favorito: false
        },
        {
            cid10: "K410",
            descricao: "Hérnia femoral bilateral, com obstrução, sem gangrena",
            favorito: false
        },
        {
            cid10: "K411",
            descricao: "Hérnia femoral bilateral, com gangrena",
            favorito: false
        },
        {
            cid10: "K412",
            descricao: "Hérnia femoral bilateral, sem obstrução ou gangrena",
            favorito: false
        },
        {
            cid10: "K413",
            descricao: "Hérnia femoral unilateral ou não especificada, com obstrução, sem gangrena",
            favorito: false
        },
        {
            cid10: "K414",
            descricao: "Hérnia femoral unilateral ou não especificada, com gangrena",
            favorito: false
        },
        {
            cid10: "K419",
            descricao: "Hérnia femoral unilateral ou não especificada, sem obstrução ou gangrena",
            favorito: false
        },
        {
            cid10: "K420",
            descricao: "Hérnia umbilical com obstrução, sem gangrena",
            favorito: false
        },
        {
            cid10: "K421",
            descricao: "Hérnia umbilical com gangrena",
            favorito: false
        },
        {
            cid10: "K429",
            descricao: "Hérnia umbilical sem obstrução ou gangrena",
            favorito: false
        },
        {
            cid10: "K430",
            descricao: "Hérnia ventral com obstrução, sem gangrena",
            favorito: false
        },
        {
            cid10: "K431",
            descricao: "Hérnia ventral com gangrena",
            favorito: false
        },
        {
            cid10: "K439",
            descricao: "Hérnia ventral sem obstrução ou gangrena",
            favorito: false
        },
        {
            cid10: "K440",
            descricao: "Hérnia diafragmática com obstrução, sem gangrena",
            favorito: false
        },
        {
            cid10: "K441",
            descricao: "Hérnia diafragmática com gangrena",
            favorito: false
        },
        {
            cid10: "K449",
            descricao: "Hérnia diafragmática sem obstrução ou gangrena",
            favorito: false
        },
        {
            cid10: "K450",
            descricao: "Outras hérnias abdominais especificadas, com obstrução, sem gangrena",
            favorito: false
        },
        {
            cid10: "K451",
            descricao: "Outras hérnias abdominais especificadas, com gangrena",
            favorito: false
        },
        {
            cid10: "K458",
            descricao: "Outras hérnias abdominais especificadas, sem obstrução ou gangrena",
            favorito: false
        },
        {
            cid10: "K460",
            descricao: "Hérnia abdominal não especificada, com obstrução, sem gangrena",
            favorito: false
        },
        {
            cid10: "K461",
            descricao: "Hérnia abdominal não especificada com gangrena",
            favorito: false
        },
        {
            cid10: "K469",
            descricao: "Hérnia abdominal não especificada, sem obstrução ou gangrena",
            favorito: false
        },
        {
            cid10: "K500",
            descricao: "Doença de Crohn do intestino delgado",
            favorito: false
        },
        {
            cid10: "K501",
            descricao: "Doença de Crohn do intestino grosso",
            favorito: false
        },
        {
            cid10: "K508",
            descricao: "Outra forma de doença de Crohn",
            favorito: false
        },
        {
            cid10: "K509",
            descricao: "Doença de Crohn de localização não especificada",
            favorito: false
        },
        {
            cid10: "K510",
            descricao: "Enterocolite ulcerativa (crônica)",
            favorito: false
        },
        {
            cid10: "K511",
            descricao: "Ileocolite ulcerativa (crônica)",
            favorito: false
        },
        {
            cid10: "K512",
            descricao: "Proctite ulcerativa (crônica)",
            favorito: false
        },
        {
            cid10: "K513",
            descricao: "Retossigmoidite ulcerativa (crônica)",
            favorito: false
        },
        {
            cid10: "K514",
            descricao: "Pseudopolipose do cólon",
            favorito: false
        },
        {
            cid10: "K515",
            descricao: "Proctocolite mucosa",
            favorito: false
        },
        {
            cid10: "K518",
            descricao: "Outras colites ulcerativas",
            favorito: false
        },
        {
            cid10: "K519",
            descricao: "Colite ulcerativa, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K520",
            descricao: "Gastroenterite e colite devida à radiação",
            favorito: false
        },
        {
            cid10: "K521",
            descricao: "Gastroenterite e colite tóxicas",
            favorito: false
        },
        {
            cid10: "K522",
            descricao: "Gastroenterite e colite alérgicas ou ligadas à dieta",
            favorito: false
        },
        {
            cid10: "K528",
            descricao: "Outras gastroenterites e colites especificadas, não-infecciosas",
            favorito: false
        },
        {
            cid10: "K529",
            descricao: "Gastroenterite e colite não-infecciosas, não especificadas",
            favorito: false
        },
        {
            cid10: "K550",
            descricao: "Transtornos vasculares agudos do intestino",
            favorito: false
        },
        {
            cid10: "K551",
            descricao: "Transtornos vasculares crônicos do intestino",
            favorito: false
        },
        {
            cid10: "K552",
            descricao: "Angiodisplasia do cólon",
            favorito: false
        },
        {
            cid10: "K558",
            descricao: "Outros transtornos vasculares do intestino",
            favorito: false
        },
        {
            cid10: "K559",
            descricao: "Transtorno vascular do intestino, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K560",
            descricao: "Íleo paralítico",
            favorito: false
        },
        {
            cid10: "K561",
            descricao: "Intussuscepção",
            favorito: false
        },
        {
            cid10: "K562",
            descricao: "Volvo",
            favorito: false
        },
        {
            cid10: "K563",
            descricao: "Íleo biliar",
            favorito: false
        },
        {
            cid10: "K564",
            descricao: "Outras obstruções do intestino",
            favorito: false
        },
        {
            cid10: "K565",
            descricao: "Aderências intestinais (bridas) com obstrução",
            favorito: false
        },
        {
            cid10: "K566",
            descricao: "Outras formas de obstrução intestinal, e as não especificadas",
            favorito: false
        },
        {
            cid10: "K567",
            descricao: "Íleo, não especificado",
            favorito: false
        },
        {
            cid10: "K570",
            descricao: "Doença diverticular do intestino delgado com perfuração e abscesso",
            favorito: false
        },
        {
            cid10: "K571",
            descricao: "Doença diverticular do intestino delgado sem perfuração ou abscesso",
            favorito: false
        },
        {
            cid10: "K572",
            descricao: "Doença diverticular do intestino grosso com perfuração e abscesso",
            favorito: false
        },
        {
            cid10: "K573",
            descricao: "Doença diverticular do intestino grosso sem perfuração ou abscesso",
            favorito: false
        },
        {
            cid10: "K574",
            descricao: "Doença diverticular concomitante dos intestinos delgado e grosso com perfuração e abscesso",
            favorito: false
        },
        {
            cid10: "K575",
            descricao: "Doença diverticular concomitante dos intestinos delgado e grosso sem perfuração ou abscesso",
            favorito: false
        },
        {
            cid10: "K578",
            descricao: "Doença diverticular do intestino, de localização não especificada, com perfuração e abscesso",
            favorito: false
        },
        {
            cid10: "K579",
            descricao: "Doença diverticular do intestino, de localização não especificada, sem perfuração ou abscesso",
            favorito: false
        },
        {
            cid10: "K580",
            descricao: "Síndrome do cólon irritável com diarréia",
            favorito: false
        },
        {
            cid10: "K589",
            descricao: "Síndrome do cólon irritável sem diarréia",
            favorito: false
        },
        {
            cid10: "K590",
            descricao: "Constipação",
            favorito: false
        },
        {
            cid10: "K591",
            descricao: "Diarréia funcional",
            favorito: false
        },
        {
            cid10: "K592",
            descricao: "Cólon neurogênico não classificado em outra parte",
            favorito: false
        },
        {
            cid10: "K593",
            descricao: "Megacólon não classificado em outra parte",
            favorito: false
        },
        {
            cid10: "K594",
            descricao: "Espasmo anal",
            favorito: false
        },
        {
            cid10: "K598",
            descricao: "Outros transtornos funcionais especificados do intestino",
            favorito: false
        },
        {
            cid10: "K599",
            descricao: "Transtorno intestinal funcional, não especificado",
            favorito: false
        },
        {
            cid10: "K600",
            descricao: "Fissura anal aguda",
            favorito: false
        },
        {
            cid10: "K601",
            descricao: "Fissura anal crônica",
            favorito: false
        },
        {
            cid10: "K602",
            descricao: "Fissura anal, não especificada",
            favorito: false
        },
        {
            cid10: "K603",
            descricao: "Fístula anal",
            favorito: false
        },
        {
            cid10: "K604",
            descricao: "Fístula retal",
            favorito: false
        },
        {
            cid10: "K605",
            descricao: "Fístula anorretal",
            favorito: false
        },
        {
            cid10: "K610",
            descricao: "Abscesso anal",
            favorito: false
        },
        {
            cid10: "K611",
            descricao: "Abscesso retal",
            favorito: false
        },
        {
            cid10: "K612",
            descricao: "Abscesso anorretal",
            favorito: false
        },
        {
            cid10: "K613",
            descricao: "Abscesso ísquio-retal",
            favorito: false
        },
        {
            cid10: "K614",
            descricao: "Abscesso intra-esfincteriano",
            favorito: false
        },
        {
            cid10: "K620",
            descricao: "Pólipo anal",
            favorito: false
        },
        {
            cid10: "K621",
            descricao: "Pólipo retal",
            favorito: false
        },
        {
            cid10: "K622",
            descricao: "Prolapso anal",
            favorito: false
        },
        {
            cid10: "K623",
            descricao: "Prolapso retal",
            favorito: false
        },
        {
            cid10: "K624",
            descricao: "Estenose do ânus e do reto",
            favorito: false
        },
        {
            cid10: "K625",
            descricao: "Hemorragia do ânus e do reto",
            favorito: false
        },
        {
            cid10: "K626",
            descricao: "Úlcera do ânus e do reto",
            favorito: false
        },
        {
            cid10: "K627",
            descricao: "Proctite por radiação",
            favorito: false
        },
        {
            cid10: "K628",
            descricao: "Outras doenças especificadas do ânus e do reto",
            favorito: false
        },
        {
            cid10: "K629",
            descricao: "Doença do ânus e do reto, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K630",
            descricao: "Abscesso do intestino",
            favorito: false
        },
        {
            cid10: "K631",
            descricao: "Perfuração do intestino (não-traumática)",
            favorito: false
        },
        {
            cid10: "K632",
            descricao: "Fístula do intestino",
            favorito: false
        },
        {
            cid10: "K633",
            descricao: "Úlcera do intestino",
            favorito: false
        },
        {
            cid10: "K634",
            descricao: "Enteroptose",
            favorito: false
        },
        {
            cid10: "K635",
            descricao: "Pólipo do cólon",
            favorito: false
        },
        {
            cid10: "K638",
            descricao: "Outras doenças especificadas do intestino",
            favorito: false
        },
        {
            cid10: "K639",
            descricao: "Doença do intestino, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K650",
            descricao: "Peritonite aguda",
            favorito: false
        },
        {
            cid10: "K658",
            descricao: "Outras peritonites",
            favorito: false
        },
        {
            cid10: "K659",
            descricao: "Peritonite, sem outras especificações",
            favorito: false
        },
        {
            cid10: "K660",
            descricao: "Aderências peritoniais",
            favorito: false
        },
        {
            cid10: "K661",
            descricao: "Hemoperitônio",
            favorito: false
        },
        {
            cid10: "K668",
            descricao: "Outras afecções especificadas do peritônio",
            favorito: false
        },
        {
            cid10: "K669",
            descricao: "Afecções do peritônio, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K670",
            descricao: "Peritonite por clamídias",
            favorito: false
        },
        {
            cid10: "K671",
            descricao: "Peritonite gonocócica",
            favorito: false
        },
        {
            cid10: "K672",
            descricao: "Peritonite sifilítica",
            favorito: false
        },
        {
            cid10: "K673",
            descricao: "Peritonite tuberculosa",
            favorito: false
        },
        {
            cid10: "K678",
            descricao: "Outras formas de comprometimento do peritônio em doenças infecciosas classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "K700",
            descricao: "Fígado gorduroso alcoólico",
            favorito: false
        },
        {
            cid10: "K701",
            descricao: "Hepatite alcoólica",
            favorito: false
        },
        {
            cid10: "K702",
            descricao: "Fibrose e esclerose alcoólicas do fígado",
            favorito: false
        },
        {
            cid10: "K703",
            descricao: "Cirrose hepática alcoólica",
            favorito: false
        },
        {
            cid10: "K704",
            descricao: "Insuficiência hepática alcoólica",
            favorito: false
        },
        {
            cid10: "K709",
            descricao: "Doença alcoólica do fígado, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K710",
            descricao: "Doença hepática tóxica com colestase",
            favorito: false
        },
        {
            cid10: "K711",
            descricao: "Doença hepática tóxica com necrose hepática",
            favorito: false
        },
        {
            cid10: "K712",
            descricao: "Doença hepática tóxica com hepatite aguda",
            favorito: false
        },
        {
            cid10: "K713",
            descricao: "Doença hepática tóxica com hepatite crônica persistente",
            favorito: false
        },
        {
            cid10: "K714",
            descricao: "Doença hepática tóxica com hepatite crônica lobular",
            favorito: false
        },
        {
            cid10: "K715",
            descricao: "Doença hepática tóxica com hepatite crônica ativa",
            favorito: false
        },
        {
            cid10: "K716",
            descricao: "Doença hepática tóxica com hepatite não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "K717",
            descricao: "Doença hepática tóxica com fibrose e cirrose hepáticas",
            favorito: false
        },
        {
            cid10: "K718",
            descricao: "Doença hepática tóxica com outros transtornos do fígado",
            favorito: false
        },
        {
            cid10: "K719",
            descricao: "Doença hepática tóxica, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K720",
            descricao: "Insuficiência hepática aguda e subaguda",
            favorito: false
        },
        {
            cid10: "K721",
            descricao: "Insuficiência hepática crônica",
            favorito: false
        },
        {
            cid10: "K729",
            descricao: "Insuficiência hepática, sem outras especificações",
            favorito: false
        },
        {
            cid10: "K730",
            descricao: "Hepatite crônica persistente, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "K731",
            descricao: "Hepatite crônica lobular, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "K732",
            descricao: "Hepatite crônica ativa, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "K738",
            descricao: "Outras hepatites crônicas não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "K739",
            descricao: "Hepatite crônica, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K740",
            descricao: "Fibrose hepática",
            favorito: false
        },
        {
            cid10: "K741",
            descricao: "Esclerose hepática",
            favorito: false
        },
        {
            cid10: "K742",
            descricao: "Fibrose hepática com esclerose hepática",
            favorito: false
        },
        {
            cid10: "K743",
            descricao: "Cirrose biliar primária",
            favorito: false
        },
        {
            cid10: "K744",
            descricao: "Cirrose biliar secundária",
            favorito: false
        },
        {
            cid10: "K745",
            descricao: "Cirrose biliar, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K746",
            descricao: "Outras formas de cirrose hepática e as não especificadas",
            favorito: false
        },
        {
            cid10: "K750",
            descricao: "Abscesso hepático",
            favorito: false
        },
        {
            cid10: "K751",
            descricao: "Flebite da veia porta",
            favorito: false
        },
        {
            cid10: "K752",
            descricao: "Hepatite reativa não-específica",
            favorito: false
        },
        {
            cid10: "K753",
            descricao: "Hepatite granulomatosa não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "K754",
            descricao: "Hepatite autoimune",
            favorito: false
        },
        {
            cid10: "K758",
            descricao: "Outras doenças hepáticas inflamatórias especificadas",
            favorito: false
        },
        {
            cid10: "K759",
            descricao: "Doença hepática inflamatória, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K760",
            descricao: "Degeneração gordurosa do fígado não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "K761",
            descricao: "Congestão passiva crônica do fígado",
            favorito: false
        },
        {
            cid10: "K762",
            descricao: "Necrose hemorrágica central do fígado",
            favorito: false
        },
        {
            cid10: "K763",
            descricao: "Infarto do fígado",
            favorito: false
        },
        {
            cid10: "K764",
            descricao: "Peliose hepática",
            favorito: false
        },
        {
            cid10: "K765",
            descricao: "Doença hepática veno-oclusiva",
            favorito: false
        },
        {
            cid10: "K766",
            descricao: "Hipertensão portal",
            favorito: false
        },
        {
            cid10: "K767",
            descricao: "Síndrome hepatorrenal",
            favorito: false
        },
        {
            cid10: "K768",
            descricao: "Outras doenças especificadas do fígado",
            favorito: false
        },
        {
            cid10: "K769",
            descricao: "Doença hepática, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K770",
            descricao: "Transtornos hepáticos em doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "K778",
            descricao: "Transtornos hepáticos em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "K800",
            descricao: "Calculose da vesícula biliar com colicistite aguda",
            favorito: false
        },
        {
            cid10: "K801",
            descricao: "Calculose da vesícula biliar com outras formas de colecistite",
            favorito: false
        },
        {
            cid10: "K802",
            descricao: "Calculose da vesícula biliar sem colecistite",
            favorito: false
        },
        {
            cid10: "K803",
            descricao: "Calculose de via biliar com colangite",
            favorito: false
        },
        {
            cid10: "K804",
            descricao: "Calculose de via biliar com colecistite",
            favorito: false
        },
        {
            cid10: "K805",
            descricao: "Calculose de via biliar sem colangite ou colecistite",
            favorito: false
        },
        {
            cid10: "K808",
            descricao: "Outras colelitíases",
            favorito: false
        },
        {
            cid10: "K810",
            descricao: "Colecistite aguda",
            favorito: false
        },
        {
            cid10: "K811",
            descricao: "Colecistite crônica",
            favorito: false
        },
        {
            cid10: "K818",
            descricao: "Outras colecistites",
            favorito: false
        },
        {
            cid10: "K819",
            descricao: "Colecistite, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K820",
            descricao: "Obstrução da vesícula biliar",
            favorito: false
        },
        {
            cid10: "K821",
            descricao: "Hidropsia da vesícula biliar",
            favorito: false
        },
        {
            cid10: "K822",
            descricao: "Perfuração da vesícula biliar",
            favorito: false
        },
        {
            cid10: "K823",
            descricao: "Fístula da vesícula biliar",
            favorito: false
        },
        {
            cid10: "K824",
            descricao: "Colesterolose da vesícula biliar",
            favorito: false
        },
        {
            cid10: "K828",
            descricao: "Outras doenças especificadas da vesícula biliar",
            favorito: false
        },
        {
            cid10: "K829",
            descricao: "Doença da vesícula biliar, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K830",
            descricao: "Colangite",
            favorito: false
        },
        {
            cid10: "K831",
            descricao: "Obstrução de via biliar",
            favorito: false
        },
        {
            cid10: "K832",
            descricao: "Perfuração de via biliar",
            favorito: false
        },
        {
            cid10: "K833",
            descricao: "Fístula de via biliar",
            favorito: false
        },
        {
            cid10: "K834",
            descricao: "Espasmo do esfíncter de Oddi",
            favorito: false
        },
        {
            cid10: "K835",
            descricao: "Cisto biliar",
            favorito: false
        },
        {
            cid10: "K838",
            descricao: "Outras doenças especificadas das vias biliares",
            favorito: false
        },
        {
            cid10: "K839",
            descricao: "Doença das vias biliares, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K850",
            descricao: "Pancreatite aguda idiopática",
            favorito: false
        },
        {
            cid10: "K851",
            descricao: "Pancreatite aguda biliar",
            favorito: false
        },
        {
            cid10: "K852",
            descricao: "Pancreatite aguda induzida por álcool",
            favorito: false
        },
        {
            cid10: "K853",
            descricao: "Pancreatite aguda induzida por droga",
            favorito: false
        },
        {
            cid10: "K858",
            descricao: "Outras pancreatites agudas",
            favorito: false
        },
        {
            cid10: "K859",
            descricao: "Pancreatite aguda, não especificada",
            favorito: false
        },
        {
            cid10: "K860",
            descricao: "Pancreatite crônica induzida por álcool",
            favorito: false
        },
        {
            cid10: "K861",
            descricao: "Outras pancreatites crônicas",
            favorito: false
        },
        {
            cid10: "K862",
            descricao: "Cisto do pâncreas",
            favorito: false
        },
        {
            cid10: "K863",
            descricao: "Pseudocisto do pâncreas",
            favorito: false
        },
        {
            cid10: "K868",
            descricao: "Outras doenças especificadas do pâncreas",
            favorito: false
        },
        {
            cid10: "K869",
            descricao: "Doença do pâncreas, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K870",
            descricao: "Transtornos da vesícula biliar e das vias biliares em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "K871",
            descricao: "Transtornos do pâncreas em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "K900",
            descricao: "Doença celíaca",
            favorito: false
        },
        {
            cid10: "K901",
            descricao: "Espru tropical",
            favorito: false
        },
        {
            cid10: "K902",
            descricao: "Síndrome da alça cega não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "K903",
            descricao: "Esteatorréia pancreática",
            favorito: false
        },
        {
            cid10: "K904",
            descricao: "Má-absorção devida a intolerância não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "K908",
            descricao: "Outras formas de má-absorção intestinal",
            favorito: false
        },
        {
            cid10: "K909",
            descricao: "Má-absorção intestinal, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K910",
            descricao: "Vômitos pós-cirurgia gastrointestinal",
            favorito: false
        },
        {
            cid10: "K911",
            descricao: "Síndromes pós-cirurgia gástrica",
            favorito: false
        },
        {
            cid10: "K912",
            descricao: "Má-absorção pós-cirúrgica não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "K913",
            descricao: "Obstrução intestinal pós-operatória",
            favorito: false
        },
        {
            cid10: "K914",
            descricao: "Mau funcionamento de colostomia e enterostomia",
            favorito: false
        },
        {
            cid10: "K915",
            descricao: "Síndrome pós-colecistectomia",
            favorito: false
        },
        {
            cid10: "K918",
            descricao: "Outros transtornos do aparelho digestivo, pós-cirúrgicos, não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "K919",
            descricao: "Transtorno do aparelho digestivo pós procedimento",
            favorito: false
        },
        {
            cid10: "K920",
            descricao: "Hematêmese",
            favorito: false
        },
        {
            cid10: "K921",
            descricao: "Melena",
            favorito: false
        },
        {
            cid10: "K922",
            descricao: "Hemorragia gastrointestinal, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K928",
            descricao: "Outras doenças especificadas do aparelho digestivo",
            favorito: false
        },
        {
            cid10: "K929",
            descricao: "Doença do aparelho digestivo, sem outra especificação",
            favorito: false
        },
        {
            cid10: "K930",
            descricao: "Transtornos devido a tuberculose do intestino, do peritônio e dos gânglios do mesentério",
            favorito: false
        },
        {
            cid10: "K931",
            descricao: "Megacólon na doença de Chagas",
            favorito: false
        },
        {
            cid10: "K938",
            descricao: "Transtornos de outros órgãos digestivos especificados em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "L00",
            descricao: "Síndrome da pele escaldada estafilocócica do recém-nascido",
            favorito: false
        },
        {
            cid10: "L010",
            descricao: "Impetigo [qualquer localização] [qualquer microorganismo]",
            favorito: false
        },
        {
            cid10: "L011",
            descricao: "Impetiginização de outras dermatoses",
            favorito: false
        },
        {
            cid10: "L020",
            descricao: "Abscesso cutâneo, furúnculo e antraz da face",
            favorito: false
        },
        {
            cid10: "L021",
            descricao: "Abscesso cutâneo, furúnculo e antraz do pescoço",
            favorito: false
        },
        {
            cid10: "L022",
            descricao: "Abscesso cutâneo, furúnculo e antraz do tronco",
            favorito: false
        },
        {
            cid10: "L023",
            descricao: "Abscesso cutâneo, furúnculo e antraz da nádega",
            favorito: false
        },
        {
            cid10: "L024",
            descricao: "Abscesso cutâneo, furúnculo e antraz do(s) membro(s)",
            favorito: false
        },
        {
            cid10: "L028",
            descricao: "Abscesso cutâneo, furúnculo e antraz de outras localizações",
            favorito: false
        },
        {
            cid10: "L029",
            descricao: "Abscesso cutâneo, furúnculo e antraz de localização não especificada",
            favorito: false
        },
        {
            cid10: "L030",
            descricao: "Celulite de dedos das mãos e dos pés",
            favorito: false
        },
        {
            cid10: "L031",
            descricao: "Celulite de outras partes do(s) membro(s)",
            favorito: false
        },
        {
            cid10: "L032",
            descricao: "Celulite da face",
            favorito: false
        },
        {
            cid10: "L033",
            descricao: "Celulite do tronco",
            favorito: false
        },
        {
            cid10: "L038",
            descricao: "Celulite de outros locais",
            favorito: false
        },
        {
            cid10: "L039",
            descricao: "Celulite não especificada",
            favorito: false
        },
        {
            cid10: "L040",
            descricao: "Linfadenite aguda de face, cabeça e pescoço",
            favorito: false
        },
        {
            cid10: "L041",
            descricao: "Linfadenite aguda do tronco",
            favorito: false
        },
        {
            cid10: "L042",
            descricao: "Linfadenite aguda de membro superior",
            favorito: false
        },
        {
            cid10: "L043",
            descricao: "Linfadenite aguda de membro inferior",
            favorito: false
        },
        {
            cid10: "L048",
            descricao: "Linfadenite aguda de outras localizações",
            favorito: false
        },
        {
            cid10: "L049",
            descricao: "Linfadenite aguda de localização não especificada",
            favorito: false
        },
        {
            cid10: "L050",
            descricao: "Cisto pilonidal com abscesso",
            favorito: false
        },
        {
            cid10: "L059",
            descricao: "Cisto pilonidal sem abscesso",
            favorito: false
        },
        {
            cid10: "L080",
            descricao: "Piodermite",
            favorito: false
        },
        {
            cid10: "L081",
            descricao: "Eritrasma",
            favorito: false
        },
        {
            cid10: "L088",
            descricao: "Outras infecções localizadas, especificadas, da pele e do tecido subcutâneo",
            favorito: false
        },
        {
            cid10: "L089",
            descricao: "Infecção localizada da pele e do tecido subcutâneo, não especificada",
            favorito: false
        },
        {
            cid10: "L100",
            descricao: "Pênfigo vulgar",
            favorito: false
        },
        {
            cid10: "L101",
            descricao: "Pênfigo vegetante",
            favorito: false
        },
        {
            cid10: "L102",
            descricao: "Pênfigo foliáceo",
            favorito: false
        },
        {
            cid10: "L103",
            descricao: "Pênfigo brasileiro [fogo selvagem]",
            favorito: false
        },
        {
            cid10: "L104",
            descricao: "Pênfigo eritematoso",
            favorito: false
        },
        {
            cid10: "L105",
            descricao: "Pênfigo induzido por drogas",
            favorito: false
        },
        {
            cid10: "L108",
            descricao: "Outras formas de pênfigo",
            favorito: false
        },
        {
            cid10: "L109",
            descricao: "Pênfigo, não especificado",
            favorito: false
        },
        {
            cid10: "L110",
            descricao: "Ceratose folicular adquirida",
            favorito: false
        },
        {
            cid10: "L111",
            descricao: "Dermatose acantolítica transitória [Grover]",
            favorito: false
        },
        {
            cid10: "L118",
            descricao: "Outras afecções acantolíticas especificadas",
            favorito: false
        },
        {
            cid10: "L119",
            descricao: "Afecções acantolíticas não especificadas",
            favorito: false
        },
        {
            cid10: "L120",
            descricao: "Penfigóide bolhoso",
            favorito: false
        },
        {
            cid10: "L121",
            descricao: "Penfigóide cicatricial",
            favorito: false
        },
        {
            cid10: "L122",
            descricao: "Doença bolhosa crônica da infância",
            favorito: false
        },
        {
            cid10: "L123",
            descricao: "Epidermólise bolhosa adquirida",
            favorito: false
        },
        {
            cid10: "L128",
            descricao: "Outras formas de penfigóide",
            favorito: false
        },
        {
            cid10: "L129",
            descricao: "Penfigóide, não especificado",
            favorito: false
        },
        {
            cid10: "L130",
            descricao: "Dermatite herpetiforme",
            favorito: false
        },
        {
            cid10: "L131",
            descricao: "Dermatite pustular subcorneana",
            favorito: false
        },
        {
            cid10: "L138",
            descricao: "Outras afecções bolhosas especificadas",
            favorito: false
        },
        {
            cid10: "L139",
            descricao: "Doença bolhosa, não especificada",
            favorito: false
        },
        {
            cid10: "L14",
            descricao: "Afecções bolhosas em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "L200",
            descricao: "Prurigo de Besnier",
            favorito: false
        },
        {
            cid10: "L208",
            descricao: "Outras dermatites atópicas",
            favorito: false
        },
        {
            cid10: "L209",
            descricao: "Dermatite atópica, não especificada",
            favorito: false
        },
        {
            cid10: "L210",
            descricao: "Seborréia do couro cabeludo",
            favorito: false
        },
        {
            cid10: "L211",
            descricao: "Dermatite seborréica infantil",
            favorito: false
        },
        {
            cid10: "L218",
            descricao: "Outras dermatites seborréicas",
            favorito: false
        },
        {
            cid10: "L219",
            descricao: "Dermatite seborréica, não especificada",
            favorito: false
        },
        {
            cid10: "L22",
            descricao: "Dermatite das fraldas",
            favorito: false
        },
        {
            cid10: "L230",
            descricao: "Dermatite alérgica de contato devida a metais",
            favorito: false
        },
        {
            cid10: "L231",
            descricao: "Dermatite alérgica de contato devida a adesivos",
            favorito: false
        },
        {
            cid10: "L232",
            descricao: "Dermatite alérgica de contato devida a cosméticos",
            favorito: false
        },
        {
            cid10: "L233",
            descricao: "Dermatite alérgica de contato devido a drogas em contato com a pele",
            favorito: false
        },
        {
            cid10: "L234",
            descricao: "Dermatite alérgica de contato devida a corantes",
            favorito: false
        },
        {
            cid10: "L235",
            descricao: "Dermatite alérgica de contato devida a outros produtos químicos",
            favorito: false
        },
        {
            cid10: "L236",
            descricao: "Dermatite alérgica de contato devida a alimentos em contato com a pele",
            favorito: false
        },
        {
            cid10: "L237",
            descricao: "Dermatite alérgica de contato devido a plantas, exceto alimentos",
            favorito: false
        },
        {
            cid10: "L238",
            descricao: "Dermatite alérgica de contato devido a outros agentes",
            favorito: false
        },
        {
            cid10: "L239",
            descricao: "Dermatite alérgica de contato, de causa não especificada",
            favorito: false
        },
        {
            cid10: "L240",
            descricao: "Dermatite de contato por irritantes devida a detergentes",
            favorito: false
        },
        {
            cid10: "L241",
            descricao: "Dermatite de contato por irritantes devido a óleos e gorduras",
            favorito: false
        },
        {
            cid10: "L242",
            descricao: "Dermatite de contato por irritantes devida a solventes",
            favorito: false
        },
        {
            cid10: "L243",
            descricao: "Dermatite de contato por irritantes devida a cosméticos",
            favorito: false
        },
        {
            cid10: "L244",
            descricao: "Dermatite de contato por irritantes devida a drogas em contato com a pele",
            favorito: false
        },
        {
            cid10: "L245",
            descricao: "Dermatite de contato por irritantes devida a outros produtos químicos",
            favorito: false
        },
        {
            cid10: "L246",
            descricao: "Dermatite de contato por irritantes devida a alimentos em contato com a pele",
            favorito: false
        },
        {
            cid10: "L247",
            descricao: "Dermatite de contato por irritantes devido a plantas, exceto alimentos",
            favorito: false
        },
        {
            cid10: "L248",
            descricao: "Dermatite de contato por irritantes devido a outros agentes",
            favorito: false
        },
        {
            cid10: "L249",
            descricao: "Dermatite de contato por irritantes, de causa não especificada",
            favorito: false
        },
        {
            cid10: "L250",
            descricao: "Dermatite de contato não especificada devida a cosméticos",
            favorito: false
        },
        {
            cid10: "L251",
            descricao: "Dermatite de contato não especificada devida a drogas em contato com a pele",
            favorito: false
        },
        {
            cid10: "L252",
            descricao: "Dermatite de contato não especificada devida a corantes",
            favorito: false
        },
        {
            cid10: "L253",
            descricao: "Dermatite de contato não especificada devida a outros produtos químicos",
            favorito: false
        },
        {
            cid10: "L254",
            descricao: "Dermatite de contato não especificada devida a alimentos em contato com a pele",
            favorito: false
        },
        {
            cid10: "L255",
            descricao: "Dermatite de contato não especificada devida a plantas, exceto alimentos",
            favorito: false
        },
        {
            cid10: "L258",
            descricao: "Dermatite de contato não especificada devida a outros agentes",
            favorito: false
        },
        {
            cid10: "L259",
            descricao: "Dermatite de contato não especificada, de causa não especificada",
            favorito: false
        },
        {
            cid10: "L26",
            descricao: "Dermatite esfoliativa",
            favorito: false
        },
        {
            cid10: "L270",
            descricao: "Erupção cutânea generalizada devida a drogas e medicamentos",
            favorito: false
        },
        {
            cid10: "L271",
            descricao: "Erupção cutânea localizada devida a drogas e medicamentos",
            favorito: false
        },
        {
            cid10: "L272",
            descricao: "Dermatite devida à ingestão de alimentos",
            favorito: false
        },
        {
            cid10: "L278",
            descricao: "Dermatite devida a outras substâncias de uso interno",
            favorito: false
        },
        {
            cid10: "L279",
            descricao: "Dermatite devida a substância não especificada de uso interno",
            favorito: false
        },
        {
            cid10: "L280",
            descricao: "Líquen simples crônico",
            favorito: false
        },
        {
            cid10: "L281",
            descricao: "Prurigo nodular",
            favorito: false
        },
        {
            cid10: "L282",
            descricao: "Outras formas de prurigo",
            favorito: false
        },
        {
            cid10: "L290",
            descricao: "Prurido anal",
            favorito: false
        },
        {
            cid10: "L291",
            descricao: "Prurido escrotal",
            favorito: false
        },
        {
            cid10: "L292",
            descricao: "Prurido vulvar",
            favorito: false
        },
        {
            cid10: "L293",
            descricao: "Prurido anogenital, não especificado",
            favorito: false
        },
        {
            cid10: "L298",
            descricao: "Outras formas de prurido",
            favorito: false
        },
        {
            cid10: "L299",
            descricao: "Prurido não especificado",
            favorito: false
        },
        {
            cid10: "L300",
            descricao: "Dermatite numular",
            favorito: false
        },
        {
            cid10: "L301",
            descricao: "Disidrose [pompholyx]",
            favorito: false
        },
        {
            cid10: "L302",
            descricao: "Autossensibilização cutânea",
            favorito: false
        },
        {
            cid10: "L303",
            descricao: "Dermatite infectada",
            favorito: false
        },
        {
            cid10: "L304",
            descricao: "Intertrigo eritematoso",
            favorito: false
        },
        {
            cid10: "L305",
            descricao: "Pitiríase alba",
            favorito: false
        },
        {
            cid10: "L308",
            descricao: "Outras dermatites especificadas",
            favorito: false
        },
        {
            cid10: "L309",
            descricao: "Dermatite não especificada",
            favorito: false
        },
        {
            cid10: "L400",
            descricao: "Psoríase vulgar",
            favorito: false
        },
        {
            cid10: "L401",
            descricao: "Psoríase pustulosa generalizada",
            favorito: false
        },
        {
            cid10: "L402",
            descricao: "Acrodermatite contínua",
            favorito: false
        },
        {
            cid10: "L403",
            descricao: "Pustulose palmar e plantar",
            favorito: false
        },
        {
            cid10: "L404",
            descricao: "Psoríase gutata",
            favorito: false
        },
        {
            cid10: "L405",
            descricao: "Artropatia psoriásica",
            favorito: false
        },
        {
            cid10: "L408",
            descricao: "Outras formas de psoríase",
            favorito: false
        },
        {
            cid10: "L409",
            descricao: "Psoríase não especificada",
            favorito: false
        },
        {
            cid10: "L410",
            descricao: "Pitiríase liquenóide e varioliforme aguda",
            favorito: false
        },
        {
            cid10: "L411",
            descricao: "Pitiríase liquenóide crônica",
            favorito: false
        },
        {
            cid10: "L412",
            descricao: "Papulose linfomatóide",
            favorito: false
        },
        {
            cid10: "L413",
            descricao: "Parapsoríase de pequenas placas",
            favorito: false
        },
        {
            cid10: "L414",
            descricao: "Parapsoríase de grandes placas",
            favorito: false
        },
        {
            cid10: "L415",
            descricao: "Parapsoríase retiforme",
            favorito: false
        },
        {
            cid10: "L418",
            descricao: "Outras parapsoríases",
            favorito: false
        },
        {
            cid10: "L419",
            descricao: "Parapsoríase, não especificada",
            favorito: false
        },
        {
            cid10: "L42",
            descricao: "Pitiríase rósea",
            favorito: false
        },
        {
            cid10: "L430",
            descricao: "Líquen plano hipertrófico",
            favorito: false
        },
        {
            cid10: "L431",
            descricao: "Líquen plano bolhoso",
            favorito: false
        },
        {
            cid10: "L432",
            descricao: "Reação liquenóide a droga",
            favorito: false
        },
        {
            cid10: "L433",
            descricao: "Líquen plano subagudo (ativo)",
            favorito: false
        },
        {
            cid10: "L438",
            descricao: "Outras formas de líquen plano",
            favorito: false
        },
        {
            cid10: "L439",
            descricao: "Líquen plano, não especificado",
            favorito: false
        },
        {
            cid10: "L440",
            descricao: "Pitiríase rubra pilar",
            favorito: false
        },
        {
            cid10: "L441",
            descricao: "Líquen nítido",
            favorito: false
        },
        {
            cid10: "L442",
            descricao: "Líquen estriado",
            favorito: false
        },
        {
            cid10: "L443",
            descricao: "Líquen rubro moniliforme",
            favorito: false
        },
        {
            cid10: "L444",
            descricao: "Acrodermatite papular infantil [Síndrome de Gianotti-Crosti]",
            favorito: false
        },
        {
            cid10: "L448",
            descricao: "Outras afecções pápulo-descamativas especificadas",
            favorito: false
        },
        {
            cid10: "L449",
            descricao: "Afecções pápulo-descamativas, não especificadas",
            favorito: false
        },
        {
            cid10: "L45",
            descricao: "Afecções pápulo-descamativas em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "L500",
            descricao: "Urticária alérgica",
            favorito: false
        },
        {
            cid10: "L501",
            descricao: "Urticária idiopática",
            favorito: false
        },
        {
            cid10: "L502",
            descricao: "Urticária devida a frio e a calor",
            favorito: false
        },
        {
            cid10: "L503",
            descricao: "Urticária dermatográfica",
            favorito: false
        },
        {
            cid10: "L504",
            descricao: "Urticária vibratória",
            favorito: false
        },
        {
            cid10: "L505",
            descricao: "Urticária colinérgica",
            favorito: false
        },
        {
            cid10: "L506",
            descricao: "Urticária de contato",
            favorito: false
        },
        {
            cid10: "L508",
            descricao: "Outras urticárias",
            favorito: false
        },
        {
            cid10: "L509",
            descricao: "Urticária não especificada",
            favorito: false
        },
        {
            cid10: "L510",
            descricao: "Eritema multiforme não bolhoso",
            favorito: false
        },
        {
            cid10: "L511",
            descricao: "Eritema multiforme bolhoso",
            favorito: false
        },
        {
            cid10: "L512",
            descricao: "Necrólise epidérmica tóxica [Síndrome de Lyell]",
            favorito: false
        },
        {
            cid10: "L518",
            descricao: "Outras formas de eritema multiforme",
            favorito: false
        },
        {
            cid10: "L519",
            descricao: "Eritema multiforme, não especificado",
            favorito: false
        },
        {
            cid10: "L52",
            descricao: "Eritema nodoso",
            favorito: false
        },
        {
            cid10: "L530",
            descricao: "Eritema tóxico",
            favorito: false
        },
        {
            cid10: "L531",
            descricao: "Eritema anular centrífugo",
            favorito: false
        },
        {
            cid10: "L532",
            descricao: "Eritema marginado",
            favorito: false
        },
        {
            cid10: "L533",
            descricao: "Outras formas de eritema figurado crônico",
            favorito: false
        },
        {
            cid10: "L538",
            descricao: "Outras afecções eritematosas especificadas",
            favorito: false
        },
        {
            cid10: "L539",
            descricao: "Afecções eritematosas, não especificadas",
            favorito: false
        },
        {
            cid10: "L540",
            descricao: "Eritema marginado na febre reumática aguda",
            favorito: false
        },
        {
            cid10: "L548",
            descricao: "Eritema em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "L550",
            descricao: "Queimadura solar de primeiro grau",
            favorito: false
        },
        {
            cid10: "L551",
            descricao: "Queimadura solar de segundo grau",
            favorito: false
        },
        {
            cid10: "L552",
            descricao: "Queimadura solar de terceiro grau",
            favorito: false
        },
        {
            cid10: "L558",
            descricao: "Outras queimaduras solares",
            favorito: false
        },
        {
            cid10: "L559",
            descricao: "Queimadura solar, não especificada",
            favorito: false
        },
        {
            cid10: "L560",
            descricao: "Resposta fototóxica a drogas",
            favorito: false
        },
        {
            cid10: "L561",
            descricao: "Resposta fotoalérgica a drogas",
            favorito: false
        },
        {
            cid10: "L562",
            descricao: "Dermatite por fotocontato [dermatite do berloque]",
            favorito: false
        },
        {
            cid10: "L563",
            descricao: "Urticária solar",
            favorito: false
        },
        {
            cid10: "L564",
            descricao: "Erupção polimorfa à luz",
            favorito: false
        },
        {
            cid10: "L568",
            descricao: "Outras alterações agudas especificadas da pele devidas a radiação ultravioleta",
            favorito: false
        },
        {
            cid10: "L569",
            descricao: "Alterações agudas da pele devidas a radiação ultravioleta, não especificadas",
            favorito: false
        },
        {
            cid10: "L570",
            descricao: "Ceratose actínica",
            favorito: false
        },
        {
            cid10: "L571",
            descricao: "Reticulóide actínica",
            favorito: false
        },
        {
            cid10: "L572",
            descricao: "Cútis romboidal da nuca",
            favorito: false
        },
        {
            cid10: "L573",
            descricao: "Poiquilodermia de Civatte",
            favorito: false
        },
        {
            cid10: "L574",
            descricao: "Cútis laxa senil",
            favorito: false
        },
        {
            cid10: "L575",
            descricao: "Granuloma actínico",
            favorito: false
        },
        {
            cid10: "L578",
            descricao: "Outras alterações da pele devidas a exposição crônica à radiação não-ionizante",
            favorito: false
        },
        {
            cid10: "L579",
            descricao: "Alterações da pele devidas a exposição crônica a radiação não-ionizante, não especificadas",
            favorito: false
        },
        {
            cid10: "L580",
            descricao: "Radiodermatite aguda",
            favorito: false
        },
        {
            cid10: "L581",
            descricao: "Radiodermatite crônica",
            favorito: false
        },
        {
            cid10: "L589",
            descricao: "Radiodermatite, não especificada",
            favorito: false
        },
        {
            cid10: "L590",
            descricao: "Eritema devido ao calor ou ao fogo [dermatite ab igne]",
            favorito: false
        },
        {
            cid10: "L598",
            descricao: "Outras afecções especificadas da pele e do tecido subcutâneo relacionadas com a radiação",
            favorito: false
        },
        {
            cid10: "L599",
            descricao: "Afecções da pele e do tecido subcutâneo relacionadas com a radiação, não especificadas",
            favorito: false
        },
        {
            cid10: "L600",
            descricao: "Unha encravada",
            favorito: false
        },
        {
            cid10: "L601",
            descricao: "Onicólise",
            favorito: false
        },
        {
            cid10: "L602",
            descricao: "Onicogrifose",
            favorito: false
        },
        {
            cid10: "L603",
            descricao: "Distrofia ungueal",
            favorito: false
        },
        {
            cid10: "L604",
            descricao: "Linhas de Beau",
            favorito: false
        },
        {
            cid10: "L605",
            descricao: "Síndrome das unhas amareladas",
            favorito: false
        },
        {
            cid10: "L608",
            descricao: "Outras afecções das unhas",
            favorito: false
        },
        {
            cid10: "L609",
            descricao: "Afecções das unhas, não especificadas",
            favorito: false
        },
        {
            cid10: "L620",
            descricao: "Paquidermoperiostose com unhas hipocráticas (em vidro de relógio)",
            favorito: false
        },
        {
            cid10: "L628",
            descricao: "Afecções das unhas em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "L630",
            descricao: "Alopécia total (capitis)",
            favorito: false
        },
        {
            cid10: "L631",
            descricao: "Alopécia universal",
            favorito: false
        },
        {
            cid10: "L632",
            descricao: "Ofíase",
            favorito: false
        },
        {
            cid10: "L638",
            descricao: "Outras formas de alopécia areata",
            favorito: false
        },
        {
            cid10: "L639",
            descricao: "Alopécia areata, não especificada",
            favorito: false
        },
        {
            cid10: "L640",
            descricao: "Alopécia androgênica induzida por droga",
            favorito: false
        },
        {
            cid10: "L648",
            descricao: "Outras alopécias androgênicas",
            favorito: false
        },
        {
            cid10: "L649",
            descricao: "Alopécia androgênica, não especificada",
            favorito: false
        },
        {
            cid10: "L650",
            descricao: "Eflúvio telógeno",
            favorito: false
        },
        {
            cid10: "L651",
            descricao: "Eflúvio anágeno",
            favorito: false
        },
        {
            cid10: "L652",
            descricao: "Alopécia mucinosa",
            favorito: false
        },
        {
            cid10: "L658",
            descricao: "Outras formas, especificadas, não cicatriciais, de perda de cabelos ou pêlos",
            favorito: false
        },
        {
            cid10: "L659",
            descricao: "Perda de cabelos ou pêlos não cicatriciais, não especificada",
            favorito: false
        },
        {
            cid10: "L660",
            descricao: "Pseudopelada",
            favorito: false
        },
        {
            cid10: "L661",
            descricao: "Líquen planopilar",
            favorito: false
        },
        {
            cid10: "L662",
            descricao: "Foliculite descalvante",
            favorito: false
        },
        {
            cid10: "L663",
            descricao: "Perifoliculite abscedante da cabeça",
            favorito: false
        },
        {
            cid10: "L664",
            descricao: "Foliculite uleritematosa reticulada",
            favorito: false
        },
        {
            cid10: "L668",
            descricao: "Outras formas de alopécia cicatricial",
            favorito: false
        },
        {
            cid10: "L669",
            descricao: "Alopécia cicatricial, não especificada",
            favorito: false
        },
        {
            cid10: "L670",
            descricao: "Tricorrexe nodosa",
            favorito: false
        },
        {
            cid10: "L671",
            descricao: "Variações da cor dos cabelos ou pêlos",
            favorito: false
        },
        {
            cid10: "L678",
            descricao: "Outras anormalidades da cor e do pedículo dos cabelos ou pêlos",
            favorito: false
        },
        {
            cid10: "L679",
            descricao: "Anormalidades da cor e do pedículo dos cabelos e dos pêlos, não especificadas",
            favorito: false
        },
        {
            cid10: "L680",
            descricao: "Hirsutismo",
            favorito: false
        },
        {
            cid10: "L681",
            descricao: "Hipertricose lanuginosa adquirida",
            favorito: false
        },
        {
            cid10: "L682",
            descricao: "Hipertricose localizada",
            favorito: false
        },
        {
            cid10: "L683",
            descricao: "Politríquia",
            favorito: false
        },
        {
            cid10: "L688",
            descricao: "Outras formas de hipertricose",
            favorito: false
        },
        {
            cid10: "L689",
            descricao: "Hipertricose, não especificada",
            favorito: false
        },
        {
            cid10: "L700",
            descricao: "Acne vulgar",
            favorito: false
        },
        {
            cid10: "L701",
            descricao: "Acne conglobata",
            favorito: false
        },
        {
            cid10: "L702",
            descricao: "Acne varioliforme",
            favorito: false
        },
        {
            cid10: "L703",
            descricao: "Acne tropical",
            favorito: false
        },
        {
            cid10: "L704",
            descricao: "Acne infantil",
            favorito: false
        },
        {
            cid10: "L705",
            descricao: "Acne escoriada das mulheres jovens",
            favorito: false
        },
        {
            cid10: "L708",
            descricao: "Outras formas de acne",
            favorito: false
        },
        {
            cid10: "L709",
            descricao: "Acne, não especificada",
            favorito: false
        },
        {
            cid10: "L710",
            descricao: "Dermatite perioral",
            favorito: false
        },
        {
            cid10: "L711",
            descricao: "Rinofima",
            favorito: false
        },
        {
            cid10: "L718",
            descricao: "Outras formas de rosácea",
            favorito: false
        },
        {
            cid10: "L719",
            descricao: "Rosácea, não especificada",
            favorito: false
        },
        {
            cid10: "L720",
            descricao: "Cisto epidérmico",
            favorito: false
        },
        {
            cid10: "L721",
            descricao: "Cisto triquilemal",
            favorito: false
        },
        {
            cid10: "L722",
            descricao: "Esteatocistoma múltiplo",
            favorito: false
        },
        {
            cid10: "L728",
            descricao: "Outras formas de cistos foliculares da pele e do tecido subcutâneo",
            favorito: false
        },
        {
            cid10: "L729",
            descricao: "Cisto folicular, não especificado da pele e do tecido subcutâneo",
            favorito: false
        },
        {
            cid10: "L730",
            descricao: "Acne quelóide",
            favorito: false
        },
        {
            cid10: "L731",
            descricao: "Pseudofoliculite da barba",
            favorito: false
        },
        {
            cid10: "L732",
            descricao: "Hidradenite supurativa",
            favorito: false
        },
        {
            cid10: "L738",
            descricao: "Outras afecções foliculares especificadas",
            favorito: false
        },
        {
            cid10: "L739",
            descricao: "Afecções foliculares, não especificadas",
            favorito: false
        },
        {
            cid10: "L740",
            descricao: "Miliária rubra",
            favorito: false
        },
        {
            cid10: "L741",
            descricao: "Miliária cristalina",
            favorito: false
        },
        {
            cid10: "L742",
            descricao: "Miliária profunda",
            favorito: false
        },
        {
            cid10: "L743",
            descricao: "Miliária, não especificada",
            favorito: false
        },
        {
            cid10: "L744",
            descricao: "Anidrose",
            favorito: false
        },
        {
            cid10: "L748",
            descricao: "Outras afecções das glândulas sudoríparas écrinas",
            favorito: false
        },
        {
            cid10: "L749",
            descricao: "Afecções das glândulas sudoríparas écrinas, não especificadas",
            favorito: false
        },
        {
            cid10: "L750",
            descricao: "Bromidrose",
            favorito: false
        },
        {
            cid10: "L751",
            descricao: "Cromidrose",
            favorito: false
        },
        {
            cid10: "L752",
            descricao: "Miliária apócrina",
            favorito: false
        },
        {
            cid10: "L758",
            descricao: "Outras afecções das glândulas sudoríparas apócrinas",
            favorito: false
        },
        {
            cid10: "L759",
            descricao: "Afecções de glândulas sudoríparas apócrinas, não especificadas",
            favorito: false
        },
        {
            cid10: "L80",
            descricao: "Vitiligo",
            favorito: false
        },
        {
            cid10: "L810",
            descricao: "Hiperpigmentação pós-inflamatória",
            favorito: false
        },
        {
            cid10: "L811",
            descricao: "Cloasma",
            favorito: false
        },
        {
            cid10: "L812",
            descricao: "Sardas",
            favorito: false
        },
        {
            cid10: "L813",
            descricao: "Manchas café-com-leite",
            favorito: false
        },
        {
            cid10: "L814",
            descricao: "Outras formas de hiperpigmentação pela melanina",
            favorito: false
        },
        {
            cid10: "L815",
            descricao: "Leucodermia não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "L816",
            descricao: "Outros transtornos ligados à diminuição da formação de melanina",
            favorito: false
        },
        {
            cid10: "L817",
            descricao: "Dermatose purpúrica pigmentada",
            favorito: false
        },
        {
            cid10: "L818",
            descricao: "Outros transtornos especificados da pigmentação",
            favorito: false
        },
        {
            cid10: "L819",
            descricao: "Transtorno da pigmentação, não especificado",
            favorito: false
        },
        {
            cid10: "L82",
            descricao: "Ceratose seborréica",
            favorito: false
        },
        {
            cid10: "L83",
            descricao: "Acantose nigricans",
            favorito: false
        },
        {
            cid10: "L84",
            descricao: "Calos e calosidades",
            favorito: false
        },
        {
            cid10: "L850",
            descricao: "Ictiose adquirida",
            favorito: false
        },
        {
            cid10: "L851",
            descricao: "Ceratose adquirida [ceratodermia] palmar e plantar",
            favorito: false
        },
        {
            cid10: "L852",
            descricao: "Ceratose punctata (palmar e plantar)",
            favorito: false
        },
        {
            cid10: "L853",
            descricao: "Xerose cutânea",
            favorito: false
        },
        {
            cid10: "L858",
            descricao: "Outras formas de espessamento epidérmico",
            favorito: false
        },
        {
            cid10: "L859",
            descricao: "Espessamento epidérmico, não especificado",
            favorito: false
        },
        {
            cid10: "L86",
            descricao: "Ceratodermia em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "L870",
            descricao: "Ceratose folicular e parafolicular penetrante na pele [Síndrome de Kyrle]",
            favorito: false
        },
        {
            cid10: "L871",
            descricao: "Colagenose perfurante reativa",
            favorito: false
        },
        {
            cid10: "L872",
            descricao: "Elastose perfurante serpiginosa",
            favorito: false
        },
        {
            cid10: "L878",
            descricao: "Outros transtornos da eliminação transepidérmica",
            favorito: false
        },
        {
            cid10: "L879",
            descricao: "Transtorno da eliminação transepidérmica, não especificado",
            favorito: false
        },
        {
            cid10: "L88",
            descricao: "Piodermite gangrenosa",
            favorito: false
        },
        {
            cid10: "L89",
            descricao: "Úlcera de decúbito",
            favorito: false
        },
        {
            cid10: "L900",
            descricao: "Líquen escleroso e atrófico",
            favorito: false
        },
        {
            cid10: "L901",
            descricao: "Anetodermia de Schweninger-Buzzi",
            favorito: false
        },
        {
            cid10: "L902",
            descricao: "Anetodermia de Jadassohn-Pellizzari",
            favorito: false
        },
        {
            cid10: "L903",
            descricao: "Atrofodermia de Pasini e Pierini",
            favorito: false
        },
        {
            cid10: "L904",
            descricao: "Acrodermatite crônica atrófica",
            favorito: false
        },
        {
            cid10: "L905",
            descricao: "Cicatrizes e fibrose cutânea",
            favorito: false
        },
        {
            cid10: "L906",
            descricao: "Estrias atróficas",
            favorito: false
        },
        {
            cid10: "L908",
            descricao: "Outras afecções atróficas da pele",
            favorito: false
        },
        {
            cid10: "L909",
            descricao: "Afecções atróficas da pele, não especificadas",
            favorito: false
        },
        {
            cid10: "L910",
            descricao: "Cicatriz quelóide",
            favorito: false
        },
        {
            cid10: "L918",
            descricao: "Outras afecções hipertróficas da pele",
            favorito: false
        },
        {
            cid10: "L919",
            descricao: "Afecções hipertróficas da pele, não especificadas",
            favorito: false
        },
        {
            cid10: "L920",
            descricao: "Granuloma anular",
            favorito: false
        },
        {
            cid10: "L921",
            descricao: "Necrobiose lipoídica não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "L922",
            descricao: "Granuloma facial [granuloma eosinófilo da pele]",
            favorito: false
        },
        {
            cid10: "L923",
            descricao: "Granuloma de corpo estranho da pele e do tecido subcutâneo",
            favorito: false
        },
        {
            cid10: "L928",
            descricao: "Outras afecções granulomatosas da pele e do tecido subcutâneo",
            favorito: false
        },
        {
            cid10: "L929",
            descricao: "Afecções granulomatosas da pele e do tecido subcutâneo, não especificados",
            favorito: false
        },
        {
            cid10: "L930",
            descricao: "Lúpus eritematoso discóide",
            favorito: false
        },
        {
            cid10: "L931",
            descricao: "Lúpus eritematoso cutâneo subagudo",
            favorito: false
        },
        {
            cid10: "L932",
            descricao: "Outras formas de lúpus eritematoso localizado",
            favorito: false
        },
        {
            cid10: "L940",
            descricao: "Esclerodermia localizada [morféia]",
            favorito: false
        },
        {
            cid10: "L941",
            descricao: "Esclerodermia linear",
            favorito: false
        },
        {
            cid10: "L942",
            descricao: "Calcinose cutânea",
            favorito: false
        },
        {
            cid10: "L943",
            descricao: "Esclerodactilia",
            favorito: false
        },
        {
            cid10: "L944",
            descricao: "Pápulas de Gottron",
            favorito: false
        },
        {
            cid10: "L945",
            descricao: "Poiquilodermia vascular atrófica",
            favorito: false
        },
        {
            cid10: "L946",
            descricao: "Ainhum",
            favorito: false
        },
        {
            cid10: "L948",
            descricao: "Outras afecções localizadas do tecido conjuntivo, especificadas",
            favorito: false
        },
        {
            cid10: "L949",
            descricao: "Afecções localizadas do tecido conjuntivo, não especificadas",
            favorito: false
        },
        {
            cid10: "L950",
            descricao: "Vasculite livedóide",
            favorito: false
        },
        {
            cid10: "L951",
            descricao: "Eritema elevado diutino (duradouro)",
            favorito: false
        },
        {
            cid10: "L958",
            descricao: "Outras vasculites limitadas a pele",
            favorito: false
        },
        {
            cid10: "L959",
            descricao: "Vasculites limitadas a pele, não especificadas",
            favorito: false
        },
        {
            cid10: "L97",
            descricao: "Úlcera dos membros inferiores não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "L980",
            descricao: "Granuloma piogênico",
            favorito: false
        },
        {
            cid10: "L981",
            descricao: "Dermatite factícia",
            favorito: false
        },
        {
            cid10: "L982",
            descricao: "Dermatose neutrofílica febril [Sweet]",
            favorito: false
        },
        {
            cid10: "L983",
            descricao: "Celulite eosinofílica [Wells]",
            favorito: false
        },
        {
            cid10: "L984",
            descricao: "Úlcera crônica da pele, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "L985",
            descricao: "Mucinose da pele",
            favorito: false
        },
        {
            cid10: "L986",
            descricao: "Outras afecções infiltrativas da pele e do tecido subcutâneo",
            favorito: false
        },
        {
            cid10: "L988",
            descricao: "Outras afecções especificadas da pele e do tecido subcutâneo",
            favorito: false
        },
        {
            cid10: "L989",
            descricao: "Afecções da pele e do tecido subcutâneo, não especificados",
            favorito: false
        },
        {
            cid10: "L990",
            descricao: "Amiloidose cutânea",
            favorito: false
        },
        {
            cid10: "L998",
            descricao: "Outras afecções especificadas da pele e do tecido subcutâneo em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M000",
            descricao: "Artrite e pioliartrite estafilocócicas",
            favorito: false
        },
        {
            cid10: "M001",
            descricao: "Artrite e poliartrite pneumocócicas",
            favorito: false
        },
        {
            cid10: "M002",
            descricao: "Outras artrites e poliartrites estreptocócicas",
            favorito: false
        },
        {
            cid10: "M008",
            descricao: "Artrite e poliartrite devidas a outro agente bacteriano especificado",
            favorito: false
        },
        {
            cid10: "M009",
            descricao: "Artrite piogênica, não especificada",
            favorito: false
        },
        {
            cid10: "M010",
            descricao: "Artrite meningocócica",
            favorito: false
        },
        {
            cid10: "M011",
            descricao: "Artrite tuberculosa",
            favorito: false
        },
        {
            cid10: "M012",
            descricao: "Artrite na doença de Lyme",
            favorito: false
        },
        {
            cid10: "M013",
            descricao: "Artrite em outras doenças bacterianas classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M014",
            descricao: "Artrite na rubéola",
            favorito: false
        },
        {
            cid10: "M015",
            descricao: "Artrite em outras doenças virais classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M016",
            descricao: "Artrite em micoses",
            favorito: false
        },
        {
            cid10: "M018",
            descricao: "Artrite em outras doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M020",
            descricao: "Artropatia pós-derivação intestinal",
            favorito: false
        },
        {
            cid10: "M021",
            descricao: "Artropatia pós-desintérica",
            favorito: false
        },
        {
            cid10: "M022",
            descricao: "Artropatia pós-imunização",
            favorito: false
        },
        {
            cid10: "M023",
            descricao: "Doença de Reiter",
            favorito: false
        },
        {
            cid10: "M028",
            descricao: "Outras artropatias reacionais",
            favorito: false
        },
        {
            cid10: "M029",
            descricao: "Artropatia reacional não especificada",
            favorito: false
        },
        {
            cid10: "M030",
            descricao: "Artrite pós-meningocócica",
            favorito: false
        },
        {
            cid10: "M031",
            descricao: "Artropatia pós-infecciosa na sífilis",
            favorito: false
        },
        {
            cid10: "M032",
            descricao: "Outras artropatias pós-infecciosas em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M036",
            descricao: "Artropatia reacional em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M050",
            descricao: "Síndrome de Felty",
            favorito: false
        },
        {
            cid10: "M051",
            descricao: "Doença reumatóide do pulmão",
            favorito: false
        },
        {
            cid10: "M052",
            descricao: "Vasculite reumatóide",
            favorito: false
        },
        {
            cid10: "M053",
            descricao: "Artrite reumatóide com comprometimento de outros órgãos e sistemas",
            favorito: false
        },
        {
            cid10: "M058",
            descricao: "Outras artrites reumatóides soro-positivas",
            favorito: true
        },
        {
            cid10: "M059",
            descricao: "Artrite reumatóide soro-positiva não especificada",
            favorito: false
        },
        {
            cid10: "M060",
            descricao: "Artrite reumatóide soro-negativa",
            favorito: true
        },
        {
            cid10: "M061",
            descricao: "Doença de Still do adulto",
            favorito: false
        },
        {
            cid10: "M062",
            descricao: "Bursite reumatóide",
            favorito: false
        },
        {
            cid10: "M063",
            descricao: "Nódulo reumatóide",
            favorito: false
        },
        {
            cid10: "M064",
            descricao: "Poliartropatia inflamatória",
            favorito: false
        },
        {
            cid10: "M068",
            descricao: "Outras artrites reumatóides especificadas",
            favorito: true
        },
        {
            cid10: "M069",
            descricao: "Artrite reumatóide não especificada",
            favorito: false
        },
        {
            cid10: "M070",
            descricao: "Artropatia psoriásica interfalangiana distal",
            favorito: true
        },
        {
            cid10: "M071",
            descricao: "Artrite mutilante",
            favorito: false
        },
        {
            cid10: "M072",
            descricao: "Espondilite psoriásica",
            favorito: false
        },
        {
            cid10: "M073",
            descricao: "Outras artropatias psoriásicas",
            favorito: true
        },
        {
            cid10: "M074",
            descricao: "Artropatia na doença de Crohn [enterite regional]",
            favorito: false
        },
        {
            cid10: "M075",
            descricao: "Artropatia na colite ulcerativa",
            favorito: false
        },
        {
            cid10: "M076",
            descricao: "Outras artropatias enteropáticas",
            favorito: false
        },
        {
            cid10: "M080",
            descricao: "Artrite reumatóide juvenil",
            favorito: true
        },
        {
            cid10: "M081",
            descricao: "Espondilite ancilosante juvenil",
            favorito: false
        },
        {
            cid10: "M082",
            descricao: "Artrite juvenil com início sistêmico",
            favorito: false
        },
        {
            cid10: "M083",
            descricao: "Poliartrite juvenil (soro-negativa)",
            favorito: false
        },
        {
            cid10: "M084",
            descricao: "Artrite juvenil pauciarticular",
            favorito: false
        },
        {
            cid10: "M088",
            descricao: "Outras artrites juvenis",
            favorito: true
        },
        {
            cid10: "M089",
            descricao: "Artrite juvenil não especificada",
            favorito: false
        },
        {
            cid10: "M090",
            descricao: "Artrite juvenil na psoríase",
            favorito: false
        },
        {
            cid10: "M091",
            descricao: "Artrite juvenil na doença de Chron [enterite regional]",
            favorito: false
        },
        {
            cid10: "M092",
            descricao: "Artrite juvenil na colite ulcerativa",
            favorito: false
        },
        {
            cid10: "M098",
            descricao: "Artrite juvenil em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M100",
            descricao: "Gota idiopática",
            favorito: true
        },
        {
            cid10: "M101",
            descricao: "Gota induzida por chumbo",
            favorito: false
        },
        {
            cid10: "M102",
            descricao: "Gota induzida por drogas",
            favorito: false
        },
        {
            cid10: "M103",
            descricao: "Gota devida à disfunção renal",
            favorito: false
        },
        {
            cid10: "M104",
            descricao: "Outra gota secundária",
            favorito: false
        },
        {
            cid10: "M109",
            descricao: "Gota, não especificada",
            favorito: false
        },
        {
            cid10: "M110",
            descricao: "Doença por deposição de hidroxiapatita",
            favorito: false
        },
        {
            cid10: "M111",
            descricao: "Condrocalcinose familiar",
            favorito: false
        },
        {
            cid10: "M112",
            descricao: "Outras condrocalcinoses",
            favorito: false
        },
        {
            cid10: "M118",
            descricao: "Outras artropatias especificadas por deposição de cristais",
            favorito: false
        },
        {
            cid10: "M119",
            descricao: "Artropatia por deposição de cristais, não especificada",
            favorito: false
        },
        {
            cid10: "M120",
            descricao: "Artropatia pós-reumática crônica [Jaccoud]",
            favorito: false
        },
        {
            cid10: "M121",
            descricao: "Doença de Kashin-Beck",
            favorito: false
        },
        {
            cid10: "M122",
            descricao: "Sinovite vilonodular (pigmentada)",
            favorito: false
        },
        {
            cid10: "M123",
            descricao: "Reumatismo palindrômico",
            favorito: false
        },
        {
            cid10: "M124",
            descricao: "Hidrartrose intermitente",
            favorito: false
        },
        {
            cid10: "M125",
            descricao: "Artropatia traumática",
            favorito: false
        },
        {
            cid10: "M128",
            descricao: "Outras artropatias específicas não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M130",
            descricao: "Poliartrite não especificada",
            favorito: false
        },
        {
            cid10: "M131",
            descricao: "Monoartrites não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M138",
            descricao: "Outras artrites especificadas",
            favorito: false
        },
        {
            cid10: "M139",
            descricao: "Artrite não especificada",
            favorito: false
        },
        {
            cid10: "M140",
            descricao: "Artropatia gotosa devida a defeitos enzimáticos e a outras doenças hereditárias",
            favorito: false
        },
        {
            cid10: "M141",
            descricao: "Artropatia por depósito de cristais em outras doenças metabólicas classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M142",
            descricao: "Artropatia diabética",
            favorito: false
        },
        {
            cid10: "M143",
            descricao: "Dermatoartrite lipóide",
            favorito: false
        },
        {
            cid10: "M144",
            descricao: "Artropatia na amiloidose",
            favorito: false
        },
        {
            cid10: "M145",
            descricao: "Artropatias em outras doenças endócrinas, nutricionais e metabólicas",
            favorito: false
        },
        {
            cid10: "M146",
            descricao: "Artropatia neuropática",
            favorito: false
        },
        {
            cid10: "M148",
            descricao: "Artropatias em outras doenças especificadas classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M150",
            descricao: "(Osteo)artrose primária generalizada",
            favorito: true
        },
        {
            cid10: "M151",
            descricao: "Nódulos de Heberden (com artropatia)",
            favorito: false
        },
        {
            cid10: "M152",
            descricao: "Nódulos de Bouchard (com artropatia)",
            favorito: false
        },
        {
            cid10: "M153",
            descricao: "Artrose múltipla secundária",
            favorito: false
        },
        {
            cid10: "M154",
            descricao: "(Osteo)artrose erosiva",
            favorito: false
        },
        {
            cid10: "M158",
            descricao: "Outras poliartroses",
            favorito: true
        },
        {
            cid10: "M159",
            descricao: "Poliartrose não especificada",
            favorito: false
        },
        {
            cid10: "M160",
            descricao: "Coxartrose primária bilateral",
            favorito: false
        },
        {
            cid10: "M161",
            descricao: "Outras coxartroses primárias",
            favorito: false
        },
        {
            cid10: "M162",
            descricao: "Coxartrose bilateral resultante de displasia",
            favorito: false
        },
        {
            cid10: "M163",
            descricao: "Outras coxartroses displásicas",
            favorito: false
        },
        {
            cid10: "M164",
            descricao: "Coxartrose bilateral pós-traumática",
            favorito: false
        },
        {
            cid10: "M165",
            descricao: "Outras coxartroses pós-traumáticas",
            favorito: false
        },
        {
            cid10: "M166",
            descricao: "Outras coxartroses secundárias bilaterais",
            favorito: false
        },
        {
            cid10: "M167",
            descricao: "Outras coxartroses secundárias",
            favorito: false
        },
        {
            cid10: "M169",
            descricao: "Coxartrose não especificada",
            favorito: false
        },
        {
            cid10: "M170",
            descricao: "Gonartrose primária bilateral",
            favorito: false
        },
        {
            cid10: "M171",
            descricao: "Outras gonartroses primárias",
            favorito: false
        },
        {
            cid10: "M172",
            descricao: "Gonartrose pós-traumática bilateral",
            favorito: false
        },
        {
            cid10: "M173",
            descricao: "Outras gonartroses pós-traumática",
            favorito: false
        },
        {
            cid10: "M174",
            descricao: "Outras gonartroses secundárias bilaterais",
            favorito: false
        },
        {
            cid10: "M175",
            descricao: "Outras gonartroses secundárias",
            favorito: false
        },
        {
            cid10: "M179",
            descricao: "Gonartrose não especificada",
            favorito: false
        },
        {
            cid10: "M180",
            descricao: "Artrose primária bilateral das primeiras articulações carpometacarpianas",
            favorito: false
        },
        {
            cid10: "M181",
            descricao: "Outras artroses primárias da primeira articulação carpometacarpiana",
            favorito: false
        },
        {
            cid10: "M182",
            descricao: "Artrose pós-traumática bilateral da primeira articulação carpometacarpiana",
            favorito: false
        },
        {
            cid10: "M183",
            descricao: "Outras artroses pós-traumáticas da primeira articulação carpometacarpiana",
            favorito: false
        },
        {
            cid10: "M184",
            descricao: "Outras cartroses secundárias bilaterais das primeiras articulações carpometacarpianas",
            favorito: false
        },
        {
            cid10: "M185",
            descricao: "Outras artroses secundárias da primeira articulação carpometacarpiana",
            favorito: false
        },
        {
            cid10: "M189",
            descricao: "Artrose não especificada da primeira articulação carpometacarpiana",
            favorito: false
        },
        {
            cid10: "M190",
            descricao: "Artrose primária de outras articulações",
            favorito: false
        },
        {
            cid10: "M191",
            descricao: "Artrose pós-traumática de outras articulações",
            favorito: false
        },
        {
            cid10: "M192",
            descricao: "Outras artroses secundárias",
            favorito: false
        },
        {
            cid10: "M198",
            descricao: "Outras artroses especificadas",
            favorito: false
        },
        {
            cid10: "M199",
            descricao: "Artrose não especificada",
            favorito: false
        },
        {
            cid10: "M200",
            descricao: "Deformidade(s) do(s) dedo(s) das mãos",
            favorito: false
        },
        {
            cid10: "M201",
            descricao: "Hallux valgo (adquirido)",
            favorito: false
        },
        {
            cid10: "M202",
            descricao: "Hallux rigidus",
            favorito: false
        },
        {
            cid10: "M203",
            descricao: "Outra deformidade do hallux (adquirida)",
            favorito: false
        },
        {
            cid10: "M204",
            descricao: "Dedo(s) do pé em malho (adquirido)",
            favorito: false
        },
        {
            cid10: "M205",
            descricao: "Outras deformidades (adquiridas) do(s) dedo(s) dos pés",
            favorito: false
        },
        {
            cid10: "M206",
            descricao: "Deformidade adquirida não especificada de dedo(s) do pé",
            favorito: false
        },
        {
            cid10: "M210",
            descricao: "Deformidade em valgo não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "M211",
            descricao: "Deformidade em varo não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "M212",
            descricao: "Deformidade em flexão",
            favorito: false
        },
        {
            cid10: "M213",
            descricao: "Mão (pulso) ou pé pendente (adquirido)",
            favorito: false
        },
        {
            cid10: "M214",
            descricao: "Pé chato [pé plano] (adquirido)",
            favorito: false
        },
        {
            cid10: "M215",
            descricao: "Mão e pé em garra e mão e pé tortos adquiridos",
            favorito: false
        },
        {
            cid10: "M216",
            descricao: "Outras deformidades adquiridas do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "M217",
            descricao: "Desigualdade (adquirida) do comprimento dos membros",
            favorito: false
        },
        {
            cid10: "M218",
            descricao: "Outras deformidades adquiridas especificadas dos membros",
            favorito: false
        },
        {
            cid10: "M219",
            descricao: "Deformidade adquirida não especificada de membro",
            favorito: false
        },
        {
            cid10: "M220",
            descricao: "Deslocamento recidivante da rótula",
            favorito: false
        },
        {
            cid10: "M221",
            descricao: "Subluxação recidivante da rótula",
            favorito: false
        },
        {
            cid10: "M222",
            descricao: "Transtornos femuropatelares",
            favorito: false
        },
        {
            cid10: "M223",
            descricao: "Outros desarranjos da rótula",
            favorito: false
        },
        {
            cid10: "M224",
            descricao: "Condromalácia da rótula",
            favorito: false
        },
        {
            cid10: "M228",
            descricao: "Outros transtornos da rótula",
            favorito: false
        },
        {
            cid10: "M229",
            descricao: "Transtorno da rótula, não especificado",
            favorito: false
        },
        {
            cid10: "M230",
            descricao: "Menisco cístico",
            favorito: false
        },
        {
            cid10: "M231",
            descricao: "Menisco discóide (congênito)",
            favorito: false
        },
        {
            cid10: "M232",
            descricao: "Transtorno do menisco devido à ruptura ou lesão antiga",
            favorito: false
        },
        {
            cid10: "M233",
            descricao: "Outros transtornos do menisco",
            favorito: false
        },
        {
            cid10: "M234",
            descricao: "Corpo flutuante no joelho",
            favorito: false
        },
        {
            cid10: "M235",
            descricao: "Instabilidade crônica do joelho",
            favorito: false
        },
        {
            cid10: "M236",
            descricao: "Outras rupturas espontâneas de ligamento(s) do joelho",
            favorito: false
        },
        {
            cid10: "M238",
            descricao: "Outros transtornos internos do joelho",
            favorito: false
        },
        {
            cid10: "M239",
            descricao: "Transtorno interno não especificado do joelho",
            favorito: false
        },
        {
            cid10: "M240",
            descricao: "Corpo flutuante em articulação",
            favorito: false
        },
        {
            cid10: "M241",
            descricao: "Outros transtornos das cartilagens articulares",
            favorito: false
        },
        {
            cid10: "M242",
            descricao: "Transtornos de ligamentos",
            favorito: false
        },
        {
            cid10: "M243",
            descricao: "Deslocamento e subluxação patológicas de articulação, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "M244",
            descricao: "Deslocamento e subluxação de articulação recidivantes",
            favorito: false
        },
        {
            cid10: "M245",
            descricao: "Contratura articular",
            favorito: false
        },
        {
            cid10: "M246",
            descricao: "Ancilose articular",
            favorito: false
        },
        {
            cid10: "M247",
            descricao: "Protusão do acetábulo",
            favorito: false
        },
        {
            cid10: "M248",
            descricao: "Outros transtornos articulares específicos, não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "M249",
            descricao: "Desarranjo articular não especificado",
            favorito: false
        },
        {
            cid10: "M250",
            descricao: "Hemartrose",
            favorito: false
        },
        {
            cid10: "M251",
            descricao: "Fístula articular",
            favorito: false
        },
        {
            cid10: "M252",
            descricao: "Flail joint",
            favorito: false
        },
        {
            cid10: "M253",
            descricao: "Outras instabilidades articulares",
            favorito: false
        },
        {
            cid10: "M254",
            descricao: "Derrame articular",
            favorito: false
        },
        {
            cid10: "M255",
            descricao: "Dor articular",
            favorito: false
        },
        {
            cid10: "M256",
            descricao: "Rigidez articular não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "M257",
            descricao: "Osteofito",
            favorito: false
        },
        {
            cid10: "M258",
            descricao: "Outros transtornos articulares especificados",
            favorito: false
        },
        {
            cid10: "M259",
            descricao: "Transtorno articular não especificado",
            favorito: false
        },
        {
            cid10: "M300",
            descricao: "Poliarterite nodosa",
            favorito: false
        },
        {
            cid10: "M301",
            descricao: "Poliarterite com comprometimento pulmonar [Churg-Strauss]",
            favorito: false
        },
        {
            cid10: "M302",
            descricao: "Poliarterite juvenil",
            favorito: false
        },
        {
            cid10: "M303",
            descricao: "Síndrome de linfonodos mucocutâneos [Kawasaki]",
            favorito: false
        },
        {
            cid10: "M308",
            descricao: "Outras afecções comuns na poliarterite nodosa",
            favorito: false
        },
        {
            cid10: "M310",
            descricao: "Angeíte de hipersensibilidade",
            favorito: false
        },
        {
            cid10: "M311",
            descricao: "Microangiopatia trombótica",
            favorito: false
        },
        {
            cid10: "M312",
            descricao: "Granuloma da linha média letal",
            favorito: false
        },
        {
            cid10: "M313",
            descricao: "Granulomatose de Wegener",
            favorito: false
        },
        {
            cid10: "M314",
            descricao: "Síndrome do arco aórtico [Takayasu]",
            favorito: false
        },
        {
            cid10: "M315",
            descricao: "Arterite de células gigantes com polimialgia reumática",
            favorito: false
        },
        {
            cid10: "M316",
            descricao: "Outras arterites de células gigantes",
            favorito: false
        },
        {
            cid10: "M317",
            descricao: "Poliangeíte microscópica",
            favorito: false
        },
        {
            cid10: "M318",
            descricao: "Outras vasculopatias necrotizantes especificadas",
            favorito: false
        },
        {
            cid10: "M319",
            descricao: "Vasculopatia necrotizante não especificada",
            favorito: false
        },
        {
            cid10: "M320",
            descricao: "Lúpus eritematoso disseminado [sistêmico] induzido por drogas",
            favorito: false
        },
        {
            cid10: "M321",
            descricao: "Lúpus eritematoso disseminado [sistêmico] com comprometimento de outros órgãos e sistemas",
            favorito: true
        },
        {
            cid10: "M328",
            descricao: "Outras formas de lúpus eritematoso disseminado [sistêmico]",
            favorito: true
        },
        {
            cid10: "M329",
            descricao: "Lúpus eritematoso disseminado [sistêmico] não especificado",
            favorito: false
        },
        {
            cid10: "M330",
            descricao: "Dermatomiosite juvenil",
            favorito: false
        },
        {
            cid10: "M331",
            descricao: "Outras dermatomiosites",
            favorito: true
        },
        {
            cid10: "M332",
            descricao: "Polimiosite",
            favorito: true
        },
        {
            cid10: "M339",
            descricao: "Dermatopolimiosite não especificada",
            favorito: false
        },
        {
            cid10: "M340",
            descricao: "Esclerose sistêmica progressiva",
            favorito: true
        },
        {
            cid10: "M341",
            descricao: "Síndrome CR(E)ST",
            favorito: true
        },
        {
            cid10: "M342",
            descricao: "Esclerose sistêmica induzida por droga e substâncias químicas",
            favorito: false
        },
        {
            cid10: "M348",
            descricao: "Outras formas de esclerose sistêmica",
            favorito: true
        },
        {
            cid10: "M349",
            descricao: "Esclerose sistêmica não especificada",
            favorito: false
        },
        {
            cid10: "M350",
            descricao: "Síndrome seca [Sjögren]",
            favorito: true
        },
        {
            cid10: "M351",
            descricao: "Outras síndromes superpostas",
            favorito: false
        },
        {
            cid10: "M352",
            descricao: "Doença de Behçet",
            favorito: false
        },
        {
            cid10: "M353",
            descricao: "Polimialgia reumática",
            favorito: false
        },
        {
            cid10: "M354",
            descricao: "Fasciíte (eosinofílica) difusa",
            favorito: false
        },
        {
            cid10: "M355",
            descricao: "Fibroesclerose multifocal",
            favorito: false
        },
        {
            cid10: "M356",
            descricao: "Paniculite recidivante [Weber-Christian]",
            favorito: false
        },
        {
            cid10: "M357",
            descricao: "Síndrome de hipermobilidade",
            favorito: false
        },
        {
            cid10: "M358",
            descricao: "Outro comprometimento sistêmico especificado do tecido conjuntivo",
            favorito: false
        },
        {
            cid10: "M359",
            descricao: "Comprometimento sistêmico não especificado do tecido conjuntivo",
            favorito: false
        },
        {
            cid10: "M360",
            descricao: "Dermato(poli)miosite em doenças neoplásicas",
            favorito: false
        },
        {
            cid10: "M361",
            descricao: "Artropatia em doenças neoplásicas classificadas",
            favorito: false
        },
        {
            cid10: "M362",
            descricao: "Artropatia hemofílica",
            favorito: false
        },
        {
            cid10: "M363",
            descricao: "Artropatias em outras doenças hematológicas",
            favorito: false
        },
        {
            cid10: "M364",
            descricao: "Artropatia associada a reações de hipersensibilidade classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M368",
            descricao: "Doenças sistêmicas do tecido conjuntivo em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M400",
            descricao: "Cifose postural",
            favorito: false
        },
        {
            cid10: "M401",
            descricao: "Outras cifoses secundárias",
            favorito: false
        },
        {
            cid10: "M402",
            descricao: "Outras cifoses e as não especificadas",
            favorito: false
        },
        {
            cid10: "M403",
            descricao: "Síndrome da retificação da coluna vertebral",
            favorito: false
        },
        {
            cid10: "M404",
            descricao: "Outras lordoses",
            favorito: false
        },
        {
            cid10: "M405",
            descricao: "Lordose não especificada",
            favorito: false
        },
        {
            cid10: "M410",
            descricao: "Escoliose idiopática infantil",
            favorito: false
        },
        {
            cid10: "M411",
            descricao: "Escoliose idiopática juvenil",
            favorito: false
        },
        {
            cid10: "M412",
            descricao: "Outras escolioses idiopáticas",
            favorito: false
        },
        {
            cid10: "M413",
            descricao: "Escoliose toracogênica",
            favorito: false
        },
        {
            cid10: "M414",
            descricao: "Escoliose neuromuscular",
            favorito: false
        },
        {
            cid10: "M415",
            descricao: "Outras escolioses secundárias",
            favorito: false
        },
        {
            cid10: "M418",
            descricao: "Outras formas de escoliose",
            favorito: false
        },
        {
            cid10: "M419",
            descricao: "Escoliose não especificada",
            favorito: false
        },
        {
            cid10: "M420",
            descricao: "Osteocondrose vertebral juvenil",
            favorito: false
        },
        {
            cid10: "M421",
            descricao: "Osteocondrose vertebral do adulto",
            favorito: false
        },
        {
            cid10: "M429",
            descricao: "Osteocondrose vertebral, não especificada",
            favorito: false
        },
        {
            cid10: "M430",
            descricao: "Espondilólise",
            favorito: false
        },
        {
            cid10: "M431",
            descricao: "Espondilolistese",
            favorito: false
        },
        {
            cid10: "M432",
            descricao: "Outras fusões da coluna vertebral",
            favorito: false
        },
        {
            cid10: "M433",
            descricao: "Subluxação atlanto-axial recidivante com mielopatia",
            favorito: false
        },
        {
            cid10: "M434",
            descricao: "Outras subluxações atlanto-axiais recidivantes",
            favorito: false
        },
        {
            cid10: "M435",
            descricao: "Outras subluxações vertebrais recidivantes",
            favorito: false
        },
        {
            cid10: "M436",
            descricao: "Torcicolo",
            favorito: false
        },
        {
            cid10: "M438",
            descricao: "Outras dorsopatias deformantes especificadas",
            favorito: false
        },
        {
            cid10: "M439",
            descricao: "Dorsopatia deformante, não especificada",
            favorito: false
        },
        {
            cid10: "M45",
            descricao: "Espondilite ancilosante",
            favorito: true
        },
        {
            cid10: "M460",
            descricao: "Entesopatia vertebral",
            favorito: false
        },
        {
            cid10: "M461",
            descricao: "Sacroileíte não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "M462",
            descricao: "Osteomielite das vértebras",
            favorito: false
        },
        {
            cid10: "M463",
            descricao: "Infecção (piogênica) do disco intervertebral",
            favorito: false
        },
        {
            cid10: "M464",
            descricao: "Discite não especificada",
            favorito: false
        },
        {
            cid10: "M465",
            descricao: "Outras espondilopatias infecciosas",
            favorito: false
        },
        {
            cid10: "M468",
            descricao: "Outras espondilopatias inflamatórias especificadas",
            favorito: true
        },
        {
            cid10: "M469",
            descricao: "Espondilopatia inflamatória não especificada",
            favorito: false
        },
        {
            cid10: "M470",
            descricao: "Síndromes de compressão da artéria espinhal anterior ou vertebral anterior",
            favorito: false
        },
        {
            cid10: "M471",
            descricao: "Outras espondiloses com mielopatia",
            favorito: false
        },
        {
            cid10: "M472",
            descricao: "Outras espondiloses com radiculopatias",
            favorito: false
        },
        {
            cid10: "M478",
            descricao: "Outras espondiloses",
            favorito: false
        },
        {
            cid10: "M479",
            descricao: "Espondilose não especificada",
            favorito: false
        },
        {
            cid10: "M480",
            descricao: "Estenose da coluna vertebral",
            favorito: false
        },
        {
            cid10: "M481",
            descricao: "Hiperostose ancilosante [Forestier]",
            favorito: false
        },
        {
            cid10: "M482",
            descricao: "Kissing spine",
            favorito: false
        },
        {
            cid10: "M483",
            descricao: "Espondilopatia traumática",
            favorito: false
        },
        {
            cid10: "M484",
            descricao: "Fratura de fadiga de vértebra",
            favorito: false
        },
        {
            cid10: "M485",
            descricao: "Vértebra colapsada não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "M488",
            descricao: "Outras espondilopatias especificadas",
            favorito: false
        },
        {
            cid10: "M489",
            descricao: "Espondilopatia não especificada",
            favorito: false
        },
        {
            cid10: "M490",
            descricao: "Tuberculose da coluna vertebral",
            favorito: false
        },
        {
            cid10: "M491",
            descricao: "Espondilite por Brucella",
            favorito: false
        },
        {
            cid10: "M492",
            descricao: "Espondilite por enterobactérias",
            favorito: false
        },
        {
            cid10: "M493",
            descricao: "Espondilopatia em outras doenças infeciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M494",
            descricao: "Espondilopatia neuropática",
            favorito: false
        },
        {
            cid10: "M495",
            descricao: "Vértebra colapsada em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M498",
            descricao: "Espondilopatia em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M500",
            descricao: "Transtorno do disco cervical com mielopatia",
            favorito: false
        },
        {
            cid10: "M501",
            descricao: "Transtorno do disco cervical com radiculopatia",
            favorito: false
        },
        {
            cid10: "M502",
            descricao: "Outro deslocamento de disco cervical",
            favorito: false
        },
        {
            cid10: "M503",
            descricao: "Outra degeneração de disco cervical",
            favorito: false
        },
        {
            cid10: "M508",
            descricao: "Outros transtornos de discos cervicais",
            favorito: false
        },
        {
            cid10: "M509",
            descricao: "Transtorno não especificado de disco cervical",
            favorito: false
        },
        {
            cid10: "M510",
            descricao: "Transtornos de discos lombares e de outros discos intervertebrais com mielopatia",
            favorito: false
        },
        {
            cid10: "M511",
            descricao: "Transtornos de discos lombares e de outros discos intervertebrais com radiculopatia",
            favorito: false
        },
        {
            cid10: "M512",
            descricao: "Outros deslocamentos discais intervertebrais especificados",
            favorito: false
        },
        {
            cid10: "M513",
            descricao: "Outra degeneração especificada de disco intervertebral",
            favorito: false
        },
        {
            cid10: "M514",
            descricao: "Nódulos de Schmorl",
            favorito: false
        },
        {
            cid10: "M518",
            descricao: "Outros transtornos especificados de discos intervertebrais",
            favorito: false
        },
        {
            cid10: "M519",
            descricao: "Transtorno não especificado de disco intervertebral",
            favorito: false
        },
        {
            cid10: "M530",
            descricao: "Síndrome cervicocraniana",
            favorito: false
        },
        {
            cid10: "M531",
            descricao: "Síndrome cervicobraquial",
            favorito: false
        },
        {
            cid10: "M532",
            descricao: "Instabilidades da coluna vertebral",
            favorito: false
        },
        {
            cid10: "M533",
            descricao: "Transtornos sacroccígeos não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "M538",
            descricao: "Outras dorsopatias especificadas",
            favorito: false
        },
        {
            cid10: "M539",
            descricao: "Dorsopatia não especificada",
            favorito: false
        },
        {
            cid10: "M540",
            descricao: "Paniculite atingindo regiões do pescoço e do dorso",
            favorito: false
        },
        {
            cid10: "M541",
            descricao: "Radiculopatia",
            favorito: false
        },
        {
            cid10: "M542",
            descricao: "Cervicalgia",
            favorito: false
        },
        {
            cid10: "M543",
            descricao: "Ciática",
            favorito: false
        },
        {
            cid10: "M544",
            descricao: "Lumbago com ciática",
            favorito: false
        },
        {
            cid10: "M545",
            descricao: "Dor lombar baixa",
            favorito: false
        },
        {
            cid10: "M546",
            descricao: "Dor na coluna torácica",
            favorito: false
        },
        {
            cid10: "M548",
            descricao: "Outra dorsalgia",
            favorito: false
        },
        {
            cid10: "M549",
            descricao: "Dorsalgia não especificada",
            favorito: false
        },
        {
            cid10: "M600",
            descricao: "Miosite infecciosa",
            favorito: false
        },
        {
            cid10: "M601",
            descricao: "Miosite intersticial",
            favorito: false
        },
        {
            cid10: "M602",
            descricao: "Granuloma de corpo estranho no tecido mole não classificado em outra parte",
            favorito: false
        },
        {
            cid10: "M608",
            descricao: "Outras miosites",
            favorito: false
        },
        {
            cid10: "M609",
            descricao: "Miosite não especificada",
            favorito: false
        },
        {
            cid10: "M610",
            descricao: "Miosite ossificante traumática",
            favorito: false
        },
        {
            cid10: "M611",
            descricao: "Miosite ossificante progressiva",
            favorito: false
        },
        {
            cid10: "M612",
            descricao: "Calcificação e ossificação paralítica de músculo",
            favorito: false
        },
        {
            cid10: "M613",
            descricao: "Calcificação e ossificação de músculo associadas com queimaduras",
            favorito: false
        },
        {
            cid10: "M614",
            descricao: "Outra calcificação de músculo",
            favorito: false
        },
        {
            cid10: "M615",
            descricao: "Outra ossificação de músculo",
            favorito: false
        },
        {
            cid10: "M619",
            descricao: "Calcificação e ossificação de músculo não especificada",
            favorito: false
        },
        {
            cid10: "M620",
            descricao: "Diástase de músculo",
            favorito: false
        },
        {
            cid10: "M621",
            descricao: "Outras rupturas musculares (não-traumáticas)",
            favorito: false
        },
        {
            cid10: "M622",
            descricao: "Infarto isquêmico do músculo",
            favorito: false
        },
        {
            cid10: "M623",
            descricao: "Síndrome de imobilidade (paraplégica)",
            favorito: false
        },
        {
            cid10: "M624",
            descricao: "Contratura de músculo",
            favorito: false
        },
        {
            cid10: "M625",
            descricao: "Perda e atrofia muscular não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M626",
            descricao: "Distensão muscular",
            favorito: false
        },
        {
            cid10: "M628",
            descricao: "Outros transtornos musculares especificados",
            favorito: false
        },
        {
            cid10: "M629",
            descricao: "Transtorno muscular não especificado",
            favorito: false
        },
        {
            cid10: "M630",
            descricao: "Miosite em doenças bacterianas classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M631",
            descricao: "Miosite em doenças infecciosas causadas por protozoários e parasitas classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M632",
            descricao: "Miosite em outras doenças infecciosas classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M633",
            descricao: "Miosite na sarcoidose",
            favorito: false
        },
        {
            cid10: "M638",
            descricao: "Outros transtornos musculares em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M650",
            descricao: "Abscesso da bainha tendínea",
            favorito: false
        },
        {
            cid10: "M651",
            descricao: "Outras (teno)sinovites infecciosas",
            favorito: false
        },
        {
            cid10: "M652",
            descricao: "Tendinite calcificada",
            favorito: false
        },
        {
            cid10: "M653",
            descricao: "Dedo em gatilho",
            favorito: false
        },
        {
            cid10: "M654",
            descricao: "Tenossinovite estilóide radial [de Quervain]",
            favorito: false
        },
        {
            cid10: "M658",
            descricao: "Outras sinovites e tenossinovites",
            favorito: false
        },
        {
            cid10: "M659",
            descricao: "Sinovite e tenossinovite não especificadas",
            favorito: false
        },
        {
            cid10: "M660",
            descricao: "Ruptura de cisto poplíteo",
            favorito: false
        },
        {
            cid10: "M661",
            descricao: "Ruptura de sinóvia",
            favorito: false
        },
        {
            cid10: "M662",
            descricao: "Ruptura espontânea de tendões extensores",
            favorito: false
        },
        {
            cid10: "M663",
            descricao: "Ruptura espontânea de tendões flexores",
            favorito: false
        },
        {
            cid10: "M664",
            descricao: "Ruptura espontânea de outros tendões",
            favorito: false
        },
        {
            cid10: "M665",
            descricao: "Ruptura espontânea de tendões não especificados",
            favorito: false
        },
        {
            cid10: "M670",
            descricao: "Tendão de Aquiles curto (adquirido)",
            favorito: false
        },
        {
            cid10: "M671",
            descricao: "Outra contratura de tendão (bainha)",
            favorito: false
        },
        {
            cid10: "M672",
            descricao: "Hipertrofia sinovial não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "M673",
            descricao: "Sinovite transitória",
            favorito: false
        },
        {
            cid10: "M674",
            descricao: "Gânglios",
            favorito: false
        },
        {
            cid10: "M678",
            descricao: "Outros transtornos especificados da sinóvia e do tendão",
            favorito: false
        },
        {
            cid10: "M679",
            descricao: "Transtorno não especificado da sinóvia e do tendão",
            favorito: false
        },
        {
            cid10: "M680",
            descricao: "Sinovite e tenossinovite em doenças bacterianas classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M688",
            descricao: "Outros transtornos de sinóvias e de tendões em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M700",
            descricao: "Sinovite crepitante crônica da mão e do punho",
            favorito: false
        },
        {
            cid10: "M701",
            descricao: "Bursite da mão",
            favorito: false
        },
        {
            cid10: "M702",
            descricao: "Bursite do olécrano",
            favorito: false
        },
        {
            cid10: "M703",
            descricao: "Outras bursites do cotovelo",
            favorito: false
        },
        {
            cid10: "M704",
            descricao: "Bursite pré-patelar",
            favorito: false
        },
        {
            cid10: "M705",
            descricao: "Outras bursites do joelho",
            favorito: false
        },
        {
            cid10: "M706",
            descricao: "Bursite trocantérica",
            favorito: false
        },
        {
            cid10: "M707",
            descricao: "Outras bursites do quadril",
            favorito: false
        },
        {
            cid10: "M708",
            descricao: "Outros transtornos dos tecidos moles relacionados com o uso, uso excessivo e pressão",
            favorito: false
        },
        {
            cid10: "M709",
            descricao: "Transtorno não especificado dos tecidos moles relacionados com o uso, uso excessivo e pressão",
            favorito: false
        },
        {
            cid10: "M710",
            descricao: "Abscesso de bolsa sinovial",
            favorito: false
        },
        {
            cid10: "M711",
            descricao: "Outras bursites infecciosas",
            favorito: false
        },
        {
            cid10: "M712",
            descricao: "Cisto sinovial do espaço poplíteo [Baker]",
            favorito: false
        },
        {
            cid10: "M713",
            descricao: "Outros cistos de bolsa sinovial",
            favorito: false
        },
        {
            cid10: "M714",
            descricao: "Depósito de cálcio em bolsa sinovial",
            favorito: false
        },
        {
            cid10: "M715",
            descricao: "Outras bursites não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M718",
            descricao: "Outras bursopatias especificadas",
            favorito: false
        },
        {
            cid10: "M719",
            descricao: "Bursopatia não especificada",
            favorito: false
        },
        {
            cid10: "M720",
            descricao: "Fibromatose de fáscia palmar [Dupuytren]",
            favorito: false
        },
        {
            cid10: "M721",
            descricao: "Coxins interfalângicos (nó dos dedos)",
            favorito: false
        },
        {
            cid10: "M722",
            descricao: "Fibromatose da fáscia plantar",
            favorito: false
        },
        {
            cid10: "M724",
            descricao: "Fibromatose pseudossarcomatosa",
            favorito: false
        },
        {
            cid10: "M726",
            descricao: "Fasciíte necrosante",
            favorito: false
        },
        {
            cid10: "M728",
            descricao: "Outros transtornos fibroblásticos",
            favorito: false
        },
        {
            cid10: "M729",
            descricao: "Transtorno fibroblástico não especificado",
            favorito: false
        },
        {
            cid10: "M730",
            descricao: "Bursite gonocócica",
            favorito: false
        },
        {
            cid10: "M731",
            descricao: "Bursite sifilítica",
            favorito: false
        },
        {
            cid10: "M738",
            descricao: "Outros transtornos dos tecidos moles em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M750",
            descricao: "Capsulite adesiva do ombro",
            favorito: false
        },
        {
            cid10: "M751",
            descricao: "Síndrome do manguito rotador",
            favorito: false
        },
        {
            cid10: "M752",
            descricao: "Tendinite bicepital",
            favorito: false
        },
        {
            cid10: "M753",
            descricao: "Tendinite calcificante do ombro",
            favorito: false
        },
        {
            cid10: "M754",
            descricao: "Síndrome de colisão do ombro",
            favorito: false
        },
        {
            cid10: "M755",
            descricao: "Bursite do ombro",
            favorito: false
        },
        {
            cid10: "M758",
            descricao: "Outras lesões do ombro",
            favorito: false
        },
        {
            cid10: "M759",
            descricao: "Lesão não especificada do ombro",
            favorito: false
        },
        {
            cid10: "M760",
            descricao: "Tendinite glútea",
            favorito: false
        },
        {
            cid10: "M761",
            descricao: "Tendinite do psoas",
            favorito: false
        },
        {
            cid10: "M762",
            descricao: "Esporão da crista ilíaca",
            favorito: false
        },
        {
            cid10: "M763",
            descricao: "Síndrome da faixa iliotibial",
            favorito: false
        },
        {
            cid10: "M764",
            descricao: "Bursite tibial colateral [Pellegrini-Stieda]",
            favorito: false
        },
        {
            cid10: "M765",
            descricao: "Tendinite patelar",
            favorito: false
        },
        {
            cid10: "M766",
            descricao: "Tendinite aquileana",
            favorito: false
        },
        {
            cid10: "M767",
            descricao: "Tendinite do perôneo",
            favorito: false
        },
        {
            cid10: "M768",
            descricao: "Outras entesopatias do membro inferior, excluindo o pé",
            favorito: false
        },
        {
            cid10: "M769",
            descricao: "Entesopatia do membro inferior não especificada",
            favorito: false
        },
        {
            cid10: "M770",
            descricao: "Epicondilite medial",
            favorito: false
        },
        {
            cid10: "M771",
            descricao: "Epicondilite lateral",
            favorito: false
        },
        {
            cid10: "M772",
            descricao: "Periartrite do punho",
            favorito: false
        },
        {
            cid10: "M773",
            descricao: "Esporão do calcâneo",
            favorito: false
        },
        {
            cid10: "M774",
            descricao: "Metatarsalgia",
            favorito: false
        },
        {
            cid10: "M775",
            descricao: "Outra entesopatia do pé",
            favorito: false
        },
        {
            cid10: "M778",
            descricao: "Outras entesopatias não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M779",
            descricao: "Entesopatia não especificada",
            favorito: false
        },
        {
            cid10: "M790",
            descricao: "Reumatismo não especificado",
            favorito: false
        },
        {
            cid10: "M791",
            descricao: "Mialgia",
            favorito: false
        },
        {
            cid10: "M792",
            descricao: "Nevralgia e neurite não especificadas",
            favorito: false
        },
        {
            cid10: "M793",
            descricao: "Paniculite não especificada",
            favorito: false
        },
        {
            cid10: "M794",
            descricao: "Hipertrofia do coxim gorduroso (infrapatelar)",
            favorito: false
        },
        {
            cid10: "M795",
            descricao: "Corpo estranho residual no tecido mole",
            favorito: false
        },
        {
            cid10: "M796",
            descricao: "Dor em membro",
            favorito: false
        },
        {
            cid10: "M797",
            descricao: "Fibromialgia",
            favorito: true
        },
        {
            cid10: "M798",
            descricao: "Outros transtornos especificados dos tecidos moles",
            favorito: false
        },
        {
            cid10: "M799",
            descricao: "Transtorno dos tecidos moles não especificado",
            favorito: false
        },
        {
            cid10: "M800",
            descricao: "Osteoporose pós-menopáusica com fratura patológica",
            favorito: true
        },
        {
            cid10: "M801",
            descricao: "Osteoporose pós-ooforectomia com fratura patológica",
            favorito: false
        },
        {
            cid10: "M802",
            descricao: "Osteoporose de desuso com fratura patológica",
            favorito: false
        },
        {
            cid10: "M803",
            descricao: "Osteoporose por má-absorção pós-cirúrgica com fratura patológica",
            favorito: false
        },
        {
            cid10: "M804",
            descricao: "Osteoporose induzida por drogas com fratura patológica",
            favorito: true
        },
        {
            cid10: "M805",
            descricao: "Osteoporose idiopática com fratura patológica",
            favorito: false
        },
        {
            cid10: "M808",
            descricao: "Outras osteoporoses com fratura patológica",
            favorito: true
        },
        {
            cid10: "M809",
            descricao: "Osteoporose não especificada com fratura patológica",
            favorito: true
        },
        {
            cid10: "M810",
            descricao: "Osteoporose pós-menopáusica",
            favorito: true
        },
        {
            cid10: "M811",
            descricao: "Osteoporose pós-ooforectomia",
            favorito: false
        },
        {
            cid10: "M812",
            descricao: "Osteoporose de desuso",
            favorito: false
        },
        {
            cid10: "M813",
            descricao: "Osteoporose devida à má-absorção pós-cirúrgica",
            favorito: false
        },
        {
            cid10: "M814",
            descricao: "Osteoporose induzida por drogas",
            favorito: true
        },
        {
            cid10: "M815",
            descricao: "Osteoporose idiopática",
            favorito: false
        },
        {
            cid10: "M816",
            descricao: "Osteoporose localizada [Lequesne]",
            favorito: false
        },
        {
            cid10: "M818",
            descricao: "Outras osteoporoses",
            favorito: true
        },
        {
            cid10: "M819",
            descricao: "Osteoporose não especificada",
            favorito: false
        },
        {
            cid10: "M820",
            descricao: "Osteoporose na mielomatose múltipla",
            favorito: false
        },
        {
            cid10: "M821",
            descricao: "Osteoporose em distúrbios endócrinos",
            favorito: false
        },
        {
            cid10: "M828",
            descricao: "Osteoporose em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M830",
            descricao: "Osteomalácia puerperal",
            favorito: false
        },
        {
            cid10: "M831",
            descricao: "Osteomalácia senil",
            favorito: false
        },
        {
            cid10: "M832",
            descricao: "Osteomalácia do adulto devida a má-absorção",
            favorito: false
        },
        {
            cid10: "M833",
            descricao: "Osteomalácia do adulto devido à desnutrição",
            favorito: false
        },
        {
            cid10: "M834",
            descricao: "Doença óssea pelo alumínio",
            favorito: false
        },
        {
            cid10: "M835",
            descricao: "Outras osteomalácias do adulto induzidas por drogas",
            favorito: false
        },
        {
            cid10: "M838",
            descricao: "Outra osteomalácia do adulto",
            favorito: false
        },
        {
            cid10: "M839",
            descricao: "Osteomalácia não especificada do adulto",
            favorito: false
        },
        {
            cid10: "M840",
            descricao: "Defeito de consolidação da fratura",
            favorito: false
        },
        {
            cid10: "M841",
            descricao: "Ausência de consolidação da fratura [pseudo-artrose]",
            favorito: false
        },
        {
            cid10: "M842",
            descricao: "Atraso de consolidação de fratura",
            favorito: false
        },
        {
            cid10: "M843",
            descricao: "Fratura de fadiga não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "M844",
            descricao: "Fratura patológica não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "M848",
            descricao: "Outros transtornos da continuidade do osso",
            favorito: false
        },
        {
            cid10: "M849",
            descricao: "Transtorno não especificado da continuidade do osso",
            favorito: false
        },
        {
            cid10: "M850",
            descricao: "Displasia fibrosa (monostótica)",
            favorito: false
        },
        {
            cid10: "M851",
            descricao: "Fluorose esquelética",
            favorito: false
        },
        {
            cid10: "M852",
            descricao: "Hiperostose do crânio",
            favorito: false
        },
        {
            cid10: "M853",
            descricao: "Osteíte condensante",
            favorito: false
        },
        {
            cid10: "M854",
            descricao: "Cisto ósseo solitário",
            favorito: false
        },
        {
            cid10: "M855",
            descricao: "Cisto ósseo aneurismático",
            favorito: false
        },
        {
            cid10: "M856",
            descricao: "Outro cisto ósseo",
            favorito: false
        },
        {
            cid10: "M858",
            descricao: "Outros transtornos especificados da densidade e da estrutura ósseas",
            favorito: false
        },
        {
            cid10: "M859",
            descricao: "Transtorno não especificado da densidade e da estrutura ósseas",
            favorito: false
        },
        {
            cid10: "M860",
            descricao: "Osteomielite aguda hematogênica",
            favorito: false
        },
        {
            cid10: "M861",
            descricao: "Outra osteomielite aguda",
            favorito: false
        },
        {
            cid10: "M862",
            descricao: "Osteomielite subaguda",
            favorito: false
        },
        {
            cid10: "M863",
            descricao: "Osteomielite crônica multifocal",
            favorito: false
        },
        {
            cid10: "M864",
            descricao: "Osteomielite crônica com seio drenante",
            favorito: false
        },
        {
            cid10: "M865",
            descricao: "Outra osteomielite crônica hematogênica",
            favorito: false
        },
        {
            cid10: "M866",
            descricao: "Outra osteomielite crônica",
            favorito: false
        },
        {
            cid10: "M868",
            descricao: "Outra osteomielite",
            favorito: false
        },
        {
            cid10: "M869",
            descricao: "Osteomielite não especificada",
            favorito: false
        },
        {
            cid10: "M870",
            descricao: "Necrose asséptica idiopática do osso",
            favorito: false
        },
        {
            cid10: "M871",
            descricao: "Osteonecrose devida a drogas",
            favorito: false
        },
        {
            cid10: "M872",
            descricao: "Osteonecrose devida a traumatismo anterior",
            favorito: false
        },
        {
            cid10: "M873",
            descricao: "Outras osteonecroses secundárias",
            favorito: false
        },
        {
            cid10: "M878",
            descricao: "Outras osteonecroses",
            favorito: false
        },
        {
            cid10: "M879",
            descricao: "Osteonecrose não especificada",
            favorito: false
        },
        {
            cid10: "M880",
            descricao: "Doença de Paget do crânio",
            favorito: false
        },
        {
            cid10: "M888",
            descricao: "Doença de Paget de outros ossos",
            favorito: false
        },
        {
            cid10: "M889",
            descricao: "Doença de Paget de osso não especificado",
            favorito: false
        },
        {
            cid10: "M890",
            descricao: "Algoneurodistrofia",
            favorito: false
        },
        {
            cid10: "M891",
            descricao: "Parada de crescimento epifisário",
            favorito: false
        },
        {
            cid10: "M892",
            descricao: "Outros transtornos do desenvolvimento e do crescimento ósseo",
            favorito: false
        },
        {
            cid10: "M893",
            descricao: "Hipertrofia óssea",
            favorito: false
        },
        {
            cid10: "M894",
            descricao: "Outras osteoartropatias hipertróficas",
            favorito: false
        },
        {
            cid10: "M895",
            descricao: "Osteolise",
            favorito: false
        },
        {
            cid10: "M896",
            descricao: "Osteopatia pós-poliomielite",
            favorito: false
        },
        {
            cid10: "M898",
            descricao: "Outros transtornos especificados do osso",
            favorito: false
        },
        {
            cid10: "M899",
            descricao: "Transtorno não especificado do osso",
            favorito: false
        },
        {
            cid10: "M900",
            descricao: "Tuberculose óssea",
            favorito: false
        },
        {
            cid10: "M901",
            descricao: "Periostite em outras doenças infecciosas classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M902",
            descricao: "Osteopatia em outras doenças infecciosas classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M903",
            descricao: "Osteonecrose em mal dos caixões",
            favorito: false
        },
        {
            cid10: "M904",
            descricao: "Osteonecrose devida à hemoglobinopatia",
            favorito: false
        },
        {
            cid10: "M905",
            descricao: "Osteonecrose em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M906",
            descricao: "Osteíte deformante em doenças neoplásicas",
            favorito: false
        },
        {
            cid10: "M907",
            descricao: "Fratura óssea em doenças neoplásicas",
            favorito: false
        },
        {
            cid10: "M908",
            descricao: "Osteopatia em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "M910",
            descricao: "Osteocondrose juvenil da pelve",
            favorito: false
        },
        {
            cid10: "M911",
            descricao: "Osteocondrose juvenil da cabeça do fêmur [Legg-Calvé-Perthes]",
            favorito: false
        },
        {
            cid10: "M912",
            descricao: "Coxa plana",
            favorito: false
        },
        {
            cid10: "M913",
            descricao: "Pseudocoxalgia",
            favorito: false
        },
        {
            cid10: "M918",
            descricao: "Outras osteocondroses juvenis do quadril e da pelve",
            favorito: false
        },
        {
            cid10: "M919",
            descricao: "Osteocondrose juvenil não especificada do quadril e da pelve",
            favorito: false
        },
        {
            cid10: "M920",
            descricao: "Osteocondrose juvenil do úmero",
            favorito: false
        },
        {
            cid10: "M921",
            descricao: "Osteocondrose juvenil do rádio e do cúbito [ulna]",
            favorito: false
        },
        {
            cid10: "M922",
            descricao: "Osteocondrose juvenil da mão",
            favorito: false
        },
        {
            cid10: "M923",
            descricao: "Outras osteocondroses juvenis do membro superior",
            favorito: false
        },
        {
            cid10: "M924",
            descricao: "Osteocondrose juvenil da rótula [patela]",
            favorito: false
        },
        {
            cid10: "M925",
            descricao: "Osteocondrose juvenil da tíbia e perônio [fíbula]",
            favorito: false
        },
        {
            cid10: "M926",
            descricao: "Osteocondrose juvenil do tarso",
            favorito: false
        },
        {
            cid10: "M927",
            descricao: "Osteocondrose juvenil do metatarso",
            favorito: false
        },
        {
            cid10: "M928",
            descricao: "Outras osteocondroses juvenis especificadas",
            favorito: false
        },
        {
            cid10: "M929",
            descricao: "Osteocondrose juvenil, não especificada",
            favorito: false
        },
        {
            cid10: "M930",
            descricao: "Luxação (não-traumática) da epífise superior do fêmur",
            favorito: false
        },
        {
            cid10: "M931",
            descricao: "Doença de Kienböck do adulto",
            favorito: false
        },
        {
            cid10: "M932",
            descricao: "Osteocondrite dissecante",
            favorito: false
        },
        {
            cid10: "M938",
            descricao: "Outras osteocondropatias, especificadas",
            favorito: false
        },
        {
            cid10: "M939",
            descricao: "Osteocondropatias, não especificada",
            favorito: false
        },
        {
            cid10: "M940",
            descricao: "Síndrome da junção condrocostal [Tietze]",
            favorito: false
        },
        {
            cid10: "M941",
            descricao: "Policondrite recidivante",
            favorito: false
        },
        {
            cid10: "M942",
            descricao: "Condromalácia",
            favorito: false
        },
        {
            cid10: "M943",
            descricao: "Condrolise",
            favorito: false
        },
        {
            cid10: "M948",
            descricao: "Outros transtornos especificados da cartilagem",
            favorito: false
        },
        {
            cid10: "M949",
            descricao: "Transtornos não especificados da cartilagem",
            favorito: false
        },
        {
            cid10: "M950",
            descricao: "Deformidade adquirida do nariz",
            favorito: false
        },
        {
            cid10: "M951",
            descricao: "Orelha em couve-flor",
            favorito: false
        },
        {
            cid10: "M952",
            descricao: "Outras deformidades adquiridas da cabeça",
            favorito: false
        },
        {
            cid10: "M953",
            descricao: "Deformidade adquirida do pescoço",
            favorito: false
        },
        {
            cid10: "M954",
            descricao: "Deformidade adquirida do tórax e das costelas",
            favorito: false
        },
        {
            cid10: "M955",
            descricao: "Deformidade adquirida da pelve",
            favorito: false
        },
        {
            cid10: "M958",
            descricao: "Outras deformidades adquiridas especificadas do sistema osteomuscular",
            favorito: false
        },
        {
            cid10: "M959",
            descricao: "Deformidade adquirida do sistema osteomuscular não especificada",
            favorito: false
        },
        {
            cid10: "M960",
            descricao: "Pseudo-artrose após fusão ou artrodese",
            favorito: false
        },
        {
            cid10: "M961",
            descricao: "Síndrome pós-laminectomia não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "M962",
            descricao: "Cifose pós-radiação",
            favorito: false
        },
        {
            cid10: "M963",
            descricao: "Cifose pós-laminectomia",
            favorito: false
        },
        {
            cid10: "M964",
            descricao: "Lordose pós-cirúrgica",
            favorito: false
        },
        {
            cid10: "M965",
            descricao: "Escoliose pós-radiação",
            favorito: false
        },
        {
            cid10: "M966",
            descricao: "Fratura de osso subsequente a implante ortopédico, prótese articular ou placa óssea",
            favorito: false
        },
        {
            cid10: "M968",
            descricao: "Outros transtornos osteomusculares pós-procedimentos",
            favorito: false
        },
        {
            cid10: "M969",
            descricao: "Transtorno osteomuscular não especificado pós-procedimento",
            favorito: false
        },
        {
            cid10: "M990",
            descricao: "Disfunção segmentar e somática",
            favorito: false
        },
        {
            cid10: "M991",
            descricao: "Complexo de subluxação (vertebral)",
            favorito: false
        },
        {
            cid10: "M992",
            descricao: "Estenose de subluxação do canal medular",
            favorito: false
        },
        {
            cid10: "M993",
            descricao: "Estenose óssea do canal medular",
            favorito: false
        },
        {
            cid10: "M994",
            descricao: "Estenose de tecido conjuntivo do canal medular",
            favorito: false
        },
        {
            cid10: "M995",
            descricao: "Estenose de disco intervertebral do canal medular",
            favorito: false
        },
        {
            cid10: "M996",
            descricao: "Estenose óssea e subluxação dos forames intervertebrais",
            favorito: false
        },
        {
            cid10: "M997",
            descricao: "Estenose de tecido conjuntivo e do disco dos forames intervertebrais",
            favorito: false
        },
        {
            cid10: "M998",
            descricao: "Outras lesões biomecânicas",
            favorito: false
        },
        {
            cid10: "M999",
            descricao: "Lesão biomecânica não especificada",
            favorito: false
        },
        {
            cid10: "N000",
            descricao: "Síndrome nefrítica aguda - anormalidade glomerular minor",
            favorito: false
        },
        {
            cid10: "N001",
            descricao: "Síndrome nefrítica aguda - lesões glomerulares focais e segmentares",
            favorito: false
        },
        {
            cid10: "N002",
            descricao: "Síndrome nefrítica aguda - glomerulonefrite membranosa difusa",
            favorito: false
        },
        {
            cid10: "N003",
            descricao: "Síndrome nefrítica aguda - glomerulonefrite proliferativa mesangial difusa",
            favorito: false
        },
        {
            cid10: "N004",
            descricao: "Síndrome nefrítica aguda - glomerulonefrite proliferativa endocapilar difusa",
            favorito: false
        },
        {
            cid10: "N005",
            descricao: "Síndrome nefrítica aguda - glomerulonefrite mesangiocapilar difusa",
            favorito: false
        },
        {
            cid10: "N006",
            descricao: "Síndrome nefrítica aguda - doença de depósito denso",
            favorito: false
        },
        {
            cid10: "N007",
            descricao: "Síndrome nefrítica aguda - glomerulonefrite difusa em crescente",
            favorito: false
        },
        {
            cid10: "N008",
            descricao: "Síndrome nefrítica aguda - outras",
            favorito: false
        },
        {
            cid10: "N009",
            descricao: "Síndrome nefrítica aguda - não especificada",
            favorito: false
        },
        {
            cid10: "N010",
            descricao: "Síndrome nefrítica rapidamente progressiva - anormalidade glomerular minor",
            favorito: false
        },
        {
            cid10: "N011",
            descricao: "Síndrome nefrítica rapidamente progressiva - lesões glomerulares focais e segmentares",
            favorito: false
        },
        {
            cid10: "N012",
            descricao: "Síndrome nefrítica rapidamente progressiva - glomerulonefrite membranosa difusa",
            favorito: false
        },
        {
            cid10: "N013",
            descricao: "Síndrome nefrítica rapidamente progressiva - glomerulonefrite proliferativa mesangial difusa",
            favorito: false
        },
        {
            cid10: "N014",
            descricao: "Síndrome nefrítica rapidamente progressiva - glomerulonefrite proliferativa endocapilar difusa",
            favorito: false
        },
        {
            cid10: "N015",
            descricao: "Síndrome nefrítica rapidamente progressiva - glomerulonefrite mesangiocapilar difusa",
            favorito: false
        },
        {
            cid10: "N016",
            descricao: "Síndrome nefrítica rapidamente progressiva - doença de depósito denso",
            favorito: false
        },
        {
            cid10: "N017",
            descricao: "Síndrome nefrítica rapidamente progressiva - glomerulonefrite difusa em crescente",
            favorito: false
        },
        {
            cid10: "N018",
            descricao: "Síndrome nefrítica rapidamente progressiva - outras",
            favorito: false
        },
        {
            cid10: "N019",
            descricao: "Síndrome nefrítica rapidamente progressiva - não especificada",
            favorito: false
        },
        {
            cid10: "N020",
            descricao: "Hematúria recidivante e persistente - anormalidade glomerular minor",
            favorito: false
        },
        {
            cid10: "N021",
            descricao: "Hematúria recidivante e persistente - lesões glomerulares focais e segmentares",
            favorito: false
        },
        {
            cid10: "N022",
            descricao: "Hematúria recidivante e persistente - glomerulonefrite membranosa difusa",
            favorito: false
        },
        {
            cid10: "N023",
            descricao: "Hematúria recidivante e persistente - glomerulonefrite proliferativa mesangial difusa",
            favorito: false
        },
        {
            cid10: "N024",
            descricao: "Hematúria recidivante e persistente - glomerulonefrite proliferativa endocapilar difusa",
            favorito: false
        },
        {
            cid10: "N025",
            descricao: "Hematúria recidivante e persistente - glomerulonefrite mesangiocapilar difusa",
            favorito: false
        },
        {
            cid10: "N026",
            descricao: "Hematúria recidivante e persistente - doença de depósito denso",
            favorito: false
        },
        {
            cid10: "N027",
            descricao: "Hematúria recidivante e persistente - glomerulonefrite difusa em crescente",
            favorito: false
        },
        {
            cid10: "N028",
            descricao: "Hematúria recidivante e persistente - outras",
            favorito: false
        },
        {
            cid10: "N029",
            descricao: "Hematúria recidivante e persistente - não especificada",
            favorito: false
        },
        {
            cid10: "N030",
            descricao: "Síndrome nefrítica crônica - anormalidade glomerular minor",
            favorito: false
        },
        {
            cid10: "N031",
            descricao: "Síndrome nefrítica crônica - lesões glomerulares focais e segmentares",
            favorito: false
        },
        {
            cid10: "N032",
            descricao: "Síndrome nefrítica crônica - glomerulonefrite membranosa difusa",
            favorito: false
        },
        {
            cid10: "N033",
            descricao: "Síndrome nefrítica crônica - glomerulonefrite proliferativa mesangial difusa",
            favorito: false
        },
        {
            cid10: "N034",
            descricao: "Síndrome nefrítica crônica - glomerulonefrite proliferativa endocapilar difusa",
            favorito: false
        },
        {
            cid10: "N035",
            descricao: "Síndrome nefrítica crônica - glomerulonefrite mesangiocapilar difusa",
            favorito: false
        },
        {
            cid10: "N036",
            descricao: "Síndrome nefrítica crônica - doença de depósito denso",
            favorito: false
        },
        {
            cid10: "N037",
            descricao: "Síndrome nefrítica crônica - glomerulonefrite difusa em crescente",
            favorito: false
        },
        {
            cid10: "N038",
            descricao: "Síndrome nefrítica crônica - outras",
            favorito: false
        },
        {
            cid10: "N039",
            descricao: "Síndrome nefrítica crônica - não especificada",
            favorito: false
        },
        {
            cid10: "N040",
            descricao: "Síndrome nefrótica - anormalidade glomerular minor",
            favorito: false
        },
        {
            cid10: "N041",
            descricao: "Síndrome nefrótica - lesões glomerulares focais e segmentares",
            favorito: false
        },
        {
            cid10: "N042",
            descricao: "Síndrome nefrótica - glomerulonefrite membranosa difusa",
            favorito: false
        },
        {
            cid10: "N043",
            descricao: "Síndrome nefrótica - glomerulonefrite proliferativa mesangial difusa",
            favorito: false
        },
        {
            cid10: "N044",
            descricao: "Síndrome nefrótica - glomerulonefrite proliferativa endocapilar difusa",
            favorito: false
        },
        {
            cid10: "N045",
            descricao: "Síndrome nefrótica - glomerulonefrite mesangiocapilar difusa",
            favorito: false
        },
        {
            cid10: "N046",
            descricao: "Síndrome nefrótica - doença de depósito denso",
            favorito: false
        },
        {
            cid10: "N047",
            descricao: "Síndrome nefrótica - glomerulonefrite difusa em crescente",
            favorito: false
        },
        {
            cid10: "N048",
            descricao: "Síndrome nefrótica - outras",
            favorito: false
        },
        {
            cid10: "N049",
            descricao: "Síndrome nefrótica - não especificada",
            favorito: false
        },
        {
            cid10: "N050",
            descricao: "Síndrome nefrítica não especificada - anormalidade glomerular minor",
            favorito: false
        },
        {
            cid10: "N051",
            descricao: "Síndrome nefrítica não especificada - lesões glomerulares focais e segmentares",
            favorito: false
        },
        {
            cid10: "N052",
            descricao: "Síndrome nefrítica não especificada - glomerulonefrite membranosa difusa",
            favorito: false
        },
        {
            cid10: "N053",
            descricao: "Síndrome nefrítica não especificada - glomerulonefrite proliferativa mesangial difusa",
            favorito: false
        },
        {
            cid10: "N054",
            descricao: "Síndrome nefrítica não especificada - glomerulonefrite proliferativa endocapilar difusa",
            favorito: false
        },
        {
            cid10: "N055",
            descricao: "Síndrome nefrítica não especificada - glomerulonefrite mesangiocapilar difusa",
            favorito: false
        },
        {
            cid10: "N056",
            descricao: "Síndrome nefrítica não especificada - doença de depósito denso",
            favorito: false
        },
        {
            cid10: "N057",
            descricao: "Síndrome nefrítica não especificada - glomerulonefrite difusa em crescente",
            favorito: false
        },
        {
            cid10: "N058",
            descricao: "Síndrome nefrítica não especificada - outras",
            favorito: false
        },
        {
            cid10: "N059",
            descricao: "Síndrome nefrítica não especificada - não especificada",
            favorito: false
        },
        {
            cid10: "N060",
            descricao: "Proteinúria isolada com lesão morfológica especificada - anormalidade glomerular minor",
            favorito: false
        },
        {
            cid10: "N061",
            descricao: "Proteinúria isolada com lesão morfológica especificada - lesões glomerulares focais e segmentares",
            favorito: false
        },
        {
            cid10: "N062",
            descricao: "Proteinúria isolada com lesão morfológica especificada - glomerulonefrite membranosa difusa",
            favorito: false
        },
        {
            cid10: "N063",
            descricao: "Proteinúria isolada com lesão morfológica especificada - glomerulonefrite proliferativa mesangial difusa",
            favorito: false
        },
        {
            cid10: "N064",
            descricao: "Proteinúria isolada com lesão morfológica especificada - glomerulonefrite proliferativa endocapilar difusa",
            favorito: false
        },
        {
            cid10: "N065",
            descricao: "Proteinúria isolada com lesão morfológica especificada - glomerulonefrite mesangiocapilar difusa",
            favorito: false
        },
        {
            cid10: "N066",
            descricao: "Proteinúria isolada com lesão morfológica especificada - doença de depósito denso",
            favorito: false
        },
        {
            cid10: "N067",
            descricao: "Proteinúria isolada com lesão morfológica especificada - glomerulonefrite difusa em crescente",
            favorito: false
        },
        {
            cid10: "N068",
            descricao: "Proteinúria isolada com lesão morfológica especificada - outras",
            favorito: false
        },
        {
            cid10: "N069",
            descricao: "Proteinúria isolada com lesão morfológica especificada - não especificada",
            favorito: false
        },
        {
            cid10: "N070",
            descricao: "Nefropatia hereditária não classificada em outra parte - anormalidade glomerular minor",
            favorito: false
        },
        {
            cid10: "N071",
            descricao: "Nefropatia hereditária não classificada em outra parte - lesões glomerulares focais e segmentares",
            favorito: false
        },
        {
            cid10: "N072",
            descricao: "Nefropatia hereditária não classificada em outra parte - glomerulonefrite membranosa difusa",
            favorito: false
        },
        {
            cid10: "N073",
            descricao: "Nefropatia hereditária não classificada em outra parte - glomerulonefrite proliferativa mesangial difusa",
            favorito: false
        },
        {
            cid10: "N074",
            descricao: "Nefropatia hereditária não classificada em outra parte - glomerulonefrite proliferativa endocapilar difusa",
            favorito: false
        },
        {
            cid10: "N075",
            descricao: "Nefropatia hereditária não classificada em outra parte - glomerulonefrite mesangiocapilar difusa",
            favorito: false
        },
        {
            cid10: "N076",
            descricao: "Nefropatia hereditária não classificada em outra parte - doença de depósito denso",
            favorito: false
        },
        {
            cid10: "N077",
            descricao: "Nefropatia hereditária não classificada em outra parte - glomerulonefrite difusa em crescente",
            favorito: false
        },
        {
            cid10: "N078",
            descricao: "Nefropatia hereditária não classificada em outra parte - outras",
            favorito: false
        },
        {
            cid10: "N079",
            descricao: "Nefropatia hereditária não classificada em outra parte - não especificada",
            favorito: false
        },
        {
            cid10: "N080",
            descricao: "Transtornos glomerulares em doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "N081",
            descricao: "Transtornos glomerulares em doenças neoplásicas",
            favorito: false
        },
        {
            cid10: "N082",
            descricao: "Transtornos glomerulares em doenças do sangue e transtornos imunitários",
            favorito: false
        },
        {
            cid10: "N083",
            descricao: "Transtornos glomerulares no diabetes mellitus",
            favorito: false
        },
        {
            cid10: "N084",
            descricao: "Transtornos glomerulares em doenças endócrinas, nutricionais e metabólicas",
            favorito: false
        },
        {
            cid10: "N085",
            descricao: "Transtornos glomerulares em doenças sistêmicas do tecido conjuntivo",
            favorito: false
        },
        {
            cid10: "N088",
            descricao: "Transtornos glomerulares em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "N10",
            descricao: "Nefrite túbulo-intersticial aguda",
            favorito: false
        },
        {
            cid10: "N110",
            descricao: "Pielonefrite não-obstrutiva crônica associada a refluxo",
            favorito: false
        },
        {
            cid10: "N111",
            descricao: "Pielonefrite obstrutiva crônica",
            favorito: false
        },
        {
            cid10: "N118",
            descricao: "Outras nefrites túbulo-intersticiais crônicas",
            favorito: false
        },
        {
            cid10: "N119",
            descricao: "Nefrite túbulo-intersticial crônica não especificada",
            favorito: false
        },
        {
            cid10: "N12",
            descricao: "Nefrite túbulo-intersticial não especificada se aguda ou crônica",
            favorito: false
        },
        {
            cid10: "N130",
            descricao: "Hidronefrose com obstrução da junção uretero-pélvica",
            favorito: false
        },
        {
            cid10: "N131",
            descricao: "Hidronefrose com estreitamento de ureter não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "N132",
            descricao: "Hidronefrose com obstrução por calculose renal e ureteral",
            favorito: false
        },
        {
            cid10: "N133",
            descricao: "Outras hidronefroses e as não especificadas",
            favorito: false
        },
        {
            cid10: "N134",
            descricao: "Hidroureter",
            favorito: false
        },
        {
            cid10: "N135",
            descricao: "Torção e estreitamento do ureter sem hidronefrose",
            favorito: false
        },
        {
            cid10: "N136",
            descricao: "Pionefrose",
            favorito: false
        },
        {
            cid10: "N137",
            descricao: "Uropatia associada a refluxo vésico-ureteral",
            favorito: false
        },
        {
            cid10: "N138",
            descricao: "Outras uropatias obstrutivas e por refluxo",
            favorito: false
        },
        {
            cid10: "N139",
            descricao: "Uropatia obstrutiva e por refluxo não especificada",
            favorito: false
        },
        {
            cid10: "N140",
            descricao: "Nefropatia induzida por analgésico",
            favorito: false
        },
        {
            cid10: "N141",
            descricao: "Nefropatia induzida por outras drogas, medicamentos e substâncias biológicas",
            favorito: false
        },
        {
            cid10: "N142",
            descricao: "Nefropatia induzida por drogas, medicamentos e substâncias biológicas não especificadas",
            favorito: false
        },
        {
            cid10: "N143",
            descricao: "Nefropatia induzida por metais pesados",
            favorito: false
        },
        {
            cid10: "N144",
            descricao: "Nefropatia tóxica não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "N150",
            descricao: "Nefropatia dos Bálcãs",
            favorito: false
        },
        {
            cid10: "N151",
            descricao: "Abscesso renal e perinefrético",
            favorito: false
        },
        {
            cid10: "N158",
            descricao: "Outras doenças renais túbulo-intersticiais especificadas",
            favorito: false
        },
        {
            cid10: "N159",
            descricao: "Doença renal túbulo-intersticial não especificada",
            favorito: false
        },
        {
            cid10: "N160",
            descricao: "Transtornos renais túbulo-intersticiais em doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "N161",
            descricao: "Transtornos renais túbulo-intersticiais em doenças neoplásicas",
            favorito: false
        },
        {
            cid10: "N162",
            descricao: "Transtornos renais túbulo-intersticiais em doenças do sangue e transtornos imunitários",
            favorito: false
        },
        {
            cid10: "N163",
            descricao: "Transtornos renais túbulo-intersticiais em doenças metabólicas",
            favorito: false
        },
        {
            cid10: "N164",
            descricao: "Transtornos renais túbulo-intersticiais em doenças do tecido conjuntivo",
            favorito: false
        },
        {
            cid10: "N165",
            descricao: "Transtornos renais túbulo-intersticiais em rejeição a transplantes",
            favorito: false
        },
        {
            cid10: "N168",
            descricao: "Transtornos renais túbulo-intersticiais em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "N170",
            descricao: "Insuficiência renal aguda com necrose tubular",
            favorito: false
        },
        {
            cid10: "N171",
            descricao: "Insuficiência renal aguda com necrose cortical aguda",
            favorito: false
        },
        {
            cid10: "N172",
            descricao: "Insuficiência renal aguda com necrose medular",
            favorito: false
        },
        {
            cid10: "N178",
            descricao: "Outro tipo de insuficiência renal aguda",
            favorito: false
        },
        {
            cid10: "N179",
            descricao: "Insuficiência renal aguda não especificada",
            favorito: false
        },
        {
            cid10: "N180",
            descricao: "Doença renal em estádio final",
            favorito: false
        },
        {
            cid10: "N188",
            descricao: "Outra insuficiência renal crônica",
            favorito: false
        },
        {
            cid10: "N189",
            descricao: "Insuficiência renal crônica não especificada",
            favorito: false
        },
        {
            cid10: "N19",
            descricao: "Insuficiência renal não especificada",
            favorito: false
        },
        {
            cid10: "N200",
            descricao: "Calculose do rim",
            favorito: false
        },
        {
            cid10: "N201",
            descricao: "Calculose do ureter",
            favorito: false
        },
        {
            cid10: "N202",
            descricao: "Calculose do rim com cálculo do ureter",
            favorito: false
        },
        {
            cid10: "N209",
            descricao: "Calculose urinária, não especificada",
            favorito: false
        },
        {
            cid10: "N210",
            descricao: "Calculose na bexiga",
            favorito: false
        },
        {
            cid10: "N211",
            descricao: "Cálculo uretral",
            favorito: false
        },
        {
            cid10: "N218",
            descricao: "Outros cálculos do trato urinário inferior",
            favorito: false
        },
        {
            cid10: "N219",
            descricao: "Cálculo do trato urinário inferior, porção não especificada",
            favorito: false
        },
        {
            cid10: "N220",
            descricao: "Cálculo urinário na esquistossomose [bilharziose] [schistosomíase]",
            favorito: false
        },
        {
            cid10: "N228",
            descricao: "Calculose do trato urinário em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "N23",
            descricao: "Cólica nefrética não especificada",
            favorito: false
        },
        {
            cid10: "N250",
            descricao: "Osteodistrofia renal",
            favorito: false
        },
        {
            cid10: "N251",
            descricao: "Diabetes insípido nefrogênico",
            favorito: false
        },
        {
            cid10: "N258",
            descricao: "Outros transtornos resultantes de função renal tubular alterada",
            favorito: false
        },
        {
            cid10: "N259",
            descricao: "Transtornos resultante de função tubular alterada",
            favorito: false
        },
        {
            cid10: "N26",
            descricao: "Rim contraído, não especificado",
            favorito: false
        },
        {
            cid10: "N270",
            descricao: "Hipoplasia renal unilateral",
            favorito: false
        },
        {
            cid10: "N271",
            descricao: "Hipoplasia renal bilateral",
            favorito: false
        },
        {
            cid10: "N279",
            descricao: "Hipoplasia renal não especificada",
            favorito: false
        },
        {
            cid10: "N280",
            descricao: "Isquemia e infarto renal",
            favorito: false
        },
        {
            cid10: "N281",
            descricao: "Cisto do rim, adquirido",
            favorito: false
        },
        {
            cid10: "N288",
            descricao: "Outros transtornos especificados do rim e do ureter",
            favorito: false
        },
        {
            cid10: "N289",
            descricao: "Transtorno não especificado do rim e do ureter",
            favorito: false
        },
        {
            cid10: "N290",
            descricao: "Sífilis tardia renal",
            favorito: false
        },
        {
            cid10: "N291",
            descricao: "Outros transtornos do rim e do ureter em doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "N298",
            descricao: "Outros transtornos do rim e do ureter em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "N300",
            descricao: "Cistite aguda",
            favorito: false
        },
        {
            cid10: "N301",
            descricao: "Cistite intersticial (crônica)",
            favorito: false
        },
        {
            cid10: "N302",
            descricao: "Outras cistites crônicas",
            favorito: false
        },
        {
            cid10: "N303",
            descricao: "Trigonite",
            favorito: false
        },
        {
            cid10: "N304",
            descricao: "Cistite por radiação",
            favorito: false
        },
        {
            cid10: "N308",
            descricao: "Outras cistites",
            favorito: false
        },
        {
            cid10: "N309",
            descricao: "Cistite, não especificada",
            favorito: false
        },
        {
            cid10: "N310",
            descricao: "Bexiga neuropática não-inibida não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "N311",
            descricao: "Bexiga neuropática reflexa não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "N312",
            descricao: "Bexiga neuropática flácida não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "N318",
            descricao: "Outra disfunção neuromuscular da bexiga",
            favorito: false
        },
        {
            cid10: "N319",
            descricao: "Disfunção neuromuscular não especificada da bexiga",
            favorito: false
        },
        {
            cid10: "N320",
            descricao: "Obstrução do colo da bexiga",
            favorito: false
        },
        {
            cid10: "N321",
            descricao: "Fístula êntero-vesical",
            favorito: false
        },
        {
            cid10: "N322",
            descricao: "Fístula vesical não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "N323",
            descricao: "Divertículo da bexiga",
            favorito: false
        },
        {
            cid10: "N324",
            descricao: "Ruptura da bexiga, não-traumática",
            favorito: false
        },
        {
            cid10: "N328",
            descricao: "Outros transtornos especificados da bexiga",
            favorito: false
        },
        {
            cid10: "N329",
            descricao: "Transtorno não especificado da bexiga",
            favorito: false
        },
        {
            cid10: "N330",
            descricao: "Cistite tuberculosa",
            favorito: false
        },
        {
            cid10: "N338",
            descricao: "Transtornos da bexiga em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "N340",
            descricao: "Abscesso uretral",
            favorito: false
        },
        {
            cid10: "N341",
            descricao: "Uretrites não específicas",
            favorito: false
        },
        {
            cid10: "N342",
            descricao: "Outras uretrites",
            favorito: false
        },
        {
            cid10: "N343",
            descricao: "Síndrome uretral, não especificada",
            favorito: false
        },
        {
            cid10: "N350",
            descricao: "Estenose uretral pós-traumática",
            favorito: false
        },
        {
            cid10: "N351",
            descricao: "Estenose uretral pós-infecciosa não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "N358",
            descricao: "Outra estenose (estreitamento) uretral",
            favorito: false
        },
        {
            cid10: "N359",
            descricao: "Estenose (estreitamento) uretral não especificada(o)",
            favorito: false
        },
        {
            cid10: "N360",
            descricao: "Fístula uretral",
            favorito: false
        },
        {
            cid10: "N361",
            descricao: "Divertículo uretral",
            favorito: false
        },
        {
            cid10: "N362",
            descricao: "Carúncula uretral",
            favorito: false
        },
        {
            cid10: "N363",
            descricao: "Prolapso da mucosa uretral",
            favorito: false
        },
        {
            cid10: "N368",
            descricao: "Outros transtornos especificados da uretra",
            favorito: false
        },
        {
            cid10: "N369",
            descricao: "Transtorno não especificado da uretra",
            favorito: false
        },
        {
            cid10: "N370",
            descricao: "Uretrite em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "N378",
            descricao: "Outros transtornos da uretra em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "N390",
            descricao: "Infecção do trato urinário de localização não especificada",
            favorito: false
        },
        {
            cid10: "N391",
            descricao: "Proteinúria persistente não especificada",
            favorito: false
        },
        {
            cid10: "N392",
            descricao: "Proteinúria ortostática não especificada",
            favorito: false
        },
        {
            cid10: "N393",
            descricao: "Incontinência de tensão stress",
            favorito: false
        },
        {
            cid10: "N394",
            descricao: "Outras incontinências urinárias especificadas",
            favorito: false
        },
        {
            cid10: "N398",
            descricao: "Outros transtornos especificados do aparelho urinário",
            favorito: false
        },
        {
            cid10: "N399",
            descricao: "Transtornos não especificados do aparelho urinário",
            favorito: false
        },
        {
            cid10: "N40",
            descricao: "Hiperplasia da próstata",
            favorito: false
        },
        {
            cid10: "N410",
            descricao: "Prostatite aguda",
            favorito: false
        },
        {
            cid10: "N411",
            descricao: "Prostatite crônica",
            favorito: false
        },
        {
            cid10: "N412",
            descricao: "Abscesso da próstata",
            favorito: false
        },
        {
            cid10: "N413",
            descricao: "Prostatocistite",
            favorito: false
        },
        {
            cid10: "N418",
            descricao: "Outras doenças inflamatórias da próstata",
            favorito: false
        },
        {
            cid10: "N419",
            descricao: "Doença inflamatória não especificada da próstata",
            favorito: false
        },
        {
            cid10: "N420",
            descricao: "Cálculo da próstata",
            favorito: false
        },
        {
            cid10: "N421",
            descricao: "Congestão e hemorragia da próstata",
            favorito: false
        },
        {
            cid10: "N422",
            descricao: "Atrofia da próstata",
            favorito: false
        },
        {
            cid10: "N428",
            descricao: "Outras afecções especificadas da próstata",
            favorito: false
        },
        {
            cid10: "N429",
            descricao: "Afecção não especificada da próstata",
            favorito: false
        },
        {
            cid10: "N430",
            descricao: "Hidrocele encistada",
            favorito: false
        },
        {
            cid10: "N431",
            descricao: "Hidrocele infectada",
            favorito: false
        },
        {
            cid10: "N432",
            descricao: "Outra hidrocele",
            favorito: false
        },
        {
            cid10: "N433",
            descricao: "Hidrocele não especificada",
            favorito: false
        },
        {
            cid10: "N434",
            descricao: "Espermatocele",
            favorito: false
        },
        {
            cid10: "N44",
            descricao: "Torção do testículo",
            favorito: false
        },
        {
            cid10: "N450",
            descricao: "Orquite, epididimite e epidídimo-orquite, com menção de abscesso",
            favorito: false
        },
        {
            cid10: "N459",
            descricao: "Orquite, epididimite e epidídimo-orquite, sem menção de abscesso",
            favorito: false
        },
        {
            cid10: "N46",
            descricao: "Infertilidade masculina",
            favorito: false
        },
        {
            cid10: "N47",
            descricao: "Hipertrofia do prepúcio, fimose e parafimose",
            favorito: false
        },
        {
            cid10: "N480",
            descricao: "Leucoplasia do pênis",
            favorito: false
        },
        {
            cid10: "N481",
            descricao: "Balanopostite",
            favorito: false
        },
        {
            cid10: "N482",
            descricao: "Outros transtornos inflamatórios do pênis",
            favorito: false
        },
        {
            cid10: "N483",
            descricao: "Priapismo",
            favorito: false
        },
        {
            cid10: "N484",
            descricao: "Impotência de origem orgânica",
            favorito: false
        },
        {
            cid10: "N485",
            descricao: "Úlcera do pênis",
            favorito: false
        },
        {
            cid10: "N486",
            descricao: "Induratio penis plastica",
            favorito: false
        },
        {
            cid10: "N488",
            descricao: "Outros transtornos especificados do pênis",
            favorito: false
        },
        {
            cid10: "N489",
            descricao: "Transtorno não especificado do pênis",
            favorito: false
        },
        {
            cid10: "N490",
            descricao: "Transtornos inflamatórios da vesícula seminal",
            favorito: false
        },
        {
            cid10: "N491",
            descricao: "Transtornos inflamatórios do cordão espermático, túnica vaginal e vasos deferentes",
            favorito: false
        },
        {
            cid10: "N492",
            descricao: "Transtorno inflamatório do escroto",
            favorito: false
        },
        {
            cid10: "N498",
            descricao: "Transtornos inflamatórios de outros órgãos genitais masculinos especificados",
            favorito: false
        },
        {
            cid10: "N499",
            descricao: "Transtorno inflamatório de órgão genital masculino, não especificado",
            favorito: false
        },
        {
            cid10: "N500",
            descricao: "Atrofia do testículo",
            favorito: false
        },
        {
            cid10: "N501",
            descricao: "Transtornos vasculares dos órgãos genitais masculinos",
            favorito: false
        },
        {
            cid10: "N508",
            descricao: "Outros transtornos especificados dos órgãos genitais masculinos",
            favorito: false
        },
        {
            cid10: "N509",
            descricao: "Transtorno não especificado dos órgãos genitais masculinos",
            favorito: false
        },
        {
            cid10: "N510",
            descricao: "Transtornos da próstata em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "N511",
            descricao: "Transtornos do testículo e do epidídimo em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "N512",
            descricao: "Balanite em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "N518",
            descricao: "Outros transtornos dos órgãos genitais masculinos em doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "N600",
            descricao: "Cisto solitário da mama",
            favorito: false
        },
        {
            cid10: "N601",
            descricao: "Mastopatia cística difusa",
            favorito: false
        },
        {
            cid10: "N602",
            descricao: "Fibroadenose da mama",
            favorito: false
        },
        {
            cid10: "N603",
            descricao: "Fibrosclerose da mama",
            favorito: false
        },
        {
            cid10: "N604",
            descricao: "Ectasia de dutos mamários",
            favorito: false
        },
        {
            cid10: "N608",
            descricao: "Outras displasias mamárias benignas",
            favorito: false
        },
        {
            cid10: "N609",
            descricao: "Displasia mamária benigna não especificada",
            favorito: false
        },
        {
            cid10: "N61",
            descricao: "Transtornos inflamatórios da mama",
            favorito: false
        },
        {
            cid10: "N62",
            descricao: "Hipertrofia da mama",
            favorito: false
        },
        {
            cid10: "N63",
            descricao: "Nódulo mamário não especificado",
            favorito: false
        },
        {
            cid10: "N640",
            descricao: "Fissura e fístula do mamilo",
            favorito: false
        },
        {
            cid10: "N641",
            descricao: "Necrose gordurosa da mama",
            favorito: false
        },
        {
            cid10: "N642",
            descricao: "Atrofia da mama",
            favorito: false
        },
        {
            cid10: "N643",
            descricao: "Galactorréia não-associada ao parto",
            favorito: false
        },
        {
            cid10: "N644",
            descricao: "Mastodinia",
            favorito: false
        },
        {
            cid10: "N645",
            descricao: "Outros sintomas e sinais da mama",
            favorito: false
        },
        {
            cid10: "N648",
            descricao: "Outros transtornos especificados da mama",
            favorito: false
        },
        {
            cid10: "N649",
            descricao: "Transtorno da mama não especificado",
            favorito: false
        },
        {
            cid10: "N700",
            descricao: "Salpingite e ooforite agudas",
            favorito: false
        },
        {
            cid10: "N701",
            descricao: "Salpingite e ooforite crônicas",
            favorito: false
        },
        {
            cid10: "N709",
            descricao: "Salpingite e ooforite não especificadas",
            favorito: false
        },
        {
            cid10: "N710",
            descricao: "Doença inflamatória aguda do útero",
            favorito: false
        },
        {
            cid10: "N711",
            descricao: "Doença inflamatória crônica do útero",
            favorito: false
        },
        {
            cid10: "N719",
            descricao: "Doença inflamatória não especificada do útero",
            favorito: false
        },
        {
            cid10: "N72",
            descricao: "Doença inflamatória do colo do útero",
            favorito: false
        },
        {
            cid10: "N730",
            descricao: "Parametrite e celulite pélvicas agudas",
            favorito: false
        },
        {
            cid10: "N731",
            descricao: "Parametrite e celulite pélvicas crônicas",
            favorito: false
        },
        {
            cid10: "N732",
            descricao: "Parametrite e celulite pélvicas não especificadas",
            favorito: false
        },
        {
            cid10: "N733",
            descricao: "Pelviperitonite aguda feminina",
            favorito: false
        },
        {
            cid10: "N734",
            descricao: "Pelviperitonite crônica feminina",
            favorito: false
        },
        {
            cid10: "N735",
            descricao: "Pelviperitonite não especificada feminina",
            favorito: false
        },
        {
            cid10: "N736",
            descricao: "Aderências pelviperitonais femininas",
            favorito: false
        },
        {
            cid10: "N738",
            descricao: "Outras doenças inflamatórias especificadas da pelve feminina",
            favorito: false
        },
        {
            cid10: "N739",
            descricao: "Doença inflamatória não especificada da pelve feminina",
            favorito: false
        },
        {
            cid10: "N740",
            descricao: "Tuberculose do colo do útero",
            favorito: false
        },
        {
            cid10: "N741",
            descricao: "Tuberculose da pelve feminina",
            favorito: false
        },
        {
            cid10: "N742",
            descricao: "Sífilis pélvica feminina",
            favorito: false
        },
        {
            cid10: "N743",
            descricao: "Infecção gonocócica pélvica feminina",
            favorito: false
        },
        {
            cid10: "N744",
            descricao: "Infecção pélvica feminina por clamídia",
            favorito: false
        },
        {
            cid10: "N748",
            descricao: "Inflamação pélvica feminina em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "N750",
            descricao: "Cisto da glândula de Bartholin",
            favorito: false
        },
        {
            cid10: "N751",
            descricao: "Abscesso da glândula de Bartholin",
            favorito: false
        },
        {
            cid10: "N758",
            descricao: "Outras doenças da glândula de Bartholin",
            favorito: false
        },
        {
            cid10: "N759",
            descricao: "Doença não especificada da glândula de Bartholin",
            favorito: false
        },
        {
            cid10: "N760",
            descricao: "Vaginite aguda",
            favorito: false
        },
        {
            cid10: "N761",
            descricao: "Vaginite subaguda e crônica",
            favorito: false
        },
        {
            cid10: "N762",
            descricao: "Vulvite aguda",
            favorito: false
        },
        {
            cid10: "N763",
            descricao: "Vulvite subaguda e crônica",
            favorito: false
        },
        {
            cid10: "N764",
            descricao: "Abscesso vulvar",
            favorito: false
        },
        {
            cid10: "N765",
            descricao: "Ulceração vaginal",
            favorito: false
        },
        {
            cid10: "N766",
            descricao: "Ulceração vulvar",
            favorito: false
        },
        {
            cid10: "N768",
            descricao: "Outras inflamações especificadas da vagina e da vulva",
            favorito: false
        },
        {
            cid10: "N770",
            descricao: "Ulceração da vulva em doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "N771",
            descricao: "Vaginite, vulvite e vulvovaginite em doenças infecciosas e parasitárias classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "N778",
            descricao: "Ulceração e inflamação vulvovaginais em outras doenças classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "N800",
            descricao: "Endometriose do útero",
            favorito: false
        },
        {
            cid10: "N801",
            descricao: "Endometriose do ovário",
            favorito: false
        },
        {
            cid10: "N802",
            descricao: "Endometriose da trompa de Falópio",
            favorito: false
        },
        {
            cid10: "N803",
            descricao: "Endometriose do peritônio pélvico",
            favorito: false
        },
        {
            cid10: "N804",
            descricao: "Endometriose do septo retovaginal e da vagina",
            favorito: false
        },
        {
            cid10: "N805",
            descricao: "Endometriose do intestino",
            favorito: false
        },
        {
            cid10: "N806",
            descricao: "Endometriose em cicatriz cutânea",
            favorito: false
        },
        {
            cid10: "N808",
            descricao: "Outra endometriose",
            favorito: false
        },
        {
            cid10: "N809",
            descricao: "Endometriose não especificada",
            favorito: false
        },
        {
            cid10: "N810",
            descricao: "Uretrocele feminina",
            favorito: false
        },
        {
            cid10: "N811",
            descricao: "Cistocele",
            favorito: false
        },
        {
            cid10: "N812",
            descricao: "Prolapso uterovaginal incompleto",
            favorito: false
        },
        {
            cid10: "N813",
            descricao: "Prolapso uterovaginal completo",
            favorito: false
        },
        {
            cid10: "N814",
            descricao: "Prolapso uterovaginal não especificado",
            favorito: false
        },
        {
            cid10: "N815",
            descricao: "Enterocele vaginal",
            favorito: false
        },
        {
            cid10: "N816",
            descricao: "Retocele",
            favorito: false
        },
        {
            cid10: "N818",
            descricao: "Outro prolapso genital feminino",
            favorito: false
        },
        {
            cid10: "N819",
            descricao: "Prolapso genital feminino não especificado",
            favorito: false
        },
        {
            cid10: "N820",
            descricao: "Fístula vesicovaginal",
            favorito: false
        },
        {
            cid10: "N821",
            descricao: "Outras fístulas do trato geniturinário feminino",
            favorito: false
        },
        {
            cid10: "N822",
            descricao: "Fístula vagina-intestino delgado",
            favorito: false
        },
        {
            cid10: "N823",
            descricao: "Fístula vagina-cólon",
            favorito: false
        },
        {
            cid10: "N824",
            descricao: "Outras fístulas genito-intestinais femininas",
            favorito: false
        },
        {
            cid10: "N825",
            descricao: "Fístula genitocutânea feminina",
            favorito: false
        },
        {
            cid10: "N828",
            descricao: "Outras fístulas do trato genital feminino",
            favorito: false
        },
        {
            cid10: "N829",
            descricao: "Fístulas não especificadas do trato genital feminino",
            favorito: false
        },
        {
            cid10: "N830",
            descricao: "Cisto folicular do ovário",
            favorito: false
        },
        {
            cid10: "N831",
            descricao: "Cisto do corpo lúteo",
            favorito: false
        },
        {
            cid10: "N832",
            descricao: "Outros cistos ovarianos e os não especificados",
            favorito: false
        },
        {
            cid10: "N833",
            descricao: "Atrofia adquirida do ovário e da trompa de Falópio",
            favorito: false
        },
        {
            cid10: "N834",
            descricao: "Prolapso e hérnia do ovário e da trompa de Falópio",
            favorito: false
        },
        {
            cid10: "N835",
            descricao: "Torção do ovário, do pedículo ovariano e da trompa de Falópio",
            favorito: false
        },
        {
            cid10: "N836",
            descricao: "Hematossalpinge",
            favorito: false
        },
        {
            cid10: "N837",
            descricao: "Hematoma do ligamento largo",
            favorito: false
        },
        {
            cid10: "N838",
            descricao: "Outros transtornos não-inflamatórios do ovário, da trompa de Falópio e do ligamento largo",
            favorito: false
        },
        {
            cid10: "N839",
            descricao: "Transtornos não-inflamatórios do ovário, da trompa de Falópio e do ligamento largo, não especificados",
            favorito: false
        },
        {
            cid10: "N840",
            descricao: "Pólipo do corpo do útero",
            favorito: false
        },
        {
            cid10: "N841",
            descricao: "Pólipo do colo do útero",
            favorito: false
        },
        {
            cid10: "N842",
            descricao: "Pólipo da vagina",
            favorito: false
        },
        {
            cid10: "N843",
            descricao: "Pólipo da vulva",
            favorito: false
        },
        {
            cid10: "N848",
            descricao: "Pólipo de outras partes do trato genital feminino",
            favorito: false
        },
        {
            cid10: "N849",
            descricao: "Pólipo do trato genital feminino não especificado",
            favorito: false
        },
        {
            cid10: "N850",
            descricao: "Hiperplasia glandular endometrial",
            favorito: false
        },
        {
            cid10: "N851",
            descricao: "Hiperplasia adenomatosa endometrial",
            favorito: false
        },
        {
            cid10: "N852",
            descricao: "Hipertrofia do útero",
            favorito: false
        },
        {
            cid10: "N853",
            descricao: "Subinvolução do útero",
            favorito: false
        },
        {
            cid10: "N854",
            descricao: "Posição anormal do útero",
            favorito: false
        },
        {
            cid10: "N855",
            descricao: "Inversão do útero",
            favorito: false
        },
        {
            cid10: "N856",
            descricao: "Sinéquias intra-uterinas",
            favorito: false
        },
        {
            cid10: "N857",
            descricao: "Hematometra",
            favorito: false
        },
        {
            cid10: "N858",
            descricao: "Outros transtornos não-inflamatórios especificados do útero",
            favorito: false
        },
        {
            cid10: "N859",
            descricao: "Transtornos não-inflamatórios do útero, não especificados",
            favorito: false
        },
        {
            cid10: "N86",
            descricao: "Erosão e ectrópio do colo do útero",
            favorito: false
        },
        {
            cid10: "N870",
            descricao: "Displasia cervical leve",
            favorito: false
        },
        {
            cid10: "N871",
            descricao: "Displasia cervical moderada",
            favorito: false
        },
        {
            cid10: "N872",
            descricao: "Displasia cervical grave, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "N879",
            descricao: "Displasia do colo do útero, não especificada",
            favorito: false
        },
        {
            cid10: "N880",
            descricao: "Leucoplasia do colo do útero",
            favorito: false
        },
        {
            cid10: "N881",
            descricao: "Laceração antiga do colo do útero",
            favorito: false
        },
        {
            cid10: "N882",
            descricao: "Estreitamento e estenose do colo do útero",
            favorito: false
        },
        {
            cid10: "N883",
            descricao: "Incompetência do colo do útero",
            favorito: false
        },
        {
            cid10: "N884",
            descricao: "Alongamento hipertrófico do colo do útero",
            favorito: false
        },
        {
            cid10: "N888",
            descricao: "Outros transtornos não-inflamatórios especificados do colo do útero",
            favorito: false
        },
        {
            cid10: "N889",
            descricao: "Transtorno não-inflamatório e não especificado do colo do útero",
            favorito: false
        },
        {
            cid10: "N890",
            descricao: "Displasia vaginal leve",
            favorito: false
        },
        {
            cid10: "N891",
            descricao: "Displasia vaginal moderada",
            favorito: false
        },
        {
            cid10: "N892",
            descricao: "Displasia vaginal grave, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "N893",
            descricao: "Displasia da vagina, não especificada",
            favorito: false
        },
        {
            cid10: "N894",
            descricao: "Leucoplasia da vagina",
            favorito: false
        },
        {
            cid10: "N895",
            descricao: "Estreitamento e atresia da vagina",
            favorito: false
        },
        {
            cid10: "N896",
            descricao: "Anel himenal apertado",
            favorito: false
        },
        {
            cid10: "N897",
            descricao: "Hematocolpos",
            favorito: false
        },
        {
            cid10: "N898",
            descricao: "Outros transtornos não-inflamatórios especificados da vagina",
            favorito: false
        },
        {
            cid10: "N899",
            descricao: "Transtorno não-inflamatório da vagina, não especificado",
            favorito: false
        },
        {
            cid10: "N900",
            descricao: "Displasia vulvar leve",
            favorito: false
        },
        {
            cid10: "N901",
            descricao: "Displasia vulvar moderada",
            favorito: false
        },
        {
            cid10: "N902",
            descricao: "Displasia vulvar grave, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "N903",
            descricao: "Displasia de vulva, não especificada",
            favorito: false
        },
        {
            cid10: "N904",
            descricao: "Leucoplasia de vulva",
            favorito: false
        },
        {
            cid10: "N905",
            descricao: "Atrofia da vulva",
            favorito: false
        },
        {
            cid10: "N906",
            descricao: "Hipertrofia da vulva",
            favorito: false
        },
        {
            cid10: "N907",
            descricao: "Cisto vulvar",
            favorito: false
        },
        {
            cid10: "N908",
            descricao: "Outros transtornos não-inflamatórios especificados da vulva e do períneo",
            favorito: false
        },
        {
            cid10: "N909",
            descricao: "Transtorno não-inflamatório e não especificado da vulva e do períneo",
            favorito: false
        },
        {
            cid10: "N910",
            descricao: "Amenorréia primária",
            favorito: false
        },
        {
            cid10: "N911",
            descricao: "Amenorréia secundária",
            favorito: false
        },
        {
            cid10: "N912",
            descricao: "Amenorréia, não especificada",
            favorito: false
        },
        {
            cid10: "N913",
            descricao: "Oligomenorréia primária",
            favorito: false
        },
        {
            cid10: "N914",
            descricao: "Oligomenorréia secundária",
            favorito: false
        },
        {
            cid10: "N915",
            descricao: "Oligomenorréia, não especificada",
            favorito: false
        },
        {
            cid10: "N920",
            descricao: "Menstruação excessiva e freqüente com ciclo regular",
            favorito: false
        },
        {
            cid10: "N921",
            descricao: "Menstruação excessiva e freqüente com ciclo irregular",
            favorito: false
        },
        {
            cid10: "N922",
            descricao: "Menstruação excessiva na puberdade",
            favorito: false
        },
        {
            cid10: "N923",
            descricao: "Sangramento da ovulação",
            favorito: false
        },
        {
            cid10: "N924",
            descricao: "Sangramento abundante na pré-menopausa",
            favorito: false
        },
        {
            cid10: "N925",
            descricao: "Outros tipos especificados de irregularidade da menstruação",
            favorito: false
        },
        {
            cid10: "N926",
            descricao: "Menstruação irregular, não especificada",
            favorito: false
        },
        {
            cid10: "N930",
            descricao: "Sangramentos pós-coito ou de contato",
            favorito: false
        },
        {
            cid10: "N938",
            descricao: "Outros sangramentos anormais especificados do útero e da vagina",
            favorito: false
        },
        {
            cid10: "N939",
            descricao: "Sangramento anormal do útero ou da vagina, não especificado",
            favorito: false
        },
        {
            cid10: "N940",
            descricao: "Ovulação dolorosa [Mittelschmerz]",
            favorito: false
        },
        {
            cid10: "N941",
            descricao: "Dispareunia",
            favorito: false
        },
        {
            cid10: "N942",
            descricao: "Vaginismo",
            favorito: false
        },
        {
            cid10: "N943",
            descricao: "Síndrome de tensão pré-menstrual",
            favorito: false
        },
        {
            cid10: "N944",
            descricao: "Dismenorréia primária",
            favorito: false
        },
        {
            cid10: "N945",
            descricao: "Dismenorréia secundária",
            favorito: false
        },
        {
            cid10: "N946",
            descricao: "Dismenorréia não especificada",
            favorito: false
        },
        {
            cid10: "N948",
            descricao: "Outras afecções especificadas associadas com os órgãos genitais femininos e com o ciclo menstrual",
            favorito: false
        },
        {
            cid10: "N949",
            descricao: "Afecções não especificadas associadas com os órgãos genitais femininos e com o ciclo menstrual",
            favorito: false
        },
        {
            cid10: "N950",
            descricao: "Sangramento pós-menopausa",
            favorito: false
        },
        {
            cid10: "N951",
            descricao: "Estado da menopausa e do climatério feminino",
            favorito: false
        },
        {
            cid10: "N952",
            descricao: "Vaginite atrófica pós-menopausa",
            favorito: false
        },
        {
            cid10: "N953",
            descricao: "Condições associadas com a menopausa artificial",
            favorito: false
        },
        {
            cid10: "N958",
            descricao: "Outros transtornos especificados da menopausa e da perimenopausa",
            favorito: false
        },
        {
            cid10: "N959",
            descricao: "Transtorno não especificado da menopausa e da perimenopausa",
            favorito: false
        },
        {
            cid10: "N96",
            descricao: "Abortamento habitual",
            favorito: false
        },
        {
            cid10: "N970",
            descricao: "Infertilidade feminina associada à anovulação",
            favorito: false
        },
        {
            cid10: "N971",
            descricao: "Infertilidade feminina de origem tubária",
            favorito: false
        },
        {
            cid10: "N972",
            descricao: "Infertilidade feminina de origem uterina",
            favorito: false
        },
        {
            cid10: "N973",
            descricao: "Infertilidade feminina de origem cervical",
            favorito: false
        },
        {
            cid10: "N974",
            descricao: "Infertilidade feminina associada à fatores do parceiro",
            favorito: false
        },
        {
            cid10: "N978",
            descricao: "Infertilidade feminina de outra origem",
            favorito: false
        },
        {
            cid10: "N979",
            descricao: "Infertilidade feminina não especificada",
            favorito: false
        },
        {
            cid10: "N980",
            descricao: "Infecção associada à inseminação artificial",
            favorito: false
        },
        {
            cid10: "N981",
            descricao: "Hiperestimulação dos ovários",
            favorito: false
        },
        {
            cid10: "N982",
            descricao: "Complicações relacionadas com a tentativa de introdução do óvulo fecundado artificialmente (in vitro)",
            favorito: false
        },
        {
            cid10: "N983",
            descricao: "Complicações relacionadas com a tentativa de transferência do embrião",
            favorito: false
        },
        {
            cid10: "N988",
            descricao: "Outras complicações associadas à fecundação artificial",
            favorito: false
        },
        {
            cid10: "N989",
            descricao: "Complicações não especificadas associadas à fecundação artificial",
            favorito: false
        },
        {
            cid10: "N990",
            descricao: "Insuficiência renal pós-procedimentos",
            favorito: false
        },
        {
            cid10: "N991",
            descricao: "Estreitamento de uretra pós-procedimentos",
            favorito: false
        },
        {
            cid10: "N992",
            descricao: "Aderências pós-operatórias da vagina",
            favorito: false
        },
        {
            cid10: "N993",
            descricao: "Prolapso de cúpula de vagina pós-histerectomia",
            favorito: false
        },
        {
            cid10: "N994",
            descricao: "Aderências do peritônio pélvico pós-procedimentos",
            favorito: false
        },
        {
            cid10: "N995",
            descricao: "Mau funcionamento de abertura externa (estoma) do trato urinário",
            favorito: false
        },
        {
            cid10: "N998",
            descricao: "Outros transtornos pós-procedimentos do aparelho geniturinário",
            favorito: false
        },
        {
            cid10: "N999",
            descricao: "Transtorno pós-procedimento não especificado do aparelho geniturinário",
            favorito: false
        },
        {
            cid10: "O000",
            descricao: "Gravidez abdominal",
            favorito: false
        },
        {
            cid10: "O001",
            descricao: "Gravidez tubária",
            favorito: false
        },
        {
            cid10: "O002",
            descricao: "Gravidez ovariana",
            favorito: false
        },
        {
            cid10: "O008",
            descricao: "Outras formas de gravidez ectópica",
            favorito: false
        },
        {
            cid10: "O009",
            descricao: "Gravidez ectópica, não especificada",
            favorito: false
        },
        {
            cid10: "O010",
            descricao: "Mola hidatiforme clássica",
            favorito: false
        },
        {
            cid10: "O011",
            descricao: "Mola hidatiforme incompleta ou parcial",
            favorito: false
        },
        {
            cid10: "O019",
            descricao: "Mola hidatiforme não especificada",
            favorito: false
        },
        {
            cid10: "O020",
            descricao: "Ovo claro e mola não-hidatiforme",
            favorito: false
        },
        {
            cid10: "O021",
            descricao: "Aborto retido",
            favorito: false
        },
        {
            cid10: "O028",
            descricao: "Outros produtos anormais da concepção especificados",
            favorito: false
        },
        {
            cid10: "O029",
            descricao: "Produto anormal da concepção, não especificado",
            favorito: false
        },
        {
            cid10: "O030",
            descricao: "Aborto espontâneo - incompleto, complicado por infecção do trato genital ou dos órgãos pélvicos",
            favorito: false
        },
        {
            cid10: "O031",
            descricao: "Aborto espontâneo - incompleto, complicado por hemorragia excessiva ou tardia",
            favorito: false
        },
        {
            cid10: "O032",
            descricao: "Aborto espontâneo - incompleto, complicado por embolia",
            favorito: false
        },
        {
            cid10: "O033",
            descricao: "Aborto espontâneo - incompleto, com outras complicações ou com complicações não especificadas",
            favorito: false
        },
        {
            cid10: "O034",
            descricao: "Aborto espontâneo - incompleto, sem complicações",
            favorito: false
        },
        {
            cid10: "O035",
            descricao: "Aborto espontâneo - completo ou não especificado, complicado por infecções do trato genital ou dos órgãos pélvicos",
            favorito: false
        },
        {
            cid10: "O036",
            descricao: "Aborto espontâneo - completo ou não especificado, complicado por hemorragia excessiva ou tardia",
            favorito: false
        },
        {
            cid10: "O037",
            descricao: "Aborto espontâneo - completo ou não especificado, complicado por embolia",
            favorito: false
        },
        {
            cid10: "O038",
            descricao: "Aborto espontâneo - completo ou não especificado, com outras complicações ou com complicações não especificadas",
            favorito: false
        },
        {
            cid10: "O039",
            descricao: "Aborto espontâneo - completo ou não especificado, sem complicações",
            favorito: false
        },
        {
            cid10: "O040",
            descricao: "Aborto por razões médicas e legais - incompleto, complicado por infecção do trato genital ou dos órgãos pélvicos",
            favorito: false
        },
        {
            cid10: "O041",
            descricao: "Aborto por razões médicas e legais - incompleto, complicado por hemorragia excessiva ou tardia",
            favorito: false
        },
        {
            cid10: "O042",
            descricao: "Aborto por razões médicas e legais - incompleto, complicado por embolia",
            favorito: false
        },
        {
            cid10: "O043",
            descricao: "Aborto por razões médicas e legais - incompleto, com outras complicações ou com complicações não especificadas",
            favorito: false
        },
        {
            cid10: "O044",
            descricao: "Aborto por razões médicas e legais - incompleto, sem complicações",
            favorito: false
        },
        {
            cid10: "O045",
            descricao: "Aborto por razões médicas e legais - completo ou não especificado, complicado por infecções do trato genital ou dos órgãos pélvicos",
            favorito: false
        },
        {
            cid10: "O046",
            descricao: "Aborto por razões médicas e legais - completo ou não especificado, complicado por hemorragia excessiva ou tardia",
            favorito: false
        },
        {
            cid10: "O047",
            descricao: "Aborto por razões médicas e legais - completo ou não especificado, complicado por embolia",
            favorito: false
        },
        {
            cid10: "O048",
            descricao: "Aborto por razões médicas e legais - completo ou não especificado, com outras complicações ou com complicações não especificadas",
            favorito: false
        },
        {
            cid10: "O049",
            descricao: "Aborto por razões médicas e legais - completo ou não especificado, sem complicações",
            favorito: false
        },
        {
            cid10: "O050",
            descricao: "Outros tipos de aborto - incompleto, complicado por infecção do trato genital ou dos órgãos pélvicos",
            favorito: false
        },
        {
            cid10: "O051",
            descricao: "Outros tipos de aborto - incompleto, complicado por hemorragia excessiva ou tardia",
            favorito: false
        },
        {
            cid10: "O052",
            descricao: "Outros tipos de aborto - incompleto, complicado por embolia",
            favorito: false
        },
        {
            cid10: "O053",
            descricao: "Outros tipos de aborto - incompleto, com outras complicações ou com complicações não especificadas",
            favorito: false
        },
        {
            cid10: "O054",
            descricao: "Outros tipos de aborto - incompleto, sem complicações",
            favorito: false
        },
        {
            cid10: "O055",
            descricao: "Outros tipos de aborto - completo ou não especificado, complicado por infecções do trato genital ou dos órgãos pélvicos",
            favorito: false
        },
        {
            cid10: "O056",
            descricao: "Outros tipos de aborto - completo ou não especificado, complicado por hemorragia excessiva ou tardia",
            favorito: false
        },
        {
            cid10: "O057",
            descricao: "Outros tipos de aborto - completo ou não especificado, complicado por embolia",
            favorito: false
        },
        {
            cid10: "O058",
            descricao: "Outros tipos de aborto - completo ou não especificado, com outras complicações ou com complicações não especificadas",
            favorito: false
        },
        {
            cid10: "O059",
            descricao: "Outros tipos de aborto - completo ou não especificado, sem complicações",
            favorito: false
        },
        {
            cid10: "O060",
            descricao: "Aborto não especificado - incompleto, complicado por infecção do trato genital ou dos órgãos pélvicos",
            favorito: false
        },
        {
            cid10: "O061",
            descricao: "Aborto não especificado - incompleto, complicado por hemorragia excessiva ou tardia",
            favorito: false
        },
        {
            cid10: "O062",
            descricao: "Aborto não especificado - incompleto, complicado por embolia",
            favorito: false
        },
        {
            cid10: "O063",
            descricao: "Aborto não especificado - incompleto, com outras complicações ou com complicações não especificadas",
            favorito: false
        },
        {
            cid10: "O064",
            descricao: "Aborto não especificado - incompleto, sem complicações",
            favorito: false
        },
        {
            cid10: "O065",
            descricao: "Aborto não especificado - completo ou não especificado, complicado por infecções do trato genital ou dos órgãos pélvicos",
            favorito: false
        },
        {
            cid10: "O066",
            descricao: "Aborto não especificado - completo ou não especificado, complicado por hemorragia excessiva ou tardia",
            favorito: false
        },
        {
            cid10: "O067",
            descricao: "Aborto não especificado - completo ou não especificado, complicado por embolia",
            favorito: false
        },
        {
            cid10: "O068",
            descricao: "Aborto não especificado - completo ou não especificado, com outras complicações ou com complicações não especificadas",
            favorito: false
        },
        {
            cid10: "O069",
            descricao: "Aborto não especificado - completo ou não especificado, sem complicações",
            favorito: false
        },
        {
            cid10: "O070",
            descricao: "Falha de aborto provocado por razões médicas, complicado por infecção do trato genital ou dos órgãos pélvicos",
            favorito: false
        },
        {
            cid10: "O071",
            descricao: "Falha de aborto provocado por razões médicas, complicado por hemorragia tardia ou excessiva",
            favorito: false
        },
        {
            cid10: "O072",
            descricao: "Falha de aborto provocado por razões médicas, complicado por embolia",
            favorito: false
        },
        {
            cid10: "O073",
            descricao: "Falha de aborto provocado por razões médicas, com outras complicações ou com complicações não especificadas",
            favorito: false
        },
        {
            cid10: "O074",
            descricao: "Falha de aborto provocado por razões médicas, sem complicações",
            favorito: false
        },
        {
            cid10: "O075",
            descricao: "Outras formas, e as não especificadas, de falha na provocação de aborto, complicadas por infecção do trato genital e por infecção dos órgãos pélvicos",
            favorito: false
        },
        {
            cid10: "O076",
            descricao: "Outras formas, e as não especificadas, de falha na provocação de aborto, complicadas por hemorragia tardia ou excessiva",
            favorito: false
        },
        {
            cid10: "O077",
            descricao: "Outras formas, e as não especificadas, de falha na provocação de aborto, complicadas por embolia",
            favorito: false
        },
        {
            cid10: "O078",
            descricao: "Outras formas, e as não especificadas, de falha na provocação de aborto, com outras complicações ou com complicações não especificadas",
            favorito: false
        },
        {
            cid10: "O079",
            descricao: "Outras formas, e as não especificadas, de falha na provocação de aborto, sem complicação",
            favorito: false
        },
        {
            cid10: "O080",
            descricao: "Infecção do trato genital e dos órgãos pélvicos conseqüente a aborto e gravidez ectópica e molar",
            favorito: false
        },
        {
            cid10: "O081",
            descricao: "Hemorragia tardia ou excessiva conseqüente a aborto e a gravidez ectópica e molar",
            favorito: false
        },
        {
            cid10: "O082",
            descricao: "Embolia conseqüente a aborto e a gravidez ectópica e molar",
            favorito: false
        },
        {
            cid10: "O083",
            descricao: "Choque conseqüente a aborto e a gravidez ectópica e molar",
            favorito: false
        },
        {
            cid10: "O084",
            descricao: "Insuficiência renal conseqüente a aborto e a gravidez ectópica e molar",
            favorito: false
        },
        {
            cid10: "O085",
            descricao: "Distúrbios metabólicos conseqüentes a aborto e a gravidez ectópica e molar",
            favorito: false
        },
        {
            cid10: "O086",
            descricao: "Lesão a órgãos e a tecidos pélvicos conseqüentes a aborto e a gravidez ectópica e molar",
            favorito: false
        },
        {
            cid10: "O087",
            descricao: "Outras complicações venosas conseqüentes a aborto e a gravidez ectópica e molar",
            favorito: false
        },
        {
            cid10: "O088",
            descricao: "Outras complicações conseqüentes a aborto e a gravidez ectópica e molar",
            favorito: false
        },
        {
            cid10: "O089",
            descricao: "Complicações conseqüentes a aborto e gravidez ectópica e molar, não especificadas",
            favorito: false
        },
        {
            cid10: "O100",
            descricao: "Hipertensão essencial pré-existente complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "O101",
            descricao: "Doença cardíaca hipertensiva pré-existente complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "O102",
            descricao: "Doença renal hipertensiva pré-existente complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "O103",
            descricao: "Doença cardíaca e renal hipertensiva pré-existente complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "O104",
            descricao: "Hipertensão secundária pré-existente complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "O109",
            descricao: "Hipertensão pré-existente não especificada, complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "O11",
            descricao: "Distúrbio hipertensivo pré-existente com proteinúria superposta",
            favorito: false
        },
        {
            cid10: "O120",
            descricao: "Edema gestacional",
            favorito: false
        },
        {
            cid10: "O121",
            descricao: "Proteinúria gestacional",
            favorito: false
        },
        {
            cid10: "O122",
            descricao: "Edema gestacional com proteinúria",
            favorito: false
        },
        {
            cid10: "O13",
            descricao: "Hipertensão gestacional [induzida pela gravidez] sem proteinúria significativa",
            favorito: false
        },
        {
            cid10: "O140",
            descricao: "Pré-eclâmpsia moderada",
            favorito: false
        },
        {
            cid10: "O141",
            descricao: "Pré-eclâmpsia grave",
            favorito: false
        },
        {
            cid10: "O149",
            descricao: "Pré-eclâmpsia não especificada",
            favorito: false
        },
        {
            cid10: "O150",
            descricao: "Eclâmpsia na gravidez",
            favorito: false
        },
        {
            cid10: "O151",
            descricao: "Eclâmpsia no trabalho de parto",
            favorito: false
        },
        {
            cid10: "O152",
            descricao: "Eclâmpsia no puerpério",
            favorito: false
        },
        {
            cid10: "O159",
            descricao: "Eclâmpsia não especificada quanto ao período",
            favorito: false
        },
        {
            cid10: "O16",
            descricao: "Hipertensão materna não especificada",
            favorito: false
        },
        {
            cid10: "O200",
            descricao: "Ameaça de aborto",
            favorito: false
        },
        {
            cid10: "O208",
            descricao: "Outras hemorragias do início da gravidez",
            favorito: false
        },
        {
            cid10: "O209",
            descricao: "Hemorragia do início da gravidez, não especificada",
            favorito: false
        },
        {
            cid10: "O210",
            descricao: "Hiperêmese gravídica leve",
            favorito: false
        },
        {
            cid10: "O211",
            descricao: "Hiperêmese gravídica com distúrbio metabólico",
            favorito: false
        },
        {
            cid10: "O212",
            descricao: "Vômitos tardios da gravidez",
            favorito: false
        },
        {
            cid10: "O218",
            descricao: "Outras formas de vômitos complicando a gravidez",
            favorito: false
        },
        {
            cid10: "O219",
            descricao: "Vômitos da gravidez, não especificados",
            favorito: false
        },
        {
            cid10: "O220",
            descricao: "Varizes dos membros inferiores na gravidez",
            favorito: false
        },
        {
            cid10: "O221",
            descricao: "Varizes genitais na gravidez",
            favorito: false
        },
        {
            cid10: "O222",
            descricao: "Tromboflebite superficial na gravidez",
            favorito: false
        },
        {
            cid10: "O223",
            descricao: "Flebotrombose profunda na gravidez",
            favorito: false
        },
        {
            cid10: "O224",
            descricao: "Hemorróidas na gravidez",
            favorito: false
        },
        {
            cid10: "O225",
            descricao: "Trombose venosa cerebral na gravidez",
            favorito: false
        },
        {
            cid10: "O228",
            descricao: "Outras complicações venosas na gravidez",
            favorito: false
        },
        {
            cid10: "O229",
            descricao: "Complicações venosas na gravidez, não especificadas",
            favorito: false
        },
        {
            cid10: "O230",
            descricao: "Infecções do rim na gravidez",
            favorito: false
        },
        {
            cid10: "O231",
            descricao: "Infecções da bexiga na gravidez",
            favorito: false
        },
        {
            cid10: "O232",
            descricao: "Infecções da uretra na gravidez",
            favorito: false
        },
        {
            cid10: "O233",
            descricao: "Infecções de outras partes do trato urinário na gravidez",
            favorito: false
        },
        {
            cid10: "O234",
            descricao: "Infecção não especificada do trato urinário na gravidez",
            favorito: false
        },
        {
            cid10: "O235",
            descricao: "Infecções do trato genital na gravidez",
            favorito: false
        },
        {
            cid10: "O239",
            descricao: "Outras infecções e as não especificadas do trato urinário na gravidez",
            favorito: false
        },
        {
            cid10: "O240",
            descricao: "Diabetes mellitus pré-existente, insulino-dependente",
            favorito: false
        },
        {
            cid10: "O241",
            descricao: "Diabetes mellitus pré-existente, não-insulino-dependente",
            favorito: false
        },
        {
            cid10: "O242",
            descricao: "Diabetes mellitus pré-existente, relacionado com a desnutrição",
            favorito: false
        },
        {
            cid10: "O243",
            descricao: "Diabetes mellitus pré-existente, não especificado",
            favorito: false
        },
        {
            cid10: "O244",
            descricao: "Diabetes mellitus que surge durante a gravidez",
            favorito: false
        },
        {
            cid10: "O249",
            descricao: "Diabetes mellitus na gravidez, não especificado",
            favorito: false
        },
        {
            cid10: "O25",
            descricao: "Desnutrição na gravidez",
            favorito: false
        },
        {
            cid10: "O260",
            descricao: "Ganho excessivo de peso na gravidez",
            favorito: false
        },
        {
            cid10: "O261",
            descricao: "Ganho de peso insuficiente na gravidez",
            favorito: false
        },
        {
            cid10: "O262",
            descricao: "Assistência à gravidez por motivo de abortamento habitual",
            favorito: false
        },
        {
            cid10: "O263",
            descricao: "Retenção de dispositivo intra-uterino contraceptivo (DIU) na gravidez",
            favorito: false
        },
        {
            cid10: "O264",
            descricao: "Herpes gestacional",
            favorito: false
        },
        {
            cid10: "O265",
            descricao: "Síndrome da hipotensão materna",
            favorito: false
        },
        {
            cid10: "O266",
            descricao: "Transtornos do fígado na gravidez, no parto e no puerpério",
            favorito: false
        },
        {
            cid10: "O267",
            descricao: "Subluxação da sínfise (púbica) na gravidez, parto e puerpério",
            favorito: false
        },
        {
            cid10: "O268",
            descricao: "Outras afecções especificadas, ligadas a gravidez",
            favorito: false
        },
        {
            cid10: "O269",
            descricao: "Afecções ligadas a gravidez, não especificadas",
            favorito: false
        },
        {
            cid10: "O280",
            descricao: "Achados hematológicos anormais do rastreamento [screening ] antenatal da mãe",
            favorito: false
        },
        {
            cid10: "O281",
            descricao: "Achados bioquímicos anormais do rastreamento [screening ] antenatal da mãe",
            favorito: false
        },
        {
            cid10: "O282",
            descricao: "Achados citológicos anormais do rastreamento [screening ] antenatal da mãe",
            favorito: false
        },
        {
            cid10: "O283",
            descricao: "Achados ultrassonográficos anormais do rastreamento [screening ] antenatal da mãe",
            favorito: false
        },
        {
            cid10: "O284",
            descricao: "Achados radiológicos anormais do rastreamento [screening ] antenatal da mãe",
            favorito: false
        },
        {
            cid10: "O285",
            descricao: "Achados cromossômicos e genéticos anormais do rastreamento [screening ] antenatal da mãe",
            favorito: false
        },
        {
            cid10: "O288",
            descricao: "Outros achados anormais do rastreamento [screening ] antenatal da mãe",
            favorito: false
        },
        {
            cid10: "O289",
            descricao: "Achados anormais do rastreamento [screening ] antenatal da mãe, não especificados",
            favorito: false
        },
        {
            cid10: "O290",
            descricao: "Complicações pulmonares de anestesia administrada durante a gravidez",
            favorito: false
        },
        {
            cid10: "O291",
            descricao: "Complicações cardíacas de anestesia administrada durante a gravidez",
            favorito: false
        },
        {
            cid10: "O292",
            descricao: "Complicações relativas ao sistema nervoso central devidas a anestesia administrada durante a gravidez",
            favorito: false
        },
        {
            cid10: "O293",
            descricao: "Reação tóxica causada por anestesia local administrada durante a gravidez",
            favorito: false
        },
        {
            cid10: "O294",
            descricao: "Cefaléia causada por anestesia raquidiana ou peridural administradas durante a gravidez",
            favorito: false
        },
        {
            cid10: "O295",
            descricao: "Outras complicações de anestesia raquidiana ou peridural administradas durante a gravidez",
            favorito: false
        },
        {
            cid10: "O296",
            descricao: "Falha ou dificuldade na entubação durante a gravidez",
            favorito: false
        },
        {
            cid10: "O298",
            descricao: "Outras complicações de anestesia durante a gravidez",
            favorito: false
        },
        {
            cid10: "O299",
            descricao: "Complicações de anestesia durante a gravidez, não especificadas",
            favorito: false
        },
        {
            cid10: "O300",
            descricao: "Gravidez dupla",
            favorito: false
        },
        {
            cid10: "O301",
            descricao: "Gravidez trigemelar",
            favorito: false
        },
        {
            cid10: "O302",
            descricao: "Gravidez quadrigemelar",
            favorito: false
        },
        {
            cid10: "O308",
            descricao: "Outras gestações múltiplas",
            favorito: false
        },
        {
            cid10: "O309",
            descricao: "Gestação múltipla, não especificada",
            favorito: false
        },
        {
            cid10: "O310",
            descricao: "Feto papiráceo",
            favorito: false
        },
        {
            cid10: "O311",
            descricao: "Continuação da gravidez após aborto de um ou mais fetos",
            favorito: false
        },
        {
            cid10: "O312",
            descricao: "Continuação da gravidez após a morte intra-uterina de um ou mais fetos",
            favorito: false
        },
        {
            cid10: "O318",
            descricao: "Outras complicações específicas de gestação múltipla",
            favorito: false
        },
        {
            cid10: "O320",
            descricao: "Assistência prestada à mãe por posição fetal instável",
            favorito: false
        },
        {
            cid10: "O321",
            descricao: "Assistência prestada à mãe por apresentação pélvica",
            favorito: false
        },
        {
            cid10: "O322",
            descricao: "Assistência prestada à mãe por posição fetal transversa e oblíqua",
            favorito: false
        },
        {
            cid10: "O323",
            descricao: "Assistência prestada à mãe por apresentação de face, mento e fronte",
            favorito: false
        },
        {
            cid10: "O324",
            descricao: "Assistência prestada à mãe por polo cefálico alto em gestação a termo",
            favorito: false
        },
        {
            cid10: "O325",
            descricao: "Assistência prestada à mãe por gestação múltipla com apresentação anormal de um ou mais fetos",
            favorito: false
        },
        {
            cid10: "O326",
            descricao: "Assistência prestada à mãe por apresentação composta",
            favorito: false
        },
        {
            cid10: "O328",
            descricao: "Assistência prestada à mãe por outras formas de apresentação anormal do feto",
            favorito: false
        },
        {
            cid10: "O329",
            descricao: "Assistência prestada à mãe por apresentação anormal do feto, não especificada",
            favorito: false
        },
        {
            cid10: "O330",
            descricao: "Assistência prestada à mãe por uma desproporção devida à deformidade da pelve óssea materna",
            favorito: false
        },
        {
            cid10: "O331",
            descricao: "Assistência prestada à mãe por uma desproporção devida à contração geral da pelve",
            favorito: false
        },
        {
            cid10: "O332",
            descricao: "Assistência prestada à mãe por uma desproporção devida à contração do estreito superior da pelve",
            favorito: false
        },
        {
            cid10: "O333",
            descricao: "Assistência prestada à mãe por uma desproporção devida à contração do estreito inferior da pelve",
            favorito: false
        },
        {
            cid10: "O334",
            descricao: "Assistência prestada à mãe por uma desproporção de origem mista, materna e fetal",
            favorito: false
        },
        {
            cid10: "O335",
            descricao: "Assistência prestada à mãe por uma desproporção devida a feto excepcionalmente grande",
            favorito: false
        },
        {
            cid10: "O336",
            descricao: "Assistência prestada à mãe por uma desproporção devida a feto hidrocefálico",
            favorito: false
        },
        {
            cid10: "O337",
            descricao: "Assistência prestada à mãe por uma desproporção devida a outras deformidades fetais",
            favorito: false
        },
        {
            cid10: "O338",
            descricao: "Assistência prestada à mãe por uma desproporção de outra origem",
            favorito: false
        },
        {
            cid10: "O339",
            descricao: "Assistência prestada à mãe por uma desproporção, não especificada",
            favorito: false
        },
        {
            cid10: "O340",
            descricao: "Assistência à mãe por malformação congênita do útero",
            favorito: false
        },
        {
            cid10: "O341",
            descricao: "Assistência prestada à mãe por tumor do corpo do útero",
            favorito: false
        },
        {
            cid10: "O342",
            descricao: "Assistência prestada à mãe por cicatriz uterina devida a uma cirurgia anterior",
            favorito: false
        },
        {
            cid10: "O343",
            descricao: "Assistência prestada à mãe por incompetência cervical",
            favorito: false
        },
        {
            cid10: "O344",
            descricao: "Assistência prestada à mãe por outras anormalidades do colo do útero",
            favorito: false
        },
        {
            cid10: "O345",
            descricao: "Assistência prestada à mãe por outras anormalidades do útero gravídico",
            favorito: false
        },
        {
            cid10: "O346",
            descricao: "Assistência prestada à mãe por anormalidade da vagina",
            favorito: false
        },
        {
            cid10: "O347",
            descricao: "Assistência prestada à mãe por anormalidade da vulva e do períneo",
            favorito: false
        },
        {
            cid10: "O348",
            descricao: "Assistência prestada à mãe por outras anormalidades dos órgãos pélvicos",
            favorito: false
        },
        {
            cid10: "O349",
            descricao: "Assistência prestada à mãe por anormalidade de órgãos pélvicos, não especificada",
            favorito: false
        },
        {
            cid10: "O350",
            descricao: "Assistência prestada à mãe por malformação do sistema nervoso central do feto (suspeitada)",
            favorito: false
        },
        {
            cid10: "O351",
            descricao: "Assistência prestada à mãe por anormalidade cromossômica (suspeitada) do feto",
            favorito: false
        },
        {
            cid10: "O352",
            descricao: "Assistência prestada à mãe por doença hereditária (suspeitada) do feto",
            favorito: false
        },
        {
            cid10: "O353",
            descricao: "Assistência prestada à mãe por lesão fetal (suspeitada) causada por doença viral materna",
            favorito: false
        },
        {
            cid10: "O354",
            descricao: "Assistência prestada à mãe por lesão (suspeitada) causada ao feto por alcoolismo materno",
            favorito: false
        },
        {
            cid10: "O355",
            descricao: "Assistência prestada à mãe por lesão fetal (suspeitada) causada por drogas",
            favorito: false
        },
        {
            cid10: "O356",
            descricao: "Assistência prestada à mãe por lesão fetal (suspeitada) causada por radiação",
            favorito: false
        },
        {
            cid10: "O357",
            descricao: "Assistência prestada à mãe por lesão fetal (suspeitada) causada por outros procedimentos médicos",
            favorito: false
        },
        {
            cid10: "O358",
            descricao: "Assistência prestada à mãe por outras anormalidades ou lesões fetais (suspeitadas)",
            favorito: false
        },
        {
            cid10: "O359",
            descricao: "Assistência prestada à mãe por lesão ou anormalidade fetais (suspeitadas), não especificadas",
            favorito: false
        },
        {
            cid10: "O360",
            descricao: "Assistência prestada à mãe por isoimunização Rh",
            favorito: false
        },
        {
            cid10: "O361",
            descricao: "Assistência prestada à mãe por outros tipos de isoimunização",
            favorito: false
        },
        {
            cid10: "O362",
            descricao: "Assistência prestada à mãe por hidropsia fetal",
            favorito: false
        },
        {
            cid10: "O363",
            descricao: "Assistência prestada à mãe por sinais de hipóxia fetal",
            favorito: false
        },
        {
            cid10: "O364",
            descricao: "Assistência prestada à mãe por morte intra-uterina",
            favorito: false
        },
        {
            cid10: "O365",
            descricao: "Assistência prestada à mãe por insuficiência de crescimento fetal",
            favorito: false
        },
        {
            cid10: "O366",
            descricao: "Assistência prestada à mãe por crescimento fetal excessivo",
            favorito: false
        },
        {
            cid10: "O367",
            descricao: "Assistência prestada à mãe por feto viável em gravidez abdominal",
            favorito: false
        },
        {
            cid10: "O368",
            descricao: "Assistência prestada à mãe por outros problemas fetais especificados",
            favorito: false
        },
        {
            cid10: "O369",
            descricao: "Assistência prestada à mãe por problema fetal não especificado",
            favorito: false
        },
        {
            cid10: "O40",
            descricao: "Polihidrâmnio",
            favorito: false
        },
        {
            cid10: "O410",
            descricao: "Oligohidrâmnio",
            favorito: false
        },
        {
            cid10: "O411",
            descricao: "Infecção do saco amniótico e das membranas",
            favorito: false
        },
        {
            cid10: "O418",
            descricao: "Outros transtornos especificados do líquido amniótico e das membranas",
            favorito: false
        },
        {
            cid10: "O419",
            descricao: "Transtornos do líquido amniótico e das membranas não especificados",
            favorito: false
        },
        {
            cid10: "O420",
            descricao: "Ruptura prematura de membranas, com início do trabalho de parto dentro de 24 horas",
            favorito: false
        },
        {
            cid10: "O421",
            descricao: "Ruptura prematura de membranas, com início do trabalho de parto depois das primeiras 24 horas",
            favorito: false
        },
        {
            cid10: "O422",
            descricao: "Ruptura prematura de membranas, com trabalho de parto retardado por terapêutica",
            favorito: false
        },
        {
            cid10: "O429",
            descricao: "Ruptura prematura de membranas, não especificada",
            favorito: false
        },
        {
            cid10: "O430",
            descricao: "Síndromes de transfusão placentária",
            favorito: false
        },
        {
            cid10: "O431",
            descricao: "Malformação da placenta",
            favorito: false
        },
        {
            cid10: "O438",
            descricao: "Outros transtornos da placenta",
            favorito: false
        },
        {
            cid10: "O439",
            descricao: "Transtorno da placenta, não especificado",
            favorito: false
        },
        {
            cid10: "O440",
            descricao: "Placenta prévia especificada como sem hemorragia",
            favorito: false
        },
        {
            cid10: "O441",
            descricao: "Placenta prévia com hemorragia",
            favorito: false
        },
        {
            cid10: "O450",
            descricao: "Descolamento prematuro da placenta com deficiência de coagulação",
            favorito: false
        },
        {
            cid10: "O458",
            descricao: "Outro descolamento prematuro da placenta",
            favorito: false
        },
        {
            cid10: "O459",
            descricao: "Descolamento prematuro da placenta, não especificado",
            favorito: false
        },
        {
            cid10: "O460",
            descricao: "Hemorragia anteparto com deficiência de coagulação",
            favorito: false
        },
        {
            cid10: "O468",
            descricao: "Outra forma de hemorragia anteparto",
            favorito: false
        },
        {
            cid10: "O469",
            descricao: "Hemorragia anteparto, não especificada",
            favorito: false
        },
        {
            cid10: "O470",
            descricao: "Falso trabalho de parto antes de se completarem 37 semanas de gestação",
            favorito: false
        },
        {
            cid10: "O471",
            descricao: "Falso trabalho de parto na 37ª semana completa ou depois dela",
            favorito: false
        },
        {
            cid10: "O479",
            descricao: "Falso trabalho de parto, não especificado",
            favorito: false
        },
        {
            cid10: "O48",
            descricao: "Gravidez prolongada",
            favorito: false
        },
        {
            cid10: "O600",
            descricao: "Trabalho de parto pré-termo sem parto",
            favorito: false
        },
        {
            cid10: "O601",
            descricao: "Trabalho de parto pré-termo com parto pré-termo",
            favorito: false
        },
        {
            cid10: "O602",
            descricao: "Trabalho de parto pré-termo com parto a termo",
            favorito: false
        },
        {
            cid10: "O610",
            descricao: "Falha na indução médica do trabalho de parto",
            favorito: false
        },
        {
            cid10: "O611",
            descricao: "Falha na indução instrumental do trabalho de parto",
            favorito: false
        },
        {
            cid10: "O618",
            descricao: "Outras formas de falha na indução do trabalho de parto",
            favorito: false
        },
        {
            cid10: "O619",
            descricao: "Falha na indução do trabalho de parto, não especificada",
            favorito: false
        },
        {
            cid10: "O620",
            descricao: "Contrações iniciais inadequadas",
            favorito: false
        },
        {
            cid10: "O621",
            descricao: "Inércia uterina secundária",
            favorito: false
        },
        {
            cid10: "O622",
            descricao: "Outras formas de inércia uterina",
            favorito: false
        },
        {
            cid10: "O623",
            descricao: "Trabalho de parto precipitado",
            favorito: false
        },
        {
            cid10: "O624",
            descricao: "Contrações uterinas hipertônicas, incoordenadas ou prolongadas",
            favorito: false
        },
        {
            cid10: "O628",
            descricao: "Outras anormalidades da contração uterina",
            favorito: false
        },
        {
            cid10: "O629",
            descricao: "Anormalidades da contração uterina, não especificadas",
            favorito: false
        },
        {
            cid10: "O630",
            descricao: "Prolongamento do primeiro período (dilatação)",
            favorito: false
        },
        {
            cid10: "O631",
            descricao: "Prolongamento do segundo período (expulsão)",
            favorito: false
        },
        {
            cid10: "O632",
            descricao: "Parto retardado do segundo gêmeo, do terceiro gêmeo etc",
            favorito: false
        },
        {
            cid10: "O639",
            descricao: "Trabalho de parto prolongado, não especificado",
            favorito: false
        },
        {
            cid10: "O640",
            descricao: "Obstrução do trabalho de parto devida à rotação incompleta do polo cefálico fetal",
            favorito: false
        },
        {
            cid10: "O641",
            descricao: "Obstrução do trabalho de parto devida à apresentação pélvica",
            favorito: false
        },
        {
            cid10: "O642",
            descricao: "Obstrução do trabalho de parto devida à apresentação de face",
            favorito: false
        },
        {
            cid10: "O643",
            descricao: "Obstrução do trabalho de parto devida à apresentação de fronte",
            favorito: false
        },
        {
            cid10: "O644",
            descricao: "Obstrução do trabalho de parto devida à apresentação de ombro",
            favorito: false
        },
        {
            cid10: "O645",
            descricao: "Obstrução do trabalho de parto devida à apresentação complexa",
            favorito: false
        },
        {
            cid10: "O648",
            descricao: "Obstrução do trabalho de parto devida a outras formas de má-apresentação e má-posição",
            favorito: false
        },
        {
            cid10: "O649",
            descricao: "Obstrução do trabalho de parto devida a má-posição ou má-apresentação, não especificadas",
            favorito: false
        },
        {
            cid10: "O650",
            descricao: "Obstrução do trabalho de parto devida a deformidade pélvica",
            favorito: false
        },
        {
            cid10: "O651",
            descricao: "Obstrução do trabalho de parto devida à contração geral da pelve",
            favorito: false
        },
        {
            cid10: "O652",
            descricao: "Obstrução do trabalho de parto devida à contração do estreito superior",
            favorito: false
        },
        {
            cid10: "O653",
            descricao: "Obstrução do trabalho de parto devida à contração do estreito inferior ou da cavidade mediana",
            favorito: false
        },
        {
            cid10: "O654",
            descricao: "Obstrução do trabalho de parto devida a desproporção feto-pélvica, não especificada",
            favorito: false
        },
        {
            cid10: "O655",
            descricao: "Obstrução do trabalho de parto devida a anormalidade dos órgãos pélvicos maternos",
            favorito: false
        },
        {
            cid10: "O658",
            descricao: "Obstrução do trabalho de parto devida a outras anormalidades pélvicas maternas",
            favorito: false
        },
        {
            cid10: "O659",
            descricao: "Obstrução do trabalho de parto devida a anormalidades pélvicas maternas não especificadas",
            favorito: false
        },
        {
            cid10: "O660",
            descricao: "Obstrução do trabalho de parto devido a distócia de ombro",
            favorito: false
        },
        {
            cid10: "O661",
            descricao: "Obstrução do trabalho de parto por colisão de gêmeos",
            favorito: false
        },
        {
            cid10: "O662",
            descricao: "Obstrução do trabalho de parto por feto excepcionalmente grande",
            favorito: false
        },
        {
            cid10: "O663",
            descricao: "Obstrução do trabalho de parto devido a outras anormalidades do feto",
            favorito: false
        },
        {
            cid10: "O664",
            descricao: "Falha de tentativa de trabalho de parto, não especificada",
            favorito: false
        },
        {
            cid10: "O665",
            descricao: "Falha de aplicação de fórceps e vácuo-extrator, não especificada",
            favorito: false
        },
        {
            cid10: "O668",
            descricao: "Outras formas de obstrução do trabalho de parto, especificadas",
            favorito: false
        },
        {
            cid10: "O669",
            descricao: "Obstrução do trabalho de parto, não especificado",
            favorito: false
        },
        {
            cid10: "O670",
            descricao: "Hemorragia intraparto com deficiência de coagulação",
            favorito: false
        },
        {
            cid10: "O678",
            descricao: "Outras hemorragias intraparto",
            favorito: false
        },
        {
            cid10: "O679",
            descricao: "Hemorragia intraparto não especificada",
            favorito: false
        },
        {
            cid10: "O680",
            descricao: "Trabalho de parto e parto complicados por anormalidade da freqüência cardíaca fetal",
            favorito: false
        },
        {
            cid10: "O681",
            descricao: "Trabalho de parto e parto complicados por mecônio no líquido amniótico",
            favorito: false
        },
        {
            cid10: "O682",
            descricao: "Trabalho de parto e parto complicados por anormalidade na freqüência cardíaca fetal com mecônio no líquido amniótico",
            favorito: false
        },
        {
            cid10: "O683",
            descricao: "Trabalho de parto e parto complicados por evidências bioquímicas de sofrimento fetal",
            favorito: false
        },
        {
            cid10: "O688",
            descricao: "Trabalho de parto e parto complicados por outras evidências de sofrimento fetal",
            favorito: false
        },
        {
            cid10: "O689",
            descricao: "Trabalho de parto e parto complicados por sofrimento fetal, não especificado",
            favorito: false
        },
        {
            cid10: "O690",
            descricao: "Trabalho de parto e parto complicados por prolapso do cordão",
            favorito: false
        },
        {
            cid10: "O691",
            descricao: "Trabalho de parto e parto complicados por circular de cordão, com compressão",
            favorito: false
        },
        {
            cid10: "O692",
            descricao: "Trabalho de parto e parto complicados por outros enovelamentos do cordão",
            favorito: false
        },
        {
            cid10: "O693",
            descricao: "Trabalho de parto e parto complicados por cordão curto",
            favorito: false
        },
        {
            cid10: "O694",
            descricao: "Trabalho de parto e parto complicados por vasa praevia",
            favorito: false
        },
        {
            cid10: "O695",
            descricao: "Trabalho de parto e parto complicados por lesão vascular do cordão",
            favorito: false
        },
        {
            cid10: "O698",
            descricao: "Trabalho de parto e parto complicados por outras complicações do cordão",
            favorito: false
        },
        {
            cid10: "O699",
            descricao: "Trabalho de parto e parto complicados por complicações do cordão, não especificadas",
            favorito: false
        },
        {
            cid10: "O700",
            descricao: "Laceração de períneo de primeiro grau durante o parto",
            favorito: false
        },
        {
            cid10: "O701",
            descricao: "Laceração de períneo de segundo grau durante o parto",
            favorito: false
        },
        {
            cid10: "O702",
            descricao: "Laceração de períneo de terceiro grau durante o parto",
            favorito: false
        },
        {
            cid10: "O703",
            descricao: "Laceração de períneo de quarto grau durante o parto",
            favorito: false
        },
        {
            cid10: "O709",
            descricao: "Laceração de períneo durante o parto, não especificada",
            favorito: false
        },
        {
            cid10: "O710",
            descricao: "Ruptura do útero antes do início do trabalho de parto",
            favorito: false
        },
        {
            cid10: "O711",
            descricao: "Ruptura do útero durante o trabalho de parto",
            favorito: false
        },
        {
            cid10: "O712",
            descricao: "Inversão do útero pós-parto",
            favorito: false
        },
        {
            cid10: "O713",
            descricao: "Laceração obstétrica do colo do útero",
            favorito: false
        },
        {
            cid10: "O714",
            descricao: "Laceração vaginal alta isolada, de origem obstétrica",
            favorito: false
        },
        {
            cid10: "O715",
            descricao: "Outras lesões obstétricas aos órgãos pélvicos",
            favorito: false
        },
        {
            cid10: "O716",
            descricao: "Lesão obstétrica das articulações e dos ligamentos pélvicos",
            favorito: false
        },
        {
            cid10: "O717",
            descricao: "Hematoma obstétrico da pelve",
            favorito: false
        },
        {
            cid10: "O718",
            descricao: "Outros traumatismos obstétricos especificados",
            favorito: false
        },
        {
            cid10: "O719",
            descricao: "Traumatismo obstétrico, não especificado",
            favorito: false
        },
        {
            cid10: "O720",
            descricao: "Hemorragia do terceiro estágio",
            favorito: false
        },
        {
            cid10: "O721",
            descricao: "Outras hemorragias do pós-parto imediato",
            favorito: false
        },
        {
            cid10: "O722",
            descricao: "Hemorragias pós-parto, tardias e secundárias",
            favorito: false
        },
        {
            cid10: "O723",
            descricao: "Deficiências de coagulação pós-parto",
            favorito: false
        },
        {
            cid10: "O730",
            descricao: "Retenção da placenta sem hemorragia",
            favorito: false
        },
        {
            cid10: "O731",
            descricao: "Retenção de partes da placenta ou das membranas, sem hemorragia",
            favorito: false
        },
        {
            cid10: "O740",
            descricao: "Pneumonite aspirativa devida a anestesia durante o trabalho de parto e o parto",
            favorito: false
        },
        {
            cid10: "O741",
            descricao: "Outras complicações pulmonares devidas a anestesia durante o trabalho de parto e no parto",
            favorito: false
        },
        {
            cid10: "O742",
            descricao: "Complicações cardíacas devidas a anestesia durante o trabalho de parto e no parto",
            favorito: false
        },
        {
            cid10: "O743",
            descricao: "Complicações do sistema nervoso central devidas a anestesia durante o trabalho de parto e no parto",
            favorito: false
        },
        {
            cid10: "O744",
            descricao: "Reação tóxica a anestesia local durante trabalho de parto e o parto",
            favorito: false
        },
        {
            cid10: "O745",
            descricao: "Cefaléia pós-anestesia raquidiana e peridural, durante o trabalho de parto e parto",
            favorito: false
        },
        {
            cid10: "O746",
            descricao: "Outras complicações de anestesia raquidiana ou peridural, durante o trabalho de parto e parto",
            favorito: false
        },
        {
            cid10: "O747",
            descricao: "Falha ou dificuldade de entubação durante o trabalho de parto e parto",
            favorito: false
        },
        {
            cid10: "O748",
            descricao: "Outras complicações de anestesia durante o trabalho de parto e parto",
            favorito: false
        },
        {
            cid10: "O749",
            descricao: "Complicações de anestesia durante o trabalho de parto e parto, não especificadas",
            favorito: false
        },
        {
            cid10: "O750",
            descricao: "Sofrimento materno durante o trabalho de parto e o parto",
            favorito: false
        },
        {
            cid10: "O751",
            descricao: "Choque durante ou subseqüente ao trabalho de parto e o parto",
            favorito: false
        },
        {
            cid10: "O752",
            descricao: "Febre durante o trabalho de parto não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "O753",
            descricao: "Outras infecções durante o trabalho de parto",
            favorito: false
        },
        {
            cid10: "O754",
            descricao: "Outras complicações de procedimentos ou de cirurgia obstétrica",
            favorito: false
        },
        {
            cid10: "O755",
            descricao: "Demora do parto após ruptura artificial das membranas",
            favorito: false
        },
        {
            cid10: "O756",
            descricao: "Demora do parto após ruptura espontânea ou não especificada das membranas",
            favorito: false
        },
        {
            cid10: "O757",
            descricao: "Parto vaginal subseqüente a cesariana anterior",
            favorito: false
        },
        {
            cid10: "O758",
            descricao: "Outras complicações especificadas do trabalho de parto e do parto",
            favorito: false
        },
        {
            cid10: "O759",
            descricao: "Complicações do trabalho de parto e do parto, não especificadas",
            favorito: false
        },
        {
            cid10: "O800",
            descricao: "Parto espontâneo cefálico",
            favorito: false
        },
        {
            cid10: "O801",
            descricao: "Parto espontâneo pélvico",
            favorito: false
        },
        {
            cid10: "O808",
            descricao: "Outros tipos de parto único espontâneo",
            favorito: false
        },
        {
            cid10: "O809",
            descricao: "Parto único espontâneo, não especificado",
            favorito: false
        },
        {
            cid10: "O810",
            descricao: "Parto por fórceps baixo",
            favorito: false
        },
        {
            cid10: "O811",
            descricao: "Parto por fórceps da cavidade mediana",
            favorito: false
        },
        {
            cid10: "O812",
            descricao: "Fórceps da cavidade mediana com rotação",
            favorito: false
        },
        {
            cid10: "O813",
            descricao: "Outros tipos de parto por fórceps e os não especificados",
            favorito: false
        },
        {
            cid10: "O814",
            descricao: "Parto por vácuo-extrator",
            favorito: false
        },
        {
            cid10: "O815",
            descricao: "Parto por combinação de fórceps e vácuo-extrator",
            favorito: false
        },
        {
            cid10: "O820",
            descricao: "Parto por cesariana eletiva",
            favorito: false
        },
        {
            cid10: "O821",
            descricao: "Parto por cesariana de emergência",
            favorito: false
        },
        {
            cid10: "O822",
            descricao: "Parto por cesariana para histerectomia",
            favorito: false
        },
        {
            cid10: "O828",
            descricao: "Outros tipos de parto único por cesariana",
            favorito: false
        },
        {
            cid10: "O829",
            descricao: "Parto por cesariana, não especificada",
            favorito: false
        },
        {
            cid10: "O830",
            descricao: "Extração pélvica",
            favorito: false
        },
        {
            cid10: "O831",
            descricao: "Outros tipos de parto pélvico assistido",
            favorito: false
        },
        {
            cid10: "O832",
            descricao: "Outras formas de parto com o auxílio de manipulação",
            favorito: false
        },
        {
            cid10: "O833",
            descricao: "Parto de gravidez intra-abdominal com feto viável",
            favorito: false
        },
        {
            cid10: "O834",
            descricao: "Cirurgia destrutiva para facilitar o parto",
            favorito: false
        },
        {
            cid10: "O838",
            descricao: "Outros tipos especificados de parto único assistido",
            favorito: false
        },
        {
            cid10: "O839",
            descricao: "Parto único assistido, não especificado",
            favorito: false
        },
        {
            cid10: "O840",
            descricao: "Parto múltiplo, todos espontâneos",
            favorito: false
        },
        {
            cid10: "O841",
            descricao: "Parto múltiplo, todos por fórceps ou vácuo-extrator",
            favorito: false
        },
        {
            cid10: "O842",
            descricao: "Parto múltiplo, todos por cesariana",
            favorito: false
        },
        {
            cid10: "O848",
            descricao: "Outro tipo de parto múltiplo",
            favorito: false
        },
        {
            cid10: "O849",
            descricao: "Parto múltiplo, não especificado",
            favorito: false
        },
        {
            cid10: "O85",
            descricao: "Infecção puerperal",
            favorito: false
        },
        {
            cid10: "O860",
            descricao: "Infecção da incisão cirúrgica de origem obstétrica",
            favorito: false
        },
        {
            cid10: "O861",
            descricao: "Outras infecções dos órgãos genitais subseqüentes ao parto",
            favorito: false
        },
        {
            cid10: "O862",
            descricao: "Infecção das vias urinárias subseqüentes ao parto",
            favorito: false
        },
        {
            cid10: "O863",
            descricao: "Outras infecções das vias genitourinárias subseqüentes ao parto",
            favorito: false
        },
        {
            cid10: "O864",
            descricao: "Febre de origem desconhecida subseqüente ao parto",
            favorito: false
        },
        {
            cid10: "O868",
            descricao: "Outras infecções puerperais especificadas",
            favorito: false
        },
        {
            cid10: "O870",
            descricao: "Tromboflebite superficial no puerpério",
            favorito: false
        },
        {
            cid10: "O871",
            descricao: "Tromboflebite profunda no puerpério",
            favorito: false
        },
        {
            cid10: "O872",
            descricao: "Hemorróidas no puerpério",
            favorito: false
        },
        {
            cid10: "O873",
            descricao: "Trombose venosa cerebral no puerpério",
            favorito: false
        },
        {
            cid10: "O878",
            descricao: "Outras complicações venosas no puerpério",
            favorito: false
        },
        {
            cid10: "O879",
            descricao: "Complicações venosas no puerpério, não especificadas",
            favorito: false
        },
        {
            cid10: "O880",
            descricao: "Embolia gasosa de origem obstétrica",
            favorito: false
        },
        {
            cid10: "O881",
            descricao: "Embolia amniótica",
            favorito: false
        },
        {
            cid10: "O882",
            descricao: "Embolia obstétrica por coágulo de sangue",
            favorito: false
        },
        {
            cid10: "O883",
            descricao: "Embolia séptica ou piêmica de origem obstétrica",
            favorito: false
        },
        {
            cid10: "O888",
            descricao: "Outras embolias de origem obstétrica",
            favorito: false
        },
        {
            cid10: "O890",
            descricao: "Complicações pulmonares da anestesia durante o puerpério",
            favorito: false
        },
        {
            cid10: "O891",
            descricao: "Complicações cardíacas devidas a anestesia durante o puerpério",
            favorito: false
        },
        {
            cid10: "O892",
            descricao: "Complicações relativas ao sistema nervoso central devidas a anestesia durante o puerpério",
            favorito: false
        },
        {
            cid10: "O893",
            descricao: "Reação tóxica a uma anestesia local durante o puerpério",
            favorito: false
        },
        {
            cid10: "O894",
            descricao: "Cefaléia provocada por uma anestesia raquidiana ou peridural, durante o puerpério",
            favorito: false
        },
        {
            cid10: "O895",
            descricao: "Outras complicações de anestesia raquidiana ou peridural, durante o puerpério",
            favorito: false
        },
        {
            cid10: "O896",
            descricao: "Falha na ou dificuldade de entubação, durante o puerpério",
            favorito: false
        },
        {
            cid10: "O898",
            descricao: "Outras complicações da anestesia durante o puerpério",
            favorito: false
        },
        {
            cid10: "O899",
            descricao: "Complicação devida a anestesia, durante o puerpério, não especificada",
            favorito: false
        },
        {
            cid10: "O900",
            descricao: "Ruptura da incisão de cesariana",
            favorito: false
        },
        {
            cid10: "O901",
            descricao: "Ruptura da incisão obstétrica, no períneo",
            favorito: false
        },
        {
            cid10: "O902",
            descricao: "Hematoma da incisão obstétrica",
            favorito: false
        },
        {
            cid10: "O903",
            descricao: "Cardiomiopatia no puerpério",
            favorito: false
        },
        {
            cid10: "O904",
            descricao: "Insuficiência renal aguda do pós-parto",
            favorito: false
        },
        {
            cid10: "O905",
            descricao: "Tireoidite do pós-parto",
            favorito: false
        },
        {
            cid10: "O908",
            descricao: "Outras complicações do puerpério, não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "O909",
            descricao: "Complicação do puerpério não especificada",
            favorito: false
        },
        {
            cid10: "O910",
            descricao: "Infecção do mamilo associada ao parto",
            favorito: false
        },
        {
            cid10: "O911",
            descricao: "Abscesso da mama associada ao parto",
            favorito: false
        },
        {
            cid10: "O912",
            descricao: "Mastite não purulenta associada ao parto",
            favorito: false
        },
        {
            cid10: "O920",
            descricao: "Mamilo retraído associado ao parto",
            favorito: false
        },
        {
            cid10: "O921",
            descricao: "Fissuras do mamilo associadas ao parto",
            favorito: false
        },
        {
            cid10: "O922",
            descricao: "Outras afecções da mama, e as não especificadas, associadas ao parto",
            favorito: false
        },
        {
            cid10: "O923",
            descricao: "Agalactia",
            favorito: false
        },
        {
            cid10: "O924",
            descricao: "Hipogalactia",
            favorito: false
        },
        {
            cid10: "O925",
            descricao: "Suspensão da lactação",
            favorito: false
        },
        {
            cid10: "O926",
            descricao: "Galactorréia",
            favorito: false
        },
        {
            cid10: "O927",
            descricao: "Outros distúrbios da lactação e os não especificados",
            favorito: false
        },
        {
            cid10: "O94",
            descricao: "Seqüelas de complicações da gravidez, parto e puerpério",
            favorito: false
        },
        {
            cid10: "O95",
            descricao: "Morte obstétrica de causa não especificada",
            favorito: false
        },
        {
            cid10: "O96",
            descricao: "Morte, por qualquer causa obstétrica, que ocorre mais de 42 dias, mas menos de 1 ano, após o parto",
            favorito: false
        },
        {
            cid10: "O97",
            descricao: "Morte por seqüelas de causas obstétricas diretas",
            favorito: false
        },
        {
            cid10: "O980",
            descricao: "Tuberculose complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "O981",
            descricao: "Sífilis complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "O982",
            descricao: "Gonorréia complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "O983",
            descricao: "Outras infecções em que a via de transmissão é predominantemente sexual, complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "O984",
            descricao: "Hepatite viral complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "O985",
            descricao: "Outras doenças virais complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "O986",
            descricao: "Doenças causadas por protozoários complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "O988",
            descricao: "Outras doenças infecciosas e parasitárias maternas complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "O989",
            descricao: "Doenças infecciosas e parasitárias maternas, não especificadas, complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "O990",
            descricao: "Anemia complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "O991",
            descricao: "Outras doenças do sangue e dos órgãos hematopoéticos e alguns transtornos que comprometem o sistema imunológico, complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "O992",
            descricao: "Doenças endócrinas, nutricionais e metabólicas complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "O993",
            descricao: "Transtornos mentais e doenças do sistema nervoso complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "O994",
            descricao: "Doenças do aparelho circulatório complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "O995",
            descricao: "Doenças do aparelho respiratório complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "O996",
            descricao: "Doenças do aparelho digestivo complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "O997",
            descricao: "Doenças da pele e do tecido subcutâneo complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "O998",
            descricao: "Outras doenças e afecções especificadas complicando a gravidez, o parto e o puerpério",
            favorito: false
        },
        {
            cid10: "P000",
            descricao: "Feto e recém-nascido afetados por transtornos maternos hipertensivos",
            favorito: false
        },
        {
            cid10: "P001",
            descricao: "Feto e recém-nascido afetados por doenças maternas renais e das vias urinárias",
            favorito: false
        },
        {
            cid10: "P002",
            descricao: "Feto e recém-nascido afetados por doenças infecciosas e parasitárias da mãe",
            favorito: false
        },
        {
            cid10: "P003",
            descricao: "Feto e recém-nascido afetados por outras doenças circulatórias e respiratórias maternas",
            favorito: false
        },
        {
            cid10: "P004",
            descricao: "Feto e recém-nascido afetados por transtornos nutricionais maternos",
            favorito: false
        },
        {
            cid10: "P005",
            descricao: "Feto e recém-nascido afetados por traumatismo materno",
            favorito: false
        },
        {
            cid10: "P006",
            descricao: "Feto e recém-nascido afetados por intervenção cirúrgica na mãe",
            favorito: false
        },
        {
            cid10: "P007",
            descricao: "Feto e recém-nascido afetados por outros procedimentos médicos na mãe, não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "P008",
            descricao: "Feto e recém-nascido afetados por outras afecções maternas",
            favorito: false
        },
        {
            cid10: "P009",
            descricao: "Feto e recém-nascido afetados por afecção materna não especificada",
            favorito: false
        },
        {
            cid10: "P010",
            descricao: "Feto e recém-nascido afetados por incompetência do colo uterino",
            favorito: false
        },
        {
            cid10: "P011",
            descricao: "Feto e recém-nascido afetados por ruptura prematura das membranas",
            favorito: false
        },
        {
            cid10: "P012",
            descricao: "Feto e recém-nascido afetados por oligohidrâmnio",
            favorito: false
        },
        {
            cid10: "P013",
            descricao: "Feto e recém-nascido afetados por polihidrâmnio",
            favorito: false
        },
        {
            cid10: "P014",
            descricao: "Feto e recém-nascido afetados por gravidez ectópica",
            favorito: false
        },
        {
            cid10: "P015",
            descricao: "Feto e recém-nascido afetados por gravidez múltipla",
            favorito: false
        },
        {
            cid10: "P016",
            descricao: "Feto e recém-nascido afetados por morte materna",
            favorito: false
        },
        {
            cid10: "P017",
            descricao: "Feto e recém-nascido afetados por apresentação anormal antes do trabalho de parto",
            favorito: false
        },
        {
            cid10: "P018",
            descricao: "Feto e recém-nascido afetados por outras complicações maternas da gravidez",
            favorito: false
        },
        {
            cid10: "P019",
            descricao: "Feto e recém-nascido afetados por afecções maternas da gravidez, não especificadas",
            favorito: false
        },
        {
            cid10: "P020",
            descricao: "Feto e recém-nascido afetados por placenta prévia",
            favorito: false
        },
        {
            cid10: "P021",
            descricao: "Feto e recém-nascido afetados por outras formas de descolamento da placenta e hemorragia",
            favorito: false
        },
        {
            cid10: "P022",
            descricao: "Feto e recém-nascido afetados por outras anormalidades morfológicas e funcionais da placenta e as não especificadas",
            favorito: false
        },
        {
            cid10: "P023",
            descricao: "Feto e recém-nascido afetados por síndromes de transfusão placentária",
            favorito: false
        },
        {
            cid10: "P024",
            descricao: "Feto e recém-nascido afetados por prolapso de cordão umbilical",
            favorito: false
        },
        {
            cid10: "P025",
            descricao: "Feto e recém-nascido afetados por outras compressões do cordão umbilical",
            favorito: false
        },
        {
            cid10: "P026",
            descricao: "Feto e recém-nascido afetados por outras afecções do cordão umbilical e as não especificadas",
            favorito: false
        },
        {
            cid10: "P027",
            descricao: "Feto e recém-nascido afetados por corioamnionite",
            favorito: false
        },
        {
            cid10: "P028",
            descricao: "Feto e recém-nascido afetados por outras anormalidades das membranas",
            favorito: false
        },
        {
            cid10: "P029",
            descricao: "Feto e recém-nascido afetados por anormalidade não especificada das membranas",
            favorito: false
        },
        {
            cid10: "P030",
            descricao: "Feto e recém-nascido afetados por parto e extração pélvicas",
            favorito: false
        },
        {
            cid10: "P031",
            descricao: "Feto e recém-nascido afetados por outras apresentações anormais, má-posições e desproporções durante o trabalho de parto e o parto",
            favorito: false
        },
        {
            cid10: "P032",
            descricao: "Feto e recém-nascido afetados por parto por fórceps",
            favorito: false
        },
        {
            cid10: "P033",
            descricao: "Feto e recém-nascido afetados por parto por vácuo-extrator [ventosa]",
            favorito: false
        },
        {
            cid10: "P034",
            descricao: "Feto e recém-nascido afetados por parto por cesariana",
            favorito: false
        },
        {
            cid10: "P035",
            descricao: "Feto e recém-nascido afetados por parto precipitado",
            favorito: false
        },
        {
            cid10: "P036",
            descricao: "Feto e recém-nascido afetados por contrações uterinas anormais",
            favorito: false
        },
        {
            cid10: "P038",
            descricao: "Feto e recém-nascido afetados por outras complicações especificadas do trabalho de parto e do parto",
            favorito: false
        },
        {
            cid10: "P039",
            descricao: "Feto e recém-nascido afetados por complicações não especificadas do trabalho de parto e do parto",
            favorito: false
        },
        {
            cid10: "P040",
            descricao: "Feto e recém-nascido afetados por anestesia e analgesia materna durante a gravidez, trabalho de parto e parto",
            favorito: false
        },
        {
            cid10: "P041",
            descricao: "Feto e recém-nascido afetados por outros medicamentos utilizados pela mãe",
            favorito: false
        },
        {
            cid10: "P042",
            descricao: "Feto e recém-nascido afetados pelo uso de fumo pela mãe",
            favorito: false
        },
        {
            cid10: "P043",
            descricao: "Feto e recém-nascido afetados pelo uso de álcool pela mãe",
            favorito: false
        },
        {
            cid10: "P044",
            descricao: "Feto e recém-nascido afetados pelo uso de drogas que causam dependência pela mãe",
            favorito: false
        },
        {
            cid10: "P045",
            descricao: "Feto e recém-nascido afetados por uso materno de substâncias químicas dos alimentos",
            favorito: false
        },
        {
            cid10: "P046",
            descricao: "Feto e recém-nascido afetados pela exposição da mãe a substâncias químicas do meio ambiente",
            favorito: false
        },
        {
            cid10: "P048",
            descricao: "Feto e recém-nascido afetados por outras influências nocivas maternas",
            favorito: false
        },
        {
            cid10: "P049",
            descricao: "Feto e recém-nascido afetados por influências nocivas maternas não especificadas",
            favorito: false
        },
        {
            cid10: "P050",
            descricao: "Recém-nascido de baixo peso para a idade gestacional",
            favorito: false
        },
        {
            cid10: "P051",
            descricao: "Pequeno para a idade gestacional",
            favorito: false
        },
        {
            cid10: "P052",
            descricao: "Desnutrição fetal sem menção de peso e comprimento baixos para a idade gestacional",
            favorito: false
        },
        {
            cid10: "P059",
            descricao: "Retardo não especificado do crescimento fetal",
            favorito: false
        },
        {
            cid10: "P070",
            descricao: "Recém-nascido com peso muito baixo",
            favorito: false
        },
        {
            cid10: "P071",
            descricao: "Outros recém-nascidos de peso baixo",
            favorito: false
        },
        {
            cid10: "P072",
            descricao: "Imaturidade extrema",
            favorito: false
        },
        {
            cid10: "P073",
            descricao: "Outros recém-nascidos de pré-termo",
            favorito: false
        },
        {
            cid10: "P080",
            descricao: "Recém-nascido de tamanho excessivamente grande",
            favorito: false
        },
        {
            cid10: "P081",
            descricao: "Outros recém-nascidos grandes para a idade gestacional",
            favorito: false
        },
        {
            cid10: "P082",
            descricao: "Recém-nascido pós-termo, não-grande para a idade gestacional",
            favorito: false
        },
        {
            cid10: "P100",
            descricao: "Hemorragia subdural devida a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P101",
            descricao: "Hemorragia cerebral devida a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P102",
            descricao: "Hemorragia intraventricular devida a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P103",
            descricao: "Hemorragia subaracnoídea devida a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P104",
            descricao: "Ruptura tentorial devida a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P108",
            descricao: "Outras lacerações e hemorragias intracranianas devidas a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P109",
            descricao: "Laceração e hemorragia intracranianas não especificadas devidas a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P110",
            descricao: "Edema cerebral devido a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P111",
            descricao: "Outras lesões cerebrais especificadas devidas a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P112",
            descricao: "Lesão cerebral não especificada devida a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P113",
            descricao: "Traumatismo de parto do nervo facial",
            favorito: false
        },
        {
            cid10: "P114",
            descricao: "Traumatismo de parto de outros nervos cranianos",
            favorito: false
        },
        {
            cid10: "P115",
            descricao: "Traumatismo de parto da coluna e da medula espinhal",
            favorito: false
        },
        {
            cid10: "P119",
            descricao: "Traumatismo de parto não especificado do sistema nervoso central",
            favorito: false
        },
        {
            cid10: "P120",
            descricao: "Céfalo-hematoma devido a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P121",
            descricao: "Chignon devido a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P122",
            descricao: "Hemorragia subaponeurótica epicraniana devida a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P123",
            descricao: "Esmagamento do couro cabeludo devido a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P124",
            descricao: "Lesão por monitorização do couro cabeludo do recém-nascido",
            favorito: false
        },
        {
            cid10: "P128",
            descricao: "Outras lesões do couro cabeludo devidas a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P129",
            descricao: "Lesão não especificada do couro cabeludo devida a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P130",
            descricao: "Fratura de crânio devida a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P131",
            descricao: "Outras lesões cranianas devidas a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P132",
            descricao: "Lesão do fêmur devida a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P133",
            descricao: "Lesão de outros ossos longos devida a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P134",
            descricao: "Fratura da clavícula devida a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P138",
            descricao: "Lesões de outras partes do esqueleto devidas a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P139",
            descricao: "Lesões não especificadas do esqueleto devidas a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P140",
            descricao: "Paralisia de Erb devida a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P141",
            descricao: "Paralisia de Klumpke devida a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P142",
            descricao: "Paralisia do nervo frênico devida a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P143",
            descricao: "Outras lesões do plexo braquial devidas a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P148",
            descricao: "Outras lesões de outras partes do sistema nervoso periférico devidas a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P149",
            descricao: "Lesão não especificada do sistema nervoso periférico devida a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P150",
            descricao: "Lesão do fígado devida a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P151",
            descricao: "Lesão do baço devida a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P152",
            descricao: "Lesão do esternomastóide devida a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P153",
            descricao: "Lesão dos olhos devida a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P154",
            descricao: "Lesão da face ao nascer",
            favorito: false
        },
        {
            cid10: "P155",
            descricao: "Lesão dos órgãos genitais externos, devida a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P156",
            descricao: "Necrose de gordura subcutânea devida a traumatismo de parto",
            favorito: false
        },
        {
            cid10: "P158",
            descricao: "Outros traumatismos de parto especificados",
            favorito: false
        },
        {
            cid10: "P159",
            descricao: "Traumatismo de parto não especificado",
            favorito: false
        },
        {
            cid10: "P200",
            descricao: "Hipóxia intra-uterina diagnosticada antes do início do trabalho de parto",
            favorito: false
        },
        {
            cid10: "P201",
            descricao: "Hipóxia intra-uterina diagnosticada durante o trabalho de parto e o parto",
            favorito: false
        },
        {
            cid10: "P209",
            descricao: "Hipóxia intra-uterina não especificada",
            favorito: false
        },
        {
            cid10: "P210",
            descricao: "Asfixia grave ao nascer",
            favorito: false
        },
        {
            cid10: "P211",
            descricao: "Asfixia leve ou moderada ao nascer",
            favorito: false
        },
        {
            cid10: "P219",
            descricao: "Asfixia ao nascer, não especificada",
            favorito: false
        },
        {
            cid10: "P220",
            descricao: "Síndrome da angústia respiratória do recém-nascido",
            favorito: false
        },
        {
            cid10: "P221",
            descricao: "Taquipnéia transitória do recém-nascido",
            favorito: false
        },
        {
            cid10: "P228",
            descricao: "Outros desconfortos respiratórios do recém-nascido",
            favorito: false
        },
        {
            cid10: "P229",
            descricao: "Desconforto respiratório não especificado do recém-nascido",
            favorito: false
        },
        {
            cid10: "P230",
            descricao: "Pneumonia congênita devida a agente viral",
            favorito: false
        },
        {
            cid10: "P231",
            descricao: "Pneumonia congênita devida a Clamídia",
            favorito: false
        },
        {
            cid10: "P232",
            descricao: "Pneumonia congênita devida a estafilococo",
            favorito: false
        },
        {
            cid10: "P233",
            descricao: "Pneumonia congênita devida a estreptococo do grupo B",
            favorito: false
        },
        {
            cid10: "P234",
            descricao: "Pneumonia congênita devida a Escherichia coli",
            favorito: false
        },
        {
            cid10: "P235",
            descricao: "Pneumonia congênita devida a Pseudomonas",
            favorito: false
        },
        {
            cid10: "P236",
            descricao: "Pneumonia congênita devida a outros agentes bacterianos",
            favorito: false
        },
        {
            cid10: "P238",
            descricao: "Pneumonia congênita devida a outros organismos",
            favorito: false
        },
        {
            cid10: "P239",
            descricao: "Pneumonia congênita não especificada",
            favorito: false
        },
        {
            cid10: "P240",
            descricao: "Aspiração neonatal de mecônio",
            favorito: false
        },
        {
            cid10: "P241",
            descricao: "Aspiração neonatal de líquido amniótico e muco",
            favorito: false
        },
        {
            cid10: "P242",
            descricao: "Aspiração neonatal de sangue",
            favorito: false
        },
        {
            cid10: "P243",
            descricao: "Aspiração neonatal de leite e alimento regurgitados",
            favorito: false
        },
        {
            cid10: "P248",
            descricao: "Outras síndromes de aspiração neonatais",
            favorito: false
        },
        {
            cid10: "P249",
            descricao: "Síndrome de aspiração neonatal não especificada",
            favorito: false
        },
        {
            cid10: "P250",
            descricao: "Enfisema intersticial originado no período perinatal",
            favorito: false
        },
        {
            cid10: "P251",
            descricao: "Pneumotórax originado no período perinatal",
            favorito: false
        },
        {
            cid10: "P252",
            descricao: "Pneumomediastino originado no período perinatal",
            favorito: false
        },
        {
            cid10: "P253",
            descricao: "Pneumopericárdio originado no período perinatal",
            favorito: false
        },
        {
            cid10: "P258",
            descricao: "Outras afecções relacionadas com o enfisema intersticial originadas no período perinatal",
            favorito: false
        },
        {
            cid10: "P260",
            descricao: "Hemorragia traqueobrônquica originada no período perinatal",
            favorito: false
        },
        {
            cid10: "P261",
            descricao: "Hemorragia pulmonar maciça originada no período perinatal",
            favorito: false
        },
        {
            cid10: "P268",
            descricao: "Outras hemorragias pulmonares originadas no período perinatal",
            favorito: false
        },
        {
            cid10: "P269",
            descricao: "Hemorragia pulmonar não especificada originada no período perinatal",
            favorito: false
        },
        {
            cid10: "P270",
            descricao: "Síndrome de Wilson-Mikity",
            favorito: false
        },
        {
            cid10: "P271",
            descricao: "Displasia broncopulmonar originada no período perinatal",
            favorito: false
        },
        {
            cid10: "P278",
            descricao: "Outras doenças respiratórias crônicas originadas no período perinatal",
            favorito: false
        },
        {
            cid10: "P279",
            descricao: "Doença respiratória crônica não especificada originada no período perinatal",
            favorito: false
        },
        {
            cid10: "P280",
            descricao: "Atelectasia primária do recém-nascido",
            favorito: false
        },
        {
            cid10: "P281",
            descricao: "Outras atelectasias do recém-nascido e as não especificadas",
            favorito: false
        },
        {
            cid10: "P282",
            descricao: "Crises cianóticas do recém-nascido",
            favorito: false
        },
        {
            cid10: "P283",
            descricao: "Apnéia primária do sono do recém-nascido",
            favorito: false
        },
        {
            cid10: "P284",
            descricao: "Outras apnéias do recém-nascido",
            favorito: false
        },
        {
            cid10: "P285",
            descricao: "Insuficiência respiratória do recém-nascido",
            favorito: false
        },
        {
            cid10: "P288",
            descricao: "Outras afecções respiratórias especificadas do recém-nascido",
            favorito: false
        },
        {
            cid10: "P289",
            descricao: "Afecção respiratória do recém-nascido, não especificada",
            favorito: false
        },
        {
            cid10: "P290",
            descricao: "Insuficiência cardíaca neonatal",
            favorito: false
        },
        {
            cid10: "P291",
            descricao: "Disritmia cardíaca neonatal",
            favorito: false
        },
        {
            cid10: "P292",
            descricao: "Hipertensão neonatal",
            favorito: false
        },
        {
            cid10: "P293",
            descricao: "Persistência da circulação fetal",
            favorito: false
        },
        {
            cid10: "P294",
            descricao: "Isquemia miocárdica transitória do recém-nascido",
            favorito: false
        },
        {
            cid10: "P298",
            descricao: "Outros transtornos cardiovasculares originados no período perinatal",
            favorito: false
        },
        {
            cid10: "P299",
            descricao: "Transtornos cardiovasculares não especificados originados no período perinatal",
            favorito: false
        },
        {
            cid10: "P350",
            descricao: "Síndrome da rubéola congênita",
            favorito: false
        },
        {
            cid10: "P351",
            descricao: "Infecção congênita por citomegalovirus",
            favorito: false
        },
        {
            cid10: "P352",
            descricao: "Infecção congênita por vírus do herpes [simples]",
            favorito: false
        },
        {
            cid10: "P353",
            descricao: "Hepatite viral congênita",
            favorito: false
        },
        {
            cid10: "P358",
            descricao: "Outras doenças virais congênitas",
            favorito: false
        },
        {
            cid10: "P359",
            descricao: "Doença viral congênita não especificada",
            favorito: false
        },
        {
            cid10: "P360",
            descricao: "Septicemia do recém-nascido devida a estreptococo do grupo B",
            favorito: false
        },
        {
            cid10: "P361",
            descricao: "Septicemia do recém-nascido devida a outros estreptococos e aos não especificados",
            favorito: false
        },
        {
            cid10: "P362",
            descricao: "Septicemia do recém-nascido devida ao Stafilococcus aureus",
            favorito: false
        },
        {
            cid10: "P363",
            descricao: "Septicemia do recém-nascido devida a outros estafilococos e aos não especificados",
            favorito: false
        },
        {
            cid10: "P364",
            descricao: "Septicemia do recém-nascido devida a Escherichia coli",
            favorito: false
        },
        {
            cid10: "P365",
            descricao: "Septicemia do recém-nascido devida a anaeróbios",
            favorito: false
        },
        {
            cid10: "P368",
            descricao: "Outras septicemias bacterianas do recém-nascido",
            favorito: false
        },
        {
            cid10: "P369",
            descricao: "Septicemia bacteriana não especificada do recém-nascido",
            favorito: false
        },
        {
            cid10: "P370",
            descricao: "Tuberculose congênita",
            favorito: false
        },
        {
            cid10: "P371",
            descricao: "Toxoplasmose congênita",
            favorito: false
        },
        {
            cid10: "P372",
            descricao: "Listeriose neonatal (disseminada)",
            favorito: false
        },
        {
            cid10: "P373",
            descricao: "Malária falciparum congênita",
            favorito: false
        },
        {
            cid10: "P374",
            descricao: "Outras malárias congênitas",
            favorito: false
        },
        {
            cid10: "P375",
            descricao: "Candidíase neonatal",
            favorito: false
        },
        {
            cid10: "P378",
            descricao: "Outras doenças infecciosas e parasitárias congênitas especificadas",
            favorito: false
        },
        {
            cid10: "P379",
            descricao: "Doença infecciosa ou parasitária congênita não especificada",
            favorito: false
        },
        {
            cid10: "P38",
            descricao: "Onfalite do recém-nascido com ou sem hemorragia leve",
            favorito: false
        },
        {
            cid10: "P390",
            descricao: "Mastite infecciosa neonatal",
            favorito: false
        },
        {
            cid10: "P391",
            descricao: "Conjuntivite e dacriocistite neonatais",
            favorito: false
        },
        {
            cid10: "P392",
            descricao: "Infecção intra-amniótica do feto não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "P393",
            descricao: "Infecção neonatal do trato urinário",
            favorito: false
        },
        {
            cid10: "P394",
            descricao: "Infecção neonatal da pele",
            favorito: false
        },
        {
            cid10: "P398",
            descricao: "Outras infecções especificadas próprias do período perinatal",
            favorito: false
        },
        {
            cid10: "P399",
            descricao: "Infecção própria do período perinatal não especificada",
            favorito: false
        },
        {
            cid10: "P500",
            descricao: "Perda sangüínea fetal devida a vasa prévia",
            favorito: false
        },
        {
            cid10: "P501",
            descricao: "Perda sangüínea fetal por ruptura do cordão",
            favorito: false
        },
        {
            cid10: "P502",
            descricao: "Perda sangüínea fetal originada da placenta",
            favorito: false
        },
        {
            cid10: "P503",
            descricao: "Hemorragia para a circulação do outro gêmeo",
            favorito: false
        },
        {
            cid10: "P504",
            descricao: "Hemorragia para a circulação materna",
            favorito: false
        },
        {
            cid10: "P505",
            descricao: "Perda sangüínea devido a secção do cordão umbilical do outro gemelar",
            favorito: false
        },
        {
            cid10: "P508",
            descricao: "Outras perdas sangüíneas fetais",
            favorito: false
        },
        {
            cid10: "P509",
            descricao: "Perda sangüínea fetal não especificada",
            favorito: false
        },
        {
            cid10: "P510",
            descricao: "Hemorragia umbilical maciça do recém-nascido",
            favorito: false
        },
        {
            cid10: "P518",
            descricao: "Outras hemorragias umbilicais do recém-nascido",
            favorito: false
        },
        {
            cid10: "P519",
            descricao: "Hemorragia umbilical do recém-nascido, não especificada",
            favorito: false
        },
        {
            cid10: "P520",
            descricao: "Hemorragia intraventricular (não-traumática) grau 1, do feto e do recém-nascido",
            favorito: false
        },
        {
            cid10: "P521",
            descricao: "Hemorragia intraventricular (não-traumática) grau 2, do feto e do recém-nascido",
            favorito: false
        },
        {
            cid10: "P522",
            descricao: "Hemorragia intraventricular (não-traumática) grau 3, do feto e do recém-nascido",
            favorito: false
        },
        {
            cid10: "P523",
            descricao: "Hemorragia intraventricular (não-traumática) não especificada, do feto ou do recém-nascido",
            favorito: false
        },
        {
            cid10: "P524",
            descricao: "Hemorragia intracerebral (não-traumática) do feto e do recém-nascido",
            favorito: false
        },
        {
            cid10: "P525",
            descricao: "Hemorragia subaracnoídea (não-traumática) do feto e do recém-nascido",
            favorito: false
        },
        {
            cid10: "P526",
            descricao: "Hemorragia cerebelar (não-traumática) e da fossa posterior do feto ou recém-nascido",
            favorito: false
        },
        {
            cid10: "P528",
            descricao: "Outras hemorragias intracranianas (não-traumáticas) do feto e do recém-nascido",
            favorito: false
        },
        {
            cid10: "P529",
            descricao: "Hemorragia intracraniana (não-traumática) do feto e do recém-nascido, não especificada",
            favorito: false
        },
        {
            cid10: "P53",
            descricao: "Doença hemorrágica do feto e do recém-nascido",
            favorito: false
        },
        {
            cid10: "P540",
            descricao: "Hematêmese neonatal",
            favorito: false
        },
        {
            cid10: "P541",
            descricao: "Melena neonatal",
            favorito: false
        },
        {
            cid10: "P542",
            descricao: "Hemorragia retal neonatal",
            favorito: false
        },
        {
            cid10: "P543",
            descricao: "Outras hemorragias gastrointestinais do período neonatal",
            favorito: false
        },
        {
            cid10: "P544",
            descricao: "Hemorragia supra-renal neonatal",
            favorito: false
        },
        {
            cid10: "P545",
            descricao: "Hemorragia cutânea neonatal",
            favorito: false
        },
        {
            cid10: "P546",
            descricao: "Hemorragia vaginal neonatal",
            favorito: false
        },
        {
            cid10: "P548",
            descricao: "Outras hemorragias fetais e neonatais especificadas",
            favorito: false
        },
        {
            cid10: "P549",
            descricao: "Hemorragia fetal e neonatal não especificada",
            favorito: false
        },
        {
            cid10: "P550",
            descricao: "Isoimunização Rh do feto e do recém-nascido",
            favorito: false
        },
        {
            cid10: "P551",
            descricao: "Isoimunização ABO do feto e do recém-nascido",
            favorito: false
        },
        {
            cid10: "P558",
            descricao: "Outras doenças hemolíticas do feto e do recém-nascido",
            favorito: false
        },
        {
            cid10: "P559",
            descricao: "Doença hemolítica não especificada do feto e do recém-nascido",
            favorito: false
        },
        {
            cid10: "P560",
            descricao: "Hidropsia fetal devida à isoimunização",
            favorito: false
        },
        {
            cid10: "P569",
            descricao: "Hidropsia fetal devida a outras doenças hemolíticas e às não especificadas",
            favorito: false
        },
        {
            cid10: "P570",
            descricao: "Kernicterus devido à isoimunização",
            favorito: false
        },
        {
            cid10: "P578",
            descricao: "Outros kernicterus especificados",
            favorito: false
        },
        {
            cid10: "P579",
            descricao: "Kernicterus não especificado",
            favorito: false
        },
        {
            cid10: "P580",
            descricao: "Icterícia neonatal devida a contusões",
            favorito: false
        },
        {
            cid10: "P581",
            descricao: "Icterícia neonatal devida a sangramento",
            favorito: false
        },
        {
            cid10: "P582",
            descricao: "Icterícia neonatal devida a infecção",
            favorito: false
        },
        {
            cid10: "P583",
            descricao: "Icterícia neonatal devida a policitemia",
            favorito: false
        },
        {
            cid10: "P584",
            descricao: "Icterícia neonatal devida a drogas ou toxinas transmitidas pela mãe ou administradas ao recém-nascido",
            favorito: false
        },
        {
            cid10: "P585",
            descricao: "Icterícia neonatal devida à deglutação de sangue materno",
            favorito: false
        },
        {
            cid10: "P588",
            descricao: "Icterícia neonatal devida a outras hemólises excessivas especificadas",
            favorito: false
        },
        {
            cid10: "P589",
            descricao: "Icterícia neonatal devida a hemólise excessiva não especificada",
            favorito: false
        },
        {
            cid10: "P590",
            descricao: "Icterícia neonatal associada ao parto prematuro",
            favorito: false
        },
        {
            cid10: "P591",
            descricao: "Síndrome da bile espessa",
            favorito: false
        },
        {
            cid10: "P592",
            descricao: "Icterícia neonatal de outras lesões hepatocelulares e das não especificadas",
            favorito: false
        },
        {
            cid10: "P593",
            descricao: "Icterícia neonatal devida a inibidores do leite materno",
            favorito: false
        },
        {
            cid10: "P598",
            descricao: "Icterícia neonatal devida a outras causas especificadas",
            favorito: false
        },
        {
            cid10: "P599",
            descricao: "Icterícia neonatal não especificada",
            favorito: false
        },
        {
            cid10: "P60",
            descricao: "Coagulação intravascular disseminada do feto e do recém-nascido",
            favorito: false
        },
        {
            cid10: "P610",
            descricao: "Trombocitopenia transitória neonatal",
            favorito: false
        },
        {
            cid10: "P611",
            descricao: "Policitemia neonatal",
            favorito: false
        },
        {
            cid10: "P612",
            descricao: "Anemia da prematuridade",
            favorito: false
        },
        {
            cid10: "P613",
            descricao: "Anemia congênita por perda sangüínea fetal",
            favorito: false
        },
        {
            cid10: "P614",
            descricao: "Outras anemias congênitas não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "P615",
            descricao: "Neutropenia transitória neonatal",
            favorito: false
        },
        {
            cid10: "P616",
            descricao: "Outros transtornos transitórios de coagulação do período neonatal",
            favorito: false
        },
        {
            cid10: "P618",
            descricao: "Outros transtornos hematológicos perinatais especificados",
            favorito: false
        },
        {
            cid10: "P619",
            descricao: "Transtorno hematológico perinatal não especificado",
            favorito: false
        },
        {
            cid10: "P700",
            descricao: "Síndrome do filho de mãe com diabetes gestacional",
            favorito: false
        },
        {
            cid10: "P701",
            descricao: "Síndrome do filho de mãe diabética",
            favorito: false
        },
        {
            cid10: "P702",
            descricao: "Diabetes mellitus neonatal",
            favorito: false
        },
        {
            cid10: "P703",
            descricao: "Hipoglicemia neonatal iatrogênica",
            favorito: false
        },
        {
            cid10: "P704",
            descricao: "Outras hipoglicemias neonatais",
            favorito: false
        },
        {
            cid10: "P708",
            descricao: "Outros transtornos transitórios do metabolismo dos carboidratos do feto e do recém-nascido",
            favorito: false
        },
        {
            cid10: "P709",
            descricao: "Transtorno transitório não especificado do metabolismo dos carboidratos do feto e do recém-nascido",
            favorito: false
        },
        {
            cid10: "P710",
            descricao: "Hipocalcemia do recém-nascido pelo uso de leite de vaca",
            favorito: false
        },
        {
            cid10: "P711",
            descricao: "Outras hipocalcemias neonatais",
            favorito: false
        },
        {
            cid10: "P712",
            descricao: "Hipomagnesemia neonatal",
            favorito: false
        },
        {
            cid10: "P713",
            descricao: "Tetania neonatal sem deficiência de cálcio ou magnésio",
            favorito: false
        },
        {
            cid10: "P714",
            descricao: "Hipoparatiroidismo neonatal transitório",
            favorito: false
        },
        {
            cid10: "P718",
            descricao: "Outros transtornos transitórios do metabolismo do cálcio e do magnésio do período neonatal",
            favorito: false
        },
        {
            cid10: "P719",
            descricao: "Transtornos transitórios não especificados do metabolismo do cálcio e do magnésio do período neonatal",
            favorito: false
        },
        {
            cid10: "P720",
            descricao: "Bócio neonatal, não classificado em outra parte",
            favorito: false
        },
        {
            cid10: "P721",
            descricao: "Hipotireoidismo neonatal transitório",
            favorito: false
        },
        {
            cid10: "P722",
            descricao: "Outros transtornos transitórios da função de tireóide não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "P728",
            descricao: "Outros transtornos endócrinos transitórios e especificados do período neonatal",
            favorito: false
        },
        {
            cid10: "P729",
            descricao: "Transtorno endócrino neonatal transitório não especificado",
            favorito: false
        },
        {
            cid10: "P740",
            descricao: "Acidose metabólica tardia do recém-nascido",
            favorito: false
        },
        {
            cid10: "P741",
            descricao: "Desidratação do recém-nascido",
            favorito: false
        },
        {
            cid10: "P742",
            descricao: "Distúrbios do equilíbrio de sódio do recém-nascido",
            favorito: false
        },
        {
            cid10: "P743",
            descricao: "Distúrbios do equilíbrio de potássio do recém-nascido",
            favorito: false
        },
        {
            cid10: "P744",
            descricao: "Outros distúrbios eletrolíticos transitórios do recém-nascido",
            favorito: false
        },
        {
            cid10: "P745",
            descricao: "Tirosinemia transitória do recém-nascido",
            favorito: false
        },
        {
            cid10: "P748",
            descricao: "Outros distúrbios metabólicos transitórios do recém-nascido",
            favorito: false
        },
        {
            cid10: "P749",
            descricao: "Distúrbio metabólico transitório não especificado do recém-nascido",
            favorito: false
        },
        {
            cid10: "P75",
            descricao: "Íleo meconial",
            favorito: false
        },
        {
            cid10: "P760",
            descricao: "Síndrome da rolha de mecônio",
            favorito: false
        },
        {
            cid10: "P761",
            descricao: "Íleo transitório do recém-nascido",
            favorito: false
        },
        {
            cid10: "P762",
            descricao: "Obstrução intestinal devido a leite espesso",
            favorito: false
        },
        {
            cid10: "P768",
            descricao: "Outras obstruções intestinais especificadas do recém-nascido",
            favorito: false
        },
        {
            cid10: "P769",
            descricao: "Obstrução intestinal não especificada do recém-nascido",
            favorito: false
        },
        {
            cid10: "P77",
            descricao: "Enterocolite necrotizante do feto e do recém-nascido",
            favorito: false
        },
        {
            cid10: "P780",
            descricao: "Perfuração intestinal no período perinatal",
            favorito: false
        },
        {
            cid10: "P781",
            descricao: "Outras peritonites neonatais",
            favorito: false
        },
        {
            cid10: "P782",
            descricao: "Hematemese e melena devidas a deglutição de sangue materno",
            favorito: false
        },
        {
            cid10: "P783",
            descricao: "Diarréia neonatal não-infecciosa",
            favorito: false
        },
        {
            cid10: "P788",
            descricao: "Outros transtornos perinatais especificados do aparelho digestivo",
            favorito: false
        },
        {
            cid10: "P789",
            descricao: "Transtorno perinatal não especificado do aparelho digestivo",
            favorito: false
        },
        {
            cid10: "P800",
            descricao: "Síndrome de lesão pelo frio",
            favorito: false
        },
        {
            cid10: "P808",
            descricao: "Outras hipotermias do recém-nascido",
            favorito: false
        },
        {
            cid10: "P809",
            descricao: "Hipotermia não especificada do recém-nascido",
            favorito: false
        },
        {
            cid10: "P810",
            descricao: "Hipertermia ambiental do recém-nascido",
            favorito: false
        },
        {
            cid10: "P818",
            descricao: "Outros distúrbios especificados da regulação térmica do recém-nascido",
            favorito: false
        },
        {
            cid10: "P819",
            descricao: "Distúrbio não especificado da regulação térmica do recém-nascido",
            favorito: false
        },
        {
            cid10: "P830",
            descricao: "Esclerema neonatal",
            favorito: false
        },
        {
            cid10: "P831",
            descricao: "Eritema tóxico neonatal",
            favorito: false
        },
        {
            cid10: "P832",
            descricao: "Hidropsia fetal não-devida à doença hemolítica",
            favorito: false
        },
        {
            cid10: "P833",
            descricao: "Outros edemas e os não especificados próprios do feto e do recém-nascido",
            favorito: false
        },
        {
            cid10: "P834",
            descricao: "Intumescimento mamário do recém-nascido",
            favorito: false
        },
        {
            cid10: "P835",
            descricao: "Hidrocele congênita",
            favorito: false
        },
        {
            cid10: "P836",
            descricao: "Pólipo umbilical do recém-nascido",
            favorito: false
        },
        {
            cid10: "P838",
            descricao: "Outras afecções especificadas do tegumento próprias do feto e do recém-nascido",
            favorito: false
        },
        {
            cid10: "P839",
            descricao: "Afecção não especificada do tegumento própria do feto e do recém-nascido",
            favorito: false
        },
        {
            cid10: "P90",
            descricao: "Convulsões do recém-nascido",
            favorito: false
        },
        {
            cid10: "P910",
            descricao: "Isquemia cerebral neonatal",
            favorito: false
        },
        {
            cid10: "P911",
            descricao: "Cistos periventriculares adquiridos do recém-nascido",
            favorito: false
        },
        {
            cid10: "P912",
            descricao: "Leucomalácia cerebral neonatal",
            favorito: false
        },
        {
            cid10: "P913",
            descricao: "Irritabilidade cerebral neonatal",
            favorito: false
        },
        {
            cid10: "P914",
            descricao: "Depressão cerebral neonatal",
            favorito: false
        },
        {
            cid10: "P915",
            descricao: "Coma neonatal",
            favorito: false
        },
        {
            cid10: "P916",
            descricao: "Encefalopatia hipóxico-isquêmica do recém-nascido",
            favorito: false
        },
        {
            cid10: "P918",
            descricao: "Outros distúrbios especificados da função cerebral do recém-nascido",
            favorito: false
        },
        {
            cid10: "P919",
            descricao: "Distúrbio não especificado da função cerebral do recém-nascido",
            favorito: false
        },
        {
            cid10: "P920",
            descricao: "Vômitos no recém-nascido",
            favorito: false
        },
        {
            cid10: "P921",
            descricao: "Regurgitação e ruminação no recém-nascido",
            favorito: false
        },
        {
            cid10: "P922",
            descricao: "Alimentação vagarosa do recém-nascido",
            favorito: false
        },
        {
            cid10: "P923",
            descricao: "Subalimentação do recém-nascido",
            favorito: false
        },
        {
            cid10: "P924",
            descricao: "Hiperalimentação do recém-nascido",
            favorito: false
        },
        {
            cid10: "P925",
            descricao: "Dificuldade neonatal na amamentação no peito",
            favorito: false
        },
        {
            cid10: "P928",
            descricao: "Outros problemas de alimentação do recém-nascido",
            favorito: false
        },
        {
            cid10: "P929",
            descricao: "Problema não especificado de alimentação do recém-nascido",
            favorito: false
        },
        {
            cid10: "P93",
            descricao: "Reações e intoxicações devidas a drogas administradas ao feto e ao recém-nascido",
            favorito: false
        },
        {
            cid10: "P940",
            descricao: "Miastenia grave neonatal transitória",
            favorito: false
        },
        {
            cid10: "P941",
            descricao: "Hipertonia congênita",
            favorito: false
        },
        {
            cid10: "P942",
            descricao: "Hipotonia congênita",
            favorito: false
        },
        {
            cid10: "P948",
            descricao: "Outros transtornos do tônus muscular do recém-nascido",
            favorito: false
        },
        {
            cid10: "P949",
            descricao: "Transtorno não especificado do tônus muscular do recém-nascido",
            favorito: false
        },
        {
            cid10: "P95",
            descricao: "Morte fetal de causa não especificada",
            favorito: false
        },
        {
            cid10: "P960",
            descricao: "Insuficiência renal congênita",
            favorito: false
        },
        {
            cid10: "P961",
            descricao: "Sintomas de abstinência neonatal de drogas utilizadas pela mãe",
            favorito: false
        },
        {
            cid10: "P962",
            descricao: "Sintomas de abstinência do uso de drogas terapêuticas no recém-nascido",
            favorito: false
        },
        {
            cid10: "P963",
            descricao: "Suturas cranianas amplas no recém-nascido",
            favorito: false
        },
        {
            cid10: "P964",
            descricao: "Interrupção de gravidez, afetando o feto e recém-nascido",
            favorito: false
        },
        {
            cid10: "P965",
            descricao: "Complicações de procedimentos intra-uterinos não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "P968",
            descricao: "Outras afecções especificadas originadas no período perinatal",
            favorito: false
        },
        {
            cid10: "P969",
            descricao: "Afecções originadas no período perinatal não especificadas",
            favorito: false
        },
        {
            cid10: "Q000",
            descricao: "Anencefalia",
            favorito: false
        },
        {
            cid10: "Q001",
            descricao: "Craniorraquisquise",
            favorito: false
        },
        {
            cid10: "Q002",
            descricao: "Iniencefalia",
            favorito: false
        },
        {
            cid10: "Q010",
            descricao: "Encefalocele frontal",
            favorito: false
        },
        {
            cid10: "Q011",
            descricao: "Encefalocele nasofrontal",
            favorito: false
        },
        {
            cid10: "Q012",
            descricao: "Encefalocele occipital",
            favorito: false
        },
        {
            cid10: "Q018",
            descricao: "Encefalocele de outras localizações",
            favorito: false
        },
        {
            cid10: "Q019",
            descricao: "Encefalocele não especificada",
            favorito: false
        },
        {
            cid10: "Q02",
            descricao: "Microcefalia",
            favorito: false
        },
        {
            cid10: "Q030",
            descricao: "Malformações do aqueduto de Sylvius",
            favorito: false
        },
        {
            cid10: "Q031",
            descricao: "Atresia das fendas de Luschka e do forâmen de Magendie",
            favorito: false
        },
        {
            cid10: "Q038",
            descricao: "Outra hidrocefalia congênita",
            favorito: false
        },
        {
            cid10: "Q039",
            descricao: "Hidrocefalia congênita não especificada",
            favorito: false
        },
        {
            cid10: "Q040",
            descricao: "Malformações congênitas do corpo caloso",
            favorito: false
        },
        {
            cid10: "Q041",
            descricao: "Arrinencefalia",
            favorito: false
        },
        {
            cid10: "Q042",
            descricao: "Holoprosencefalia",
            favorito: false
        },
        {
            cid10: "Q043",
            descricao: "Outras deformidades por redução do encéfalo",
            favorito: false
        },
        {
            cid10: "Q044",
            descricao: "Displasia do septo e das vias ópticas",
            favorito: false
        },
        {
            cid10: "Q045",
            descricao: "Megalencefalia",
            favorito: false
        },
        {
            cid10: "Q046",
            descricao: "Cistos cerebrais congênitos",
            favorito: false
        },
        {
            cid10: "Q048",
            descricao: "Outras malformações congênitas especificadas do encéfalo",
            favorito: false
        },
        {
            cid10: "Q049",
            descricao: "Malformação congênita não especificada do encéfalo",
            favorito: false
        },
        {
            cid10: "Q050",
            descricao: "Espinha bífida cervical com hidrocefalia",
            favorito: false
        },
        {
            cid10: "Q051",
            descricao: "Espinha bífida torácica com hidrocefalia",
            favorito: false
        },
        {
            cid10: "Q052",
            descricao: "Espinha bífida lombar com hidrocefalia",
            favorito: false
        },
        {
            cid10: "Q053",
            descricao: "Espinha bífida sacra com hidrocefalia",
            favorito: false
        },
        {
            cid10: "Q054",
            descricao: "Espinha bífida não especificada, com hidrocefalia",
            favorito: false
        },
        {
            cid10: "Q055",
            descricao: "Espinha bífida cervical, sem hidrocefalia",
            favorito: false
        },
        {
            cid10: "Q056",
            descricao: "Espinha bífida torácica, sem hidrocefalia",
            favorito: false
        },
        {
            cid10: "Q057",
            descricao: "Espinha bífida lombar, sem hidrocefalia",
            favorito: false
        },
        {
            cid10: "Q058",
            descricao: "Espinha bífida sacra, sem hidrocefalia",
            favorito: false
        },
        {
            cid10: "Q059",
            descricao: "Espinha bífida não especificada",
            favorito: false
        },
        {
            cid10: "Q060",
            descricao: "Amielia",
            favorito: false
        },
        {
            cid10: "Q061",
            descricao: "Hipoplasia e displasia da medula espinal",
            favorito: false
        },
        {
            cid10: "Q062",
            descricao: "Diastematomielia",
            favorito: false
        },
        {
            cid10: "Q063",
            descricao: "Outras malformações congênitas da cauda eqüina",
            favorito: false
        },
        {
            cid10: "Q064",
            descricao: "Hidromielia",
            favorito: false
        },
        {
            cid10: "Q068",
            descricao: "Outras malformações congênitas especificadas da medula espinal",
            favorito: false
        },
        {
            cid10: "Q069",
            descricao: "Malformação congênita não especificada da medula espinal",
            favorito: false
        },
        {
            cid10: "Q070",
            descricao: "Síndrome de Arnold-Chiari",
            favorito: false
        },
        {
            cid10: "Q078",
            descricao: "Outras malformações congênitas especificadas do sistema nervoso",
            favorito: false
        },
        {
            cid10: "Q079",
            descricao: "Malformação congênita não especificada do sistema nervoso",
            favorito: false
        },
        {
            cid10: "Q100",
            descricao: "Ptose congênita",
            favorito: false
        },
        {
            cid10: "Q101",
            descricao: "Ectrópio congênito",
            favorito: false
        },
        {
            cid10: "Q102",
            descricao: "Entrópio congênito",
            favorito: false
        },
        {
            cid10: "Q103",
            descricao: "Outras malformações congênitas das pálpebras",
            favorito: false
        },
        {
            cid10: "Q104",
            descricao: "Ausência ou agenesia do aparelho lacrimal",
            favorito: false
        },
        {
            cid10: "Q105",
            descricao: "Estenose ou estreitamento congênito do canal lacrimal",
            favorito: false
        },
        {
            cid10: "Q106",
            descricao: "Outras malformações congênitas do aparelho lacrimal",
            favorito: false
        },
        {
            cid10: "Q107",
            descricao: "Malformação congênita da órbita",
            favorito: false
        },
        {
            cid10: "Q110",
            descricao: "Olho cístico",
            favorito: false
        },
        {
            cid10: "Q111",
            descricao: "Outras formas de anoftalmia",
            favorito: false
        },
        {
            cid10: "Q112",
            descricao: "Microftalmia",
            favorito: false
        },
        {
            cid10: "Q113",
            descricao: "Macroftalmia",
            favorito: false
        },
        {
            cid10: "Q120",
            descricao: "Catarata congênita",
            favorito: false
        },
        {
            cid10: "Q121",
            descricao: "Luxação congênita do cristalino",
            favorito: false
        },
        {
            cid10: "Q122",
            descricao: "Coloboma do cristalino",
            favorito: false
        },
        {
            cid10: "Q123",
            descricao: "Afaquia congênita",
            favorito: false
        },
        {
            cid10: "Q124",
            descricao: "Esferofaquia",
            favorito: false
        },
        {
            cid10: "Q128",
            descricao: "Outras malformações congênitas do cristalino",
            favorito: false
        },
        {
            cid10: "Q129",
            descricao: "Malformação congênita não especificada do cristalino",
            favorito: false
        },
        {
            cid10: "Q130",
            descricao: "Coloboma da íris",
            favorito: false
        },
        {
            cid10: "Q131",
            descricao: "Ausência de íris",
            favorito: false
        },
        {
            cid10: "Q132",
            descricao: "Outras malformações congênitas da íris",
            favorito: false
        },
        {
            cid10: "Q133",
            descricao: "Opacidade congênita da córnea",
            favorito: false
        },
        {
            cid10: "Q134",
            descricao: "Outras malformações congênitas da córnea",
            favorito: false
        },
        {
            cid10: "Q135",
            descricao: "Esclerótica azul",
            favorito: false
        },
        {
            cid10: "Q138",
            descricao: "Outras malformações congênitas da câmara anterior do olho",
            favorito: false
        },
        {
            cid10: "Q139",
            descricao: "Malformação congênita não especificada da câmara anterior do olho",
            favorito: false
        },
        {
            cid10: "Q140",
            descricao: "Malformação congênita do humor vítreo",
            favorito: false
        },
        {
            cid10: "Q141",
            descricao: "Malformação congênita da retina",
            favorito: false
        },
        {
            cid10: "Q142",
            descricao: "Malformação congênita do disco óptico",
            favorito: false
        },
        {
            cid10: "Q143",
            descricao: "Malformação congênita da coróide",
            favorito: false
        },
        {
            cid10: "Q148",
            descricao: "Outras malformações congênitas da câmara posterior do olho",
            favorito: false
        },
        {
            cid10: "Q149",
            descricao: "Malformação congênita não especificada da câmara posterior do olho",
            favorito: false
        },
        {
            cid10: "Q150",
            descricao: "Glaucoma congênito",
            favorito: false
        },
        {
            cid10: "Q158",
            descricao: "Outras malformações congênitas especificadas do olho",
            favorito: false
        },
        {
            cid10: "Q159",
            descricao: "Malformação congênita não especificada do olho",
            favorito: false
        },
        {
            cid10: "Q160",
            descricao: "Ausência congênita do pavilhão auricular [orelha]",
            favorito: false
        },
        {
            cid10: "Q161",
            descricao: "Ausência, atresia e estreitamento congênitos do conduto auditivo (externo)",
            favorito: false
        },
        {
            cid10: "Q162",
            descricao: "Ausência da trompa de Eustáquio",
            favorito: false
        },
        {
            cid10: "Q163",
            descricao: "Malformação congênita dos ossículos do ouvido",
            favorito: false
        },
        {
            cid10: "Q164",
            descricao: "Outras malformações congênitas do ouvido médio",
            favorito: false
        },
        {
            cid10: "Q165",
            descricao: "Malformação congênita do ouvido interno",
            favorito: false
        },
        {
            cid10: "Q169",
            descricao: "Malformação congênita do ouvido não especificada causando comprometimento da audição",
            favorito: false
        },
        {
            cid10: "Q170",
            descricao: "Pavilhão supranumerário",
            favorito: false
        },
        {
            cid10: "Q171",
            descricao: "Macrotia",
            favorito: false
        },
        {
            cid10: "Q172",
            descricao: "Microtia",
            favorito: false
        },
        {
            cid10: "Q173",
            descricao: "Outras deformidades da orelha",
            favorito: false
        },
        {
            cid10: "Q174",
            descricao: "Anomalia de posição da orelha",
            favorito: false
        },
        {
            cid10: "Q175",
            descricao: "Orelhas proeminentes",
            favorito: false
        },
        {
            cid10: "Q178",
            descricao: "Outras malformações congênitas especificadas da orelha",
            favorito: false
        },
        {
            cid10: "Q179",
            descricao: "Malformação congênita não especificada da orelha",
            favorito: false
        },
        {
            cid10: "Q180",
            descricao: "Seio, fístula e cisto de origem branquial",
            favorito: false
        },
        {
            cid10: "Q181",
            descricao: "Seio, fístula e cisto pré-auricular",
            favorito: false
        },
        {
            cid10: "Q182",
            descricao: "Outras malformações da fenda branquial",
            favorito: false
        },
        {
            cid10: "Q183",
            descricao: "Pescoço alado",
            favorito: false
        },
        {
            cid10: "Q184",
            descricao: "Macrostomia",
            favorito: false
        },
        {
            cid10: "Q185",
            descricao: "Microstomia",
            favorito: false
        },
        {
            cid10: "Q186",
            descricao: "Macroqueilia",
            favorito: false
        },
        {
            cid10: "Q187",
            descricao: "Microqueilia",
            favorito: false
        },
        {
            cid10: "Q188",
            descricao: "Outras malformações congênitas especificadas da face e do pescoço",
            favorito: false
        },
        {
            cid10: "Q189",
            descricao: "Malformação congênita não especificada da face e do pescoço",
            favorito: false
        },
        {
            cid10: "Q200",
            descricao: "Tronco arterial comum",
            favorito: false
        },
        {
            cid10: "Q201",
            descricao: "Ventrículo direito com dupla via de saída",
            favorito: false
        },
        {
            cid10: "Q202",
            descricao: "Ventrículo esquerdo com dupla via de saída",
            favorito: false
        },
        {
            cid10: "Q203",
            descricao: "Comunicação ventrículo-atrial discordante",
            favorito: false
        },
        {
            cid10: "Q204",
            descricao: "Ventrículo com dupla via de entrada",
            favorito: false
        },
        {
            cid10: "Q205",
            descricao: "Comunicação átrio-ventricular discordante",
            favorito: false
        },
        {
            cid10: "Q206",
            descricao: "Isomerismo dos apêndices atriais",
            favorito: false
        },
        {
            cid10: "Q208",
            descricao: "Outras malformações congênitas das câmaras e das comunicações cardíacas",
            favorito: false
        },
        {
            cid10: "Q209",
            descricao: "Malformação congênita não especificada das câmaras e das comunicações cardíacas",
            favorito: false
        },
        {
            cid10: "Q210",
            descricao: "Comunicação interventricular",
            favorito: false
        },
        {
            cid10: "Q211",
            descricao: "Comunicação interatrial",
            favorito: false
        },
        {
            cid10: "Q212",
            descricao: "Comunicação atrioventricular",
            favorito: false
        },
        {
            cid10: "Q213",
            descricao: "Tetralogia de Fallot",
            favorito: false
        },
        {
            cid10: "Q214",
            descricao: "Comunicação aortopulmonar",
            favorito: false
        },
        {
            cid10: "Q218",
            descricao: "Outras malformações congênitas dos septos cardíacos",
            favorito: false
        },
        {
            cid10: "Q219",
            descricao: "Malformação congênita não especificada de septo cardíaco",
            favorito: false
        },
        {
            cid10: "Q220",
            descricao: "Atresia da valva pulmonar",
            favorito: false
        },
        {
            cid10: "Q221",
            descricao: "Estenose congênita da valva pulmonar",
            favorito: false
        },
        {
            cid10: "Q222",
            descricao: "Insuficiência congênita da valva pulmonar",
            favorito: false
        },
        {
            cid10: "Q223",
            descricao: "Outras malformações congênitas da valva pulmonar",
            favorito: false
        },
        {
            cid10: "Q224",
            descricao: "Estenose congênita da valva tricúspide",
            favorito: false
        },
        {
            cid10: "Q225",
            descricao: "Anomalia de Ebstein",
            favorito: false
        },
        {
            cid10: "Q226",
            descricao: "Síndrome do coração direito hipoplásico",
            favorito: false
        },
        {
            cid10: "Q228",
            descricao: "Outras malformações congênitas da valva tricúspide",
            favorito: false
        },
        {
            cid10: "Q229",
            descricao: "Malformação congênita não especificada da valva tricúspide",
            favorito: false
        },
        {
            cid10: "Q230",
            descricao: "Estenose congênita da valva aórtica",
            favorito: false
        },
        {
            cid10: "Q231",
            descricao: "Insuficiência congênita da valva aórtica",
            favorito: false
        },
        {
            cid10: "Q232",
            descricao: "Estenose mitral congênita",
            favorito: false
        },
        {
            cid10: "Q233",
            descricao: "Insuficiência mitral congênita",
            favorito: false
        },
        {
            cid10: "Q234",
            descricao: "Síndrome do coração esquerdo hipoplásico",
            favorito: false
        },
        {
            cid10: "Q238",
            descricao: "Outras malformações congênitas das valvas aórtica e mitral",
            favorito: false
        },
        {
            cid10: "Q239",
            descricao: "Malformação congênita não especificada das valvas aórtica e mitral",
            favorito: false
        },
        {
            cid10: "Q240",
            descricao: "Dextrocardia",
            favorito: false
        },
        {
            cid10: "Q241",
            descricao: "Levocardia",
            favorito: false
        },
        {
            cid10: "Q242",
            descricao: "Cor triatriatum",
            favorito: false
        },
        {
            cid10: "Q243",
            descricao: "Estenose do infundíbulo pulmonar",
            favorito: false
        },
        {
            cid10: "Q244",
            descricao: "Estenose subaórtica congênita",
            favorito: false
        },
        {
            cid10: "Q245",
            descricao: "Malformações dos vasos coronários",
            favorito: false
        },
        {
            cid10: "Q246",
            descricao: "Bloqueio congênito do coração",
            favorito: false
        },
        {
            cid10: "Q248",
            descricao: "Outras malformações congênitas especificadas do coração",
            favorito: false
        },
        {
            cid10: "Q249",
            descricao: "Malformação não especificada do coração",
            favorito: false
        },
        {
            cid10: "Q250",
            descricao: "Permeabilidade do canal arterial",
            favorito: false
        },
        {
            cid10: "Q251",
            descricao: "Coartação da aorta",
            favorito: false
        },
        {
            cid10: "Q252",
            descricao: "Atresia da aorta",
            favorito: false
        },
        {
            cid10: "Q253",
            descricao: "Estenose da aorta",
            favorito: false
        },
        {
            cid10: "Q254",
            descricao: "Outras malformações congênitas da aorta",
            favorito: false
        },
        {
            cid10: "Q255",
            descricao: "Atresia da artéria pulmonar",
            favorito: false
        },
        {
            cid10: "Q256",
            descricao: "Estenose da artéria pulmonar",
            favorito: false
        },
        {
            cid10: "Q257",
            descricao: "Outras malformações congênitas da artéria pulmonar",
            favorito: false
        },
        {
            cid10: "Q258",
            descricao: "Outras malformações congênitas das grandes artérias",
            favorito: false
        },
        {
            cid10: "Q259",
            descricao: "Malformação congênita não especificada das grandes artérias",
            favorito: false
        },
        {
            cid10: "Q260",
            descricao: "Estenose congênita da veia cava",
            favorito: false
        },
        {
            cid10: "Q261",
            descricao: "Persistência da veia cava superior esquerda",
            favorito: false
        },
        {
            cid10: "Q262",
            descricao: "Comunicação venosa pulmonar anormal total",
            favorito: false
        },
        {
            cid10: "Q263",
            descricao: "Comunicação venosa pulmonar anormal parcial",
            favorito: false
        },
        {
            cid10: "Q264",
            descricao: "Comunicação venosa pulmonar anormal não especificado",
            favorito: false
        },
        {
            cid10: "Q265",
            descricao: "Comunicação venosa portal anormal",
            favorito: false
        },
        {
            cid10: "Q266",
            descricao: "Fístula entre a veia porta e a artéria hepática",
            favorito: false
        },
        {
            cid10: "Q268",
            descricao: "Outras malformações congênitas das grandes veias",
            favorito: false
        },
        {
            cid10: "Q269",
            descricao: "Malformação congênita não especificada de grande veia",
            favorito: false
        },
        {
            cid10: "Q270",
            descricao: "Ausência congênita e hipoplasia da artéria umbilical",
            favorito: false
        },
        {
            cid10: "Q271",
            descricao: "Estenose congênita da artéria renal",
            favorito: false
        },
        {
            cid10: "Q272",
            descricao: "Outras malformações congênitas da artéria renal",
            favorito: false
        },
        {
            cid10: "Q273",
            descricao: "Malformação artério-venosa periférica",
            favorito: false
        },
        {
            cid10: "Q274",
            descricao: "Ectasia venosa (flebectasia) congênita",
            favorito: false
        },
        {
            cid10: "Q278",
            descricao: "Outras malformações congênitas especificadas do sistema vascular periférico",
            favorito: false
        },
        {
            cid10: "Q279",
            descricao: "Malformação congênita não especificada do sistema vascular periférico",
            favorito: false
        },
        {
            cid10: "Q280",
            descricao: "Malformação arteriovenosa de vasos pré-cerebrais",
            favorito: false
        },
        {
            cid10: "Q281",
            descricao: "Outras malformações dos vasos pré-cerebrais",
            favorito: false
        },
        {
            cid10: "Q282",
            descricao: "Malformação arteriovenosa dos vasos cerebrais",
            favorito: false
        },
        {
            cid10: "Q283",
            descricao: "Outras malformações dos vasos cerebrais",
            favorito: false
        },
        {
            cid10: "Q288",
            descricao: "Outras malformações congênitas especificadas do aparelho circulatório",
            favorito: false
        },
        {
            cid10: "Q289",
            descricao: "Malformação congênita não especificada do aparelho circulatório",
            favorito: false
        },
        {
            cid10: "Q300",
            descricao: "Atresia das coanas",
            favorito: false
        },
        {
            cid10: "Q301",
            descricao: "Agenesia ou hipoplasia do nariz",
            favorito: false
        },
        {
            cid10: "Q302",
            descricao: "Fissura, entalhe ou fenda nasal",
            favorito: false
        },
        {
            cid10: "Q303",
            descricao: "Perfuração congênita do septo nasal",
            favorito: false
        },
        {
            cid10: "Q308",
            descricao: "Outras malformações congênitas do nariz",
            favorito: false
        },
        {
            cid10: "Q309",
            descricao: "Malformação congênita não especificada do nariz",
            favorito: false
        },
        {
            cid10: "Q310",
            descricao: "Pterígio da laringe",
            favorito: false
        },
        {
            cid10: "Q311",
            descricao: "Estenose subglótica congênita",
            favorito: false
        },
        {
            cid10: "Q312",
            descricao: "Hipoplasia da laringe",
            favorito: false
        },
        {
            cid10: "Q313",
            descricao: "Laringocele",
            favorito: false
        },
        {
            cid10: "Q315",
            descricao: "Laringomalácia congênita",
            favorito: false
        },
        {
            cid10: "Q318",
            descricao: "Outras malformações congênitas da laringe",
            favorito: false
        },
        {
            cid10: "Q319",
            descricao: "Malformação congênita não especificada da laringe",
            favorito: false
        },
        {
            cid10: "Q320",
            descricao: "Traqueomalácia congênita",
            favorito: false
        },
        {
            cid10: "Q321",
            descricao: "Outras malformações congênitas da traquéia",
            favorito: false
        },
        {
            cid10: "Q322",
            descricao: "Broncomalácia congênita",
            favorito: false
        },
        {
            cid10: "Q323",
            descricao: "Estenose congênita dos brônquios",
            favorito: false
        },
        {
            cid10: "Q324",
            descricao: "Outras malformações congênitas dos brônquios",
            favorito: false
        },
        {
            cid10: "Q330",
            descricao: "Pulmão cístico congênito",
            favorito: false
        },
        {
            cid10: "Q331",
            descricao: "Lobo pulmonar supranumerário",
            favorito: false
        },
        {
            cid10: "Q332",
            descricao: "Seqüestro pulmonar",
            favorito: false
        },
        {
            cid10: "Q333",
            descricao: "Agenesia do pulmão",
            favorito: false
        },
        {
            cid10: "Q334",
            descricao: "Bronquectasia congênita",
            favorito: false
        },
        {
            cid10: "Q335",
            descricao: "Tecido ectópico intrapulmonar",
            favorito: false
        },
        {
            cid10: "Q336",
            descricao: "Hipoplasia e displasia do pulmão",
            favorito: false
        },
        {
            cid10: "Q338",
            descricao: "Outras malformações congênitas do pulmão",
            favorito: false
        },
        {
            cid10: "Q339",
            descricao: "Malformação congênita não especificada do pulmão",
            favorito: false
        },
        {
            cid10: "Q340",
            descricao: "Anomalia da pleura",
            favorito: false
        },
        {
            cid10: "Q341",
            descricao: "Cisto congênito do mediastino",
            favorito: false
        },
        {
            cid10: "Q348",
            descricao: "Outras malformações congênitas especificadas do aparelho respiratório",
            favorito: false
        },
        {
            cid10: "Q349",
            descricao: "Malformação congênita não especificada do aparelho respiratório",
            favorito: false
        },
        {
            cid10: "Q351",
            descricao: "Fenda do palato duro",
            favorito: false
        },
        {
            cid10: "Q353",
            descricao: "Fenda do palato mole",
            favorito: false
        },
        {
            cid10: "Q355",
            descricao: "Fenda do palato duro com fenda do palato mole",
            favorito: false
        },
        {
            cid10: "Q357",
            descricao: "Fenda da úvula",
            favorito: false
        },
        {
            cid10: "Q359",
            descricao: "Fenda palatina não especificada",
            favorito: false
        },
        {
            cid10: "Q360",
            descricao: "Fenda labial bilateral",
            favorito: false
        },
        {
            cid10: "Q361",
            descricao: "Fenda labial mediana",
            favorito: false
        },
        {
            cid10: "Q369",
            descricao: "Fenda labial unilateral",
            favorito: false
        },
        {
            cid10: "Q370",
            descricao: "Fenda do palato duro com fenda labial bilateral",
            favorito: false
        },
        {
            cid10: "Q371",
            descricao: "Fenda do palato duro com fenda labial unilateral",
            favorito: false
        },
        {
            cid10: "Q372",
            descricao: "Fenda do palato mole com fenda labial bilateral",
            favorito: false
        },
        {
            cid10: "Q373",
            descricao: "Fenda do palato mole com fenda labial unilateral",
            favorito: false
        },
        {
            cid10: "Q374",
            descricao: "Fenda dos palatos duro e mole com fenda labial bilateral",
            favorito: false
        },
        {
            cid10: "Q375",
            descricao: "Fenda dos palatos duro e mole com fenda labial unilateral",
            favorito: false
        },
        {
            cid10: "Q378",
            descricao: "Fenda do palato com fenda labial bilateral, não especificada",
            favorito: false
        },
        {
            cid10: "Q379",
            descricao: "Fenda do palato com fenda labial unilateral, não especificada",
            favorito: false
        },
        {
            cid10: "Q380",
            descricao: "Malformações congênitas dos lábios, não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "Q381",
            descricao: "Anquiloglossia",
            favorito: false
        },
        {
            cid10: "Q382",
            descricao: "Macroglossia",
            favorito: false
        },
        {
            cid10: "Q383",
            descricao: "Outras malformações congênitas da língua",
            favorito: false
        },
        {
            cid10: "Q384",
            descricao: "Malformações congênitas das glândulas e dutos salivares",
            favorito: false
        },
        {
            cid10: "Q385",
            descricao: "Malformações congênitas do palato não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "Q386",
            descricao: "Outras malformações congênitas da boca",
            favorito: false
        },
        {
            cid10: "Q387",
            descricao: "Bolsa faríngea",
            favorito: false
        },
        {
            cid10: "Q388",
            descricao: "Outras malformações congênitas da faringe",
            favorito: false
        },
        {
            cid10: "Q390",
            descricao: "Atresia de esôfago, sem fístula",
            favorito: false
        },
        {
            cid10: "Q391",
            descricao: "Atresia de esôfago, com fístula traqueoesofágica",
            favorito: false
        },
        {
            cid10: "Q392",
            descricao: "Fístula traqueoesofágica congênita, sem atresia",
            favorito: false
        },
        {
            cid10: "Q393",
            descricao: "Estenose congênita e estreitamento congênito do esôfago",
            favorito: false
        },
        {
            cid10: "Q394",
            descricao: "Pterígio do esôfago",
            favorito: false
        },
        {
            cid10: "Q395",
            descricao: "Dilatação congênita do esôfago",
            favorito: false
        },
        {
            cid10: "Q396",
            descricao: "Divertículo do esôfago",
            favorito: false
        },
        {
            cid10: "Q398",
            descricao: "Outras malformações congênitas do esôfago",
            favorito: false
        },
        {
            cid10: "Q399",
            descricao: "Malformação congênita não especificada do esôfago",
            favorito: false
        },
        {
            cid10: "Q400",
            descricao: "Estenose hipertrófica congênita do piloro",
            favorito: false
        },
        {
            cid10: "Q401",
            descricao: "Hérnia congênita de hiato",
            favorito: false
        },
        {
            cid10: "Q402",
            descricao: "Outras malformações congênitas especificadas do estômago",
            favorito: false
        },
        {
            cid10: "Q403",
            descricao: "Malformação congênita não especificada do estômago",
            favorito: false
        },
        {
            cid10: "Q408",
            descricao: "Outras malformações congênitas especificadas do trato digestivo superior",
            favorito: false
        },
        {
            cid10: "Q409",
            descricao: "Malformação congênita não especificada do trato digestivo superior",
            favorito: false
        },
        {
            cid10: "Q410",
            descricao: "Ausência, atresia e estenose congênita do duodeno",
            favorito: false
        },
        {
            cid10: "Q411",
            descricao: "Ausência, atresia e estenose congênita do jejuno",
            favorito: false
        },
        {
            cid10: "Q412",
            descricao: "Ausência, atresia e estenose congênita do íleo",
            favorito: false
        },
        {
            cid10: "Q418",
            descricao: "Ausência, atresia e estenose congênita de outras partes especificadas do intestino delgado",
            favorito: false
        },
        {
            cid10: "Q419",
            descricao: "Ausência, atresia e estenose congênita do intestino delgado, sem especificação de localização",
            favorito: false
        },
        {
            cid10: "Q420",
            descricao: "Ausência, atresia e estenose congênita do reto, com fístula",
            favorito: false
        },
        {
            cid10: "Q421",
            descricao: "Ausência, atresia e estenose congênita do reto, sem fístula",
            favorito: false
        },
        {
            cid10: "Q422",
            descricao: "Ausência, atresia e estenose congênita do ânus, com fístula",
            favorito: false
        },
        {
            cid10: "Q423",
            descricao: "Ausência, atresia e estenose congênita do ânus, sem fístula",
            favorito: false
        },
        {
            cid10: "Q428",
            descricao: "Ausência, atresia e estenose congênita de outras partes do cólon (intestino grosso)",
            favorito: false
        },
        {
            cid10: "Q429",
            descricao: "Ausência, atresia e estenose congênita de partes não especificadas do cólon (intestino grosso)",
            favorito: false
        },
        {
            cid10: "Q430",
            descricao: "Divertículo de Meckel",
            favorito: false
        },
        {
            cid10: "Q431",
            descricao: "Doença de Hirschsprung",
            favorito: false
        },
        {
            cid10: "Q432",
            descricao: "Outros transtornos funcionais congênitos do cólon",
            favorito: false
        },
        {
            cid10: "Q433",
            descricao: "Malformações congênitas da fixação do intestino",
            favorito: false
        },
        {
            cid10: "Q434",
            descricao: "Duplicação do intestino",
            favorito: false
        },
        {
            cid10: "Q435",
            descricao: "Ânus ectópico",
            favorito: false
        },
        {
            cid10: "Q436",
            descricao: "Fístula congênita do reto e do ânus",
            favorito: false
        },
        {
            cid10: "Q437",
            descricao: "Persistência de cloaca",
            favorito: false
        },
        {
            cid10: "Q438",
            descricao: "Outras malformações congênitas especificadas do intestino",
            favorito: false
        },
        {
            cid10: "Q439",
            descricao: "Malformação congênita não especificada do intestino",
            favorito: false
        },
        {
            cid10: "Q440",
            descricao: "Agenesia, aplasia e hipoplasia da vesícula biliar",
            favorito: false
        },
        {
            cid10: "Q441",
            descricao: "Outras malformações congênitas da vesícula biliar",
            favorito: false
        },
        {
            cid10: "Q442",
            descricao: "Atresia das vias biliares",
            favorito: false
        },
        {
            cid10: "Q443",
            descricao: "Estenose e estreitamento congênitos das vias biliares",
            favorito: false
        },
        {
            cid10: "Q444",
            descricao: "Cisto do colédoco",
            favorito: false
        },
        {
            cid10: "Q445",
            descricao: "Outras malformações congênitas das vias biliares",
            favorito: false
        },
        {
            cid10: "Q446",
            descricao: "Doença cística do fígado",
            favorito: false
        },
        {
            cid10: "Q447",
            descricao: "Outras malformações congênitas do fígado",
            favorito: false
        },
        {
            cid10: "Q450",
            descricao: "Agenesia, aplasia e hipoplasia do pâncreas",
            favorito: false
        },
        {
            cid10: "Q451",
            descricao: "Pâncreas anular",
            favorito: false
        },
        {
            cid10: "Q452",
            descricao: "Cisto pancreático congênito",
            favorito: false
        },
        {
            cid10: "Q453",
            descricao: "Outras malformações congênitas do pâncreas e do duto pancreático",
            favorito: false
        },
        {
            cid10: "Q458",
            descricao: "Outras malformações congênitas especificadas do aparelho digestivo",
            favorito: false
        },
        {
            cid10: "Q459",
            descricao: "Malformação congênita não especificada do aparelho digestivo",
            favorito: false
        },
        {
            cid10: "Q500",
            descricao: "Ausência congênita dos ovários",
            favorito: false
        },
        {
            cid10: "Q501",
            descricao: "Cisto ovariano de desenvolvimento",
            favorito: false
        },
        {
            cid10: "Q502",
            descricao: "Torsão congênita do ovário",
            favorito: false
        },
        {
            cid10: "Q503",
            descricao: "Outras malformações congênitas do ovário",
            favorito: false
        },
        {
            cid10: "Q504",
            descricao: "Cisto embrionário da trompa de Falópio",
            favorito: false
        },
        {
            cid10: "Q505",
            descricao: "Cisto embrionário do ligamento largo",
            favorito: false
        },
        {
            cid10: "Q506",
            descricao: "Outras malformações congênitas das trompas de Falópio e dos ligamentos largos",
            favorito: false
        },
        {
            cid10: "Q510",
            descricao: "Agenesia e aplasia do útero",
            favorito: false
        },
        {
            cid10: "Q511",
            descricao: "Útero duplo com duplicação do colo uterino e da vagina",
            favorito: false
        },
        {
            cid10: "Q512",
            descricao: "Outra duplicação do útero",
            favorito: false
        },
        {
            cid10: "Q513",
            descricao: "Útero bicórneo",
            favorito: false
        },
        {
            cid10: "Q514",
            descricao: "Útero unicórneo",
            favorito: false
        },
        {
            cid10: "Q515",
            descricao: "Agenesia e aplasia do colo do útero",
            favorito: false
        },
        {
            cid10: "Q516",
            descricao: "Cisto embrionário do colo do útero",
            favorito: false
        },
        {
            cid10: "Q517",
            descricao: "Fístula congênita útero-digestiva ou útero-urinária",
            favorito: false
        },
        {
            cid10: "Q518",
            descricao: "Outras malformações congênitas do útero e do colo do útero",
            favorito: false
        },
        {
            cid10: "Q519",
            descricao: "Malformação congênita não especificada do útero e do colo do útero SOE",
            favorito: false
        },
        {
            cid10: "Q520",
            descricao: "Ausência congênita da vagina",
            favorito: false
        },
        {
            cid10: "Q521",
            descricao: "Duplicação da vagina",
            favorito: false
        },
        {
            cid10: "Q522",
            descricao: "Fístula reto-vaginal congênita",
            favorito: false
        },
        {
            cid10: "Q523",
            descricao: "Imperfuração do hímen",
            favorito: false
        },
        {
            cid10: "Q524",
            descricao: "Outras malformações congênitas da vagina",
            favorito: false
        },
        {
            cid10: "Q525",
            descricao: "Fusão dos lábios vulvares",
            favorito: false
        },
        {
            cid10: "Q526",
            descricao: "Malformação congênita do clitóris",
            favorito: false
        },
        {
            cid10: "Q527",
            descricao: "Outras malformações congênitas da vulva",
            favorito: false
        },
        {
            cid10: "Q528",
            descricao: "Outras malformações congênitas especificadas dos órgãos genitais femininos",
            favorito: false
        },
        {
            cid10: "Q529",
            descricao: "Malformação congênita não especificada dos órgãos genitais femininos",
            favorito: false
        },
        {
            cid10: "Q530",
            descricao: "Testículo ectópico",
            favorito: false
        },
        {
            cid10: "Q531",
            descricao: "Testículo não-descido, unilateral",
            favorito: false
        },
        {
            cid10: "Q532",
            descricao: "Testículo não-descido, bilateral",
            favorito: false
        },
        {
            cid10: "Q539",
            descricao: "Testículo não-descido, não especificado",
            favorito: false
        },
        {
            cid10: "Q540",
            descricao: "Hipospádia balânica",
            favorito: false
        },
        {
            cid10: "Q541",
            descricao: "Hipospádia peniana",
            favorito: false
        },
        {
            cid10: "Q542",
            descricao: "Hipospádia penoscrotal",
            favorito: false
        },
        {
            cid10: "Q543",
            descricao: "Hipospádia perineal",
            favorito: false
        },
        {
            cid10: "Q544",
            descricao: "Corda venérea congênita",
            favorito: false
        },
        {
            cid10: "Q548",
            descricao: "Outras hipospádias",
            favorito: false
        },
        {
            cid10: "Q549",
            descricao: "Hipospádia não especificada",
            favorito: false
        },
        {
            cid10: "Q550",
            descricao: "Ausência e aplasia do testículo",
            favorito: false
        },
        {
            cid10: "Q551",
            descricao: "Hipoplasia do(s) testículo(s) e do escroto",
            favorito: false
        },
        {
            cid10: "Q552",
            descricao: "Outras malformações congênitas do(s) testículo(s) e do escroto",
            favorito: false
        },
        {
            cid10: "Q553",
            descricao: "Atresia do canal deferente",
            favorito: false
        },
        {
            cid10: "Q554",
            descricao: "Outras malformações congênitas do canal deferente, do epidídimo, das vesículas seminais e da próstata",
            favorito: false
        },
        {
            cid10: "Q555",
            descricao: "Ausência e aplasia congênitas do pênis",
            favorito: false
        },
        {
            cid10: "Q556",
            descricao: "Outras malformações congênitas do pênis",
            favorito: false
        },
        {
            cid10: "Q558",
            descricao: "Outras malformações congênitas especificadas dos órgãos genitais masculinos",
            favorito: false
        },
        {
            cid10: "Q559",
            descricao: "Malformação congênita não especificada dos órgãos genitais masculinos",
            favorito: false
        },
        {
            cid10: "Q560",
            descricao: "Hermafroditismo não classificado em outra parte",
            favorito: false
        },
        {
            cid10: "Q561",
            descricao: "Pseudo-hermafroditismo masculino, não classificado em outra parte",
            favorito: false
        },
        {
            cid10: "Q562",
            descricao: "Pseudo-hermafroditismo feminino, não classificado em outra parte",
            favorito: false
        },
        {
            cid10: "Q563",
            descricao: "Pseudo-hermafroditismo não especificado",
            favorito: false
        },
        {
            cid10: "Q564",
            descricao: "Sexo indeterminado, não especificado",
            favorito: false
        },
        {
            cid10: "Q600",
            descricao: "Agenesia unilateral do rim",
            favorito: false
        },
        {
            cid10: "Q601",
            descricao: "Agenesia bilateral do rim",
            favorito: false
        },
        {
            cid10: "Q602",
            descricao: "Agenesia renal não especificada",
            favorito: false
        },
        {
            cid10: "Q603",
            descricao: "Hipoplasia renal unilateral",
            favorito: false
        },
        {
            cid10: "Q604",
            descricao: "Hipoplasia renal bilateral",
            favorito: false
        },
        {
            cid10: "Q605",
            descricao: "Hipoplasia renal não especificada",
            favorito: false
        },
        {
            cid10: "Q606",
            descricao: "Síndrome de Potter",
            favorito: false
        },
        {
            cid10: "Q610",
            descricao: "Cisto congênito único do rim",
            favorito: false
        },
        {
            cid10: "Q611",
            descricao: "Rim policístico, autossômico recessivo",
            favorito: false
        },
        {
            cid10: "Q612",
            descricao: "Rim policístico, autossômico dominante",
            favorito: false
        },
        {
            cid10: "Q613",
            descricao: "Rim policístico não especificado",
            favorito: false
        },
        {
            cid10: "Q614",
            descricao: "Displasia renal",
            favorito: false
        },
        {
            cid10: "Q615",
            descricao: "Cisto medular do rim",
            favorito: false
        },
        {
            cid10: "Q618",
            descricao: "Outras doenças císticas do rim",
            favorito: false
        },
        {
            cid10: "Q619",
            descricao: "Doença cística não especificada do rim",
            favorito: false
        },
        {
            cid10: "Q620",
            descricao: "Hidronefrose congênita",
            favorito: false
        },
        {
            cid10: "Q621",
            descricao: "Atresia e estenose do ureter",
            favorito: false
        },
        {
            cid10: "Q622",
            descricao: "Megaureter congênito",
            favorito: false
        },
        {
            cid10: "Q623",
            descricao: "Outras anomalias obstrutivas da pelve renal e do ureter",
            favorito: false
        },
        {
            cid10: "Q624",
            descricao: "Agenesia do ureter",
            favorito: false
        },
        {
            cid10: "Q625",
            descricao: "Duplicação do ureter",
            favorito: false
        },
        {
            cid10: "Q626",
            descricao: "Má-posição do ureter",
            favorito: false
        },
        {
            cid10: "Q627",
            descricao: "Refluxo vésico-uretero-renal congênito",
            favorito: false
        },
        {
            cid10: "Q628",
            descricao: "Outras malformações congênitas do ureter",
            favorito: false
        },
        {
            cid10: "Q630",
            descricao: "Rim supranumerário",
            favorito: false
        },
        {
            cid10: "Q631",
            descricao: "Rim lobulado, fundido ou em ferradura",
            favorito: false
        },
        {
            cid10: "Q632",
            descricao: "Rim ectópico",
            favorito: false
        },
        {
            cid10: "Q633",
            descricao: "Rim hiperplásico e gigante",
            favorito: false
        },
        {
            cid10: "Q638",
            descricao: "Outras malformações congênitas especificadas do rim",
            favorito: false
        },
        {
            cid10: "Q639",
            descricao: "Malformação congênita não especificada do rim",
            favorito: false
        },
        {
            cid10: "Q640",
            descricao: "Epispádias",
            favorito: false
        },
        {
            cid10: "Q641",
            descricao: "Extrofia vesical",
            favorito: false
        },
        {
            cid10: "Q642",
            descricao: "Válvulas uretrais posteriores congênitas",
            favorito: false
        },
        {
            cid10: "Q643",
            descricao: "Outras formas de atresia e de estenose de uretra e do colo da bexiga",
            favorito: false
        },
        {
            cid10: "Q644",
            descricao: "Malformação do úraco",
            favorito: false
        },
        {
            cid10: "Q645",
            descricao: "Ausência congênita da bexiga e da uretra",
            favorito: false
        },
        {
            cid10: "Q646",
            descricao: "Divertículo congênito da bexiga",
            favorito: false
        },
        {
            cid10: "Q647",
            descricao: "Outras malformações congênitas da bexiga e da uretra",
            favorito: false
        },
        {
            cid10: "Q648",
            descricao: "Outras malformações congênitas especificadas do aparelho urinário",
            favorito: false
        },
        {
            cid10: "Q649",
            descricao: "Malformação congênita não especificada do aparelho urinário",
            favorito: false
        },
        {
            cid10: "Q650",
            descricao: "Luxação congênita unilateral do quadril",
            favorito: false
        },
        {
            cid10: "Q651",
            descricao: "Luxação congênita bilateral do quadril",
            favorito: false
        },
        {
            cid10: "Q652",
            descricao: "Luxação congênita não especificada do quadril",
            favorito: false
        },
        {
            cid10: "Q653",
            descricao: "Subluxação congênita unilateral do quadril",
            favorito: false
        },
        {
            cid10: "Q654",
            descricao: "Subluxação congênita bilateral do quadril",
            favorito: false
        },
        {
            cid10: "Q655",
            descricao: "Subluxação congênita não especificada do quadril",
            favorito: false
        },
        {
            cid10: "Q656",
            descricao: "Quadril instável",
            favorito: false
        },
        {
            cid10: "Q658",
            descricao: "Outras deformidades congênitas do quadril",
            favorito: false
        },
        {
            cid10: "Q659",
            descricao: "Deformidade congênita não especificada do quadril",
            favorito: false
        },
        {
            cid10: "Q660",
            descricao: "Pé torto eqüinovaro",
            favorito: false
        },
        {
            cid10: "Q661",
            descricao: "Pé torto calcaneovaro",
            favorito: false
        },
        {
            cid10: "Q662",
            descricao: "Metatarso varo",
            favorito: false
        },
        {
            cid10: "Q663",
            descricao: "Outras deformidades congênitas dos pés em varo",
            favorito: false
        },
        {
            cid10: "Q664",
            descricao: "Pé torto calcaneovalgo",
            favorito: false
        },
        {
            cid10: "Q665",
            descricao: "Pé chato congênito",
            favorito: false
        },
        {
            cid10: "Q666",
            descricao: "Outras deformidades congênitas dos pés em valgo",
            favorito: false
        },
        {
            cid10: "Q667",
            descricao: "Pé cavo",
            favorito: false
        },
        {
            cid10: "Q668",
            descricao: "Outras deformidades congênitas do pé",
            favorito: false
        },
        {
            cid10: "Q669",
            descricao: "Deformidade congênita não especificada do pé",
            favorito: false
        },
        {
            cid10: "Q670",
            descricao: "Assimetria facial",
            favorito: false
        },
        {
            cid10: "Q671",
            descricao: "Deformidade facial por compressão",
            favorito: false
        },
        {
            cid10: "Q672",
            descricao: "Dolicocefalia",
            favorito: false
        },
        {
            cid10: "Q673",
            descricao: "Plagiocefalia",
            favorito: false
        },
        {
            cid10: "Q674",
            descricao: "Outras deformidades congênitas do crânio, da face e da mandíbula",
            favorito: false
        },
        {
            cid10: "Q675",
            descricao: "Deformidades congênitas da coluna vertebral",
            favorito: false
        },
        {
            cid10: "Q676",
            descricao: "Tórax escavado",
            favorito: false
        },
        {
            cid10: "Q677",
            descricao: "Tórax carinado",
            favorito: false
        },
        {
            cid10: "Q678",
            descricao: "Outras deformidades congênitas do tórax",
            favorito: false
        },
        {
            cid10: "Q680",
            descricao: "Deformidade congênita do músculo esternocleidomastoideu",
            favorito: false
        },
        {
            cid10: "Q681",
            descricao: "Deformidade congênita da mão",
            favorito: false
        },
        {
            cid10: "Q682",
            descricao: "Deformidade congênita do joelho",
            favorito: false
        },
        {
            cid10: "Q683",
            descricao: "Encurvamento congênito do fêmur",
            favorito: false
        },
        {
            cid10: "Q684",
            descricao: "Encurvamento congênito da tíbia e da perônio [fíbula]",
            favorito: false
        },
        {
            cid10: "Q685",
            descricao: "Encurvamento congênito de ossos longos não especificados do membro inferior",
            favorito: false
        },
        {
            cid10: "Q688",
            descricao: "Outras deformidades osteomusculares congênitas",
            favorito: false
        },
        {
            cid10: "Q690",
            descricao: "Dedo(s) da mão supranumerário(s)",
            favorito: false
        },
        {
            cid10: "Q691",
            descricao: "Polegar(es) supranumerário(s)",
            favorito: false
        },
        {
            cid10: "Q692",
            descricao: "Artelho(s) supranumerário(s)",
            favorito: false
        },
        {
            cid10: "Q699",
            descricao: "Polidactilia não especificada",
            favorito: false
        },
        {
            cid10: "Q700",
            descricao: "Coalescência dos dedos (dedos da mão fundidos)",
            favorito: false
        },
        {
            cid10: "Q701",
            descricao: "Dedos palmados",
            favorito: false
        },
        {
            cid10: "Q702",
            descricao: "Coalescência dos artelhos (artelhos fundidos)",
            favorito: false
        },
        {
            cid10: "Q703",
            descricao: "Artelhos palmados",
            favorito: false
        },
        {
            cid10: "Q704",
            descricao: "Polissindactilia",
            favorito: false
        },
        {
            cid10: "Q709",
            descricao: "Sindactilia não especificada",
            favorito: false
        },
        {
            cid10: "Q710",
            descricao: "Ausência congênita completa do(s) membro(s) superior(es)",
            favorito: false
        },
        {
            cid10: "Q711",
            descricao: "Ausência congênita do braço e do antebraço, com mão presente",
            favorito: false
        },
        {
            cid10: "Q712",
            descricao: "Ausência congênita do antebraço e da mão",
            favorito: false
        },
        {
            cid10: "Q713",
            descricao: "Ausência congênita da mão e de dedo(s)",
            favorito: false
        },
        {
            cid10: "Q714",
            descricao: "Defeito de redução longitudinal do rádio",
            favorito: false
        },
        {
            cid10: "Q715",
            descricao: "Defeito de redução longitudinal do cúbito [ulna]",
            favorito: false
        },
        {
            cid10: "Q716",
            descricao: "Mão em garra de lagosta",
            favorito: false
        },
        {
            cid10: "Q718",
            descricao: "Outros defeitos de redução do membro superior",
            favorito: false
        },
        {
            cid10: "Q719",
            descricao: "Defeito por redução do membro superior, não especificado",
            favorito: false
        },
        {
            cid10: "Q720",
            descricao: "Ausência congênita completa do(s) membro(s) inferior(es)",
            favorito: false
        },
        {
            cid10: "Q721",
            descricao: "Ausência congênita da coxa e da perna com pé presente",
            favorito: false
        },
        {
            cid10: "Q722",
            descricao: "Ausência congênita da perna e do pé",
            favorito: false
        },
        {
            cid10: "Q723",
            descricao: "Ausência congênita do pé e de artelho(s)",
            favorito: false
        },
        {
            cid10: "Q724",
            descricao: "Defeito por redução longitudinal da tíbia",
            favorito: false
        },
        {
            cid10: "Q725",
            descricao: "Defeito por redução longitudinal da tíbia",
            favorito: false
        },
        {
            cid10: "Q726",
            descricao: "Defeito por redução longitudinal do perônio [fíbula]",
            favorito: false
        },
        {
            cid10: "Q727",
            descricao: "Pé bífido",
            favorito: false
        },
        {
            cid10: "Q728",
            descricao: "Outros defeitos por redução do(s) membro(s) inferior(es)",
            favorito: false
        },
        {
            cid10: "Q729",
            descricao: "Defeito não especificado por redução do membro inferior",
            favorito: false
        },
        {
            cid10: "Q730",
            descricao: "Ausência congênita de membro(s) não especificado(s)",
            favorito: false
        },
        {
            cid10: "Q731",
            descricao: "Focomelia, membro(s) não especificado(s)",
            favorito: false
        },
        {
            cid10: "Q738",
            descricao: "Outros defeitos por redução de membro(s) não especificado(s)",
            favorito: false
        },
        {
            cid10: "Q740",
            descricao: "Outras malformações congênitas do(s) membro(s) superiores, inclusive da cintura escapular",
            favorito: false
        },
        {
            cid10: "Q741",
            descricao: "Malformação congênita do joelho",
            favorito: false
        },
        {
            cid10: "Q742",
            descricao: "Outras malformações congênitas do(s) membro(s) inferiores, inclusive da cintura pélvica",
            favorito: false
        },
        {
            cid10: "Q743",
            descricao: "Artrogripose congênita múltipla",
            favorito: false
        },
        {
            cid10: "Q748",
            descricao: "Outras malformações congênitas especificadas de membro(s)",
            favorito: false
        },
        {
            cid10: "Q749",
            descricao: "Malformações congênitas não especificadas de membro(s)",
            favorito: false
        },
        {
            cid10: "Q750",
            descricao: "Craniossinostose",
            favorito: false
        },
        {
            cid10: "Q751",
            descricao: "Disostose craniofacial",
            favorito: false
        },
        {
            cid10: "Q752",
            descricao: "Hipertelorismo",
            favorito: false
        },
        {
            cid10: "Q753",
            descricao: "Macrocefalia",
            favorito: false
        },
        {
            cid10: "Q754",
            descricao: "Disostose mandíbulo-facial",
            favorito: false
        },
        {
            cid10: "Q755",
            descricao: "Disostose óculo-mandibular",
            favorito: false
        },
        {
            cid10: "Q758",
            descricao: "Outras malformações congênitas especificadas dos ossos do crânio e da face",
            favorito: false
        },
        {
            cid10: "Q759",
            descricao: "Malformação congênita não especificada dos ossos do crânio e da face",
            favorito: false
        },
        {
            cid10: "Q760",
            descricao: "Espinha bífida oculta",
            favorito: false
        },
        {
            cid10: "Q761",
            descricao: "Síndrome de Klippel-Feil",
            favorito: false
        },
        {
            cid10: "Q762",
            descricao: "Espondilolistese congênita",
            favorito: false
        },
        {
            cid10: "Q763",
            descricao: "Escoliose congênita devida à malformação óssea congênita",
            favorito: false
        },
        {
            cid10: "Q764",
            descricao: "Outras malformações congênitas da coluna vertebral não-associadas com escoliose",
            favorito: false
        },
        {
            cid10: "Q765",
            descricao: "Costela cervical",
            favorito: false
        },
        {
            cid10: "Q766",
            descricao: "Outras malformações congênitas das costelas",
            favorito: false
        },
        {
            cid10: "Q767",
            descricao: "Malformação congênita do esterno",
            favorito: false
        },
        {
            cid10: "Q768",
            descricao: "Outras malformações congênitas dos ossos do tórax",
            favorito: false
        },
        {
            cid10: "Q769",
            descricao: "Malformação congênita não especificada dos ossos do tórax",
            favorito: false
        },
        {
            cid10: "Q770",
            descricao: "Acondrogenesia",
            favorito: false
        },
        {
            cid10: "Q771",
            descricao: "Nanismo tanatofórico",
            favorito: false
        },
        {
            cid10: "Q772",
            descricao: "Síndrome das costelas curtas",
            favorito: false
        },
        {
            cid10: "Q773",
            descricao: "Condrodisplasia puntacta",
            favorito: false
        },
        {
            cid10: "Q774",
            descricao: "Acondroplasia",
            favorito: false
        },
        {
            cid10: "Q775",
            descricao: "Displasia diastrófica",
            favorito: false
        },
        {
            cid10: "Q776",
            descricao: "Displasia condroectodérmica",
            favorito: false
        },
        {
            cid10: "Q777",
            descricao: "Displasia espondiloepifisária",
            favorito: false
        },
        {
            cid10: "Q778",
            descricao: "Outras osteocondrodisplasias com anomalias do crescimento dos ossos longos e da coluna vertebral",
            favorito: false
        },
        {
            cid10: "Q779",
            descricao: "Osteocondrodisplasia não especificada com anomalias do crescimento dos ossos longos e da coluna vertebral",
            favorito: false
        },
        {
            cid10: "Q780",
            descricao: "Osteogênese imperfeita",
            favorito: false
        },
        {
            cid10: "Q781",
            descricao: "Displasia poliostótica fibrosa",
            favorito: false
        },
        {
            cid10: "Q782",
            descricao: "Osteopetrose",
            favorito: false
        },
        {
            cid10: "Q783",
            descricao: "Displasia diafisária progressiva",
            favorito: false
        },
        {
            cid10: "Q784",
            descricao: "Encondromatose",
            favorito: false
        },
        {
            cid10: "Q785",
            descricao: "Displasia metafisária",
            favorito: false
        },
        {
            cid10: "Q786",
            descricao: "Exostoses congênitas múltiplas",
            favorito: false
        },
        {
            cid10: "Q788",
            descricao: "Outras osteocondrodisplasias especificadas",
            favorito: false
        },
        {
            cid10: "Q789",
            descricao: "Osteocondrodisplasia não especificada",
            favorito: false
        },
        {
            cid10: "Q790",
            descricao: "Hérnia diafragmática congênita",
            favorito: false
        },
        {
            cid10: "Q791",
            descricao: "Outras malformações congênitas do diafragma",
            favorito: false
        },
        {
            cid10: "Q792",
            descricao: "Exonfalia",
            favorito: false
        },
        {
            cid10: "Q793",
            descricao: "Gastrosquise",
            favorito: false
        },
        {
            cid10: "Q794",
            descricao: "Síndrome do abdome em ameixa seca prune belly syndrome",
            favorito: false
        },
        {
            cid10: "Q795",
            descricao: "Outras malformações congênitas da parede abdominal",
            favorito: false
        },
        {
            cid10: "Q796",
            descricao: "Síndrome de Ehlers-Danlos",
            favorito: false
        },
        {
            cid10: "Q798",
            descricao: "Outras malformações congênitas do sistema osteomuscular",
            favorito: false
        },
        {
            cid10: "Q799",
            descricao: "Malformação congênita não especificada do sistema osteomuscular",
            favorito: false
        },
        {
            cid10: "Q800",
            descricao: "Ictiose vulgar",
            favorito: false
        },
        {
            cid10: "Q801",
            descricao: "Ictiose ligada ao cromossomo X",
            favorito: false
        },
        {
            cid10: "Q802",
            descricao: "Ictiose lamelar",
            favorito: false
        },
        {
            cid10: "Q803",
            descricao: "Eritrodermia ictiosiforme bulhosa congênita",
            favorito: false
        },
        {
            cid10: "Q804",
            descricao: "Feto arlequim",
            favorito: false
        },
        {
            cid10: "Q808",
            descricao: "Outras ictioses congênitas",
            favorito: false
        },
        {
            cid10: "Q809",
            descricao: "Ictiose congênita não especificada",
            favorito: false
        },
        {
            cid10: "Q810",
            descricao: "Epidermólise bolhosa simples",
            favorito: false
        },
        {
            cid10: "Q811",
            descricao: "Epidermólise bolhosa letal",
            favorito: false
        },
        {
            cid10: "Q812",
            descricao: "Epidermólise bolhosa distrófica",
            favorito: false
        },
        {
            cid10: "Q818",
            descricao: "Outras epidermólises bolhosas",
            favorito: false
        },
        {
            cid10: "Q819",
            descricao: "Epidermólise bolhosa não especificada",
            favorito: false
        },
        {
            cid10: "Q820",
            descricao: "Linfedema hereditário",
            favorito: false
        },
        {
            cid10: "Q821",
            descricao: "Xeroderma pigmentoso",
            favorito: false
        },
        {
            cid10: "Q822",
            descricao: "Mastocitose",
            favorito: false
        },
        {
            cid10: "Q823",
            descricao: "Incontinentia pigmenti",
            favorito: false
        },
        {
            cid10: "Q824",
            descricao: "Displasia ectodérmica (anidrótica)",
            favorito: false
        },
        {
            cid10: "Q825",
            descricao: "Nevo não-neoplásico congênito",
            favorito: false
        },
        {
            cid10: "Q828",
            descricao: "Outras malformações congênitas especificadas da pele",
            favorito: false
        },
        {
            cid10: "Q829",
            descricao: "Malformação congênita não especificada da pele",
            favorito: false
        },
        {
            cid10: "Q830",
            descricao: "Ausência congênita da mama com ausência do mamilo",
            favorito: false
        },
        {
            cid10: "Q831",
            descricao: "Mama supranumerária",
            favorito: false
        },
        {
            cid10: "Q832",
            descricao: "Ausência de mamilo",
            favorito: false
        },
        {
            cid10: "Q833",
            descricao: "Mamilo acessório",
            favorito: false
        },
        {
            cid10: "Q838",
            descricao: "Outras malformações congênitas da mama",
            favorito: false
        },
        {
            cid10: "Q839",
            descricao: "Malformação congênita não especificada da mama",
            favorito: false
        },
        {
            cid10: "Q840",
            descricao: "Alopécia congênita",
            favorito: false
        },
        {
            cid10: "Q841",
            descricao: "Alterações morfológicas congênitas dos cabelos não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "Q842",
            descricao: "Outras malformações congênitas dos cabelos",
            favorito: false
        },
        {
            cid10: "Q843",
            descricao: "Anoníquia",
            favorito: false
        },
        {
            cid10: "Q844",
            descricao: "Leuconíquia congênita",
            favorito: false
        },
        {
            cid10: "Q845",
            descricao: "Hipertrofia e alargamento das unhas",
            favorito: false
        },
        {
            cid10: "Q846",
            descricao: "Outras malformações congênitas das unhas",
            favorito: false
        },
        {
            cid10: "Q848",
            descricao: "Outras malformações congênitas especificadas do tegumento",
            favorito: false
        },
        {
            cid10: "Q849",
            descricao: "Malformação congênita não especificada do tegumento",
            favorito: false
        },
        {
            cid10: "Q850",
            descricao: "Neurofibromatose (não-maligna)",
            favorito: false
        },
        {
            cid10: "Q851",
            descricao: "Esclerose tuberosa",
            favorito: false
        },
        {
            cid10: "Q858",
            descricao: "Outras facomatoses não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "Q859",
            descricao: "Facomatose não especificada",
            favorito: false
        },
        {
            cid10: "Q860",
            descricao: "Síndrome fetal alcoólico (dismórfico)",
            favorito: false
        },
        {
            cid10: "Q861",
            descricao: "Síndrome fetal devida à hidantoína",
            favorito: false
        },
        {
            cid10: "Q862",
            descricao: "Dismorfismo devido ao Warfarin",
            favorito: false
        },
        {
            cid10: "Q868",
            descricao: "Outras síndromes com malformações congênitas devidas a causas exógenas conhecidas",
            favorito: false
        },
        {
            cid10: "Q870",
            descricao: "Síndromes com malformações congênitas afetando predominantemente o aspecto da face",
            favorito: false
        },
        {
            cid10: "Q871",
            descricao: "Síndromes com malformações congênitas associadas predominantemente com nanismo",
            favorito: false
        },
        {
            cid10: "Q872",
            descricao: "Síndromes com malformações congênitas afetando predominantemente os membros",
            favorito: false
        },
        {
            cid10: "Q873",
            descricao: "Síndromes com malformações congênitas com hipercrescimento precoce",
            favorito: false
        },
        {
            cid10: "Q874",
            descricao: "Síndrome de Marfan",
            favorito: false
        },
        {
            cid10: "Q875",
            descricao: "Outras síndromes com malformações congênitas com outras alterações do esqueleto",
            favorito: false
        },
        {
            cid10: "Q878",
            descricao: "Outras síndromes com malformações congênitas especificadas, não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "Q890",
            descricao: "Malformações congênitas do baço",
            favorito: false
        },
        {
            cid10: "Q891",
            descricao: "Malformações congênitas das supra-renais",
            favorito: false
        },
        {
            cid10: "Q892",
            descricao: "Malformações congênitas de outras glândulas endócrinas",
            favorito: false
        },
        {
            cid10: "Q893",
            descricao: "Situs inversus",
            favorito: false
        },
        {
            cid10: "Q894",
            descricao: "Reunião de gêmeos",
            favorito: false
        },
        {
            cid10: "Q897",
            descricao: "Malformações congênitas múltiplas, não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "Q898",
            descricao: "Outras malformações congênitas especificadas",
            favorito: false
        },
        {
            cid10: "Q899",
            descricao: "Malformações congênitas não especificadas",
            favorito: false
        },
        {
            cid10: "Q900",
            descricao: "Trissomia 21, não-disjunção meiótica",
            favorito: false
        },
        {
            cid10: "Q901",
            descricao: "Trissomia 21, mosaicismo (não-disjunção mitótica)",
            favorito: false
        },
        {
            cid10: "Q902",
            descricao: "Trissomia 21, translocação",
            favorito: false
        },
        {
            cid10: "Q909",
            descricao: "Síndrome de Down não especificada",
            favorito: false
        },
        {
            cid10: "Q910",
            descricao: "Trissomia 18, não-disjunção meiótica",
            favorito: false
        },
        {
            cid10: "Q911",
            descricao: "Trissomia 18, mosaicismo cromossômico (não-disjunção mitótica)",
            favorito: false
        },
        {
            cid10: "Q912",
            descricao: "Trissomia 18, translocação",
            favorito: false
        },
        {
            cid10: "Q913",
            descricao: "Síndrome de Edwards não especificada",
            favorito: false
        },
        {
            cid10: "Q914",
            descricao: "Trissomia 13, não-disjunção meiótica",
            favorito: false
        },
        {
            cid10: "Q915",
            descricao: "Trissomia 13, mosaicismo cromossômico (não-disjunção mitótica)",
            favorito: false
        },
        {
            cid10: "Q916",
            descricao: "Trissomia 13, translocação",
            favorito: false
        },
        {
            cid10: "Q917",
            descricao: "Síndrome de Patau não especificada",
            favorito: false
        },
        {
            cid10: "Q920",
            descricao: "Trissomia de um cromossomo inteiro, não-disjunção meiótica",
            favorito: false
        },
        {
            cid10: "Q921",
            descricao: "Trissomia de um cromossomo inteiro, mosaicismo cromossômico (não-disjunção mitótica)",
            favorito: false
        },
        {
            cid10: "Q922",
            descricao: "Trissomia parcial major",
            favorito: false
        },
        {
            cid10: "Q923",
            descricao: "Trissomia parcial minor",
            favorito: false
        },
        {
            cid10: "Q924",
            descricao: "Duplicações vistas somente na prometáfase",
            favorito: false
        },
        {
            cid10: "Q925",
            descricao: "Duplicação com outros rearranjos complexos",
            favorito: false
        },
        {
            cid10: "Q926",
            descricao: "Cromossomos marcadores suplementares",
            favorito: false
        },
        {
            cid10: "Q927",
            descricao: "Triploidia e poliploidia",
            favorito: false
        },
        {
            cid10: "Q928",
            descricao: "Outras trissomias especificadas e trissomias parciais dos autossomos",
            favorito: false
        },
        {
            cid10: "Q929",
            descricao: "Trissomia e trissomia parcial não especificada dos autossomos",
            favorito: false
        },
        {
            cid10: "Q930",
            descricao: "Monossomia de cromossomo inteiro, não-disjunção meiótica",
            favorito: false
        },
        {
            cid10: "Q931",
            descricao: "Monossomia de cromossomo inteiro, mosaicismo cromossômico (não-disjunção mitótica)",
            favorito: false
        },
        {
            cid10: "Q932",
            descricao: "Cromossomo substituído por anel ou dicêntrico",
            favorito: false
        },
        {
            cid10: "Q933",
            descricao: "Deleção do braço curto do cromossomo 4",
            favorito: false
        },
        {
            cid10: "Q934",
            descricao: "Deleção do braço curto do cromossomo 5",
            favorito: false
        },
        {
            cid10: "Q935",
            descricao: "Outras deleções parciais de cromossomo",
            favorito: false
        },
        {
            cid10: "Q936",
            descricao: "Deleções vistas somente na prometáfase",
            favorito: false
        },
        {
            cid10: "Q937",
            descricao: "Deleções com outros rearranjos complexos",
            favorito: false
        },
        {
            cid10: "Q938",
            descricao: "Outras deleções dos autossomos",
            favorito: false
        },
        {
            cid10: "Q939",
            descricao: "Deleções não especificadas dos autossomos",
            favorito: false
        },
        {
            cid10: "Q950",
            descricao: "Translocação ou inserção equilibrada em sujeito normal",
            favorito: false
        },
        {
            cid10: "Q951",
            descricao: "Inversão cromossômica em sujeito normal",
            favorito: false
        },
        {
            cid10: "Q952",
            descricao: "Rearranjo autossômico equilibrado em sujeito anormal",
            favorito: false
        },
        {
            cid10: "Q953",
            descricao: "Rearranjo sexual/autossômico equilibrado em sujeito anormal",
            favorito: false
        },
        {
            cid10: "Q954",
            descricao: "Sujeito com marcador de heterocromatina",
            favorito: false
        },
        {
            cid10: "Q955",
            descricao: "Sujeito com sítio autossômico frágil",
            favorito: false
        },
        {
            cid10: "Q958",
            descricao: "Outros rearranjos e marcadores equilibrados",
            favorito: false
        },
        {
            cid10: "Q959",
            descricao: "Rearranjos e marcadores equilibrados não especificados",
            favorito: false
        },
        {
            cid10: "Q960",
            descricao: "Cariótipo 45, X",
            favorito: false
        },
        {
            cid10: "Q961",
            descricao: "Cariótipo 46, X iso (Xq)",
            favorito: false
        },
        {
            cid10: "Q962",
            descricao: "Cariótipo 46, X com cromossomo sexual anormal, salvo iso (Xq)",
            favorito: false
        },
        {
            cid10: "Q963",
            descricao: "Mosaicismo cromossômico, 45, X/46, XX ou XY",
            favorito: false
        },
        {
            cid10: "Q964",
            descricao: "Mosaicismo cromossômico, 45, X/outra(s) linhagens celular(es) com cromossomo sexual anormal",
            favorito: false
        },
        {
            cid10: "Q968",
            descricao: "Outras variantes da síndrome de Turner",
            favorito: false
        },
        {
            cid10: "Q969",
            descricao: "Síndrome de Turner não especificada",
            favorito: false
        },
        {
            cid10: "Q970",
            descricao: "Cariótipo 47, XXX",
            favorito: false
        },
        {
            cid10: "Q971",
            descricao: "Mulher com mais de três cromossomos X",
            favorito: false
        },
        {
            cid10: "Q972",
            descricao: "Mosaicismo cromossômico, linhagens com diversos números de cromossomos X",
            favorito: false
        },
        {
            cid10: "Q973",
            descricao: "Mulher com cariótipo 46, XY",
            favorito: false
        },
        {
            cid10: "Q978",
            descricao: "Outras anomalias especificadas dos cromossomos sexuais, fenótipo feminino",
            favorito: false
        },
        {
            cid10: "Q979",
            descricao: "Anomalias não especificadas dos cromossomos sexuais, fenótipo feminino",
            favorito: false
        },
        {
            cid10: "Q980",
            descricao: "Síndrome de Klinefelter, cariótipo 47, XXY",
            favorito: false
        },
        {
            cid10: "Q981",
            descricao: "Síndrome de Klinefelter, homem com mais de dois cromossomos X",
            favorito: false
        },
        {
            cid10: "Q982",
            descricao: "Síndrome de Klinefelter, homem com cariótipo 46, XX",
            favorito: false
        },
        {
            cid10: "Q983",
            descricao: "Outro homem com cariótipo 46, XX",
            favorito: false
        },
        {
            cid10: "Q984",
            descricao: "Síndrome de Klinefelter não especificada",
            favorito: false
        },
        {
            cid10: "Q985",
            descricao: "Cariótipo 47, XYY",
            favorito: false
        },
        {
            cid10: "Q986",
            descricao: "Homem com cromossomos sexuais de estrutura anormal",
            favorito: false
        },
        {
            cid10: "Q987",
            descricao: "Homem com mosaicismo dos cromossomos sexuais",
            favorito: false
        },
        {
            cid10: "Q988",
            descricao: "Outras anomalias especificadas dos cromossomos sexuais, fenótipo masculino",
            favorito: false
        },
        {
            cid10: "Q989",
            descricao: "Anomalias não especificadas dos cromossomos sexuais, fenótipo masculino",
            favorito: false
        },
        {
            cid10: "Q990",
            descricao: "Quimera 46, XX/46, XY",
            favorito: false
        },
        {
            cid10: "Q991",
            descricao: "Hermafrodite verdadeiro 46, XX",
            favorito: false
        },
        {
            cid10: "Q992",
            descricao: "Cromossomo X frágil",
            favorito: false
        },
        {
            cid10: "Q998",
            descricao: "Outras anomalias cromossômicas especificadas",
            favorito: false
        },
        {
            cid10: "Q999",
            descricao: "Anomalia cromossômica não especificada",
            favorito: false
        },
        {
            cid10: "R000",
            descricao: "Taquicardia não especificada",
            favorito: false
        },
        {
            cid10: "R001",
            descricao: "Bradicardia não especificada",
            favorito: false
        },
        {
            cid10: "R002",
            descricao: "Palpitações",
            favorito: false
        },
        {
            cid10: "R008",
            descricao: "Outras anormalidades e as não especificadas do batimento cardíaco",
            favorito: false
        },
        {
            cid10: "R010",
            descricao: "Sopros cardíacos benignos ou inocentes",
            favorito: false
        },
        {
            cid10: "R011",
            descricao: "Sopro cardíaco, não especificado",
            favorito: false
        },
        {
            cid10: "R012",
            descricao: "Outros ruídos cardíacos",
            favorito: false
        },
        {
            cid10: "R02",
            descricao: "Gangrena não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "R030",
            descricao: "Valor elevado da pressão arterial sem o diagnóstico de hipertensão",
            favorito: false
        },
        {
            cid10: "R031",
            descricao: "Valor baixo da pressão arterial não específico",
            favorito: false
        },
        {
            cid10: "R040",
            descricao: "Epistaxis",
            favorito: false
        },
        {
            cid10: "R041",
            descricao: "Hemorragia da garganta",
            favorito: false
        },
        {
            cid10: "R042",
            descricao: "Hemoptise",
            favorito: false
        },
        {
            cid10: "R048",
            descricao: "Hemorragia de outras localizações das vias respiratórias",
            favorito: false
        },
        {
            cid10: "R049",
            descricao: "Hemorragia não especificada das vias respiratórias",
            favorito: false
        },
        {
            cid10: "R05",
            descricao: "Tosse",
            favorito: false
        },
        {
            cid10: "R060",
            descricao: "Dispnéia",
            favorito: false
        },
        {
            cid10: "R061",
            descricao: "Estridor",
            favorito: false
        },
        {
            cid10: "R062",
            descricao: "Respiração ofegante",
            favorito: false
        },
        {
            cid10: "R063",
            descricao: "Respiração periódica",
            favorito: false
        },
        {
            cid10: "R064",
            descricao: "Hiperventilação",
            favorito: false
        },
        {
            cid10: "R065",
            descricao: "Respiração pela boca",
            favorito: false
        },
        {
            cid10: "R066",
            descricao: "Soluço",
            favorito: false
        },
        {
            cid10: "R067",
            descricao: "Espirro",
            favorito: false
        },
        {
            cid10: "R068",
            descricao: "Outras anormalidades e as não especificadas da respiração",
            favorito: false
        },
        {
            cid10: "R070",
            descricao: "Dor de garganta",
            favorito: false
        },
        {
            cid10: "R071",
            descricao: "Dor torácica ao respirar",
            favorito: false
        },
        {
            cid10: "R072",
            descricao: "Dor precordial",
            favorito: false
        },
        {
            cid10: "R073",
            descricao: "Outra dor torácica",
            favorito: false
        },
        {
            cid10: "R074",
            descricao: "Dor torácica, não especificada",
            favorito: false
        },
        {
            cid10: "R090",
            descricao: "Asfixia",
            favorito: false
        },
        {
            cid10: "R091",
            descricao: "Pleurisia",
            favorito: false
        },
        {
            cid10: "R092",
            descricao: "Parada respiratória",
            favorito: false
        },
        {
            cid10: "R093",
            descricao: "Escarro anormal",
            favorito: false
        },
        {
            cid10: "R098",
            descricao: "Outros sintomas e sinais especificados relativos aos aparelhos circulatório e respiratório",
            favorito: false
        },
        {
            cid10: "R100",
            descricao: "Abdome agudo",
            favorito: false
        },
        {
            cid10: "R101",
            descricao: "Dor localizada no abdome superior",
            favorito: false
        },
        {
            cid10: "R102",
            descricao: "Dor pélvica e perineal",
            favorito: false
        },
        {
            cid10: "R103",
            descricao: "Dor localizada em outras partes do abdome inferior",
            favorito: false
        },
        {
            cid10: "R104",
            descricao: "Outras dores abdominais e as não especificadas",
            favorito: false
        },
        {
            cid10: "R11",
            descricao: "Náusea e vômitos",
            favorito: false
        },
        {
            cid10: "R12",
            descricao: "Pirose",
            favorito: false
        },
        {
            cid10: "R13",
            descricao: "Disfagia",
            favorito: false
        },
        {
            cid10: "R14",
            descricao: "Flatulência e afecções correlatas",
            favorito: false
        },
        {
            cid10: "R15",
            descricao: "Incontinência fecal",
            favorito: false
        },
        {
            cid10: "R160",
            descricao: "Hepatomegalia não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "R161",
            descricao: "Esplenomegalia não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "R162",
            descricao: "Hepatomegalia com esplenomegalia não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "R17",
            descricao: "Icterícia não especificada",
            favorito: false
        },
        {
            cid10: "R18",
            descricao: "Ascite",
            favorito: false
        },
        {
            cid10: "R190",
            descricao: "Massa, tumoração ou tumefação intra-abdominal e pélvica",
            favorito: false
        },
        {
            cid10: "R191",
            descricao: "Ruídos hidroaéreos anormais",
            favorito: false
        },
        {
            cid10: "R192",
            descricao: "Peristaltismo visível",
            favorito: false
        },
        {
            cid10: "R193",
            descricao: "Rigidez abdominal",
            favorito: false
        },
        {
            cid10: "R194",
            descricao: "Alteração do hábito intestinal",
            favorito: false
        },
        {
            cid10: "R195",
            descricao: "Outras anormalidades fecais",
            favorito: false
        },
        {
            cid10: "R196",
            descricao: "Halitose",
            favorito: false
        },
        {
            cid10: "R198",
            descricao: "Outros sintomas e sinais especificados relativos ao aparelho digestivo e ao abdome",
            favorito: false
        },
        {
            cid10: "R200",
            descricao: "Anestesia cutânea",
            favorito: false
        },
        {
            cid10: "R201",
            descricao: "Hipoestesia cutânea",
            favorito: false
        },
        {
            cid10: "R202",
            descricao: "Parestesias cutâneas",
            favorito: false
        },
        {
            cid10: "R203",
            descricao: "Hiperestesia",
            favorito: false
        },
        {
            cid10: "R208",
            descricao: "Outros distúrbios e os não especificadas da sensibilidade cutânea",
            favorito: false
        },
        {
            cid10: "R21",
            descricao: "Eritema e outras erupções cutâneas não especificadas",
            favorito: false
        },
        {
            cid10: "R220",
            descricao: "Tumefação, massa ou tumoração localizadas da cabeça",
            favorito: false
        },
        {
            cid10: "R221",
            descricao: "Tumefação, massa ou tumoração localizadas do pescoço",
            favorito: false
        },
        {
            cid10: "R222",
            descricao: "Tumefação, massa ou tumoração localizadas do tronco",
            favorito: false
        },
        {
            cid10: "R223",
            descricao: "Tumefação, massa ou tumoração localizadas de membro superior",
            favorito: false
        },
        {
            cid10: "R224",
            descricao: "Tumefação, massa ou tumoração localizadas no membro inferior",
            favorito: false
        },
        {
            cid10: "R227",
            descricao: "Tumefação, massa ou tumoração localizadas de múltiplas localizações",
            favorito: false
        },
        {
            cid10: "R229",
            descricao: "Tumefação, massa ou tumoração não especificadas, localizadas",
            favorito: false
        },
        {
            cid10: "R230",
            descricao: "Cianose",
            favorito: false
        },
        {
            cid10: "R231",
            descricao: "Palidez",
            favorito: false
        },
        {
            cid10: "R232",
            descricao: "Rubor",
            favorito: false
        },
        {
            cid10: "R233",
            descricao: "Equimoses espontâneas",
            favorito: false
        },
        {
            cid10: "R234",
            descricao: "Alterações na textura da pele",
            favorito: false
        },
        {
            cid10: "R238",
            descricao: "Outras alterações da pele e as não especificadas",
            favorito: false
        },
        {
            cid10: "R250",
            descricao: "Movimentos anormais da cabeça",
            favorito: false
        },
        {
            cid10: "R251",
            descricao: "Tremor não especificado",
            favorito: false
        },
        {
            cid10: "R252",
            descricao: "Cãibras e espasmos",
            favorito: false
        },
        {
            cid10: "R253",
            descricao: "Fasciculação",
            favorito: false
        },
        {
            cid10: "R258",
            descricao: "Outros movimentos involuntários anormais e os não especificados",
            favorito: false
        },
        {
            cid10: "R260",
            descricao: "Marcha atáxica",
            favorito: false
        },
        {
            cid10: "R261",
            descricao: "Marcha paralítica",
            favorito: false
        },
        {
            cid10: "R262",
            descricao: "Dificuldade para andar não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "R268",
            descricao: "Outras anormalidades da marcha e da mobilidade e as não especificadas",
            favorito: false
        },
        {
            cid10: "R270",
            descricao: "Ataxia não especificada",
            favorito: false
        },
        {
            cid10: "R278",
            descricao: "Outros distúrbios da coordenação e os não especificados",
            favorito: false
        },
        {
            cid10: "R290",
            descricao: "Tetania",
            favorito: false
        },
        {
            cid10: "R291",
            descricao: "Meningismo",
            favorito: false
        },
        {
            cid10: "R292",
            descricao: "Reflexos anormais",
            favorito: false
        },
        {
            cid10: "R293",
            descricao: "Postura anormal",
            favorito: false
        },
        {
            cid10: "R294",
            descricao: "Quadril estalante",
            favorito: false
        },
        {
            cid10: "R296",
            descricao: "Tendência a queda, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "R298",
            descricao: "Outros sintomas e sinais relativos aos sistemas nervoso e osteomuscular e os não especificados",
            favorito: false
        },
        {
            cid10: "R300",
            descricao: "Disúria",
            favorito: false
        },
        {
            cid10: "R301",
            descricao: "Tenesmo vesical",
            favorito: false
        },
        {
            cid10: "R309",
            descricao: "Micção dolorosa, não especificada",
            favorito: false
        },
        {
            cid10: "R31",
            descricao: "Hematúria não especificada",
            favorito: false
        },
        {
            cid10: "R32",
            descricao: "Incontinência urinária não especificada",
            favorito: false
        },
        {
            cid10: "R33",
            descricao: "Retenção urinária",
            favorito: false
        },
        {
            cid10: "R34",
            descricao: "Anúria e oligúria",
            favorito: false
        },
        {
            cid10: "R35",
            descricao: "Poliúria",
            favorito: false
        },
        {
            cid10: "R36",
            descricao: "Secreção uretral",
            favorito: false
        },
        {
            cid10: "R390",
            descricao: "Extravasamento de urina",
            favorito: false
        },
        {
            cid10: "R391",
            descricao: "Outras dificuldades à micção",
            favorito: false
        },
        {
            cid10: "R392",
            descricao: "Uremia extra-renal",
            favorito: false
        },
        {
            cid10: "R398",
            descricao: "Outros sintomas e sinais relativos ao aparelho urinário e os não especificados",
            favorito: false
        },
        {
            cid10: "R400",
            descricao: "Sonolência",
            favorito: false
        },
        {
            cid10: "R401",
            descricao: "Estupor",
            favorito: false
        },
        {
            cid10: "R402",
            descricao: "Coma não especificado",
            favorito: false
        },
        {
            cid10: "R410",
            descricao: "Desorientação não especificada",
            favorito: false
        },
        {
            cid10: "R411",
            descricao: "Amnésia anterógrada",
            favorito: false
        },
        {
            cid10: "R412",
            descricao: "Amnésia retrógrada",
            favorito: false
        },
        {
            cid10: "R413",
            descricao: "Outra amnésia",
            favorito: false
        },
        {
            cid10: "R418",
            descricao: "Outros sintomas e sinais especificados relativos às funções cognitivas e à consciência",
            favorito: false
        },
        {
            cid10: "R42",
            descricao: "Tontura e instabilidade",
            favorito: false
        },
        {
            cid10: "R430",
            descricao: "Anosmia",
            favorito: false
        },
        {
            cid10: "R431",
            descricao: "Parosmia",
            favorito: false
        },
        {
            cid10: "R432",
            descricao: "Parageusia",
            favorito: false
        },
        {
            cid10: "R438",
            descricao: "Outros distúrbios do olfato e do paladar e os não especificados",
            favorito: false
        },
        {
            cid10: "R440",
            descricao: "Alucinações auditivas",
            favorito: false
        },
        {
            cid10: "R441",
            descricao: "Alucinações visuais",
            favorito: false
        },
        {
            cid10: "R442",
            descricao: "Outras alucinações",
            favorito: false
        },
        {
            cid10: "R443",
            descricao: "Alucinações não especificadas",
            favorito: false
        },
        {
            cid10: "R448",
            descricao: "Outros sintomas e sinais especificados relativos às sensações e percepções gerais",
            favorito: false
        },
        {
            cid10: "R450",
            descricao: "Nervosismo",
            favorito: false
        },
        {
            cid10: "R451",
            descricao: "Agitação e inquietação",
            favorito: false
        },
        {
            cid10: "R452",
            descricao: "Tristeza",
            favorito: false
        },
        {
            cid10: "R453",
            descricao: "Apatia e desinteresse",
            favorito: false
        },
        {
            cid10: "R454",
            descricao: "Irritabilidade e mau humor",
            favorito: false
        },
        {
            cid10: "R455",
            descricao: "Hostilidade",
            favorito: false
        },
        {
            cid10: "R456",
            descricao: "Violência física",
            favorito: false
        },
        {
            cid10: "R457",
            descricao: "Estado de choque emocional e tensão, não especificado",
            favorito: false
        },
        {
            cid10: "R458",
            descricao: "Outros sintomas e sinais relativos ao estado emocional",
            favorito: false
        },
        {
            cid10: "R460",
            descricao: "Baixo nível de higiene pessoal",
            favorito: false
        },
        {
            cid10: "R461",
            descricao: "Aparência pessoal bizarra",
            favorito: false
        },
        {
            cid10: "R462",
            descricao: "Comportamento estranho e inexplicável",
            favorito: false
        },
        {
            cid10: "R463",
            descricao: "Hiperatividade",
            favorito: false
        },
        {
            cid10: "R464",
            descricao: "Lentidão e baixa reatividade",
            favorito: false
        },
        {
            cid10: "R465",
            descricao: "Personalidade suspeita e evasiva",
            favorito: false
        },
        {
            cid10: "R466",
            descricao: "Inquietação e preocupação exageradas com acontecimentos estressantes",
            favorito: false
        },
        {
            cid10: "R467",
            descricao: "Verborragia e pormenores circunstanciais mascarando o motivo da consulta",
            favorito: false
        },
        {
            cid10: "R468",
            descricao: "Outros sintomas e sinais relativos à aparência e ao comportamento",
            favorito: false
        },
        {
            cid10: "R470",
            descricao: "Disfasia e afasia",
            favorito: false
        },
        {
            cid10: "R471",
            descricao: "Disartria e anartria",
            favorito: false
        },
        {
            cid10: "R478",
            descricao: "Outros distúrbios da fala e os não especificados",
            favorito: false
        },
        {
            cid10: "R480",
            descricao: "Dislexia e alexia",
            favorito: false
        },
        {
            cid10: "R481",
            descricao: "Agnosia",
            favorito: false
        },
        {
            cid10: "R482",
            descricao: "Apraxia",
            favorito: false
        },
        {
            cid10: "R488",
            descricao: "Outras disfunções simbólicas e as não especificadas",
            favorito: false
        },
        {
            cid10: "R490",
            descricao: "Disfonia",
            favorito: false
        },
        {
            cid10: "R491",
            descricao: "Afonia",
            favorito: false
        },
        {
            cid10: "R492",
            descricao: "Hipernasalidade e hiponasalidade",
            favorito: false
        },
        {
            cid10: "R498",
            descricao: "Outros distúrbios da voz e os não especificados",
            favorito: false
        },
        {
            cid10: "R502",
            descricao: "Febre induzida por drogas",
            favorito: false
        },
        {
            cid10: "R508",
            descricao: "Outra febre especificada",
            favorito: false
        },
        {
            cid10: "R509",
            descricao: "Febre não especificada",
            favorito: false
        },
        {
            cid10: "R51",
            descricao: "Cefaléia",
            favorito: false
        },
        {
            cid10: "R520",
            descricao: "Dor aguda",
            favorito: false
        },
        {
            cid10: "R521",
            descricao: "Dor crônica intratável",
            favorito: true
        },
        {
            cid10: "R522",
            descricao: "Outra dor crônica",
            favorito: true
        },
        {
            cid10: "R529",
            descricao: "Dor não especificada",
            favorito: false
        },
        {
            cid10: "R53",
            descricao: "Mal estar, fadiga",
            favorito: false
        },
        {
            cid10: "R54",
            descricao: "Senilidade",
            favorito: false
        },
        {
            cid10: "R55",
            descricao: "Síncope e colapso",
            favorito: false
        },
        {
            cid10: "R560",
            descricao: "Convulsões febris",
            favorito: false
        },
        {
            cid10: "R568",
            descricao: "Outras convulsões e as não especificadas",
            favorito: false
        },
        {
            cid10: "R570",
            descricao: "Choque cardiogênico",
            favorito: false
        },
        {
            cid10: "R571",
            descricao: "Choque hipovolêmico",
            favorito: false
        },
        {
            cid10: "R578",
            descricao: "Outras formas de choque",
            favorito: false
        },
        {
            cid10: "R579",
            descricao: "Choque não especificado",
            favorito: false
        },
        {
            cid10: "R58",
            descricao: "Hemorragia não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "R590",
            descricao: "Aumento de volume localizado de gânglios linfáticos",
            favorito: false
        },
        {
            cid10: "R591",
            descricao: "Aumento de volume generalizado de gânglios linfáticos",
            favorito: false
        },
        {
            cid10: "R599",
            descricao: "Adenomegalia ou aumento de volume dos gânglios linfáticos, não especificado",
            favorito: false
        },
        {
            cid10: "R600",
            descricao: "Edema localizado",
            favorito: false
        },
        {
            cid10: "R601",
            descricao: "Edema generalizado",
            favorito: false
        },
        {
            cid10: "R609",
            descricao: "Edema não especificado",
            favorito: false
        },
        {
            cid10: "R610",
            descricao: "Hiperidrose localizada",
            favorito: false
        },
        {
            cid10: "R611",
            descricao: "Hiperidrose generalizada",
            favorito: false
        },
        {
            cid10: "R619",
            descricao: "Hiperidrose não especificada",
            favorito: false
        },
        {
            cid10: "R620",
            descricao: "Retardo de maturação",
            favorito: false
        },
        {
            cid10: "R628",
            descricao: "Outras formas de retardo do desenvolvimento fisiológico normal",
            favorito: false
        },
        {
            cid10: "R629",
            descricao: "Retardo do desenvolvimento fisiológico normal, não especificado",
            favorito: false
        },
        {
            cid10: "R630",
            descricao: "Anorexia",
            favorito: false
        },
        {
            cid10: "R631",
            descricao: "Polidipsia",
            favorito: false
        },
        {
            cid10: "R632",
            descricao: "Polifagia",
            favorito: false
        },
        {
            cid10: "R633",
            descricao: "Dificuldades de alimentação e erros na administração de alimentos",
            favorito: false
        },
        {
            cid10: "R634",
            descricao: "Perda de peso anormal",
            favorito: false
        },
        {
            cid10: "R635",
            descricao: "Ganho de peso anormal",
            favorito: false
        },
        {
            cid10: "R638",
            descricao: "Outros sintomas e sinais relativos a ingestão de alimentos e de líquidos",
            favorito: false
        },
        {
            cid10: "R64",
            descricao: "Caquexia",
            favorito: false
        },
        {
            cid10: "R680",
            descricao: "Hipotermia não associada à baixa temperatura ambiental",
            favorito: false
        },
        {
            cid10: "R681",
            descricao: "Sintomas não específicos peculiares à infância",
            favorito: false
        },
        {
            cid10: "R682",
            descricao: "Boca seca, não especificada",
            favorito: false
        },
        {
            cid10: "R683",
            descricao: "Baqueteamento dos dedos",
            favorito: false
        },
        {
            cid10: "R688",
            descricao: "Outros sintomas e sinais gerais especificados",
            favorito: false
        },
        {
            cid10: "R69",
            descricao: "Causas desconhecidas e não especificadas de morbidade",
            favorito: false
        },
        {
            cid10: "R700",
            descricao: "Velocidade de hemossedimentação elevada",
            favorito: false
        },
        {
            cid10: "R701",
            descricao: "Viscosidade plasmática anormal",
            favorito: false
        },
        {
            cid10: "R71",
            descricao: "Anormalidade das hemácias",
            favorito: false
        },
        {
            cid10: "R72",
            descricao: "Anormalidade dos leucócitos não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "R730",
            descricao: "Teste de tolerância a glicose anormal",
            favorito: false
        },
        {
            cid10: "R739",
            descricao: "Hiperglicemia não especificada",
            favorito: false
        },
        {
            cid10: "R740",
            descricao: "Aumento dos níveis de transaminases e da desidrogenase lática (DHL)",
            favorito: false
        },
        {
            cid10: "R748",
            descricao: "Níveis anormais de outras enzimas séricas",
            favorito: false
        },
        {
            cid10: "R749",
            descricao: "Anormalidade dos níveis de enzimas séricas, não especificada",
            favorito: false
        },
        {
            cid10: "R75",
            descricao: "Evidência laboratorial do vírus da imunodeficiência humana [HIV]",
            favorito: false
        },
        {
            cid10: "R760",
            descricao: "Título aumentado de anticorpos",
            favorito: false
        },
        {
            cid10: "R761",
            descricao: "Reação anormal ao teste da tuberculina",
            favorito: false
        },
        {
            cid10: "R762",
            descricao: "Exame sorológico falso positivo para sífilis",
            favorito: false
        },
        {
            cid10: "R768",
            descricao: "Outros achados imunológicos especificados anormais no soro",
            favorito: false
        },
        {
            cid10: "R769",
            descricao: "Achado anormal de exame imunológico sérico, não especificado",
            favorito: false
        },
        {
            cid10: "R770",
            descricao: "Anormalidade da albumina",
            favorito: false
        },
        {
            cid10: "R771",
            descricao: "Anormalidade das globulinas",
            favorito: false
        },
        {
            cid10: "R772",
            descricao: "Anormalidade da alfafetoproteína",
            favorito: false
        },
        {
            cid10: "R778",
            descricao: "Outras anormalidades especificadas das proteínas plasmáticas",
            favorito: false
        },
        {
            cid10: "R779",
            descricao: "Anormalidades de proteína plasmática, não especificadas",
            favorito: false
        },
        {
            cid10: "R780",
            descricao: "Presença de álcool no sangue",
            favorito: false
        },
        {
            cid10: "R781",
            descricao: "Presença de opiáceos no sangue",
            favorito: false
        },
        {
            cid10: "R782",
            descricao: "Presença de cocaína no sangue",
            favorito: false
        },
        {
            cid10: "R783",
            descricao: "Presença de alucinógeno no sangue",
            favorito: false
        },
        {
            cid10: "R784",
            descricao: "Presença de outras drogas com potencial de causar dependência, no sangue",
            favorito: false
        },
        {
            cid10: "R785",
            descricao: "Presença de droga psicotrópica no sangue",
            favorito: false
        },
        {
            cid10: "R786",
            descricao: "Presença de agente esteróide no sangue",
            favorito: false
        },
        {
            cid10: "R787",
            descricao: "Presença de nível anormal de metais pesados no sangue",
            favorito: false
        },
        {
            cid10: "R788",
            descricao: "Presença de outras substâncias especificadas não normalmente encontradas no sangue",
            favorito: false
        },
        {
            cid10: "R789",
            descricao: "Presença de substância não especificada normalmente não encontrada no sangue",
            favorito: false
        },
        {
            cid10: "R790",
            descricao: "Anormalidade do nível de elementos minerais no sangue",
            favorito: false
        },
        {
            cid10: "R798",
            descricao: "Outros achados anormais especificados de exames químicos do sangue",
            favorito: false
        },
        {
            cid10: "R799",
            descricao: "Achado anormal de exame químico do sangue, não especificado",
            favorito: false
        },
        {
            cid10: "R80",
            descricao: "Proteinúria isolada",
            favorito: false
        },
        {
            cid10: "R81",
            descricao: "Glicosúria",
            favorito: false
        },
        {
            cid10: "R820",
            descricao: "Quilúria",
            favorito: false
        },
        {
            cid10: "R821",
            descricao: "Mioglobinúria",
            favorito: false
        },
        {
            cid10: "R822",
            descricao: "Biliúria",
            favorito: false
        },
        {
            cid10: "R823",
            descricao: "Hemoglobinúria",
            favorito: false
        },
        {
            cid10: "R824",
            descricao: "Acetonúria",
            favorito: false
        },
        {
            cid10: "R825",
            descricao: "Níveis urinários elevados de drogas, medicamentos e substâncias biológicas",
            favorito: false
        },
        {
            cid10: "R826",
            descricao: "Níveis urinários anormais de substâncias cuja origem é essencialmente não-medicinal",
            favorito: false
        },
        {
            cid10: "R827",
            descricao: "Achados anormais ao exame microbiológico da urina",
            favorito: false
        },
        {
            cid10: "R828",
            descricao: "Achados anormais ao exame citológico e histológico da urina",
            favorito: false
        },
        {
            cid10: "R829",
            descricao: "Outros achados anormais na urina e os não especificados",
            favorito: false
        },
        {
            cid10: "R830",
            descricao: "Achados anormais no líquido cefalorraquidiano - nível enzimático anormal",
            favorito: false
        },
        {
            cid10: "R831",
            descricao: "Achados anormais no líquido cefalorraquidiano - nível hormonal anormal",
            favorito: false
        },
        {
            cid10: "R832",
            descricao: "Achados anormais no líquido cefalorraquidiano - nível anormal de outras drogas, medicamentos e substâncias biológicas",
            favorito: false
        },
        {
            cid10: "R833",
            descricao: "Achados anormais no líquido cefalorraquidiano - nível anormal de substâncias cuja origem é essencialmente não medicinal",
            favorito: false
        },
        {
            cid10: "R834",
            descricao: "Achados anormais no líquido cefalorraquidiano - achados imunológicos anormais",
            favorito: false
        },
        {
            cid10: "R835",
            descricao: "Achados anormais no líquido cefalorraquidiano - achados microbiológicos anormais",
            favorito: false
        },
        {
            cid10: "R836",
            descricao: "Achados anormais no líquido cefalorraquidiano - achados citológicos anormais",
            favorito: false
        },
        {
            cid10: "R837",
            descricao: "Achados anormais no líquido cefalorraquidiano - achados histológicos anormais",
            favorito: false
        },
        {
            cid10: "R838",
            descricao: "Achados anormais no líquido cefalorraquidiano - outros achados anormais",
            favorito: false
        },
        {
            cid10: "R839",
            descricao: "Achados anormais no líquido cefalorraquidiano - achado anormal não especificado",
            favorito: false
        },
        {
            cid10: "R840",
            descricao: "Achados anormais de material proveniente dos órgãos respiratórios e do tórax - nível enzimático anormal",
            favorito: false
        },
        {
            cid10: "R841",
            descricao: "Achados anormais de material proveniente dos órgãos respiratórios e do tórax - nível hormonal anormal",
            favorito: false
        },
        {
            cid10: "R842",
            descricao: "Achados anormais de material proveniente dos órgãos respiratórios e do tórax - nível anormal de outras drogas, medicamentos e substâncias biológicas",
            favorito: false
        },
        {
            cid10: "R843",
            descricao: "Achados anormais de material proveniente dos órgãos respiratórios e do tórax - nível anormal de substâncias cuja origem é essencialmente não medicinal",
            favorito: false
        },
        {
            cid10: "R844",
            descricao: "Achados anormais de material proveniente dos órgãos respiratórios e do tórax - achados imunológicos anormais",
            favorito: false
        },
        {
            cid10: "R845",
            descricao: "Achados anormais de material proveniente dos órgãos respiratórios e do tórax - achados microbiológicos anormais",
            favorito: false
        },
        {
            cid10: "R846",
            descricao: "Achados anormais de material proveniente dos órgãos respiratórios e do tórax - achados citológicos anormais",
            favorito: false
        },
        {
            cid10: "R847",
            descricao: "Achados anormais de material proveniente dos órgãos respiratórios e do tórax - achados histológicos anormais",
            favorito: false
        },
        {
            cid10: "R848",
            descricao: "Achados anormais de material proveniente dos órgãos respiratórios e do tórax - outros achados anormais",
            favorito: false
        },
        {
            cid10: "R849",
            descricao: "Achados anormais de material proveniente dos órgãos respiratórios e do tórax - achado anormal não especificado",
            favorito: false
        },
        {
            cid10: "R850",
            descricao: "Achados anormais de material proveniente dos órgãos digestivos e da cavidade abdominal - nível enzimático anormal",
            favorito: false
        },
        {
            cid10: "R851",
            descricao: "Achados anormais de material proveniente dos órgãos digestivos e da cavidade abdominal - nível hormonal anormal",
            favorito: false
        },
        {
            cid10: "R852",
            descricao: "Achados anormais de material proveniente dos órgãos digestivos e da cavidade abdominal - nível anormal de outras drogas, medicamentos e substâncias biológicas",
            favorito: false
        },
        {
            cid10: "R853",
            descricao: "Achados anormais de material proveniente dos órgãos digestivos e da cavidade abdominal - nível anormal de substâncias cuja origem é essencialmente não medicinal",
            favorito: false
        },
        {
            cid10: "R854",
            descricao: "Achados anormais de material proveniente dos órgãos digestivos e da cavidade abdominal - achados imunológicos anormais",
            favorito: false
        },
        {
            cid10: "R855",
            descricao: "Achados anormais de material proveniente dos órgãos digestivos e da cavidade abdominal - achados microbiológicos anormais",
            favorito: false
        },
        {
            cid10: "R856",
            descricao: "Achados anormais de material proveniente dos órgãos digestivos e da cavidade abdominal - achados citológicos anormais",
            favorito: false
        },
        {
            cid10: "R857",
            descricao: "Achados anormais de material proveniente dos órgãos digestivos e da cavidade abdominal - achados histológicos anormais",
            favorito: false
        },
        {
            cid10: "R858",
            descricao: "Achados anormais de material proveniente dos órgãos digestivos e da cavidade abdominal - outros achados anormais",
            favorito: false
        },
        {
            cid10: "R859",
            descricao: "Achados anormais de material proveniente dos órgãos digestivos e da cavidade abdominal - achado anormal não especificado",
            favorito: false
        },
        {
            cid10: "R860",
            descricao: "Achados anormais de material proveniente dos órgãos genitais masculinos - nível enzimático anormal",
            favorito: false
        },
        {
            cid10: "R861",
            descricao: "Achados anormais de material proveniente dos órgãos genitais masculinos - nível hormonal anormal",
            favorito: false
        },
        {
            cid10: "R862",
            descricao: "Achados anormais de material proveniente dos órgãos genitais masculinos - nível anormal de outras drogas, medicamentos e substâncias biológicas",
            favorito: false
        },
        {
            cid10: "R863",
            descricao: "Achados anormais de material proveniente dos órgãos genitais masculinos - nível anormal de substâncias cuja origem é essencialmente não medicinal",
            favorito: false
        },
        {
            cid10: "R864",
            descricao: "Achados anormais de material proveniente dos órgãos genitais masculinos - achados imunológicos anormais",
            favorito: false
        },
        {
            cid10: "R865",
            descricao: "Achados anormais de material proveniente dos órgãos genitais masculinos - achados microbiológicos anormais",
            favorito: false
        },
        {
            cid10: "R866",
            descricao: "Achados anormais de material proveniente dos órgãos genitais masculinos - achados citológicos anormais",
            favorito: false
        },
        {
            cid10: "R867",
            descricao: "Achados anormais de material proveniente dos órgãos genitais masculinos - achados histológicos anormais",
            favorito: false
        },
        {
            cid10: "R868",
            descricao: "Achados anormais de material proveniente dos órgãos genitais masculinos - outros achados anormais",
            favorito: false
        },
        {
            cid10: "R869",
            descricao: "Achados anormais de material proveniente dos órgãos genitais masculinos - achado anormal não especificado",
            favorito: false
        },
        {
            cid10: "R870",
            descricao: "Achados anormais de material proveniente dos órgãos genitais femininos - nível enzimático anormal",
            favorito: false
        },
        {
            cid10: "R871",
            descricao: "Achados anormais de material proveniente dos órgãos genitais femininos - nível hormonal anormal",
            favorito: false
        },
        {
            cid10: "R872",
            descricao: "Achados anormais de material proveniente dos órgãos genitais femininos - nível anormal de outras drogas, medicamentos e substâncias biológicas",
            favorito: false
        },
        {
            cid10: "R873",
            descricao: "Achados anormais de material proveniente dos órgãos genitais femininos - nível anormal de substâncias cuja origem é essencialmente não medicinal",
            favorito: false
        },
        {
            cid10: "R874",
            descricao: "Achados anormais de material proveniente dos órgãos genitais femininos - achados imunológicos anormais",
            favorito: false
        },
        {
            cid10: "R875",
            descricao: "Achados anormais de material proveniente dos órgãos genitais femininos - achados microbiológicos anormais",
            favorito: false
        },
        {
            cid10: "R876",
            descricao: "Achados anormais de material proveniente dos órgãos genitais femininos - achados citológicos anormais",
            favorito: false
        },
        {
            cid10: "R877",
            descricao: "Achados anormais de material proveniente dos órgãos genitais femininos - achados histológicos anormais",
            favorito: false
        },
        {
            cid10: "R878",
            descricao: "Achados anormais de material proveniente dos órgãos genitais femininos - outros achados anormais",
            favorito: false
        },
        {
            cid10: "R879",
            descricao: "Achados anormais de material proveniente dos órgãos genitais femininos - achado anormal não especificado",
            favorito: false
        },
        {
            cid10: "R890",
            descricao: "Achados anormais de material proveniente de outros órgãos, aparelhos, sistemas e tecidos - nível enzimático anormal",
            favorito: false
        },
        {
            cid10: "R891",
            descricao: "Achados anormais de material proveniente de outros órgãos, aparelhos, sistemas e tecidos - nível hormonal anormal",
            favorito: false
        },
        {
            cid10: "R892",
            descricao: "Achados anormais de material proveniente de outros órgãos, aparelhos, sistemas e tecidos - nível anormal de outras drogas, medicamentos e substâncias biológicas",
            favorito: false
        },
        {
            cid10: "R893",
            descricao: "Achados anormais de material proveniente de outros órgãos, aparelhos, sistemas e tecidos - nível anormal de substâncias cuja origem é essencialmente não medicinal",
            favorito: false
        },
        {
            cid10: "R894",
            descricao: "Achados anormais de material proveniente de outros órgãos, aparelhos, sistemas e tecidos - achados imunológicos anormais",
            favorito: false
        },
        {
            cid10: "R895",
            descricao: "Achados anormais de material proveniente de outros órgãos, aparelhos, sistemas e tecidos - achados microbiológicos anormais",
            favorito: false
        },
        {
            cid10: "R896",
            descricao: "Achados anormais de material proveniente de outros órgãos, aparelhos, sistemas e tecidos - achados citológicos anormais",
            favorito: false
        },
        {
            cid10: "R897",
            descricao: "Achados anormais de material proveniente de outros órgãos, aparelhos, sistemas e tecidos - achados histológicos anormais",
            favorito: false
        },
        {
            cid10: "R898",
            descricao: "Achados anormais de material proveniente de outros órgãos, aparelhos, sistemas e tecidos - outros achados anormais",
            favorito: false
        },
        {
            cid10: "R899",
            descricao: "Achados anormais de material proveniente de outros órgãos, aparelhos, sistemas e tecidos - achado anormal não especificado",
            favorito: false
        },
        {
            cid10: "R900",
            descricao: "Lesão intracraniana ocupando espaço",
            favorito: false
        },
        {
            cid10: "R908",
            descricao: "Outros resultados anormais de exames para diagnóstico por imagem do sistema nervoso central",
            favorito: false
        },
        {
            cid10: "R91",
            descricao: "Achados anormais, de exames para diagnóstico por imagem, do pulmão",
            favorito: false
        },
        {
            cid10: "R92",
            descricao: "Achados anormais, de exames para diagnóstico por imagem, da mama",
            favorito: false
        },
        {
            cid10: "R930",
            descricao: "Achados anormais de exames para diagnóstico por imagem do crânio e da cabeça não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "R931",
            descricao: "Achados anormais de exames para diagnóstico por imagem do coração e da circulação coronariana",
            favorito: false
        },
        {
            cid10: "R932",
            descricao: "Achados anormais de exames para diagnóstico por imagem do fígado e das vias biliares",
            favorito: false
        },
        {
            cid10: "R933",
            descricao: "Achados anormais de exames para diagnóstico por imagem de outras partes do tubo digestivo",
            favorito: false
        },
        {
            cid10: "R934",
            descricao: "Achados anormais de exames para diagnóstico por imagem dos órgãos urinários",
            favorito: false
        },
        {
            cid10: "R935",
            descricao: "Achados anormais de exames para diagnóstico por imagem de outras regiões do abdome, incluindo o retroperitônio",
            favorito: false
        },
        {
            cid10: "R936",
            descricao: "Achados anormais de exames para diagnóstico por imagem dos membros",
            favorito: false
        },
        {
            cid10: "R937",
            descricao: "Achados anormais de exames para diagnóstico por imagem de outras partes do sistema osteomuscular",
            favorito: false
        },
        {
            cid10: "R938",
            descricao: "Achados anormais de exames diagnósticos por imagem de outras estruturas somáticas especificadas",
            favorito: false
        },
        {
            cid10: "R940",
            descricao: "Resultados anormais de estudos de função do sistema nervoso central",
            favorito: false
        },
        {
            cid10: "R941",
            descricao: "Resultados anormais de estudos de função do sistema nervoso periférico e dos órgãos dos sentidos",
            favorito: false
        },
        {
            cid10: "R942",
            descricao: "Resultados anormais de estudos da função pulmonar",
            favorito: false
        },
        {
            cid10: "R943",
            descricao: "Resultados anormais de estudos da função cardiovascular",
            favorito: false
        },
        {
            cid10: "R944",
            descricao: "Resultados anormais de estudos de função renal",
            favorito: false
        },
        {
            cid10: "R945",
            descricao: "Resultados anormais de estudos de função hepática",
            favorito: false
        },
        {
            cid10: "R946",
            descricao: "Resultados anormais de estudos de função tireoidiana",
            favorito: false
        },
        {
            cid10: "R947",
            descricao: "Resultados anormais de estudos de função de outras glândulas endócrinas",
            favorito: false
        },
        {
            cid10: "R948",
            descricao: "Resultados anormais de estudos de função de outros órgãos, aparelhos e sistemas",
            favorito: false
        },
        {
            cid10: "R95",
            descricao: "Síndrome da morte súbita na infância",
            favorito: false
        },
        {
            cid10: "R960",
            descricao: "Morte instantânea",
            favorito: false
        },
        {
            cid10: "R961",
            descricao: "Morte que ocorre em menos de 24 horas após o início dos sintomas, que não pode ser explicada",
            favorito: false
        },
        {
            cid10: "R98",
            descricao: "Morte sem assistência",
            favorito: false
        },
        {
            cid10: "R99",
            descricao: "Outras causas mal definidas e as não especificadas de mortalidade",
            favorito: false
        },
        {
            cid10: "S000",
            descricao: "Traumatismo superficial do couro cabeludo",
            favorito: false
        },
        {
            cid10: "S001",
            descricao: "Contusão da pálpebra e da região periocular",
            favorito: false
        },
        {
            cid10: "S002",
            descricao: "Outros traumatismos superficiais da pálpebra e da região periocular",
            favorito: false
        },
        {
            cid10: "S003",
            descricao: "Traumatismo superficial do nariz",
            favorito: false
        },
        {
            cid10: "S004",
            descricao: "Traumatismo superficial do ouvido",
            favorito: false
        },
        {
            cid10: "S005",
            descricao: "Traumatismo superficial dos lábios e da cavidade oral",
            favorito: false
        },
        {
            cid10: "S007",
            descricao: "Traumatismos superficiais múltiplos da cabeça",
            favorito: false
        },
        {
            cid10: "S008",
            descricao: "Traumatismo superficial de outras partes da cabeça",
            favorito: false
        },
        {
            cid10: "S009",
            descricao: "Traumatismo superficial da cabeça, parte não especificada",
            favorito: false
        },
        {
            cid10: "S010",
            descricao: "Ferimento do couro cabeludo",
            favorito: false
        },
        {
            cid10: "S011",
            descricao: "Ferimento da pálpebra e da região periocular",
            favorito: false
        },
        {
            cid10: "S012",
            descricao: "Ferimento do nariz",
            favorito: false
        },
        {
            cid10: "S013",
            descricao: "Ferimento do ouvido",
            favorito: false
        },
        {
            cid10: "S014",
            descricao: "Ferimento da bochecha e região têmporo-mandibular",
            favorito: false
        },
        {
            cid10: "S015",
            descricao: "Ferimento do lábio e da cavidade oral",
            favorito: false
        },
        {
            cid10: "S017",
            descricao: "Ferimentos múltiplos da cabeça",
            favorito: false
        },
        {
            cid10: "S018",
            descricao: "Ferimento na cabeça, de outras localizações",
            favorito: false
        },
        {
            cid10: "S019",
            descricao: "Ferimento na cabeça, parte não especificada",
            favorito: false
        },
        {
            cid10: "S020",
            descricao: "Fratura da abóbada do crânio",
            favorito: false
        },
        {
            cid10: "S021",
            descricao: "Fratura da base do crânio",
            favorito: false
        },
        {
            cid10: "S022",
            descricao: "Fratura dos ossos nasais",
            favorito: false
        },
        {
            cid10: "S023",
            descricao: "Fratura do assoalho orbital",
            favorito: false
        },
        {
            cid10: "S024",
            descricao: "Fratura dos ossos malares e maxilares",
            favorito: false
        },
        {
            cid10: "S025",
            descricao: "Fratura de dentes",
            favorito: false
        },
        {
            cid10: "S026",
            descricao: "Fratura de mandíbula",
            favorito: false
        },
        {
            cid10: "S027",
            descricao: "Fraturas múltiplas envolvendo os ossos do crânio e da face",
            favorito: false
        },
        {
            cid10: "S028",
            descricao: "Outras fraturas do crânio e dos ossos da face",
            favorito: false
        },
        {
            cid10: "S029",
            descricao: "Fratura do crânio ou dos ossos da face, parte não especificada",
            favorito: false
        },
        {
            cid10: "S030",
            descricao: "Luxação do maxilar",
            favorito: false
        },
        {
            cid10: "S031",
            descricao: "Luxação da cartilagem do septo nasal",
            favorito: false
        },
        {
            cid10: "S032",
            descricao: "Luxação dentária",
            favorito: false
        },
        {
            cid10: "S033",
            descricao: "Luxação de outras partes da cabeça e das não especificadas",
            favorito: false
        },
        {
            cid10: "S034",
            descricao: "Entorse e distensão do maxilar",
            favorito: false
        },
        {
            cid10: "S035",
            descricao: "Entorse e distensão da articulações e dos ligamentos de outras localizações e de localizações não especificadas da cabeça",
            favorito: false
        },
        {
            cid10: "S040",
            descricao: "Traumatismo do nervo e das vias ópticas",
            favorito: false
        },
        {
            cid10: "S041",
            descricao: "Traumatismo do nervo oculomotor",
            favorito: false
        },
        {
            cid10: "S042",
            descricao: "Traumatismo do nervo troclear",
            favorito: false
        },
        {
            cid10: "S043",
            descricao: "Traumatismo do nervo trigêmeo",
            favorito: false
        },
        {
            cid10: "S044",
            descricao: "Traumatismo do nervo abducente",
            favorito: false
        },
        {
            cid10: "S045",
            descricao: "Traumatismo do nervo facial",
            favorito: false
        },
        {
            cid10: "S046",
            descricao: "Traumatismo do nervo acústico",
            favorito: false
        },
        {
            cid10: "S047",
            descricao: "Traumatismo do nervo acessório",
            favorito: false
        },
        {
            cid10: "S048",
            descricao: "Traumatismo de outros nervos cranianos",
            favorito: false
        },
        {
            cid10: "S049",
            descricao: "Traumatismo de nervo craniano não especificado",
            favorito: false
        },
        {
            cid10: "S050",
            descricao: "Traumatismo da conjuntiva e abrasão da córnea sem menção de corpo estranho",
            favorito: false
        },
        {
            cid10: "S051",
            descricao: "Contusão do globo ocular e dos tecidos da órbita",
            favorito: false
        },
        {
            cid10: "S052",
            descricao: "Laceração e ruptura ocular com prolapso ou perda de tecido intra-ocular",
            favorito: false
        },
        {
            cid10: "S053",
            descricao: "Laceração ocular sem prolapso ou perda de tecido intra-ocular",
            favorito: false
        },
        {
            cid10: "S054",
            descricao: "Ferimento penetrante da órbita com ou sem corpo estranho",
            favorito: false
        },
        {
            cid10: "S055",
            descricao: "Ferimento penetrante do globo ocular com corpo estranho",
            favorito: false
        },
        {
            cid10: "S056",
            descricao: "Ferimento penetrante do globo ocular sem corpo estranho",
            favorito: false
        },
        {
            cid10: "S057",
            descricao: "Avulsão do olho",
            favorito: false
        },
        {
            cid10: "S058",
            descricao: "Outros traumatismos do olho e da órbita",
            favorito: false
        },
        {
            cid10: "S059",
            descricao: "Traumatismo do olho e da órbita, não especificado",
            favorito: false
        },
        {
            cid10: "S060",
            descricao: "Concussão cerebral",
            favorito: false
        },
        {
            cid10: "S061",
            descricao: "Edema cerebral traumático",
            favorito: false
        },
        {
            cid10: "S062",
            descricao: "Traumatismo cerebral difuso",
            favorito: false
        },
        {
            cid10: "S063",
            descricao: "Traumatismo cerebral focal",
            favorito: false
        },
        {
            cid10: "S064",
            descricao: "Hemorragia epidural",
            favorito: false
        },
        {
            cid10: "S065",
            descricao: "Hemorragia subdural devida a traumatismo",
            favorito: false
        },
        {
            cid10: "S066",
            descricao: "Hemorragia subaracnóide devida a traumatismo",
            favorito: false
        },
        {
            cid10: "S067",
            descricao: "Traumatismo intracraniano com coma prolongado",
            favorito: false
        },
        {
            cid10: "S068",
            descricao: "Outros traumatismos intracranianos",
            favorito: false
        },
        {
            cid10: "S069",
            descricao: "Traumatismo intracraniano, não especificado",
            favorito: false
        },
        {
            cid10: "S070",
            descricao: "Lesão por esmagamento da face",
            favorito: false
        },
        {
            cid10: "S071",
            descricao: "Lesão por esmagamento do crânio",
            favorito: false
        },
        {
            cid10: "S078",
            descricao: "Lesão por esmagamento de outras partes da cabeça",
            favorito: false
        },
        {
            cid10: "S079",
            descricao: "Lesão por esmagamento da cabeça, parte não especificada",
            favorito: false
        },
        {
            cid10: "S080",
            descricao: "Avulsão do couro cabeludo",
            favorito: false
        },
        {
            cid10: "S081",
            descricao: "Amputação traumática da orelha",
            favorito: false
        },
        {
            cid10: "S088",
            descricao: "Amputação traumática de outras partes da cabeça",
            favorito: false
        },
        {
            cid10: "S089",
            descricao: "Amputação traumática de parte não especificada da cabeça",
            favorito: false
        },
        {
            cid10: "S090",
            descricao: "Traumatismo dos vasos sangüíneos da cabeça não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "S091",
            descricao: "Traumatismo dos músculos e dos tendões da cabeça",
            favorito: false
        },
        {
            cid10: "S092",
            descricao: "Ruptura traumática do tímpano",
            favorito: false
        },
        {
            cid10: "S097",
            descricao: "Traumatismos múltiplos da cabeça",
            favorito: false
        },
        {
            cid10: "S098",
            descricao: "Outros traumatismos especificados da cabeça",
            favorito: false
        },
        {
            cid10: "S099",
            descricao: "Traumatismo não especificado da cabeça",
            favorito: false
        },
        {
            cid10: "S100",
            descricao: "Contusão da garganta",
            favorito: false
        },
        {
            cid10: "S101",
            descricao: "Outros traumatismos superficiais da garganta e os não especificados",
            favorito: false
        },
        {
            cid10: "S107",
            descricao: "Traumatismos superficiais múltiplos do pescoço",
            favorito: false
        },
        {
            cid10: "S108",
            descricao: "Traumatismo superficial de outras localizações do pescoço",
            favorito: false
        },
        {
            cid10: "S109",
            descricao: "Traumatismo superficial do pescoço, parte não especificada",
            favorito: false
        },
        {
            cid10: "S110",
            descricao: "Ferimento envolvendo a laringe e a traquéia",
            favorito: false
        },
        {
            cid10: "S111",
            descricao: "Ferimento da glândula tireóide",
            favorito: false
        },
        {
            cid10: "S112",
            descricao: "Ferimento envolvendo a faringe e o esôfago cervical",
            favorito: false
        },
        {
            cid10: "S117",
            descricao: "Ferimentos múltiplos do pescoço",
            favorito: false
        },
        {
            cid10: "S118",
            descricao: "Ferimentos de outras partes do pescoço",
            favorito: false
        },
        {
            cid10: "S119",
            descricao: "Ferimentos do pescoço, parte não especificada",
            favorito: false
        },
        {
            cid10: "S120",
            descricao: "Fratura da primeira vértebra cervical",
            favorito: false
        },
        {
            cid10: "S121",
            descricao: "Fratura da segunda vértebra cervical",
            favorito: false
        },
        {
            cid10: "S122",
            descricao: "Fratura de outras vértebras cervicais especificadas",
            favorito: false
        },
        {
            cid10: "S127",
            descricao: "Fraturas múltiplas da coluna cervical",
            favorito: false
        },
        {
            cid10: "S128",
            descricao: "Fratura de outras partes do pescoço",
            favorito: false
        },
        {
            cid10: "S129",
            descricao: "Fratura do pescoço, parte não especificada",
            favorito: false
        },
        {
            cid10: "S130",
            descricao: "Ruptura traumática de disco intervertebral cervical",
            favorito: false
        },
        {
            cid10: "S131",
            descricao: "Luxação de vértebra cervical",
            favorito: false
        },
        {
            cid10: "S132",
            descricao: "Luxação de outras partes do pescoço e das não especificadas",
            favorito: false
        },
        {
            cid10: "S133",
            descricao: "Luxações múltiplas do pescoço",
            favorito: false
        },
        {
            cid10: "S134",
            descricao: "Distensão e entorse da coluna cervical",
            favorito: false
        },
        {
            cid10: "S135",
            descricao: "Distensão e entorse da região tireoidiana",
            favorito: false
        },
        {
            cid10: "S136",
            descricao: "Distensão e entorse de articulações e de ligamentos de outras localizações e das não especificadas do pescoço",
            favorito: false
        },
        {
            cid10: "S140",
            descricao: "Concussão e edema da medula cervical",
            favorito: false
        },
        {
            cid10: "S141",
            descricao: "Outros traumatismos e os não especificados da medula cervical",
            favorito: false
        },
        {
            cid10: "S142",
            descricao: "Traumatismo da raiz nervosa da coluna cervical",
            favorito: false
        },
        {
            cid10: "S143",
            descricao: "Traumatismo do plexo braquial",
            favorito: false
        },
        {
            cid10: "S144",
            descricao: "Traumatismo dos nervos periféricos do pescoço",
            favorito: false
        },
        {
            cid10: "S145",
            descricao: "Traumatismo dos nervos simpáticos do pescoço",
            favorito: false
        },
        {
            cid10: "S146",
            descricao: "Traumatismo de outros nervos e dos não especificados do pescoço",
            favorito: false
        },
        {
            cid10: "S150",
            descricao: "Traumatismo da artéria carótida",
            favorito: false
        },
        {
            cid10: "S151",
            descricao: "Traumatismo da artéria vertebral",
            favorito: false
        },
        {
            cid10: "S152",
            descricao: "Traumatismo da veia jugular externa",
            favorito: false
        },
        {
            cid10: "S153",
            descricao: "Traumatismo da veia jugular interna",
            favorito: false
        },
        {
            cid10: "S157",
            descricao: "Traumatismo de múltiplos vasos sangüíneos ao nível do pescoço",
            favorito: false
        },
        {
            cid10: "S158",
            descricao: "Traumatismo de outros vasos sangüíneos ao nível do pescoço",
            favorito: false
        },
        {
            cid10: "S159",
            descricao: "Traumatismo de vasos sangüíneos não especificados ao nível do pescoço",
            favorito: false
        },
        {
            cid10: "S16",
            descricao: "Traumatismo de tendões e de músculos do pescoço",
            favorito: false
        },
        {
            cid10: "S170",
            descricao: "Lesão por esmagamento da laringe e da traquéia",
            favorito: false
        },
        {
            cid10: "S178",
            descricao: "Lesão por esmagamento de outras partes do pescoço",
            favorito: false
        },
        {
            cid10: "S179",
            descricao: "Lesão por esmagamento do pescoço, parte não especificada",
            favorito: false
        },
        {
            cid10: "S18",
            descricao: "Amputação traumática ao nível do pescoço",
            favorito: false
        },
        {
            cid10: "S197",
            descricao: "Traumatismos múltiplos do pescoço",
            favorito: false
        },
        {
            cid10: "S198",
            descricao: "Outros traumatismos especificados do pescoço",
            favorito: false
        },
        {
            cid10: "S199",
            descricao: "Traumatismo não especificado do pescoço",
            favorito: false
        },
        {
            cid10: "S200",
            descricao: "Contusão da mama",
            favorito: false
        },
        {
            cid10: "S201",
            descricao: "Outros traumatismos superficiais da mama e os não especificados",
            favorito: false
        },
        {
            cid10: "S202",
            descricao: "Contusão do tórax",
            favorito: false
        },
        {
            cid10: "S203",
            descricao: "Outros traumatismos superficiais da parede anterior do tórax",
            favorito: false
        },
        {
            cid10: "S204",
            descricao: "Outros traumatismos superficiais da parede posterior do tórax",
            favorito: false
        },
        {
            cid10: "S207",
            descricao: "Traumatismos superficiais múltiplos do tórax",
            favorito: false
        },
        {
            cid10: "S208",
            descricao: "Traumatismo superficial de outras partes especificadas do tórax e das não especificadas",
            favorito: false
        },
        {
            cid10: "S210",
            descricao: "Ferimento da mama",
            favorito: false
        },
        {
            cid10: "S211",
            descricao: "Ferimento da parede anterior do tórax",
            favorito: false
        },
        {
            cid10: "S212",
            descricao: "Ferimento da parede posterior do tórax",
            favorito: false
        },
        {
            cid10: "S217",
            descricao: "Ferimentos múltiplos da parede torácica",
            favorito: false
        },
        {
            cid10: "S218",
            descricao: "Ferimento de outras partes do tórax",
            favorito: false
        },
        {
            cid10: "S219",
            descricao: "Ferimento do tórax parte não especificada",
            favorito: false
        },
        {
            cid10: "S220",
            descricao: "Fratura de vértebra torácica",
            favorito: false
        },
        {
            cid10: "S221",
            descricao: "Fraturas múltiplas da coluna torácica",
            favorito: false
        },
        {
            cid10: "S222",
            descricao: "Fratura do esterno",
            favorito: false
        },
        {
            cid10: "S223",
            descricao: "Fratura de costela",
            favorito: false
        },
        {
            cid10: "S224",
            descricao: "Fraturas múltiplas de costelas",
            favorito: false
        },
        {
            cid10: "S225",
            descricao: "Tórax fundido (oscilante)",
            favorito: false
        },
        {
            cid10: "S228",
            descricao: "Fraturas de outras partes dos ossos do tórax",
            favorito: false
        },
        {
            cid10: "S229",
            descricao: "Fratura dos ossos do tórax, parte não especificada",
            favorito: false
        },
        {
            cid10: "S230",
            descricao: "Ruptura traumática de disco intervertebral torácico",
            favorito: false
        },
        {
            cid10: "S231",
            descricao: "Luxação de vértebra torácica",
            favorito: false
        },
        {
            cid10: "S232",
            descricao: "Luxação de outras partes do tórax e das não especificadas",
            favorito: false
        },
        {
            cid10: "S233",
            descricao: "Entorse e distensão da coluna torácica",
            favorito: false
        },
        {
            cid10: "S234",
            descricao: "Entorse e distensão das costelas e do esterno",
            favorito: false
        },
        {
            cid10: "S235",
            descricao: "Entorse e distensão de outras partes do tórax e de partes não especificadas",
            favorito: false
        },
        {
            cid10: "S240",
            descricao: "Concussão e edema da medula espinhal torácica",
            favorito: false
        },
        {
            cid10: "S241",
            descricao: "Outros traumatismos da medula espinhal torácica e os não especificados",
            favorito: false
        },
        {
            cid10: "S242",
            descricao: "Traumatismo da raiz de nervo da coluna torácica",
            favorito: false
        },
        {
            cid10: "S243",
            descricao: "Traumatismo de nervos periféricos do tórax",
            favorito: false
        },
        {
            cid10: "S244",
            descricao: "Traumatismo de nervos simpáticos do tórax",
            favorito: false
        },
        {
            cid10: "S245",
            descricao: "Traumatismo de outros nervos do tórax",
            favorito: false
        },
        {
            cid10: "S246",
            descricao: "Traumatismo de nervo do tórax não especificado",
            favorito: false
        },
        {
            cid10: "S250",
            descricao: "Traumatismo da aorta torácica",
            favorito: false
        },
        {
            cid10: "S251",
            descricao: "Traumatismo da artéria subclávia ou inominada [braquiocefálica]",
            favorito: false
        },
        {
            cid10: "S252",
            descricao: "Traumatismo da veia cava superior",
            favorito: false
        },
        {
            cid10: "S253",
            descricao: "Traumatismo da veia subclávia ou inominada [braquiocefálica]",
            favorito: false
        },
        {
            cid10: "S254",
            descricao: "Traumatismo de vasos sangüíneos pulmonares",
            favorito: false
        },
        {
            cid10: "S255",
            descricao: "Traumatismo de vasos sangüíneos intercostais",
            favorito: false
        },
        {
            cid10: "S257",
            descricao: "Traumatismo de múltiplos vasos sangüíneos do tórax",
            favorito: false
        },
        {
            cid10: "S258",
            descricao: "Traumatismo de outros vasos sangüíneos do tórax",
            favorito: false
        },
        {
            cid10: "S259",
            descricao: "Traumatismo de vaso sangüíneo não especificado do tórax",
            favorito: false
        },
        {
            cid10: "S260",
            descricao: "Traumatismo do coração com hemopericárdio",
            favorito: false
        },
        {
            cid10: "S268",
            descricao: "Outros traumatismos do coração",
            favorito: false
        },
        {
            cid10: "S269",
            descricao: "Traumatismo do coração não especificado",
            favorito: false
        },
        {
            cid10: "S270",
            descricao: "Pneumotórax traumático",
            favorito: false
        },
        {
            cid10: "S271",
            descricao: "Hemotórax traumático",
            favorito: false
        },
        {
            cid10: "S272",
            descricao: "Hemopneumotórax traumático",
            favorito: false
        },
        {
            cid10: "S273",
            descricao: "Outros traumatismos do pulmão",
            favorito: false
        },
        {
            cid10: "S274",
            descricao: "Traumatismo dos brônquios",
            favorito: false
        },
        {
            cid10: "S275",
            descricao: "Traumatismo da traquéia torácica",
            favorito: false
        },
        {
            cid10: "S276",
            descricao: "Traumatismo da pleura",
            favorito: false
        },
        {
            cid10: "S277",
            descricao: "Traumatismos múltiplos de órgãos intratorácicos",
            favorito: false
        },
        {
            cid10: "S278",
            descricao: "Traumatismo de outros órgãos intratorácicos especificados",
            favorito: false
        },
        {
            cid10: "S279",
            descricao: "Traumatismo de órgão intratorácico não especificado",
            favorito: false
        },
        {
            cid10: "S280",
            descricao: "Esmagamento do tórax",
            favorito: false
        },
        {
            cid10: "S281",
            descricao: "Amputação traumática de parte do tórax",
            favorito: false
        },
        {
            cid10: "S290",
            descricao: "Traumatismo de músculo e de tendão ao nível tóracico",
            favorito: false
        },
        {
            cid10: "S297",
            descricao: "Traumatismos múltiplos do tórax",
            favorito: false
        },
        {
            cid10: "S298",
            descricao: "Outros traumatismos especificados do tórax",
            favorito: false
        },
        {
            cid10: "S299",
            descricao: "Traumatismo não especificado do tórax",
            favorito: false
        },
        {
            cid10: "S300",
            descricao: "Contusão do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "S301",
            descricao: "Contusão da parede abdominal",
            favorito: false
        },
        {
            cid10: "S302",
            descricao: "Contusão dos órgãos genitais externos",
            favorito: false
        },
        {
            cid10: "S307",
            descricao: "Traumatismos superficiais múltiplos do abdome, do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "S308",
            descricao: "Outros traumatismos superficiais do abdome, do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "S309",
            descricao: "Traumatismo superficial de parte não especificada do abdome, do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "S310",
            descricao: "Ferimento do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "S311",
            descricao: "Ferimento da parede abdominal",
            favorito: false
        },
        {
            cid10: "S312",
            descricao: "Ferimento do pênis",
            favorito: false
        },
        {
            cid10: "S313",
            descricao: "Ferimento do escroto e do testículo",
            favorito: false
        },
        {
            cid10: "S314",
            descricao: "Ferimento da vagina e da vulva",
            favorito: false
        },
        {
            cid10: "S315",
            descricao: "Ferimento de outros órgãos genitais externos e os não especificados",
            favorito: false
        },
        {
            cid10: "S317",
            descricao: "Ferimentos múltiplos do abdome, do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "S318",
            descricao: "Ferimento de outras partes e de partes não especificadas do abdome",
            favorito: false
        },
        {
            cid10: "S320",
            descricao: "Fratura de vértebra lombar",
            favorito: false
        },
        {
            cid10: "S321",
            descricao: "Fratura do sacro",
            favorito: false
        },
        {
            cid10: "S322",
            descricao: "Fratura do cóccix",
            favorito: false
        },
        {
            cid10: "S323",
            descricao: "Fratura do ílio",
            favorito: false
        },
        {
            cid10: "S324",
            descricao: "Fratura do acetábulo",
            favorito: false
        },
        {
            cid10: "S325",
            descricao: "Fratura do púbis",
            favorito: false
        },
        {
            cid10: "S327",
            descricao: "Fraturas múltiplas de coluna lombar e da pelve",
            favorito: false
        },
        {
            cid10: "S328",
            descricao: "Fratura de outras partes da coluna lombossacra e da pelve e de partes não especificadas",
            favorito: false
        },
        {
            cid10: "S330",
            descricao: "Ruptura traumática do disco intervertebral lombar",
            favorito: false
        },
        {
            cid10: "S331",
            descricao: "Luxação da vértebra lombar",
            favorito: false
        },
        {
            cid10: "S332",
            descricao: "Luxação das articulações sacroilíaca e sacrococcígea",
            favorito: false
        },
        {
            cid10: "S333",
            descricao: "Luxação de outras partes e das não especificadas da coluna lombar e da pelve",
            favorito: false
        },
        {
            cid10: "S334",
            descricao: "Ruptura traumática da sínfise púbica",
            favorito: false
        },
        {
            cid10: "S335",
            descricao: "Entorse e distensão da coluna lombar",
            favorito: false
        },
        {
            cid10: "S336",
            descricao: "Entorse e distensão da articulação sacroilíaca",
            favorito: false
        },
        {
            cid10: "S337",
            descricao: "Entorse e distensão de outras partes e das não especificadas da coluna lombar e da pelve",
            favorito: false
        },
        {
            cid10: "S340",
            descricao: "Concussão e edema da medula lombar",
            favorito: false
        },
        {
            cid10: "S341",
            descricao: "Outro traumatismo da medula lombar",
            favorito: false
        },
        {
            cid10: "S342",
            descricao: "Traumatismo de raiz nervosa da medula lombar e sacra",
            favorito: false
        },
        {
            cid10: "S343",
            descricao: "Traumatismo de cauda eqüina",
            favorito: false
        },
        {
            cid10: "S344",
            descricao: "Traumatismo do plexo lombossacro",
            favorito: false
        },
        {
            cid10: "S345",
            descricao: "Traumatismo de nervos simpáticos lombares, sacros e pélvicos",
            favorito: false
        },
        {
            cid10: "S346",
            descricao: "Traumatismo do(s) nervo(s) periférico(s) do abdome, do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "S348",
            descricao: "Traumatismo de outros nervos e dos não especificados ao nível do abdome, do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "S350",
            descricao: "Traumatismo da aorta abdominal",
            favorito: false
        },
        {
            cid10: "S351",
            descricao: "Traumatismo da veia cava inferior",
            favorito: false
        },
        {
            cid10: "S352",
            descricao: "Traumatismo da artéria celíaca ou mesentérica",
            favorito: false
        },
        {
            cid10: "S353",
            descricao: "Traumatismo da veia porta ou esplênica",
            favorito: false
        },
        {
            cid10: "S354",
            descricao: "Traumatismo de vaso sangüíneo renal",
            favorito: false
        },
        {
            cid10: "S355",
            descricao: "Traumatismo de vaso sangüíneo ilíaco",
            favorito: false
        },
        {
            cid10: "S357",
            descricao: "Traumatismo de vasos múltiplos ao nível do abdome, do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "S358",
            descricao: "Traumatismo de outros vasos sangüíneos ao nível do abdome, do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "S359",
            descricao: "Traumatismo de vaso sangüíneo não especificado ao nível do abdome, do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "S360",
            descricao: "Traumatismo do baço",
            favorito: false
        },
        {
            cid10: "S361",
            descricao: "Traumatismo do fígado ou da vesícula biliar",
            favorito: false
        },
        {
            cid10: "S362",
            descricao: "Traumatismo do pâncreas",
            favorito: false
        },
        {
            cid10: "S363",
            descricao: "Traumatismo do estômago",
            favorito: false
        },
        {
            cid10: "S364",
            descricao: "Traumatismo do intestino delgado",
            favorito: false
        },
        {
            cid10: "S365",
            descricao: "Traumatismo do cólon",
            favorito: false
        },
        {
            cid10: "S366",
            descricao: "Traumatismo do reto",
            favorito: false
        },
        {
            cid10: "S367",
            descricao: "Traumatismo de múltiplos órgãos intra-abdominais",
            favorito: false
        },
        {
            cid10: "S368",
            descricao: "Traumatismo de outros órgãos intra-abdominais",
            favorito: false
        },
        {
            cid10: "S369",
            descricao: "Traumatismo de órgão intra-abdominal não especificado",
            favorito: false
        },
        {
            cid10: "S370",
            descricao: "Traumatismo do rim",
            favorito: false
        },
        {
            cid10: "S371",
            descricao: "Traumatismo do ureter",
            favorito: false
        },
        {
            cid10: "S372",
            descricao: "Traumatismo da bexiga",
            favorito: false
        },
        {
            cid10: "S373",
            descricao: "Traumatismo da uretra",
            favorito: false
        },
        {
            cid10: "S374",
            descricao: "Traumatismo do ovário",
            favorito: false
        },
        {
            cid10: "S375",
            descricao: "Traumatismo da trompa de Falópio",
            favorito: false
        },
        {
            cid10: "S376",
            descricao: "Traumatismo do útero",
            favorito: false
        },
        {
            cid10: "S377",
            descricao: "Traumatismo de múltiplos órgãos pélvicos",
            favorito: false
        },
        {
            cid10: "S378",
            descricao: "Traumatismo de outros órgãos pélvicos",
            favorito: false
        },
        {
            cid10: "S379",
            descricao: "Traumatismo de órgão pélvico não especificado",
            favorito: false
        },
        {
            cid10: "S380",
            descricao: "Lesão por esmagamento dos órgãos genitais externos",
            favorito: false
        },
        {
            cid10: "S381",
            descricao: "Lesão por esmagamento de outras partes e de partes não especificadas do abdome, do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "S382",
            descricao: "Amputação traumática de órgãos genitais externos",
            favorito: false
        },
        {
            cid10: "S383",
            descricao: "Amputação traumática de outras partes e de partes não especificadas do abdome, do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "S390",
            descricao: "Traumatismo de músculo e de tendão do abdome, do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "S396",
            descricao: "Traumatismo de órgão(s) intra-abdominal(is) com órgão(s) pélvico(s)",
            favorito: false
        },
        {
            cid10: "S397",
            descricao: "Outros traumatismos múltiplos do abdome, do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "S398",
            descricao: "Outros traumatismos especificados do abdome, do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "S399",
            descricao: "Traumatismo não especificado do abdome, do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "S400",
            descricao: "Contusão do ombro e do braço",
            favorito: false
        },
        {
            cid10: "S407",
            descricao: "Traumatismos superficiais múltiplos do ombro e do braço",
            favorito: false
        },
        {
            cid10: "S408",
            descricao: "Outros traumatismos superficiais do ombro e do braço",
            favorito: false
        },
        {
            cid10: "S409",
            descricao: "Traumatismo superficial não especificado do ombro e do braço",
            favorito: false
        },
        {
            cid10: "S410",
            descricao: "Ferimento do ombro",
            favorito: false
        },
        {
            cid10: "S411",
            descricao: "Ferimento do braço",
            favorito: false
        },
        {
            cid10: "S417",
            descricao: "Ferimentos múltiplos do ombro e do braço",
            favorito: false
        },
        {
            cid10: "S418",
            descricao: "Ferimento de outras partes e de partes não especificadas da cintura escapular",
            favorito: false
        },
        {
            cid10: "S420",
            descricao: "Fratura da clavícula",
            favorito: false
        },
        {
            cid10: "S421",
            descricao: "Fratura da omoplata [escápula]",
            favorito: false
        },
        {
            cid10: "S422",
            descricao: "Fratura da extremidade superior do úmero",
            favorito: false
        },
        {
            cid10: "S423",
            descricao: "Fratura da diáfise do úmero",
            favorito: false
        },
        {
            cid10: "S424",
            descricao: "Fratura da extremidade inferior do úmero",
            favorito: false
        },
        {
            cid10: "S427",
            descricao: "Fraturas múltiplas da clavícula, da omoplata [escápula] e do úmero",
            favorito: false
        },
        {
            cid10: "S428",
            descricao: "Fratura de outras partes do ombro e do braço",
            favorito: false
        },
        {
            cid10: "S429",
            descricao: "Fratura da cintura escapular, parte não especificada",
            favorito: false
        },
        {
            cid10: "S430",
            descricao: "Luxação da articulação do ombro",
            favorito: false
        },
        {
            cid10: "S431",
            descricao: "Luxação da articulação acromioclavicular",
            favorito: false
        },
        {
            cid10: "S432",
            descricao: "Luxação da articulação esternoclavicular",
            favorito: false
        },
        {
            cid10: "S433",
            descricao: "Luxação de outras partes e das não especificadas da cintura escapular",
            favorito: false
        },
        {
            cid10: "S434",
            descricao: "Entorse e distensão de articulação do ombro",
            favorito: false
        },
        {
            cid10: "S435",
            descricao: "Entorse e distensão da articulação acromioclavicular",
            favorito: false
        },
        {
            cid10: "S436",
            descricao: "Entorse e distensão da articulação esternoclavicular",
            favorito: false
        },
        {
            cid10: "S437",
            descricao: "Entorse e distensão de outras partes e de partes não especificadas da cintura escapular",
            favorito: false
        },
        {
            cid10: "S440",
            descricao: "Traumatismo do nervo cubital [ulnar] ao nível do braço",
            favorito: false
        },
        {
            cid10: "S441",
            descricao: "Traumatismo do nervo mediano ao nível do braço",
            favorito: false
        },
        {
            cid10: "S442",
            descricao: "Traumatismo do nervo radial ao nível do braço",
            favorito: false
        },
        {
            cid10: "S443",
            descricao: "Traumatismo do nervo axilar",
            favorito: false
        },
        {
            cid10: "S444",
            descricao: "Traumatismo do nervo musculocutâneo",
            favorito: false
        },
        {
            cid10: "S445",
            descricao: "Traumatismo do nervo sensitivo cutâneo ao nível do ombro e do braço",
            favorito: false
        },
        {
            cid10: "S447",
            descricao: "Traumatismo de múltiplos nervos ao nível do ombro e do braço",
            favorito: false
        },
        {
            cid10: "S448",
            descricao: "Traumatismo de outros nervos ao nível do ombro e do braço",
            favorito: false
        },
        {
            cid10: "S449",
            descricao: "Traumatismo de nervo não especificado ao nível do ombro e do braço",
            favorito: false
        },
        {
            cid10: "S450",
            descricao: "Traumatismo da artéria axilar",
            favorito: false
        },
        {
            cid10: "S451",
            descricao: "Traumatismo da artéria braquial",
            favorito: false
        },
        {
            cid10: "S452",
            descricao: "Traumatismo da veia axilar ou braquial",
            favorito: false
        },
        {
            cid10: "S453",
            descricao: "Traumatismo de veia superficial ao nível do ombro e do braço",
            favorito: false
        },
        {
            cid10: "S457",
            descricao: "Traumatismo de múltiplos vasos sangüíneos ao nível do ombro e do braço",
            favorito: false
        },
        {
            cid10: "S458",
            descricao: "Traumatismo de outros vasos sangüíneos ao nível do ombro e do braço",
            favorito: false
        },
        {
            cid10: "S459",
            descricao: "Traumatismo de vaso sangüíneo não especificado ao nível do ombro e do braço",
            favorito: false
        },
        {
            cid10: "S460",
            descricao: "Traumatismo do tendão do manguito rotador do ombro",
            favorito: false
        },
        {
            cid10: "S461",
            descricao: "Traumatismo do músculo e tendão da cabeça longa do bíceps",
            favorito: false
        },
        {
            cid10: "S462",
            descricao: "Traumatismo do músculo e tendão de outras partes do bíceps",
            favorito: false
        },
        {
            cid10: "S463",
            descricao: "Traumatismo do músculo e tendão do tríceps",
            favorito: false
        },
        {
            cid10: "S467",
            descricao: "Traumatismo de múltiplos músculos e tendões ao nível do ombro e do braço",
            favorito: false
        },
        {
            cid10: "S468",
            descricao: "Traumatismo de outros músculos e tendões ao nível do ombro e do braço",
            favorito: false
        },
        {
            cid10: "S469",
            descricao: "Traumatismo de músculo e tendão não especificado ao nível do ombro e do braço",
            favorito: false
        },
        {
            cid10: "S47",
            descricao: "Lesão por esmagamento do ombro e do braço",
            favorito: false
        },
        {
            cid10: "S480",
            descricao: "Amputação traumática da articulação do ombro",
            favorito: false
        },
        {
            cid10: "S481",
            descricao: "Amputação traumática de localização entre o ombro e o cotovelo",
            favorito: false
        },
        {
            cid10: "S489",
            descricao: "Amputação traumática do ombro e do braço, de localização não especificada",
            favorito: false
        },
        {
            cid10: "S497",
            descricao: "Traumatismos múltiplos do ombro e do braço",
            favorito: false
        },
        {
            cid10: "S498",
            descricao: "Outros traumatismos especificados do ombro e do braço",
            favorito: false
        },
        {
            cid10: "S499",
            descricao: "Traumatismo não especificado do ombro e do braço",
            favorito: false
        },
        {
            cid10: "S500",
            descricao: "Contusão do cotovelo",
            favorito: false
        },
        {
            cid10: "S501",
            descricao: "Contusão de outras partes e de partes não especificadas do antebraço",
            favorito: false
        },
        {
            cid10: "S507",
            descricao: "Traumatismos superficiais múltiplos do antebraço",
            favorito: false
        },
        {
            cid10: "S508",
            descricao: "Outros traumatismos superficiais do antebraço",
            favorito: false
        },
        {
            cid10: "S509",
            descricao: "Traumatismo superficial do antebraço, não especificado",
            favorito: false
        },
        {
            cid10: "S510",
            descricao: "Ferimento do cotovelo",
            favorito: false
        },
        {
            cid10: "S517",
            descricao: "Ferimentos múltiplos do antebraço",
            favorito: false
        },
        {
            cid10: "S518",
            descricao: "Ferimento de outras partes do antebraço",
            favorito: false
        },
        {
            cid10: "S519",
            descricao: "Ferimento do antebraço, parte não especificado",
            favorito: false
        },
        {
            cid10: "S520",
            descricao: "Fratura da extremidade superior do cúbito [ulna]",
            favorito: false
        },
        {
            cid10: "S521",
            descricao: "Fratura da extremidade superior do rádio",
            favorito: false
        },
        {
            cid10: "S522",
            descricao: "Fratura da diáfise do cúbito [ulna]",
            favorito: false
        },
        {
            cid10: "S523",
            descricao: "Fratura da diáfise do rádio",
            favorito: false
        },
        {
            cid10: "S524",
            descricao: "Fratura das diáfises do rádio e do cúbito [ulna]",
            favorito: false
        },
        {
            cid10: "S525",
            descricao: "Fratura da extremidade distal do rádio",
            favorito: false
        },
        {
            cid10: "S526",
            descricao: "Fratura da extremidade distal do rádio e do cúbito [ulna]",
            favorito: false
        },
        {
            cid10: "S527",
            descricao: "Fraturas múltiplas do antebraço",
            favorito: false
        },
        {
            cid10: "S528",
            descricao: "Fratura de outras partes do antebraço",
            favorito: false
        },
        {
            cid10: "S529",
            descricao: "Fratura do antebraço, parte não especificada",
            favorito: false
        },
        {
            cid10: "S530",
            descricao: "Luxação da cabeça do rádio",
            favorito: false
        },
        {
            cid10: "S531",
            descricao: "Luxação do cotovelo, não especificada",
            favorito: false
        },
        {
            cid10: "S532",
            descricao: "Ruptura traumática do ligamento colateral do rádio",
            favorito: false
        },
        {
            cid10: "S533",
            descricao: "Ruptura traumática do ligamento colateral do cúbito [ulna]",
            favorito: false
        },
        {
            cid10: "S534",
            descricao: "Entorse e distensão do cotovelo",
            favorito: false
        },
        {
            cid10: "S540",
            descricao: "Traumatismo do nervo cubital [ulnar] ao nível do antebraço",
            favorito: false
        },
        {
            cid10: "S541",
            descricao: "Traumatismo do nervo mediano ao nível do antebraço",
            favorito: false
        },
        {
            cid10: "S542",
            descricao: "Traumatismo do nervo radial ao nível do antebraço",
            favorito: false
        },
        {
            cid10: "S543",
            descricao: "Traumatismo do nervo cutâneo sensitivo ao nível do antebraço",
            favorito: false
        },
        {
            cid10: "S547",
            descricao: "Traumatismo de múltiplos nervos ao nível do antebraço",
            favorito: false
        },
        {
            cid10: "S548",
            descricao: "Traumatismo de outros nervos ao nível do antebraço",
            favorito: false
        },
        {
            cid10: "S549",
            descricao: "Traumatismo de nervo não especificado ao nível do antebraço",
            favorito: false
        },
        {
            cid10: "S550",
            descricao: "Traumatismo da artéria cubital [ulnar] ao nível do antebraço",
            favorito: false
        },
        {
            cid10: "S551",
            descricao: "Traumatismo da artéria radial ao nível do antebraço",
            favorito: false
        },
        {
            cid10: "S552",
            descricao: "Traumatismo de veia ao nível do antebraço",
            favorito: false
        },
        {
            cid10: "S557",
            descricao: "Traumatismo de múltiplos vasos sangüíneos ao nível do antebraço",
            favorito: false
        },
        {
            cid10: "S558",
            descricao: "Traumatismo de outros vasos sangüíneos ao nível do antebraço",
            favorito: false
        },
        {
            cid10: "S559",
            descricao: "Traumatismo de vasos sangüíneos não especificados ao nível do antebraço",
            favorito: false
        },
        {
            cid10: "S560",
            descricao: "Traumatismo do músculo flexor e tendão do polegar ao nível do antebraço",
            favorito: false
        },
        {
            cid10: "S561",
            descricao: "Traumatismo do músculo flexor e tendão de outro(s) dedo(s) ao nível do antebraço",
            favorito: false
        },
        {
            cid10: "S562",
            descricao: "Traumatismo de outro músculo flexor e tendão ao nível do antebraço",
            favorito: false
        },
        {
            cid10: "S563",
            descricao: "Traumatismo dos músculos extensor ou abdutor e tendões do polegar ao nível do antebraço",
            favorito: false
        },
        {
            cid10: "S564",
            descricao: "Traumatismo do músculo extensor e tendão de outro(s) dedo(s) ao nível do antebraço",
            favorito: false
        },
        {
            cid10: "S565",
            descricao: "Traumatismo de outros músculos extensores e tendões ao nível do antebraço",
            favorito: false
        },
        {
            cid10: "S567",
            descricao: "Traumatismo de músculos e tendões múltiplos ao nível do antebraço",
            favorito: false
        },
        {
            cid10: "S568",
            descricao: "Traumatismo de outros músculos e tendões e dos não especificados ao nível do antebraço",
            favorito: false
        },
        {
            cid10: "S570",
            descricao: "Lesão por esmagamento do cotovelo",
            favorito: false
        },
        {
            cid10: "S578",
            descricao: "Lesão por esmagamento de outras partes do antebraço",
            favorito: false
        },
        {
            cid10: "S579",
            descricao: "Lesão por esmagamento de parte não especificada do antebraço",
            favorito: false
        },
        {
            cid10: "S580",
            descricao: "Amputação traumática ao nível do cotovelo",
            favorito: false
        },
        {
            cid10: "S581",
            descricao: "Amputação traumática do antebraço entre o cotovelo e o punho",
            favorito: false
        },
        {
            cid10: "S589",
            descricao: "Amputação traumática do antebraço, nível não especificado",
            favorito: false
        },
        {
            cid10: "S597",
            descricao: "Traumatismos múltiplos do cotovelo",
            favorito: false
        },
        {
            cid10: "S598",
            descricao: "Outros traumatismos especificados do antebraço",
            favorito: false
        },
        {
            cid10: "S599",
            descricao: "Traumatismo não especificado do antebraço",
            favorito: false
        },
        {
            cid10: "S600",
            descricao: "Contusão de dedo(s) sem lesão da unha",
            favorito: false
        },
        {
            cid10: "S601",
            descricao: "Contusão de dedo(s) com lesão da unha",
            favorito: false
        },
        {
            cid10: "S602",
            descricao: "Contusão de outras partes do punho e da mão",
            favorito: false
        },
        {
            cid10: "S607",
            descricao: "Traumatismos superficiais múltiplos do punho e da mão",
            favorito: false
        },
        {
            cid10: "S608",
            descricao: "Outros traumatismos superficiais do punho e da mão",
            favorito: false
        },
        {
            cid10: "S609",
            descricao: "Traumatismo superficial não especificado do punho e da mão",
            favorito: false
        },
        {
            cid10: "S610",
            descricao: "Ferimento de dedo(s) sem lesão da unha",
            favorito: false
        },
        {
            cid10: "S611",
            descricao: "Ferimento de dedo(s) com lesão da unha",
            favorito: false
        },
        {
            cid10: "S617",
            descricao: "Ferimentos múltiplos do punho e da mão",
            favorito: false
        },
        {
            cid10: "S618",
            descricao: "Ferimento de outras partes do punho e da mão",
            favorito: false
        },
        {
            cid10: "S619",
            descricao: "Ferimento do punho e da mão, parte não especificada",
            favorito: false
        },
        {
            cid10: "S620",
            descricao: "Fratura do osso navicular [escafoíde] da mão",
            favorito: false
        },
        {
            cid10: "S621",
            descricao: "Fratura de outro(s) osso(s) do carpo",
            favorito: false
        },
        {
            cid10: "S622",
            descricao: "Fratura do primeiro metacarpiano",
            favorito: false
        },
        {
            cid10: "S623",
            descricao: "Fratura de outros ossos do metacarpo",
            favorito: false
        },
        {
            cid10: "S624",
            descricao: "Fraturas múltiplas de ossos metacarpianos",
            favorito: false
        },
        {
            cid10: "S625",
            descricao: "Fratura do polegar",
            favorito: false
        },
        {
            cid10: "S626",
            descricao: "Fratura de outros dedos",
            favorito: false
        },
        {
            cid10: "S627",
            descricao: "Fraturas múltiplas de dedo(s)",
            favorito: false
        },
        {
            cid10: "S628",
            descricao: "Fratura de outras partes e de partes não especificadas do punho e da mão",
            favorito: false
        },
        {
            cid10: "S630",
            descricao: "Luxação do punho",
            favorito: false
        },
        {
            cid10: "S631",
            descricao: "Luxação do dedo",
            favorito: false
        },
        {
            cid10: "S632",
            descricao: "Luxações múltiplas dos dedos",
            favorito: false
        },
        {
            cid10: "S633",
            descricao: "Ruptura traumática de ligamento(s) do punho e do carpo",
            favorito: false
        },
        {
            cid10: "S634",
            descricao: "Ruptura traumática do ligamento do dedo nas articulações metacarpofalangianas e interfalangianas",
            favorito: false
        },
        {
            cid10: "S635",
            descricao: "Entorse e distensão do punho",
            favorito: false
        },
        {
            cid10: "S636",
            descricao: "Entorse e distensão do(s) dedo(s)",
            favorito: false
        },
        {
            cid10: "S637",
            descricao: "Entorse e distensão de outras partes e das não especificadas da mão",
            favorito: false
        },
        {
            cid10: "S640",
            descricao: "Traumatismo do nervo cubital [ulnar] ao nível do punho e da mão",
            favorito: false
        },
        {
            cid10: "S641",
            descricao: "Traumatismo do nervo mediano ao nível do punho e da mão",
            favorito: false
        },
        {
            cid10: "S642",
            descricao: "Traumatismo do nervo radial ao nível do punho e da mão",
            favorito: false
        },
        {
            cid10: "S643",
            descricao: "Traumatismo do nervo digital do polegar",
            favorito: false
        },
        {
            cid10: "S644",
            descricao: "Traumatismo do nervo digital de outro dedo",
            favorito: false
        },
        {
            cid10: "S647",
            descricao: "Traumatismo de múltiplos nervos ao nível de punho e da mão",
            favorito: false
        },
        {
            cid10: "S648",
            descricao: "Traumatismo de outros nervos ao nível do punho e da mão",
            favorito: false
        },
        {
            cid10: "S649",
            descricao: "Traumatismo de nervo não especificado ao nível do punho e da mão",
            favorito: false
        },
        {
            cid10: "S650",
            descricao: "Traumatismo da artéria cubital [ulnar] ao nível do punho e da mão",
            favorito: false
        },
        {
            cid10: "S651",
            descricao: "Traumatismo da artéria radial ao nível do punho e da mão",
            favorito: false
        },
        {
            cid10: "S652",
            descricao: "Traumatismo do arco palmar superficial",
            favorito: false
        },
        {
            cid10: "S653",
            descricao: "Traumatismo do arco palmar profundo",
            favorito: false
        },
        {
            cid10: "S654",
            descricao: "Traumatismo de vaso(s) sangüíneo(s) do polegar",
            favorito: false
        },
        {
            cid10: "S655",
            descricao: "Traumatismo de vaso(s) sangüíneo(s) de outro dedo",
            favorito: false
        },
        {
            cid10: "S657",
            descricao: "Traumatismo de vasos sangüíneos múltiplos ao nível de punho e da mão",
            favorito: false
        },
        {
            cid10: "S658",
            descricao: "Traumatismo de outros vasos sangüíneos ao nível do punho e de mão",
            favorito: false
        },
        {
            cid10: "S659",
            descricao: "Traumatismo de vaso sangüíneo não especificado ao nível do punho e da mão",
            favorito: false
        },
        {
            cid10: "S660",
            descricao: "Traumatismo do músculo flexor longo e tendão do polegar ao nível do punho e da mão",
            favorito: false
        },
        {
            cid10: "S661",
            descricao: "Traumatismo do músculo flexor e tendão de outro dedo ao nível do punho e da mão",
            favorito: false
        },
        {
            cid10: "S662",
            descricao: "Traumatismo do músculo extensor e tendão do polegar ao nível do punho e da mão",
            favorito: false
        },
        {
            cid10: "S663",
            descricao: "Traumatismo do músculo extensor e tendão de outro dedo ao nível do punho e da mão",
            favorito: false
        },
        {
            cid10: "S664",
            descricao: "Traumatismo do músculo intrínseco e tendão do polegar ao nível do punho e da mão",
            favorito: false
        },
        {
            cid10: "S665",
            descricao: "Traumatismo do músculo intrínseco e tendão de outro dedo ao nível do punho e da mão",
            favorito: false
        },
        {
            cid10: "S666",
            descricao: "Traumatismo de músculos flexores e tendões múltiplos ao nível do punho e da mão",
            favorito: false
        },
        {
            cid10: "S667",
            descricao: "Traumatismo de músculos extensores e tendões múltiplos ao nível do punho e da mão",
            favorito: false
        },
        {
            cid10: "S668",
            descricao: "Traumatismo de outros músculos e tendões ao nível do punho e da mão",
            favorito: false
        },
        {
            cid10: "S669",
            descricao: "Traumatismo de músculo e tendão não especificado ao nível do punho e da mão",
            favorito: false
        },
        {
            cid10: "S670",
            descricao: "Lesão por esmagamento do polegar e de outro(s) dedo(s)",
            favorito: false
        },
        {
            cid10: "S678",
            descricao: "Lesão por esmagamento de outras partes e das não especificadas do punho e da mão",
            favorito: false
        },
        {
            cid10: "S680",
            descricao: "Amputação traumática do polegar (completa) (parcial)",
            favorito: false
        },
        {
            cid10: "S681",
            descricao: "Amputação traumática de um outro dedo apenas (completa) (parcial)",
            favorito: false
        },
        {
            cid10: "S682",
            descricao: "Amputação traumática de dois ou mais dedos somente (completa) (parcial)",
            favorito: false
        },
        {
            cid10: "S683",
            descricao: "Amputação traumática combinada de (partes de) dedo(s) associada a outras partes do punho e da mão",
            favorito: false
        },
        {
            cid10: "S684",
            descricao: "Amputação traumática da mão ao nível do punho",
            favorito: false
        },
        {
            cid10: "S688",
            descricao: "Amputação traumática de outras partes do punho e da mão",
            favorito: false
        },
        {
            cid10: "S689",
            descricao: "Amputação traumática do punho e da mão, nível não especificado",
            favorito: false
        },
        {
            cid10: "S697",
            descricao: "Traumatismos múltiplos do punho e da mão",
            favorito: false
        },
        {
            cid10: "S698",
            descricao: "Outros traumatismos especificados do punho e da mão",
            favorito: false
        },
        {
            cid10: "S699",
            descricao: "Traumatismo não especificados do punho e da mão",
            favorito: false
        },
        {
            cid10: "S700",
            descricao: "Contusão do quadril",
            favorito: false
        },
        {
            cid10: "S701",
            descricao: "Contusão da coxa",
            favorito: false
        },
        {
            cid10: "S707",
            descricao: "Traumatismos superficiais múltiplos do quadril e da coxa",
            favorito: false
        },
        {
            cid10: "S708",
            descricao: "Outros traumatismos superficiais do quadril e da coxa",
            favorito: false
        },
        {
            cid10: "S709",
            descricao: "Traumatismo superficial não especificado do quadril e da coxa",
            favorito: false
        },
        {
            cid10: "S710",
            descricao: "Ferimento do quadril",
            favorito: false
        },
        {
            cid10: "S711",
            descricao: "Ferimento da coxa",
            favorito: false
        },
        {
            cid10: "S717",
            descricao: "Ferimentos múltiplos do quadril e da coxa",
            favorito: false
        },
        {
            cid10: "S718",
            descricao: "Ferimentos de outras partes e das não especificadas da cintura pélvica",
            favorito: false
        },
        {
            cid10: "S720",
            descricao: "Fratura do colo do fêmur",
            favorito: false
        },
        {
            cid10: "S721",
            descricao: "Fratura pertrocantérica",
            favorito: false
        },
        {
            cid10: "S722",
            descricao: "Fratura subtrocantérica",
            favorito: false
        },
        {
            cid10: "S723",
            descricao: "Fratura da diáfise do fêmur",
            favorito: false
        },
        {
            cid10: "S724",
            descricao: "Fratura da extremidade distal do fêmur",
            favorito: false
        },
        {
            cid10: "S727",
            descricao: "Fraturas múltiplas do fêmur",
            favorito: false
        },
        {
            cid10: "S728",
            descricao: "Fraturas de outras partes do fêmur",
            favorito: false
        },
        {
            cid10: "S729",
            descricao: "Fratura do fêmur, parte não especificada",
            favorito: false
        },
        {
            cid10: "S730",
            descricao: "Luxação da articulação do quadril",
            favorito: false
        },
        {
            cid10: "S731",
            descricao: "Entorse e distensão do quadril",
            favorito: false
        },
        {
            cid10: "S740",
            descricao: "Traumatismo do nervo ciático ao nível do quadril e da coxa",
            favorito: false
        },
        {
            cid10: "S741",
            descricao: "Traumatismo do nervo femural ao nível do quadril e da coxa",
            favorito: false
        },
        {
            cid10: "S742",
            descricao: "Traumatismo do nervo sensitivo cutâneo ao nível do quadril e da coxa",
            favorito: false
        },
        {
            cid10: "S747",
            descricao: "Traumatismo de nervos múltiplos ao nível do quadril e da coxa",
            favorito: false
        },
        {
            cid10: "S748",
            descricao: "Traumatismo de outros nervos ao nível do quadril e da coxa",
            favorito: false
        },
        {
            cid10: "S749",
            descricao: "Traumatismo de nervo não especificado ao nível do quadril e da coxa",
            favorito: false
        },
        {
            cid10: "S750",
            descricao: "Traumatismo da artéria femural",
            favorito: false
        },
        {
            cid10: "S751",
            descricao: "Traumatismo da veia femural ao nível do quadril e da coxa",
            favorito: false
        },
        {
            cid10: "S752",
            descricao: "Traumatismo da veia grande safena ao nível do quadril e da coxa",
            favorito: false
        },
        {
            cid10: "S757",
            descricao: "Traumatismo de múltiplos vasos sangüíneos ao nível do quadril e da coxa",
            favorito: false
        },
        {
            cid10: "S758",
            descricao: "Traumatismo de outros vaso sangüíneos ao nível do quadril e da coxa",
            favorito: false
        },
        {
            cid10: "S759",
            descricao: "Traumatismo de vaso sangüíneo não especificado ao nível do quadril e da coxa",
            favorito: false
        },
        {
            cid10: "S760",
            descricao: "Traumatismo do músculo e do tendão do quadril",
            favorito: false
        },
        {
            cid10: "S761",
            descricao: "Traumatismo do músculo e do tendão do quadríceps",
            favorito: false
        },
        {
            cid10: "S762",
            descricao: "Traumatismo do músculo e do tendão do adutor da coxa",
            favorito: false
        },
        {
            cid10: "S763",
            descricao: "Traumatismo do músculo e do tendão dos músculos posteriores ao nível da coxa",
            favorito: false
        },
        {
            cid10: "S764",
            descricao: "Traumatismo de outros músculos e tendões e os não especificados ao nível da coxa",
            favorito: false
        },
        {
            cid10: "S767",
            descricao: "Traumatismo de múltiplos músculos e tendões ao nível do quadril e da coxa",
            favorito: false
        },
        {
            cid10: "S770",
            descricao: "Lesão por esmagamento do quadril",
            favorito: false
        },
        {
            cid10: "S771",
            descricao: "Lesão por esmagamento da coxa",
            favorito: false
        },
        {
            cid10: "S772",
            descricao: "Lesão por esmagamento do quadril e da coxa",
            favorito: false
        },
        {
            cid10: "S780",
            descricao: "Amputação traumática na articulação do quadril",
            favorito: false
        },
        {
            cid10: "S781",
            descricao: "Amputação traumática localizada entre o joelho e o quadril",
            favorito: false
        },
        {
            cid10: "S789",
            descricao: "Amputação traumática do quadril e coxa nível não especificado",
            favorito: false
        },
        {
            cid10: "S797",
            descricao: "Traumatismos múltiplos do quadril e da coxa",
            favorito: false
        },
        {
            cid10: "S798",
            descricao: "Outros traumatismos especificados do quadril e da coxa",
            favorito: false
        },
        {
            cid10: "S799",
            descricao: "Traumatismo não especificado do quadril e da coxa",
            favorito: false
        },
        {
            cid10: "S800",
            descricao: "Contusão do joelho",
            favorito: false
        },
        {
            cid10: "S801",
            descricao: "Contusão de outras partes e de partes não especificadas da perna",
            favorito: false
        },
        {
            cid10: "S807",
            descricao: "Traumatismos superficiais múltiplos da perna",
            favorito: false
        },
        {
            cid10: "S808",
            descricao: "Outros traumatismos superficiais da perna",
            favorito: false
        },
        {
            cid10: "S809",
            descricao: "Traumatismo superficial não especificado da perna",
            favorito: false
        },
        {
            cid10: "S810",
            descricao: "Ferimento do joelho",
            favorito: false
        },
        {
            cid10: "S817",
            descricao: "Ferimentos múltiplos da perna",
            favorito: false
        },
        {
            cid10: "S818",
            descricao: "Ferimento de outras partes da perna",
            favorito: false
        },
        {
            cid10: "S819",
            descricao: "Ferimento da perna, parte não especificada",
            favorito: false
        },
        {
            cid10: "S820",
            descricao: "Fratura da rótula [patela]",
            favorito: false
        },
        {
            cid10: "S821",
            descricao: "Fratura da extremidade proximal da tíbia",
            favorito: false
        },
        {
            cid10: "S822",
            descricao: "Fratura da diáfise da tíbia",
            favorito: false
        },
        {
            cid10: "S823",
            descricao: "Fratura da extremidade distal da tíbia",
            favorito: false
        },
        {
            cid10: "S824",
            descricao: "Fratura do perônio [fíbula]",
            favorito: false
        },
        {
            cid10: "S825",
            descricao: "Fratura do maléolo medial",
            favorito: false
        },
        {
            cid10: "S826",
            descricao: "Fratura do maléolo lateral",
            favorito: false
        },
        {
            cid10: "S827",
            descricao: "Fraturas múltiplas da perna",
            favorito: false
        },
        {
            cid10: "S828",
            descricao: "Fratura de outras partes da perna",
            favorito: false
        },
        {
            cid10: "S829",
            descricao: "Fratura da perna, parte não especificada",
            favorito: false
        },
        {
            cid10: "S830",
            descricao: "Luxação da rótula [patela]",
            favorito: false
        },
        {
            cid10: "S831",
            descricao: "Luxação do joelho",
            favorito: false
        },
        {
            cid10: "S832",
            descricao: "Ruptura do menisco, atual",
            favorito: false
        },
        {
            cid10: "S833",
            descricao: "Ruptura atual da cartilagem da articulação do joelho",
            favorito: false
        },
        {
            cid10: "S834",
            descricao: "Entorse e distensão envolvendo ligamento colateral (peronial) (tibial) do joelho",
            favorito: false
        },
        {
            cid10: "S835",
            descricao: "Entorse e distensão envolvendo ligamento cruzado (anterior) (posterior) do joelho",
            favorito: false
        },
        {
            cid10: "S836",
            descricao: "Entorse e distensão de outras partes e das não especificadas do joelho",
            favorito: false
        },
        {
            cid10: "S837",
            descricao: "Traumatismo de estruturas múltiplas do joelho",
            favorito: false
        },
        {
            cid10: "S840",
            descricao: "Traumatismo do nervo tibial ao nível da perna",
            favorito: false
        },
        {
            cid10: "S841",
            descricao: "Traumatismo do nervo peronial ao nível da perna",
            favorito: false
        },
        {
            cid10: "S842",
            descricao: "Traumatismo do nervo cutâneo sensitivo ao nível da perna",
            favorito: false
        },
        {
            cid10: "S847",
            descricao: "Traumatismo de múltiplos nervos ao nível da perna",
            favorito: false
        },
        {
            cid10: "S848",
            descricao: "Traumatismo de outros nervos ao nível da perna",
            favorito: false
        },
        {
            cid10: "S849",
            descricao: "Traumatismo de nervo não especificado ao nível da perna",
            favorito: false
        },
        {
            cid10: "S850",
            descricao: "Traumatismo da artéria poplítea",
            favorito: false
        },
        {
            cid10: "S851",
            descricao: "Traumatismo da artéria tibial (anterior) (posterior)",
            favorito: false
        },
        {
            cid10: "S852",
            descricao: "Traumatismo da artéria peronial",
            favorito: false
        },
        {
            cid10: "S853",
            descricao: "Traumatismo da veia grande safena ao nível da perna",
            favorito: false
        },
        {
            cid10: "S854",
            descricao: "Traumatismo da veia safena menor ao nível da perna",
            favorito: false
        },
        {
            cid10: "S855",
            descricao: "Traumatismo da veia poplítea",
            favorito: false
        },
        {
            cid10: "S857",
            descricao: "Traumatismo de múltiplos vasos sangüíneos ao nível da perna",
            favorito: false
        },
        {
            cid10: "S858",
            descricao: "Traumatismo de outros vasos sangüíneos ao nível da perna",
            favorito: false
        },
        {
            cid10: "S859",
            descricao: "Traumatismo de vasos sangüíneos não especificados ao nível da perna",
            favorito: false
        },
        {
            cid10: "S860",
            descricao: "Traumatismo do tendão de Aquiles",
            favorito: false
        },
        {
            cid10: "S861",
            descricao: "Traumatismo de outro(s) músculo(s) e tendão(ões) do grupo muscular posterior ao nível da perna",
            favorito: false
        },
        {
            cid10: "S862",
            descricao: "Traumatismo do(s) músculo(s) e tendão(ões) do grupo muscular anterior ao nível da perna",
            favorito: false
        },
        {
            cid10: "S863",
            descricao: "Traumatismo do(s) músculo(s) e tendão(ões) do grupo muscular peronial ao nível da perna",
            favorito: false
        },
        {
            cid10: "S867",
            descricao: "Traumatismo de múltiplos músculos e tendões ao nível da perna",
            favorito: false
        },
        {
            cid10: "S868",
            descricao: "Traumatismos de outros músculos e tendões ao nível da perna",
            favorito: false
        },
        {
            cid10: "S869",
            descricao: "Traumatismo de músculo e de tendão não especificado ao nível da perna",
            favorito: false
        },
        {
            cid10: "S870",
            descricao: "Traumatismo por esmagamento do joelho",
            favorito: false
        },
        {
            cid10: "S878",
            descricao: "Traumatismo por esmagamento de outra parte e de partes não especificadas da perna",
            favorito: false
        },
        {
            cid10: "S880",
            descricao: "Amputação traumática ao nível do joelho",
            favorito: false
        },
        {
            cid10: "S881",
            descricao: "Amputação traumática entre o joelho e o tornozelo",
            favorito: false
        },
        {
            cid10: "S889",
            descricao: "Amputação traumática da perna ao nível não especificado",
            favorito: false
        },
        {
            cid10: "S890",
            descricao: "Traumatismos múltiplos da perna",
            favorito: false
        },
        {
            cid10: "S898",
            descricao: "Outros traumatismos especificados da perna",
            favorito: false
        },
        {
            cid10: "S899",
            descricao: "Traumatismo não especificado da perna",
            favorito: false
        },
        {
            cid10: "S900",
            descricao: "Contusão do tornozelo",
            favorito: false
        },
        {
            cid10: "S901",
            descricao: "Contusão de artelho sem lesão da unha",
            favorito: false
        },
        {
            cid10: "S902",
            descricao: "Contusão de artelho(s) com lesão da unha",
            favorito: false
        },
        {
            cid10: "S903",
            descricao: "Contusão de outras partes e partes não especificadas do pé",
            favorito: false
        },
        {
            cid10: "S907",
            descricao: "Traumatismos superficiais múltiplos do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "S908",
            descricao: "Outros traumatismos superficiais do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "S909",
            descricao: "Traumatismo superficial do tornozelo e do pé, não especificado",
            favorito: false
        },
        {
            cid10: "S910",
            descricao: "Ferimento do tornozelo",
            favorito: false
        },
        {
            cid10: "S911",
            descricao: "Ferimento do(s) artelho(s) sem lesão da unha",
            favorito: false
        },
        {
            cid10: "S912",
            descricao: "Ferimento do(s) artelho(s) com lesão da unha",
            favorito: false
        },
        {
            cid10: "S913",
            descricao: "Ferimento de outras partes do pé",
            favorito: false
        },
        {
            cid10: "S917",
            descricao: "Ferimentos múltiplos do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "S920",
            descricao: "Fratura do calcâneo",
            favorito: false
        },
        {
            cid10: "S921",
            descricao: "Fratura do astrágalo",
            favorito: false
        },
        {
            cid10: "S922",
            descricao: "Fratura de outros ossos do tarso",
            favorito: false
        },
        {
            cid10: "S923",
            descricao: "Fratura de ossos do metatarso",
            favorito: false
        },
        {
            cid10: "S924",
            descricao: "Fratura do hálux",
            favorito: false
        },
        {
            cid10: "S925",
            descricao: "Fratura de outro artelho",
            favorito: false
        },
        {
            cid10: "S927",
            descricao: "Fraturas múltiplas do pé",
            favorito: false
        },
        {
            cid10: "S929",
            descricao: "Fratura do pé não especificada",
            favorito: false
        },
        {
            cid10: "S930",
            descricao: "Luxação da articulação do tornozelo",
            favorito: false
        },
        {
            cid10: "S931",
            descricao: "Luxação do(s) artelho(s)",
            favorito: false
        },
        {
            cid10: "S932",
            descricao: "Ruptura de ligamentos ao nível do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "S933",
            descricao: "Luxação de outras partes e das não especificadas do pé",
            favorito: false
        },
        {
            cid10: "S934",
            descricao: "Entorse e distensão do tornozelo",
            favorito: false
        },
        {
            cid10: "S935",
            descricao: "Entorse e distensão do(s) artelho(s)",
            favorito: false
        },
        {
            cid10: "S936",
            descricao: "Entorse e distensão de outras partes e de partes não especificadas do pé",
            favorito: false
        },
        {
            cid10: "S940",
            descricao: "Traumatismo do nervo plantar externo (lateral)",
            favorito: false
        },
        {
            cid10: "S941",
            descricao: "Traumatismo do nervo plantar interno (medial)",
            favorito: false
        },
        {
            cid10: "S942",
            descricao: "Traumatismo nervo peronial [fibular] profundo ao nível do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "S943",
            descricao: "Traumatismo do nervo sensitivo cutâneo ao nível do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "S947",
            descricao: "Traumatismo de múltiplos nervos ao nível do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "S948",
            descricao: "Traumatismo de outros nervos ao nível do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "S949",
            descricao: "Traumatismo de nervo não especificado, ao nível do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "S950",
            descricao: "Traumatismo da artéria dorsal do pé",
            favorito: false
        },
        {
            cid10: "S951",
            descricao: "Traumatismo da artéria plantar do pé",
            favorito: false
        },
        {
            cid10: "S952",
            descricao: "Traumatismo da veia dorsal do pé",
            favorito: false
        },
        {
            cid10: "S957",
            descricao: "Traumatismo de múltiplos vasos sangüíneos ao nível do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "S958",
            descricao: "Traumatismo de outros vasos sangüíneos ao nível do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "S959",
            descricao: "Traumatismo de vaso sangüíneo não especificado ao nível do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "S960",
            descricao: "Traumatismo do músculo e tendão do músculo flexor longo do(s) artelho(s) ao nível do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "S961",
            descricao: "Traumatismo do músculo e tendão do extensor longo do(s) artelho(s) ao nível do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "S962",
            descricao: "Traumatismo de músculo intrínseco e tendão ao nível do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "S967",
            descricao: "Traumatismo de múltiplos músculos e tendões ao nível do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "S968",
            descricao: "Traumatismo de outros tendões e músculos ao nível do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "S969",
            descricao: "Traumatismo de músculo e tendão não especificados do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "S970",
            descricao: "Lesão por esmagamento do tornozelo",
            favorito: false
        },
        {
            cid10: "S971",
            descricao: "Lesão por esmagamento do(s) artelho(s)",
            favorito: false
        },
        {
            cid10: "S978",
            descricao: "Lesão por esmagamento de outras partes do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "S980",
            descricao: "Amputação traumática do pé ao nível do tornozelo",
            favorito: false
        },
        {
            cid10: "S981",
            descricao: "Amputação traumática de apenas um artelho",
            favorito: false
        },
        {
            cid10: "S982",
            descricao: "Amputação traumática de dois ou mais artelhos",
            favorito: false
        },
        {
            cid10: "S983",
            descricao: "Amputação traumática de outras partes do pé",
            favorito: false
        },
        {
            cid10: "S984",
            descricao: "Amputação traumática do pé ao nível não especificado",
            favorito: false
        },
        {
            cid10: "S997",
            descricao: "Traumatismos múltiplos do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "S998",
            descricao: "Outros traumatismos especificados do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "S999",
            descricao: "Traumatismos não especificados do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "T000",
            descricao: "Traumatismos superficiais envolvendo a cabeça com o pescoço",
            favorito: false
        },
        {
            cid10: "T001",
            descricao: "Traumatismos superficiais envolvendo o tórax com o abdome, parte inferior do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "T002",
            descricao: "Traumatismos superficiais envolvendo regiões múltiplas do(s) membro(s) superior(es)",
            favorito: false
        },
        {
            cid10: "T003",
            descricao: "Traumatismos superficiais envolvendo regiões múltiplas do(s) membro(s) inferior(es)",
            favorito: false
        },
        {
            cid10: "T006",
            descricao: "Traumatismos superficiais envolvendo regiões múltiplas dos membros superiores com membro(s) inferiores",
            favorito: false
        },
        {
            cid10: "T008",
            descricao: "Traumatismos superficiais envolvendo outras combinações de regiões do corpo",
            favorito: false
        },
        {
            cid10: "T009",
            descricao: "Traumatismos superficiais múltiplos não especificados",
            favorito: false
        },
        {
            cid10: "T010",
            descricao: "Ferimentos envolvendo a cabeça com o pescoço",
            favorito: false
        },
        {
            cid10: "T011",
            descricao: "Ferimentos envolvendo o tórax com o abdome, parte inferior do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "T012",
            descricao: "Ferimentos envolvendo regiões múltiplas do(s) membro(s) superior(es)",
            favorito: false
        },
        {
            cid10: "T013",
            descricao: "Ferimentos envolvendo múltiplas regiões do(s) membro(s) inferior(es)",
            favorito: false
        },
        {
            cid10: "T016",
            descricao: "Ferimentos envolvendo regiões múltiplas do(s) membro(s) superior(es) com membro(s) inferior(es)",
            favorito: false
        },
        {
            cid10: "T018",
            descricao: "Ferimentos envolvendo outras combinações de regiões do corpo",
            favorito: false
        },
        {
            cid10: "T019",
            descricao: "Ferimentos múltiplos não especificados",
            favorito: false
        },
        {
            cid10: "T020",
            descricao: "Fraturas envolvendo cabeça com pescoço",
            favorito: false
        },
        {
            cid10: "T021",
            descricao: "Fraturas envolvendo tórax com parte inferior do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "T022",
            descricao: "Fraturas envolvendo regiões múltiplas de um membro superior",
            favorito: false
        },
        {
            cid10: "T023",
            descricao: "Fraturas envolvendo regiões múltiplas de um membro inferior",
            favorito: false
        },
        {
            cid10: "T024",
            descricao: "Fraturas envolvendo regiões múltiplas de ambos os membros superiores",
            favorito: false
        },
        {
            cid10: "T025",
            descricao: "Fraturas envolvendo regiões múltiplas de ambos os membros inferiores",
            favorito: false
        },
        {
            cid10: "T026",
            descricao: "Fraturas envolvendo regiões múltiplas do(s) membro(s) superior(es) com inferior(es)",
            favorito: false
        },
        {
            cid10: "T027",
            descricao: "Fraturas envolvendo tórax com parte inferior do dorso e pelve com membro(s)",
            favorito: false
        },
        {
            cid10: "T028",
            descricao: "Fraturas envolvendo outras combinações de regiões do corpo",
            favorito: false
        },
        {
            cid10: "T029",
            descricao: "Fraturas múltiplas não especificadas",
            favorito: false
        },
        {
            cid10: "T030",
            descricao: "Luxações, entorses e distensões envolvendo a cabeça com o pescoço",
            favorito: false
        },
        {
            cid10: "T031",
            descricao: "Luxações, entorses e distensões envolvendo tórax com parte inferior do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "T032",
            descricao: "Luxações, entorses e distensões envolvendo regiões múltiplas de membro(s) superior(es)",
            favorito: false
        },
        {
            cid10: "T033",
            descricao: "Luxações, entorses e distensões envolvendo regiões múltiplas do(s) membro(s) inferior(es)",
            favorito: false
        },
        {
            cid10: "T034",
            descricao: "Luxações, entorses e distensões envolvendo regiões múltiplas dos membros superiores com inferiores",
            favorito: false
        },
        {
            cid10: "T038",
            descricao: "Luxações, entorses e distensões envolvendo outras combinações de regiões do corpo",
            favorito: false
        },
        {
            cid10: "T039",
            descricao: "Luxações, entorses e distensões múltiplas, não especificadas",
            favorito: false
        },
        {
            cid10: "T040",
            descricao: "Traumatismos por esmagamento envolvendo a cabeça com o pescoço",
            favorito: false
        },
        {
            cid10: "T041",
            descricao: "Traumatismos por esmagamento envolvendo tórax com o abdome, parte inferior do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "T042",
            descricao: "Traumatismos por esmagamento envolvendo regiões múltiplas do(s) membro(s) superior(es)",
            favorito: false
        },
        {
            cid10: "T043",
            descricao: "Traumatismos por esmagamento envolvendo regiões múltiplas do(s) membro(s) inferior(es)",
            favorito: false
        },
        {
            cid10: "T044",
            descricao: "Traumatismos por esmagamento envolvendo regiões múltiplas do(s) membro(s) superior(es) com inferior(es)",
            favorito: false
        },
        {
            cid10: "T047",
            descricao: "Traumatismo por esmagamento do tórax com abdome, parte inferior do dorso, pelve e membro(s)",
            favorito: false
        },
        {
            cid10: "T048",
            descricao: "Traumatismos por esmagamento envolvendo outras combinações de regiões do corpo",
            favorito: false
        },
        {
            cid10: "T049",
            descricao: "Traumatismo múltiplos por esmagamento não especificados",
            favorito: false
        },
        {
            cid10: "T050",
            descricao: "Amputação traumática de ambas as mãos",
            favorito: false
        },
        {
            cid10: "T051",
            descricao: "Amputação traumática de uma mão e de um outro braço [qualquer nível, exceto mão]",
            favorito: false
        },
        {
            cid10: "T052",
            descricao: "Amputação traumática de ambos os braços [qualquer nível]",
            favorito: false
        },
        {
            cid10: "T053",
            descricao: "Amputação traumática de ambos os pés",
            favorito: false
        },
        {
            cid10: "T054",
            descricao: "Amputação traumática de um pé e outra perna [qualquer nível, exceto pé]",
            favorito: false
        },
        {
            cid10: "T055",
            descricao: "Amputação traumática de ambas as pernas [qualquer nível]",
            favorito: false
        },
        {
            cid10: "T056",
            descricao: "Amputação traumática de membros superiores e inferiores, qualquer combinação [qualquer nível]",
            favorito: false
        },
        {
            cid10: "T058",
            descricao: "Amputações traumáticas envolvendo outras combinações de regiões do corpo",
            favorito: false
        },
        {
            cid10: "T059",
            descricao: "Amputações traumáticas múltiplas não especificadas",
            favorito: false
        },
        {
            cid10: "T060",
            descricao: "Traumatismos de cérebro e nervos cranianos com traumatismos de nervos e da medula espinhal ao nível do pescoço",
            favorito: false
        },
        {
            cid10: "T061",
            descricao: "Traumatismos de nervos e da medula espinhal envolvendo outras regiões múltiplas do corpo",
            favorito: false
        },
        {
            cid10: "T062",
            descricao: "Traumatismos de nervos envolvendo regiões múltiplas do corpo",
            favorito: false
        },
        {
            cid10: "T063",
            descricao: "Traumatismos de vasos sangüíneos envolvendo regiões múltiplas do corpo",
            favorito: false
        },
        {
            cid10: "T064",
            descricao: "Traumatismos de músculos e de tendões envolvendo regiões múltiplas do corpo",
            favorito: false
        },
        {
            cid10: "T065",
            descricao: "Traumatismos de órgãos intratorácicos com órgãos intra-abdominais e pélvicos",
            favorito: false
        },
        {
            cid10: "T068",
            descricao: "Outros traumatismos especificados envolvendo regiões múltiplas do corpo",
            favorito: false
        },
        {
            cid10: "T07",
            descricao: "Traumatismos múltiplos não especificados",
            favorito: false
        },
        {
            cid10: "T08",
            descricao: "Fratura da coluna, nível não especificado",
            favorito: false
        },
        {
            cid10: "T090",
            descricao: "Traumatismo superficial do tronco, nível não especificado",
            favorito: false
        },
        {
            cid10: "T091",
            descricao: "Ferimento do tronco, nível não especificado",
            favorito: false
        },
        {
            cid10: "T092",
            descricao: "Luxação, entorse e distensão de articulações e ligamentos não especificados do tronco",
            favorito: false
        },
        {
            cid10: "T093",
            descricao: "Traumatismo de medula espinhal, nível não especificado",
            favorito: false
        },
        {
            cid10: "T094",
            descricao: "Traumatismo de nervos, de raízes de nervos espinhais e de plexos não especificados do tronco",
            favorito: false
        },
        {
            cid10: "T095",
            descricao: "Traumatismo de músculo e tendão não especificados do tronco",
            favorito: false
        },
        {
            cid10: "T096",
            descricao: "Amputação traumática do tronco, nível não especificado",
            favorito: false
        },
        {
            cid10: "T098",
            descricao: "Outros traumatismos especificados do tronco, nível não especificado",
            favorito: false
        },
        {
            cid10: "T099",
            descricao: "Traumatismo não especificado do tronco, nível não especificado",
            favorito: false
        },
        {
            cid10: "T10",
            descricao: "Fratura do membro superior, nível não especificado",
            favorito: false
        },
        {
            cid10: "T110",
            descricao: "Traumatismo superficial do membro superior, nível não especificado",
            favorito: false
        },
        {
            cid10: "T111",
            descricao: "Ferimento do membro superior, nível não especificado",
            favorito: false
        },
        {
            cid10: "T112",
            descricao: "Luxação, entorse e distensão de articulação e ligamento não especificados do membro superior, nível não especificado",
            favorito: false
        },
        {
            cid10: "T113",
            descricao: "Traumatismo de nervo não especificado do membro superior, nível não especificado",
            favorito: false
        },
        {
            cid10: "T114",
            descricao: "Traumatismo de vaso sangüíneo não especificado, do membro superior, nível não especificado",
            favorito: false
        },
        {
            cid10: "T115",
            descricao: "Traumatismo de músculo e tendão não especificado do membro superior, nível não especificado",
            favorito: false
        },
        {
            cid10: "T116",
            descricao: "Amputação traumática do membro superior, nível não especificado",
            favorito: false
        },
        {
            cid10: "T118",
            descricao: "Outros traumatismos especificados do membro superior nível não especificado",
            favorito: false
        },
        {
            cid10: "T119",
            descricao: "Traumatismo não especificado do membro superior nível não especificado",
            favorito: false
        },
        {
            cid10: "T12",
            descricao: "Fratura do membro inferior, nível não especificado",
            favorito: false
        },
        {
            cid10: "T130",
            descricao: "Traumatismo superficial de membro inferior, nível não especificado",
            favorito: false
        },
        {
            cid10: "T131",
            descricao: "Ferimento de membro inferior, nível não especificado",
            favorito: false
        },
        {
            cid10: "T132",
            descricao: "Luxação, entorse e distensão de articulação e ligamento não especificados de membro inferior, nível não especificado",
            favorito: false
        },
        {
            cid10: "T133",
            descricao: "Traumatismo de nervo não especificado de membro inferior, nível não especificado",
            favorito: false
        },
        {
            cid10: "T134",
            descricao: "Traumatismo de vaso sangüíneo não especificado de membro inferior, nível não especificado",
            favorito: false
        },
        {
            cid10: "T135",
            descricao: "Traumatismo de músculo e tendão não especificado de membro inferior, nível não especificado",
            favorito: false
        },
        {
            cid10: "T136",
            descricao: "Amputação traumática de membro inferior, nível não especificado",
            favorito: false
        },
        {
            cid10: "T138",
            descricao: "Outros traumatismos especificados de membro inferior, nível não especificado",
            favorito: false
        },
        {
            cid10: "T139",
            descricao: "Traumatismo não especificado do membro inferior, nível não especificado",
            favorito: false
        },
        {
            cid10: "T140",
            descricao: "Traumatismo superficial de região não especificada do corpo",
            favorito: false
        },
        {
            cid10: "T141",
            descricao: "Ferimento de região não especificada do corpo",
            favorito: false
        },
        {
            cid10: "T142",
            descricao: "Fratura de região não especificada do corpo",
            favorito: false
        },
        {
            cid10: "T143",
            descricao: "Luxação, entorse e distensão de região não especificada do corpo",
            favorito: false
        },
        {
            cid10: "T144",
            descricao: "Traumatismo de nervo(s) de região não especificada do corpo",
            favorito: false
        },
        {
            cid10: "T145",
            descricao: "Traumatismo de vaso(s) sangüíneo(s) de região não especificada do corpo",
            favorito: false
        },
        {
            cid10: "T146",
            descricao: "Traumatismo de músculos e tendões de região não especificada do corpo",
            favorito: false
        },
        {
            cid10: "T147",
            descricao: "Traumatismo por esmagamento e amputação traumática de regiões não especificadas do corpo",
            favorito: false
        },
        {
            cid10: "T148",
            descricao: "Outros traumatismos de região não especificada do corpo",
            favorito: false
        },
        {
            cid10: "T149",
            descricao: "Traumatismo não especificado",
            favorito: false
        },
        {
            cid10: "T150",
            descricao: "Corpo estranho na córnea",
            favorito: false
        },
        {
            cid10: "T151",
            descricao: "Corpo estranho no saco conjuntival",
            favorito: false
        },
        {
            cid10: "T158",
            descricao: "Corpo estranho em outros locais e em locais múltiplos da parte externa do olho",
            favorito: false
        },
        {
            cid10: "T159",
            descricao: "Corpo estranho em parte não especificada da região externa do olho",
            favorito: false
        },
        {
            cid10: "T16",
            descricao: "Corpo estranho no ouvido",
            favorito: false
        },
        {
            cid10: "T170",
            descricao: "Corpo estranho no seio nasal",
            favorito: false
        },
        {
            cid10: "T171",
            descricao: "Corpo estranho na narina",
            favorito: false
        },
        {
            cid10: "T172",
            descricao: "Corpo estranho na faringe",
            favorito: false
        },
        {
            cid10: "T173",
            descricao: "Corpo estranho na laringe",
            favorito: false
        },
        {
            cid10: "T174",
            descricao: "Corpo estranho na traquéia",
            favorito: false
        },
        {
            cid10: "T175",
            descricao: "Corpo estranho no brônquio",
            favorito: false
        },
        {
            cid10: "T178",
            descricao: "Corpo estranho em outras partes e partes múltiplas do trato respiratório",
            favorito: false
        },
        {
            cid10: "T179",
            descricao: "Corpo estranho no trato respiratório, parte não especificada",
            favorito: false
        },
        {
            cid10: "T180",
            descricao: "Corpo estranho na boca",
            favorito: false
        },
        {
            cid10: "T181",
            descricao: "Corpo estranho no esôfago",
            favorito: false
        },
        {
            cid10: "T182",
            descricao: "Corpo estranho no estômago",
            favorito: false
        },
        {
            cid10: "T183",
            descricao: "Corpo estranho no intestino delgado",
            favorito: false
        },
        {
            cid10: "T184",
            descricao: "Corpo estranho no cólon",
            favorito: false
        },
        {
            cid10: "T185",
            descricao: "Corpo estranho no ânus e reto",
            favorito: false
        },
        {
            cid10: "T188",
            descricao: "Corpo estranho em outras partes e partes múltiplas do aparelho digestivo",
            favorito: false
        },
        {
            cid10: "T189",
            descricao: "Corpo estranho em parte não especificada do aparelho digestivo",
            favorito: false
        },
        {
            cid10: "T190",
            descricao: "Corpo estranho na uretra",
            favorito: false
        },
        {
            cid10: "T191",
            descricao: "Corpo estranho na bexiga",
            favorito: false
        },
        {
            cid10: "T192",
            descricao: "Corpo estranho na vulva e vagina",
            favorito: false
        },
        {
            cid10: "T193",
            descricao: "Corpo estranho no útero [qualquer parte]",
            favorito: false
        },
        {
            cid10: "T198",
            descricao: "Corpo estranho em outras partes e partes múltiplas do trato geniturinário",
            favorito: false
        },
        {
            cid10: "T199",
            descricao: "Corpo estranho no trato geniturinário, parte não especificada",
            favorito: false
        },
        {
            cid10: "T200",
            descricao: "Queimadura da cabeça e do pescoço, grau não especificado",
            favorito: false
        },
        {
            cid10: "T201",
            descricao: "Queimadura de primeiro grau da cabeça e do pescoço",
            favorito: false
        },
        {
            cid10: "T202",
            descricao: "Queimadura de segundo grau da cabeça e do pescoço",
            favorito: false
        },
        {
            cid10: "T203",
            descricao: "Queimadura de terceiro grau da cabeça e do pescoço",
            favorito: false
        },
        {
            cid10: "T204",
            descricao: "Corrosão da cabeça e do pescoço, grau não especificado",
            favorito: false
        },
        {
            cid10: "T205",
            descricao: "Corrosão de primeiro grau da cabeça e do pescoço",
            favorito: false
        },
        {
            cid10: "T206",
            descricao: "Corrosão de segundo grau da cabeça e do pescoço",
            favorito: false
        },
        {
            cid10: "T207",
            descricao: "Corrosão de terceiro grau da cabeça e do pescoço",
            favorito: false
        },
        {
            cid10: "T210",
            descricao: "Queimadura do tronco, grau não especificado",
            favorito: false
        },
        {
            cid10: "T211",
            descricao: "Queimadura de primeiro grau do tronco",
            favorito: false
        },
        {
            cid10: "T212",
            descricao: "Queimadura de segundo grau do tronco",
            favorito: false
        },
        {
            cid10: "T213",
            descricao: "Queimadura de terceiro grau do tronco",
            favorito: false
        },
        {
            cid10: "T214",
            descricao: "Corrosão do tronco, grau não especificado",
            favorito: false
        },
        {
            cid10: "T215",
            descricao: "Corrosão de primeiro grau do tronco",
            favorito: false
        },
        {
            cid10: "T216",
            descricao: "Corrosão de segundo grau do tronco",
            favorito: false
        },
        {
            cid10: "T217",
            descricao: "Corrosão de terceiro grau do tronco",
            favorito: false
        },
        {
            cid10: "T220",
            descricao: "Queimadura do ombro e do membro superior, exceto punho e mão, grau não especificado",
            favorito: false
        },
        {
            cid10: "T221",
            descricao: "Queimadura de primeiro grau do ombro e do membro superior, exceto punho e mão",
            favorito: false
        },
        {
            cid10: "T222",
            descricao: "Queimadura de segundo grau do ombro e do membro superior, exceto punho e mão",
            favorito: false
        },
        {
            cid10: "T223",
            descricao: "Queimadura de terceiro grau do ombro e do membro superior, exceto punho e mão",
            favorito: false
        },
        {
            cid10: "T224",
            descricao: "Corrosão do ombro e do membro superior, exceto punho e mão, grau não especificado",
            favorito: false
        },
        {
            cid10: "T225",
            descricao: "Corrosão de primeiro grau do ombro e do membro superior, exceto punho e mão",
            favorito: false
        },
        {
            cid10: "T226",
            descricao: "Corrosão de segundo grau do ombro e do membro superior, exceto punho e mão",
            favorito: false
        },
        {
            cid10: "T227",
            descricao: "Corrosão de terceiro grau do ombro e do membro superior, exceto punho e mão",
            favorito: false
        },
        {
            cid10: "T230",
            descricao: "Queimadura do punho e da mão, grau não especificado",
            favorito: false
        },
        {
            cid10: "T231",
            descricao: "Queimadura de primeiro grau do punho e da mão",
            favorito: false
        },
        {
            cid10: "T232",
            descricao: "Queimadura de segundo grau do punho e da mão",
            favorito: false
        },
        {
            cid10: "T233",
            descricao: "Queimadura de terceiro grau do punho e da mão",
            favorito: false
        },
        {
            cid10: "T234",
            descricao: "Corrosão do punho e da mão, grau não especificado",
            favorito: false
        },
        {
            cid10: "T235",
            descricao: "Corrosão de primeiro grau do punho e da mão",
            favorito: false
        },
        {
            cid10: "T236",
            descricao: "Corrosão de segundo grau do punho e da mão",
            favorito: false
        },
        {
            cid10: "T237",
            descricao: "Corrosão de terceiro grau do punho e da mão",
            favorito: false
        },
        {
            cid10: "T240",
            descricao: "Queimadura do quadril e membro inferior, exceto tornozelo e do pé, grau não especificado",
            favorito: false
        },
        {
            cid10: "T241",
            descricao: "Queimadura de primeiro grau do quadril e do membro inferior, exceto tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "T242",
            descricao: "Queimadura de segundo grau do quadril e do membro inferior, exceto tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "T243",
            descricao: "Queimadura de terceiro grau do quadril e do membro inferior, exceto tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "T244",
            descricao: "Corrosão do quadril e do membro inferior, exceto tornozelo e do pé, grau não especificado",
            favorito: false
        },
        {
            cid10: "T245",
            descricao: "Corrosão de primeiro grau do quadril e do membro inferior, exceto tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "T246",
            descricao: "Corrosão de segundo grau do quadril e do membro inferior, exceto tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "T247",
            descricao: "Corrosão de terceiro grau do quadril e do membro inferior, exceto tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "T250",
            descricao: "Queimadura do tornozelo e do pé, grau não especificado",
            favorito: false
        },
        {
            cid10: "T251",
            descricao: "Queimadura de primeiro grau do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "T252",
            descricao: "Queimadura de segundo grau do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "T253",
            descricao: "Queimadura de terceiro grau do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "T254",
            descricao: "Corrosão do tornozelo e do pé, grau não especificado",
            favorito: false
        },
        {
            cid10: "T255",
            descricao: "Corrosão de primeiro grau do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "T256",
            descricao: "Corrosão de segundo grau do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "T257",
            descricao: "Corrosão de terceiro grau do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "T260",
            descricao: "Queimadura da pálpebra e da região periocular",
            favorito: false
        },
        {
            cid10: "T261",
            descricao: "Queimadura da córnea e do saco conjuntival",
            favorito: false
        },
        {
            cid10: "T262",
            descricao: "Queimadura com resultante ruptura e destruição do globo ocular",
            favorito: false
        },
        {
            cid10: "T263",
            descricao: "Queimadura de outras partes do olho e anexos",
            favorito: false
        },
        {
            cid10: "T264",
            descricao: "Queimadura do olho e anexos, parte não especificada",
            favorito: false
        },
        {
            cid10: "T265",
            descricao: "Corrosão da pálpebra e da região periocular",
            favorito: false
        },
        {
            cid10: "T266",
            descricao: "Corrosão da córnea e do saco conjuntival",
            favorito: false
        },
        {
            cid10: "T267",
            descricao: "Corrosão com resultante ruptura e destruição do globo ocular",
            favorito: false
        },
        {
            cid10: "T268",
            descricao: "Corrosão de outras partes do olho e anexos",
            favorito: false
        },
        {
            cid10: "T269",
            descricao: "Corrosão do olho e anexos, parte não especificada",
            favorito: false
        },
        {
            cid10: "T270",
            descricao: "Queimadura da laringe e traquéia",
            favorito: false
        },
        {
            cid10: "T271",
            descricao: "Queimadura da laringe, traquéia com pulmão",
            favorito: false
        },
        {
            cid10: "T272",
            descricao: "Queimadura de outras partes do trato respiratório",
            favorito: false
        },
        {
            cid10: "T273",
            descricao: "Queimadura do trato respiratório, parte não especificada",
            favorito: false
        },
        {
            cid10: "T274",
            descricao: "Corrosão da laringe e traquéia",
            favorito: false
        },
        {
            cid10: "T275",
            descricao: "Corrosão da laringe, traquéia com corrosão de pulmão",
            favorito: false
        },
        {
            cid10: "T276",
            descricao: "Corrosão de outras partes do trato respiratório",
            favorito: false
        },
        {
            cid10: "T277",
            descricao: "Corrosão do trato respiratório, parte não especificada",
            favorito: false
        },
        {
            cid10: "T280",
            descricao: "Queimadura da boca e da faringe",
            favorito: false
        },
        {
            cid10: "T281",
            descricao: "Queimadura do esôfago",
            favorito: false
        },
        {
            cid10: "T282",
            descricao: "Queimadura de outras partes do trato alimentar",
            favorito: false
        },
        {
            cid10: "T283",
            descricao: "Queimadura dos órgãos geniturinários internos",
            favorito: false
        },
        {
            cid10: "T284",
            descricao: "Queimadura de outros órgãos internos e dos não especificados",
            favorito: false
        },
        {
            cid10: "T285",
            descricao: "Corrosão da boca e faringe",
            favorito: false
        },
        {
            cid10: "T286",
            descricao: "Corrosão do esôfago",
            favorito: false
        },
        {
            cid10: "T287",
            descricao: "Corrosão de outras partes do trato alimentar",
            favorito: false
        },
        {
            cid10: "T288",
            descricao: "Corrosão de órgãos geniturinários internos",
            favorito: false
        },
        {
            cid10: "T289",
            descricao: "Corrosão de outros órgãos internos e dos não especificados",
            favorito: false
        },
        {
            cid10: "T290",
            descricao: "Queimaduras múltiplas, grau não especificado",
            favorito: false
        },
        {
            cid10: "T291",
            descricao: "Queimaduras múltiplas, sem mencionar queimadura(s) ultrapassando o primeiro grau",
            favorito: false
        },
        {
            cid10: "T292",
            descricao: "Queimaduras múltiplas, sem mencionar queimadura(s) ultrapassando o segundo grau",
            favorito: false
        },
        {
            cid10: "T293",
            descricao: "Queimaduras múltiplas, mencionando ao menos uma queimadura de terceiro grau",
            favorito: false
        },
        {
            cid10: "T294",
            descricao: "Corrosões múltiplas, grau não especificado",
            favorito: false
        },
        {
            cid10: "T295",
            descricao: "Corrosões múltiplas, sem mencionar corrosão(ões) ultrapassando o primeiro grau",
            favorito: false
        },
        {
            cid10: "T296",
            descricao: "Corrosões múltiplas, sem mencionar corrosão(ões) ultrapassando o segundo grau",
            favorito: false
        },
        {
            cid10: "T297",
            descricao: "Corrosões múltiplas, mencionado ao menos uma corrosão de terceiro grau",
            favorito: false
        },
        {
            cid10: "T300",
            descricao: "Queimadura, parte do corpo não especificada, grau não especificado",
            favorito: false
        },
        {
            cid10: "T301",
            descricao: "Queimadura de primeiro grau, parte do corpo não especificada",
            favorito: false
        },
        {
            cid10: "T302",
            descricao: "Queimadura de segundo grau, parte do corpo não especificada",
            favorito: false
        },
        {
            cid10: "T303",
            descricao: "Queimadura de terceiro grau, parte do corpo não especificada",
            favorito: false
        },
        {
            cid10: "T304",
            descricao: "Corrosão, parte do corpo não especificada, grau não especificado",
            favorito: false
        },
        {
            cid10: "T305",
            descricao: "Corrosão de primeiro grau, parte do corpo não especificada",
            favorito: false
        },
        {
            cid10: "T306",
            descricao: "Corrosão de segundo grau, parte do corpo não especificada",
            favorito: false
        },
        {
            cid10: "T307",
            descricao: "Corrosão de terceiro grau, parte do corpo não especificada",
            favorito: false
        },
        {
            cid10: "T310",
            descricao: "Queimaduras envolvendo menos de 10% da superfície corporal",
            favorito: false
        },
        {
            cid10: "T311",
            descricao: "Queimaduras envolvendo de 10 - 19% da superfície corporal",
            favorito: false
        },
        {
            cid10: "T312",
            descricao: "Queimaduras envolvendo de 20 - 29% da superfície corporal",
            favorito: false
        },
        {
            cid10: "T313",
            descricao: "Queimaduras envolvendo de 30 - 39% da superfície corporal",
            favorito: false
        },
        {
            cid10: "T314",
            descricao: "Queimaduras envolvendo de 40 - 49% da superfície corporal",
            favorito: false
        },
        {
            cid10: "T315",
            descricao: "Queimaduras envolvendo de 50 - 59% da superfície corporal",
            favorito: false
        },
        {
            cid10: "T316",
            descricao: "Queimaduras envolvendo de 60 - 69% da superfície corporal",
            favorito: false
        },
        {
            cid10: "T317",
            descricao: "Queimaduras envolvendo de 70 - 79% da superfície corporal",
            favorito: false
        },
        {
            cid10: "T318",
            descricao: "Queimaduras envolvendo de 80 - 89% da superfície corporal",
            favorito: false
        },
        {
            cid10: "T319",
            descricao: "Queimaduras envolvendo 90% ou mais da superfície corporal",
            favorito: false
        },
        {
            cid10: "T320",
            descricao: "Corrosões envolvendo menos de 10% da superfície corporal",
            favorito: false
        },
        {
            cid10: "T321",
            descricao: "Corrosões envolvendo 10 - 19% da superfície corporal",
            favorito: false
        },
        {
            cid10: "T322",
            descricao: "Corrosões envolvendo 20 - 29% da superfície corporal",
            favorito: false
        },
        {
            cid10: "T323",
            descricao: "Corrosões envolvendo 30 - 39% da superfície corporal",
            favorito: false
        },
        {
            cid10: "T324",
            descricao: "Corrosões envolvendo 40 - 49% da superfície corporal",
            favorito: false
        },
        {
            cid10: "T325",
            descricao: "Corrosões envolvendo 50 - 59% da superfície corporal",
            favorito: false
        },
        {
            cid10: "T326",
            descricao: "Corrosões envolvendo 60 - 69% da superfície corporal",
            favorito: false
        },
        {
            cid10: "T327",
            descricao: "Corrosões envolvendo 70 - 79% da superfície corporal",
            favorito: false
        },
        {
            cid10: "T328",
            descricao: "Corrosões envolvendo 80 - 89% da superfície corporal",
            favorito: false
        },
        {
            cid10: "T329",
            descricao: "Corrosões envolvendo 90% ou mais da superfície corporal",
            favorito: false
        },
        {
            cid10: "T330",
            descricao: "Geladura superficial da cabeça",
            favorito: false
        },
        {
            cid10: "T331",
            descricao: "Geladura superficial do pescoço",
            favorito: false
        },
        {
            cid10: "T332",
            descricao: "Geladura superficial do tórax",
            favorito: false
        },
        {
            cid10: "T333",
            descricao: "Geladura superficial da parede abdominal, parte inferior do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "T334",
            descricao: "Geladura superficial do braço",
            favorito: false
        },
        {
            cid10: "T335",
            descricao: "Geladura superficial do punho e da mão",
            favorito: false
        },
        {
            cid10: "T336",
            descricao: "Geladura superficial do quadril e da coxa",
            favorito: false
        },
        {
            cid10: "T337",
            descricao: "Geladura superficial do joelho e da perna",
            favorito: false
        },
        {
            cid10: "T338",
            descricao: "Geladura superficial do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "T339",
            descricao: "Geladura superficial de outros locais e de locais não especificados",
            favorito: false
        },
        {
            cid10: "T340",
            descricao: "Geladura, com necrose de tecidos, da cabeça",
            favorito: false
        },
        {
            cid10: "T341",
            descricao: "Geladura, com necrose de tecidos, do pescoço",
            favorito: false
        },
        {
            cid10: "T342",
            descricao: "Geladura, com necrose de tecidos, do tórax",
            favorito: false
        },
        {
            cid10: "T343",
            descricao: "Geladura, com necrose de tecidos, da parede abdominal, parte inferior do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "T344",
            descricao: "Geladura, com necrose de tecidos, do braço",
            favorito: false
        },
        {
            cid10: "T345",
            descricao: "Geladura, com necrose de tecidos, do punho e da mão",
            favorito: false
        },
        {
            cid10: "T346",
            descricao: "Geladura, com necrose de tecidos, do quadril e da coxa",
            favorito: false
        },
        {
            cid10: "T347",
            descricao: "Geladura, com necrose de tecidos, do joelho e da perna",
            favorito: false
        },
        {
            cid10: "T348",
            descricao: "Geladura, com necrose de tecidos, do tornozelo e do pé",
            favorito: false
        },
        {
            cid10: "T349",
            descricao: "Geladura, com necrose de tecidos, de localização não especificada",
            favorito: false
        },
        {
            cid10: "T350",
            descricao: "Geladura superficial envolvendo múltiplas partes do corpo",
            favorito: false
        },
        {
            cid10: "T351",
            descricao: "Geladura, com necrose de tecidos, de múltiplas partes do corpo",
            favorito: false
        },
        {
            cid10: "T352",
            descricao: "Geladura, de grau não especificado, da cabeça e do pescoço",
            favorito: false
        },
        {
            cid10: "T353",
            descricao: "Geladura, de grau não especificado, do tórax, abdome, parte inferior do dorso e da pelve",
            favorito: false
        },
        {
            cid10: "T354",
            descricao: "Geladura, de grau não especificado, do membro superior",
            favorito: false
        },
        {
            cid10: "T355",
            descricao: "Geladura, de grau não especificado, do membro inferior",
            favorito: false
        },
        {
            cid10: "T356",
            descricao: "Geladura, de grau não especificado, de múltiplas partes do corpo",
            favorito: false
        },
        {
            cid10: "T357",
            descricao: "Geladura, de grau não especificado, de localização não especificada",
            favorito: false
        },
        {
            cid10: "T360",
            descricao: "Intoxicação por penicilinas",
            favorito: false
        },
        {
            cid10: "T361",
            descricao: "Intoxicação por cefalosporinas e outras betalactaminas",
            favorito: false
        },
        {
            cid10: "T362",
            descricao: "Intoxicação pelo grupo do cloranfenicol",
            favorito: false
        },
        {
            cid10: "T363",
            descricao: "Intoxicação por macrolídios",
            favorito: false
        },
        {
            cid10: "T364",
            descricao: "Intoxicação por tetraciclinas",
            favorito: false
        },
        {
            cid10: "T365",
            descricao: "Intoxicação por aminoglicosídeos",
            favorito: false
        },
        {
            cid10: "T366",
            descricao: "Intoxicação por rifamicinas",
            favorito: false
        },
        {
            cid10: "T367",
            descricao: "Intoxicação por antibióticos antifúngicos administrados por via sistêmica",
            favorito: false
        },
        {
            cid10: "T368",
            descricao: "Intoxicação por outros antibióticos sistêmicos",
            favorito: false
        },
        {
            cid10: "T369",
            descricao: "Intoxicação por antibióticos sistêmicos não especificados",
            favorito: false
        },
        {
            cid10: "T370",
            descricao: "Intoxicação por sulfonamidas",
            favorito: false
        },
        {
            cid10: "T371",
            descricao: "Intoxicação por drogas antimicobacterianas",
            favorito: false
        },
        {
            cid10: "T372",
            descricao: "Intoxicação por antimaláricos e drogas que atuam sobre outros protozoários do sangue",
            favorito: false
        },
        {
            cid10: "T373",
            descricao: "Intoxicação por outros medicamentos antiprotozoários",
            favorito: false
        },
        {
            cid10: "T374",
            descricao: "Intoxicação por anti-helmínticos",
            favorito: false
        },
        {
            cid10: "T375",
            descricao: "Intoxicação por drogas antivirais",
            favorito: false
        },
        {
            cid10: "T378",
            descricao: "Intoxicação por outros agentes antiinfecciosos e antiparasitários especificados",
            favorito: false
        },
        {
            cid10: "T379",
            descricao: "Intoxicação por agentes antiinfecciosos e antiparasitários sistêmicos não especificados",
            favorito: false
        },
        {
            cid10: "T380",
            descricao: "Intoxicação por glicocorticóides e análogos sintéticos",
            favorito: false
        },
        {
            cid10: "T381",
            descricao: "Intoxicação por hormônios tireoidianos e seus derivados",
            favorito: false
        },
        {
            cid10: "T382",
            descricao: "Intoxicação por medicamentos antitireoidianos",
            favorito: false
        },
        {
            cid10: "T383",
            descricao: "Intoxicação por insulina e drogas hipoglicemiantes orais [antidiabéticos]",
            favorito: false
        },
        {
            cid10: "T384",
            descricao: "Intoxicação por anticoncepcionais orais",
            favorito: false
        },
        {
            cid10: "T385",
            descricao: "Intoxicação por outros estrógenos e progestágenos",
            favorito: false
        },
        {
            cid10: "T386",
            descricao: "Intoxicação por antigonadotrofinas, antiestrógenos, antiandrógenos não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "T387",
            descricao: "Intoxicação por andrógenos e anabolizantes congêneres",
            favorito: false
        },
        {
            cid10: "T388",
            descricao: "Intoxicação por outros hormônios e substitutos sintéticos, e os não especificados",
            favorito: false
        },
        {
            cid10: "T389",
            descricao: "Intoxicação por outros antagonistas hormonais, e os não especificados",
            favorito: false
        },
        {
            cid10: "T390",
            descricao: "Intoxicação por salicilatos",
            favorito: false
        },
        {
            cid10: "T391",
            descricao: "Intoxicação por derivados do 4-aminofenol",
            favorito: false
        },
        {
            cid10: "T392",
            descricao: "Intoxicação por derivados pirazolônicos",
            favorito: false
        },
        {
            cid10: "T393",
            descricao: "Intoxicação por outros antiinflamatórios não esteróides",
            favorito: false
        },
        {
            cid10: "T394",
            descricao: "Intoxicação por anti-reumáticos não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "T398",
            descricao: "Intoxicação por outros analgésicos não opiáceos e antipiréticos não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "T399",
            descricao: "Intoxicação por analgésico não-opiáceo, antipirético e anti-reumático, não especificados",
            favorito: false
        },
        {
            cid10: "T400",
            descricao: "Intoxicação por ópio",
            favorito: false
        },
        {
            cid10: "T401",
            descricao: "Intoxicação por heroína",
            favorito: false
        },
        {
            cid10: "T402",
            descricao: "Intoxicação por outros opiáceos",
            favorito: false
        },
        {
            cid10: "T403",
            descricao: "Intoxicação por metadona",
            favorito: false
        },
        {
            cid10: "T404",
            descricao: "Intoxicação por outros narcóticos sintéticos",
            favorito: false
        },
        {
            cid10: "T405",
            descricao: "Intoxicação por cocaína",
            favorito: false
        },
        {
            cid10: "T406",
            descricao: "Intoxicação por outros narcóticos e os não especificados",
            favorito: false
        },
        {
            cid10: "T407",
            descricao: "Intoxicação por cannabis (derivados)",
            favorito: false
        },
        {
            cid10: "T408",
            descricao: "Intoxicação por lisergida [LSD]",
            favorito: false
        },
        {
            cid10: "T409",
            descricao: "Intoxicação por outros psicodislépticos e os não especificados [alucinógenos]",
            favorito: false
        },
        {
            cid10: "T410",
            descricao: "Intoxicação por anestésicos inalatórios",
            favorito: false
        },
        {
            cid10: "T411",
            descricao: "Intoxicação por anestésicos intravenosos",
            favorito: false
        },
        {
            cid10: "T412",
            descricao: "Intoxicação por outros anestésicos gerais e os não especificados",
            favorito: false
        },
        {
            cid10: "T413",
            descricao: "Intoxicação por anestésicos locais",
            favorito: false
        },
        {
            cid10: "T414",
            descricao: "Intoxicação por anestésico não especificado",
            favorito: false
        },
        {
            cid10: "T415",
            descricao: "Intoxicação por gases terapêuticos",
            favorito: false
        },
        {
            cid10: "T420",
            descricao: "Intoxicação por derivados da hidantoína",
            favorito: false
        },
        {
            cid10: "T421",
            descricao: "Intoxicação por iminostilbenos",
            favorito: false
        },
        {
            cid10: "T422",
            descricao: "Intoxicação por succinimidas e oxazolidinadionas",
            favorito: false
        },
        {
            cid10: "T423",
            descricao: "Intoxicação por barbitúricos",
            favorito: false
        },
        {
            cid10: "T424",
            descricao: "Intoxicação por benzodiazepinas",
            favorito: false
        },
        {
            cid10: "T425",
            descricao: "Intoxicação por antiepiléticos em associação, não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "T426",
            descricao: "Intoxicação por outras drogas antiepilépticas e sedativos-hipnóticos",
            favorito: false
        },
        {
            cid10: "T427",
            descricao: "Intoxicação por drogas antiepilépticos, sedativos-hipnóticos, não especificados",
            favorito: false
        },
        {
            cid10: "T428",
            descricao: "Intoxicação por drogas antiparkinsonianos e outros depressores centrais do tônus muscular",
            favorito: false
        },
        {
            cid10: "T430",
            descricao: "Intoxicação por antidepressivos tricíclicos e tetracíclicos",
            favorito: false
        },
        {
            cid10: "T431",
            descricao: "Intoxicação por antidepressivos inibidores da monoaminooxidase (MAO)",
            favorito: false
        },
        {
            cid10: "T432",
            descricao: "Intoxicação por outros antidepressivos e os não especificados",
            favorito: false
        },
        {
            cid10: "T433",
            descricao: "Intoxicação por antipsicóticos e neurolépticos derivados da fenotiazina",
            favorito: false
        },
        {
            cid10: "T434",
            descricao: "Intoxicação por neurolépticos do tipo butirofenona e tioxanteno",
            favorito: false
        },
        {
            cid10: "T435",
            descricao: "Intoxicação por outros antipsicóticos e neurolépticos e os não especificados",
            favorito: false
        },
        {
            cid10: "T436",
            descricao: "Intoxicação por psicoestimulantes que potencialmente podem provocar dependência",
            favorito: false
        },
        {
            cid10: "T438",
            descricao: "Intoxicação por outras drogas psicotrópicas não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "T439",
            descricao: "Intoxicação por droga psicotrópica não especificada",
            favorito: false
        },
        {
            cid10: "T440",
            descricao: "Intoxicação por agentes anticolinesterase",
            favorito: false
        },
        {
            cid10: "T441",
            descricao: "Intoxicação por outros agentes parassimpaticomiméticos [colinérgicos]",
            favorito: false
        },
        {
            cid10: "T442",
            descricao: "Intoxicação por bloqueadores ganglionares não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "T443",
            descricao: "Intoxicação por outros parassimpaticolíticos [anticolinérgicos e antimuscarínicos] e espasmolíticos não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "T444",
            descricao: "Intoxicação por agonistas principalmente alfa-adrenorreceptores não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "T445",
            descricao: "Intoxicação por agonistas principalmente beta-adrenorreceptores não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "T446",
            descricao: "Intoxicação por antagonistas alfa-adrenorreceptores não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "T447",
            descricao: "Intoxicação por antagonistas beta-adrenorreceptores não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "T448",
            descricao: "Intoxicação por bloqueadores neuronais adrenérgicos e de ação central, não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "T449",
            descricao: "Intoxicação por outras drogas que afetam principalmente o sistema nervoso autônomo e as não especificadas",
            favorito: false
        },
        {
            cid10: "T450",
            descricao: "Intoxicação por drogas antialérgicas e antieméticas",
            favorito: false
        },
        {
            cid10: "T451",
            descricao: "Intoxicação por drogas antineoplásicas e imunomupressoras",
            favorito: false
        },
        {
            cid10: "T452",
            descricao: "Intoxicação por vitaminas, não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "T453",
            descricao: "Intoxicação por enzimas, não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "T454",
            descricao: "Intoxicação por ferro e seus compostos",
            favorito: false
        },
        {
            cid10: "T455",
            descricao: "Intoxicação por anticoagulantes",
            favorito: false
        },
        {
            cid10: "T456",
            descricao: "Intoxicação por drogas que afetam a fibrinólise",
            favorito: false
        },
        {
            cid10: "T457",
            descricao: "Intoxicação por antagonistas de anticoagulantes, vitamina K e de outros fatores de coagulação",
            favorito: false
        },
        {
            cid10: "T458",
            descricao: "Intoxicação por outras substâncias primariamente sistêmicas e hematológicas",
            favorito: false
        },
        {
            cid10: "T459",
            descricao: "Intoxicação por substância essencialmente sistêmica e hematológica não especificada",
            favorito: false
        },
        {
            cid10: "T460",
            descricao: "Intoxicação por glicosídeos estimulantes do coração e substâncias de ação semelhante",
            favorito: false
        },
        {
            cid10: "T461",
            descricao: "Intoxicação por bloqueadores dos canais de cálcio",
            favorito: false
        },
        {
            cid10: "T462",
            descricao: "Intoxicação por outras drogas antidisrítmicas não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "T463",
            descricao: "Intoxicação por vasodilatadores coronarianos, não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "T464",
            descricao: "Intoxicação por inibidores da enzima de conversão da angiotensina",
            favorito: false
        },
        {
            cid10: "T465",
            descricao: "Intoxicação por outras drogas anti-hipertensivas não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "T466",
            descricao: "Intoxicação por drogas antihiperlipêmicas e antiarterioscleróticas",
            favorito: false
        },
        {
            cid10: "T467",
            descricao: "Intoxicação por vasodilatadores periféricos",
            favorito: false
        },
        {
            cid10: "T468",
            descricao: "Intoxicação por drogas antivaricosas, incluindo substâncias esclerosantes",
            favorito: false
        },
        {
            cid10: "T469",
            descricao: "Intoxicação por outras substâncias e as não especificadas que agem essencialmente sobre o aparelho circulatório",
            favorito: false
        },
        {
            cid10: "T470",
            descricao: "Intoxicação por antagonistas dos receptores H2 da histamina",
            favorito: false
        },
        {
            cid10: "T471",
            descricao: "Intoxicação por outros antiácidos e drogas que inibem a secreção gástrica",
            favorito: false
        },
        {
            cid10: "T472",
            descricao: "Intoxicação por laxativos estimulantes",
            favorito: false
        },
        {
            cid10: "T473",
            descricao: "Intoxicação por laxativos salinos e osmóticos",
            favorito: false
        },
        {
            cid10: "T474",
            descricao: "Intoxicação por outros laxativos",
            favorito: false
        },
        {
            cid10: "T475",
            descricao: "Intoxicação por digestivos",
            favorito: false
        },
        {
            cid10: "T476",
            descricao: "Intoxicação por drogas antidiarréicas",
            favorito: false
        },
        {
            cid10: "T477",
            descricao: "Intoxicação por eméticos",
            favorito: false
        },
        {
            cid10: "T478",
            descricao: "Intoxicação por outras substâncias que atuam primariamente sobre o aparelho gastrintestinal",
            favorito: false
        },
        {
            cid10: "T479",
            descricao: "Intoxicação por substância não especificada que atua primariamente sobre o aparelho gastrointestinal",
            favorito: false
        },
        {
            cid10: "T480",
            descricao: "Intoxicação por drogas ocitócicas",
            favorito: false
        },
        {
            cid10: "T481",
            descricao: "Intoxicação por relaxantes musculares esqueléticos [bloqueadores neuromusculares]",
            favorito: false
        },
        {
            cid10: "T482",
            descricao: "Intoxicação por outras substâncias e as não especificadas que atuam sobre os músculos",
            favorito: false
        },
        {
            cid10: "T483",
            descricao: "Intoxicação por antitussígenos",
            favorito: false
        },
        {
            cid10: "T484",
            descricao: "Intoxicação por expectorantes",
            favorito: false
        },
        {
            cid10: "T485",
            descricao: "Intoxicação por drogas anti-resfriado",
            favorito: false
        },
        {
            cid10: "T486",
            descricao: "Intoxicação por antiasmáticos não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "T487",
            descricao: "Intoxicação por outras substâncias e as não especificadas que atuam primariamente sobre o aparelho respiratório",
            favorito: false
        },
        {
            cid10: "T490",
            descricao: "Intoxicação por antifúngicos, antiinfecciosos e antiinflamatórios de uso tópico, não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "T491",
            descricao: "Intoxicação por antipruriginosos",
            favorito: false
        },
        {
            cid10: "T492",
            descricao: "Intoxicação por adstringentes e detergentes de uso local",
            favorito: false
        },
        {
            cid10: "T493",
            descricao: "Intoxicação por emolientes, demulcentes e protetores",
            favorito: false
        },
        {
            cid10: "T494",
            descricao: "Intoxicação por ceratolíticos, ceratoplásticos e outras drogas e preparados para o tratamento dos cabelos",
            favorito: false
        },
        {
            cid10: "T495",
            descricao: "Intoxicação por drogas e preparações de uso oftalmológico",
            favorito: false
        },
        {
            cid10: "T496",
            descricao: "Intoxicação por drogas e preparações de uso otorrinolaringológico",
            favorito: false
        },
        {
            cid10: "T497",
            descricao: "Intoxicação por drogas dentárias de uso tópico",
            favorito: false
        },
        {
            cid10: "T498",
            descricao: "Intoxicação por outras drogas de uso tópico",
            favorito: false
        },
        {
            cid10: "T499",
            descricao: "Intoxicação por preparado de uso tópico, não especificado",
            favorito: false
        },
        {
            cid10: "T500",
            descricao: "Intoxicação por mineralocorticóides e seus antagonistas",
            favorito: false
        },
        {
            cid10: "T501",
            descricao: "Intoxicação por diuréticos de alça",
            favorito: false
        },
        {
            cid10: "T502",
            descricao: "Intoxicação por inibidores da anidrase carbônica, benzotiazidas e outros diuréticos",
            favorito: false
        },
        {
            cid10: "T503",
            descricao: "Intoxicação por produtos que agem sobre o equilíbrio eletrolítico, calórico e hídrico",
            favorito: false
        },
        {
            cid10: "T504",
            descricao: "Intoxicação por drogas que agem sobre o metabolismo do ácido úrico",
            favorito: false
        },
        {
            cid10: "T505",
            descricao: "Intoxicação por inibidores do apetite",
            favorito: false
        },
        {
            cid10: "T506",
            descricao: "Intoxicação por antídotos e quelantes não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "T507",
            descricao: "Intoxicação por analépticos e antagonistas dos receptores de opiáceos",
            favorito: false
        },
        {
            cid10: "T508",
            descricao: "Intoxicação por agentes de diagnóstico",
            favorito: false
        },
        {
            cid10: "T509",
            descricao: "Intoxicação por outras drogas, medicamentos e substâncias biológicas e as não especificadas",
            favorito: false
        },
        {
            cid10: "T510",
            descricao: "Efeito tóxico do etanol",
            favorito: false
        },
        {
            cid10: "T511",
            descricao: "Efeito tóxico do metanol",
            favorito: false
        },
        {
            cid10: "T512",
            descricao: "Efeito tóxico do 2-propanol",
            favorito: false
        },
        {
            cid10: "T513",
            descricao: "Efeito tóxico do óleo de fusel",
            favorito: false
        },
        {
            cid10: "T518",
            descricao: "Efeito tóxico de outros álcoois",
            favorito: false
        },
        {
            cid10: "T519",
            descricao: "Efeito tóxico de álcool não especificado",
            favorito: false
        },
        {
            cid10: "T520",
            descricao: "Efeito tóxico de derivados do petróleo",
            favorito: false
        },
        {
            cid10: "T521",
            descricao: "Efeito tóxico do benzeno",
            favorito: false
        },
        {
            cid10: "T522",
            descricao: "Efeito tóxico de homólogos do benzeno",
            favorito: false
        },
        {
            cid10: "T523",
            descricao: "Efeito tóxico de glicóis",
            favorito: false
        },
        {
            cid10: "T524",
            descricao: "Efeito tóxico de cetonas",
            favorito: false
        },
        {
            cid10: "T528",
            descricao: "Efeito tóxico de outros solventes orgânicos",
            favorito: false
        },
        {
            cid10: "T529",
            descricao: "Efeito tóxico de solvente orgânico não especificado",
            favorito: false
        },
        {
            cid10: "T530",
            descricao: "Efeito tóxico do tetracloreto de carbono",
            favorito: false
        },
        {
            cid10: "T531",
            descricao: "Efeito tóxico do clorofórmio",
            favorito: false
        },
        {
            cid10: "T532",
            descricao: "Efeito tóxico do tricloroetileno",
            favorito: false
        },
        {
            cid10: "T533",
            descricao: "Efeito tóxico do tetracloroetileno",
            favorito: false
        },
        {
            cid10: "T534",
            descricao: "Efeito tóxico do diclorometano",
            favorito: false
        },
        {
            cid10: "T535",
            descricao: "Efeito tóxico do clorofluorcarbono",
            favorito: false
        },
        {
            cid10: "T536",
            descricao: "Efeito tóxico de outros derivados halogênicos de hidrocarbonetos alifáticos",
            favorito: false
        },
        {
            cid10: "T537",
            descricao: "Efeito tóxico de derivados halogênicos de hidrocarbonetos aromáticos",
            favorito: false
        },
        {
            cid10: "T539",
            descricao: "Efeito tóxico de derivados halogênicos de hidrocarbonetos alifáticos e aromáticos não especificados",
            favorito: false
        },
        {
            cid10: "T540",
            descricao: "Efeito tóxico do fenol e homólogos do fenol",
            favorito: false
        },
        {
            cid10: "T541",
            descricao: "Efeito tóxico de outros compostos corrosivos orgânicos",
            favorito: false
        },
        {
            cid10: "T542",
            descricao: "Efeito tóxico de ácidos corrosivos e substâncias semelhantes",
            favorito: false
        },
        {
            cid10: "T543",
            descricao: "Efeito tóxico de bases (álcalis) cáusticas(os) e substâncias semelhantes",
            favorito: false
        },
        {
            cid10: "T549",
            descricao: "Efeito tóxico de substância corrosiva não especificada",
            favorito: false
        },
        {
            cid10: "T55",
            descricao: "Efeito tóxico de sabões e detergentes",
            favorito: false
        },
        {
            cid10: "T560",
            descricao: "Efeito tóxico do chumbo e seus compostos",
            favorito: false
        },
        {
            cid10: "T561",
            descricao: "Efeito tóxico do mercúrio e seus compostos",
            favorito: false
        },
        {
            cid10: "T562",
            descricao: "Efeito tóxico do cromo e seus compostos",
            favorito: false
        },
        {
            cid10: "T563",
            descricao: "Efeito tóxico do cádmio e seus compostos",
            favorito: false
        },
        {
            cid10: "T564",
            descricao: "Efeito tóxico do cobre e seus compostos",
            favorito: false
        },
        {
            cid10: "T565",
            descricao: "Efeito tóxico do zinco e seus compostos",
            favorito: false
        },
        {
            cid10: "T566",
            descricao: "Efeito tóxico do estanho e seus compostos",
            favorito: false
        },
        {
            cid10: "T567",
            descricao: "Efeito tóxico do berilo e seus compostos",
            favorito: false
        },
        {
            cid10: "T568",
            descricao: "Efeito tóxico de outros metais",
            favorito: false
        },
        {
            cid10: "T569",
            descricao: "Efeito tóxico de metal, não especificado",
            favorito: false
        },
        {
            cid10: "T570",
            descricao: "Efeito tóxico do arsênico e seus compostos",
            favorito: false
        },
        {
            cid10: "T571",
            descricao: "Efeito tóxico do fósforo e seus compostos",
            favorito: false
        },
        {
            cid10: "T572",
            descricao: "Efeito tóxico do manganês e seus compostos",
            favorito: false
        },
        {
            cid10: "T573",
            descricao: "Efeito tóxico do cianeto de hidrogênio (ácido cianídrico)",
            favorito: false
        },
        {
            cid10: "T578",
            descricao: "Efeito tóxico de outras substâncias inorgânicas especificadas",
            favorito: false
        },
        {
            cid10: "T579",
            descricao: "Efeito tóxico de substância inorgânica não especificada",
            favorito: false
        },
        {
            cid10: "T58",
            descricao: "Efeito tóxico do monóxido de carbono",
            favorito: false
        },
        {
            cid10: "T590",
            descricao: "Efeito tóxico de óxidos de nitrogênio",
            favorito: false
        },
        {
            cid10: "T591",
            descricao: "Efeito tóxico do dióxido de enxofre",
            favorito: false
        },
        {
            cid10: "T592",
            descricao: "Efeito tóxico de formaldeído",
            favorito: false
        },
        {
            cid10: "T593",
            descricao: "Efeito tóxico do gás lacrimogêneo",
            favorito: false
        },
        {
            cid10: "T594",
            descricao: "Efeito tóxico do cloro gasoso",
            favorito: false
        },
        {
            cid10: "T595",
            descricao: "Efeito tóxico do fluoreto gasoso e ácido fluorídrico",
            favorito: false
        },
        {
            cid10: "T596",
            descricao: "Efeito tóxico do ácido sulfídrico",
            favorito: false
        },
        {
            cid10: "T597",
            descricao: "Efeito tóxico do dióxido de carbono",
            favorito: false
        },
        {
            cid10: "T598",
            descricao: "Efeito tóxico de outros gases, fumaças e vapores especificados",
            favorito: false
        },
        {
            cid10: "T599",
            descricao: "Efeito tóxico de gases, fumaças e vapores não especificados",
            favorito: false
        },
        {
            cid10: "T600",
            descricao: "Efeito tóxico de inseticidas organofosforados e carbamatos",
            favorito: false
        },
        {
            cid10: "T601",
            descricao: "Efeito tóxico de inseticidas halogenados",
            favorito: false
        },
        {
            cid10: "T602",
            descricao: "Efeito tóxico de outros inseticidas",
            favorito: false
        },
        {
            cid10: "T603",
            descricao: "Efeito tóxico de herbicidas e fungicidas",
            favorito: false
        },
        {
            cid10: "T604",
            descricao: "Efeito tóxico de rodenticidas",
            favorito: false
        },
        {
            cid10: "T608",
            descricao: "Efeito tóxico de outros pesticidas",
            favorito: false
        },
        {
            cid10: "T609",
            descricao: "Efeito tóxico de pesticida não especificado",
            favorito: false
        },
        {
            cid10: "T610",
            descricao: "Intoxicação pelo peixe ciguatera",
            favorito: false
        },
        {
            cid10: "T611",
            descricao: "Intoxicação por peixes escombrídeos",
            favorito: false
        },
        {
            cid10: "T612",
            descricao: "Outras intoxicações por peixes e mariscos",
            favorito: false
        },
        {
            cid10: "T618",
            descricao: "Efeito tóxico de outros frutos do mar",
            favorito: false
        },
        {
            cid10: "T619",
            descricao: "Efeito tóxico de frutos do mar não especificados",
            favorito: false
        },
        {
            cid10: "T620",
            descricao: "Efeito tóxico de ingestão de cogumelos",
            favorito: false
        },
        {
            cid10: "T621",
            descricao: "Efeito tóxico de ingestão de frutos",
            favorito: false
        },
        {
            cid10: "T622",
            descricao: "Efeito tóxico de ingestão de outras (partes de) plantas",
            favorito: false
        },
        {
            cid10: "T628",
            descricao: "Efeito tóxico de outras substâncias nocivas ingeridas como alimento",
            favorito: false
        },
        {
            cid10: "T629",
            descricao: "Efeito tóxico de substâncias nocivas ingeridas como alimento, não especificadas",
            favorito: false
        },
        {
            cid10: "T630",
            descricao: "Efeito tóxico do veneno de serpente",
            favorito: false
        },
        {
            cid10: "T631",
            descricao: "Efeito tóxico do veneno de outros répteis",
            favorito: false
        },
        {
            cid10: "T632",
            descricao: "Efeito tóxico do veneno de escorpião",
            favorito: false
        },
        {
            cid10: "T633",
            descricao: "Efeito tóxico do veneno de aranha",
            favorito: false
        },
        {
            cid10: "T634",
            descricao: "Efeito tóxico do veneno de outros artrópodes",
            favorito: false
        },
        {
            cid10: "T635",
            descricao: "Efeito tóxico de contato com peixe",
            favorito: false
        },
        {
            cid10: "T636",
            descricao: "Efeito tóxico de contato com outros animais marinhos",
            favorito: false
        },
        {
            cid10: "T638",
            descricao: "Efeito tóxico de contato com outros animais venenosos",
            favorito: false
        },
        {
            cid10: "T639",
            descricao: "Efeito tóxico de contato com animal venenoso não especificado",
            favorito: false
        },
        {
            cid10: "T64",
            descricao: "Efeito tóxico da aflatoxina e de outras micotoxinas contaminantes de alimentos",
            favorito: false
        },
        {
            cid10: "T650",
            descricao: "Efeito tóxico de cianetos",
            favorito: false
        },
        {
            cid10: "T651",
            descricao: "Efeito tóxico da estricnina e seus sais",
            favorito: false
        },
        {
            cid10: "T652",
            descricao: "Efeito tóxico do tabaco e da nicotina",
            favorito: false
        },
        {
            cid10: "T653",
            descricao: "Efeito tóxico de nitroderivados e aminoderivados do benzeno e seus homólogos",
            favorito: false
        },
        {
            cid10: "T654",
            descricao: "Efeito tóxico do dissulfeto de carbono",
            favorito: false
        },
        {
            cid10: "T655",
            descricao: "Efeito tóxico da nitroglicerina e outros ácidos e ésteres nítricos",
            favorito: false
        },
        {
            cid10: "T656",
            descricao: "Efeito tóxico de tinturas e corantes, não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "T658",
            descricao: "Efeito tóxico de outras substâncias especificadas",
            favorito: false
        },
        {
            cid10: "T659",
            descricao: "Efeito tóxico de substância não especificada",
            favorito: false
        },
        {
            cid10: "T66",
            descricao: "Efeitos não especificados de radiação",
            favorito: false
        },
        {
            cid10: "T670",
            descricao: "Golpe de calor e insolação",
            favorito: false
        },
        {
            cid10: "T671",
            descricao: "Síncope devida ao calor",
            favorito: false
        },
        {
            cid10: "T672",
            descricao: "Cãibras devidas ao calor",
            favorito: false
        },
        {
            cid10: "T673",
            descricao: "Exaustão devida ao calor e à perda hídrica",
            favorito: false
        },
        {
            cid10: "T674",
            descricao: "Exaustão devida ao calor e à perda de sal",
            favorito: false
        },
        {
            cid10: "T675",
            descricao: "Exaustão devida ao calor, sem especificação",
            favorito: false
        },
        {
            cid10: "T676",
            descricao: "Fadiga transitória devida ao calor",
            favorito: false
        },
        {
            cid10: "T677",
            descricao: "Edema devido ao calor",
            favorito: false
        },
        {
            cid10: "T678",
            descricao: "Outros efeitos do calor e da luz",
            favorito: false
        },
        {
            cid10: "T679",
            descricao: "Efeito do calor e da luz não especificado",
            favorito: false
        },
        {
            cid10: "T68",
            descricao: "Hipotermia",
            favorito: false
        },
        {
            cid10: "T690",
            descricao: "Mão e pé de imersão",
            favorito: false
        },
        {
            cid10: "T691",
            descricao: "Congelamento [chilblains]",
            favorito: false
        },
        {
            cid10: "T698",
            descricao: "Outros efeitos especificados de temperatura reduzida",
            favorito: false
        },
        {
            cid10: "T699",
            descricao: "Efeito não especificado de temperatura reduzida",
            favorito: false
        },
        {
            cid10: "T700",
            descricao: "Otite barotraumática",
            favorito: false
        },
        {
            cid10: "T701",
            descricao: "Sinusite barotraumática",
            favorito: false
        },
        {
            cid10: "T702",
            descricao: "Outros efeitos das grandes altitude e os não especificados",
            favorito: false
        },
        {
            cid10: "T703",
            descricao: "Mal dos caixões [doença de descompressão]",
            favorito: false
        },
        {
            cid10: "T704",
            descricao: "Efeito dos fluidos em alta pressão",
            favorito: false
        },
        {
            cid10: "T708",
            descricao: "Outros efeitos da pressão atmosférica ou da pressão da água",
            favorito: false
        },
        {
            cid10: "T709",
            descricao: "Efeitos não especificados da pressão atmosférica ou da pressão da água",
            favorito: false
        },
        {
            cid10: "T71",
            descricao: "Asfixia",
            favorito: false
        },
        {
            cid10: "T730",
            descricao: "Efeitos da fome",
            favorito: false
        },
        {
            cid10: "T731",
            descricao: "Efeitos da sede",
            favorito: false
        },
        {
            cid10: "T732",
            descricao: "Exaustão devida às intempéries",
            favorito: false
        },
        {
            cid10: "T733",
            descricao: "Exaustão devido a um esforço intenso",
            favorito: false
        },
        {
            cid10: "T738",
            descricao: "Outros efeitos de privação",
            favorito: false
        },
        {
            cid10: "T739",
            descricao: "Efeito não especificado de privação",
            favorito: false
        },
        {
            cid10: "T740",
            descricao: "Abandono",
            favorito: false
        },
        {
            cid10: "T741",
            descricao: "Sevícias físicas",
            favorito: false
        },
        {
            cid10: "T742",
            descricao: "Abuso sexual",
            favorito: false
        },
        {
            cid10: "T743",
            descricao: "Abuso psicológico",
            favorito: false
        },
        {
            cid10: "T748",
            descricao: "Outras síndromes especificadas de maus tratos",
            favorito: false
        },
        {
            cid10: "T749",
            descricao: "Síndrome não especificada de maus tratos",
            favorito: false
        },
        {
            cid10: "T750",
            descricao: "Efeitos do raio",
            favorito: false
        },
        {
            cid10: "T751",
            descricao: "Afogamento e submersão não mortal",
            favorito: false
        },
        {
            cid10: "T752",
            descricao: "Efeitos da vibração",
            favorito: false
        },
        {
            cid10: "T753",
            descricao: "Doença causada pelo movimento",
            favorito: false
        },
        {
            cid10: "T754",
            descricao: "Efeitos da corrente elétrica",
            favorito: false
        },
        {
            cid10: "T758",
            descricao: "Outros efeitos especificados de causas externas",
            favorito: false
        },
        {
            cid10: "T780",
            descricao: "Choque anafilático devido à intolerância alimentar",
            favorito: false
        },
        {
            cid10: "T781",
            descricao: "Outras reações de intolerância alimentar não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "T782",
            descricao: "Choque anafilático não especificado",
            favorito: false
        },
        {
            cid10: "T783",
            descricao: "Edema angioneurótico",
            favorito: false
        },
        {
            cid10: "T784",
            descricao: "Alergia não especificada",
            favorito: false
        },
        {
            cid10: "T788",
            descricao: "Outros efeitos adversos não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "T789",
            descricao: "Efeito adverso não especificado",
            favorito: false
        },
        {
            cid10: "T790",
            descricao: "Embolia gasosa (traumática)",
            favorito: false
        },
        {
            cid10: "T791",
            descricao: "Embolia gordurosa (traumática)",
            favorito: false
        },
        {
            cid10: "T792",
            descricao: "Hemorragia traumática secundária e recidivante",
            favorito: false
        },
        {
            cid10: "T793",
            descricao: "Infecção pós-traumática de ferimento não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "T794",
            descricao: "Choque traumático",
            favorito: false
        },
        {
            cid10: "T795",
            descricao: "Anúria traumática",
            favorito: false
        },
        {
            cid10: "T796",
            descricao: "Isquemia muscular traumática",
            favorito: false
        },
        {
            cid10: "T797",
            descricao: "Enfisema subcutâneo de origem traumática",
            favorito: false
        },
        {
            cid10: "T798",
            descricao: "Outras complicações precoces de traumatismo",
            favorito: false
        },
        {
            cid10: "T799",
            descricao: "Complicação precoce não especificada de traumatismo",
            favorito: false
        },
        {
            cid10: "T800",
            descricao: "Embolia aérea subseqüentes à infusão, transfusão e injeção terapêutica",
            favorito: false
        },
        {
            cid10: "T801",
            descricao: "Complicações vasculares subseqüentes à infusão, transfusão e injeção terapêutica",
            favorito: false
        },
        {
            cid10: "T802",
            descricao: "Infecções subseqüentes à infusão, transfusão e injeção terapêutica",
            favorito: false
        },
        {
            cid10: "T803",
            descricao: "Reação de incompatibilidade ABO",
            favorito: false
        },
        {
            cid10: "T804",
            descricao: "Reação de incompatibilidade Rh",
            favorito: false
        },
        {
            cid10: "T805",
            descricao: "Choque anafilático devido a soro",
            favorito: false
        },
        {
            cid10: "T806",
            descricao: "Outras reações ao soro",
            favorito: false
        },
        {
            cid10: "T808",
            descricao: "Outras complicações subseqüentes à infusão, transfusão e injeção terapêutica",
            favorito: false
        },
        {
            cid10: "T809",
            descricao: "Complicação não especificada subseqüente à infusão, transfusão e injeção terapêutica",
            favorito: false
        },
        {
            cid10: "T810",
            descricao: "Hemorragia e hematoma complicando procedimento não classificado em outra parte",
            favorito: false
        },
        {
            cid10: "T811",
            descricao: "Choque durante ou resultante de procedimento não classificado em outra parte",
            favorito: false
        },
        {
            cid10: "T812",
            descricao: "Perfuração e laceração acidentais durante um procedimento não classificado em outra parte",
            favorito: false
        },
        {
            cid10: "T813",
            descricao: "Deiscência de ferida cirúrgica não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "T814",
            descricao: "Infecção subseqüente a procedimento não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "T815",
            descricao: "Corpo estranho deixado acidentalmente em cavidade corporal ou em ferida operatória subseqüente a procedimento",
            favorito: false
        },
        {
            cid10: "T816",
            descricao: "Reação aguda à substância estranha deixada acidentalmente durante um procedimento",
            favorito: false
        },
        {
            cid10: "T817",
            descricao: "Complicações vasculares subseqüentes a um procedimento não classificado em outra parte",
            favorito: false
        },
        {
            cid10: "T818",
            descricao: "Outras complicações de procedimentos não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "T819",
            descricao: "Complicação não especificada de procedimento",
            favorito: false
        },
        {
            cid10: "T820",
            descricao: "Complicação mecânica de prótese valvular cardíaca",
            favorito: false
        },
        {
            cid10: "T821",
            descricao: "Complicação mecânica de dispositivo eletrônico cardíaco",
            favorito: false
        },
        {
            cid10: "T822",
            descricao: "Complicação mecânica de enxerto de ponte coronária e implantes de valvas",
            favorito: false
        },
        {
            cid10: "T823",
            descricao: "Complicações mecânicas de outros enxertos vasculares",
            favorito: false
        },
        {
            cid10: "T824",
            descricao: "Complicação mecânica de cateter vascular de diálise",
            favorito: false
        },
        {
            cid10: "T825",
            descricao: "Complicações mecânicas de outros dispositivos e implantes cardiovasculares",
            favorito: false
        },
        {
            cid10: "T826",
            descricao: "Infecção e reação inflamatórias devidas à prótese valvular cardíaca",
            favorito: false
        },
        {
            cid10: "T827",
            descricao: "Infecção e reação inflamatórias devidas a outros dispositivos, implantes e enxertos cardíacos e vasculares",
            favorito: false
        },
        {
            cid10: "T828",
            descricao: "Outras complicações de dispositivos protéticos, implantes e enxertos cardíacos e vasculares",
            favorito: false
        },
        {
            cid10: "T829",
            descricao: "Complicações não especificada de dispositivo protético, implante e enxerto cardíacos e vasculares",
            favorito: false
        },
        {
            cid10: "T830",
            descricao: "Complicação mecânica de cateter (de demora) urinário",
            favorito: false
        },
        {
            cid10: "T831",
            descricao: "Complicação mecânica de outros dispositivos e implantes urinários",
            favorito: false
        },
        {
            cid10: "T832",
            descricao: "Complicação mecânica de enxerto de órgão urinário",
            favorito: false
        },
        {
            cid10: "T833",
            descricao: "Complicação mecânica de dispositivo intra-uterino (anticoncepcional)",
            favorito: false
        },
        {
            cid10: "T834",
            descricao: "Complicação mecânica de outros dispositivos protéticos, implantes e enxertos do trato genital",
            favorito: false
        },
        {
            cid10: "T835",
            descricao: "Infecção e reação inflamatória devidas à dispositivo protético, implante e enxerto no aparelho urinário",
            favorito: false
        },
        {
            cid10: "T836",
            descricao: "Infecção e reação inflamatória devidas à dispositivo protético, implante e enxerto no trato genital",
            favorito: false
        },
        {
            cid10: "T838",
            descricao: "Outras complicações de dispositivos protéticos, implantes e enxertos geniturinários",
            favorito: false
        },
        {
            cid10: "T839",
            descricao: "Complicação não especificada de prótese, implante e enxerto geniturinários",
            favorito: false
        },
        {
            cid10: "T840",
            descricao: "Complicação mecânica de prótese articular interna",
            favorito: false
        },
        {
            cid10: "T841",
            descricao: "Complicação mecânica de dispositivo de fixação interna de ossos dos membros",
            favorito: false
        },
        {
            cid10: "T842",
            descricao: "Complicação mecânica de dispositivo de fixação interna de outros ossos",
            favorito: false
        },
        {
            cid10: "T843",
            descricao: "Complicação mecânica de outros dispositivos, implantes e enxertos ósseos",
            favorito: false
        },
        {
            cid10: "T844",
            descricao: "Complicação mecânica de outros dispositivos, implantes e enxertos ortopédicos internos",
            favorito: false
        },
        {
            cid10: "T845",
            descricao: "Infecção e reação inflamatória devidas à prótese articular interna",
            favorito: false
        },
        {
            cid10: "T846",
            descricao: "Infecção e reação inflamatória devidas a dispositivo de fixação interna [qualquer local]",
            favorito: false
        },
        {
            cid10: "T847",
            descricao: "Infecção e reação inflamatória devidas a outros dispositivos protéticos, implantes e enxertos ortopédicos internos",
            favorito: false
        },
        {
            cid10: "T848",
            descricao: "Outras complicações de dispositivos protéticos, implantes e enxertos ortopédicos internos",
            favorito: false
        },
        {
            cid10: "T849",
            descricao: "Complicação não especificada de dispositivo protético, implante e enxerto ortopédicos internos",
            favorito: false
        },
        {
            cid10: "T850",
            descricao: "Complicação mecânica de shunt ventricular (comunicação) intracraniano",
            favorito: false
        },
        {
            cid10: "T851",
            descricao: "Complicação mecânica de estimulador eletrônico implantado no sistema nervoso",
            favorito: false
        },
        {
            cid10: "T852",
            descricao: "Complicação mecânica de lente intraocular",
            favorito: false
        },
        {
            cid10: "T853",
            descricao: "Complicações mecânicas de outros dispositivos protéticos, implantes e enxertos oculares",
            favorito: false
        },
        {
            cid10: "T854",
            descricao: "Complicação mecânica de prótese e implante mamários",
            favorito: false
        },
        {
            cid10: "T855",
            descricao: "Complicação mecânica de dispositivos protéticos, implante e enxerto gastrointestinais",
            favorito: false
        },
        {
            cid10: "T856",
            descricao: "Complicação mecânica de outros dispositivos protéticos, implantes e enxertos internos especificados",
            favorito: false
        },
        {
            cid10: "T857",
            descricao: "Infecção e reação inflamatória devida a outros dispositivos protéticos, implantes e enxertos internos",
            favorito: false
        },
        {
            cid10: "T858",
            descricao: "Outras complicações de dispositivos protéticos, implantes e enxertos internos não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "T859",
            descricao: "Complicação não especificada de outros dispositivos protéticos, implantes e enxertos internos",
            favorito: false
        },
        {
            cid10: "T860",
            descricao: "Rejeição a transplante de medula óssea",
            favorito: false
        },
        {
            cid10: "T861",
            descricao: "Falência ou rejeição de transplante de rim",
            favorito: false
        },
        {
            cid10: "T862",
            descricao: "Falência ou rejeição de transplante de coração",
            favorito: false
        },
        {
            cid10: "T863",
            descricao: "Falência ou rejeição de transplante duplo de coração e pulmão",
            favorito: false
        },
        {
            cid10: "T864",
            descricao: "Falência ou rejeição de transplante de fígado",
            favorito: false
        },
        {
            cid10: "T868",
            descricao: "Outra insuficiência ou rejeição de outros órgãos ou tecidos transplantados",
            favorito: false
        },
        {
            cid10: "T869",
            descricao: "Falência e rejeição a transplante de órgão ou tecido não especificado",
            favorito: false
        },
        {
            cid10: "T870",
            descricao: "Complicações de reimplante (de parte) de extremidade superior",
            favorito: false
        },
        {
            cid10: "T871",
            descricao: "Complicações de reimplante (de parte) de extremidade inferior",
            favorito: false
        },
        {
            cid10: "T872",
            descricao: "Complicações de outras partes reimplantadas do corpo",
            favorito: false
        },
        {
            cid10: "T873",
            descricao: "Neuroma de coto da amputação",
            favorito: false
        },
        {
            cid10: "T874",
            descricao: "Infecção de coto da amputação",
            favorito: false
        },
        {
            cid10: "T875",
            descricao: "Necrose do coto da amputação",
            favorito: false
        },
        {
            cid10: "T876",
            descricao: "Outras complicações e as não especificadas do coto de amputação",
            favorito: false
        },
        {
            cid10: "T880",
            descricao: "Infecção subseqüente à imunização",
            favorito: false
        },
        {
            cid10: "T881",
            descricao: "Outras complicações subseqüentes à imunização não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "T882",
            descricao: "Choque devido à anestesia",
            favorito: false
        },
        {
            cid10: "T883",
            descricao: "Hipertermia maligna devida à anestesia",
            favorito: false
        },
        {
            cid10: "T884",
            descricao: "Entubação falha ou difícil",
            favorito: false
        },
        {
            cid10: "T885",
            descricao: "Outras complicações de anestesia",
            favorito: false
        },
        {
            cid10: "T886",
            descricao: "Choque anafilático devido a efeito adverso de droga ou medicamento corretos e administrados de maneira apropriada",
            favorito: false
        },
        {
            cid10: "T887",
            descricao: "Efeito adverso não especificado de droga ou medicamento",
            favorito: false
        },
        {
            cid10: "T888",
            descricao: "Outras complicações de cuidados médicos e cirúrgicos especificados não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "T889",
            descricao: "Complicação não especificada de cuidados médicos e cirúrgicos",
            favorito: false
        },
        {
            cid10: "T900",
            descricao: "Seqüelas de traumatismo superficial da cabeça",
            favorito: false
        },
        {
            cid10: "T901",
            descricao: "Seqüelas de ferimento da cabeça",
            favorito: false
        },
        {
            cid10: "T902",
            descricao: "Seqüelas de fratura de crânio e de ossos da face",
            favorito: false
        },
        {
            cid10: "T903",
            descricao: "Seqüelas de traumatismo de nervos cranianos",
            favorito: false
        },
        {
            cid10: "T904",
            descricao: "Seqüelas de traumatismo do olho e da órbita",
            favorito: false
        },
        {
            cid10: "T905",
            descricao: "Seqüelas de traumatismo intracraniano",
            favorito: false
        },
        {
            cid10: "T908",
            descricao: "Seqüelas de outros traumatismos especificados da cabeça",
            favorito: false
        },
        {
            cid10: "T909",
            descricao: "Seqüelas de traumatismo não especificado da cabeça",
            favorito: false
        },
        {
            cid10: "T910",
            descricao: "Seqüelas de traumatismo superficial e ferimento do pescoço e do tronco",
            favorito: false
        },
        {
            cid10: "T911",
            descricao: "Seqüelas de fratura de coluna vertebral",
            favorito: false
        },
        {
            cid10: "T912",
            descricao: "Seqüelas de outra fratura do tórax e da pelve",
            favorito: false
        },
        {
            cid10: "T913",
            descricao: "Seqüelas de traumatismo de medula espinhal",
            favorito: false
        },
        {
            cid10: "T914",
            descricao: "Seqüelas de traumatismo de órgãos intratorácicos",
            favorito: false
        },
        {
            cid10: "T915",
            descricao: "Seqüelas de traumatismo de órgãos intra-abdominais e órgãos pélvicos",
            favorito: false
        },
        {
            cid10: "T918",
            descricao: "Seqüelas de outros traumatismos especificados do pescoço e do tronco",
            favorito: false
        },
        {
            cid10: "T919",
            descricao: "Seqüelas de traumatismo não especificado do pescoço e do tronco",
            favorito: false
        },
        {
            cid10: "T920",
            descricao: "Seqüelas de ferimento do membro superior",
            favorito: false
        },
        {
            cid10: "T921",
            descricao: "Seqüelas de fratura do braço",
            favorito: false
        },
        {
            cid10: "T922",
            descricao: "Seqüelas de fratura ao nível do punho e da mão",
            favorito: false
        },
        {
            cid10: "T923",
            descricao: "Seqüelas de luxação, entorse e distensão do membro superior",
            favorito: false
        },
        {
            cid10: "T924",
            descricao: "Seqüelas de traumatismo de nervo de membro superior",
            favorito: false
        },
        {
            cid10: "T925",
            descricao: "Seqüelas de traumatismo de músculo e tendão do membro superior",
            favorito: false
        },
        {
            cid10: "T926",
            descricao: "Seqüelas de esmagamento e amputação traumática do membro superior",
            favorito: false
        },
        {
            cid10: "T928",
            descricao: "Seqüelas de outros traumatismos especificados do membro superior",
            favorito: false
        },
        {
            cid10: "T929",
            descricao: "Seqüelas de traumatismo não especificado do membro superior",
            favorito: false
        },
        {
            cid10: "T930",
            descricao: "Seqüelas de ferimento do membro inferior",
            favorito: false
        },
        {
            cid10: "T931",
            descricao: "Seqüelas de fratura do fêmur",
            favorito: false
        },
        {
            cid10: "T932",
            descricao: "Seqüelas de outras fraturas do membro inferior",
            favorito: false
        },
        {
            cid10: "T933",
            descricao: "Seqüelas de luxação, entorse e distensão do membro inferior",
            favorito: false
        },
        {
            cid10: "T934",
            descricao: "Seqüelas de traumatismo de nervo do membro inferior",
            favorito: false
        },
        {
            cid10: "T935",
            descricao: "Seqüelas de traumatismo de músculo e tendão do membro inferior",
            favorito: false
        },
        {
            cid10: "T936",
            descricao: "Seqüelas de esmagamento e amputação traumática do membro inferior",
            favorito: false
        },
        {
            cid10: "T938",
            descricao: "Seqüelas de outros traumatismos especificados do membro inferior",
            favorito: false
        },
        {
            cid10: "T939",
            descricao: "Seqüelas de traumatismo não especificado do membro inferior",
            favorito: false
        },
        {
            cid10: "T940",
            descricao: "Seqüelas de traumatismos envolvendo regiões múltiplas do corpo",
            favorito: false
        },
        {
            cid10: "T941",
            descricao: "Seqüelas de traumatismos não especificados por regiões do corpo",
            favorito: false
        },
        {
            cid10: "T950",
            descricao: "Seqüelas de queimadura, corrosão e geladura da cabeça e pescoço",
            favorito: false
        },
        {
            cid10: "T951",
            descricao: "Seqüelas de queimadura, corrosão e geladura do tronco",
            favorito: false
        },
        {
            cid10: "T952",
            descricao: "Seqüelas de queimadura, corrosão e geladura do membro superior",
            favorito: false
        },
        {
            cid10: "T953",
            descricao: "Seqüelas de queimadura, corrosão e geladura do membro inferior",
            favorito: false
        },
        {
            cid10: "T954",
            descricao: "Seqüelas de queimadura e corrosão classificável só de acordo com a extensão da área corporal envolvida",
            favorito: false
        },
        {
            cid10: "T958",
            descricao: "Seqüelas de outra queimadura, corrosões e geladuras especificadas",
            favorito: false
        },
        {
            cid10: "T959",
            descricao: "Seqüelas de queimadura, corrosão e geladura de local não especificado",
            favorito: false
        },
        {
            cid10: "T96",
            descricao: "Seqüelas de intoxicação por drogas, medicamentos e substâncias biológicas",
            favorito: false
        },
        {
            cid10: "T97",
            descricao: "Seqüelas de efeitos tóxicos de substâncias de origem predominantemente não-medicinal",
            favorito: false
        },
        {
            cid10: "T980",
            descricao: "Seqüelas dos efeitos da penetração de corpo estranho através de orifício natural",
            favorito: false
        },
        {
            cid10: "T981",
            descricao: "Seqüelas em outros efeitos de causas externas e os não especificados",
            favorito: false
        },
        {
            cid10: "T982",
            descricao: "Seqüelas de algumas complicações precoces de traumatismos",
            favorito: false
        },
        {
            cid10: "T983",
            descricao: "Seqüelas de complicações dos cuidados médicos e cirúrgicos não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "V010",
            descricao: "Pedestre traumatizado em colisão com um veículo a pedal - acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V011",
            descricao: "Pedestre traumatizado em colisão com um veículo a pedal - acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V019",
            descricao: "Pedestre traumatizado em colisão com um veículo a pedal - acidente não especificado se de trânsito ou não de trânsito",
            favorito: false
        },
        {
            cid10: "V020",
            descricao: "Pedestre traumatizado em colisão com um veículo a motor de duas ou três rodas - acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V021",
            descricao: "Pedestre traumatizado em colisão com um veículo a motor de duas ou três rodas - acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V029",
            descricao: "Pedestre traumatizado em colisão com um veículo a motor de duas ou três rodas - acidente não especificado se de trânsito ou não de trânsito",
            favorito: false
        },
        {
            cid10: "V030",
            descricao: "Pedestre traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V031",
            descricao: "Pedestre traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V039",
            descricao: "Pedestre traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - acidente não-de-trânsito não especificado se de trânsito ou não de trânsito",
            favorito: false
        },
        {
            cid10: "V040",
            descricao: "Pedestre traumatizado em colisão com um veículo de transporte pesado ou com um ônibus - acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V041",
            descricao: "Pedestre traumatizado em colisão com um veículo de transporte pesado ou com um ônibus - acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V049",
            descricao: "Pedestre traumatizado em colisão com um veículo de transporte pesado ou com um ônibus - acidente não especificado se de trânsito ou não de trânsito",
            favorito: false
        },
        {
            cid10: "V050",
            descricao: "Pedestre traumatizado em colisão com trem [comboio] ou um veículo ferroviário - acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V051",
            descricao: "Pedestre traumatizado em colisão com trem [comboio] ou um veículo ferroviário - acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V059",
            descricao: "Pedestre traumatizado em colisão com trem [comboio] ou um veículo ferroviário - acidente não especificado se de trânsito ou não de trânsito",
            favorito: false
        },
        {
            cid10: "V060",
            descricao: "Pedestre traumatizado em colisão com outro veículo não-motorizado - acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V061",
            descricao: "Pedestre traumatizado em colisão com outro veículo não-motorizado - acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V069",
            descricao: "Pedestre traumatizado em colisão com outro veículo não-motorizado - acidente não especificado se de trânsito ou não de trânsito",
            favorito: false
        },
        {
            cid10: "V090",
            descricao: "Pedestre traumatizado em um acidente não-de-trânsito, envolvendo outros veículos a motor e os não especificados",
            favorito: false
        },
        {
            cid10: "V091",
            descricao: "Pedestre traumatizado em um acidente não-de-trânsito não especificado",
            favorito: false
        },
        {
            cid10: "V092",
            descricao: "Pedestre traumatizado em um acidente de trânsito envolvendo outros veículos e os não especificados, a motor",
            favorito: false
        },
        {
            cid10: "V093",
            descricao: "Pedestre traumatizado em um acidente de trânsito não especificado",
            favorito: false
        },
        {
            cid10: "V099",
            descricao: "Pedestre traumatizado em um acidente de transporte não especificado",
            favorito: false
        },
        {
            cid10: "V100",
            descricao: "Ciclista traumatizado em colisão com um pedestre ou um animal - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V101",
            descricao: "Ciclista traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V102",
            descricao: "Ciclista traumatizado em colisão com um pedestre ou um animal - ciclista não especificado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V103",
            descricao: "Ciclista traumatizado em colisão com um pedestre ou um animal - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V104",
            descricao: "Ciclista traumatizado em colisão com um pedestre ou um animal - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V105",
            descricao: "Ciclista traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V109",
            descricao: "Ciclista traumatizado em colisão com um pedestre ou um animal - ciclista não especificado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V110",
            descricao: "Ciclista traumatizado em colisão com outro veículo a pedal - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V111",
            descricao: "Ciclista traumatizado em colisão com outro veículo a pedal - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V112",
            descricao: "Ciclista traumatizado em colisão com outro veículo a pedal - ciclista não especificado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V113",
            descricao: "Ciclista traumatizado em colisão com outro veículo a pedal - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V114",
            descricao: "Ciclista traumatizado em colisão com outro veículo a pedal - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V115",
            descricao: "Ciclista traumatizado em colisão com outro veículo a pedal - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V119",
            descricao: "Ciclista traumatizado em colisão com outro veículo a pedal - ciclista não especificado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V120",
            descricao: "Ciclista traumatizado em colisão com um veículo a motor de duas ou três rodas - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V121",
            descricao: "Ciclista traumatizado em colisão com um veículo a motor de duas ou três rodas - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V122",
            descricao: "Ciclista traumatizado em colisão com um veículo a motor de duas ou três rodas - ciclista não especificado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V123",
            descricao: "Ciclista traumatizado em colisão com um veículo a motor de duas ou três rodas - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V124",
            descricao: "Ciclista traumatizado em colisão com um veículo a motor de duas ou três rodas - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V125",
            descricao: "Ciclista traumatizado em colisão com um veículo a motor de duas ou três rodas - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V129",
            descricao: "Ciclista traumatizado em colisão com um veículo a motor de duas ou três rodas - ciclista não especificado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V130",
            descricao: "Ciclista traumatizado em colisão com um automóvel, pick up ou caminhonete - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V131",
            descricao: "Ciclista traumatizado em colisão com um automóvel, pick up  ou caminhonete - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V132",
            descricao: "Ciclista traumatizado em colisão com um automóvel, pick up ou caminhonete - ciclista não especificado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V133",
            descricao: "Ciclista traumatizado em colisão com um automóvel, pick up ou caminhonete - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V134",
            descricao: "Ciclista traumatizado em colisão com um automóvel, pick up ou caminhonete - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V135",
            descricao: "Ciclista traumatizado em colisão com um automóvel, pick up ou caminhonete - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V139",
            descricao: "Ciclista traumatizado em colisão com um automóvel, pick up ou caminhonete - ciclista não especificado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V140",
            descricao: "Ciclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V141",
            descricao: "Ciclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V142",
            descricao: "Ciclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - ciclista não especificado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V143",
            descricao: "Ciclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V144",
            descricao: "Ciclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V145",
            descricao: "Ciclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V149",
            descricao: "Ciclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - ciclista não especificado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V150",
            descricao: "Ciclista traumatizado em colisão com um trem ou um veículo ferroviário - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V151",
            descricao: "Ciclista traumatizado em colisão com um trem ou um veículo ferroviário - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V152",
            descricao: "Ciclista traumatizado em colisão com um trem ou um veículo ferroviário - ciclista não especificado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V153",
            descricao: "Ciclista traumatizado em colisão com um trem ou um veículo ferroviário - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V154",
            descricao: "Ciclista traumatizado em colisão com um trem ou um veículo ferroviário - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V155",
            descricao: "Ciclista traumatizado em colisão com um trem ou um veículo ferroviário - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V159",
            descricao: "Ciclista traumatizado em colisão com um trem ou um veículo ferroviário - ciclista não especificado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V160",
            descricao: "Ciclista traumatizado em colisão com outro veículo não-motorizado - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V161",
            descricao: "Ciclista traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V162",
            descricao: "Ciclista traumatizado em colisão com outro veículo não-motorizado - ciclista não especificado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V163",
            descricao: "Ciclista traumatizado em colisão com outro veículo não-motorizado - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V164",
            descricao: "Ciclista traumatizado em colisão com outro veículo não-motorizado - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V165",
            descricao: "Ciclista traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V169",
            descricao: "Ciclista traumatizado em colisão com outro veículo não-motorizado - ciclista não especificado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V170",
            descricao: "Ciclista traumatizado em colisão com um objeto fixo ou parado - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V171",
            descricao: "Ciclista traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V172",
            descricao: "Ciclista traumatizado em colisão com um objeto fixo ou parado - ciclista não especificado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V173",
            descricao: "Ciclista traumatizado em colisão com um objeto fixo ou parado - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V174",
            descricao: "Ciclista traumatizado em colisão com um objeto fixo ou parado - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V175",
            descricao: "Ciclista traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V179",
            descricao: "Ciclista traumatizado em colisão com um objeto fixo ou parado - ciclista não especificado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V180",
            descricao: "Ciclista traumatizado em um acidente de transporte sem colisão - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V181",
            descricao: "Ciclista traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V182",
            descricao: "Ciclista traumatizado em um acidente de transporte sem colisão - ciclista não especificado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V183",
            descricao: "Ciclista traumatizado em um acidente de transporte sem colisão - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V184",
            descricao: "Ciclista traumatizado em um acidente de transporte sem colisão - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V185",
            descricao: "Ciclista traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V189",
            descricao: "Ciclista traumatizado em um acidente de transporte sem colisão - ciclista não especificado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V190",
            descricao: "Condutor traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V191",
            descricao: "Passageiro traumatizado em colisão com outro veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V192",
            descricao: "Ciclista não especificado traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V193",
            descricao: "Ciclista [qualquer] traumatizado em um acidente não especificado e não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V194",
            descricao: "Condutor traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V195",
            descricao: "Passageiro traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V196",
            descricao: "Ciclista não especificado traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V198",
            descricao: "Ciclista [qualquer] traumatizado em outros acidentes de transporte especificados",
            favorito: false
        },
        {
            cid10: "V199",
            descricao: "Ciclista [qualquer] traumatizado em um acidente de trânsito não especificado",
            favorito: false
        },
        {
            cid10: "V200",
            descricao: "Motociclista traumatizado em colisão com um pedestre ou um animal - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V201",
            descricao: "Motociclista traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V202",
            descricao: "Motociclista traumatizado em colisão com um pedestre ou um animal - motociclista não especificado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V203",
            descricao: "Motociclista traumatizado em colisão com um pedestre ou um animal - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V204",
            descricao: "Motociclista traumatizado em colisão com um pedestre ou um animal - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V205",
            descricao: "Motociclista traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V209",
            descricao: "Motociclista traumatizado em colisão com um pedestre ou um animal - motociclista não especificado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V210",
            descricao: "Motociclista traumatizado em colisão com um veículo a pedal - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V211",
            descricao: "Motociclista traumatizado em colisão com um veículo a pedal - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V212",
            descricao: "Motociclista traumatizado em colisão com um veículo a pedal - motociclista não especificado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V213",
            descricao: "Motociclista traumatizado em colisão com um veículo a pedal - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V214",
            descricao: "Motociclista traumatizado em colisão com um veículo a pedal - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V215",
            descricao: "Motociclista traumatizado em colisão com um veículo a pedal - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V219",
            descricao: "Motociclista traumatizado em colisão com um veículo a pedal - motociclista não especificado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V220",
            descricao: "Motociclista traumatizado em colisão com um veículo a motor de duas ou três rodas - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V221",
            descricao: "Motociclista traumatizado em colisão com um veículo a motor de duas ou três rodas - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V222",
            descricao: "Motociclista traumatizado em colisão com um veículo a motor de duas ou três rodas - motociclista não especificado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V223",
            descricao: "Motociclista traumatizado em colisão com um veículo a motor de duas ou três rodas - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V224",
            descricao: "Motociclista traumatizado em colisão com um veículo a motor de duas ou três rodas - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V225",
            descricao: "Motociclista traumatizado em colisão com um veículo a motor de duas ou três rodas - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V229",
            descricao: "Motociclista traumatizado em colisão com um veículo a motor de duas ou três rodas - motociclista não especificado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V230",
            descricao: "Motociclista traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V231",
            descricao: "Motociclista traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V232",
            descricao: "Motociclista traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - motociclista não especificado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V233",
            descricao: "Motociclista traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V234",
            descricao: "Motociclista traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - condutor traumatizado em um acidente de trânsit",
            favorito: false
        },
        {
            cid10: "V235",
            descricao: "Motociclista traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V239",
            descricao: "Motociclista traumatizado em colisão com um automóvel [carro], pick up ou caminhonete - motociclista não especificado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V240",
            descricao: "Motociclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V241",
            descricao: "Motociclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V242",
            descricao: "Motociclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - motociclista não especificado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V243",
            descricao: "Motociclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V244",
            descricao: "Motociclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V245",
            descricao: "Motociclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V249",
            descricao: "Motociclista traumatizado em colisão com um veículo de transporte pesado ou um ônibus - motociclista não especificado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V250",
            descricao: "Motociclista traumatizado em colisão com um trem ou um veículo ferroviário - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V251",
            descricao: "Motociclista traumatizado em colisão com um trem ou um veículo ferroviário - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V252",
            descricao: "Motociclista traumatizado em colisão com um trem ou um veículo ferroviário - motociclista não especificado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V253",
            descricao: "Motociclista traumatizado em colisão com um trem ou um veículo ferroviário - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V254",
            descricao: "Motociclista traumatizado em colisão com um trem ou um veículo ferroviário - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V255",
            descricao: "Motociclista traumatizado em colisão com um trem ou um veículo ferroviário - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V259",
            descricao: "Motociclista traumatizado em colisão com um trem ou um veículo ferroviário - motociclista não especificado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V260",
            descricao: "Motociclista traumatizado em colisão com outro veículo não-motorizado - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V261",
            descricao: "Motociclista traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V262",
            descricao: "Motociclista traumatizado em colisão com outro veículo não-motorizado - motociclista não especificado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V263",
            descricao: "Motociclista traumatizado em colisão com outro veículo não-motorizado - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V264",
            descricao: "Motociclista traumatizado em colisão com outro veículo não-motorizado - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V265",
            descricao: "Motociclista traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V269",
            descricao: "Motociclista traumatizado em colisão com outro veículo não-motorizado - motociclista não especificado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V270",
            descricao: "Motociclista traumatizado em colisão com um objeto fixo ou parado - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V271",
            descricao: "Motociclista traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V272",
            descricao: "Motociclista traumatizado em colisão com um objeto fixo ou parado - motociclista não especificado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V273",
            descricao: "Motociclista traumatizado em colisão com um objeto fixo ou parado - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V274",
            descricao: "Motociclista traumatizado em colisão com um objeto fixo ou parado - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V275",
            descricao: "Motociclista traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V279",
            descricao: "Motociclista traumatizado em colisão com um objeto fixo ou parado - motociclista não especificado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V280",
            descricao: "Motociclista traumatizado em um acidente de transporte sem colisão - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V281",
            descricao: "Motociclista traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V282",
            descricao: "Motociclista traumatizado em um acidente de transporte sem colisão - motociclista não especificado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V283",
            descricao: "Motociclista traumatizado em um acidente de transporte sem colisão - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V284",
            descricao: "Motociclista traumatizado em um acidente de transporte sem colisão - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V285",
            descricao: "Motociclista traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V289",
            descricao: "Motociclista traumatizado em um acidente de transporte sem colisão - motociclista não especificado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V290",
            descricao: "Condutor traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V291",
            descricao: "Passageiro traumatizado em colisão com outros veículos com veículos não especificados, a motor, em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V292",
            descricao: "Motociclista não especificado traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V293",
            descricao: "Motociclista [qualquer] traumatizado em um acidente não especificado, não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V294",
            descricao: "Condutor traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V295",
            descricao: "Passageiro traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V296",
            descricao: "Motociclista não especificado traumatizado em colisão com outros veículos e com veículos não especificados, a motor em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V298",
            descricao: "Motociclista [qualquer] traumatizado em outros acidentes de transporte especificados",
            favorito: false
        },
        {
            cid10: "V299",
            descricao: "Motociclista [qualquer] traumatizado em um acidente de trânsito não especificado",
            favorito: false
        },
        {
            cid10: "V300",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um pedestre ou um animal - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V301",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V302",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um pedestre ou um animal - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V303",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um pedestre ou um animal - ocupante não especificado de um triciclo motorizado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V304",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um pedestre ou um animal - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V305",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um pedestre ou um animal - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V306",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V307",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um pedestre ou um animal - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V309",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um pedestre ou um animal - ocupante não especificado de um triciclo motorizado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V310",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo a pedal - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V311",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo a pedal - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V312",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo a pedal - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V313",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo a pedal - ocupante não especificado de um triciclo motorizado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V314",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo a pedal - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V315",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo a pedal - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V316",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo a pedal - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V317",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo a pedal - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V319",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo a pedal - ocupante não especificado de um triciclo motorizado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V320",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo a motor de duas ou três rodas - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V321",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo a motor de duas ou três rodas - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V322",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo a motor de duas ou três rodas - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V323",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo a motor de duas ou três rodas - ocupante não especificado de um triciclo motorizado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V324",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo a motor de duas ou três rodas - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V325",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo a motor de duas ou três rodas - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V326",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo a motor de duas ou três rodas - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V327",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo a motor de duas ou três rodas - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V329",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo a motor de duas ou três rodas - ocupante não especificado de um triciclo motorizado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V330",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um automóvel, pick up ou caminhonete - condutor traumatizado em acidente não-de-trânsito pick up  ou caminhonete - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V331",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um automóvel, pick up ou caminhonete - passageiro traumatizado em acidente não-de-trânsito pick up  ou caminhonete - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V332",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um automóvel, pick up ou caminhonete - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito pick up ou caminhonete - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V333",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um automóvel, pick up ou caminhonete - ocupante não especificado de um triciclo motorizado traumatizado em acidente não-de-trânsito pick up  ou caminhonete - ocupante não especificado de um triciclo motorizado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V334",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um automóvel, pick up ou caminhonete - pessoa traumatizada ao subir ou descer do veículo pick up",
            favorito: false
        },
        {
            cid10: "V335",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um automóvel, pick up  ou caminhonete - condutor traumatizado em um acidente de trânsito pick up  ou caminhonete - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V336",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um automóvel, pick up ou caminhonete - passageiro traumatizado em um acidente de trânsito pick up  ou caminhonete - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V337",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um automóvel, pick up ou caminhonete - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito ou caminhonete - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V339",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um automóvel, pick up  ou caminhonete - ocupante não especificado de um triciclo motorizado traumatizado em um acidente de trânsito ou caminhonete - ocupante não especificado de um triciclo motorizado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V340",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V341",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V342",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V343",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo de transporte pesado ou um ônibus - ocupante não especificado de um triciclo motorizado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V344",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V345",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V346",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V347",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V349",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um veículo de transporte pesado ou um ônibus - ocupante não especificado de um triciclo motorizado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V350",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V351",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V352",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V353",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - ocupante não especificado de um triciclo motorizado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V354",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V355",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V356",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V357",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V359",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - ocupante não especificado de um triciclo motorizado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V360",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo não-motorizado - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V361",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V362",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo não-motorizado - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V363",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo não-motorizado - ocupante não especificado de um triciclo motorizado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V364",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo não-motorizado - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V365",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo não-motorizado - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V366",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V367",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo não-motorizado - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V369",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com outro veículo não-motorizado - ocupante não especificado de um triciclo motorizado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V370",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um objeto fixo ou parado - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V371",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V372",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um objeto fixo ou parado - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V373",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um objeto fixo ou parado - ocupante não especificado de um triciclo motorizado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V374",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um objeto fixo ou parado - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V375",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um objeto fixo ou parado - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V376",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V377",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um objeto fixo ou parado - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V379",
            descricao: "Ocupante de um triciclo motorizado traumatizado em colisão com um objeto fixo ou parado - ocupante não especificado de um triciclo motorizado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V380",
            descricao: "Ocupante de um triciclo motorizado traumatizado em um acidente de transporte sem colisão - condutor traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V381",
            descricao: "Ocupante de um triciclo motorizado traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V382",
            descricao: "Ocupante de um triciclo motorizado traumatizado em um acidente de transporte sem colisão - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V383",
            descricao: "Ocupante de um triciclo motorizado traumatizado em um acidente de transporte sem colisão - ocupante não especificado de um triciclo motorizado traumatizado em acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V384",
            descricao: "Ocupante de um triciclo motorizado traumatizado em um acidente de transporte sem colisão - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V385",
            descricao: "Ocupante de um triciclo motorizado traumatizado em um acidente de transporte sem colisão - condutor traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V386",
            descricao: "Ocupante de um triciclo motorizado traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V387",
            descricao: "Ocupante de um triciclo motorizado traumatizado em um acidente de transporte sem colisão - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V389",
            descricao: "Ocupante de um triciclo motorizado traumatizado em um acidente de transporte sem colisão - ocupante não especificado de um triciclo motorizado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V390",
            descricao: "Condutor traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V391",
            descricao: "Passageiro traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V392",
            descricao: "Ocupante não especificado de um triciclo motorizado traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V393",
            descricao: "Ocupante [qualquer] de um triciclo motorizado traumatizado em um acidente não especificado e não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V394",
            descricao: "Condutor traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V395",
            descricao: "Passageiro traumatizado em colisão outros veículos e com veículos não especificados, a motor, em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V396",
            descricao: "Ocupante não especificado de um triciclo motorizado traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V398",
            descricao: "Ocupante [qualquer] de um triciclo motorizado traumatizado em outros acidentes de trânsito especificados",
            favorito: false
        },
        {
            cid10: "V399",
            descricao: "Ocupante [qualquer] de um triciclo motorizado, traumatizado em acidente de trânsito não especificado",
            favorito: false
        },
        {
            cid10: "V400",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um pedestre ou um animal - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V401",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V402",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um pedestre ou um animal - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V403",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um pedestre ou um animal - ocupante não especificado de um automóvel [carro] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V404",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um pedestre ou um animal - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V405",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um pedestre ou um animal - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V406",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V407",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um pedestre ou um animal - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V409",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um pedestre ou um animal - ocupante não especificado de um automóvel [carro] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V410",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo a pedal - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V411",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo a pedal - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V412",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo a pedal - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V413",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo a pedal - ocupante não especificado de um automóvel [carro] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V414",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo a pedal - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V415",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo a pedal - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V416",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo a pedal - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V417",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo a pedal - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V419",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo a pedal - ocupante não especificado de um automóvel [carro] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V420",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo a motor de duas ou três rodas - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V421",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo a motor de duas ou três rodas - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V422",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo a motor de duas ou três rodas - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V423",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo a motor de duas ou três rodas - ocupante não especificado de um automóvel [carro] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V424",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo a motor de duas ou três rodas - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V425",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo a motor de duas ou três rodas - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V426",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo a motor de duas ou três rodas - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V427",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo a motor de duas ou três rodas - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V429",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo a motor de duas ou três rodas - ocupante não especificado de um automóvel [carro] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V430",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um automóvel [carro], pick up  ou caminhonete - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V431",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um automóvel [carro], pick up  ou caminhonete - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V432",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um automóvel [carro], pick up  ou caminhonete - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V433",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um automóvel [carro], pick up  ou caminhonete - ocupante não especificado de um automóvel [carro] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V434",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um automóvel [carro], pick up  ou caminhonete - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V435",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um automóvel [carro], pick up  ou caminhonete - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V436",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um automóvel [carro], pick up  ou caminhonete - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V437",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um automóvel [carro], pick up  ou caminhonete - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V439",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um automóvel [carro], pick up  ou caminhonete - ocupante não especificado de um automóvel [carro] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V440",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V441",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V442",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V443",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo de transporte pesado ou um ônibus - ocupante não especificado de um automóvel [carro] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V444",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V445",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V446",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V447",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V449",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um veículo de transporte pesado ou um ônibus - ocupante não especificado de um automóvel [carro] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V450",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V451",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V452",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V453",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - ocupante não especificado de um automóvel [carro] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V454",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V455",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V456",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V457",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V459",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - ocupante não especificado de um automóvel [carro] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V460",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo não-motorizado - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V461",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V462",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo não-motorizado - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V463",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo não-motorizado - ocupante não especificado de um automóvel [carro] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V464",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo não-motorizado - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V465",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo não-motorizado - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V466",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V467",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo não-motorizado - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V469",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com outro veículo não-motorizado - ocupante não especificado de um automóvel [carro] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V470",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um objeto fixo ou parado - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V471",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V472",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um objeto fixo ou parado - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V473",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um objeto fixo ou parado - ocupante não especificado de um automóvel [carro] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V474",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um objeto fixo ou parado - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V475",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um objeto fixo ou parado - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V476",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V477",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um objeto fixo ou parado - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V479",
            descricao: "Ocupante de um automóvel [carro] traumatizado em colisão com um objeto fixo ou parado - ocupante não especificado de um automóvel [carro] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V480",
            descricao: "Ocupante de um automóvel [carro] traumatizado em um acidente de transporte sem colisão - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V481",
            descricao: "Ocupante de um automóvel [carro] traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V482",
            descricao: "Ocupante de um automóvel [carro] traumatizado em um acidente de transporte sem colisão - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V483",
            descricao: "Ocupante de um automóvel [carro] traumatizado em um acidente de transporte sem colisão - ocupante não especificado de um automóvel [carro] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V484",
            descricao: "Ocupante de um automóvel [carro] traumatizado em um acidente de transporte sem colisão - pessoa traumatizada ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V485",
            descricao: "Ocupante de um automóvel [carro] traumatizado em um acidente de transporte sem colisão - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V486",
            descricao: "Ocupante de um automóvel [carro] traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V487",
            descricao: "Ocupante de um automóvel [carro] traumatizado em um acidente de transporte sem colisão - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V489",
            descricao: "Ocupante de um automóvel [carro] traumatizado em um acidente de transporte sem colisão - ocupante não especificado de um automóvel [carro] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V490",
            descricao: "Condutor [motorista] traumatizado em colisão com outros veículos e com veículos não especificados, a motor em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V491",
            descricao: "Passageiro traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V492",
            descricao: "Ocupante não especificado de um automóvel [carro] traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V493",
            descricao: "Ocupante [qualquer] de um automóvel [carro] traumatizado em um acidente não especificado e não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V494",
            descricao: "Condutor [motorista] traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V495",
            descricao: "Passageiro traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V496",
            descricao: "Ocupante não especificado de um automóvel [carro] traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V498",
            descricao: "Ocupante [qualquer] de um automóvel [carro] traumatizado em outros acidentes de transporte especificados",
            favorito: false
        },
        {
            cid10: "V499",
            descricao: "Ocupante [qualquer] de um automóvel [carro], traumatizado em um acidente de trânsito não especificado",
            favorito: false
        },
        {
            cid10: "V500",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um pedestre ou um animal - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V501",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V502",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um pedestre ou um animal - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V503",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um pedestre ou um animal - ocupante não especificado de uma caminhonete traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V504",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um pedestre ou um animal - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V505",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um pedestre ou um animal - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V506",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V507",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um pedestre ou um animal - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V509",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um pedestre ou um animal - ocupante não especificado de uma caminhonete traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V510",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um veículo a pedal - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V511",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um veículo a pedal - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V512",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um veículo a pedal - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V513",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um veículo a pedal - ocupante não especificado de uma caminhonete traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V514",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um veículo a pedal - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V515",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um veículo a pedal - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V516",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um veículo a pedal - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V517",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um veículo a pedal - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V519",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um veículo a pedal - ocupante não especificado de uma caminhonete traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V520",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com veículo a motor de duas ou três rodas - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V521",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com veículo a motor de duas ou três rodas - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V522",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com veículo a motor de duas ou três rodas - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V523",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com veículo a motor de duas ou três rodas - ocupante não especificado de uma caminhonete traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V524",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com veículo a motor de duas ou três rodas - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V525",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com veículo a motor de duas ou três rodas - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V526",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com veículo a motor de duas ou três rodas - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V527",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com veículo a motor de duas ou três rodas - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V529",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com veículo a motor de duas ou três rodas - ocupante não especificado de uma caminhonete traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V530",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um automóvel [carro] ou uma caminhonete - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V531",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um automóvel [carro] ou uma caminhonete - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V532",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um automóvel [carro] ou uma caminhonete - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V533",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um automóvel [carro] ou uma caminhonete - ocupante não especificado de uma caminhonete traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V534",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um automóvel [carro] ou uma caminhonete - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V535",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um automóvel [carro] ou uma caminhonete - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V536",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um automóvel [carro] ou uma caminhonete - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V537",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um automóvel [carro] ou uma caminhonete - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V539",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um automóvel [carro] ou uma caminhonete - ocupante não especificado de uma caminhonete traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V540",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V541",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V542",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V543",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um veículo de transporte pesado ou um ônibus - ocupante não especificado de uma caminhonete traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V544",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V545",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V546",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V547",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V549",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um veículo de transporte pesado ou um ônibus - ocupante não especificado de uma caminhonete traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V550",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um trem [comboio] ou veículo ferroviário - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V551",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um trem [comboio] ou veículo ferroviário - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V552",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um trem [comboio] ou veículo ferroviário - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V553",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um trem [comboio] ou veículo ferroviário - ocupante não especificado de uma caminhonete traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V554",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um trem [comboio] ou veículo ferroviário - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V555",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um trem [comboio] ou veículo ferroviário - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V556",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um trem [comboio] ou veículo ferroviário - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V557",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um trem [comboio] ou veículo ferroviário - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V559",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um trem [comboio] ou veículo ferroviário - ocupante não especificado de uma caminhonete traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V560",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com outro veículo não-motorizado - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V561",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V562",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com outro veículo não-motorizado - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V563",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com outro veículo não-motorizado - ocupante não especificado de uma caminhonete traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V564",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com outro veículo não-motorizado - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V565",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com outro veículo não-motorizado - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V566",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V567",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com outro veículo não-motorizado - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V569",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com outro veículo não-motorizado - ocupante não especificado de uma caminhonete traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V570",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um objeto fixo ou parado - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V571",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V572",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um objeto fixo ou parado - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V573",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um objeto fixo ou parado - ocupante não especificado de uma caminhonete traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V574",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um objeto fixo ou parado - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V575",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um objeto fixo ou parado - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V576",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V577",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um objeto fixo ou parado - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V579",
            descricao: "Ocupante de uma caminhonete traumatizado em colisão com um objeto fixo ou parado - ocupante não especificado de uma caminhonete traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V580",
            descricao: "Ocupante de uma caminhonete traumatizado em um acidente de transporte sem colisão - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V581",
            descricao: "Ocupante de uma caminhonete traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V582",
            descricao: "Ocupante de uma caminhonete traumatizado em um acidente de transporte sem colisão - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V583",
            descricao: "Ocupante de uma caminhonete traumatizado em um acidente de transporte sem colisão - ocupante não especificado de uma caminhonete traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V584",
            descricao: "Ocupante de uma caminhonete traumatizado em um acidente de transporte sem colisão - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V585",
            descricao: "Ocupante de uma caminhonete traumatizado em um acidente de transporte sem colisão - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V586",
            descricao: "Ocupante de uma caminhonete traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V587",
            descricao: "Ocupante de uma caminhonete traumatizado em um acidente de transporte sem colisão - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V589",
            descricao: "Ocupante de uma caminhonete traumatizado em um acidente de transporte sem colisão - ocupante não especificado de uma caminhonete traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V590",
            descricao: "Condutor [motorista] traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V591",
            descricao: "Passageiro traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V592",
            descricao: "Ocupante não especificado de uma caminhonete traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V593",
            descricao: "Ocupante [qualquer] de uma caminhonete traumatizado em um acidente não especificado e não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V594",
            descricao: "Condutor [motorista] traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V595",
            descricao: "Passageiro de uma caminhonete traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V596",
            descricao: "Ocupante não especificado de uma caminhonete traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V598",
            descricao: "Ocupante [qualquer] de uma caminhonete traumatizado em outros acidentes de transporte especificados",
            favorito: false
        },
        {
            cid10: "V599",
            descricao: "Ocupante [qualquer] de uma caminhonete traumatizado em um acidente de trânsito não especificado",
            favorito: false
        },
        {
            cid10: "V600",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um pedestre ou um animal - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V601",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V602",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um pedestre ou um animal - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V603",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um pedestre ou um animal - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V604",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um pedestre ou um animal - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V605",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um pedestre ou um animal - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V606",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V607",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um pedestre ou um animal - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V609",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um pedestre ou um animal - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V610",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a pedal - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V611",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a pedal - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V612",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a pedal - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V613",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a pedal - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V614",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a pedal - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V615",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a pedal - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V616",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a pedal - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V617",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a pedal - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V619",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a pedal - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V620",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a motor de duas ou três rodas - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V621",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a motor de duas ou três rodas - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V622",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a motor de duas ou três rodas - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V623",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a motor de duas ou três rodas - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V624",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a motor de duas ou três rodas - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V625",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a motor de duas ou três rodas - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V626",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a motor de duas ou três rodas - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V627",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a motor de duas ou três rodas - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V629",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um veículo a motor de duas ou três rodas - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V630",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um automóvel [carro] ou uma caminhonete - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V631",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um automóvel [carro] ou uma caminhonete - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V632",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um automóvel [carro] ou uma caminhonete - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V633",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um automóvel [carro] ou uma caminhonete - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V634",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um automóvel [carro] ou uma caminhonete - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V635",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um automóvel [carro] ou uma caminhonete - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V636",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um automóvel [carro] ou uma caminhonete - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V637",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um automóvel [carro] ou uma caminhonete - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V639",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um automóvel [carro] ou uma caminhonete - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V640",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo de transporte pesado ou um ônibus - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V641",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo de transporte pesado ou um ônibus - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V642",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo de transporte pesado ou um ônibus - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V643",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo de transporte pesado ou um ônibus - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V644",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo de transporte pesado ou um ônibus - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V645",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo de transporte pesado ou um ônibus - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V646",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo de transporte pesado ou um ônibus - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V647",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo de transporte pesado ou um ônibus - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V649",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo de transporte pesado ou um ônibus - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V650",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V651",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V652",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V653",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V654",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V655",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V656",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V657",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V659",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V660",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo não-motorizado - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V661",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo não-motorizado - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V662",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo não-motorizado - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V663",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo não-motorizado - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V664",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo não-motorizado - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V665",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo não-motorizado - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V666",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo não-motorizado - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V667",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo não-motorizado - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V669",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um outro veículo não-motorizado - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V670",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um objeto fixo ou parado - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V671",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V672",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um objeto fixo ou parado - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V673",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um objeto fixo ou parado - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V674",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um objeto fixo ou parado - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V675",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um objeto fixo ou parado - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V676",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V677",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um objeto fixo ou parado - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V679",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em colisão com um objeto fixo ou parado - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V680",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em um acidente de transporte sem colisão - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V681",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V682",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em um acidente de transporte sem colisão - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V683",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em um acidente de transporte sem colisão - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V684",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em um acidente de transporte sem colisão - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V685",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em um acidente de transporte sem colisão - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V686",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V687",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em um acidente de transporte sem colisão - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V689",
            descricao: "Ocupante de um veículo de transporte pesado traumatizado em um acidente de transporte sem colisão - ocupante não especificado de um veículo de transporte pesado traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V690",
            descricao: "Condutor [motorista] traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V691",
            descricao: "Passageiro traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V692",
            descricao: "Ocupante não especificado de um veículo de transporte pesado traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V693",
            descricao: "Ocupante [qualquer] de um veículo de transporte pesado traumatizado em um acidente não especificado e não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V694",
            descricao: "Condutor [motorista] traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V695",
            descricao: "Passageiro traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V696",
            descricao: "Ocupante não especificado de um veículo de transporte pesado traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V698",
            descricao: "Ocupante [qualquer] de um veículo de transporte pesado traumatizado em outros acidentes de transporte especificados",
            favorito: false
        },
        {
            cid10: "V699",
            descricao: "Ocupante [qualquer] de um veículo de transporte pesado traumatizado em um acidente de trânsito não especificado",
            favorito: false
        },
        {
            cid10: "V700",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um pedestre ou um animal - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V701",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V702",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um pedestre ou um animal - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V703",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um pedestre ou um animal - ocupante não especificado de um ônibus traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V704",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um pedestre ou um animal - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V705",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um pedestre ou um animal - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V706",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um pedestre ou um animal - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V707",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um pedestre ou um animal - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V709",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um pedestre ou um animal - ocupante não especificado de um ônibus traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V710",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um veículo a pedal - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V711",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um veículo a pedal - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V712",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um veículo a pedal - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V713",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um veículo a pedal - ocupante não especificado de um ônibus traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V714",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um veículo a pedal - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V715",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um veículo a pedal - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V716",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um veículo a pedal - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V717",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um veículo a pedal - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V719",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um veículo a pedal - ocupante não especificado de um ônibus traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V720",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um outro veículo a motor de duas ou três rodas - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V721",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um outro veículo a motor de duas ou três rodas - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V722",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um outro veículo a motor de duas ou três rodas - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V723",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um outro veículo a motor de duas ou três rodas - ocupante não especificado de um ônibus traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V724",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um outro veículo a motor de duas ou três rodas - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V725",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um outro veículo a motor de duas ou três rodas - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V726",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um outro veículo a motor de duas ou três rodas - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V727",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um outro veículo a motor de duas ou três rodas - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V729",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um outro veículo a motor de duas ou três rodas - ocupante não especificado de um ônibus traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V730",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um automóvel [carro] ou uma caminhonete - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V731",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um automóvel [carro] ou uma caminhonete - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V732",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um automóvel [carro] ou uma caminhonete - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V733",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um automóvel [carro] ou uma caminhonete - ocupante não especificado de um ônibus traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V734",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um automóvel [carro] ou uma caminhonete - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V735",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um automóvel [carro] ou uma caminhonete - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V736",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um automóvel [carro] ou uma caminhonete - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V737",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um automóvel [carro] ou uma caminhonete - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V739",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um automóvel [carro] ou uma caminhonete - ocupante não especificado de um ônibus traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V740",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V741",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V742",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V743",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um veículo de transporte pesado ou um ônibus - ocupante não especificado de um ônibus traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V744",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V745",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um veículo de transporte pesado ou um ônibus - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V746",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um veículo de transporte pesado ou um ônibus - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V747",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um veículo de transporte pesado ou um ônibus - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V749",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um veículo de transporte pesado ou um ônibus - ocupante não especificado de um ônibus traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V750",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V751",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V752",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V753",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - ocupante não especificado de um ônibus traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V754",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V755",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V756",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V757",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V759",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um trem [comboio] ou um veículo ferroviário - ocupante não especificado de um ônibus traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V760",
            descricao: "Ocupante de um ônibus traumatizado em colisão com outro veículo não-motorizado - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V761",
            descricao: "Ocupante de um ônibus traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V762",
            descricao: "Ocupante de um ônibus traumatizado em colisão com outro veículo não-motorizado - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V763",
            descricao: "Ocupante de um ônibus traumatizado em colisão com outro veículo não-motorizado - ocupante não especificado de um ônibus traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V764",
            descricao: "Ocupante de um ônibus traumatizado em colisão com outro veículo não-motorizado - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V765",
            descricao: "Ocupante de um ônibus traumatizado em colisão com outro veículo não-motorizado - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V766",
            descricao: "Ocupante de um ônibus traumatizado em colisão com outro veículo não-motorizado - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V767",
            descricao: "Ocupante de um ônibus traumatizado em colisão com outro veículo não-motorizado - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V769",
            descricao: "Ocupante de um ônibus traumatizado em colisão com outro veículo não-motorizado - ocupante não especificado de um ônibus traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V770",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um objeto fixo ou parado - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V771",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V772",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um objeto fixo ou parado - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V773",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um objeto fixo ou parado - ocupante não especificado de um ônibus traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V774",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um objeto fixo ou parado - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V775",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um objeto fixo ou parado - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V776",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um objeto fixo ou parado - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V777",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um objeto fixo ou parado - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V779",
            descricao: "Ocupante de um ônibus traumatizado em colisão com um objeto fixo ou parado - ocupante não especificado de um ônibus traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V780",
            descricao: "Ocupante de um ônibus traumatizado em um acidente de transporte sem colisão - condutor [motorista] traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V781",
            descricao: "Ocupante de um ônibus traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V782",
            descricao: "Ocupante de um ônibus traumatizado em um acidente de transporte sem colisão - pessoa viajando no exterior do veículo traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V783",
            descricao: "Ocupante de um ônibus traumatizado em um acidente de transporte sem colisão - ocupante não especificado de um ônibus traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V784",
            descricao: "Ocupante de um ônibus traumatizado em um acidente de transporte sem colisão - pessoa traumatizado ao subir ou descer do veículo",
            favorito: false
        },
        {
            cid10: "V785",
            descricao: "Ocupante de um ônibus traumatizado em um acidente de transporte sem colisão - condutor [motorista] traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V786",
            descricao: "Ocupante de um ônibus traumatizado em um acidente de transporte sem colisão - passageiro traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V787",
            descricao: "Ocupante de um ônibus traumatizado em um acidente de transporte sem colisão - pessoa viajando no exterior do veículo traumatizada em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V789",
            descricao: "Ocupante de um ônibus traumatizado em um acidente de transporte sem colisão - ocupante não especificado de um ônibus traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V790",
            descricao: "Condutor [motorista] traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V791",
            descricao: "Passageiro traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V792",
            descricao: "Ocupante não especificado de um ônibus traumatizado em colisão com outros veículos e com um veículos não especificados, a motor, em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V793",
            descricao: "Ocupante [qualquer] de um ônibus traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V794",
            descricao: "Condutor [motorista] traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V795",
            descricao: "Passageiro traumatizado em colisão com outros veículos e com veículos não especificados, a motor, em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V796",
            descricao: "Ocupante não especificado de um ônibus traumatizado em colisão com um outros veículos e com um veículos não especificados, a motor, em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V798",
            descricao: "Ocupante [ qualquer] de um ônibus traumatizado em outros acidentes de transporte especificados",
            favorito: false
        },
        {
            cid10: "V799",
            descricao: "Ocupante [qualquer] de um ônibus traumatizado em um acidente de trânsito não especificado",
            favorito: false
        },
        {
            cid10: "V800",
            descricao: "Queda ou ejeção de uma pessoa montada em animal ou ocupante de um veículo a tração animal em um acidente sem colisão",
            favorito: false
        },
        {
            cid10: "V801",
            descricao: "Pessoa montada em animal ou ocupante de um veículo a tração animal traumatizado em colisão com um pedestre ou um animal",
            favorito: false
        },
        {
            cid10: "V802",
            descricao: "Pessoa montada em animal ou ocupante de um veículo a tração animal traumatizado em colisão com um veículo a pedal",
            favorito: false
        },
        {
            cid10: "V803",
            descricao: "Pessoa montada em animal ou ocupante de um veículo a tração animal traumatizado em colisão com um veículo a motor de duas ou três rodas",
            favorito: false
        },
        {
            cid10: "V804",
            descricao: "Pessoa montada em animal ou ocupante de um veículo a tração animal traumatizado em colisão com um automóvel [carro], uma caminhonete, um veículo de transporte pesado ou um ônibus",
            favorito: false
        },
        {
            cid10: "V805",
            descricao: "Pessoa montada em animal ou ocupante de um veículo a tração animal traumatizado em colisão com um outro veículo a motor especificado",
            favorito: false
        },
        {
            cid10: "V806",
            descricao: "Pessoa montada em animal ou ocupante de um veículo a tração animal traumatizado em colisão com um trem [comboio] ou um veículo ferroviário",
            favorito: false
        },
        {
            cid10: "V807",
            descricao: "Pessoa montada em animal ou ocupante de um veículo a tração animal traumatizado em colisão com um outro veículo não-motorizado",
            favorito: false
        },
        {
            cid10: "V808",
            descricao: "Pessoa montada em animal ou ocupante de um veículo a tração animal traumatizado em colisão com um objeto fixo ou parado",
            favorito: false
        },
        {
            cid10: "V809",
            descricao: "Pessoa montada em animal ou ocupante de um veículo a tração animal traumatizado em outros acidentes de transporte e em acidentes de transporte não especificados",
            favorito: false
        },
        {
            cid10: "V810",
            descricao: "Ocupante de um trem [comboio] ou de um veículo ferroviário traumatizado em colisão com um veículo a motor em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V811",
            descricao: "Ocupante de um trem [comboio] ou de um veículo ferroviário traumatizado em colisão com um veículo a motor em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V812",
            descricao: "Ocupante de um trem [comboio] ou de um veículo ferroviário traumatizado em colisão com ou atingido por material rodante",
            favorito: false
        },
        {
            cid10: "V813",
            descricao: "Ocupante de um trem [comboio] ou de um veículo ferroviário traumatizado em colisão com outro objeto",
            favorito: false
        },
        {
            cid10: "V814",
            descricao: "Pessoa traumatizada ao subir ou descer de um trem [comboio] ou de um veículo ferroviário",
            favorito: false
        },
        {
            cid10: "V815",
            descricao: "Ocupante de um trem [comboio] ou de um veículo ferroviário traumatizado devido a uma queda no interior dos mesmos",
            favorito: false
        },
        {
            cid10: "V816",
            descricao: "Ocupante de um trem [comboio] ou de um veículo ferroviário traumatizado devido a uma queda do trem ou do veículo ferroviário",
            favorito: false
        },
        {
            cid10: "V817",
            descricao: "Ocupante de um trem [comboio] ou de um veículo ferroviário traumatizado em razão de um descarrilhamento sem colisão antecedente",
            favorito: false
        },
        {
            cid10: "V818",
            descricao: "Ocupante de um trem [comboio] ou de um veículo ferroviário traumatizado em outro acidente ferroviário especificado",
            favorito: false
        },
        {
            cid10: "V819",
            descricao: "Ocupante de um trem [comboio] ou de um veículo ferroviário traumatizado em um acidente ferroviário não especificado",
            favorito: false
        },
        {
            cid10: "V820",
            descricao: "Ocupante de um bonde [carro elétrico] traumatizado em uma colisão com um veículo a motor em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V821",
            descricao: "Ocupante de um bonde [carro elétrico] traumatizado em uma colisão com um veículo a motor em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V822",
            descricao: "Ocupante de um bonde [carro elétrico] traumatizado em uma colisão com ou atingido por material rodante",
            favorito: false
        },
        {
            cid10: "V823",
            descricao: "Ocupante de um bonde [carro elétrico] traumatizado em uma colisão com outro objeto",
            favorito: false
        },
        {
            cid10: "V824",
            descricao: "Pessoa traumatizada ao subir ou descer de um bonde [carro elétrico]",
            favorito: false
        },
        {
            cid10: "V825",
            descricao: "Ocupante de um bonde [carro elétrico] traumatizado devido a uma queda no interior do mesmo",
            favorito: false
        },
        {
            cid10: "V826",
            descricao: "Ocupante de um bonde [carro elétrico] traumatizado devido a uma queda do mesmo",
            favorito: false
        },
        {
            cid10: "V827",
            descricao: "Ocupante de um bonde [carro elétrico] traumatizado devido a um descarrilhamento sem colisão antecedente",
            favorito: false
        },
        {
            cid10: "V828",
            descricao: "Ocupante de um bonde [carro elétrico] traumatizado em outros acidentes de transporte especificados",
            favorito: false
        },
        {
            cid10: "V829",
            descricao: "Ocupante de um bonde [carro elétrico] traumatizado em um acidente de trânsito não especificado",
            favorito: false
        },
        {
            cid10: "V830",
            descricao: "Condutor [motorista] de um veículo especial a motor usado principalmente em áreas industrias traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V831",
            descricao: "Passageiro de um veículo especial a motor usado principalmente em áreas industriais traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V832",
            descricao: "Pessoa viajando no exterior de um veículo especial a motor usado principalmente em áreas industriais traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V833",
            descricao: "Ocupante não especificado de um veículo especial a motor usado principalmente em áreas industriais traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V834",
            descricao: "Pessoa traumatizada ao subir ou descer de um veículo especial a motor usado principalmente em áreas industriais",
            favorito: false
        },
        {
            cid10: "V835",
            descricao: "Condutor [motorista] de um veículo especial a motor usado principalmente em áreas industrias traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V836",
            descricao: "Passageiro de um veículo especial a motor usado principalmente em áreas industriais traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V837",
            descricao: "Pessoa viajando no exterior de um veículo especial a motor usado principalmente em áreas industriais traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V839",
            descricao: "Ocupante não especificado de um veículo especial a motor usado principalmente em áreas industriais traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V840",
            descricao: "Condutor [motorista] de um veículo especial a motor de uso essencialmente agrícola traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V841",
            descricao: "Passageiro de um veículo especial a motor de uso essencialmente agrícola traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V842",
            descricao: "Pessoa viajando no exterior de um veículo especial a motor de uso essencialmente agrícola traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V843",
            descricao: "Ocupante não especificado de um veículo especial a motor de uso essencialmente agrícola traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V844",
            descricao: "Pessoa traumatizado ao subir ou descer de um veículo especial a motor de uso essencialmente agrícola",
            favorito: false
        },
        {
            cid10: "V845",
            descricao: "Condutor [motorista] de um veículo especial a motor de uso essencialmente agrícola traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V846",
            descricao: "Passageiro de um veículo especial a motor de uso essencialmente agrícola traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V847",
            descricao: "Pessoa viajando no exterior de um veículo especial a motor de uso essencialmente agrícola traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V849",
            descricao: "Ocupante não especificado de um veículo especial a motor de uso essencialmente agrícola traumatizada em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V850",
            descricao: "Condutor [motorista] de um veículo a motor especial de construções traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V851",
            descricao: "Passageiro de um veículo a motor especial de construções traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V852",
            descricao: "Pessoa viajando no exterior de um veículo a motor especial de construções traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V853",
            descricao: "Ocupante não especificado de um veículo a motor especial de construções traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V854",
            descricao: "Pessoa traumatizada ao subir ou descer de um veículo a motor especial de construções",
            favorito: false
        },
        {
            cid10: "V855",
            descricao: "Condutor [motorista] de um veículo a motor especial de construções traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V856",
            descricao: "Passageiro de um veículo a motor especial de construções traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V857",
            descricao: "Pessoa viajando no exterior de um veículo a motor especial de construções traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V859",
            descricao: "Ocupante não especificado de um veículo a motor especial de construções traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V860",
            descricao: "Condutor [motorista] de um veículo especial para qualquer terreno ou de outro veículo a motor projetado essencialmente para uso não em via pública, traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V861",
            descricao: "Passageiro de um veículo especial para qualquer terreno ou de outro veículo a motor projetado essencialmente para uso não em via pública, traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V862",
            descricao: "Pessoa viajando no exterior de um veículo especial para qualquer terreno ou de outro veículo a motor projetado essencialmente para uso não em via pública, traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V863",
            descricao: "Ocupante não especificado de um veículo especial para qualquer terreno ou de outro veículo a motor projetado essencialmente para uso não em via pública traumatizado em um acidente de trânsito",
            favorito: false
        },
        {
            cid10: "V864",
            descricao: "Pessoa traumatizada ao subir ou descer de um veículo especial para qualquer terreno ou de outro veículo a motor projetado essencialmente para uso não em via pública",
            favorito: false
        },
        {
            cid10: "V865",
            descricao: "Condutor [motorista] de um veículo especial para qualquer terreno ou de outro veículo a motor projetado essencialmente para uso não em via pública traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V866",
            descricao: "Passageiro de um veículo especial para qualquer terreno ou de outro veículo a motor projetado essencialmente para uso não em via pública traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V867",
            descricao: "Pessoa viajando no exterior de um veículo especial para qualquer terreno ou de outro veículo a motor projetado essencialmente para uso não em via pública traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V869",
            descricao: "Ocupante não especificado de um veículo especial para qualquer terreno ou de outro veículo a motor projetado essencialmente para uso não em via pública traumatizado em um acidente não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V870",
            descricao: "Pessoa traumatizada em uma colisão entre um automóvel [carro] e um veículo a motor de duas ou três rodas (acidente de trânsito)",
            favorito: false
        },
        {
            cid10: "V871",
            descricao: "Pessoa traumatizada em uma colisão entre outro veículo a motor e um veículo a motor de duas ou três rodas (acidente de trânsito)",
            favorito: false
        },
        {
            cid10: "V872",
            descricao: "Pessoa traumatizada em uma colisão entre um automóvel [carro] e uma caminhonete (acidente de trânsito)",
            favorito: false
        },
        {
            cid10: "V873",
            descricao: "Pessoa traumatizada em uma colisão entre um automóvel [carro] e um ônibus (acidente de trânsito)",
            favorito: false
        },
        {
            cid10: "V874",
            descricao: "Pessoa traumatizada em uma colisão entre um automóvel [carro] e um veículo de transporte pesado (acidente de trânsito)",
            favorito: false
        },
        {
            cid10: "V875",
            descricao: "Pessoa traumatizada em uma colisão entre um veículo de transporte pesado e um ônibus (acidente de trânsito)",
            favorito: false
        },
        {
            cid10: "V876",
            descricao: "Pessoa traumatizada em uma colisão entre um trem ou um veículo ferroviário e um automóvel [carro] (acidente de trânsito)",
            favorito: false
        },
        {
            cid10: "V877",
            descricao: "Pessoa traumatizada em uma colisão entre outros veículos a motor especificados (acidente de trânsito)",
            favorito: false
        },
        {
            cid10: "V878",
            descricao: "Pessoa traumatizada em outros acidentes de transporte especificados com veículo a motor, sem colisão (acidente de trânsito)",
            favorito: false
        },
        {
            cid10: "V879",
            descricao: "Pessoa traumatizada em outros acidentes de transporte especificados (com colisão) (sem colisão) envolvendo um veículo não-motorizado (acidente de trânsito)",
            favorito: false
        },
        {
            cid10: "V880",
            descricao: "Pessoa traumatizada em uma colisão entre um automóvel [carro] e um veículo a motor de duas ou três rodas, não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V881",
            descricao: "Pessoa traumatizada em uma colisão entre outro veículo a motor e um veículo a motor de duas ou três rodas, não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V882",
            descricao: "Pessoa traumatizada em uma colisão entre um automóvel [carro] e uma caminhonete, não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V883",
            descricao: "Pessoa traumatizada em uma colisão entre um automóvel [carro] e um ônibus, não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V884",
            descricao: "Pessoa traumatizada em uma colisão entre um automóvel [carro] e um veículo de transporte pesado, não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V885",
            descricao: "Pessoa traumatizada em uma colisão entre um veículo de transporte pesado e um ônibus, não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V886",
            descricao: "Pessoa traumatizada em uma colisão entre um trem ou um veículo ferroviário e um automóvel [carro], não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V887",
            descricao: "Pessoa traumatizada em uma colisão entre outros veículos a motor especificados, não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V888",
            descricao: "Pessoa traumatizada em outros acidentes de transporte especificados, com um veículo a motor, sem colisão, não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V889",
            descricao: "Pessoa traumatizada em outros acidente de transporte especificados (com colisão) (sem colisão), envolvendo um veículo não-motorizado, não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V890",
            descricao: "Pessoa traumatizada em um acidente com um veículo a motor não especificado, não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V891",
            descricao: "Pessoa traumatizada em um acidente com veículo não-motorizado, não especificado, não-de-trânsito",
            favorito: false
        },
        {
            cid10: "V892",
            descricao: "Pessoa traumatizada em um acidente de trânsito com um veículo a motor não especificado",
            favorito: false
        },
        {
            cid10: "V893",
            descricao: "Pessoa traumatizado em um acidente de trânsito com um veículo não-motorizado, não especificado",
            favorito: false
        },
        {
            cid10: "V899",
            descricao: "Pessoa traumatizada em um acidente com um veículo não especificado",
            favorito: false
        },
        {
            cid10: "V900",
            descricao: "Acidente com embarcação causando afogamento e submersão - navio mercante",
            favorito: false
        },
        {
            cid10: "V901",
            descricao: "Acidente com embarcação causando afogamento e submersão - navio de passageiros",
            favorito: false
        },
        {
            cid10: "V902",
            descricao: "Acidente com embarcação causando afogamento e submersão - barco de pesca",
            favorito: false
        },
        {
            cid10: "V903",
            descricao: "Acidente com embarcação causando afogamento e submersão - outras embarcações a motor",
            favorito: false
        },
        {
            cid10: "V904",
            descricao: "Acidente com embarcação causando afogamento e submersão - barco a vela",
            favorito: false
        },
        {
            cid10: "V905",
            descricao: "Acidente com embarcação causando afogamento e submersão - caiaque ou canoa",
            favorito: false
        },
        {
            cid10: "V906",
            descricao: "Acidente com embarcação causando afogamento e submersão - bote inflável (sem motor)",
            favorito: false
        },
        {
            cid10: "V907",
            descricao: "Acidente com embarcação causando afogamento e submersão - esqui aquático",
            favorito: false
        },
        {
            cid10: "V908",
            descricao: "Acidente com embarcação causando afogamento e submersão - outras embarcações sem motor",
            favorito: false
        },
        {
            cid10: "V909",
            descricao: "Acidente com embarcação causando afogamento e submersão - embarcação não especificada",
            favorito: false
        },
        {
            cid10: "V910",
            descricao: "Acidente com embarcação causando outro tipo de traumatismo - navio mercante",
            favorito: false
        },
        {
            cid10: "V911",
            descricao: "Acidente com embarcação causando outro tipo de traumatismo - navio de passageiros",
            favorito: false
        },
        {
            cid10: "V912",
            descricao: "Acidente com embarcação causando outro tipo de traumatismo - barco de pesca",
            favorito: false
        },
        {
            cid10: "V913",
            descricao: "Acidente com embarcação causando outro tipo de traumatismo - outras embarcações a motor",
            favorito: false
        },
        {
            cid10: "V914",
            descricao: "Acidente com embarcação causando outro tipo de traumatismo - barco a vela",
            favorito: false
        },
        {
            cid10: "V915",
            descricao: "Acidente com embarcação causando outro tipo de traumatismo - caiaque ou canoa",
            favorito: false
        },
        {
            cid10: "V916",
            descricao: "Acidente com embarcação causando outro tipo de traumatismo - bote inflável (sem motor)",
            favorito: false
        },
        {
            cid10: "V917",
            descricao: "Acidente com embarcação causando outro tipo de traumatismo - esqui aquático",
            favorito: false
        },
        {
            cid10: "V918",
            descricao: "Acidente com embarcação causando outro tipo de traumatismo - outras embarcações sem motor",
            favorito: false
        },
        {
            cid10: "V919",
            descricao: "Acidente com embarcação causando outro tipo de traumatismo - embarcação não especificada",
            favorito: false
        },
        {
            cid10: "V920",
            descricao: "Afogamento e submersão relacionados com transporte por água sem acidente com a embarcação - navio mercante",
            favorito: false
        },
        {
            cid10: "V921",
            descricao: "Afogamento e submersão relacionados com transporte por água sem acidente com a embarcação - navio de passageiros",
            favorito: false
        },
        {
            cid10: "V922",
            descricao: "Afogamento e submersão relacionados com transporte por água sem acidente com a embarcação - barco de pesca",
            favorito: false
        },
        {
            cid10: "V923",
            descricao: "Afogamento e submersão relacionados com transporte por água sem acidente com a embarcação - outras embarcações a motor",
            favorito: false
        },
        {
            cid10: "V924",
            descricao: "Afogamento e submersão relacionados com transporte por água sem acidente com a embarcação - barco a vela",
            favorito: false
        },
        {
            cid10: "V925",
            descricao: "Afogamento e submersão relacionados com transporte por água sem acidente com a embarcação - caiaque ou canoa",
            favorito: false
        },
        {
            cid10: "V926",
            descricao: "Afogamento e submersão relacionados com transporte por água sem acidente com a embarcação - bote inflável (sem motor)",
            favorito: false
        },
        {
            cid10: "V927",
            descricao: "Afogamento e submersão relacionados com transporte por água sem acidente com a embarcação - esqui aquático",
            favorito: false
        },
        {
            cid10: "V928",
            descricao: "Afogamento e submersão relacionados com transporte por água sem acidente com a embarcação - outras embarcações sem motor",
            favorito: false
        },
        {
            cid10: "V929",
            descricao: "Afogamento e submersão relacionados com transporte por água sem acidente com a embarcação - embarcação não especificada",
            favorito: false
        },
        {
            cid10: "V930",
            descricao: "Acidente a bordo de uma embarcação, sem acidente da embarcação e não causando afogamento ou submersão - navio mercante",
            favorito: false
        },
        {
            cid10: "V931",
            descricao: "Acidente a bordo de uma embarcação, sem acidente da embarcação e não causando afogamento ou submersão - navio de passageiros",
            favorito: false
        },
        {
            cid10: "V932",
            descricao: "Acidente a bordo de uma embarcação, sem acidente da embarcação e não causando afogamento ou submersão - barco de pesca",
            favorito: false
        },
        {
            cid10: "V933",
            descricao: "Acidente a bordo de uma embarcação, sem acidente da embarcação e não causando afogamento ou submersão - outras embarcações a motor",
            favorito: false
        },
        {
            cid10: "V934",
            descricao: "Acidente a bordo de uma embarcação, sem acidente da embarcação e não causando afogamento ou submersão - barco a vela",
            favorito: false
        },
        {
            cid10: "V935",
            descricao: "Acidente a bordo de uma embarcação, sem acidente da embarcação e não causando afogamento ou submersão - caiaque ou canoa",
            favorito: false
        },
        {
            cid10: "V936",
            descricao: "Acidente a bordo de uma embarcação, sem acidente da embarcação e não causando afogamento ou submersão - bote inflável (sem motor)",
            favorito: false
        },
        {
            cid10: "V937",
            descricao: "Acidente a bordo de uma embarcação, sem acidente da embarcação e não causando afogamento ou submersão - esqui aquático",
            favorito: false
        },
        {
            cid10: "V938",
            descricao: "Acidente a bordo de uma embarcação, sem acidente da embarcação e não causando afogamento ou submersão - outras embarcações sem motor",
            favorito: false
        },
        {
            cid10: "V939",
            descricao: "Acidente a bordo de uma embarcação, sem acidente da embarcação e não causando afogamento ou submersão - embarcação não especificada",
            favorito: false
        },
        {
            cid10: "V940",
            descricao: "Outros acidentes de transporte por água e os não especificados - navio mercante",
            favorito: false
        },
        {
            cid10: "V941",
            descricao: "Outros acidentes de transporte por água e os não especificados - navio de passageiros",
            favorito: false
        },
        {
            cid10: "V942",
            descricao: "Outros acidentes de transporte por água e os não especificados - barco de pesca",
            favorito: false
        },
        {
            cid10: "V943",
            descricao: "Outros acidentes de transporte por água e os não especificados - outras embarcações a motor",
            favorito: false
        },
        {
            cid10: "V944",
            descricao: "Outros acidentes de transporte por água e os não especificados - barco a vela",
            favorito: false
        },
        {
            cid10: "V945",
            descricao: "Outros acidentes de transporte por água e os não especificados - caiaque ou canoa",
            favorito: false
        },
        {
            cid10: "V946",
            descricao: "Outros acidentes de transporte por água e os não especificados - bote inflável (sem motor)",
            favorito: false
        },
        {
            cid10: "V947",
            descricao: "Outros acidentes de transporte por água e os não especificados - esqui aquático",
            favorito: false
        },
        {
            cid10: "V948",
            descricao: "Outros acidentes de transporte por água e os não especificados - outras embarcações sem motor",
            favorito: false
        },
        {
            cid10: "V949",
            descricao: "Outros acidentes de transporte por água e os não especificados - embarcação não especificada",
            favorito: false
        },
        {
            cid10: "V950",
            descricao: "Acidente de helicóptero causando traumatismo a ocupante",
            favorito: false
        },
        {
            cid10: "V951",
            descricao: "Acidente de planador motorizado e ultraleve causando traumatismo a ocupante",
            favorito: false
        },
        {
            cid10: "V952",
            descricao: "Acidente de outra aeronave de asa fixa de uso particular causando traumatismo a ocupante",
            favorito: false
        },
        {
            cid10: "V953",
            descricao: "Acidente de aeronave de asa fixa de uso comercial causando traumatismo a ocupante",
            favorito: false
        },
        {
            cid10: "V954",
            descricao: "Acidente de nave espacial causando traumatismo a ocupante",
            favorito: false
        },
        {
            cid10: "V958",
            descricao: "Outros acidente de aeronaves causando traumatismo a ocupante",
            favorito: false
        },
        {
            cid10: "V959",
            descricao: "Acidente de aeronave não especificada causando traumatismo a ocupante",
            favorito: false
        },
        {
            cid10: "V960",
            descricao: "Acidente de balão causando traumatismo a ocupante",
            favorito: false
        },
        {
            cid10: "V961",
            descricao: "Acidente de asa delta causando traumatismo a ocupante",
            favorito: false
        },
        {
            cid10: "V962",
            descricao: "Acidente de planador (não-motorizado) causando traumatismo a ocupante",
            favorito: false
        },
        {
            cid10: "V968",
            descricao: "Outros acidentes de aeronaves não-motorizadas causando traumatismo a ocupante",
            favorito: false
        },
        {
            cid10: "V969",
            descricao: "Acidente não especificado de aeronave não-motorizada causando traumatismo a ocupante",
            favorito: false
        },
        {
            cid10: "V970",
            descricao: "Ocupante de uma aeronave traumatizado em outros acidentes de transporte aéreo especificados",
            favorito: false
        },
        {
            cid10: "V971",
            descricao: "Pessoa traumatizada ao embarcar em ou desembarcar de uma aeronave",
            favorito: false
        },
        {
            cid10: "V972",
            descricao: "Paraquedista traumatizado em acidente de transporte aéreo",
            favorito: false
        },
        {
            cid10: "V973",
            descricao: "Pessoa em terra traumatizada em acidente de transporte aéreo",
            favorito: false
        },
        {
            cid10: "V978",
            descricao: "Outros acidentes de transporte aéreo não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "V98",
            descricao: "Outros acidentes de transporte especificados",
            favorito: false
        },
        {
            cid10: "V99",
            descricao: "Acidente de transporte não especificado",
            favorito: false
        },
        {
            cid10: "W000",
            descricao: "Queda no mesmo nível envolvendo gelo e neve - residência",
            favorito: false
        },
        {
            cid10: "W001",
            descricao: "Queda no mesmo nível envolvendo gelo e neve - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W002",
            descricao: "Queda no mesmo nível envolvendo gelo e neve - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W003",
            descricao: "Queda no mesmo nível envolvendo gelo e neve - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W004",
            descricao: "Queda no mesmo nível envolvendo gelo e neve - rua e estrada",
            favorito: false
        },
        {
            cid10: "W005",
            descricao: "Queda no mesmo nível envolvendo gelo e neve - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W006",
            descricao: "Queda no mesmo nível envolvendo gelo e neve - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W007",
            descricao: "Queda no mesmo nível envolvendo gelo e neve - fazenda",
            favorito: false
        },
        {
            cid10: "W008",
            descricao: "Queda no mesmo nível envolvendo gelo e neve - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W009",
            descricao: "Queda no mesmo nível envolvendo gelo e neve - local não especificado",
            favorito: false
        },
        {
            cid10: "W010",
            descricao: "Queda no mesmo nível por escorregão, tropeção ou passos em falsos [traspés] - residência",
            favorito: false
        },
        {
            cid10: "W011",
            descricao: "Queda no mesmo nível por escorregão, tropeção ou passos em falsos [traspés] - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W012",
            descricao: "Queda no mesmo nível por escorregão, tropeção ou passos em falsos [traspés] - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W013",
            descricao: "Queda no mesmo nível por escorregão, tropeção ou passos em falsos [traspés] - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W014",
            descricao: "Queda no mesmo nível por escorregão, tropeção ou passos em falsos [traspés] - rua e estrada",
            favorito: false
        },
        {
            cid10: "W015",
            descricao: "Queda no mesmo nível por escorregão, tropeção ou passos em falsos [traspés] - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W016",
            descricao: "Queda no mesmo nível por escorregão, tropeção ou passos em falsos [traspés] - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W017",
            descricao: "Queda no mesmo nível por escorregão, tropeção ou passos em falsos [traspés] - fazenda",
            favorito: false
        },
        {
            cid10: "W018",
            descricao: "Queda no mesmo nível por escorregão, tropeção ou passos em falsos [traspés] - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W019",
            descricao: "Queda no mesmo nível por escorregão, tropeção ou passos em falsos [traspés] - local não especificado",
            favorito: false
        },
        {
            cid10: "W020",
            descricao: "Queda envolvendo patins de rodas ou para gelo, esqui ou pranchas de rodas - residência",
            favorito: false
        },
        {
            cid10: "W021",
            descricao: "Queda envolvendo patins de rodas ou para gelo, esqui ou pranchas de rodas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W022",
            descricao: "Queda envolvendo patins de rodas ou para gelo, esqui ou pranchas de rodas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W023",
            descricao: "Queda envolvendo patins de rodas ou para gelo, esqui ou pranchas de rodas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W024",
            descricao: "Queda envolvendo patins de rodas ou para gelo, esqui ou pranchas de rodas - rua e estrada",
            favorito: false
        },
        {
            cid10: "W025",
            descricao: "Queda envolvendo patins de rodas ou para gelo, esqui ou pranchas de rodas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W026",
            descricao: "Queda envolvendo patins de rodas ou para gelo, esqui ou pranchas de rodas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W027",
            descricao: "Queda envolvendo patins de rodas ou para gelo, esqui ou pranchas de rodas - fazenda",
            favorito: false
        },
        {
            cid10: "W028",
            descricao: "Queda envolvendo patins de rodas ou para gelo, esqui ou pranchas de rodas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W029",
            descricao: "Queda envolvendo patins de rodas ou para gelo, esqui ou pranchas de rodas - local não especificado",
            favorito: false
        },
        {
            cid10: "W030",
            descricao: "Outras quedas no mesmo nível por colisão com ou empurrão por outra pessoa - residência",
            favorito: false
        },
        {
            cid10: "W031",
            descricao: "Outras quedas no mesmo nível por colisão com ou empurrão por outra pessoa - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W032",
            descricao: "Outras quedas no mesmo nível por colisão com ou empurrão por outra pessoa - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W033",
            descricao: "Outras quedas no mesmo nível por colisão com ou empurrão por outra pessoa - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W034",
            descricao: "Outras quedas no mesmo nível por colisão com ou empurrão por outra pessoa - rua e estrada",
            favorito: false
        },
        {
            cid10: "W035",
            descricao: "Outras quedas no mesmo nível por colisão com ou empurrão por outra pessoa - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W036",
            descricao: "Outras quedas no mesmo nível por colisão com ou empurrão por outra pessoa - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W037",
            descricao: "Outras quedas no mesmo nível por colisão com ou empurrão por outra pessoa - fazenda",
            favorito: false
        },
        {
            cid10: "W038",
            descricao: "Outras quedas no mesmo nível por colisão com ou empurrão por outra pessoa - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W039",
            descricao: "Outras quedas no mesmo nível por colisão com ou empurrão por outra pessoa - local não especificado",
            favorito: false
        },
        {
            cid10: "W040",
            descricao: "Queda, enquanto estava sendo carregado ou apoiado por outra(s) pessoa(s) - residência",
            favorito: false
        },
        {
            cid10: "W041",
            descricao: "Queda, enquanto estava sendo carregado ou apoiado por outra(s) pessoa(s) - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W042",
            descricao: "Queda, enquanto estava sendo carregado ou apoiado por outra(s) pessoa(s) - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W043",
            descricao: "Queda, enquanto estava sendo carregado ou apoiado por outra(s) pessoa(s) - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W044",
            descricao: "Queda, enquanto estava sendo carregado ou apoiado por outra(s) pessoa(s) - rua e estrada",
            favorito: false
        },
        {
            cid10: "W045",
            descricao: "Queda, enquanto estava sendo carregado ou apoiado por outra(s) pessoa(s) - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W046",
            descricao: "Queda, enquanto estava sendo carregado ou apoiado por outra(s) pessoa(s) - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W047",
            descricao: "Queda, enquanto estava sendo carregado ou apoiado por outra(s) pessoa(s) - fazenda",
            favorito: false
        },
        {
            cid10: "W048",
            descricao: "Queda, enquanto estava sendo carregado ou apoiado por outra(s) pessoa(s) - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W049",
            descricao: "Queda, enquanto estava sendo carregado ou apoiado por outra(s) pessoa(s) - local não especificado",
            favorito: false
        },
        {
            cid10: "W050",
            descricao: "Queda envolvendo uma cadeira de rodas - residência",
            favorito: false
        },
        {
            cid10: "W051",
            descricao: "Queda envolvendo uma cadeira de rodas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W052",
            descricao: "Queda envolvendo uma cadeira de rodas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W053",
            descricao: "Queda envolvendo uma cadeira de rodas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W054",
            descricao: "Queda envolvendo uma cadeira de rodas - rua e estrada",
            favorito: false
        },
        {
            cid10: "W055",
            descricao: "Queda envolvendo uma cadeira de rodas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W056",
            descricao: "Queda envolvendo uma cadeira de rodas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W057",
            descricao: "Queda envolvendo uma cadeira de rodas - fazenda",
            favorito: false
        },
        {
            cid10: "W058",
            descricao: "Queda envolvendo uma cadeira de rodas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W059",
            descricao: "Queda envolvendo uma cadeira de rodas - local não especificado",
            favorito: false
        },
        {
            cid10: "W060",
            descricao: "Queda de um leito - residência",
            favorito: false
        },
        {
            cid10: "W061",
            descricao: "Queda de um leito - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W062",
            descricao: "Queda de um leito - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W063",
            descricao: "Queda de um leito - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W064",
            descricao: "Queda de um leito - rua e estrada",
            favorito: false
        },
        {
            cid10: "W065",
            descricao: "Queda de um leito - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W066",
            descricao: "Queda de um leito - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W067",
            descricao: "Queda de um leito - fazenda",
            favorito: false
        },
        {
            cid10: "W068",
            descricao: "Queda de um leito - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W069",
            descricao: "Queda de um leito - local não especificado",
            favorito: false
        },
        {
            cid10: "W070",
            descricao: "Queda de uma cadeira - residência",
            favorito: false
        },
        {
            cid10: "W071",
            descricao: "Queda de uma cadeira - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W072",
            descricao: "Queda de uma cadeira - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W073",
            descricao: "Queda de uma cadeira - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W074",
            descricao: "Queda de uma cadeira - rua e estrada",
            favorito: false
        },
        {
            cid10: "W075",
            descricao: "Queda de uma cadeira - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W076",
            descricao: "Queda de uma cadeira - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W077",
            descricao: "Queda de uma cadeira - fazenda",
            favorito: false
        },
        {
            cid10: "W078",
            descricao: "Queda de uma cadeira - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W079",
            descricao: "Queda de uma cadeira - local não especificado",
            favorito: false
        },
        {
            cid10: "W080",
            descricao: "Queda de outro tipo de mobília - residência",
            favorito: false
        },
        {
            cid10: "W081",
            descricao: "Queda de outro tipo de mobília - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W082",
            descricao: "Queda de outro tipo de mobília - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W083",
            descricao: "Queda de outro tipo de mobília - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W084",
            descricao: "Queda de outro tipo de mobília - rua e estrada",
            favorito: false
        },
        {
            cid10: "W085",
            descricao: "Queda de outro tipo de mobília - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W086",
            descricao: "Queda de outro tipo de mobília - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W087",
            descricao: "Queda de outro tipo de mobília - fazenda",
            favorito: false
        },
        {
            cid10: "W088",
            descricao: "Queda de outro tipo de mobília - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W089",
            descricao: "Queda de outro tipo de mobília - local não especificado",
            favorito: false
        },
        {
            cid10: "W090",
            descricao: "Queda envolvendo equipamento de playground - residência",
            favorito: false
        },
        {
            cid10: "W091",
            descricao: "Queda envolvendo equipamento de playground - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W092",
            descricao: "Queda envolvendo equipamento de playground - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W093",
            descricao: "Queda envolvendo equipamento de playground - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W094",
            descricao: "Queda envolvendo equipamento de playground - rua e estrada",
            favorito: false
        },
        {
            cid10: "W095",
            descricao: "Queda envolvendo equipamento de playground - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W096",
            descricao: "Queda envolvendo equipamento de playground - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W097",
            descricao: "Queda envolvendo equipamento de playground - fazenda",
            favorito: false
        },
        {
            cid10: "W098",
            descricao: "Queda envolvendo equipamento de playground - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W099",
            descricao: "Queda envolvendo equipamento de playground - local não especificado",
            favorito: false
        },
        {
            cid10: "W100",
            descricao: "Queda em ou de escadas ou degraus - residência",
            favorito: false
        },
        {
            cid10: "W101",
            descricao: "Queda em ou de escadas ou degraus - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W102",
            descricao: "Queda em ou de escadas ou degraus - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W103",
            descricao: "Queda em ou de escadas ou degraus - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W104",
            descricao: "Queda em ou de escadas ou degraus - rua e estrada",
            favorito: false
        },
        {
            cid10: "W105",
            descricao: "Queda em ou de escadas ou degraus - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W106",
            descricao: "Queda em ou de escadas ou degraus - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W107",
            descricao: "Queda em ou de escadas ou degraus - fazenda",
            favorito: false
        },
        {
            cid10: "W108",
            descricao: "Queda em ou de escadas ou degraus - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W109",
            descricao: "Queda em ou de escadas ou degraus - local não especificado",
            favorito: false
        },
        {
            cid10: "W110",
            descricao: "Queda em ou de escadas de mão - residência",
            favorito: false
        },
        {
            cid10: "W111",
            descricao: "Queda em ou de escadas de mão - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W112",
            descricao: "Queda em ou de escadas de mão - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W113",
            descricao: "Queda em ou de escadas de mão - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W114",
            descricao: "Queda em ou de escadas de mão - rua e estrada",
            favorito: false
        },
        {
            cid10: "W115",
            descricao: "Queda em ou de escadas de mão - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W116",
            descricao: "Queda em ou de escadas de mão - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W117",
            descricao: "Queda em ou de escadas de mão - fazenda",
            favorito: false
        },
        {
            cid10: "W118",
            descricao: "Queda em ou de escadas de mão - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W119",
            descricao: "Queda em ou de escadas de mão - local não especificado",
            favorito: false
        },
        {
            cid10: "W120",
            descricao: "Queda em ou de um andaime - residência",
            favorito: false
        },
        {
            cid10: "W121",
            descricao: "Queda em ou de um andaime - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W122",
            descricao: "Queda em ou de um andaime - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W123",
            descricao: "Queda em ou de um andaime - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W124",
            descricao: "Queda em ou de um andaime - rua e estrada",
            favorito: false
        },
        {
            cid10: "W125",
            descricao: "Queda em ou de um andaime - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W126",
            descricao: "Queda em ou de um andaime - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W127",
            descricao: "Queda em ou de um andaime - fazenda",
            favorito: false
        },
        {
            cid10: "W128",
            descricao: "Queda em ou de um andaime - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W129",
            descricao: "Queda em ou de um andaime - local não especificado",
            favorito: false
        },
        {
            cid10: "W130",
            descricao: "Queda de ou para fora de edifícios ou outras estruturas - residência",
            favorito: false
        },
        {
            cid10: "W131",
            descricao: "Queda de ou para fora de edifícios ou outras estruturas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W132",
            descricao: "Queda de ou para fora de edifícios ou outras estruturas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W133",
            descricao: "Queda de ou para fora de edifícios ou outras estruturas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W134",
            descricao: "Queda de ou para fora de edifícios ou outras estruturas - rua e estrada",
            favorito: false
        },
        {
            cid10: "W135",
            descricao: "Queda de ou para fora de edifícios ou outras estruturas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W136",
            descricao: "Queda de ou para fora de edifícios ou outras estruturas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W137",
            descricao: "Queda de ou para fora de edifícios ou outras estruturas - fazenda",
            favorito: false
        },
        {
            cid10: "W138",
            descricao: "Queda de ou para fora de edifícios ou outras estruturas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W139",
            descricao: "Queda de ou para fora de edifícios ou outras estruturas - local não especificado",
            favorito: false
        },
        {
            cid10: "W140",
            descricao: "Queda de árvore - residência",
            favorito: false
        },
        {
            cid10: "W141",
            descricao: "Queda de árvore - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W142",
            descricao: "Queda de árvore - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W143",
            descricao: "Queda de árvore - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W144",
            descricao: "Queda de árvore - rua e estrada",
            favorito: false
        },
        {
            cid10: "W145",
            descricao: "Queda de árvore - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W146",
            descricao: "Queda de árvore - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W147",
            descricao: "Queda de árvore - fazenda",
            favorito: false
        },
        {
            cid10: "W148",
            descricao: "Queda de árvore - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W149",
            descricao: "Queda de árvore - local não especificado",
            favorito: false
        },
        {
            cid10: "W150",
            descricao: "Queda de penhasco - residência",
            favorito: false
        },
        {
            cid10: "W151",
            descricao: "Queda de penhasco - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W152",
            descricao: "Queda de penhasco - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W153",
            descricao: "Queda de penhasco - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W154",
            descricao: "Queda de penhasco - rua e estrada",
            favorito: false
        },
        {
            cid10: "W155",
            descricao: "Queda de penhasco - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W156",
            descricao: "Queda de penhasco - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W157",
            descricao: "Queda de penhasco - fazenda",
            favorito: false
        },
        {
            cid10: "W158",
            descricao: "Queda de penhasco - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W159",
            descricao: "Queda de penhasco - local não especificado",
            favorito: false
        },
        {
            cid10: "W160",
            descricao: "Mergulho ou pulo na água causando outro traumatismo que não afogamento ou submersão - residência",
            favorito: false
        },
        {
            cid10: "W161",
            descricao: "Mergulho ou pulo na água causando outro traumatismo que não afogamento ou submersão - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W162",
            descricao: "Mergulho ou pulo na água causando outro traumatismo que não afogamento ou submersão - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W163",
            descricao: "Mergulho ou pulo na água causando outro traumatismo que não afogamento ou submersão - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W164",
            descricao: "Mergulho ou pulo na água causando outro traumatismo que não afogamento ou submersão - rua e estrada",
            favorito: false
        },
        {
            cid10: "W165",
            descricao: "Mergulho ou pulo na água causando outro traumatismo que não afogamento ou submersão - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W166",
            descricao: "Mergulho ou pulo na água causando outro traumatismo que não afogamento ou submersão - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W167",
            descricao: "Mergulho ou pulo na água causando outro traumatismo que não afogamento ou submersão - fazenda",
            favorito: false
        },
        {
            cid10: "W168",
            descricao: "Mergulho ou pulo na água causando outro traumatismo que não afogamento ou submersão - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W169",
            descricao: "Mergulho ou pulo na água causando outro traumatismo que não afogamento ou submersão - local não especificado",
            favorito: false
        },
        {
            cid10: "W170",
            descricao: "Outras quedas de um nível a outro - residência",
            favorito: false
        },
        {
            cid10: "W171",
            descricao: "Outras quedas de um nível a outro - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W172",
            descricao: "Outras quedas de um nível a outro - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W173",
            descricao: "Outras quedas de um nível a outro - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W174",
            descricao: "Outras quedas de um nível a outro - rua e estrada",
            favorito: false
        },
        {
            cid10: "W175",
            descricao: "Outras quedas de um nível a outro - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W176",
            descricao: "Outras quedas de um nível a outro - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W177",
            descricao: "Outras quedas de um nível a outro - fazenda",
            favorito: false
        },
        {
            cid10: "W178",
            descricao: "Outras quedas de um nível a outro - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W179",
            descricao: "Outras quedas de um nível a outro - local não especificado",
            favorito: false
        },
        {
            cid10: "W180",
            descricao: "Outras quedas no mesmo nível - residência",
            favorito: false
        },
        {
            cid10: "W181",
            descricao: "Outras quedas no mesmo nível - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W182",
            descricao: "Outras quedas no mesmo nível - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W183",
            descricao: "Outras quedas no mesmo nível - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W184",
            descricao: "Outras quedas no mesmo nível - rua e estrada",
            favorito: false
        },
        {
            cid10: "W185",
            descricao: "Outras quedas no mesmo nível - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W186",
            descricao: "Outras quedas no mesmo nível - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W187",
            descricao: "Outras quedas no mesmo nível - fazenda",
            favorito: false
        },
        {
            cid10: "W188",
            descricao: "Outras quedas no mesmo nível - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W189",
            descricao: "Outras quedas no mesmo nível - local não especificado",
            favorito: false
        },
        {
            cid10: "W190",
            descricao: "Queda sem especificação - residência",
            favorito: false
        },
        {
            cid10: "W191",
            descricao: "Queda sem especificação - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W192",
            descricao: "Queda sem especificação - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W193",
            descricao: "Queda sem especificação - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W194",
            descricao: "Queda sem especificação - rua e estrada",
            favorito: false
        },
        {
            cid10: "W195",
            descricao: "Queda sem especificação - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W196",
            descricao: "Queda sem especificação - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W197",
            descricao: "Queda sem especificação - fazenda",
            favorito: false
        },
        {
            cid10: "W198",
            descricao: "Queda sem especificação - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W199",
            descricao: "Queda sem especificação - local não especificado",
            favorito: false
        },
        {
            cid10: "W200",
            descricao: "Impacto causado por objeto lançado, projetado ou em queda - residência",
            favorito: false
        },
        {
            cid10: "W201",
            descricao: "Impacto causado por objeto lançado, projetado ou em queda - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W202",
            descricao: "Impacto causado por objeto lançado, projetado ou em queda - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W203",
            descricao: "Impacto causado por objeto lançado, projetado ou em queda - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W204",
            descricao: "Impacto causado por objeto lançado, projetado ou em queda - rua e estrada",
            favorito: false
        },
        {
            cid10: "W205",
            descricao: "Impacto causado por objeto lançado, projetado ou em queda - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W206",
            descricao: "Impacto causado por objeto lançado, projetado ou em queda - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W207",
            descricao: "Impacto causado por objeto lançado, projetado ou em queda - fazenda",
            favorito: false
        },
        {
            cid10: "W208",
            descricao: "Impacto causado por objeto lançado, projetado ou em queda - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W209",
            descricao: "Impacto causado por objeto lançado, projetado ou em queda - local não especificado",
            favorito: false
        },
        {
            cid10: "W210",
            descricao: "Impacto acidental ativo ou passivo causado por equipamento esportivo - residência",
            favorito: false
        },
        {
            cid10: "W211",
            descricao: "Impacto acidental ativo ou passivo causado por equipamento esportivo - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W212",
            descricao: "Impacto acidental ativo ou passivo causado por equipamento esportivo - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W213",
            descricao: "Impacto acidental ativo ou passivo causado por equipamento esportivo - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W214",
            descricao: "Impacto acidental ativo ou passivo causado por equipamento esportivo - rua e estrada",
            favorito: false
        },
        {
            cid10: "W215",
            descricao: "Impacto acidental ativo ou passivo causado por equipamento esportivo - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W216",
            descricao: "Impacto acidental ativo ou passivo causado por equipamento esportivo - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W217",
            descricao: "Impacto acidental ativo ou passivo causado por equipamento esportivo - fazenda",
            favorito: false
        },
        {
            cid10: "W218",
            descricao: "Impacto acidental ativo ou passivo causado por equipamento esportivo - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W219",
            descricao: "Impacto acidental ativo ou passivo causado por equipamento esportivo - local não especificado",
            favorito: false
        },
        {
            cid10: "W220",
            descricao: "Impacto acidental ativo ou passivo causado por outros objetos - residência",
            favorito: false
        },
        {
            cid10: "W221",
            descricao: "Impacto acidental ativo ou passivo causado por outros objetos - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W222",
            descricao: "Impacto acidental ativo ou passivo causado por outros objetos - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W223",
            descricao: "Impacto acidental ativo ou passivo causado por outros objetos - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W224",
            descricao: "Impacto acidental ativo ou passivo causado por outros objetos - rua e estrada",
            favorito: false
        },
        {
            cid10: "W225",
            descricao: "Impacto acidental ativo ou passivo causado por outros objetos - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W226",
            descricao: "Impacto acidental ativo ou passivo causado por outros objetos - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W227",
            descricao: "Impacto acidental ativo ou passivo causado por outros objetos - fazenda",
            favorito: false
        },
        {
            cid10: "W228",
            descricao: "Impacto acidental ativo ou passivo causado por outros objetos - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W229",
            descricao: "Impacto acidental ativo ou passivo causado por outros objetos - local não especificado",
            favorito: false
        },
        {
            cid10: "W230",
            descricao: "Apertado, colhido, comprimido ou esmagado dentro de ou entre objetos - residência",
            favorito: false
        },
        {
            cid10: "W231",
            descricao: "Apertado, colhido, comprimido ou esmagado dentro de ou entre objetos - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W232",
            descricao: "Apertado, colhido, comprimido ou esmagado dentro de ou entre objetos - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W233",
            descricao: "Apertado, colhido, comprimido ou esmagado dentro de ou entre objetos - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W234",
            descricao: "Apertado, colhido, comprimido ou esmagado dentro de ou entre objetos - rua e estrada",
            favorito: false
        },
        {
            cid10: "W235",
            descricao: "Apertado, colhido, comprimido ou esmagado dentro de ou entre objetos - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W236",
            descricao: "Apertado, colhido, comprimido ou esmagado dentro de ou entre objetos - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W237",
            descricao: "Apertado, colhido, comprimido ou esmagado dentro de ou entre objetos - fazenda",
            favorito: false
        },
        {
            cid10: "W238",
            descricao: "Apertado, colhido, comprimido ou esmagado dentro de ou entre objetos - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W239",
            descricao: "Apertado, colhido, comprimido ou esmagado dentro de ou entre objetos - local não especificado",
            favorito: false
        },
        {
            cid10: "W240",
            descricao: "Contato com elevadores e instrumentos de transmissão, não classificados em outra parte - residência",
            favorito: false
        },
        {
            cid10: "W241",
            descricao: "Contato com elevadores e instrumentos de transmissão, não classificados em outra parte - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W242",
            descricao: "Contato com elevadores e instrumentos de transmissão, não classificados em outra parte - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W243",
            descricao: "Contato com elevadores e instrumentos de transmissão, não classificados em outra parte - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W244",
            descricao: "Contato com elevadores e instrumentos de transmissão, não classificados em outra parte - rua e estrada",
            favorito: false
        },
        {
            cid10: "W245",
            descricao: "Contato com elevadores e instrumentos de transmissão, não classificados em outra parte - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W246",
            descricao: "Contato com elevadores e instrumentos de transmissão, não classificados em outra parte - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W247",
            descricao: "Contato com elevadores e instrumentos de transmissão, não classificados em outra parte - fazenda",
            favorito: false
        },
        {
            cid10: "W248",
            descricao: "Contato com elevadores e instrumentos de transmissão, não classificados em outra parte - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W249",
            descricao: "Contato com elevadores e instrumentos de transmissão, não classificados em outra parte - local não especificado",
            favorito: false
        },
        {
            cid10: "W250",
            descricao: "Contato com vidro cortante - residência",
            favorito: false
        },
        {
            cid10: "W251",
            descricao: "Contato com vidro cortante - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W252",
            descricao: "Contato com vidro cortante - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W253",
            descricao: "Contato com vidro cortante - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W254",
            descricao: "Contato com vidro cortante - rua e estrada",
            favorito: false
        },
        {
            cid10: "W255",
            descricao: "Contato com vidro cortante - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W256",
            descricao: "Contato com vidro cortante - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W257",
            descricao: "Contato com vidro cortante - fazenda",
            favorito: false
        },
        {
            cid10: "W258",
            descricao: "Contato com vidro cortante - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W259",
            descricao: "Contato com vidro cortante - local não especificado",
            favorito: false
        },
        {
            cid10: "W260",
            descricao: "Contato com faca, espada e punhal - residência",
            favorito: false
        },
        {
            cid10: "W261",
            descricao: "Contato com faca, espada e punhal - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W262",
            descricao: "Contato com faca, espada e punhal - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W263",
            descricao: "Contato com faca, espada e punhal - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W264",
            descricao: "Contato com faca, espada e punhal - rua e estrada",
            favorito: false
        },
        {
            cid10: "W265",
            descricao: "Contato com faca, espada e punhal - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W266",
            descricao: "Contato com faca, espada e punhal - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W267",
            descricao: "Contato com faca, espada e punhal - fazenda",
            favorito: false
        },
        {
            cid10: "W268",
            descricao: "Contato com faca, espada e punhal - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W269",
            descricao: "Contato com faca, espada e punhal - local não especificado",
            favorito: false
        },
        {
            cid10: "W270",
            descricao: "Contato com ferramentas manuais sem motor - residência",
            favorito: false
        },
        {
            cid10: "W271",
            descricao: "Contato com ferramentas manuais sem motor - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W272",
            descricao: "Contato com ferramentas manuais sem motor - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W273",
            descricao: "Contato com ferramentas manuais sem motor - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W274",
            descricao: "Contato com ferramentas manuais sem motor - rua e estrada",
            favorito: false
        },
        {
            cid10: "W275",
            descricao: "Contato com ferramentas manuais sem motor - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W276",
            descricao: "Contato com ferramentas manuais sem motor - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W277",
            descricao: "Contato com ferramentas manuais sem motor - fazenda",
            favorito: false
        },
        {
            cid10: "W278",
            descricao: "Contato com ferramentas manuais sem motor - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W279",
            descricao: "Contato com ferramentas manuais sem motor - local não especificado",
            favorito: false
        },
        {
            cid10: "W280",
            descricao: "Contato com segadeira motorizada para cortar ou aparar a grama - residência",
            favorito: false
        },
        {
            cid10: "W281",
            descricao: "Contato com segadeira motorizada para cortar ou aparar a grama - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W282",
            descricao: "Contato com segadeira motorizada para cortar ou aparar a grama - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W283",
            descricao: "Contato com segadeira motorizada para cortar ou aparar a grama - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W284",
            descricao: "Contato com segadeira motorizada para cortar ou aparar a grama - rua e estrada",
            favorito: false
        },
        {
            cid10: "W285",
            descricao: "Contato com segadeira motorizada para cortar ou aparar a grama - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W286",
            descricao: "Contato com segadeira motorizada para cortar ou aparar a grama - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W287",
            descricao: "Contato com segadeira motorizada para cortar ou aparar a grama - fazenda",
            favorito: false
        },
        {
            cid10: "W288",
            descricao: "Contato com segadeira motorizada para cortar ou aparar a grama - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W289",
            descricao: "Contato com segadeira motorizada para cortar ou aparar a grama - local não especificado",
            favorito: false
        },
        {
            cid10: "W290",
            descricao: "Contato com outros utensílios manuais e aparelhos domésticos equipados com motor - residência",
            favorito: false
        },
        {
            cid10: "W291",
            descricao: "Contato com outros utensílios manuais e aparelhos domésticos equipados com motor - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W292",
            descricao: "Contato com outros utensílios manuais e aparelhos domésticos equipados com motor - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W293",
            descricao: "Contato com outros utensílios manuais e aparelhos domésticos equipados com motor - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W294",
            descricao: "Contato com outros utensílios manuais e aparelhos domésticos equipados com motor - rua e estrada",
            favorito: false
        },
        {
            cid10: "W295",
            descricao: "Contato com outros utensílios manuais e aparelhos domésticos equipados com motor - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W296",
            descricao: "Contato com outros utensílios manuais e aparelhos domésticos equipados com motor - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W297",
            descricao: "Contato com outros utensílios manuais e aparelhos domésticos equipados com motor - fazenda",
            favorito: false
        },
        {
            cid10: "W298",
            descricao: "Contato com outros utensílios manuais e aparelhos domésticos equipados com motor - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W299",
            descricao: "Contato com outros utensílios manuais e aparelhos domésticos equipados com motor - local não especificado",
            favorito: false
        },
        {
            cid10: "W300",
            descricao: "Contato com maquinaria agrícola - residência",
            favorito: false
        },
        {
            cid10: "W301",
            descricao: "Contato com maquinaria agrícola - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W302",
            descricao: "Contato com maquinaria agrícola - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W303",
            descricao: "Contato com maquinaria agrícola - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W304",
            descricao: "Contato com maquinaria agrícola - rua e estrada",
            favorito: false
        },
        {
            cid10: "W305",
            descricao: "Contato com maquinaria agrícola - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W306",
            descricao: "Contato com maquinaria agrícola - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W307",
            descricao: "Contato com maquinaria agrícola - fazenda",
            favorito: false
        },
        {
            cid10: "W308",
            descricao: "Contato com maquinaria agrícola - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W309",
            descricao: "Contato com maquinaria agrícola - local não especificado",
            favorito: false
        },
        {
            cid10: "W310",
            descricao: "Contato com outras máquinas e com as não especificadas - residência",
            favorito: false
        },
        {
            cid10: "W311",
            descricao: "Contato com outras máquinas e com as não especificadas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W312",
            descricao: "Contato com outras máquinas e com as não especificadas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W313",
            descricao: "Contato com outras máquinas e com as não especificadas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W314",
            descricao: "Contato com outras máquinas e com as não especificadas - rua e estrada",
            favorito: false
        },
        {
            cid10: "W315",
            descricao: "Contato com outras máquinas e com as não especificadas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W316",
            descricao: "Contato com outras máquinas e com as não especificadas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W317",
            descricao: "Contato com outras máquinas e com as não especificadas - fazenda",
            favorito: false
        },
        {
            cid10: "W318",
            descricao: "Contato com outras máquinas e com as não especificadas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W319",
            descricao: "Contato com outras máquinas e com as não especificadas - local não especificado",
            favorito: false
        },
        {
            cid10: "W320",
            descricao: "Projétil de revólver - residência",
            favorito: false
        },
        {
            cid10: "W321",
            descricao: "Projétil de revólver - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W322",
            descricao: "Projétil de revólver - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W323",
            descricao: "Projétil de revólver - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W324",
            descricao: "Projétil de revólver - rua e estrada",
            favorito: false
        },
        {
            cid10: "W325",
            descricao: "Projétil de revólver - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W326",
            descricao: "Projétil de revólver - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W327",
            descricao: "Projétil de revólver - fazenda",
            favorito: false
        },
        {
            cid10: "W328",
            descricao: "Projétil de revólver - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W329",
            descricao: "Projétil de revólver - local não especificado",
            favorito: false
        },
        {
            cid10: "W330",
            descricao: "Rifle, espingarda e armas de fogo de maior tamanho - residência",
            favorito: false
        },
        {
            cid10: "W331",
            descricao: "Rifle, espingarda e armas de fogo de maior tamanho - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W332",
            descricao: "Rifle, espingarda e armas de fogo de maior tamanho - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W333",
            descricao: "Rifle, espingarda e armas de fogo de maior tamanho - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W334",
            descricao: "Rifle, espingarda e armas de fogo de maior tamanho - rua e estrada",
            favorito: false
        },
        {
            cid10: "W335",
            descricao: "Rifle, espingarda e armas de fogo de maior tamanho - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W336",
            descricao: "Rifle, espingarda e armas de fogo de maior tamanho - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W337",
            descricao: "Rifle, espingarda e armas de fogo de maior tamanho - fazenda",
            favorito: false
        },
        {
            cid10: "W338",
            descricao: "Rifle, espingarda e armas de fogo de maior tamanho - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W339",
            descricao: "Rifle, espingarda e armas de fogo de maior tamanho - local não especificado",
            favorito: false
        },
        {
            cid10: "W340",
            descricao: "Projéteis de outras armas de fogo e das não especificadas - residência",
            favorito: false
        },
        {
            cid10: "W341",
            descricao: "Projéteis de outras armas de fogo e das não especificadas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W342",
            descricao: "Projéteis de outras armas de fogo e das não especificadas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W343",
            descricao: "Projéteis de outras armas de fogo e das não especificadas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W344",
            descricao: "Projéteis de outras armas de fogo e das não especificadas - rua e estrada",
            favorito: false
        },
        {
            cid10: "W345",
            descricao: "Projéteis de outras armas de fogo e das não especificadas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W346",
            descricao: "Projéteis de outras armas de fogo e das não especificadas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W347",
            descricao: "Projéteis de outras armas de fogo e das não especificadas - fazenda",
            favorito: false
        },
        {
            cid10: "W348",
            descricao: "Projéteis de outras armas de fogo e das não especificadas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W349",
            descricao: "Projéteis de outras armas de fogo e das não especificadas - local não especificado",
            favorito: false
        },
        {
            cid10: "W350",
            descricao: "Explosão ou ruptura de caldeira - residência",
            favorito: false
        },
        {
            cid10: "W351",
            descricao: "Explosão ou ruptura de caldeira - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W352",
            descricao: "Explosão ou ruptura de caldeira - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W353",
            descricao: "Explosão ou ruptura de caldeira - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W354",
            descricao: "Explosão ou ruptura de caldeira - rua e estrada",
            favorito: false
        },
        {
            cid10: "W355",
            descricao: "Explosão ou ruptura de caldeira - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W356",
            descricao: "Explosão ou ruptura de caldeira - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W357",
            descricao: "Explosão ou ruptura de caldeira - fazenda",
            favorito: false
        },
        {
            cid10: "W358",
            descricao: "Explosão ou ruptura de caldeira - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W359",
            descricao: "Explosão ou ruptura de caldeira - local não especificado",
            favorito: false
        },
        {
            cid10: "W360",
            descricao: "Explosão ou ruptura de cilindro de gás - residência",
            favorito: false
        },
        {
            cid10: "W361",
            descricao: "Explosão ou ruptura de cilindro de gás - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W362",
            descricao: "Explosão ou ruptura de cilindro de gás - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W363",
            descricao: "Explosão ou ruptura de cilindro de gás - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W364",
            descricao: "Explosão ou ruptura de cilindro de gás - rua e estrada",
            favorito: false
        },
        {
            cid10: "W365",
            descricao: "Explosão ou ruptura de cilindro de gás - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W366",
            descricao: "Explosão ou ruptura de cilindro de gás - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W367",
            descricao: "Explosão ou ruptura de cilindro de gás - fazenda",
            favorito: false
        },
        {
            cid10: "W368",
            descricao: "Explosão ou ruptura de cilindro de gás - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W369",
            descricao: "Explosão ou ruptura de cilindro de gás - local não especificado",
            favorito: false
        },
        {
            cid10: "W370",
            descricao: "Explosão ou ruptura de pneumático, tubulação ou mangueira, pressurizados - residência",
            favorito: false
        },
        {
            cid10: "W371",
            descricao: "Explosão ou ruptura de pneumático, tubulação ou mangueira, pressurizados - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W372",
            descricao: "Explosão ou ruptura de pneumático, tubulação ou mangueira, pressurizados - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W373",
            descricao: "Explosão ou ruptura de pneumático, tubulação ou mangueira, pressurizados - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W374",
            descricao: "Explosão ou ruptura de pneumático, tubulação ou mangueira, pressurizados - rua e estrada",
            favorito: false
        },
        {
            cid10: "W375",
            descricao: "Explosão ou ruptura de pneumático, tubulação ou mangueira, pressurizados - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W376",
            descricao: "Explosão ou ruptura de pneumático, tubulação ou mangueira, pressurizados - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W377",
            descricao: "Explosão ou ruptura de pneumático, tubulação ou mangueira, pressurizados - fazenda",
            favorito: false
        },
        {
            cid10: "W378",
            descricao: "Explosão ou ruptura de pneumático, tubulação ou mangueira, pressurizados - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W379",
            descricao: "Explosão ou ruptura de pneumático, tubulação ou mangueira, pressurizados - local não especificado",
            favorito: false
        },
        {
            cid10: "W380",
            descricao: "Explosão ou ruptura de outros aparelhos pressurizados especificados - residência",
            favorito: false
        },
        {
            cid10: "W381",
            descricao: "Explosão ou ruptura de outros aparelhos pressurizados especificados - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W382",
            descricao: "Explosão ou ruptura de outros aparelhos pressurizados especificados - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W383",
            descricao: "Explosão ou ruptura de outros aparelhos pressurizados especificados - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W384",
            descricao: "Explosão ou ruptura de outros aparelhos pressurizados especificados - rua e estrada",
            favorito: false
        },
        {
            cid10: "W385",
            descricao: "Explosão ou ruptura de outros aparelhos pressurizados especificados - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W386",
            descricao: "Explosão ou ruptura de outros aparelhos pressurizados especificados - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W387",
            descricao: "Explosão ou ruptura de outros aparelhos pressurizados especificados - fazenda",
            favorito: false
        },
        {
            cid10: "W388",
            descricao: "Explosão ou ruptura de outros aparelhos pressurizados especificados - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W389",
            descricao: "Explosão ou ruptura de outros aparelhos pressurizados especificados - local não especificado",
            favorito: false
        },
        {
            cid10: "W390",
            descricao: "Queima de fogos de artifício - residência",
            favorito: false
        },
        {
            cid10: "W391",
            descricao: "Queima de fogos de artifício - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W392",
            descricao: "Queima de fogos de artifício - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W393",
            descricao: "Queima de fogos de artifício - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W394",
            descricao: "Queima de fogos de artifício - rua e estrada",
            favorito: false
        },
        {
            cid10: "W395",
            descricao: "Queima de fogos de artifício - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W396",
            descricao: "Queima de fogos de artifício - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W397",
            descricao: "Queima de fogos de artifício - fazenda",
            favorito: false
        },
        {
            cid10: "W398",
            descricao: "Queima de fogos de artifício - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W399",
            descricao: "Queima de fogos de artifício - local não especificado",
            favorito: false
        },
        {
            cid10: "W400",
            descricao: "Explosão de outros materiais - residência",
            favorito: false
        },
        {
            cid10: "W401",
            descricao: "Explosão de outros materiais - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W402",
            descricao: "Explosão de outros materiais - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W403",
            descricao: "Explosão de outros materiais - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W404",
            descricao: "Explosão de outros materiais - rua e estrada",
            favorito: false
        },
        {
            cid10: "W405",
            descricao: "Explosão de outros materiais - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W406",
            descricao: "Explosão de outros materiais - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W407",
            descricao: "Explosão de outros materiais - fazenda",
            favorito: false
        },
        {
            cid10: "W408",
            descricao: "Explosão de outros materiais - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W409",
            descricao: "Explosão de outros materiais - local não especificado",
            favorito: false
        },
        {
            cid10: "W410",
            descricao: "Exposição a um jato de alta pressão - residência",
            favorito: false
        },
        {
            cid10: "W411",
            descricao: "Exposição a um jato de alta pressão - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W412",
            descricao: "Exposição a um jato de alta pressão - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W413",
            descricao: "Exposição a um jato de alta pressão - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W414",
            descricao: "Exposição a um jato de alta pressão - rua e estrada",
            favorito: false
        },
        {
            cid10: "W415",
            descricao: "Exposição a um jato de alta pressão - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W416",
            descricao: "Exposição a um jato de alta pressão - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W417",
            descricao: "Exposição a um jato de alta pressão - fazenda",
            favorito: false
        },
        {
            cid10: "W418",
            descricao: "Exposição a um jato de alta pressão - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W419",
            descricao: "Exposição a um jato de alta pressão - local não especificado",
            favorito: false
        },
        {
            cid10: "W420",
            descricao: "Exposição ao ruído - residência",
            favorito: false
        },
        {
            cid10: "W421",
            descricao: "Exposição ao ruído - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W422",
            descricao: "Exposição ao ruído - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W423",
            descricao: "Exposição ao ruído - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W424",
            descricao: "Exposição ao ruído - rua e estrada",
            favorito: false
        },
        {
            cid10: "W425",
            descricao: "Exposição ao ruído - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W426",
            descricao: "Exposição ao ruído - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W427",
            descricao: "Exposição ao ruído - fazenda",
            favorito: false
        },
        {
            cid10: "W428",
            descricao: "Exposição ao ruído - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W429",
            descricao: "Exposição ao ruído - local não especificado",
            favorito: false
        },
        {
            cid10: "W430",
            descricao: "Exposição à vibração - residência",
            favorito: false
        },
        {
            cid10: "W431",
            descricao: "Exposição à vibração - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W432",
            descricao: "Exposição à vibração - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W433",
            descricao: "Exposição à vibração - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W434",
            descricao: "Exposição à vibração - rua e estrada",
            favorito: false
        },
        {
            cid10: "W435",
            descricao: "Exposição à vibração - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W436",
            descricao: "Exposição à vibração - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W437",
            descricao: "Exposição à vibração - fazenda",
            favorito: false
        },
        {
            cid10: "W438",
            descricao: "Exposição à vibração - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W439",
            descricao: "Exposição à vibração - local não especificado",
            favorito: false
        },
        {
            cid10: "W440",
            descricao: "Penetração de corpo estranho no ou através de olho ou orifício natural - residência",
            favorito: false
        },
        {
            cid10: "W441",
            descricao: "Penetração de corpo estranho no ou através de olho ou orifício natural - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W442",
            descricao: "Penetração de corpo estranho no ou através de olho ou orifício natural - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W443",
            descricao: "Penetração de corpo estranho no ou através de olho ou orifício natural - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W444",
            descricao: "Penetração de corpo estranho no ou através de olho ou orifício natural - rua e estrada",
            favorito: false
        },
        {
            cid10: "W445",
            descricao: "Penetração de corpo estranho no ou através de olho ou orifício natural - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W446",
            descricao: "Penetração de corpo estranho no ou através de olho ou orifício natural - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W447",
            descricao: "Penetração de corpo estranho no ou através de olho ou orifício natural - fazenda",
            favorito: false
        },
        {
            cid10: "W448",
            descricao: "Penetração de corpo estranho no ou através de olho ou orifício natural - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W449",
            descricao: "Penetração de corpo estranho no ou através de olho ou orifício natural - local não especificado",
            favorito: false
        },
        {
            cid10: "W450",
            descricao: "Penetração de corpo ou objeto estranho através da pele - residência",
            favorito: false
        },
        {
            cid10: "W451",
            descricao: "Penetração de corpo ou objeto estranho através da pele - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W452",
            descricao: "Penetração de corpo ou objeto estranho através da pele - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W453",
            descricao: "Penetração de corpo ou objeto estranho através da pele - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W454",
            descricao: "Penetração de corpo ou objeto estranho através da pele - rua e estrada",
            favorito: false
        },
        {
            cid10: "W455",
            descricao: "Penetração de corpo ou objeto estranho através da pele - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W456",
            descricao: "Penetração de corpo ou objeto estranho através da pele - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W457",
            descricao: "Penetração de corpo ou objeto estranho através da pele - fazenda",
            favorito: false
        },
        {
            cid10: "W458",
            descricao: "Penetração de corpo ou objeto estranho através da pele - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W459",
            descricao: "Penetração de corpo ou objeto estranho através da pele - local não especificado",
            favorito: false
        },
        {
            cid10: "W460",
            descricao: "Contato com agulha hipodérmica - residência",
            favorito: false
        },
        {
            cid10: "W461",
            descricao: "Contato com agulha hipodérmica - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W462",
            descricao: "Contato com agulha hipodérmica - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W463",
            descricao: "Contato com agulha hipodérmica - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W464",
            descricao: "Contato com agulha hipodérmica - rua e estrada",
            favorito: false
        },
        {
            cid10: "W465",
            descricao: "Contato com agulha hipodérmica - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W466",
            descricao: "Contato com agulha hipodérmica - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W467",
            descricao: "Contato com agulha hipodérmica - fazenda",
            favorito: false
        },
        {
            cid10: "W468",
            descricao: "Contato com agulha hipodérmica - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W469",
            descricao: "Contato com agulha hipodérmica - local não especificado",
            favorito: false
        },
        {
            cid10: "W490",
            descricao: "Exposição a outras forças mecânicas inanimadas e às não especificadas - residência",
            favorito: false
        },
        {
            cid10: "W491",
            descricao: "Exposição a outras forças mecânicas inanimadas e às não especificadas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W492",
            descricao: "Exposição a outras forças mecânicas inanimadas e às não especificadas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W493",
            descricao: "Exposição a outras forças mecânicas inanimadas e às não especificadas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W494",
            descricao: "Exposição a outras forças mecânicas inanimadas e às não especificadas - rua e estrada",
            favorito: false
        },
        {
            cid10: "W495",
            descricao: "Exposição a outras forças mecânicas inanimadas e às não especificadas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W496",
            descricao: "Exposição a outras forças mecânicas inanimadas e às não especificadas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W497",
            descricao: "Exposição a outras forças mecânicas inanimadas e às não especificadas - fazenda",
            favorito: false
        },
        {
            cid10: "W498",
            descricao: "Exposição a outras forças mecânicas inanimadas e às não especificadas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W499",
            descricao: "Exposição a outras forças mecânicas inanimadas e às não especificadas - local não especificado",
            favorito: false
        },
        {
            cid10: "W500",
            descricao: "Golpe, pancada, pontapé, mordedura ou escoriação infligidos por outra pessoa - residência",
            favorito: false
        },
        {
            cid10: "W501",
            descricao: "Golpe, pancada, pontapé, mordedura ou escoriação infligidos por outra pessoa - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W502",
            descricao: "Golpe, pancada, pontapé, mordedura ou escoriação infligidos por outra pessoa - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W503",
            descricao: "Golpe, pancada, pontapé, mordedura ou escoriação infligidos por outra pessoa - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W504",
            descricao: "Golpe, pancada, pontapé, mordedura ou escoriação infligidos por outra pessoa - rua e estrada",
            favorito: false
        },
        {
            cid10: "W505",
            descricao: "Golpe, pancada, pontapé, mordedura ou escoriação infligidos por outra pessoa - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W506",
            descricao: "Golpe, pancada, pontapé, mordedura ou escoriação infligidos por outra pessoa - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W507",
            descricao: "Golpe, pancada, pontapé, mordedura ou escoriação infligidos por outra pessoa - fazenda",
            favorito: false
        },
        {
            cid10: "W508",
            descricao: "Golpe, pancada, pontapé, mordedura ou escoriação infligidos por outra pessoa - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W509",
            descricao: "Golpe, pancada, pontapé, mordedura ou escoriação infligidos por outra pessoa - local não especificado",
            favorito: false
        },
        {
            cid10: "W510",
            descricao: "Colisão entre duas pessoas - residência",
            favorito: false
        },
        {
            cid10: "W511",
            descricao: "Colisão entre duas pessoas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W512",
            descricao: "Colisão entre duas pessoas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W513",
            descricao: "Colisão entre duas pessoas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W514",
            descricao: "Colisão entre duas pessoas - rua e estrada",
            favorito: false
        },
        {
            cid10: "W515",
            descricao: "Colisão entre duas pessoas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W516",
            descricao: "Colisão entre duas pessoas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W517",
            descricao: "Colisão entre duas pessoas - fazenda",
            favorito: false
        },
        {
            cid10: "W518",
            descricao: "Colisão entre duas pessoas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W519",
            descricao: "Colisão entre duas pessoas - local não especificado",
            favorito: false
        },
        {
            cid10: "W520",
            descricao: "Esmagado, empurrado ou pisoteado por multidão ou debandada em massa de pessoas - residência",
            favorito: false
        },
        {
            cid10: "W521",
            descricao: "Esmagado, empurrado ou pisoteado por multidão ou debandada em massa de pessoas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W522",
            descricao: "Esmagado, empurrado ou pisoteado por multidão ou debandada em massa de pessoas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W523",
            descricao: "Esmagado, empurrado ou pisoteado por multidão ou debandada em massa de pessoas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W524",
            descricao: "Esmagado, empurrado ou pisoteado por multidão ou debandada em massa de pessoas - rua e estrada",
            favorito: false
        },
        {
            cid10: "W525",
            descricao: "Esmagado, empurrado ou pisoteado por multidão ou debandada em massa de pessoas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W526",
            descricao: "Esmagado, empurrado ou pisoteado por multidão ou debandada em massa de pessoas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W527",
            descricao: "Esmagado, empurrado ou pisoteado por multidão ou debandada em massa de pessoas - fazenda",
            favorito: false
        },
        {
            cid10: "W528",
            descricao: "Esmagado, empurrado ou pisoteado por multidão ou debandada em massa de pessoas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W529",
            descricao: "Esmagado, empurrado ou pisoteado por multidão ou debandada em massa de pessoas - local não especificado",
            favorito: false
        },
        {
            cid10: "W530",
            descricao: "Mordedura de rato - residência",
            favorito: false
        },
        {
            cid10: "W531",
            descricao: "Mordedura de rato - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W532",
            descricao: "Mordedura de rato - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W533",
            descricao: "Mordedura de rato - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W534",
            descricao: "Mordedura de rato - rua e estrada",
            favorito: false
        },
        {
            cid10: "W535",
            descricao: "Mordedura de rato - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W536",
            descricao: "Mordedura de rato - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W537",
            descricao: "Mordedura de rato - fazenda",
            favorito: false
        },
        {
            cid10: "W538",
            descricao: "Mordedura de rato - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W539",
            descricao: "Mordedura de rato - local não especificado",
            favorito: false
        },
        {
            cid10: "W540",
            descricao: "Mordedura ou golpe provocado por cão - residência",
            favorito: false
        },
        {
            cid10: "W541",
            descricao: "Mordedura ou golpe provocado por cão - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W542",
            descricao: "Mordedura ou golpe provocado por cão - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W543",
            descricao: "Mordedura ou golpe provocado por cão - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W544",
            descricao: "Mordedura ou golpe provocado por cão - rua e estrada",
            favorito: false
        },
        {
            cid10: "W545",
            descricao: "Mordedura ou golpe provocado por cão - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W546",
            descricao: "Mordedura ou golpe provocado por cão - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W547",
            descricao: "Mordedura ou golpe provocado por cão - fazenda",
            favorito: false
        },
        {
            cid10: "W548",
            descricao: "Mordedura ou golpe provocado por cão - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W549",
            descricao: "Mordedura ou golpe provocado por cão - local não especificado",
            favorito: false
        },
        {
            cid10: "W550",
            descricao: "Mordedura ou golpe provocado por outros animais mamíferos - residência",
            favorito: false
        },
        {
            cid10: "W551",
            descricao: "Mordedura ou golpe provocado por outros animais mamíferos - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W552",
            descricao: "Mordedura ou golpe provocado por outros animais mamíferos - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W553",
            descricao: "Mordedura ou golpe provocado por outros animais mamíferos - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W554",
            descricao: "Mordedura ou golpe provocado por outros animais mamíferos - rua e estrada",
            favorito: false
        },
        {
            cid10: "W555",
            descricao: "Mordedura ou golpe provocado por outros animais mamíferos - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W556",
            descricao: "Mordedura ou golpe provocado por outros animais mamíferos - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W557",
            descricao: "Mordedura ou golpe provocado por outros animais mamíferos - fazenda",
            favorito: false
        },
        {
            cid10: "W558",
            descricao: "Mordedura ou golpe provocado por outros animais mamíferos - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W559",
            descricao: "Mordedura ou golpe provocado por outros animais mamíferos - local não especificado",
            favorito: false
        },
        {
            cid10: "W560",
            descricao: "Contato com animais marinhos - residência",
            favorito: false
        },
        {
            cid10: "W561",
            descricao: "Contato com animais marinhos - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W562",
            descricao: "Contato com animais marinhos - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W563",
            descricao: "Contato com animais marinhos - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W564",
            descricao: "Contato com animais marinhos - rua e estrada",
            favorito: false
        },
        {
            cid10: "W565",
            descricao: "Contato com animais marinhos - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W566",
            descricao: "Contato com animais marinhos - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W567",
            descricao: "Contato com animais marinhos - fazenda",
            favorito: false
        },
        {
            cid10: "W568",
            descricao: "Contato com animais marinhos - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W569",
            descricao: "Contato com animais marinhos - local não especificado",
            favorito: false
        },
        {
            cid10: "W570",
            descricao: "Mordeduras e picadas de inseto e de outros artrópodes, não-venenosos - residência",
            favorito: false
        },
        {
            cid10: "W571",
            descricao: "Mordeduras e picadas de inseto e de outros artrópodes, não-venenosos - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W572",
            descricao: "Mordeduras e picadas de inseto e de outros artrópodes, não-venenosos - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W573",
            descricao: "Mordeduras e picadas de inseto e de outros artrópodes, não-venenosos - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W574",
            descricao: "Mordeduras e picadas de inseto e de outros artrópodes, não-venenosos - rua e estrada",
            favorito: false
        },
        {
            cid10: "W575",
            descricao: "Mordeduras e picadas de inseto e de outros artrópodes, não-venenosos - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W576",
            descricao: "Mordeduras e picadas de inseto e de outros artrópodes, não-venenosos - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W577",
            descricao: "Mordeduras e picadas de inseto e de outros artrópodes, não-venenosos - fazenda",
            favorito: false
        },
        {
            cid10: "W578",
            descricao: "Mordeduras e picadas de inseto e de outros artrópodes, não-venenosos - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W579",
            descricao: "Mordeduras e picadas de inseto e de outros artrópodes, não-venenosos - local não especificado",
            favorito: false
        },
        {
            cid10: "W580",
            descricao: "Mordedura ou golpe provocado por crocodilo ou aligátor - residência",
            favorito: false
        },
        {
            cid10: "W581",
            descricao: "Mordedura ou golpe provocado por crocodilo ou aligátor - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W582",
            descricao: "Mordedura ou golpe provocado por crocodilo ou aligátor - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W583",
            descricao: "Mordedura ou golpe provocado por crocodilo ou aligátor - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W584",
            descricao: "Mordedura ou golpe provocado por crocodilo ou aligátor - rua e estrada",
            favorito: false
        },
        {
            cid10: "W585",
            descricao: "Mordedura ou golpe provocado por crocodilo ou aligátor - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W586",
            descricao: "Mordedura ou golpe provocado por crocodilo ou aligátor - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W587",
            descricao: "Mordedura ou golpe provocado por crocodilo ou aligátor - fazenda",
            favorito: false
        },
        {
            cid10: "W588",
            descricao: "Mordedura ou golpe provocado por crocodilo ou aligátor - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W589",
            descricao: "Mordedura ou golpe provocado por crocodilo ou aligátor - local não especificado",
            favorito: false
        },
        {
            cid10: "W590",
            descricao: "Mordedura ou esmagamento provocado por outros répteis - residência",
            favorito: false
        },
        {
            cid10: "W591",
            descricao: "Mordedura ou esmagamento provocado por outros répteis - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W592",
            descricao: "Mordedura ou esmagamento provocado por outros répteis - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W593",
            descricao: "Mordedura ou esmagamento provocado por outros répteis - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W594",
            descricao: "Mordedura ou esmagamento provocado por outros répteis - rua e estrada",
            favorito: false
        },
        {
            cid10: "W595",
            descricao: "Mordedura ou esmagamento provocado por outros répteis - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W596",
            descricao: "Mordedura ou esmagamento provocado por outros répteis - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W597",
            descricao: "Mordedura ou esmagamento provocado por outros répteis - fazenda",
            favorito: false
        },
        {
            cid10: "W598",
            descricao: "Mordedura ou esmagamento provocado por outros répteis - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W599",
            descricao: "Mordedura ou esmagamento provocado por outros répteis - local não especificado",
            favorito: false
        },
        {
            cid10: "W600",
            descricao: "Contato com espinhos de plantas ou com folhas aguçadas - residência",
            favorito: false
        },
        {
            cid10: "W601",
            descricao: "Contato com espinhos de plantas ou com folhas aguçadas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W602",
            descricao: "Contato com espinhos de plantas ou com folhas aguçadas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W603",
            descricao: "Contato com espinhos de plantas ou com folhas aguçadas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W604",
            descricao: "Contato com espinhos de plantas ou com folhas aguçadas - rua e estrada",
            favorito: false
        },
        {
            cid10: "W605",
            descricao: "Contato com espinhos de plantas ou com folhas aguçadas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W606",
            descricao: "Contato com espinhos de plantas ou com folhas aguçadas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W607",
            descricao: "Contato com espinhos de plantas ou com folhas aguçadas - fazenda",
            favorito: false
        },
        {
            cid10: "W608",
            descricao: "Contato com espinhos de plantas ou com folhas aguçadas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W609",
            descricao: "Contato com espinhos de plantas ou com folhas aguçadas - local não especificado",
            favorito: false
        },
        {
            cid10: "W640",
            descricao: "Exposição a outras forças mecânicas animadas e às não especificadas - residência",
            favorito: false
        },
        {
            cid10: "W641",
            descricao: "Exposição a outras forças mecânicas animadas e às não especificadas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W642",
            descricao: "Exposição a outras forças mecânicas animadas e às não especificadas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W643",
            descricao: "Exposição a outras forças mecânicas animadas e às não especificadas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W644",
            descricao: "Exposição a outras forças mecânicas animadas e às não especificadas - rua e estrada",
            favorito: false
        },
        {
            cid10: "W645",
            descricao: "Exposição a outras forças mecânicas animadas e às não especificadas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W646",
            descricao: "Exposição a outras forças mecânicas animadas e às não especificadas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W647",
            descricao: "Exposição a outras forças mecânicas animadas e às não especificadas - fazenda",
            favorito: false
        },
        {
            cid10: "W648",
            descricao: "Exposição a outras forças mecânicas animadas e às não especificadas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W649",
            descricao: "Exposição a outras forças mecânicas animadas e às não especificadas - local não especificado",
            favorito: false
        },
        {
            cid10: "W650",
            descricao: "Afogamento e submersão durante banho em banheira - residência",
            favorito: false
        },
        {
            cid10: "W651",
            descricao: "Afogamento e submersão durante banho em banheira - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W652",
            descricao: "Afogamento e submersão durante banho em banheira - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W653",
            descricao: "Afogamento e submersão durante banho em banheira - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W654",
            descricao: "Afogamento e submersão durante banho em banheira - rua e estrada",
            favorito: false
        },
        {
            cid10: "W655",
            descricao: "Afogamento e submersão durante banho em banheira - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W656",
            descricao: "Afogamento e submersão durante banho em banheira - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W657",
            descricao: "Afogamento e submersão durante banho em banheira - fazenda",
            favorito: false
        },
        {
            cid10: "W658",
            descricao: "Afogamento e submersão durante banho em banheira - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W659",
            descricao: "Afogamento e submersão durante banho em banheira - local não especificado",
            favorito: false
        },
        {
            cid10: "W660",
            descricao: "Afogamento e submersão consecutiva a queda dentro de uma banheira - residência",
            favorito: false
        },
        {
            cid10: "W661",
            descricao: "Afogamento e submersão consecutiva a queda dentro de uma banheira - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W662",
            descricao: "Afogamento e submersão consecutiva a queda dentro de uma banheira - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W663",
            descricao: "Afogamento e submersão consecutiva a queda dentro de uma banheira - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W664",
            descricao: "Afogamento e submersão consecutiva a queda dentro de uma banheira - rua e estrada",
            favorito: false
        },
        {
            cid10: "W665",
            descricao: "Afogamento e submersão consecutiva a queda dentro de uma banheira - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W666",
            descricao: "Afogamento e submersão consecutiva a queda dentro de uma banheira - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W667",
            descricao: "Afogamento e submersão consecutiva a queda dentro de uma banheira - fazenda",
            favorito: false
        },
        {
            cid10: "W668",
            descricao: "Afogamento e submersão consecutiva a queda dentro de uma banheira - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W669",
            descricao: "Afogamento e submersão consecutiva a queda dentro de uma banheira - local não especificado",
            favorito: false
        },
        {
            cid10: "W670",
            descricao: "Afogamento e submersão em piscina - residência",
            favorito: false
        },
        {
            cid10: "W671",
            descricao: "Afogamento e submersão em piscina - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W672",
            descricao: "Afogamento e submersão em piscina - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W673",
            descricao: "Afogamento e submersão em piscina - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W674",
            descricao: "Afogamento e submersão em piscina - rua e estrada",
            favorito: false
        },
        {
            cid10: "W675",
            descricao: "Afogamento e submersão em piscina - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W676",
            descricao: "Afogamento e submersão em piscina - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W677",
            descricao: "Afogamento e submersão em piscina - fazenda",
            favorito: false
        },
        {
            cid10: "W678",
            descricao: "Afogamento e submersão em piscina - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W679",
            descricao: "Afogamento e submersão em piscina - local não especificado",
            favorito: false
        },
        {
            cid10: "W680",
            descricao: "Afogamento e submersão conseqüente a queda dentro de uma piscina - residência",
            favorito: false
        },
        {
            cid10: "W681",
            descricao: "Afogamento e submersão conseqüente a queda dentro de uma piscina - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W682",
            descricao: "Afogamento e submersão conseqüente a queda dentro de uma piscina - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W683",
            descricao: "Afogamento e submersão conseqüente a queda dentro de uma piscina - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W684",
            descricao: "Afogamento e submersão conseqüente a queda dentro de uma piscina - rua e estrada",
            favorito: false
        },
        {
            cid10: "W685",
            descricao: "Afogamento e submersão conseqüente a queda dentro de uma piscina - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W686",
            descricao: "Afogamento e submersão conseqüente a queda dentro de uma piscina - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W687",
            descricao: "Afogamento e submersão conseqüente a queda dentro de uma piscina - fazenda",
            favorito: false
        },
        {
            cid10: "W688",
            descricao: "Afogamento e submersão conseqüente a queda dentro de uma piscina - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W689",
            descricao: "Afogamento e submersão conseqüente a queda dentro de uma piscina - local não especificado",
            favorito: false
        },
        {
            cid10: "W690",
            descricao: "Afogamento e submersão em águas naturais - residência",
            favorito: false
        },
        {
            cid10: "W691",
            descricao: "Afogamento e submersão em águas naturais - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W692",
            descricao: "Afogamento e submersão em águas naturais - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W693",
            descricao: "Afogamento e submersão em águas naturais - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W694",
            descricao: "Afogamento e submersão em águas naturais - rua e estrada",
            favorito: false
        },
        {
            cid10: "W695",
            descricao: "Afogamento e submersão em águas naturais - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W696",
            descricao: "Afogamento e submersão em águas naturais - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W697",
            descricao: "Afogamento e submersão em águas naturais - fazenda",
            favorito: false
        },
        {
            cid10: "W698",
            descricao: "Afogamento e submersão em águas naturais - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W699",
            descricao: "Afogamento e submersão em águas naturais - local não especificado",
            favorito: false
        },
        {
            cid10: "W700",
            descricao: "Afogamento e submersão conseqüentes a queda dentro de águas naturais - residência",
            favorito: false
        },
        {
            cid10: "W701",
            descricao: "Afogamento e submersão conseqüentes a queda dentro de águas naturais - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W702",
            descricao: "Afogamento e submersão conseqüentes a queda dentro de águas naturais - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W703",
            descricao: "Afogamento e submersão conseqüentes a queda dentro de águas naturais - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W704",
            descricao: "Afogamento e submersão conseqüentes a queda dentro de águas naturais - rua e estrada",
            favorito: false
        },
        {
            cid10: "W705",
            descricao: "Afogamento e submersão conseqüentes a queda dentro de águas naturais - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W706",
            descricao: "Afogamento e submersão conseqüentes a queda dentro de águas naturais - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W707",
            descricao: "Afogamento e submersão conseqüentes a queda dentro de águas naturais - fazenda",
            favorito: false
        },
        {
            cid10: "W708",
            descricao: "Afogamento e submersão conseqüentes a queda dentro de águas naturais - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W709",
            descricao: "Afogamento e submersão conseqüentes a queda dentro de águas naturais - local não especificado",
            favorito: false
        },
        {
            cid10: "W730",
            descricao: "Outros afogamentos e submersão especificados - residência",
            favorito: false
        },
        {
            cid10: "W731",
            descricao: "Outros afogamentos e submersão especificados - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W732",
            descricao: "Outros afogamentos e submersão especificados - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W733",
            descricao: "Outros afogamentos e submersão especificados - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W734",
            descricao: "Outros afogamentos e submersão especificados - rua e estrada",
            favorito: false
        },
        {
            cid10: "W735",
            descricao: "Outros afogamentos e submersão especificados - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W736",
            descricao: "Outros afogamentos e submersão especificados - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W737",
            descricao: "Outros afogamentos e submersão especificados - fazenda",
            favorito: false
        },
        {
            cid10: "W738",
            descricao: "Outros afogamentos e submersão especificados - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W739",
            descricao: "Outros afogamentos e submersão especificados - local não especificado",
            favorito: false
        },
        {
            cid10: "W740",
            descricao: "Afogamento e submersão não especificados - residência",
            favorito: false
        },
        {
            cid10: "W741",
            descricao: "Afogamento e submersão não especificados - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W742",
            descricao: "Afogamento e submersão não especificados - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W743",
            descricao: "Afogamento e submersão não especificados - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W744",
            descricao: "Afogamento e submersão não especificados - rua e estrada",
            favorito: false
        },
        {
            cid10: "W745",
            descricao: "Afogamento e submersão não especificados - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W746",
            descricao: "Afogamento e submersão não especificados - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W747",
            descricao: "Afogamento e submersão não especificados - fazenda",
            favorito: false
        },
        {
            cid10: "W748",
            descricao: "Afogamento e submersão não especificados - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W749",
            descricao: "Afogamento e submersão não especificados - local não especificado",
            favorito: false
        },
        {
            cid10: "W750",
            descricao: "Sufocação e estrangulamento acidental na cama - residência",
            favorito: false
        },
        {
            cid10: "W751",
            descricao: "Sufocação e estrangulamento acidental na cama - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W752",
            descricao: "Sufocação e estrangulamento acidental na cama - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W753",
            descricao: "Sufocação e estrangulamento acidental na cama - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W754",
            descricao: "Sufocação e estrangulamento acidental na cama - rua e estrada",
            favorito: false
        },
        {
            cid10: "W755",
            descricao: "Sufocação e estrangulamento acidental na cama - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W756",
            descricao: "Sufocação e estrangulamento acidental na cama - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W757",
            descricao: "Sufocação e estrangulamento acidental na cama - fazenda",
            favorito: false
        },
        {
            cid10: "W758",
            descricao: "Sufocação e estrangulamento acidental na cama - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W759",
            descricao: "Sufocação e estrangulamento acidental na cama - local não especificado",
            favorito: false
        },
        {
            cid10: "W760",
            descricao: "Outro enforcamento e estrangulamento acidental - residência",
            favorito: false
        },
        {
            cid10: "W761",
            descricao: "Outro enforcamento e estrangulamento acidental - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W762",
            descricao: "Outro enforcamento e estrangulamento acidental - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W763",
            descricao: "Outro enforcamento e estrangulamento acidental - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W764",
            descricao: "Outro enforcamento e estrangulamento acidental - rua e estrada",
            favorito: false
        },
        {
            cid10: "W765",
            descricao: "Outro enforcamento e estrangulamento acidental - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W766",
            descricao: "Outro enforcamento e estrangulamento acidental - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W767",
            descricao: "Outro enforcamento e estrangulamento acidental - fazenda",
            favorito: false
        },
        {
            cid10: "W768",
            descricao: "Outro enforcamento e estrangulamento acidental - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W769",
            descricao: "Outro enforcamento e estrangulamento acidental - local não especificado",
            favorito: false
        },
        {
            cid10: "W770",
            descricao: "Risco a respiração devido a desmoronamento, queda de terra e de outras substâncias - residência",
            favorito: false
        },
        {
            cid10: "W771",
            descricao: "Risco a respiração devido a desmoronamento, queda de terra e de outras substâncias - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W772",
            descricao: "Risco a respiração devido a desmoronamento, queda de terra e de outras substâncias - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W773",
            descricao: "Risco a respiração devido a desmoronamento, queda de terra e de outras substâncias - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W774",
            descricao: "Risco a respiração devido a desmoronamento, queda de terra e de outras substâncias - rua e estrada",
            favorito: false
        },
        {
            cid10: "W775",
            descricao: "Risco a respiração devido a desmoronamento, queda de terra e de outras substâncias - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W776",
            descricao: "Risco a respiração devido a desmoronamento, queda de terra e de outras substâncias - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W777",
            descricao: "Risco a respiração devido a desmoronamento, queda de terra e de outras substâncias - fazenda",
            favorito: false
        },
        {
            cid10: "W778",
            descricao: "Risco a respiração devido a desmoronamento, queda de terra e de outras substâncias - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W779",
            descricao: "Risco a respiração devido a desmoronamento, queda de terra e de outras substâncias - local não especificado",
            favorito: false
        },
        {
            cid10: "W780",
            descricao: "Inalação do conteúdo gástrico - residência",
            favorito: false
        },
        {
            cid10: "W781",
            descricao: "Inalação do conteúdo gástrico - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W782",
            descricao: "Inalação do conteúdo gástrico - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W783",
            descricao: "Inalação do conteúdo gástrico - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W784",
            descricao: "Inalação do conteúdo gástrico - rua e estrada",
            favorito: false
        },
        {
            cid10: "W785",
            descricao: "Inalação do conteúdo gástrico - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W786",
            descricao: "Inalação do conteúdo gástrico - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W787",
            descricao: "Inalação do conteúdo gástrico - fazenda",
            favorito: false
        },
        {
            cid10: "W788",
            descricao: "Inalação do conteúdo gástrico - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W789",
            descricao: "Inalação do conteúdo gástrico - local não especificado",
            favorito: false
        },
        {
            cid10: "W790",
            descricao: "Inalação e ingestão de alimentos causando obstrução do trato respiratório - residência",
            favorito: false
        },
        {
            cid10: "W791",
            descricao: "Inalação e ingestão de alimentos causando obstrução do trato respiratório - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W792",
            descricao: "Inalação e ingestão de alimentos causando obstrução do trato respiratório - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W793",
            descricao: "Inalação e ingestão de alimentos causando obstrução do trato respiratório - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W794",
            descricao: "Inalação e ingestão de alimentos causando obstrução do trato respiratório - rua e estrada",
            favorito: false
        },
        {
            cid10: "W795",
            descricao: "Inalação e ingestão de alimentos causando obstrução do trato respiratório - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W796",
            descricao: "Inalação e ingestão de alimentos causando obstrução do trato respiratório - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W797",
            descricao: "Inalação e ingestão de alimentos causando obstrução do trato respiratório - fazenda",
            favorito: false
        },
        {
            cid10: "W798",
            descricao: "Inalação e ingestão de alimentos causando obstrução do trato respiratório - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W799",
            descricao: "Inalação e ingestão de alimentos causando obstrução do trato respiratório - local não especificado",
            favorito: false
        },
        {
            cid10: "W800",
            descricao: "Inalação e ingestão de outros objetos causando obstrução do trato respiratório - residência",
            favorito: false
        },
        {
            cid10: "W801",
            descricao: "Inalação e ingestão de outros objetos causando obstrução do trato respiratório - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W802",
            descricao: "Inalação e ingestão de outros objetos causando obstrução do trato respiratório - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W803",
            descricao: "Inalação e ingestão de outros objetos causando obstrução do trato respiratório - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W804",
            descricao: "Inalação e ingestão de outros objetos causando obstrução do trato respiratório - rua e estrada",
            favorito: false
        },
        {
            cid10: "W805",
            descricao: "Inalação e ingestão de outros objetos causando obstrução do trato respiratório - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W806",
            descricao: "Inalação e ingestão de outros objetos causando obstrução do trato respiratório - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W807",
            descricao: "Inalação e ingestão de outros objetos causando obstrução do trato respiratório - fazenda",
            favorito: false
        },
        {
            cid10: "W808",
            descricao: "Inalação e ingestão de outros objetos causando obstrução do trato respiratório - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W809",
            descricao: "Inalação e ingestão de outros objetos causando obstrução do trato respiratório - local não especificado",
            favorito: false
        },
        {
            cid10: "W810",
            descricao: "Confinado ou aprisionado em um ambiente pobre em oxigênio - residência",
            favorito: false
        },
        {
            cid10: "W811",
            descricao: "Confinado ou aprisionado em um ambiente pobre em oxigênio - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W812",
            descricao: "Confinado ou aprisionado em um ambiente pobre em oxigênio - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W813",
            descricao: "Confinado ou aprisionado em um ambiente pobre em oxigênio - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W814",
            descricao: "Confinado ou aprisionado em um ambiente pobre em oxigênio - rua e estrada",
            favorito: false
        },
        {
            cid10: "W815",
            descricao: "Confinado ou aprisionado em um ambiente pobre em oxigênio - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W816",
            descricao: "Confinado ou aprisionado em um ambiente pobre em oxigênio - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W817",
            descricao: "Confinado ou aprisionado em um ambiente pobre em oxigênio - fazenda",
            favorito: false
        },
        {
            cid10: "W818",
            descricao: "Confinado ou aprisionado em um ambiente pobre em oxigênio - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W819",
            descricao: "Confinado ou aprisionado em um ambiente pobre em oxigênio - local não especificado",
            favorito: false
        },
        {
            cid10: "W830",
            descricao: "Outros riscos especificados à respiração - residência",
            favorito: false
        },
        {
            cid10: "W831",
            descricao: "Outros riscos especificados à respiração - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W832",
            descricao: "Outros riscos especificados à respiração - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W833",
            descricao: "Outros riscos especificados à respiração - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W834",
            descricao: "Outros riscos especificados à respiração - rua e estrada",
            favorito: false
        },
        {
            cid10: "W835",
            descricao: "Outros riscos especificados à respiração - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W836",
            descricao: "Outros riscos especificados à respiração - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W837",
            descricao: "Outros riscos especificados à respiração - fazenda",
            favorito: false
        },
        {
            cid10: "W838",
            descricao: "Outros riscos especificados à respiração - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W839",
            descricao: "Outros riscos especificados à respiração - local não especificado",
            favorito: false
        },
        {
            cid10: "W840",
            descricao: "Riscos não especificados à respiração - residência",
            favorito: false
        },
        {
            cid10: "W841",
            descricao: "Riscos não especificados à respiração - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W842",
            descricao: "Riscos não especificados à respiração - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W843",
            descricao: "Riscos não especificados à respiração - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W844",
            descricao: "Riscos não especificados à respiração - rua e estrada",
            favorito: false
        },
        {
            cid10: "W845",
            descricao: "Riscos não especificados à respiração - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W846",
            descricao: "Riscos não especificados à respiração - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W847",
            descricao: "Riscos não especificados à respiração - fazenda",
            favorito: false
        },
        {
            cid10: "W848",
            descricao: "Riscos não especificados à respiração - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W849",
            descricao: "Riscos não especificados à respiração - local não especificado",
            favorito: false
        },
        {
            cid10: "W850",
            descricao: "Exposição a linhas de transmissão de corrente elétrica - residência",
            favorito: false
        },
        {
            cid10: "W851",
            descricao: "Exposição a linhas de transmissão de corrente elétrica - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W852",
            descricao: "Exposição a linhas de transmissão de corrente elétrica - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W853",
            descricao: "Exposição a linhas de transmissão de corrente elétrica - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W854",
            descricao: "Exposição a linhas de transmissão de corrente elétrica - rua e estrada",
            favorito: false
        },
        {
            cid10: "W855",
            descricao: "Exposição a linhas de transmissão de corrente elétrica - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W856",
            descricao: "Exposição a linhas de transmissão de corrente elétrica - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W857",
            descricao: "Exposição a linhas de transmissão de corrente elétrica - fazenda",
            favorito: false
        },
        {
            cid10: "W858",
            descricao: "Exposição a linhas de transmissão de corrente elétrica - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W859",
            descricao: "Exposição a linhas de transmissão de corrente elétrica - local não especificado",
            favorito: false
        },
        {
            cid10: "W860",
            descricao: "Exposição a outra corrente elétrica especificada - residência",
            favorito: false
        },
        {
            cid10: "W861",
            descricao: "Exposição a outra corrente elétrica especificada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W862",
            descricao: "Exposição a outra corrente elétrica especificada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W863",
            descricao: "Exposição a outra corrente elétrica especificada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W864",
            descricao: "Exposição a outra corrente elétrica especificada - rua e estrada",
            favorito: false
        },
        {
            cid10: "W865",
            descricao: "Exposição a outra corrente elétrica especificada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W866",
            descricao: "Exposição a outra corrente elétrica especificada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W867",
            descricao: "Exposição a outra corrente elétrica especificada - fazenda",
            favorito: false
        },
        {
            cid10: "W868",
            descricao: "Exposição a outra corrente elétrica especificada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W869",
            descricao: "Exposição a outra corrente elétrica especificada - local não especificado",
            favorito: false
        },
        {
            cid10: "W870",
            descricao: "Exposição a corrente elétrica não especificada - residência",
            favorito: false
        },
        {
            cid10: "W871",
            descricao: "Exposição a corrente elétrica não especificada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W872",
            descricao: "Exposição a corrente elétrica não especificada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W873",
            descricao: "Exposição a corrente elétrica não especificada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W874",
            descricao: "Exposição a corrente elétrica não especificada - rua e estrada",
            favorito: false
        },
        {
            cid10: "W875",
            descricao: "Exposição a corrente elétrica não especificada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W876",
            descricao: "Exposição a corrente elétrica não especificada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W877",
            descricao: "Exposição a corrente elétrica não especificada - fazenda",
            favorito: false
        },
        {
            cid10: "W878",
            descricao: "Exposição a corrente elétrica não especificada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W879",
            descricao: "Exposição a corrente elétrica não especificada - local não especificado",
            favorito: false
        },
        {
            cid10: "W880",
            descricao: "Exposição a radiação ionizante - residência",
            favorito: false
        },
        {
            cid10: "W881",
            descricao: "Exposição a radiação ionizante - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W882",
            descricao: "Exposição a radiação ionizante - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W883",
            descricao: "Exposição a radiação ionizante - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W884",
            descricao: "Exposição a radiação ionizante - rua e estrada",
            favorito: false
        },
        {
            cid10: "W885",
            descricao: "Exposição a radiação ionizante - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W886",
            descricao: "Exposição a radiação ionizante - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W887",
            descricao: "Exposição a radiação ionizante - fazenda",
            favorito: false
        },
        {
            cid10: "W888",
            descricao: "Exposição a radiação ionizante - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W889",
            descricao: "Exposição a radiação ionizante - local não especificado",
            favorito: false
        },
        {
            cid10: "W890",
            descricao: "Exposição a fontes luminosas artificiais visíveis ou à luz ultravioleta - residência",
            favorito: false
        },
        {
            cid10: "W891",
            descricao: "Exposição a fontes luminosas artificiais visíveis ou à luz ultravioleta - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W892",
            descricao: "Exposição a fontes luminosas artificiais visíveis ou à luz ultravioleta - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W893",
            descricao: "Exposição a fontes luminosas artificiais visíveis ou à luz ultravioleta - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W894",
            descricao: "Exposição a fontes luminosas artificiais visíveis ou à luz ultravioleta - rua e estrada",
            favorito: false
        },
        {
            cid10: "W895",
            descricao: "Exposição a fontes luminosas artificiais visíveis ou à luz ultravioleta - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W896",
            descricao: "Exposição a fontes luminosas artificiais visíveis ou à luz ultravioleta - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W897",
            descricao: "Exposição a fontes luminosas artificiais visíveis ou à luz ultravioleta - fazenda",
            favorito: false
        },
        {
            cid10: "W898",
            descricao: "Exposição a fontes luminosas artificiais visíveis ou à luz ultravioleta - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W899",
            descricao: "Exposição a fontes luminosas artificiais visíveis ou à luz ultravioleta - local não especificado",
            favorito: false
        },
        {
            cid10: "W900",
            descricao: "Exposição a outros tipos de radiação não-ionizante - residência",
            favorito: false
        },
        {
            cid10: "W901",
            descricao: "Exposição a outros tipos de radiação não-ionizante - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W902",
            descricao: "Exposição a outros tipos de radiação não-ionizante - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W903",
            descricao: "Exposição a outros tipos de radiação não-ionizante - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W904",
            descricao: "Exposição a outros tipos de radiação não-ionizante - rua e estrada",
            favorito: false
        },
        {
            cid10: "W905",
            descricao: "Exposição a outros tipos de radiação não-ionizante - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W906",
            descricao: "Exposição a outros tipos de radiação não-ionizante - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W907",
            descricao: "Exposição a outros tipos de radiação não-ionizante - fazenda",
            favorito: false
        },
        {
            cid10: "W908",
            descricao: "Exposição a outros tipos de radiação não-ionizante - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W909",
            descricao: "Exposição a outros tipos de radiação não-ionizante - local não especificado",
            favorito: false
        },
        {
            cid10: "W910",
            descricao: "Exposição a tipo não especificado de radiação - residência",
            favorito: false
        },
        {
            cid10: "W911",
            descricao: "Exposição a tipo não especificado de radiação - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W912",
            descricao: "Exposição a tipo não especificado de radiação - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W913",
            descricao: "Exposição a tipo não especificado de radiação - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W914",
            descricao: "Exposição a tipo não especificado de radiação - rua e estrada",
            favorito: false
        },
        {
            cid10: "W915",
            descricao: "Exposição a tipo não especificado de radiação - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W916",
            descricao: "Exposição a tipo não especificado de radiação - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W917",
            descricao: "Exposição a tipo não especificado de radiação - fazenda",
            favorito: false
        },
        {
            cid10: "W918",
            descricao: "Exposição a tipo não especificado de radiação - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W919",
            descricao: "Exposição a tipo não especificado de radiação - local não especificado",
            favorito: false
        },
        {
            cid10: "W920",
            descricao: "Exposição a um calor excessivo de origem artificial - residência",
            favorito: false
        },
        {
            cid10: "W921",
            descricao: "Exposição a um calor excessivo de origem artificial - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W922",
            descricao: "Exposição a um calor excessivo de origem artificial - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W923",
            descricao: "Exposição a um calor excessivo de origem artificial - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W924",
            descricao: "Exposição a um calor excessivo de origem artificial - rua e estrada",
            favorito: false
        },
        {
            cid10: "W925",
            descricao: "Exposição a um calor excessivo de origem artificial - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W926",
            descricao: "Exposição a um calor excessivo de origem artificial - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W927",
            descricao: "Exposição a um calor excessivo de origem artificial - fazenda",
            favorito: false
        },
        {
            cid10: "W928",
            descricao: "Exposição a um calor excessivo de origem artificial - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W929",
            descricao: "Exposição a um calor excessivo de origem artificial - local não especificado",
            favorito: false
        },
        {
            cid10: "W930",
            descricao: "Exposição a um frio excessivo de origem artificial - residência",
            favorito: false
        },
        {
            cid10: "W931",
            descricao: "Exposição a um frio excessivo de origem artificial - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W932",
            descricao: "Exposição a um frio excessivo de origem artificial - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W933",
            descricao: "Exposição a um frio excessivo de origem artificial - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W934",
            descricao: "Exposição a um frio excessivo de origem artificial - rua e estrada",
            favorito: false
        },
        {
            cid10: "W935",
            descricao: "Exposição a um frio excessivo de origem artificial - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W936",
            descricao: "Exposição a um frio excessivo de origem artificial - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W937",
            descricao: "Exposição a um frio excessivo de origem artificial - fazenda",
            favorito: false
        },
        {
            cid10: "W938",
            descricao: "Exposição a um frio excessivo de origem artificial - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W939",
            descricao: "Exposição a um frio excessivo de origem artificial - local não especificado",
            favorito: false
        },
        {
            cid10: "W940",
            descricao: "Exposição a alta, baixa e a variações da pressão atmosférica - residência",
            favorito: false
        },
        {
            cid10: "W941",
            descricao: "Exposição a alta, baixa e a variações da pressão atmosférica - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W942",
            descricao: "Exposição a alta, baixa e a variações da pressão atmosférica - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W943",
            descricao: "Exposição a alta, baixa e a variações da pressão atmosférica - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W944",
            descricao: "Exposição a alta, baixa e a variações da pressão atmosférica - rua e estrada",
            favorito: false
        },
        {
            cid10: "W945",
            descricao: "Exposição a alta, baixa e a variações da pressão atmosférica - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W946",
            descricao: "Exposição a alta, baixa e a variações da pressão atmosférica - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W947",
            descricao: "Exposição a alta, baixa e a variações da pressão atmosférica - fazenda",
            favorito: false
        },
        {
            cid10: "W948",
            descricao: "Exposição a alta, baixa e a variações da pressão atmosférica - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W949",
            descricao: "Exposição a alta, baixa e a variações da pressão atmosférica - local não especificado",
            favorito: false
        },
        {
            cid10: "W990",
            descricao: "Exposição a outros fatores ambientais artificiais e aos não especificados - residência",
            favorito: false
        },
        {
            cid10: "W991",
            descricao: "Exposição a outros fatores ambientais artificiais e aos não especificados - habitação coletiva",
            favorito: false
        },
        {
            cid10: "W992",
            descricao: "Exposição a outros fatores ambientais artificiais e aos não especificados - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "W993",
            descricao: "Exposição a outros fatores ambientais artificiais e aos não especificados - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "W994",
            descricao: "Exposição a outros fatores ambientais artificiais e aos não especificados - rua e estrada",
            favorito: false
        },
        {
            cid10: "W995",
            descricao: "Exposição a outros fatores ambientais artificiais e aos não especificados - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "W996",
            descricao: "Exposição a outros fatores ambientais artificiais e aos não especificados - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "W997",
            descricao: "Exposição a outros fatores ambientais artificiais e aos não especificados - fazenda",
            favorito: false
        },
        {
            cid10: "W998",
            descricao: "Exposição a outros fatores ambientais artificiais e aos não especificados - outros locais especificados",
            favorito: false
        },
        {
            cid10: "W999",
            descricao: "Exposição a outros fatores ambientais artificiais e aos não especificados - local não especificado",
            favorito: false
        },
        {
            cid10: "X000",
            descricao: "Exposição a fogo não-controlado em um edifício ou outro tipo de construção - residência",
            favorito: false
        },
        {
            cid10: "X001",
            descricao: "Exposição a fogo não-controlado em um edifício ou outro tipo de construção - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X002",
            descricao: "Exposição a fogo não-controlado em um edifício ou outro tipo de construção - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X003",
            descricao: "Exposição a fogo não-controlado em um edifício ou outro tipo de construção - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X004",
            descricao: "Exposição a fogo não-controlado em um edifício ou outro tipo de construção - rua e estrada",
            favorito: false
        },
        {
            cid10: "X005",
            descricao: "Exposição a fogo não-controlado em um edifício ou outro tipo de construção - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X006",
            descricao: "Exposição a fogo não-controlado em um edifício ou outro tipo de construção - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X007",
            descricao: "Exposição a fogo não-controlado em um edifício ou outro tipo de construção - fazenda",
            favorito: false
        },
        {
            cid10: "X008",
            descricao: "Exposição a fogo não-controlado em um edifício ou outro tipo de construção - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X009",
            descricao: "Exposição a fogo não-controlado em um edifício ou outro tipo de construção - local não especificado",
            favorito: false
        },
        {
            cid10: "X010",
            descricao: "Exposição a fogo não-controlado fora de um edifício ou de outro tipo de construção - residência",
            favorito: false
        },
        {
            cid10: "X011",
            descricao: "Exposição a fogo não-controlado fora de um edifício ou de outro tipo de construção - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X012",
            descricao: "Exposição a fogo não-controlado fora de um edifício ou de outro tipo de construção - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X013",
            descricao: "Exposição a fogo não-controlado fora de um edifício ou de outro tipo de construção - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X014",
            descricao: "Exposição a fogo não-controlado fora de um edifício ou de outro tipo de construção - rua e estrada",
            favorito: false
        },
        {
            cid10: "X015",
            descricao: "Exposição a fogo não-controlado fora de um edifício ou de outro tipo de construção - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X016",
            descricao: "Exposição a fogo não-controlado fora de um edifício ou de outro tipo de construção - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X017",
            descricao: "Exposição a fogo não-controlado fora de um edifício ou de outro tipo de construção - fazenda",
            favorito: false
        },
        {
            cid10: "X018",
            descricao: "Exposição a fogo não-controlado fora de um edifício ou de outro tipo de construção - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X019",
            descricao: "Exposição a fogo não-controlado fora de um edifício ou de outro tipo de construção - local não especificado",
            favorito: false
        },
        {
            cid10: "X020",
            descricao: "Exposição a fogo controlado em um edifício ou outro tipo de construção - residência",
            favorito: false
        },
        {
            cid10: "X021",
            descricao: "Exposição a fogo controlado em um edifício ou outro tipo de construção - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X022",
            descricao: "Exposição a fogo controlado em um edifício ou outro tipo de construção - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X023",
            descricao: "Exposição a fogo controlado em um edifício ou outro tipo de construção - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X024",
            descricao: "Exposição a fogo controlado em um edifício ou outro tipo de construção - rua e estrada",
            favorito: false
        },
        {
            cid10: "X025",
            descricao: "Exposição a fogo controlado em um edifício ou outro tipo de construção - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X026",
            descricao: "Exposição a fogo controlado em um edifício ou outro tipo de construção - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X027",
            descricao: "Exposição a fogo controlado em um edifício ou outro tipo de construção - fazenda",
            favorito: false
        },
        {
            cid10: "X028",
            descricao: "Exposição a fogo controlado em um edifício ou outro tipo de construção - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X029",
            descricao: "Exposição a fogo controlado em um edifício ou outro tipo de construção - local não especificado",
            favorito: false
        },
        {
            cid10: "X030",
            descricao: "Exposição a fogo controlado fora de um edifício ou de outro tipo de construção - residência",
            favorito: false
        },
        {
            cid10: "X031",
            descricao: "Exposição a fogo controlado fora de um edifício ou de outro tipo de construção - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X032",
            descricao: "Exposição a fogo controlado fora de um edifício ou de outro tipo de construção - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X033",
            descricao: "Exposição a fogo controlado fora de um edifício ou de outro tipo de construção - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X034",
            descricao: "Exposição a fogo controlado fora de um edifício ou de outro tipo de construção - rua e estrada",
            favorito: false
        },
        {
            cid10: "X035",
            descricao: "Exposição a fogo controlado fora de um edifício ou de outro tipo de construção - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X036",
            descricao: "Exposição a fogo controlado fora de um edifício ou de outro tipo de construção - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X037",
            descricao: "Exposição a fogo controlado fora de um edifício ou de outro tipo de construção - fazenda",
            favorito: false
        },
        {
            cid10: "X038",
            descricao: "Exposição a fogo controlado fora de um edifício ou de outro tipo de construção - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X039",
            descricao: "Exposição a fogo controlado fora de um edifício ou de outro tipo de construção - local não especificado",
            favorito: false
        },
        {
            cid10: "X040",
            descricao: "Exposição a combustão de substância muito inflamável - residência",
            favorito: false
        },
        {
            cid10: "X041",
            descricao: "Exposição a combustão de substância muito inflamável - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X042",
            descricao: "Exposição a combustão de substância muito inflamável - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X043",
            descricao: "Exposição a combustão de substância muito inflamável - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X044",
            descricao: "Exposição a combustão de substância muito inflamável - rua e estrada",
            favorito: false
        },
        {
            cid10: "X045",
            descricao: "Exposição a combustão de substância muito inflamável - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X046",
            descricao: "Exposição a combustão de substância muito inflamável - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X047",
            descricao: "Exposição a combustão de substância muito inflamável - fazenda",
            favorito: false
        },
        {
            cid10: "X048",
            descricao: "Exposição a combustão de substância muito inflamável - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X049",
            descricao: "Exposição a combustão de substância muito inflamável - local não especificado",
            favorito: false
        },
        {
            cid10: "X050",
            descricao: "Exposição a combustão de roupa de dormir - residência",
            favorito: false
        },
        {
            cid10: "X051",
            descricao: "Exposição a combustão de roupa de dormir - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X052",
            descricao: "Exposição a combustão de roupa de dormir - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X053",
            descricao: "Exposição a combustão de roupa de dormir - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X054",
            descricao: "Exposição a combustão de roupa de dormir - rua e estrada",
            favorito: false
        },
        {
            cid10: "X055",
            descricao: "Exposição a combustão de roupa de dormir - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X056",
            descricao: "Exposição a combustão de roupa de dormir - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X057",
            descricao: "Exposição a combustão de roupa de dormir - fazenda",
            favorito: false
        },
        {
            cid10: "X058",
            descricao: "Exposição a combustão de roupa de dormir - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X059",
            descricao: "Exposição a combustão de roupa de dormir - local não especificado",
            favorito: false
        },
        {
            cid10: "X060",
            descricao: "Exposição a combustão de outro tipo de roupa ou de acessórios - residência",
            favorito: false
        },
        {
            cid10: "X061",
            descricao: "Exposição a combustão de outro tipo de roupa ou de acessórios - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X062",
            descricao: "Exposição a combustão de outro tipo de roupa ou de acessórios - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X063",
            descricao: "Exposição a combustão de outro tipo de roupa ou de acessórios - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X064",
            descricao: "Exposição a combustão de outro tipo de roupa ou de acessórios - rua e estrada",
            favorito: false
        },
        {
            cid10: "X065",
            descricao: "Exposição a combustão de outro tipo de roupa ou de acessórios - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X066",
            descricao: "Exposição a combustão de outro tipo de roupa ou de acessórios - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X067",
            descricao: "Exposição a combustão de outro tipo de roupa ou de acessórios - fazenda",
            favorito: false
        },
        {
            cid10: "X068",
            descricao: "Exposição a combustão de outro tipo de roupa ou de acessórios - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X069",
            descricao: "Exposição a combustão de outro tipo de roupa ou de acessórios - local não especificado",
            favorito: false
        },
        {
            cid10: "X080",
            descricao: "Exposição a outro tipo especificado de fumaça, fogo ou chamas - residência",
            favorito: false
        },
        {
            cid10: "X081",
            descricao: "Exposição a outro tipo especificado de fumaça, fogo ou chamas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X082",
            descricao: "Exposição a outro tipo especificado de fumaça, fogo ou chamas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X083",
            descricao: "Exposição a outro tipo especificado de fumaça, fogo ou chamas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X084",
            descricao: "Exposição a outro tipo especificado de fumaça, fogo ou chamas - rua e estrada",
            favorito: false
        },
        {
            cid10: "X085",
            descricao: "Exposição a outro tipo especificado de fumaça, fogo ou chamas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X086",
            descricao: "Exposição a outro tipo especificado de fumaça, fogo ou chamas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X087",
            descricao: "Exposição a outro tipo especificado de fumaça, fogo ou chamas - fazenda",
            favorito: false
        },
        {
            cid10: "X088",
            descricao: "Exposição a outro tipo especificado de fumaça, fogo ou chamas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X089",
            descricao: "Exposição a outro tipo especificado de fumaça, fogo ou chamas - local não especificado",
            favorito: false
        },
        {
            cid10: "X090",
            descricao: "Exposição a tipo não especificado de fumaça, fogo ou chamas - residência",
            favorito: false
        },
        {
            cid10: "X091",
            descricao: "Exposição a tipo não especificado de fumaça, fogo ou chamas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X092",
            descricao: "Exposição a tipo não especificado de fumaça, fogo ou chamas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X093",
            descricao: "Exposição a tipo não especificado de fumaça, fogo ou chamas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X094",
            descricao: "Exposição a tipo não especificado de fumaça, fogo ou chamas - rua e estrada",
            favorito: false
        },
        {
            cid10: "X095",
            descricao: "Exposição a tipo não especificado de fumaça, fogo ou chamas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X096",
            descricao: "Exposição a tipo não especificado de fumaça, fogo ou chamas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X097",
            descricao: "Exposição a tipo não especificado de fumaça, fogo ou chamas - fazenda",
            favorito: false
        },
        {
            cid10: "X098",
            descricao: "Exposição a tipo não especificado de fumaça, fogo ou chamas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X099",
            descricao: "Exposição a tipo não especificado de fumaça, fogo ou chamas - local não especificado",
            favorito: false
        },
        {
            cid10: "X100",
            descricao: "Contato com bebidas, alimentos, gordura e óleo de cozinha quentes - residência",
            favorito: false
        },
        {
            cid10: "X101",
            descricao: "Contato com bebidas, alimentos, gordura e óleo de cozinha quentes - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X102",
            descricao: "Contato com bebidas, alimentos, gordura e óleo de cozinha quentes - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X103",
            descricao: "Contato com bebidas, alimentos, gordura e óleo de cozinha quentes - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X104",
            descricao: "Contato com bebidas, alimentos, gordura e óleo de cozinha quentes - rua e estrada",
            favorito: false
        },
        {
            cid10: "X105",
            descricao: "Contato com bebidas, alimentos, gordura e óleo de cozinha quentes - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X106",
            descricao: "Contato com bebidas, alimentos, gordura e óleo de cozinha quentes - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X107",
            descricao: "Contato com bebidas, alimentos, gordura e óleo de cozinha quentes - fazenda",
            favorito: false
        },
        {
            cid10: "X108",
            descricao: "Contato com bebidas, alimentos, gordura e óleo de cozinha quentes - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X109",
            descricao: "Contato com bebidas, alimentos, gordura e óleo de cozinha quentes - local não especificado",
            favorito: false
        },
        {
            cid10: "X110",
            descricao: "Contato com água corrente quente de torneira - residência",
            favorito: false
        },
        {
            cid10: "X111",
            descricao: "Contato com água corrente quente de torneira - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X112",
            descricao: "Contato com água corrente quente de torneira - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X113",
            descricao: "Contato com água corrente quente de torneira - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X114",
            descricao: "Contato com água corrente quente de torneira - rua e estrada",
            favorito: false
        },
        {
            cid10: "X115",
            descricao: "Contato com água corrente quente de torneira - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X116",
            descricao: "Contato com água corrente quente de torneira - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X117",
            descricao: "Contato com água corrente quente de torneira - fazenda",
            favorito: false
        },
        {
            cid10: "X118",
            descricao: "Contato com água corrente quente de torneira - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X119",
            descricao: "Contato com água corrente quente de torneira - local não especificado",
            favorito: false
        },
        {
            cid10: "X120",
            descricao: "Contato com outros líquidos quentes - residência",
            favorito: false
        },
        {
            cid10: "X121",
            descricao: "Contato com outros líquidos quentes - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X122",
            descricao: "Contato com outros líquidos quentes - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X123",
            descricao: "Contato com outros líquidos quentes - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X124",
            descricao: "Contato com outros líquidos quentes - rua e estrada",
            favorito: false
        },
        {
            cid10: "X125",
            descricao: "Contato com outros líquidos quentes - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X126",
            descricao: "Contato com outros líquidos quentes - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X127",
            descricao: "Contato com outros líquidos quentes - fazenda",
            favorito: false
        },
        {
            cid10: "X128",
            descricao: "Contato com outros líquidos quentes - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X129",
            descricao: "Contato com outros líquidos quentes - local não especificado",
            favorito: false
        },
        {
            cid10: "X130",
            descricao: "Contato com vapor dágua e com vapores quentes - residência",
            favorito: false
        },
        {
            cid10: "X131",
            descricao: "Contato com vapor dágua e com vapores quentes - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X132",
            descricao: "Contato com vapor dágua e com vapores quentes - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X133",
            descricao: "Contato com vapor dágua e com vapores quentes - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X134",
            descricao: "Contato com vapor dágua e com vapores quentes - rua e estrada",
            favorito: false
        },
        {
            cid10: "X135",
            descricao: "Contato com vapor dágua e com vapores quentes - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X136",
            descricao: "Contato com vapor dágua e com vapores quentes - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X137",
            descricao: "Contato com vapor dágua e com vapores quentes - fazenda",
            favorito: false
        },
        {
            cid10: "X138",
            descricao: "Contato com vapor dágua e com vapores quentes - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X139",
            descricao: "Contato com vapor dágua e com vapores quentes - local não especificado",
            favorito: false
        },
        {
            cid10: "X140",
            descricao: "Contato com ar e gases quentes - residência",
            favorito: false
        },
        {
            cid10: "X141",
            descricao: "Contato com ar e gases quentes - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X142",
            descricao: "Contato com ar e gases quentes - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X143",
            descricao: "Contato com ar e gases quentes - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X144",
            descricao: "Contato com ar e gases quentes - rua e estrada",
            favorito: false
        },
        {
            cid10: "X145",
            descricao: "Contato com ar e gases quentes - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X146",
            descricao: "Contato com ar e gases quentes - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X147",
            descricao: "Contato com ar e gases quentes - fazenda",
            favorito: false
        },
        {
            cid10: "X148",
            descricao: "Contato com ar e gases quentes - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X149",
            descricao: "Contato com ar e gases quentes - local não especificado",
            favorito: false
        },
        {
            cid10: "X150",
            descricao: "Contato com aparelhos domésticos quentes - residência",
            favorito: false
        },
        {
            cid10: "X151",
            descricao: "Contato com aparelhos domésticos quentes - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X152",
            descricao: "Contato com aparelhos domésticos quentes - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X153",
            descricao: "Contato com aparelhos domésticos quentes - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X154",
            descricao: "Contato com aparelhos domésticos quentes - rua e estrada",
            favorito: false
        },
        {
            cid10: "X155",
            descricao: "Contato com aparelhos domésticos quentes - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X156",
            descricao: "Contato com aparelhos domésticos quentes - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X157",
            descricao: "Contato com aparelhos domésticos quentes - fazenda",
            favorito: false
        },
        {
            cid10: "X158",
            descricao: "Contato com aparelhos domésticos quentes - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X159",
            descricao: "Contato com aparelhos domésticos quentes - local não especificado",
            favorito: false
        },
        {
            cid10: "X160",
            descricao: "Contato com aquecedores, radiadores e tubulação - residência",
            favorito: false
        },
        {
            cid10: "X161",
            descricao: "Contato com aquecedores, radiadores e tubulação - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X162",
            descricao: "Contato com aquecedores, radiadores e tubulação - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X163",
            descricao: "Contato com aquecedores, radiadores e tubulação - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X164",
            descricao: "Contato com aquecedores, radiadores e tubulação - rua e estrada",
            favorito: false
        },
        {
            cid10: "X165",
            descricao: "Contato com aquecedores, radiadores e tubulação - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X166",
            descricao: "Contato com aquecedores, radiadores e tubulação - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X167",
            descricao: "Contato com aquecedores, radiadores e tubulação - fazenda",
            favorito: false
        },
        {
            cid10: "X168",
            descricao: "Contato com aquecedores, radiadores e tubulação - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X169",
            descricao: "Contato com aquecedores, radiadores e tubulação - local não especificado",
            favorito: false
        },
        {
            cid10: "X170",
            descricao: "Contato com motores, máquinas e ferramentas quentes - residência",
            favorito: false
        },
        {
            cid10: "X171",
            descricao: "Contato com motores, máquinas e ferramentas quentes - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X172",
            descricao: "Contato com motores, máquinas e ferramentas quentes - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X173",
            descricao: "Contato com motores, máquinas e ferramentas quentes - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X174",
            descricao: "Contato com motores, máquinas e ferramentas quentes - rua e estrada",
            favorito: false
        },
        {
            cid10: "X175",
            descricao: "Contato com motores, máquinas e ferramentas quentes - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X176",
            descricao: "Contato com motores, máquinas e ferramentas quentes - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X177",
            descricao: "Contato com motores, máquinas e ferramentas quentes - fazenda",
            favorito: false
        },
        {
            cid10: "X178",
            descricao: "Contato com motores, máquinas e ferramentas quentes - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X179",
            descricao: "Contato com motores, máquinas e ferramentas quentes - local não especificado",
            favorito: false
        },
        {
            cid10: "X180",
            descricao: "Contato com outros metais quentes - residência",
            favorito: false
        },
        {
            cid10: "X181",
            descricao: "Contato com outros metais quentes - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X182",
            descricao: "Contato com outros metais quentes - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X183",
            descricao: "Contato com outros metais quentes - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X184",
            descricao: "Contato com outros metais quentes - rua e estrada",
            favorito: false
        },
        {
            cid10: "X185",
            descricao: "Contato com outros metais quentes - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X186",
            descricao: "Contato com outros metais quentes - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X187",
            descricao: "Contato com outros metais quentes - fazenda",
            favorito: false
        },
        {
            cid10: "X188",
            descricao: "Contato com outros metais quentes - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X189",
            descricao: "Contato com outros metais quentes - local não especificado",
            favorito: false
        },
        {
            cid10: "X190",
            descricao: "Contato com outras fontes de calor ou com substâncias quentes não especificados - residência",
            favorito: false
        },
        {
            cid10: "X191",
            descricao: "Contato com outras fontes de calor ou com substâncias quentes não especificados - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X192",
            descricao: "Contato com outras fontes de calor ou com substâncias quentes não especificados - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X193",
            descricao: "Contato com outras fontes de calor ou com substâncias quentes não especificados - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X194",
            descricao: "Contato com outras fontes de calor ou com substâncias quentes não especificados - rua e estrada",
            favorito: false
        },
        {
            cid10: "X195",
            descricao: "Contato com outras fontes de calor ou com substâncias quentes não especificados - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X196",
            descricao: "Contato com outras fontes de calor ou com substâncias quentes não especificados - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X197",
            descricao: "Contato com outras fontes de calor ou com substâncias quentes não especificados - fazenda",
            favorito: false
        },
        {
            cid10: "X198",
            descricao: "Contato com outras fontes de calor ou com substâncias quentes não especificados - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X199",
            descricao: "Contato com outras fontes de calor ou com substâncias quentes não especificados - local não especificado",
            favorito: false
        },
        {
            cid10: "X200",
            descricao: "Contato com serpentes e lagartos venenosos - residência",
            favorito: false
        },
        {
            cid10: "X201",
            descricao: "Contato com serpentes e lagartos venenosos - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X202",
            descricao: "Contato com serpentes e lagartos venenosos - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X203",
            descricao: "Contato com serpentes e lagartos venenosos - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X204",
            descricao: "Contato com serpentes e lagartos venenosos - rua e estrada",
            favorito: false
        },
        {
            cid10: "X205",
            descricao: "Contato com serpentes e lagartos venenosos - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X206",
            descricao: "Contato com serpentes e lagartos venenosos - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X207",
            descricao: "Contato com serpentes e lagartos venenosos - fazenda",
            favorito: false
        },
        {
            cid10: "X208",
            descricao: "Contato com serpentes e lagartos venenosos - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X209",
            descricao: "Contato com serpentes e lagartos venenosos - local não especificado",
            favorito: false
        },
        {
            cid10: "X210",
            descricao: "Contato com aranhas venenosas - residência",
            favorito: false
        },
        {
            cid10: "X211",
            descricao: "Contato com aranhas venenosas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X212",
            descricao: "Contato com aranhas venenosas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X213",
            descricao: "Contato com aranhas venenosas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X214",
            descricao: "Contato com aranhas venenosas - rua e estrada",
            favorito: false
        },
        {
            cid10: "X215",
            descricao: "Contato com aranhas venenosas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X216",
            descricao: "Contato com aranhas venenosas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X217",
            descricao: "Contato com aranhas venenosas - fazenda",
            favorito: false
        },
        {
            cid10: "X218",
            descricao: "Contato com aranhas venenosas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X219",
            descricao: "Contato com aranhas venenosas - local não especificado",
            favorito: false
        },
        {
            cid10: "X220",
            descricao: "Contato com escorpiões - residência",
            favorito: false
        },
        {
            cid10: "X221",
            descricao: "Contato com escorpiões - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X222",
            descricao: "Contato com escorpiões - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X223",
            descricao: "Contato com escorpiões - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X224",
            descricao: "Contato com escorpiões - rua e estrada",
            favorito: false
        },
        {
            cid10: "X225",
            descricao: "Contato com escorpiões - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X226",
            descricao: "Contato com escorpiões - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X227",
            descricao: "Contato com escorpiões - fazenda",
            favorito: false
        },
        {
            cid10: "X228",
            descricao: "Contato com escorpiões - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X229",
            descricao: "Contato com escorpiões - local não especificado",
            favorito: false
        },
        {
            cid10: "X230",
            descricao: "Contato com abelhas, vespas e vespões - residência",
            favorito: false
        },
        {
            cid10: "X231",
            descricao: "Contato com abelhas, vespas e vespões - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X232",
            descricao: "Contato com abelhas, vespas e vespões - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X233",
            descricao: "Contato com abelhas, vespas e vespões - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X234",
            descricao: "Contato com abelhas, vespas e vespões - rua e estrada",
            favorito: false
        },
        {
            cid10: "X235",
            descricao: "Contato com abelhas, vespas e vespões - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X236",
            descricao: "Contato com abelhas, vespas e vespões - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X237",
            descricao: "Contato com abelhas, vespas e vespões - fazenda",
            favorito: false
        },
        {
            cid10: "X238",
            descricao: "Contato com abelhas, vespas e vespões - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X239",
            descricao: "Contato com abelhas, vespas e vespões - local não especificado",
            favorito: false
        },
        {
            cid10: "X240",
            descricao: "Contato com centopéias e miriápodes venenosas (tropicais) - residência",
            favorito: false
        },
        {
            cid10: "X241",
            descricao: "Contato com centopéias e miriápodes venenosas (tropicais) - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X242",
            descricao: "Contato com centopéias e miriápodes venenosas (tropicais) - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X243",
            descricao: "Contato com centopéias e miriápodes venenosas (tropicais) - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X244",
            descricao: "Contato com centopéias e miriápodes venenosas (tropicais) - rua e estrada",
            favorito: false
        },
        {
            cid10: "X245",
            descricao: "Contato com centopéias e miriápodes venenosas (tropicais) - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X246",
            descricao: "Contato com centopéias e miriápodes venenosas (tropicais) - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X247",
            descricao: "Contato com centopéias e miriápodes venenosas (tropicais) - fazenda",
            favorito: false
        },
        {
            cid10: "X248",
            descricao: "Contato com centopéias e miriápodes venenosas (tropicais) - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X249",
            descricao: "Contato com centopéias e miriápodes venenosas (tropicais) - local não especificado",
            favorito: false
        },
        {
            cid10: "X250",
            descricao: "Contato com outros artrópodes venenosos - residência",
            favorito: false
        },
        {
            cid10: "X251",
            descricao: "Contato com outros artrópodes venenosos - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X252",
            descricao: "Contato com outros artrópodes venenosos - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X253",
            descricao: "Contato com outros artrópodes venenosos - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X254",
            descricao: "Contato com outros artrópodes venenosos - rua e estrada",
            favorito: false
        },
        {
            cid10: "X255",
            descricao: "Contato com outros artrópodes venenosos - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X256",
            descricao: "Contato com outros artrópodes venenosos - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X257",
            descricao: "Contato com outros artrópodes venenosos - fazenda",
            favorito: false
        },
        {
            cid10: "X258",
            descricao: "Contato com outros artrópodes venenosos - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X259",
            descricao: "Contato com outros artrópodes venenosos - local não especificado",
            favorito: false
        },
        {
            cid10: "X260",
            descricao: "Contato com animais e plantas marinhos venenosos - residência",
            favorito: false
        },
        {
            cid10: "X261",
            descricao: "Contato com animais e plantas marinhos venenosos - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X262",
            descricao: "Contato com animais e plantas marinhos venenosos - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X263",
            descricao: "Contato com animais e plantas marinhos venenosos - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X264",
            descricao: "Contato com animais e plantas marinhos venenosos - rua e estrada",
            favorito: false
        },
        {
            cid10: "X265",
            descricao: "Contato com animais e plantas marinhos venenosos - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X266",
            descricao: "Contato com animais e plantas marinhos venenosos - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X267",
            descricao: "Contato com animais e plantas marinhos venenosos - fazenda",
            favorito: false
        },
        {
            cid10: "X268",
            descricao: "Contato com animais e plantas marinhos venenosos - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X269",
            descricao: "Contato com animais e plantas marinhos venenosos - local não especificado",
            favorito: false
        },
        {
            cid10: "X270",
            descricao: "Contato com outros animais venenosos especificados - residência",
            favorito: false
        },
        {
            cid10: "X271",
            descricao: "Contato com outros animais venenosos especificados - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X272",
            descricao: "Contato com outros animais venenosos especificados - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X273",
            descricao: "Contato com outros animais venenosos especificados - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X274",
            descricao: "Contato com outros animais venenosos especificados - rua e estrada",
            favorito: false
        },
        {
            cid10: "X275",
            descricao: "Contato com outros animais venenosos especificados - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X276",
            descricao: "Contato com outros animais venenosos especificados - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X277",
            descricao: "Contato com outros animais venenosos especificados - fazenda",
            favorito: false
        },
        {
            cid10: "X278",
            descricao: "Contato com outros animais venenosos especificados - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X279",
            descricao: "Contato com outros animais venenosos especificados - local não especificado",
            favorito: false
        },
        {
            cid10: "X280",
            descricao: "Contato com outras plantas venenosas especificadas - residência",
            favorito: false
        },
        {
            cid10: "X281",
            descricao: "Contato com outras plantas venenosas especificadas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X282",
            descricao: "Contato com outras plantas venenosas especificadas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X283",
            descricao: "Contato com outras plantas venenosas especificadas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X284",
            descricao: "Contato com outras plantas venenosas especificadas - rua e estrada",
            favorito: false
        },
        {
            cid10: "X285",
            descricao: "Contato com outras plantas venenosas especificadas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X286",
            descricao: "Contato com outras plantas venenosas especificadas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X287",
            descricao: "Contato com outras plantas venenosas especificadas - fazenda",
            favorito: false
        },
        {
            cid10: "X288",
            descricao: "Contato com outras plantas venenosas especificadas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X289",
            descricao: "Contato com outras plantas venenosas especificadas - local não especificado",
            favorito: false
        },
        {
            cid10: "X290",
            descricao: "Contato com animais ou plantas venenosos, sem especificação - residência",
            favorito: false
        },
        {
            cid10: "X291",
            descricao: "Contato com animais ou plantas venenosos, sem especificação - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X292",
            descricao: "Contato com animais ou plantas venenosos, sem especificação - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X293",
            descricao: "Contato com animais ou plantas venenosos, sem especificação - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X294",
            descricao: "Contato com animais ou plantas venenosos, sem especificação - rua e estrada",
            favorito: false
        },
        {
            cid10: "X295",
            descricao: "Contato com animais ou plantas venenosos, sem especificação - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X296",
            descricao: "Contato com animais ou plantas venenosos, sem especificação - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X297",
            descricao: "Contato com animais ou plantas venenosos, sem especificação - fazenda",
            favorito: false
        },
        {
            cid10: "X298",
            descricao: "Contato com animais ou plantas venenosos, sem especificação - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X299",
            descricao: "Contato com animais ou plantas venenosos, sem especificação - local não especificado",
            favorito: false
        },
        {
            cid10: "X300",
            descricao: "Exposição a calor natural excessivo - residência",
            favorito: false
        },
        {
            cid10: "X301",
            descricao: "Exposição a calor natural excessivo - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X302",
            descricao: "Exposição a calor natural excessivo - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X303",
            descricao: "Exposição a calor natural excessivo - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X304",
            descricao: "Exposição a calor natural excessivo - rua e estrada",
            favorito: false
        },
        {
            cid10: "X305",
            descricao: "Exposição a calor natural excessivo - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X306",
            descricao: "Exposição a calor natural excessivo - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X307",
            descricao: "Exposição a calor natural excessivo - fazenda",
            favorito: false
        },
        {
            cid10: "X308",
            descricao: "Exposição a calor natural excessivo - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X309",
            descricao: "Exposição a calor natural excessivo - local não especificado",
            favorito: false
        },
        {
            cid10: "X310",
            descricao: "Exposição a frio natural excessivo - residência",
            favorito: false
        },
        {
            cid10: "X311",
            descricao: "Exposição a frio natural excessivo - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X312",
            descricao: "Exposição a frio natural excessivo - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X313",
            descricao: "Exposição a frio natural excessivo - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X314",
            descricao: "Exposição a frio natural excessivo - rua e estrada",
            favorito: false
        },
        {
            cid10: "X315",
            descricao: "Exposição a frio natural excessivo - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X316",
            descricao: "Exposição a frio natural excessivo - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X317",
            descricao: "Exposição a frio natural excessivo - fazenda",
            favorito: false
        },
        {
            cid10: "X318",
            descricao: "Exposição a frio natural excessivo - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X319",
            descricao: "Exposição a frio natural excessivo - local não especificado",
            favorito: false
        },
        {
            cid10: "X320",
            descricao: "Exposição à luz solar - residência",
            favorito: false
        },
        {
            cid10: "X321",
            descricao: "Exposição à luz solar - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X322",
            descricao: "Exposição à luz solar - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X323",
            descricao: "Exposição à luz solar - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X324",
            descricao: "Exposição à luz solar - rua e estrada",
            favorito: false
        },
        {
            cid10: "X325",
            descricao: "Exposição à luz solar - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X326",
            descricao: "Exposição à luz solar - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X327",
            descricao: "Exposição à luz solar - fazenda",
            favorito: false
        },
        {
            cid10: "X328",
            descricao: "Exposição à luz solar - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X329",
            descricao: "Exposição à luz solar - local não especificado",
            favorito: false
        },
        {
            cid10: "X330",
            descricao: "Vítima de raio - residência",
            favorito: false
        },
        {
            cid10: "X331",
            descricao: "Vítima de raio - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X332",
            descricao: "Vítima de raio - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X333",
            descricao: "Vítima de raio - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X334",
            descricao: "Vítima de raio - rua e estrada",
            favorito: false
        },
        {
            cid10: "X335",
            descricao: "Vítima de raio - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X336",
            descricao: "Vítima de raio - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X337",
            descricao: "Vítima de raio - fazenda",
            favorito: false
        },
        {
            cid10: "X338",
            descricao: "Vítima de raio - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X339",
            descricao: "Vítima de raio - local não especificado",
            favorito: false
        },
        {
            cid10: "X340",
            descricao: "Vítima de terremoto - residência",
            favorito: false
        },
        {
            cid10: "X341",
            descricao: "Vítima de terremoto - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X342",
            descricao: "Vítima de terremoto - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X343",
            descricao: "Vítima de terremoto - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X344",
            descricao: "Vítima de terremoto - rua e estrada",
            favorito: false
        },
        {
            cid10: "X345",
            descricao: "Vítima de terremoto - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X346",
            descricao: "Vítima de terremoto - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X347",
            descricao: "Vítima de terremoto - fazenda",
            favorito: false
        },
        {
            cid10: "X348",
            descricao: "Vítima de terremoto - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X349",
            descricao: "Vítima de terremoto - local não especificado",
            favorito: false
        },
        {
            cid10: "X350",
            descricao: "Vítima de erupção vulcânica - residência",
            favorito: false
        },
        {
            cid10: "X351",
            descricao: "Vítima de erupção vulcânica - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X352",
            descricao: "Vítima de erupção vulcânica - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X353",
            descricao: "Vítima de erupção vulcânica - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X354",
            descricao: "Vítima de erupção vulcânica - rua e estrada",
            favorito: false
        },
        {
            cid10: "X355",
            descricao: "Vítima de erupção vulcânica - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X356",
            descricao: "Vítima de erupção vulcânica - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X357",
            descricao: "Vítima de erupção vulcânica - fazenda",
            favorito: false
        },
        {
            cid10: "X358",
            descricao: "Vítima de erupção vulcânica - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X359",
            descricao: "Vítima de erupção vulcânica - local não especificado",
            favorito: false
        },
        {
            cid10: "X360",
            descricao: "Vítima de avalanche, desabamento de terra e outros movimentos da superfície terrestre - residência",
            favorito: false
        },
        {
            cid10: "X361",
            descricao: "Vítima de avalanche, desabamento de terra e outros movimentos da superfície terrestre - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X362",
            descricao: "Vítima de avalanche, desabamento de terra e outros movimentos da superfície terrestre - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X363",
            descricao: "Vítima de avalanche, desabamento de terra e outros movimentos da superfície terrestre - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X364",
            descricao: "Vítima de avalanche, desabamento de terra e outros movimentos da superfície terrestre - rua e estrada",
            favorito: false
        },
        {
            cid10: "X365",
            descricao: "Vítima de avalanche, desabamento de terra e outros movimentos da superfície terrestre - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X366",
            descricao: "Vítima de avalanche, desabamento de terra e outros movimentos da superfície terrestre - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X367",
            descricao: "Vítima de avalanche, desabamento de terra e outros movimentos da superfície terrestre - fazenda",
            favorito: false
        },
        {
            cid10: "X368",
            descricao: "Vítima de avalanche, desabamento de terra e outros movimentos da superfície terrestre - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X369",
            descricao: "Vítima de avalanche, desabamento de terra e outros movimentos da superfície terrestre - local não especificado",
            favorito: false
        },
        {
            cid10: "X370",
            descricao: "Vítima de tempestade cataclísmica - residência",
            favorito: false
        },
        {
            cid10: "X371",
            descricao: "Vítima de tempestade cataclísmica - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X372",
            descricao: "Vítima de tempestade cataclísmica - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X373",
            descricao: "Vítima de tempestade cataclísmica - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X374",
            descricao: "Vítima de tempestade cataclísmica - rua e estrada",
            favorito: false
        },
        {
            cid10: "X375",
            descricao: "Vítima de tempestade cataclísmica - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X376",
            descricao: "Vítima de tempestade cataclísmica - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X377",
            descricao: "Vítima de tempestade cataclísmica - fazenda",
            favorito: false
        },
        {
            cid10: "X378",
            descricao: "Vítima de tempestade cataclísmica - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X379",
            descricao: "Vítima de tempestade cataclísmica - local não especificado",
            favorito: false
        },
        {
            cid10: "X380",
            descricao: "Vítima de inundação - residência",
            favorito: false
        },
        {
            cid10: "X381",
            descricao: "Vítima de inundação - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X382",
            descricao: "Vítima de inundação - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X383",
            descricao: "Vítima de inundação - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X384",
            descricao: "Vítima de inundação - rua e estrada",
            favorito: false
        },
        {
            cid10: "X385",
            descricao: "Vítima de inundação - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X386",
            descricao: "Vítima de inundação - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X387",
            descricao: "Vítima de inundação - fazenda",
            favorito: false
        },
        {
            cid10: "X388",
            descricao: "Vítima de inundação - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X389",
            descricao: "Vítima de inundação - local não especificado",
            favorito: false
        },
        {
            cid10: "X390",
            descricao: "Exposição a outras forças da natureza e às não especificadas - residência",
            favorito: false
        },
        {
            cid10: "X391",
            descricao: "Exposição a outras forças da natureza e às não especificadas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X392",
            descricao: "Exposição a outras forças da natureza e às não especificadas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X393",
            descricao: "Exposição a outras forças da natureza e às não especificadas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X394",
            descricao: "Exposição a outras forças da natureza e às não especificadas - rua e estrada",
            favorito: false
        },
        {
            cid10: "X395",
            descricao: "Exposição a outras forças da natureza e às não especificadas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X396",
            descricao: "Exposição a outras forças da natureza e às não especificadas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X397",
            descricao: "Exposição a outras forças da natureza e às não especificadas - fazenda",
            favorito: false
        },
        {
            cid10: "X398",
            descricao: "Exposição a outras forças da natureza e às não especificadas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X399",
            descricao: "Exposição a outras forças da natureza e às não especificadas - local não especificado",
            favorito: false
        },
        {
            cid10: "X400",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - residência",
            favorito: false
        },
        {
            cid10: "X401",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X402",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X403",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X404",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - rua e estrada",
            favorito: false
        },
        {
            cid10: "X405",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X406",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X407",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - fazenda",
            favorito: false
        },
        {
            cid10: "X408",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X409",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - local não especificado",
            favorito: false
        },
        {
            cid10: "X410",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificadas em outra parte - residência",
            favorito: false
        },
        {
            cid10: "X411",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificadas em outra parte - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X412",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificadas em outra parte - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X413",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificadas em outra parte - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X414",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificadas em outra parte - rua e estrada",
            favorito: false
        },
        {
            cid10: "X415",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificadas em outra parte - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X416",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificadas em outra parte - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X417",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificadas em outra parte - fazenda",
            favorito: false
        },
        {
            cid10: "X418",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificadas em outra parte - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X419",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificadas em outra parte - local não especificado",
            favorito: false
        },
        {
            cid10: "X420",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - residência",
            favorito: false
        },
        {
            cid10: "X421",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X422",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X423",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X424",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - rua e estrada",
            favorito: false
        },
        {
            cid10: "X425",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X426",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X427",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - fazenda",
            favorito: false
        },
        {
            cid10: "X428",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X429",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - local não especificado",
            favorito: false
        },
        {
            cid10: "X430",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - residência",
            favorito: false
        },
        {
            cid10: "X431",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X432",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X433",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X434",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - rua e estrada",
            favorito: false
        },
        {
            cid10: "X435",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X436",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X437",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - fazenda",
            favorito: false
        },
        {
            cid10: "X438",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X439",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - local não especificado",
            favorito: false
        },
        {
            cid10: "X440",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras drogas, medicamentos e substâncias biológicas não especificadas - residência",
            favorito: false
        },
        {
            cid10: "X441",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras drogas, medicamentos e substâncias biológicas não especificadas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X442",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras drogas, medicamentos e substâncias biológicas não especificadas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X443",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras drogas, medicamentos e substâncias biológicas não especificadas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X444",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras drogas, medicamentos e substâncias biológicas não especificadas - rua e estrada",
            favorito: false
        },
        {
            cid10: "X445",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras drogas, medicamentos e substâncias biológicas não especificadas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X446",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras drogas, medicamentos e substâncias biológicas não especificadas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X447",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras drogas, medicamentos e substâncias biológicas não especificadas - fazenda",
            favorito: false
        },
        {
            cid10: "X448",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras drogas, medicamentos e substâncias biológicas não especificadas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X449",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras drogas, medicamentos e substâncias biológicas não especificadas - local não especificado",
            favorito: false
        },
        {
            cid10: "X450",
            descricao: "Envenenamento [intoxicação] acidental por e exposição ao álcool - residência",
            favorito: false
        },
        {
            cid10: "X451",
            descricao: "Envenenamento [intoxicação] acidental por e exposição ao álcool - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X452",
            descricao: "Envenenamento [intoxicação] acidental por e exposição ao álcool - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X453",
            descricao: "Envenenamento [intoxicação] acidental por e exposição ao álcool - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X454",
            descricao: "Envenenamento [intoxicação] acidental por e exposição ao álcool - rua e estrada",
            favorito: false
        },
        {
            cid10: "X455",
            descricao: "Envenenamento [intoxicação] acidental por e exposição ao álcool - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X456",
            descricao: "Envenenamento [intoxicação] acidental por e exposição ao álcool - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X457",
            descricao: "Envenenamento [intoxicação] acidental por e exposição ao álcool - fazenda",
            favorito: false
        },
        {
            cid10: "X458",
            descricao: "Envenenamento [intoxicação] acidental por e exposição ao álcool - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X459",
            descricao: "Envenenamento [intoxicação] acidental por e exposição ao álcool - local não especificado",
            favorito: false
        },
        {
            cid10: "X460",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores - residência",
            favorito: false
        },
        {
            cid10: "X461",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X462",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X463",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X464",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores - rua e estrada",
            favorito: false
        },
        {
            cid10: "X465",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X466",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X467",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores - fazenda",
            favorito: false
        },
        {
            cid10: "X468",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X469",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores - local não especificado",
            favorito: false
        },
        {
            cid10: "X470",
            descricao: "Intoxicação acidental por e exposição a outros gases e vapores - residência",
            favorito: false
        },
        {
            cid10: "X471",
            descricao: "Intoxicação acidental por e exposição a outros gases e vapores - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X472",
            descricao: "Intoxicação acidental por e exposição a outros gases e vapores - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X473",
            descricao: "Intoxicação acidental por e exposição a outros gases e vapores - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X474",
            descricao: "Intoxicação acidental por e exposição a outros gases e vapores - rua e estrada",
            favorito: false
        },
        {
            cid10: "X475",
            descricao: "Intoxicação acidental por e exposição a outros gases e vapores - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X476",
            descricao: "Intoxicação acidental por e exposição a outros gases e vapores - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X477",
            descricao: "Intoxicação acidental por e exposição a outros gases e vapores - fazenda",
            favorito: false
        },
        {
            cid10: "X478",
            descricao: "Intoxicação acidental por e exposição a outros gases e vapores - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X479",
            descricao: "Intoxicação acidental por e exposição a outros gases e vapores - local não especificado",
            favorito: false
        },
        {
            cid10: "X480",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a pesticidas - residência",
            favorito: false
        },
        {
            cid10: "X481",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a pesticidas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X482",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a pesticidas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X483",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a pesticidas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X484",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a pesticidas - rua e estrada",
            favorito: false
        },
        {
            cid10: "X485",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a pesticidas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X486",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a pesticidas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X487",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a pesticidas - fazenda",
            favorito: false
        },
        {
            cid10: "X488",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a pesticidas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X489",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a pesticidas - local não especificado",
            favorito: false
        },
        {
            cid10: "X490",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras substâncias químicas nocivas e às não especificadas - residência",
            favorito: false
        },
        {
            cid10: "X491",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras substâncias químicas nocivas e às não especificadas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X492",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras substâncias químicas nocivas e às não especificadas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X493",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras substâncias químicas nocivas e às não especificadas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X494",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras substâncias químicas nocivas e às não especificadas - rua e estrada",
            favorito: false
        },
        {
            cid10: "X495",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras substâncias químicas nocivas e às não especificadas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X496",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras substâncias químicas nocivas e às não especificadas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X497",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras substâncias químicas nocivas e às não especificadas - fazenda",
            favorito: false
        },
        {
            cid10: "X498",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras substâncias químicas nocivas e às não especificadas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X499",
            descricao: "Envenenamento [intoxicação] acidental por e exposição a outras substâncias químicas nocivas e às não especificadas - local não especificado",
            favorito: false
        },
        {
            cid10: "X500",
            descricao: "Excesso de exercícios e movimentos vigorosos ou repetitivos - residência",
            favorito: false
        },
        {
            cid10: "X501",
            descricao: "Excesso de exercícios e movimentos vigorosos ou repetitivos - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X502",
            descricao: "Excesso de exercícios e movimentos vigorosos ou repetitivos - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X503",
            descricao: "Excesso de exercícios e movimentos vigorosos ou repetitivos - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X504",
            descricao: "Excesso de exercícios e movimentos vigorosos ou repetitivos - rua e estrada",
            favorito: false
        },
        {
            cid10: "X505",
            descricao: "Excesso de exercícios e movimentos vigorosos ou repetitivos - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X506",
            descricao: "Excesso de exercícios e movimentos vigorosos ou repetitivos - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X507",
            descricao: "Excesso de exercícios e movimentos vigorosos ou repetitivos - fazenda",
            favorito: false
        },
        {
            cid10: "X508",
            descricao: "Excesso de exercícios e movimentos vigorosos ou repetitivos - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X509",
            descricao: "Excesso de exercícios e movimentos vigorosos ou repetitivos - local não especificado",
            favorito: false
        },
        {
            cid10: "X510",
            descricao: "Viagem e movimento - residência",
            favorito: false
        },
        {
            cid10: "X511",
            descricao: "Viagem e movimento - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X512",
            descricao: "Viagem e movimento - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X513",
            descricao: "Viagem e movimento - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X514",
            descricao: "Viagem e movimento - rua e estrada",
            favorito: false
        },
        {
            cid10: "X515",
            descricao: "Viagem e movimento - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X516",
            descricao: "Viagem e movimento - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X517",
            descricao: "Viagem e movimento - fazenda",
            favorito: false
        },
        {
            cid10: "X518",
            descricao: "Viagem e movimento - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X519",
            descricao: "Viagem e movimento - local não especificado",
            favorito: false
        },
        {
            cid10: "X520",
            descricao: "Estadia prolongada em ambiente agravitacional - residência",
            favorito: false
        },
        {
            cid10: "X521",
            descricao: "Estadia prolongada em ambiente agravitacional - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X522",
            descricao: "Estadia prolongada em ambiente agravitacional - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X523",
            descricao: "Estadia prolongada em ambiente agravitacional - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X524",
            descricao: "Estadia prolongada em ambiente agravitacional - rua e estrada",
            favorito: false
        },
        {
            cid10: "X525",
            descricao: "Estadia prolongada em ambiente agravitacional - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X526",
            descricao: "Estadia prolongada em ambiente agravitacional - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X527",
            descricao: "Estadia prolongada em ambiente agravitacional - fazenda",
            favorito: false
        },
        {
            cid10: "X528",
            descricao: "Estadia prolongada em ambiente agravitacional - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X529",
            descricao: "Estadia prolongada em ambiente agravitacional - local não especificado",
            favorito: false
        },
        {
            cid10: "X530",
            descricao: "Falta de alimento - residência",
            favorito: false
        },
        {
            cid10: "X531",
            descricao: "Falta de alimento - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X532",
            descricao: "Falta de alimento - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X533",
            descricao: "Falta de alimento - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X534",
            descricao: "Falta de alimento - rua e estrada",
            favorito: false
        },
        {
            cid10: "X535",
            descricao: "Falta de alimento - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X536",
            descricao: "Falta de alimento - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X537",
            descricao: "Falta de alimento - fazenda",
            favorito: false
        },
        {
            cid10: "X538",
            descricao: "Falta de alimento - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X539",
            descricao: "Falta de alimento - local não especificado",
            favorito: false
        },
        {
            cid10: "X540",
            descricao: "Falta de água - residência",
            favorito: false
        },
        {
            cid10: "X541",
            descricao: "Falta de água - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X542",
            descricao: "Falta de água - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X543",
            descricao: "Falta de água - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X544",
            descricao: "Falta de água - rua e estrada",
            favorito: false
        },
        {
            cid10: "X545",
            descricao: "Falta de água - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X546",
            descricao: "Falta de água - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X547",
            descricao: "Falta de água - fazenda",
            favorito: false
        },
        {
            cid10: "X548",
            descricao: "Falta de água - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X549",
            descricao: "Falta de água - local não especificado",
            favorito: false
        },
        {
            cid10: "X570",
            descricao: "Privação não especificada - residência",
            favorito: false
        },
        {
            cid10: "X571",
            descricao: "Privação não especificada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X572",
            descricao: "Privação não especificada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X573",
            descricao: "Privação não especificada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X574",
            descricao: "Privação não especificada - rua e estrada",
            favorito: false
        },
        {
            cid10: "X575",
            descricao: "Privação não especificada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X576",
            descricao: "Privação não especificada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X577",
            descricao: "Privação não especificada - fazenda",
            favorito: false
        },
        {
            cid10: "X578",
            descricao: "Privação não especificada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X579",
            descricao: "Privação não especificada - local não especificado",
            favorito: false
        },
        {
            cid10: "X580",
            descricao: "Exposição a outros fatores especificados - residência",
            favorito: false
        },
        {
            cid10: "X581",
            descricao: "Exposição a outros fatores especificados - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X582",
            descricao: "Exposição a outros fatores especificados - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X583",
            descricao: "Exposição a outros fatores especificados - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X584",
            descricao: "Exposição a outros fatores especificados - rua e estrada",
            favorito: false
        },
        {
            cid10: "X585",
            descricao: "Exposição a outros fatores especificados - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X586",
            descricao: "Exposição a outros fatores especificados - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X587",
            descricao: "Exposição a outros fatores especificados - fazenda",
            favorito: false
        },
        {
            cid10: "X588",
            descricao: "Exposição a outros fatores especificados - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X589",
            descricao: "Exposição a outros fatores especificados - local não especificado",
            favorito: false
        },
        {
            cid10: "X590",
            descricao: "Exposição a fator não especificado causando fratura",
            favorito: false
        },
        {
            cid10: "X599",
            descricao: "Exposição a fator não especificado causando outro tr|aumatismo e traumatismo não especificado",
            favorito: false
        },
        {
            cid10: "X600",
            descricao: "Auto-intoxicação por e exposição, intencional, a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - residência",
            favorito: false
        },
        {
            cid10: "X601",
            descricao: "Auto-intoxicação por e exposição, intencional, a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X602",
            descricao: "Auto-intoxicação por e exposição, intencional, a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X603",
            descricao: "Auto-intoxicação por e exposição, intencional, a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X604",
            descricao: "Auto-intoxicação por e exposição, intencional, a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - rua e estrada",
            favorito: false
        },
        {
            cid10: "X605",
            descricao: "Auto-intoxicação por e exposição, intencional, a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X606",
            descricao: "Auto-intoxicação por e exposição, intencional, a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X607",
            descricao: "Auto-intoxicação por e exposição, intencional, a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - fazenda",
            favorito: false
        },
        {
            cid10: "X608",
            descricao: "Auto-intoxicação por e exposição, intencional, a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X609",
            descricao: "Auto-intoxicação por e exposição, intencional, a analgésicos, antipiréticos e anti-reumáticos, não-opiáceos - local não especificado",
            favorito: false
        },
        {
            cid10: "X610",
            descricao: "Auto-intoxicação por e exposição, intencional, a drogas anticonvulsivantes [antiepilépticos] sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte - residência",
            favorito: false
        },
        {
            cid10: "X611",
            descricao: "Auto-intoxicação por e exposição, intencional, a drogas anticonvulsivantes [antiepilépticos] sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X612",
            descricao: "Auto-intoxicação por e exposição, intencional, a drogas anticonvulsivantes [antiepilépticos] sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X613",
            descricao: "Auto-intoxicação por e exposição, intencional, a drogas anticonvulsivantes [antiepilépticos] sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X614",
            descricao: "Auto-intoxicação por e exposição, intencional, a drogas anticonvulsivantes [antiepilépticos] sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte - rua e estrada",
            favorito: false
        },
        {
            cid10: "X615",
            descricao: "Auto-intoxicação por e exposição, intencional, a drogas anticonvulsivantes [antiepilépticos] sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X616",
            descricao: "Auto-intoxicação por e exposição, intencional, a drogas anticonvulsivantes [antiepilépticos] sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X617",
            descricao: "Auto-intoxicação por e exposição, intencional, a drogas anticonvulsivantes [antiepilépticos] sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte - fazenda",
            favorito: false
        },
        {
            cid10: "X618",
            descricao: "Auto-intoxicação por e exposição, intencional, a drogas anticonvulsivantes [antiepilépticos] sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X619",
            descricao: "Auto-intoxicação por e exposição, intencional, a drogas anticonvulsivantes [antiepilépticos] sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte - local não especificado",
            favorito: false
        },
        {
            cid10: "X620",
            descricao: "Auto-intoxicação por e exposição, intencional, a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - residência",
            favorito: false
        },
        {
            cid10: "X621",
            descricao: "Auto-intoxicação por e exposição, intencional, a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X622",
            descricao: "Auto-intoxicação por e exposição, intencional, a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X623",
            descricao: "Auto-intoxicação por e exposição, intencional, a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X624",
            descricao: "Auto-intoxicação por e exposição, intencional, a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - rua e estrada",
            favorito: false
        },
        {
            cid10: "X625",
            descricao: "Auto-intoxicação por e exposição, intencional, a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X626",
            descricao: "Auto-intoxicação por e exposição, intencional, a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X627",
            descricao: "Auto-intoxicação por e exposição, intencional, a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - fazenda",
            favorito: false
        },
        {
            cid10: "X628",
            descricao: "Auto-intoxicação por e exposição, intencional, a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X629",
            descricao: "Auto-intoxicação por e exposição, intencional, a narcóticos e psicodislépticos [alucinógenos] não classificados em outra parte - local não especificado",
            favorito: false
        },
        {
            cid10: "X630",
            descricao: "Auto-intoxicação por e exposição, intencional, a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - residência",
            favorito: false
        },
        {
            cid10: "X631",
            descricao: "Auto-intoxicação por e exposição, intencional, a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X632",
            descricao: "Auto-intoxicação por e exposição, intencional, a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X633",
            descricao: "Auto-intoxicação por e exposição, intencional, a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X634",
            descricao: "Auto-intoxicação por e exposição, intencional, a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - rua e estrada",
            favorito: false
        },
        {
            cid10: "X635",
            descricao: "Auto-intoxicação por e exposição, intencional, a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X636",
            descricao: "Auto-intoxicação por e exposição, intencional, a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X637",
            descricao: "Auto-intoxicação por e exposição, intencional, a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - fazenda",
            favorito: false
        },
        {
            cid10: "X638",
            descricao: "Auto-intoxicação por e exposição, intencional, a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X639",
            descricao: "Auto-intoxicação por e exposição, intencional, a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo - local não especificado",
            favorito: false
        },
        {
            cid10: "X640",
            descricao: "Auto-intoxicação por e exposição, intencional, a outras drogas, medicamentos e substâncias biológicas e às não especificadas - residência",
            favorito: false
        },
        {
            cid10: "X641",
            descricao: "Auto-intoxicação por e exposição, intencional, a outras drogas, medicamentos e substâncias biológicas e às não especificadas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X642",
            descricao: "Auto-intoxicação por e exposição, intencional, a outras drogas, medicamentos e substâncias biológicas e às não especificadas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X643",
            descricao: "Auto-intoxicação por e exposição, intencional, a outras drogas, medicamentos e substâncias biológicas e às não especificadas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X644",
            descricao: "Auto-intoxicação por e exposição, intencional, a outras drogas, medicamentos e substâncias biológicas e às não especificadas - rua e estrada",
            favorito: false
        },
        {
            cid10: "X645",
            descricao: "Auto-intoxicação por e exposição, intencional, a outras drogas, medicamentos e substâncias biológicas e às não especificadas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X646",
            descricao: "Auto-intoxicação por e exposição, intencional, a outras drogas, medicamentos e substâncias biológicas e às não especificadas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X647",
            descricao: "Auto-intoxicação por e exposição, intencional, a outras drogas, medicamentos e substâncias biológicas e às não especificadas - fazenda",
            favorito: false
        },
        {
            cid10: "X648",
            descricao: "Auto-intoxicação por e exposição, intencional, a outras drogas, medicamentos e substâncias biológicas e às não especificadas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X649",
            descricao: "Auto-intoxicação por e exposição, intencional, a outras drogas, medicamentos e substâncias biológicas e às não especificadas - local não especificado",
            favorito: false
        },
        {
            cid10: "X650",
            descricao: "Auto-intoxicação voluntária por álcool - residência",
            favorito: false
        },
        {
            cid10: "X651",
            descricao: "Auto-intoxicação voluntária por álcool - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X652",
            descricao: "Auto-intoxicação voluntária por álcool - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X653",
            descricao: "Auto-intoxicação voluntária por álcool - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X654",
            descricao: "Auto-intoxicação voluntária por álcool - rua e estrada",
            favorito: false
        },
        {
            cid10: "X655",
            descricao: "Auto-intoxicação voluntária por álcool - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X656",
            descricao: "Auto-intoxicação voluntária por álcool - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X657",
            descricao: "Auto-intoxicação voluntária por álcool - fazenda",
            favorito: false
        },
        {
            cid10: "X658",
            descricao: "Auto-intoxicação voluntária por álcool - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X659",
            descricao: "Auto-intoxicação voluntária por álcool - local não especificado",
            favorito: false
        },
        {
            cid10: "X660",
            descricao: "Auto-intoxicação intencional por solventes orgânicos, hidrocarbonetos halogenados e seus vapores - residência",
            favorito: false
        },
        {
            cid10: "X661",
            descricao: "Auto-intoxicação intencional por solventes orgânicos, hidrocarbonetos halogenados e seus vapores - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X662",
            descricao: "Auto-intoxicação intencional por solventes orgânicos, hidrocarbonetos halogenados e seus vapores - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X663",
            descricao: "Auto-intoxicação intencional por solventes orgânicos, hidrocarbonetos halogenados e seus vapores - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X664",
            descricao: "Auto-intoxicação intencional por solventes orgânicos, hidrocarbonetos halogenados e seus vapores - rua e estrada",
            favorito: false
        },
        {
            cid10: "X665",
            descricao: "Auto-intoxicação intencional por solventes orgânicos, hidrocarbonetos halogenados e seus vapores - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X666",
            descricao: "Auto-intoxicação intencional por solventes orgânicos, hidrocarbonetos halogenados e seus vapores - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X667",
            descricao: "Auto-intoxicação intencional por solventes orgânicos, hidrocarbonetos halogenados e seus vapores - fazenda",
            favorito: false
        },
        {
            cid10: "X668",
            descricao: "Auto-intoxicação intencional por solventes orgânicos, hidrocarbonetos halogenados e seus vapores - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X669",
            descricao: "Auto-intoxicação intencional por solventes orgânicos, hidrocarbonetos halogenados e seus vapores - local não especificado",
            favorito: false
        },
        {
            cid10: "X670",
            descricao: "Auto-intoxicação intencional por outros gases e vapores - residência",
            favorito: false
        },
        {
            cid10: "X671",
            descricao: "Auto-intoxicação intencional por outros gases e vapores - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X672",
            descricao: "Auto-intoxicação intencional por outros gases e vapores - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X673",
            descricao: "Auto-intoxicação intencional por outros gases e vapores - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X674",
            descricao: "Auto-intoxicação intencional por outros gases e vapores - rua e estrada",
            favorito: false
        },
        {
            cid10: "X675",
            descricao: "Auto-intoxicação intencional por outros gases e vapores - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X676",
            descricao: "Auto-intoxicação intencional por outros gases e vapores - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X677",
            descricao: "Auto-intoxicação intencional por outros gases e vapores - fazenda",
            favorito: false
        },
        {
            cid10: "X678",
            descricao: "Auto-intoxicação intencional por outros gases e vapores - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X679",
            descricao: "Auto-intoxicação intencional por outros gases e vapores - local não especificado",
            favorito: false
        },
        {
            cid10: "X680",
            descricao: "Auto-intoxicação por e exposição, intencional, a pesticidas - residência",
            favorito: false
        },
        {
            cid10: "X681",
            descricao: "Auto-intoxicação por e exposição, intencional, a pesticidas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X682",
            descricao: "Auto-intoxicação por e exposição, intencional, a pesticidas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X683",
            descricao: "Auto-intoxicação por e exposição, intencional, a pesticidas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X684",
            descricao: "Auto-intoxicação por e exposição, intencional, a pesticidas - rua e estrada",
            favorito: false
        },
        {
            cid10: "X685",
            descricao: "Auto-intoxicação por e exposição, intencional, a pesticidas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X686",
            descricao: "Auto-intoxicação por e exposição, intencional, a pesticidas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X687",
            descricao: "Auto-intoxicação por e exposição, intencional, a pesticidas - fazenda",
            favorito: false
        },
        {
            cid10: "X688",
            descricao: "Auto-intoxicação por e exposição, intencional, a pesticidas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X689",
            descricao: "Auto-intoxicação por e exposição, intencional, a pesticidas - local não especificado",
            favorito: false
        },
        {
            cid10: "X690",
            descricao: "Auto-intoxicação por e exposição, intencional, a outros produtos químicos e substâncias nocivas não especificadas - residência",
            favorito: false
        },
        {
            cid10: "X691",
            descricao: "Auto-intoxicação por e exposição, intencional, a outros produtos químicos e substâncias nocivas não especificadas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X692",
            descricao: "Auto-intoxicação por e exposição, intencional, a outros produtos químicos e substâncias nocivas não especificadas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X693",
            descricao: "Auto-intoxicação por e exposição, intencional, a outros produtos químicos e substâncias nocivas não especificadas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X694",
            descricao: "Auto-intoxicação por e exposição, intencional, a outros produtos químicos e substâncias nocivas não especificadas - rua e estrada",
            favorito: false
        },
        {
            cid10: "X695",
            descricao: "Auto-intoxicação por e exposição, intencional, a outros produtos químicos e substâncias nocivas não especificadas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X696",
            descricao: "Auto-intoxicação por e exposição, intencional, a outros produtos químicos e substâncias nocivas não especificadas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X697",
            descricao: "Auto-intoxicação por e exposição, intencional, a outros produtos químicos e substâncias nocivas não especificadas - fazenda",
            favorito: false
        },
        {
            cid10: "X698",
            descricao: "Auto-intoxicação por e exposição, intencional, a outros produtos químicos e substâncias nocivas não especificadas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X699",
            descricao: "Auto-intoxicação por e exposição, intencional, a outros produtos químicos e substâncias nocivas não especificadas - local não especificado",
            favorito: false
        },
        {
            cid10: "X700",
            descricao: "Lesão autoprovocada intencionalmente por enforcamento, estrangulamento e sufocação - residência",
            favorito: false
        },
        {
            cid10: "X701",
            descricao: "Lesão autoprovocada intencionalmente por enforcamento, estrangulamento e sufocação - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X702",
            descricao: "Lesão autoprovocada intencionalmente por enforcamento, estrangulamento e sufocação - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X703",
            descricao: "Lesão autoprovocada intencionalmente por enforcamento, estrangulamento e sufocação - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X704",
            descricao: "Lesão autoprovocada intencionalmente por enforcamento, estrangulamento e sufocação - rua e estrada",
            favorito: false
        },
        {
            cid10: "X705",
            descricao: "Lesão autoprovocada intencionalmente por enforcamento, estrangulamento e sufocação - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X706",
            descricao: "Lesão autoprovocada intencionalmente por enforcamento, estrangulamento e sufocação - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X707",
            descricao: "Lesão autoprovocada intencionalmente por enforcamento, estrangulamento e sufocação - fazenda",
            favorito: false
        },
        {
            cid10: "X708",
            descricao: "Lesão autoprovocada intencionalmente por enforcamento, estrangulamento e sufocação - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X709",
            descricao: "Lesão autoprovocada intencionalmente por enforcamento, estrangulamento e sufocação - local não especificado",
            favorito: false
        },
        {
            cid10: "X710",
            descricao: "Lesão autoprovocada intencionalmente por afogamento e submersão - residência",
            favorito: false
        },
        {
            cid10: "X711",
            descricao: "Lesão autoprovocada intencionalmente por afogamento e submersão - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X712",
            descricao: "Lesão autoprovocada intencionalmente por afogamento e submersão - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X713",
            descricao: "Lesão autoprovocada intencionalmente por afogamento e submersão - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X714",
            descricao: "Lesão autoprovocada intencionalmente por afogamento e submersão - rua e estrada",
            favorito: false
        },
        {
            cid10: "X715",
            descricao: "Lesão autoprovocada intencionalmente por afogamento e submersão - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X716",
            descricao: "Lesão autoprovocada intencionalmente por afogamento e submersão - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X717",
            descricao: "Lesão autoprovocada intencionalmente por afogamento e submersão - fazenda",
            favorito: false
        },
        {
            cid10: "X718",
            descricao: "Lesão autoprovocada intencionalmente por afogamento e submersão - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X719",
            descricao: "Lesão autoprovocada intencionalmente por afogamento e submersão - local não especificado",
            favorito: false
        },
        {
            cid10: "X720",
            descricao: "Lesão autoprovocada intencionalmente por disparo de arma de fogo de mão - residência",
            favorito: false
        },
        {
            cid10: "X721",
            descricao: "Lesão autoprovocada intencionalmente por disparo de arma de fogo de mão - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X722",
            descricao: "Lesão autoprovocada intencionalmente por disparo de arma de fogo de mão - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X723",
            descricao: "Lesão autoprovocada intencionalmente por disparo de arma de fogo de mão - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X724",
            descricao: "Lesão autoprovocada intencionalmente por disparo de arma de fogo de mão - rua e estrada",
            favorito: false
        },
        {
            cid10: "X725",
            descricao: "Lesão autoprovocada intencionalmente por disparo de arma de fogo de mão - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X726",
            descricao: "Lesão autoprovocada intencionalmente por disparo de arma de fogo de mão - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X727",
            descricao: "Lesão autoprovocada intencionalmente por disparo de arma de fogo de mão - fazenda",
            favorito: false
        },
        {
            cid10: "X728",
            descricao: "Lesão autoprovocada intencionalmente por disparo de arma de fogo de mão - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X729",
            descricao: "Lesão autoprovocada intencionalmente por disparo de arma de fogo de mão - local não especificado",
            favorito: false
        },
        {
            cid10: "X730",
            descricao: "Lesão autoprovocada intencionalmente por disparo de espingarda, carabina, ou arma de fogo de maior calibre - residência",
            favorito: false
        },
        {
            cid10: "X731",
            descricao: "Lesão autoprovocada intencionalmente por disparo de espingarda, carabina, ou arma de fogo de maior calibre - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X732",
            descricao: "Lesão autoprovocada intencionalmente por disparo de espingarda, carabina, ou arma de fogo de maior calibre - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X733",
            descricao: "Lesão autoprovocada intencionalmente por disparo de espingarda, carabina, ou arma de fogo de maior calibre - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X734",
            descricao: "Lesão autoprovocada intencionalmente por disparo de espingarda, carabina, ou arma de fogo de maior calibre - rua e estrada",
            favorito: false
        },
        {
            cid10: "X735",
            descricao: "Lesão autoprovocada intencionalmente por disparo de espingarda, carabina, ou arma de fogo de maior calibre - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X736",
            descricao: "Lesão autoprovocada intencionalmente por disparo de espingarda, carabina, ou arma de fogo de maior calibre - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X737",
            descricao: "Lesão autoprovocada intencionalmente por disparo de espingarda, carabina, ou arma de fogo de maior calibre - fazenda",
            favorito: false
        },
        {
            cid10: "X738",
            descricao: "Lesão autoprovocada intencionalmente por disparo de espingarda, carabina, ou arma de fogo de maior calibre - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X739",
            descricao: "Lesão autoprovocada intencionalmente por disparo de espingarda, carabina, ou arma de fogo de maior calibre - local não especificado",
            favorito: false
        },
        {
            cid10: "X740",
            descricao: "Lesão autoprovocada intencionalmente por disparo de outra arma de fogo e de arma de fogo não especificada - residência",
            favorito: false
        },
        {
            cid10: "X741",
            descricao: "Lesão autoprovocada intencionalmente por disparo de outra arma de fogo e de arma de fogo não especificada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X742",
            descricao: "Lesão autoprovocada intencionalmente por disparo de outra arma de fogo e de arma de fogo não especificada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X743",
            descricao: "Lesão autoprovocada intencionalmente por disparo de outra arma de fogo e de arma de fogo não especificada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X744",
            descricao: "Lesão autoprovocada intencionalmente por disparo de outra arma de fogo e de arma de fogo não especificada - rua e estrada",
            favorito: false
        },
        {
            cid10: "X745",
            descricao: "Lesão autoprovocada intencionalmente por disparo de outra arma de fogo e de arma de fogo não especificada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X746",
            descricao: "Lesão autoprovocada intencionalmente por disparo de outra arma de fogo e de arma de fogo não especificada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X747",
            descricao: "Lesão autoprovocada intencionalmente por disparo de outra arma de fogo e de arma de fogo não especificada - fazenda",
            favorito: false
        },
        {
            cid10: "X748",
            descricao: "Lesão autoprovocada intencionalmente por disparo de outra arma de fogo e de arma de fogo não especificada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X749",
            descricao: "Lesão autoprovocada intencionalmente por disparo de outra arma de fogo e de arma de fogo não especificada - local não especificado",
            favorito: false
        },
        {
            cid10: "X750",
            descricao: "Lesão autoprovocada intencionalmente por dispositivos explosivos - residência",
            favorito: false
        },
        {
            cid10: "X751",
            descricao: "Lesão autoprovocada intencionalmente por dispositivos explosivos - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X752",
            descricao: "Lesão autoprovocada intencionalmente por dispositivos explosivos - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X753",
            descricao: "Lesão autoprovocada intencionalmente por dispositivos explosivos - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X754",
            descricao: "Lesão autoprovocada intencionalmente por dispositivos explosivos - rua e estrada",
            favorito: false
        },
        {
            cid10: "X755",
            descricao: "Lesão autoprovocada intencionalmente por dispositivos explosivos - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X756",
            descricao: "Lesão autoprovocada intencionalmente por dispositivos explosivos - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X757",
            descricao: "Lesão autoprovocada intencionalmente por dispositivos explosivos - fazenda",
            favorito: false
        },
        {
            cid10: "X758",
            descricao: "Lesão autoprovocada intencionalmente por dispositivos explosivos - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X759",
            descricao: "Lesão autoprovocada intencionalmente por dispositivos explosivos - local não especificado",
            favorito: false
        },
        {
            cid10: "X760",
            descricao: "Lesão autoprovocada intencionalmente pela fumaça, pelo fogo e por chamas - residência",
            favorito: false
        },
        {
            cid10: "X761",
            descricao: "Lesão autoprovocada intencionalmente pela fumaça, pelo fogo e por chamas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X762",
            descricao: "Lesão autoprovocada intencionalmente pela fumaça, pelo fogo e por chamas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X763",
            descricao: "Lesão autoprovocada intencionalmente pela fumaça, pelo fogo e por chamas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X764",
            descricao: "Lesão autoprovocada intencionalmente pela fumaça, pelo fogo e por chamas - rua e estrada",
            favorito: false
        },
        {
            cid10: "X765",
            descricao: "Lesão autoprovocada intencionalmente pela fumaça, pelo fogo e por chamas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X766",
            descricao: "Lesão autoprovocada intencionalmente pela fumaça, pelo fogo e por chamas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X767",
            descricao: "Lesão autoprovocada intencionalmente pela fumaça, pelo fogo e por chamas - fazenda",
            favorito: false
        },
        {
            cid10: "X768",
            descricao: "Lesão autoprovocada intencionalmente pela fumaça, pelo fogo e por chamas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X769",
            descricao: "Lesão autoprovocada intencionalmente pela fumaça, pelo fogo e por chamas - local não especificado",
            favorito: false
        },
        {
            cid10: "X770",
            descricao: "Lesão autoprovocada intencionalmente por vapor de água, gases ou objetos quentes - residência",
            favorito: false
        },
        {
            cid10: "X771",
            descricao: "Lesão autoprovocada intencionalmente por vapor de água, gases ou objetos quentes - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X772",
            descricao: "Lesão autoprovocada intencionalmente por vapor de água, gases ou objetos quentes - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X773",
            descricao: "Lesão autoprovocada intencionalmente por vapor de água, gases ou objetos quentes - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X774",
            descricao: "Lesão autoprovocada intencionalmente por vapor de água, gases ou objetos quentes - rua e estrada",
            favorito: false
        },
        {
            cid10: "X775",
            descricao: "Lesão autoprovocada intencionalmente por vapor de água, gases ou objetos quentes - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X776",
            descricao: "Lesão autoprovocada intencionalmente por vapor de água, gases ou objetos quentes - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X777",
            descricao: "Lesão autoprovocada intencionalmente por vapor de água, gases ou objetos quentes - fazenda",
            favorito: false
        },
        {
            cid10: "X778",
            descricao: "Lesão autoprovocada intencionalmente por vapor de água, gases ou objetos quentes - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X779",
            descricao: "Lesão autoprovocada intencionalmente por vapor de água, gases ou objetos quentes - local não especificado",
            favorito: false
        },
        {
            cid10: "X780",
            descricao: "Lesão autoprovocada intencionalmente por objeto cortante ou penetrante - residência",
            favorito: false
        },
        {
            cid10: "X781",
            descricao: "Lesão autoprovocada intencionalmente por objeto cortante ou penetrante - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X782",
            descricao: "Lesão autoprovocada intencionalmente por objeto cortante ou penetrante - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X783",
            descricao: "Lesão autoprovocada intencionalmente por objeto cortante ou penetrante - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X784",
            descricao: "Lesão autoprovocada intencionalmente por objeto cortante ou penetrante - rua e estrada",
            favorito: false
        },
        {
            cid10: "X785",
            descricao: "Lesão autoprovocada intencionalmente por objeto cortante ou penetrante - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X786",
            descricao: "Lesão autoprovocada intencionalmente por objeto cortante ou penetrante - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X787",
            descricao: "Lesão autoprovocada intencionalmente por objeto cortante ou penetrante - fazenda",
            favorito: false
        },
        {
            cid10: "X788",
            descricao: "Lesão autoprovocada intencionalmente por objeto cortante ou penetrante - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X789",
            descricao: "Lesão autoprovocada intencionalmente por objeto cortante ou penetrante - local não especificado",
            favorito: false
        },
        {
            cid10: "X790",
            descricao: "Lesão autoprovocada intencionalmente por objeto contundente - residência",
            favorito: false
        },
        {
            cid10: "X791",
            descricao: "Lesão autoprovocada intencionalmente por objeto contundente - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X792",
            descricao: "Lesão autoprovocada intencionalmente por objeto contundente - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X793",
            descricao: "Lesão autoprovocada intencionalmente por objeto contundente - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X794",
            descricao: "Lesão autoprovocada intencionalmente por objeto contundente - rua e estrada",
            favorito: false
        },
        {
            cid10: "X795",
            descricao: "Lesão autoprovocada intencionalmente por objeto contundente - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X796",
            descricao: "Lesão autoprovocada intencionalmente por objeto contundente - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X797",
            descricao: "Lesão autoprovocada intencionalmente por objeto contundente - fazenda",
            favorito: false
        },
        {
            cid10: "X798",
            descricao: "Lesão autoprovocada intencionalmente por objeto contundente - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X799",
            descricao: "Lesão autoprovocada intencionalmente por objeto contundente - local não especificado",
            favorito: false
        },
        {
            cid10: "X800",
            descricao: "Lesão autoprovocada intencionalmente por precipitação de um lugar elevado - residência",
            favorito: false
        },
        {
            cid10: "X801",
            descricao: "Lesão autoprovocada intencionalmente por precipitação de um lugar elevado - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X802",
            descricao: "Lesão autoprovocada intencionalmente por precipitação de um lugar elevado - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X803",
            descricao: "Lesão autoprovocada intencionalmente por precipitação de um lugar elevado - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X804",
            descricao: "Lesão autoprovocada intencionalmente por precipitação de um lugar elevado - rua e estrada",
            favorito: false
        },
        {
            cid10: "X805",
            descricao: "Lesão autoprovocada intencionalmente por precipitação de um lugar elevado - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X806",
            descricao: "Lesão autoprovocada intencionalmente por precipitação de um lugar elevado - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X807",
            descricao: "Lesão autoprovocada intencionalmente por precipitação de um lugar elevado - fazenda",
            favorito: false
        },
        {
            cid10: "X808",
            descricao: "Lesão autoprovocada intencionalmente por precipitação de um lugar elevado - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X809",
            descricao: "Lesão autoprovocada intencionalmente por precipitação de um lugar elevado - local não especificado",
            favorito: false
        },
        {
            cid10: "X810",
            descricao: "Lesão autoprovocada intencionalmente por precipitação ou permanência diante de um objeto em movimento - residência",
            favorito: false
        },
        {
            cid10: "X811",
            descricao: "Lesão autoprovocada intencionalmente por precipitação ou permanência diante de um objeto em movimento - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X812",
            descricao: "Lesão autoprovocada intencionalmente por precipitação ou permanência diante de um objeto em movimento - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X813",
            descricao: "Lesão autoprovocada intencionalmente por precipitação ou permanência diante de um objeto em movimento - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X814",
            descricao: "Lesão autoprovocada intencionalmente por precipitação ou permanência diante de um objeto em movimento - rua e estrada",
            favorito: false
        },
        {
            cid10: "X815",
            descricao: "Lesão autoprovocada intencionalmente por precipitação ou permanência diante de um objeto em movimento - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X816",
            descricao: "Lesão autoprovocada intencionalmente por precipitação ou permanência diante de um objeto em movimento - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X817",
            descricao: "Lesão autoprovocada intencionalmente por precipitação ou permanência diante de um objeto em movimento - fazenda",
            favorito: false
        },
        {
            cid10: "X818",
            descricao: "Lesão autoprovocada intencionalmente por precipitação ou permanência diante de um objeto em movimento - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X819",
            descricao: "Lesão autoprovocada intencionalmente por precipitação ou permanência diante de um objeto em movimento - local não especificado",
            favorito: false
        },
        {
            cid10: "X820",
            descricao: "Lesão autoprovocada intencionalmente por impacto de um veículo a motor - residência",
            favorito: false
        },
        {
            cid10: "X821",
            descricao: "Lesão autoprovocada intencionalmente por impacto de um veículo a motor - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X822",
            descricao: "Lesão autoprovocada intencionalmente por impacto de um veículo a motor - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X823",
            descricao: "Lesão autoprovocada intencionalmente por impacto de um veículo a motor - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X824",
            descricao: "Lesão autoprovocada intencionalmente por impacto de um veículo a motor - rua e estrada",
            favorito: false
        },
        {
            cid10: "X825",
            descricao: "Lesão autoprovocada intencionalmente por impacto de um veículo a motor - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X826",
            descricao: "Lesão autoprovocada intencionalmente por impacto de um veículo a motor - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X827",
            descricao: "Lesão autoprovocada intencionalmente por impacto de um veículo a motor - fazenda",
            favorito: false
        },
        {
            cid10: "X828",
            descricao: "Lesão autoprovocada intencionalmente por impacto de um veículo a motor - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X829",
            descricao: "Lesão autoprovocada intencionalmente por impacto de um veículo a motor - local não especificado",
            favorito: false
        },
        {
            cid10: "X830",
            descricao: "Lesão autoprovocada intencionalmente por outros meios especificados - residência",
            favorito: false
        },
        {
            cid10: "X831",
            descricao: "Lesão autoprovocada intencionalmente por outros meios especificados - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X832",
            descricao: "Lesão autoprovocada intencionalmente por outros meios especificados - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X833",
            descricao: "Lesão autoprovocada intencionalmente por outros meios especificados - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X834",
            descricao: "Lesão autoprovocada intencionalmente por outros meios especificados - rua e estrada",
            favorito: false
        },
        {
            cid10: "X835",
            descricao: "Lesão autoprovocada intencionalmente por outros meios especificados - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X836",
            descricao: "Lesão autoprovocada intencionalmente por outros meios especificados - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X837",
            descricao: "Lesão autoprovocada intencionalmente por outros meios especificados - fazenda",
            favorito: false
        },
        {
            cid10: "X838",
            descricao: "Lesão autoprovocada intencionalmente por outros meios especificados - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X839",
            descricao: "Lesão autoprovocada intencionalmente por outros meios especificados - local não especificado",
            favorito: false
        },
        {
            cid10: "X840",
            descricao: "Lesão autoprovocada intencionalmente por meios não especificados - residência",
            favorito: false
        },
        {
            cid10: "X841",
            descricao: "Lesão autoprovocada intencionalmente por meios não especificados - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X842",
            descricao: "Lesão autoprovocada intencionalmente por meios não especificados - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X843",
            descricao: "Lesão autoprovocada intencionalmente por meios não especificados - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X844",
            descricao: "Lesão autoprovocada intencionalmente por meios não especificados - rua e estrada",
            favorito: false
        },
        {
            cid10: "X845",
            descricao: "Lesão autoprovocada intencionalmente por meios não especificados - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X846",
            descricao: "Lesão autoprovocada intencionalmente por meios não especificados - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X847",
            descricao: "Lesão autoprovocada intencionalmente por meios não especificados - fazenda",
            favorito: false
        },
        {
            cid10: "X848",
            descricao: "Lesão autoprovocada intencionalmente por meios não especificados - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X849",
            descricao: "Lesão autoprovocada intencionalmente por meios não especificados - local não especificado",
            favorito: false
        },
        {
            cid10: "X850",
            descricao: "Agressão por meio de drogas, medicamentos e substâncias biológicas - residência",
            favorito: false
        },
        {
            cid10: "X851",
            descricao: "Agressão por meio de drogas, medicamentos e substâncias biológicas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X852",
            descricao: "Agressão por meio de drogas, medicamentos e substâncias biológicas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X853",
            descricao: "Agressão por meio de drogas, medicamentos e substâncias biológicas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X854",
            descricao: "Agressão por meio de drogas, medicamentos e substâncias biológicas - rua e estrada",
            favorito: false
        },
        {
            cid10: "X855",
            descricao: "Agressão por meio de drogas, medicamentos e substâncias biológicas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X856",
            descricao: "Agressão por meio de drogas, medicamentos e substâncias biológicas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X857",
            descricao: "Agressão por meio de drogas, medicamentos e substâncias biológicas - fazenda",
            favorito: false
        },
        {
            cid10: "X858",
            descricao: "Agressão por meio de drogas, medicamentos e substâncias biológicas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X859",
            descricao: "Agressão por meio de drogas, medicamentos e substâncias biológicas - local não especificado",
            favorito: false
        },
        {
            cid10: "X860",
            descricao: "Agressão por meio de substâncias corrosivas - residência",
            favorito: false
        },
        {
            cid10: "X861",
            descricao: "Agressão por meio de substâncias corrosivas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X862",
            descricao: "Agressão por meio de substâncias corrosivas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X863",
            descricao: "Agressão por meio de substâncias corrosivas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X864",
            descricao: "Agressão por meio de substâncias corrosivas - rua e estrada",
            favorito: false
        },
        {
            cid10: "X865",
            descricao: "Agressão por meio de substâncias corrosivas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X866",
            descricao: "Agressão por meio de substâncias corrosivas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X867",
            descricao: "Agressão por meio de substâncias corrosivas - fazenda",
            favorito: false
        },
        {
            cid10: "X868",
            descricao: "Agressão por meio de substâncias corrosivas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X869",
            descricao: "Agressão por meio de substâncias corrosivas - local não especificado",
            favorito: false
        },
        {
            cid10: "X870",
            descricao: "Agressão por pesticidas - residência",
            favorito: false
        },
        {
            cid10: "X871",
            descricao: "Agressão por pesticidas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X872",
            descricao: "Agressão por pesticidas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X873",
            descricao: "Agressão por pesticidas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X874",
            descricao: "Agressão por pesticidas - rua e estrada",
            favorito: false
        },
        {
            cid10: "X875",
            descricao: "Agressão por pesticidas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X876",
            descricao: "Agressão por pesticidas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X877",
            descricao: "Agressão por pesticidas - fazenda",
            favorito: false
        },
        {
            cid10: "X878",
            descricao: "Agressão por pesticidas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X879",
            descricao: "Agressão por pesticidas - local não especificado",
            favorito: false
        },
        {
            cid10: "X880",
            descricao: "Agressão por meio de gases e vapores - residência",
            favorito: false
        },
        {
            cid10: "X881",
            descricao: "Agressão por meio de gases e vapores - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X882",
            descricao: "Agressão por meio de gases e vapores - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X883",
            descricao: "Agressão por meio de gases e vapores - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X884",
            descricao: "Agressão por meio de gases e vapores - rua e estrada",
            favorito: false
        },
        {
            cid10: "X885",
            descricao: "Agressão por meio de gases e vapores - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X886",
            descricao: "Agressão por meio de gases e vapores - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X887",
            descricao: "Agressão por meio de gases e vapores - fazenda",
            favorito: false
        },
        {
            cid10: "X888",
            descricao: "Agressão por meio de gases e vapores - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X889",
            descricao: "Agressão por meio de gases e vapores - local não especificado",
            favorito: false
        },
        {
            cid10: "X890",
            descricao: "Agressão por meio de outros produtos químicos e substâncias nocivas especificados - residência",
            favorito: false
        },
        {
            cid10: "X891",
            descricao: "Agressão por meio de outros produtos químicos e substâncias nocivas especificados - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X892",
            descricao: "Agressão por meio de outros produtos químicos e substâncias nocivas especificados - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X893",
            descricao: "Agressão por meio de outros produtos químicos e substâncias nocivas especificados - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X894",
            descricao: "Agressão por meio de outros produtos químicos e substâncias nocivas especificados - rua e estrada",
            favorito: false
        },
        {
            cid10: "X895",
            descricao: "Agressão por meio de outros produtos químicos e substâncias nocivas especificados - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X896",
            descricao: "Agressão por meio de outros produtos químicos e substâncias nocivas especificados - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X897",
            descricao: "Agressão por meio de outros produtos químicos e substâncias nocivas especificados - fazenda",
            favorito: false
        },
        {
            cid10: "X898",
            descricao: "Agressão por meio de outros produtos químicos e substâncias nocivas especificados - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X899",
            descricao: "Agressão por meio de outros produtos químicos e substâncias nocivas especificados - local não especificado",
            favorito: false
        },
        {
            cid10: "X900",
            descricao: "Agressão por meio de produtos químicos e substâncias nocivas não especificados - residência",
            favorito: false
        },
        {
            cid10: "X901",
            descricao: "Agressão por meio de produtos químicos e substâncias nocivas não especificados - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X902",
            descricao: "Agressão por meio de produtos químicos e substâncias nocivas não especificados - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X903",
            descricao: "Agressão por meio de produtos químicos e substâncias nocivas não especificados - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X904",
            descricao: "Agressão por meio de produtos químicos e substâncias nocivas não especificados - rua e estrada",
            favorito: false
        },
        {
            cid10: "X905",
            descricao: "Agressão por meio de produtos químicos e substâncias nocivas não especificados - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X906",
            descricao: "Agressão por meio de produtos químicos e substâncias nocivas não especificados - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X907",
            descricao: "Agressão por meio de produtos químicos e substâncias nocivas não especificados - fazenda",
            favorito: false
        },
        {
            cid10: "X908",
            descricao: "Agressão por meio de produtos químicos e substâncias nocivas não especificados - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X909",
            descricao: "Agressão por meio de produtos químicos e substâncias nocivas não especificados - local não especificado",
            favorito: false
        },
        {
            cid10: "X910",
            descricao: "Agressão por meio de enforcamento, estrangulamento e sufocação - residência",
            favorito: false
        },
        {
            cid10: "X911",
            descricao: "Agressão por meio de enforcamento, estrangulamento e sufocação - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X912",
            descricao: "Agressão por meio de enforcamento, estrangulamento e sufocação - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X913",
            descricao: "Agressão por meio de enforcamento, estrangulamento e sufocação - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X914",
            descricao: "Agressão por meio de enforcamento, estrangulamento e sufocação - rua e estrada",
            favorito: false
        },
        {
            cid10: "X915",
            descricao: "Agressão por meio de enforcamento, estrangulamento e sufocação - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X916",
            descricao: "Agressão por meio de enforcamento, estrangulamento e sufocação - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X917",
            descricao: "Agressão por meio de enforcamento, estrangulamento e sufocação - fazenda",
            favorito: false
        },
        {
            cid10: "X918",
            descricao: "Agressão por meio de enforcamento, estrangulamento e sufocação - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X919",
            descricao: "Agressão por meio de enforcamento, estrangulamento e sufocação - local não especificado",
            favorito: false
        },
        {
            cid10: "X920",
            descricao: "Agressão por meio de afogamento e submersão - residência",
            favorito: false
        },
        {
            cid10: "X921",
            descricao: "Agressão por meio de afogamento e submersão - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X922",
            descricao: "Agressão por meio de afogamento e submersão - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X923",
            descricao: "Agressão por meio de afogamento e submersão - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X924",
            descricao: "Agressão por meio de afogamento e submersão - rua e estrada",
            favorito: false
        },
        {
            cid10: "X925",
            descricao: "Agressão por meio de afogamento e submersão - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X926",
            descricao: "Agressão por meio de afogamento e submersão - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X927",
            descricao: "Agressão por meio de afogamento e submersão - fazenda",
            favorito: false
        },
        {
            cid10: "X928",
            descricao: "Agressão por meio de afogamento e submersão - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X929",
            descricao: "Agressão por meio de afogamento e submersão - local não especificado",
            favorito: false
        },
        {
            cid10: "X930",
            descricao: "Agressão por meio de disparo de arma de fogo de mão - residência",
            favorito: false
        },
        {
            cid10: "X931",
            descricao: "Agressão por meio de disparo de arma de fogo de mão - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X932",
            descricao: "Agressão por meio de disparo de arma de fogo de mão - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X933",
            descricao: "Agressão por meio de disparo de arma de fogo de mão - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X934",
            descricao: "Agressão por meio de disparo de arma de fogo de mão - rua e estrada",
            favorito: false
        },
        {
            cid10: "X935",
            descricao: "Agressão por meio de disparo de arma de fogo de mão - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X936",
            descricao: "Agressão por meio de disparo de arma de fogo de mão - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X937",
            descricao: "Agressão por meio de disparo de arma de fogo de mão - fazenda",
            favorito: false
        },
        {
            cid10: "X938",
            descricao: "Agressão por meio de disparo de arma de fogo de mão - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X939",
            descricao: "Agressão por meio de disparo de arma de fogo de mão - local não especificado",
            favorito: false
        },
        {
            cid10: "X940",
            descricao: "Agressão por meio de disparo de espingarda, carabina ou arma de fogo de maior calibre - residência",
            favorito: false
        },
        {
            cid10: "X941",
            descricao: "Agressão por meio de disparo de espingarda, carabina ou arma de fogo de maior calibre - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X942",
            descricao: "Agressão por meio de disparo de espingarda, carabina ou arma de fogo de maior calibre - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X943",
            descricao: "Agressão por meio de disparo de espingarda, carabina ou arma de fogo de maior calibre - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X944",
            descricao: "Agressão por meio de disparo de espingarda, carabina ou arma de fogo de maior calibre - rua e estrada",
            favorito: false
        },
        {
            cid10: "X945",
            descricao: "Agressão por meio de disparo de espingarda, carabina ou arma de fogo de maior calibre - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X946",
            descricao: "Agressão por meio de disparo de espingarda, carabina ou arma de fogo de maior calibre - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X947",
            descricao: "Agressão por meio de disparo de espingarda, carabina ou arma de fogo de maior calibre - fazenda",
            favorito: false
        },
        {
            cid10: "X948",
            descricao: "Agressão por meio de disparo de espingarda, carabina ou arma de fogo de maior calibre - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X949",
            descricao: "Agressão por meio de disparo de espingarda, carabina ou arma de fogo de maior calibre - local não especificado",
            favorito: false
        },
        {
            cid10: "X950",
            descricao: "Agressão por meio de disparo de outra arma de fogo ou de arma não especificada - residência",
            favorito: false
        },
        {
            cid10: "X951",
            descricao: "Agressão por meio de disparo de outra arma de fogo ou de arma não especificada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X952",
            descricao: "Agressão por meio de disparo de outra arma de fogo ou de arma não especificada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X953",
            descricao: "Agressão por meio de disparo de outra arma de fogo ou de arma não especificada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X954",
            descricao: "Agressão por meio de disparo de outra arma de fogo ou de arma não especificada - rua e estrada",
            favorito: false
        },
        {
            cid10: "X955",
            descricao: "Agressão por meio de disparo de outra arma de fogo ou de arma não especificada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X956",
            descricao: "Agressão por meio de disparo de outra arma de fogo ou de arma não especificada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X957",
            descricao: "Agressão por meio de disparo de outra arma de fogo ou de arma não especificada - fazenda",
            favorito: false
        },
        {
            cid10: "X958",
            descricao: "Agressão por meio de disparo de outra arma de fogo ou de arma não especificada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X959",
            descricao: "Agressão por meio de disparo de outra arma de fogo ou de arma não especificada - local não especificado",
            favorito: false
        },
        {
            cid10: "X960",
            descricao: "Agressão por meio de material explosivo - residência",
            favorito: false
        },
        {
            cid10: "X961",
            descricao: "Agressão por meio de material explosivo - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X962",
            descricao: "Agressão por meio de material explosivo - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X963",
            descricao: "Agressão por meio de material explosivo - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X964",
            descricao: "Agressão por meio de material explosivo - rua e estrada",
            favorito: false
        },
        {
            cid10: "X965",
            descricao: "Agressão por meio de material explosivo - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X966",
            descricao: "Agressão por meio de material explosivo - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X967",
            descricao: "Agressão por meio de material explosivo - fazenda",
            favorito: false
        },
        {
            cid10: "X968",
            descricao: "Agressão por meio de material explosivo - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X969",
            descricao: "Agressão por meio de material explosivo - local não especificado",
            favorito: false
        },
        {
            cid10: "X970",
            descricao: "Agressão por meio de fumaça, fogo e chamas - residência",
            favorito: false
        },
        {
            cid10: "X971",
            descricao: "Agressão por meio de fumaça, fogo e chamas - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X972",
            descricao: "Agressão por meio de fumaça, fogo e chamas - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X973",
            descricao: "Agressão por meio de fumaça, fogo e chamas - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X974",
            descricao: "Agressão por meio de fumaça, fogo e chamas - rua e estrada",
            favorito: false
        },
        {
            cid10: "X975",
            descricao: "Agressão por meio de fumaça, fogo e chamas - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X976",
            descricao: "Agressão por meio de fumaça, fogo e chamas - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X977",
            descricao: "Agressão por meio de fumaça, fogo e chamas - fazenda",
            favorito: false
        },
        {
            cid10: "X978",
            descricao: "Agressão por meio de fumaça, fogo e chamas - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X979",
            descricao: "Agressão por meio de fumaça, fogo e chamas - local não especificado",
            favorito: false
        },
        {
            cid10: "X980",
            descricao: "Agressão por meio de vapor de água, gases ou objetos quentes - residência",
            favorito: false
        },
        {
            cid10: "X981",
            descricao: "Agressão por meio de vapor de água, gases ou objetos quentes - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X982",
            descricao: "Agressão por meio de vapor de água, gases ou objetos quentes - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X983",
            descricao: "Agressão por meio de vapor de água, gases ou objetos quentes - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X984",
            descricao: "Agressão por meio de vapor de água, gases ou objetos quentes - rua e estrada",
            favorito: false
        },
        {
            cid10: "X985",
            descricao: "Agressão por meio de vapor de água, gases ou objetos quentes - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X986",
            descricao: "Agressão por meio de vapor de água, gases ou objetos quentes - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X987",
            descricao: "Agressão por meio de vapor de água, gases ou objetos quentes - fazenda",
            favorito: false
        },
        {
            cid10: "X988",
            descricao: "Agressão por meio de vapor de água, gases ou objetos quentes - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X989",
            descricao: "Agressão por meio de vapor de água, gases ou objetos quentes - local não especificado",
            favorito: false
        },
        {
            cid10: "X990",
            descricao: "Agressão por meio de objeto cortante ou penetrante - residência",
            favorito: false
        },
        {
            cid10: "X991",
            descricao: "Agressão por meio de objeto cortante ou penetrante - habitação coletiva",
            favorito: false
        },
        {
            cid10: "X992",
            descricao: "Agressão por meio de objeto cortante ou penetrante - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "X993",
            descricao: "Agressão por meio de objeto cortante ou penetrante - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "X994",
            descricao: "Agressão por meio de objeto cortante ou penetrante - rua e estrada",
            favorito: false
        },
        {
            cid10: "X995",
            descricao: "Agressão por meio de objeto cortante ou penetrante - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "X996",
            descricao: "Agressão por meio de objeto cortante ou penetrante - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "X997",
            descricao: "Agressão por meio de objeto cortante ou penetrante - fazenda",
            favorito: false
        },
        {
            cid10: "X998",
            descricao: "Agressão por meio de objeto cortante ou penetrante - outros locais especificados",
            favorito: false
        },
        {
            cid10: "X999",
            descricao: "Agressão por meio de objeto cortante ou penetrante - local não especificado",
            favorito: false
        },
        {
            cid10: "Y000",
            descricao: "Agressão por meio de um objeto contundente - residência",
            favorito: false
        },
        {
            cid10: "Y001",
            descricao: "Agressão por meio de um objeto contundente - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y002",
            descricao: "Agressão por meio de um objeto contundente - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y003",
            descricao: "Agressão por meio de um objeto contundente - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y004",
            descricao: "Agressão por meio de um objeto contundente - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y005",
            descricao: "Agressão por meio de um objeto contundente - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y006",
            descricao: "Agressão por meio de um objeto contundente - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y007",
            descricao: "Agressão por meio de um objeto contundente - fazenda",
            favorito: false
        },
        {
            cid10: "Y008",
            descricao: "Agressão por meio de um objeto contundente - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y009",
            descricao: "Agressão por meio de um objeto contundente - local não especificado",
            favorito: false
        },
        {
            cid10: "Y010",
            descricao: "Agressão por meio de projeção de um lugar elevado - residência",
            favorito: false
        },
        {
            cid10: "Y011",
            descricao: "Agressão por meio de projeção de um lugar elevado - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y012",
            descricao: "Agressão por meio de projeção de um lugar elevado - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y013",
            descricao: "Agressão por meio de projeção de um lugar elevado - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y014",
            descricao: "Agressão por meio de projeção de um lugar elevado - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y015",
            descricao: "Agressão por meio de projeção de um lugar elevado - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y016",
            descricao: "Agressão por meio de projeção de um lugar elevado - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y017",
            descricao: "Agressão por meio de projeção de um lugar elevado - fazenda",
            favorito: false
        },
        {
            cid10: "Y018",
            descricao: "Agressão por meio de projeção de um lugar elevado - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y019",
            descricao: "Agressão por meio de projeção de um lugar elevado - local não especificado",
            favorito: false
        },
        {
            cid10: "Y020",
            descricao: "Agressão por meio de projeção ou colocação da vítima diante de um objeto em movimento - residência",
            favorito: false
        },
        {
            cid10: "Y021",
            descricao: "Agressão por meio de projeção ou colocação da vítima diante de um objeto em movimento - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y022",
            descricao: "Agressão por meio de projeção ou colocação da vítima diante de um objeto em movimento - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y023",
            descricao: "Agressão por meio de projeção ou colocação da vítima diante de um objeto em movimento - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y024",
            descricao: "Agressão por meio de projeção ou colocação da vítima diante de um objeto em movimento - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y025",
            descricao: "Agressão por meio de projeção ou colocação da vítima diante de um objeto em movimento - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y026",
            descricao: "Agressão por meio de projeção ou colocação da vítima diante de um objeto em movimento - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y027",
            descricao: "Agressão por meio de projeção ou colocação da vítima diante de um objeto em movimento - fazenda",
            favorito: false
        },
        {
            cid10: "Y028",
            descricao: "Agressão por meio de projeção ou colocação da vítima diante de um objeto em movimento - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y029",
            descricao: "Agressão por meio de projeção ou colocação da vítima diante de um objeto em movimento - local não especificado",
            favorito: false
        },
        {
            cid10: "Y030",
            descricao: "Agressão por meio de impacto de um veículo a motor - residência",
            favorito: false
        },
        {
            cid10: "Y031",
            descricao: "Agressão por meio de impacto de um veículo a motor - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y032",
            descricao: "Agressão por meio de impacto de um veículo a motor - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y033",
            descricao: "Agressão por meio de impacto de um veículo a motor - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y034",
            descricao: "Agressão por meio de impacto de um veículo a motor - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y035",
            descricao: "Agressão por meio de impacto de um veículo a motor - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y036",
            descricao: "Agressão por meio de impacto de um veículo a motor - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y037",
            descricao: "Agressão por meio de impacto de um veículo a motor - fazenda",
            favorito: false
        },
        {
            cid10: "Y038",
            descricao: "Agressão por meio de impacto de um veículo a motor - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y039",
            descricao: "Agressão por meio de impacto de um veículo a motor - local não especificado",
            favorito: false
        },
        {
            cid10: "Y040",
            descricao: "Agressão por meio de força corporal - residência",
            favorito: false
        },
        {
            cid10: "Y041",
            descricao: "Agressão por meio de força corporal - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y042",
            descricao: "Agressão por meio de força corporal - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y043",
            descricao: "Agressão por meio de força corporal - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y044",
            descricao: "Agressão por meio de força corporal - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y045",
            descricao: "Agressão por meio de força corporal - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y046",
            descricao: "Agressão por meio de força corporal - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y047",
            descricao: "Agressão por meio de força corporal - fazenda",
            favorito: false
        },
        {
            cid10: "Y048",
            descricao: "Agressão por meio de força corporal - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y049",
            descricao: "Agressão por meio de força corporal - local não especificado",
            favorito: false
        },
        {
            cid10: "Y050",
            descricao: "Agressão sexual por meio de força física - residência",
            favorito: false
        },
        {
            cid10: "Y051",
            descricao: "Agressão sexual por meio de força física - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y052",
            descricao: "Agressão sexual por meio de força física - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y053",
            descricao: "Agressão sexual por meio de força física - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y054",
            descricao: "Agressão sexual por meio de força física - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y055",
            descricao: "Agressão sexual por meio de força física - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y056",
            descricao: "Agressão sexual por meio de força física - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y057",
            descricao: "Agressão sexual por meio de força física - fazenda",
            favorito: false
        },
        {
            cid10: "Y058",
            descricao: "Agressão sexual por meio de força física - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y059",
            descricao: "Agressão sexual por meio de força física - local não especificado",
            favorito: false
        },
        {
            cid10: "Y060",
            descricao: "Negligência e abandono pelo esposo ou companheiro",
            favorito: false
        },
        {
            cid10: "Y061",
            descricao: "Negligência e abandono pelos pais",
            favorito: false
        },
        {
            cid10: "Y062",
            descricao: "Negligência e abandono por conhecido ou amigo",
            favorito: false
        },
        {
            cid10: "Y068",
            descricao: "Negligência e abandono por outra pessoa especificada",
            favorito: false
        },
        {
            cid10: "Y069",
            descricao: "Negligência e abandono por pessoa não especificada",
            favorito: false
        },
        {
            cid10: "Y070",
            descricao: "Outras síndromes de maus tratos pelo esposo ou companheiro",
            favorito: false
        },
        {
            cid10: "Y071",
            descricao: "Outras síndromes de maus tratos pelos pais",
            favorito: false
        },
        {
            cid10: "Y072",
            descricao: "Outras síndromes de maus tratos por conhecido ou amigo",
            favorito: false
        },
        {
            cid10: "Y073",
            descricao: "Outras síndromes de maus tratos por autoridades oficiais",
            favorito: false
        },
        {
            cid10: "Y078",
            descricao: "Outras síndromes de maus tratos por outra pessoa especificada",
            favorito: false
        },
        {
            cid10: "Y079",
            descricao: "Outras síndromes de maus tratos por pessoa não especificada",
            favorito: false
        },
        {
            cid10: "Y080",
            descricao: "Agressão por outros meios especificados - residência",
            favorito: false
        },
        {
            cid10: "Y081",
            descricao: "Agressão por outros meios especificados - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y082",
            descricao: "Agressão por outros meios especificados - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y083",
            descricao: "Agressão por outros meios especificados - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y084",
            descricao: "Agressão por outros meios especificados - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y085",
            descricao: "Agressão por outros meios especificados - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y086",
            descricao: "Agressão por outros meios especificados - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y087",
            descricao: "Agressão por outros meios especificados - fazenda",
            favorito: false
        },
        {
            cid10: "Y088",
            descricao: "Agressão por outros meios especificados - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y089",
            descricao: "Agressão por outros meios especificados - local não especificado",
            favorito: false
        },
        {
            cid10: "Y090",
            descricao: "Agressão por meios não especificados - residência",
            favorito: false
        },
        {
            cid10: "Y091",
            descricao: "Agressão por meios não especificados - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y092",
            descricao: "Agressão por meios não especificados - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y093",
            descricao: "Agressão por meios não especificados - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y094",
            descricao: "Agressão por meios não especificados - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y095",
            descricao: "Agressão por meios não especificados - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y096",
            descricao: "Agressão por meios não especificados - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y097",
            descricao: "Agressão por meios não especificados - fazenda",
            favorito: false
        },
        {
            cid10: "Y098",
            descricao: "Agressão por meios não especificados - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y099",
            descricao: "Agressão por meios não especificados - local não especificado",
            favorito: false
        },
        {
            cid10: "Y100",
            descricao: "Envenenamento [intoxicação] por e exposição a analgésicos, antipiréticos e anti-reumáticos não-opiáceos, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y101",
            descricao: "Envenenamento [intoxicação] por e exposição a analgésicos, antipiréticos e anti-reumáticos não-opiáceos, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y102",
            descricao: "Envenenamento [intoxicação] por e exposição a analgésicos, antipiréticos e anti-reumáticos não-opiáceos, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y103",
            descricao: "Envenenamento [intoxicação] por e exposição a analgésicos, antipiréticos e anti-reumáticos não-opiáceos, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y104",
            descricao: "Envenenamento [intoxicação] por e exposição a analgésicos, antipiréticos e anti-reumáticos não-opiáceos, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y105",
            descricao: "Envenenamento [intoxicação] por e exposição a analgésicos, antipiréticos e anti-reumáticos não-opiáceos, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y106",
            descricao: "Envenenamento [intoxicação] por e exposição a analgésicos, antipiréticos e anti-reumáticos não-opiáceos, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y107",
            descricao: "Envenenamento [intoxicação] por e exposição a analgésicos, antipiréticos e anti-reumáticos não-opiáceos, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y108",
            descricao: "Envenenamento [intoxicação] por e exposição a analgésicos, antipiréticos e anti-reumáticos não-opiáceos, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y109",
            descricao: "Envenenamento [intoxicação] por e exposição a analgésicos, antipiréticos e anti-reumáticos não-opiáceos, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y110",
            descricao: "Envenenamento [intoxicação] por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y111",
            descricao: "Envenenamento [intoxicação] por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y112",
            descricao: "Envenenamento [intoxicação] por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y113",
            descricao: "Envenenamento [intoxicação] por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y114",
            descricao: "Envenenamento [intoxicação] por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y115",
            descricao: "Envenenamento [intoxicação] por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y116",
            descricao: "Envenenamento [intoxicação] por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y117",
            descricao: "Envenenamento [intoxicação] por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y118",
            descricao: "Envenenamento [intoxicação] por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y119",
            descricao: "Envenenamento [intoxicação] por e exposição a anticonvulsivantes [antiepilépticos], sedativos, hipnóticos, antiparkinsonianos e psicotrópicos não classificados em outra parte, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y120",
            descricao: "Envenenamento [intoxicação] por e exposição a narcóticos e a psicodislépticos [alucinógenos] não classificados em outra parte, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y121",
            descricao: "Envenenamento [intoxicação] por e exposição a narcóticos e a psicodislépticos [alucinógenos] não classificados em outra parte, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y122",
            descricao: "Envenenamento [intoxicação] por e exposição a narcóticos e a psicodislépticos [alucinógenos] não classificados em outra parte, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y123",
            descricao: "Envenenamento [intoxicação] por e exposição a narcóticos e a psicodislépticos [alucinógenos] não classificados em outra parte, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y124",
            descricao: "Envenenamento [intoxicação] por e exposição a narcóticos e a psicodislépticos [alucinógenos] não classificados em outra parte, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y125",
            descricao: "Envenenamento [intoxicação] por e exposição a narcóticos e a psicodislépticos [alucinógenos] não classificados em outra parte, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y126",
            descricao: "Envenenamento [intoxicação] por e exposição a narcóticos e a psicodislépticos [alucinógenos] não classificados em outra parte, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y127",
            descricao: "Envenenamento [intoxicação] por e exposição a narcóticos e a psicodislépticos [alucinógenos] não classificados em outra parte, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y128",
            descricao: "Envenenamento [intoxicação] por e exposição a narcóticos e a psicodislépticos [alucinógenos] não classificados em outra parte, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y129",
            descricao: "Envenenamento [intoxicação] por e exposição a narcóticos e a psicodislépticos [alucinógenos] não classificados em outra parte, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y130",
            descricao: "Envenenamento [intoxicação] por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y131",
            descricao: "Envenenamento [intoxicação] por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y132",
            descricao: "Envenenamento [intoxicação] por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y133",
            descricao: "Envenenamento [intoxicação] por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y134",
            descricao: "Envenenamento [intoxicação] por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y135",
            descricao: "Envenenamento [intoxicação] por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y136",
            descricao: "Envenenamento [intoxicação] por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y137",
            descricao: "Envenenamento [intoxicação] por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y138",
            descricao: "Envenenamento [intoxicação] por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y139",
            descricao: "Envenenamento [intoxicação] por e exposição a outras substâncias farmacológicas de ação sobre o sistema nervoso autônomo, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y140",
            descricao: "Envenenamento [intoxicação] por e exposição a outras drogas, medicamentos e substâncias biológicas e as não especificadas, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y141",
            descricao: "Envenenamento [intoxicação] por e exposição a outras drogas, medicamentos e substâncias biológicas e as não especificadas, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y142",
            descricao: "Envenenamento [intoxicação] por e exposição a outras drogas, medicamentos e substâncias biológicas e as não especificadas, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y143",
            descricao: "Envenenamento [intoxicação] por e exposição a outras drogas, medicamentos e substâncias biológicas e as não especificadas, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y144",
            descricao: "Envenenamento [intoxicação] por e exposição a outras drogas, medicamentos e substâncias biológicas e as não especificadas, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y145",
            descricao: "Envenenamento [intoxicação] por e exposição a outras drogas, medicamentos e substâncias biológicas e as não especificadas, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y146",
            descricao: "Envenenamento [intoxicação] por e exposição a outras drogas, medicamentos e substâncias biológicas e as não especificadas, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y147",
            descricao: "Envenenamento [intoxicação] por e exposição a outras drogas, medicamentos e substâncias biológicas e as não especificadas, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y148",
            descricao: "Envenenamento [intoxicação] por e exposição a outras drogas, medicamentos e substâncias biológicas e as não especificadas, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y149",
            descricao: "Envenenamento [intoxicação] por e exposição a outras drogas, medicamentos e substâncias biológicas e as não especificadas, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y150",
            descricao: "Envenenamento [intoxicação] por e exposição ao álcool, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y151",
            descricao: "Envenenamento [intoxicação] por e exposição ao álcool, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y152",
            descricao: "Envenenamento [intoxicação] por e exposição ao álcool, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y153",
            descricao: "Envenenamento [intoxicação] por e exposição ao álcool, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y154",
            descricao: "Envenenamento [intoxicação] por e exposição ao álcool, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y155",
            descricao: "Envenenamento [intoxicação] por e exposição ao álcool, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y156",
            descricao: "Envenenamento [intoxicação] por e exposição ao álcool, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y157",
            descricao: "Envenenamento [intoxicação] por e exposição ao álcool, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y158",
            descricao: "Envenenamento [intoxicação] por e exposição ao álcool, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y159",
            descricao: "Envenenamento [intoxicação] por e exposição ao álcool, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y160",
            descricao: "Envenenamento [intoxicação] por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y161",
            descricao: "Envenenamento [intoxicação] por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y162",
            descricao: "Envenenamento [intoxicação] por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y163",
            descricao: "Envenenamento [intoxicação] por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y164",
            descricao: "Envenenamento [intoxicação] por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y165",
            descricao: "Envenenamento [intoxicação] por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y166",
            descricao: "Envenenamento [intoxicação] por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y167",
            descricao: "Envenenamento [intoxicação] por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y168",
            descricao: "Envenenamento [intoxicação] por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y169",
            descricao: "Envenenamento [intoxicação] por e exposição a solventes orgânicos e hidrocarbonetos halogenados e seus vapores, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y170",
            descricao: "Envenenamento [intoxicação] por e exposição a outros gases e vapores, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y171",
            descricao: "Envenenamento [intoxicação] por e exposição a outros gases e vapores, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y172",
            descricao: "Envenenamento [intoxicação] por e exposição a outros gases e vapores, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y173",
            descricao: "Envenenamento [intoxicação] por e exposição a outros gases e vapores, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y174",
            descricao: "Envenenamento [intoxicação] por e exposição a outros gases e vapores, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y175",
            descricao: "Envenenamento [intoxicação] por e exposição a outros gases e vapores, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y176",
            descricao: "Envenenamento [intoxicação] por e exposição a outros gases e vapores, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y177",
            descricao: "Envenenamento [intoxicação] por e exposição a outros gases e vapores, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y178",
            descricao: "Envenenamento [intoxicação] por e exposição a outros gases e vapores, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y179",
            descricao: "Envenenamento [intoxicação] por e exposição a outros gases e vapores, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y180",
            descricao: "Envenenamento [intoxicação] por e exposição a pesticidas, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y181",
            descricao: "Envenenamento [intoxicação] por e exposição a pesticidas, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y182",
            descricao: "Envenenamento [intoxicação] por e exposição a pesticidas, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y183",
            descricao: "Envenenamento [intoxicação] por e exposição a pesticidas, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y184",
            descricao: "Envenenamento [intoxicação] por e exposição a pesticidas, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y185",
            descricao: "Envenenamento [intoxicação] por e exposição a pesticidas, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y186",
            descricao: "Envenenamento [intoxicação] por e exposição a pesticidas, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y187",
            descricao: "Envenenamento [intoxicação] por e exposição a pesticidas, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y188",
            descricao: "Envenenamento [intoxicação] por e exposição a pesticidas, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y189",
            descricao: "Envenenamento [intoxicação] por e exposição a pesticidas, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y190",
            descricao: "Envenenamento [intoxicação] por e exposição a outros produtos químicos e substâncias nocivas e aos não especificados, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y191",
            descricao: "Envenenamento [intoxicação] por e exposição a outros produtos químicos e substâncias nocivas e aos não especificados, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y192",
            descricao: "Envenenamento [intoxicação] por e exposição a outros produtos químicos e substâncias nocivas e aos não especificados, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y193",
            descricao: "Envenenamento [intoxicação] por e exposição a outros produtos químicos e substâncias nocivas e aos não especificados, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y194",
            descricao: "Envenenamento [intoxicação] por e exposição a outros produtos químicos e substâncias nocivas e aos não especificados, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y195",
            descricao: "Envenenamento [intoxicação] por e exposição a outros produtos químicos e substâncias nocivas e aos não especificados, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y196",
            descricao: "Envenenamento [intoxicação] por e exposição a outros produtos químicos e substâncias nocivas e aos não especificados, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y197",
            descricao: "Envenenamento [intoxicação] por e exposição a outros produtos químicos e substâncias nocivas e aos não especificados, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y198",
            descricao: "Envenenamento [intoxicação] por e exposição a outros produtos químicos e substâncias nocivas e aos não especificados, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y199",
            descricao: "Envenenamento [intoxicação] por e exposição a outros produtos químicos e substâncias nocivas e aos não especificados, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y200",
            descricao: "Enforcamento, estrangulamento e sufocação, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y201",
            descricao: "Enforcamento, estrangulamento e sufocação, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y202",
            descricao: "Enforcamento, estrangulamento e sufocação, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y203",
            descricao: "Enforcamento, estrangulamento e sufocação, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y204",
            descricao: "Enforcamento, estrangulamento e sufocação, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y205",
            descricao: "Enforcamento, estrangulamento e sufocação, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y206",
            descricao: "Enforcamento, estrangulamento e sufocação, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y207",
            descricao: "Enforcamento, estrangulamento e sufocação, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y208",
            descricao: "Enforcamento, estrangulamento e sufocação, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y209",
            descricao: "Enforcamento, estrangulamento e sufocação, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y210",
            descricao: "Afogamento e submersão, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y211",
            descricao: "Afogamento e submersão, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y212",
            descricao: "Afogamento e submersão, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y213",
            descricao: "Afogamento e submersão, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y214",
            descricao: "Afogamento e submersão, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y215",
            descricao: "Afogamento e submersão, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y216",
            descricao: "Afogamento e submersão, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y217",
            descricao: "Afogamento e submersão, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y218",
            descricao: "Afogamento e submersão, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y219",
            descricao: "Afogamento e submersão, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y220",
            descricao: "Disparo de pistola, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y221",
            descricao: "Disparo de pistola, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y222",
            descricao: "Disparo de pistola, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y223",
            descricao: "Disparo de pistola, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y224",
            descricao: "Disparo de pistola, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y225",
            descricao: "Disparo de pistola, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y226",
            descricao: "Disparo de pistola, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y227",
            descricao: "Disparo de pistola, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y228",
            descricao: "Disparo de pistola, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y229",
            descricao: "Disparo de pistola, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y230",
            descricao: "Disparo de fuzil, carabina e arma de fogo de maior calibre, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y231",
            descricao: "Disparo de fuzil, carabina e arma de fogo de maior calibre, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y232",
            descricao: "Disparo de fuzil, carabina e arma de fogo de maior calibre, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y233",
            descricao: "Disparo de fuzil, carabina e arma de fogo de maior calibre, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y234",
            descricao: "Disparo de fuzil, carabina e arma de fogo de maior calibre, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y235",
            descricao: "Disparo de fuzil, carabina e arma de fogo de maior calibre, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y236",
            descricao: "Disparo de fuzil, carabina e arma de fogo de maior calibre, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y237",
            descricao: "Disparo de fuzil, carabina e arma de fogo de maior calibre, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y238",
            descricao: "Disparo de fuzil, carabina e arma de fogo de maior calibre, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y239",
            descricao: "Disparo de fuzil, carabina e arma de fogo de maior calibre, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y240",
            descricao: "Disparo de outra arma de fogo e de arma de fogo não especificada, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y241",
            descricao: "Disparo de outra arma de fogo e de arma de fogo não especificada, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y242",
            descricao: "Disparo de outra arma de fogo e de arma de fogo não especificada, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y243",
            descricao: "Disparo de outra arma de fogo e de arma de fogo não especificada, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y244",
            descricao: "Disparo de outra arma de fogo e de arma de fogo não especificada, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y245",
            descricao: "Disparo de outra arma de fogo e de arma de fogo não especificada, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y246",
            descricao: "Disparo de outra arma de fogo e de arma de fogo não especificada, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y247",
            descricao: "Disparo de outra arma de fogo e de arma de fogo não especificada, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y248",
            descricao: "Disparo de outra arma de fogo e de arma de fogo não especificada, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y249",
            descricao: "Disparo de outra arma de fogo e de arma de fogo não especificada, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y250",
            descricao: "Contato com material explosivo, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y251",
            descricao: "Contato com material explosivo, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y252",
            descricao: "Contato com material explosivo, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y253",
            descricao: "Contato com material explosivo, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y254",
            descricao: "Contato com material explosivo, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y255",
            descricao: "Contato com material explosivo, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y256",
            descricao: "Contato com material explosivo, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y257",
            descricao: "Contato com material explosivo, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y258",
            descricao: "Contato com material explosivo, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y259",
            descricao: "Contato com material explosivo, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y260",
            descricao: "Exposição a fumaça, fogo e chamas, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y261",
            descricao: "Exposição a fumaça, fogo e chamas, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y262",
            descricao: "Exposição a fumaça, fogo e chamas, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y263",
            descricao: "Exposição a fumaça, fogo e chamas, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y264",
            descricao: "Exposição a fumaça, fogo e chamas, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y265",
            descricao: "Exposição a fumaça, fogo e chamas, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y266",
            descricao: "Exposição a fumaça, fogo e chamas, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y267",
            descricao: "Exposição a fumaça, fogo e chamas, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y268",
            descricao: "Exposição a fumaça, fogo e chamas, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y269",
            descricao: "Exposição a fumaça, fogo e chamas, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y270",
            descricao: "Exposição a vapor de água, gases ou objetos quentes, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y271",
            descricao: "Exposição a vapor de água, gases ou objetos quentes, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y272",
            descricao: "Exposição a vapor de água, gases ou objetos quentes, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y273",
            descricao: "Exposição a vapor de água, gases ou objetos quentes, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y274",
            descricao: "Exposição a vapor de água, gases ou objetos quentes, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y275",
            descricao: "Exposição a vapor de água, gases ou objetos quentes, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y276",
            descricao: "Exposição a vapor de água, gases ou objetos quentes, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y277",
            descricao: "Exposição a vapor de água, gases ou objetos quentes, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y278",
            descricao: "Exposição a vapor de água, gases ou objetos quentes, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y279",
            descricao: "Exposição a vapor de água, gases ou objetos quentes, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y280",
            descricao: "Contato com objeto cortante ou penetrante, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y281",
            descricao: "Contato com objeto cortante ou penetrante, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y282",
            descricao: "Contato com objeto cortante ou penetrante, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y283",
            descricao: "Contato com objeto cortante ou penetrante, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y284",
            descricao: "Contato com objeto cortante ou penetrante, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y285",
            descricao: "Contato com objeto cortante ou penetrante, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y286",
            descricao: "Contato com objeto cortante ou penetrante, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y287",
            descricao: "Contato com objeto cortante ou penetrante, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y288",
            descricao: "Contato com objeto cortante ou penetrante, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y289",
            descricao: "Contato com objeto cortante ou penetrante, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y290",
            descricao: "Contato com objeto contundente, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y291",
            descricao: "Contato com objeto contundente, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y292",
            descricao: "Contato com objeto contundente, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y293",
            descricao: "Contato com objeto contundente, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y294",
            descricao: "Contato com objeto contundente, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y295",
            descricao: "Contato com objeto contundente, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y296",
            descricao: "Contato com objeto contundente, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y297",
            descricao: "Contato com objeto contundente, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y298",
            descricao: "Contato com objeto contundente, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y299",
            descricao: "Contato com objeto contundente, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y300",
            descricao: "Queda, salto ou empurrado de um lugar elevado, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y301",
            descricao: "Queda, salto ou empurrado de um lugar elevado, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y302",
            descricao: "Queda, salto ou empurrado de um lugar elevado, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y303",
            descricao: "Queda, salto ou empurrado de um lugar elevado, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y304",
            descricao: "Queda, salto ou empurrado de um lugar elevado, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y305",
            descricao: "Queda, salto ou empurrado de um lugar elevado, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y306",
            descricao: "Queda, salto ou empurrado de um lugar elevado, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y307",
            descricao: "Queda, salto ou empurrado de um lugar elevado, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y308",
            descricao: "Queda, salto ou empurrado de um lugar elevado, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y309",
            descricao: "Queda, salto ou empurrado de um lugar elevado, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y310",
            descricao: "Queda, permanência ou corrida diante de um objeto em movimento, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y311",
            descricao: "Queda, permanência ou corrida diante de um objeto em movimento, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y312",
            descricao: "Queda, permanência ou corrida diante de um objeto em movimento, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y313",
            descricao: "Queda, permanência ou corrida diante de um objeto em movimento, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y314",
            descricao: "Queda, permanência ou corrida diante de um objeto em movimento, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y315",
            descricao: "Queda, permanência ou corrida diante de um objeto em movimento, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y316",
            descricao: "Queda, permanência ou corrida diante de um objeto em movimento, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y317",
            descricao: "Queda, permanência ou corrida diante de um objeto em movimento, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y318",
            descricao: "Queda, permanência ou corrida diante de um objeto em movimento, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y319",
            descricao: "Queda, permanência ou corrida diante de um objeto em movimento, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y320",
            descricao: "Impacto de um veículo a motor, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y321",
            descricao: "Impacto de um veículo a motor, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y322",
            descricao: "Impacto de um veículo a motor, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y323",
            descricao: "Impacto de um veículo a motor, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y324",
            descricao: "Impacto de um veículo a motor, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y325",
            descricao: "Impacto de um veículo a motor, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y326",
            descricao: "Impacto de um veículo a motor, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y327",
            descricao: "Impacto de um veículo a motor, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y328",
            descricao: "Impacto de um veículo a motor, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y329",
            descricao: "Impacto de um veículo a motor, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y330",
            descricao: "Outros fatos ou eventos especificados, intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y331",
            descricao: "Outros fatos ou eventos especificados, intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y332",
            descricao: "Outros fatos ou eventos especificados, intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y333",
            descricao: "Outros fatos ou eventos especificados, intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y334",
            descricao: "Outros fatos ou eventos especificados, intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y335",
            descricao: "Outros fatos ou eventos especificados, intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y336",
            descricao: "Outros fatos ou eventos especificados, intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y337",
            descricao: "Outros fatos ou eventos especificados, intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y338",
            descricao: "Outros fatos ou eventos especificados, intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y339",
            descricao: "Outros fatos ou eventos especificados, intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y340",
            descricao: "Fatos ou eventos não especificados e intenção não determinada - residência",
            favorito: false
        },
        {
            cid10: "Y341",
            descricao: "Fatos ou eventos não especificados e intenção não determinada - habitação coletiva",
            favorito: false
        },
        {
            cid10: "Y342",
            descricao: "Fatos ou eventos não especificados e intenção não determinada - escolas, outras instituições e áreas de administração pública",
            favorito: false
        },
        {
            cid10: "Y343",
            descricao: "Fatos ou eventos não especificados e intenção não determinada - área para a prática de esportes e atletismo",
            favorito: false
        },
        {
            cid10: "Y344",
            descricao: "Fatos ou eventos não especificados e intenção não determinada - rua e estrada",
            favorito: false
        },
        {
            cid10: "Y345",
            descricao: "Fatos ou eventos não especificados e intenção não determinada - áreas de comércio e de serviços",
            favorito: false
        },
        {
            cid10: "Y346",
            descricao: "Fatos ou eventos não especificados e intenção não determinada - áreas industriais e em construção",
            favorito: false
        },
        {
            cid10: "Y347",
            descricao: "Fatos ou eventos não especificados e intenção não determinada - fazenda",
            favorito: false
        },
        {
            cid10: "Y348",
            descricao: "Fatos ou eventos não especificados e intenção não determinada - outros locais especificados",
            favorito: false
        },
        {
            cid10: "Y349",
            descricao: "Fatos ou eventos não especificados e intenção não determinada - local não especificado",
            favorito: false
        },
        {
            cid10: "Y350",
            descricao: "Intervenção legal envolvendo o uso de armas de fogo",
            favorito: false
        },
        {
            cid10: "Y351",
            descricao: "Intervenção legal envolvendo o uso de explosivos",
            favorito: false
        },
        {
            cid10: "Y352",
            descricao: "Intervenção legal envolvendo o uso de gás",
            favorito: false
        },
        {
            cid10: "Y353",
            descricao: "Intervenção legal envolvendo o uso de objetos contundentes",
            favorito: false
        },
        {
            cid10: "Y354",
            descricao: "Intervenção legal envolvendo o uso de objetos cortantes e penetrantes",
            favorito: false
        },
        {
            cid10: "Y355",
            descricao: "Execução legal",
            favorito: false
        },
        {
            cid10: "Y356",
            descricao: "Intervenção legal envolvendo o uso de outros meios especificados",
            favorito: false
        },
        {
            cid10: "Y357",
            descricao: "Intervenção legal, meio não especificado",
            favorito: false
        },
        {
            cid10: "Y360",
            descricao: "Operações de guerra envolvendo explosão de armamento naval",
            favorito: false
        },
        {
            cid10: "Y361",
            descricao: "Operações de guerra envolvendo destruição de aeronave",
            favorito: false
        },
        {
            cid10: "Y362",
            descricao: "Operações de guerra envolvendo outras explosões ou fragmentos",
            favorito: false
        },
        {
            cid10: "Y363",
            descricao: "Operações de guerra envolvendo incêndio, fogo e substâncias quentes",
            favorito: false
        },
        {
            cid10: "Y364",
            descricao: "Operações de guerra envolvendo disparo de armas de fogo e de outras formas convencionais de combate",
            favorito: false
        },
        {
            cid10: "Y365",
            descricao: "Operações de guerra envolvendo armamento nuclear",
            favorito: false
        },
        {
            cid10: "Y366",
            descricao: "Operações de guerra envolvendo armas biológicas",
            favorito: false
        },
        {
            cid10: "Y367",
            descricao: "Operações de guerra envolvendo armas químicas e outros meios de combate não-convencionais",
            favorito: false
        },
        {
            cid10: "Y368",
            descricao: "Operações de guerra ocorridas após a cessação das hostilidades",
            favorito: false
        },
        {
            cid10: "Y369",
            descricao: "Operações de guerra não especificadas",
            favorito: false
        },
        {
            cid10: "Y400",
            descricao: "Efeitos adversos de penicilinas",
            favorito: false
        },
        {
            cid10: "Y401",
            descricao: "Efeitos adversos de cefalosporinas e outras betalactaminas",
            favorito: false
        },
        {
            cid10: "Y402",
            descricao: "Efeitos adversos do grupo do cloranfenicol",
            favorito: false
        },
        {
            cid10: "Y403",
            descricao: "Efeitos adversos de macrolídeos",
            favorito: false
        },
        {
            cid10: "Y404",
            descricao: "Efeitos adversos de tetraciclinas",
            favorito: false
        },
        {
            cid10: "Y405",
            descricao: "Efeitos adversos de aminoglicosídeos",
            favorito: false
        },
        {
            cid10: "Y406",
            descricao: "Efeitos adversos de rifamicinas",
            favorito: false
        },
        {
            cid10: "Y407",
            descricao: "Efeitos adversos de antibióticos antifúngicos administrados por via sistêmica",
            favorito: false
        },
        {
            cid10: "Y408",
            descricao: "Efeitos adversos de outros antibióticos administrados por via sistêmica",
            favorito: false
        },
        {
            cid10: "Y409",
            descricao: "Efeitos adversos de antibiótico sistêmico, não especificado",
            favorito: false
        },
        {
            cid10: "Y410",
            descricao: "Efeitos adversos de sulfonamidas",
            favorito: false
        },
        {
            cid10: "Y411",
            descricao: "Efeitos adversos de drogas antimicobacterianas",
            favorito: false
        },
        {
            cid10: "Y412",
            descricao: "Efeitos adversos de antimaláricos e drogas que atuam sobre outros protozoários parasitas do sangue",
            favorito: false
        },
        {
            cid10: "Y413",
            descricao: "Efeitos adversos de outras drogas antiprotozoárias",
            favorito: false
        },
        {
            cid10: "Y414",
            descricao: "Efeitos adversos de anti-helmínticos",
            favorito: false
        },
        {
            cid10: "Y415",
            descricao: "Efeitos adversos de drogas antivirais",
            favorito: false
        },
        {
            cid10: "Y418",
            descricao: "Efeitos adversos de outros antiinfecciosos e antiparasitários especificados",
            favorito: false
        },
        {
            cid10: "Y419",
            descricao: "Efeitos adversos de antiinfecciosos e antiparasitários sistêmicos não especificados",
            favorito: false
        },
        {
            cid10: "Y420",
            descricao: "Efeitos adversos de glicocorticóides e análogos sintéticos",
            favorito: false
        },
        {
            cid10: "Y421",
            descricao: "Efeitos adversos de hormônios e substitutos da tireóide",
            favorito: false
        },
        {
            cid10: "Y422",
            descricao: "Efeitos adversos de drogas antitireoidianas",
            favorito: false
        },
        {
            cid10: "Y423",
            descricao: "Efeitos adversos de insulina e hipoglicêmicos orais (antidiabéticos)",
            favorito: false
        },
        {
            cid10: "Y424",
            descricao: "Efeitos adversos de anticoncepcionais [contraceptivos] orais",
            favorito: false
        },
        {
            cid10: "Y425",
            descricao: "Efeitos adversos de outros estrógenos e progestágenos",
            favorito: false
        },
        {
            cid10: "Y426",
            descricao: "Efeitos adversos de antigonadotrofinas, antiestrógenos e antiandrógenos, não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "Y427",
            descricao: "Efeitos adversos de andrógenos e anabolizantes congêneres",
            favorito: false
        },
        {
            cid10: "Y428",
            descricao: "Efeitos adversos de outros hormônios e os não especificados e seus substitutos sintéticos",
            favorito: false
        },
        {
            cid10: "Y429",
            descricao: "Efeitos adversos de outros antagonistas hormonais e os não especificados",
            favorito: false
        },
        {
            cid10: "Y430",
            descricao: "Efeitos adversos de drogas antialérgicas e antieméticas",
            favorito: false
        },
        {
            cid10: "Y431",
            descricao: "Efeitos adversos de antimetabólicos antineoplásicos",
            favorito: false
        },
        {
            cid10: "Y432",
            descricao: "Efeitos adversos de produtos naturais antineoplásicos",
            favorito: false
        },
        {
            cid10: "Y433",
            descricao: "Efeitos adversos de outras drogas antineoplásicas",
            favorito: false
        },
        {
            cid10: "Y434",
            descricao: "Efeitos adversos de agentes imunossupressores",
            favorito: false
        },
        {
            cid10: "Y435",
            descricao: "Efeitos adversos de acidificantes e alcalinizantes",
            favorito: false
        },
        {
            cid10: "Y436",
            descricao: "Efeitos adversos de enzimas, não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "Y438",
            descricao: "Efeitos adversos de outras substâncias farmacológicas de ação primariamente sistêmica, não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "Y439",
            descricao: "Efeitos adversos de substância farmacológica de ação sistêmica, não especificada",
            favorito: false
        },
        {
            cid10: "Y440",
            descricao: "Efeitos adversos de preparações a base de ferro e outros preparações prescritas na anemia hipocrômica",
            favorito: false
        },
        {
            cid10: "Y441",
            descricao: "Efeitos adversos da vitamina B12, ácido fólico e outros preparações prescritas nas anemias megaloblásticas",
            favorito: false
        },
        {
            cid10: "Y442",
            descricao: "Efeitos adversos de anticoagulantes",
            favorito: false
        },
        {
            cid10: "Y443",
            descricao: "Efeitos adversos de antagonistas de anticoagulantes, vitamina K e outros coagulantes",
            favorito: false
        },
        {
            cid10: "Y444",
            descricao: "Efeitos adversos de drogas antitrombóticas [inibidores da agregação de plaquetas]",
            favorito: false
        },
        {
            cid10: "Y445",
            descricao: "Efeitos adversos de trombolíticos",
            favorito: false
        },
        {
            cid10: "Y446",
            descricao: "Efeitos adversos do sangue natural e produtos do sangue",
            favorito: false
        },
        {
            cid10: "Y447",
            descricao: "Efeitos adversos de substitutos do plasma",
            favorito: false
        },
        {
            cid10: "Y449",
            descricao: "Efeitos adversos de outras substâncias farmacológicas que atuam sobre os constituintes do sangue e as não especificadas",
            favorito: false
        },
        {
            cid10: "Y450",
            descricao: "Efeitos adversos de opiáceos e analgésicos correlatos",
            favorito: false
        },
        {
            cid10: "Y451",
            descricao: "Efeitos adversos de salicilatos",
            favorito: false
        },
        {
            cid10: "Y452",
            descricao: "Efeitos adversos de derivados do ácido propiônico",
            favorito: false
        },
        {
            cid10: "Y453",
            descricao: "Efeitos adversos de outras drogas antiinflamatórias não-esteróides",
            favorito: false
        },
        {
            cid10: "Y454",
            descricao: "Efeitos adversos de anti-reumáticos",
            favorito: false
        },
        {
            cid10: "Y455",
            descricao: "Efeitos adversos de derivados do 4-aminofenol",
            favorito: false
        },
        {
            cid10: "Y458",
            descricao: "Efeitos adversos de outros analgésicos e antipiréticos",
            favorito: false
        },
        {
            cid10: "Y459",
            descricao: "Efeitos adversos de substâncias analgésicas e antipiréticas não especificadas",
            favorito: false
        },
        {
            cid10: "Y460",
            descricao: "Efeitos adversos de succimidas",
            favorito: false
        },
        {
            cid10: "Y461",
            descricao: "Efeitos adversos de oxazolidínas",
            favorito: false
        },
        {
            cid10: "Y462",
            descricao: "Efeitos adversos de derivados da hidantoína",
            favorito: false
        },
        {
            cid10: "Y463",
            descricao: "Efeitos adversos de desoxibarbitúricos",
            favorito: false
        },
        {
            cid10: "Y464",
            descricao: "Efeitos adversos de iminostilbenos",
            favorito: false
        },
        {
            cid10: "Y465",
            descricao: "Efeitos adversos do ácido valpróico",
            favorito: false
        },
        {
            cid10: "Y466",
            descricao: "Efeitos adversos de outros anticonvulsivantes (antiepilépticos) e os não especificados",
            favorito: false
        },
        {
            cid10: "Y467",
            descricao: "Efeitos adversos de drogas antiparkinsonianas",
            favorito: false
        },
        {
            cid10: "Y468",
            descricao: "Efeitos adversos de substâncias miorrelaxantes (anti-espásticas)",
            favorito: false
        },
        {
            cid10: "Y470",
            descricao: "Efeitos adversos de barbitúricos, não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "Y471",
            descricao: "Efeitos adversos de benzodiazepínicos",
            favorito: false
        },
        {
            cid10: "Y472",
            descricao: "Efeitos adversos de derivados do cloral",
            favorito: false
        },
        {
            cid10: "Y473",
            descricao: "Efeitos adversos de paraldeído",
            favorito: false
        },
        {
            cid10: "Y474",
            descricao: "Efeitos adversos de compostos de bromo",
            favorito: false
        },
        {
            cid10: "Y475",
            descricao: "Efeitos adversos de sedativos e hipnóticos em associações não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "Y478",
            descricao: "Efeitos adversos de outros sedativos, hipnóticos e tranquilizantes [ansiolíticos]",
            favorito: false
        },
        {
            cid10: "Y479",
            descricao: "Efeitos adversos de sedativos, hipnóticos e tranquilizantes [ansiolíticos], não especificados",
            favorito: false
        },
        {
            cid10: "Y480",
            descricao: "Efeitos adversos de anestésicos por inalação",
            favorito: false
        },
        {
            cid10: "Y481",
            descricao: "Efeitos adversos de anestésicos por via parenteral",
            favorito: false
        },
        {
            cid10: "Y482",
            descricao: "Efeitos adversos de outros anestésicos gerais e os não especificados",
            favorito: false
        },
        {
            cid10: "Y483",
            descricao: "Efeitos adversos de anestésicos locais",
            favorito: false
        },
        {
            cid10: "Y484",
            descricao: "Efeitos adversos de anestésico, não especificado",
            favorito: false
        },
        {
            cid10: "Y485",
            descricao: "Efeitos adversos de gases terapêuticos",
            favorito: false
        },
        {
            cid10: "Y490",
            descricao: "Efeitos adversos de antidepressivos triciclos e tetracíclicos",
            favorito: false
        },
        {
            cid10: "Y491",
            descricao: "Efeitos adversos de antidepressivos inibidores da mono-amino-oxidase",
            favorito: false
        },
        {
            cid10: "Y492",
            descricao: "Efeitos adversos de outros antidepressivos e os não especificados",
            favorito: false
        },
        {
            cid10: "Y493",
            descricao: "Efeitos adversos de antipsicóticos e neurolépticos fenotiazínicos",
            favorito: false
        },
        {
            cid10: "Y494",
            descricao: "Efeitos adversos de neurolépticos a base da butirofenona e do tioxanteno",
            favorito: false
        },
        {
            cid10: "Y495",
            descricao: "Efeitos adversos de outros antipsicóticos e neurolépticos",
            favorito: false
        },
        {
            cid10: "Y496",
            descricao: "Efeitos adversos de psicodislépticos [alucinógenos]",
            favorito: false
        },
        {
            cid10: "Y497",
            descricao: "Efeitos adversos de psicoestimulantes que podem provocar dependência",
            favorito: false
        },
        {
            cid10: "Y498",
            descricao: "Efeitos adversos de outras substâncias psicotrópicas, não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "Y499",
            descricao: "Efeitos adversos de substância psicotrópica, não especificada",
            favorito: false
        },
        {
            cid10: "Y500",
            descricao: "Efeitos adversos de analépticos",
            favorito: false
        },
        {
            cid10: "Y501",
            descricao: "Efeitos adversos de antagonistas de receptores dos opiáceos",
            favorito: false
        },
        {
            cid10: "Y502",
            descricao: "Efeitos adversos de metilxantinas, não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "Y508",
            descricao: "Efeitos adversos de outros estimulantes do sistema nervoso central",
            favorito: false
        },
        {
            cid10: "Y509",
            descricao: "Efeitos adversos de estimulante do sistema nervoso central, não especificado",
            favorito: false
        },
        {
            cid10: "Y510",
            descricao: "Efeitos adversos de agentes anticolinesterásicos",
            favorito: false
        },
        {
            cid10: "Y511",
            descricao: "Efeitos adversos de outros parasimpaticomiméticos [colinérgicos]",
            favorito: false
        },
        {
            cid10: "Y512",
            descricao: "Efeitos adversos de ganglioplégicos, não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "Y513",
            descricao: "Efeitos adversos de outros parassimpaticolíticos [anticolinérgicos e antimuscarínicos] e espasmolíticos, não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "Y514",
            descricao: "Efeitos adversos de agonistas predominantemente alfa-adrenorreceptores, não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "Y515",
            descricao: "Efeitos adversos de agonistas predominantemente beta-adrenorreceptores, não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "Y516",
            descricao: "Efeitos adversos de antagonistas alfa-adrenorreceptores, não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "Y517",
            descricao: "Efeitos adversos de antagonistas beta-adrenorreceptores, não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "Y518",
            descricao: "Efeitos adversos de bloqueadores nervosos de ação central e adrenérgicos, não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "Y519",
            descricao: "Efeitos adversos de outras drogas que atuam primariamente sobre o sistema nervoso autônomo e as não especificadas",
            favorito: false
        },
        {
            cid10: "Y520",
            descricao: "Efeitos adversos de glicosídeos estimulantes do coração e substâncias de ação semelhante",
            favorito: false
        },
        {
            cid10: "Y521",
            descricao: "Efeitos adversos de antagonistas do cálcio [inibidores do cálcio]",
            favorito: false
        },
        {
            cid10: "Y522",
            descricao: "Efeitos adversos de outras drogas antidisrrítmicas, não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "Y523",
            descricao: "Efeitos adversos de vasodilatadores coronarianos, não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "Y524",
            descricao: "Efeitos adversos de inibidores da enzima de conversão da angiotensina",
            favorito: false
        },
        {
            cid10: "Y525",
            descricao: "Efeitos adversos de outras drogas anti-hipertensivas, não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "Y526",
            descricao: "Efeitos adversos de drogas anti-hiperlipidêmicas e antiarterioscleróticas",
            favorito: false
        },
        {
            cid10: "Y527",
            descricao: "Efeitos adversos de vasodilatadores periféricos",
            favorito: false
        },
        {
            cid10: "Y528",
            descricao: "Efeitos adversos de drogas antivaricosas, incluindo os agentes esclerosantes",
            favorito: false
        },
        {
            cid10: "Y529",
            descricao: "Efeitos adversos de outras drogas que atuam primariamente sobre o aparelho cardiovascular e as não especificadas",
            favorito: false
        },
        {
            cid10: "Y530",
            descricao: "Efeitos adversos de antagonistas dos receptores H2 da histamina",
            favorito: false
        },
        {
            cid10: "Y531",
            descricao: "Efeitos adversos de outros medicamentos antiácidos e anti-secreção gástrica",
            favorito: false
        },
        {
            cid10: "Y532",
            descricao: "Efeitos adversos de laxativos estimulantes",
            favorito: false
        },
        {
            cid10: "Y533",
            descricao: "Efeitos adversos de laxativos salinos e osmóticos",
            favorito: false
        },
        {
            cid10: "Y534",
            descricao: "Efeitos adversos de outros laxativos",
            favorito: false
        },
        {
            cid10: "Y535",
            descricao: "Efeitos adversos de medicamentos que facilitam a digestão",
            favorito: false
        },
        {
            cid10: "Y536",
            descricao: "Efeitos adversos de antidiarréicos",
            favorito: false
        },
        {
            cid10: "Y537",
            descricao: "Efeitos adversos de eméticos",
            favorito: false
        },
        {
            cid10: "Y538",
            descricao: "Efeitos adversos de outras substâncias que atuam primariamente sobre o aparelho gastrointestinal",
            favorito: false
        },
        {
            cid10: "Y539",
            descricao: "Efeitos adversos de substâncias que atuam primariamente sobre o aparelho gastrointestinal, não especificadas",
            favorito: false
        },
        {
            cid10: "Y540",
            descricao: "Efeitos adversos de mineralocorticóides",
            favorito: false
        },
        {
            cid10: "Y541",
            descricao: "Efeitos adversos de antagonistas dos mineralocorticóides [antagonistas da aldosterona]",
            favorito: false
        },
        {
            cid10: "Y542",
            descricao: "Efeitos adversos de inibidores da anidrase carbônica",
            favorito: false
        },
        {
            cid10: "Y543",
            descricao: "Efeitos adversos de derivados da benzotiadiazína",
            favorito: false
        },
        {
            cid10: "Y544",
            descricao: "Efeitos adversos de diuréticos de alça",
            favorito: false
        },
        {
            cid10: "Y545",
            descricao: "Efeitos adversos de outros diuréticos",
            favorito: false
        },
        {
            cid10: "Y546",
            descricao: "Efeitos adversos de agentes que atuam sobre o equilíbrio eletrolítico, calórico e hídrico",
            favorito: false
        },
        {
            cid10: "Y547",
            descricao: "Efeitos adversos de medicamentos que atuam sobre o metabolismo de cálcio",
            favorito: false
        },
        {
            cid10: "Y548",
            descricao: "Efeitos adversos de drogas que atuam sobre o metabolismo de ácido úrico",
            favorito: false
        },
        {
            cid10: "Y549",
            descricao: "Efeitos adversos de sais minerais não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "Y550",
            descricao: "Efeitos adversos de ocitócicos",
            favorito: false
        },
        {
            cid10: "Y551",
            descricao: "Efeitos adversos de relaxantes da musculatura esquelética [bloqueadores neuromusculares] [curarizantes]",
            favorito: false
        },
        {
            cid10: "Y552",
            descricao: "Efeitos adversos de outras substâncias que atuam primariamente sobre os músculos e as não especificadas",
            favorito: false
        },
        {
            cid10: "Y553",
            descricao: "Efeitos adversos de antitússicos",
            favorito: false
        },
        {
            cid10: "Y554",
            descricao: "Efeitos adversos de expectorantes",
            favorito: false
        },
        {
            cid10: "Y555",
            descricao: "Efeitos adversos de drogas anti-resfriado comum",
            favorito: false
        },
        {
            cid10: "Y556",
            descricao: "Efeitos adversos de antiasmáticos, não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "Y559",
            descricao: "Efeitos adversos de outras drogas que atuam sobre o aparelho respiratório e as não especificadas",
            favorito: false
        },
        {
            cid10: "Y560",
            descricao: "Efeitos adversos de drogas antifúngicas, antiinfecciosas e antiinflamatórias de uso local, não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "Y561",
            descricao: "Efeitos adversos de antipruriginosos",
            favorito: false
        },
        {
            cid10: "Y562",
            descricao: "Efeitos adversos de adstringentes e detergentes locais",
            favorito: false
        },
        {
            cid10: "Y563",
            descricao: "Efeitos adversos de emolientes, suavizantes e protetores da pele",
            favorito: false
        },
        {
            cid10: "Y564",
            descricao: "Efeitos adversos de ceratolíticos, ceratoplásticos e outros medicamentos e preparações capilares",
            favorito: false
        },
        {
            cid10: "Y565",
            descricao: "Efeitos adversos de medicamentos e preparações de uso oftalmológico",
            favorito: false
        },
        {
            cid10: "Y566",
            descricao: "Efeitos adversos de medicamentos e preparações usados em otorrinolaringologia",
            favorito: false
        },
        {
            cid10: "Y567",
            descricao: "Efeitos adversos de drogas de uso dentário aplicadas topicamente",
            favorito: false
        },
        {
            cid10: "Y568",
            descricao: "Efeitos adversos de outras substâncias de uso tópico",
            favorito: false
        },
        {
            cid10: "Y569",
            descricao: "Efeitos adversos de substância não especificada de uso tópico",
            favorito: false
        },
        {
            cid10: "Y570",
            descricao: "Efeitos adversos de depressores do apetite [anoréxicos]",
            favorito: false
        },
        {
            cid10: "Y571",
            descricao: "Efeitos adversos de lipotrópicos",
            favorito: false
        },
        {
            cid10: "Y572",
            descricao: "Efeitos adversos de antídotos e agentes quelantes, não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "Y573",
            descricao: "Efeitos adversos de substâncias para restringir a ingestão de álcool",
            favorito: false
        },
        {
            cid10: "Y574",
            descricao: "Efeitos adversos de excipientes farmacêuticos",
            favorito: false
        },
        {
            cid10: "Y575",
            descricao: "Efeitos adversos de substâncias utilizadas como contraste em radiologia",
            favorito: false
        },
        {
            cid10: "Y576",
            descricao: "Efeitos adversos de outras substâncias para fins diagnósticos",
            favorito: false
        },
        {
            cid10: "Y577",
            descricao: "Efeitos adversos de vitaminas, não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "Y578",
            descricao: "Efeitos adversos de outras drogas e medicamentos",
            favorito: false
        },
        {
            cid10: "Y579",
            descricao: "Efeitos adversos de droga e medicamento não especificado",
            favorito: false
        },
        {
            cid10: "Y580",
            descricao: "Efeitos adversos da vacina BCG",
            favorito: false
        },
        {
            cid10: "Y581",
            descricao: "Efeitos adversos de vacinas antitifoídica e antiparafoídica",
            favorito: false
        },
        {
            cid10: "Y582",
            descricao: "Efeitos adversos da vacina anticolérica",
            favorito: false
        },
        {
            cid10: "Y583",
            descricao: "Efeitos adversos da vacina antipeste",
            favorito: false
        },
        {
            cid10: "Y584",
            descricao: "Efeitos adversos da vacina antitetânica",
            favorito: false
        },
        {
            cid10: "Y585",
            descricao: "Efeitos adversos da vacina antidiftérica",
            favorito: false
        },
        {
            cid10: "Y586",
            descricao: "Efeitos adversos da vacina antipertussis, incluindo as combinações em que um dos componentes é a antipertussis",
            favorito: false
        },
        {
            cid10: "Y588",
            descricao: "Efeitos adversos de vacinas bacterianas mistas, exceto as combinações em que um dos componentes é a antipertussis",
            favorito: false
        },
        {
            cid10: "Y589",
            descricao: "Efeitos adversos de outras vacinas bacterianas e as não especificadas",
            favorito: false
        },
        {
            cid10: "Y590",
            descricao: "Efeitos adversos de vacinas antivirais",
            favorito: false
        },
        {
            cid10: "Y591",
            descricao: "Efeitos adversos de vacinas anti-rickettsias",
            favorito: false
        },
        {
            cid10: "Y592",
            descricao: "Efeitos adversos de vacinas antiprotozoários",
            favorito: false
        },
        {
            cid10: "Y593",
            descricao: "Efeitos adversos da imunoglobulina",
            favorito: false
        },
        {
            cid10: "Y598",
            descricao: "Efeitos adversos de outras vacinas e substâncias biológicas especificadas",
            favorito: false
        },
        {
            cid10: "Y599",
            descricao: "Efeitos adversos de vacina ou substância biológica, não especificada",
            favorito: false
        },
        {
            cid10: "Y600",
            descricao: "Corte, punção, perfuração ou hemorragia acidentais durante intervenção cirúrgica",
            favorito: false
        },
        {
            cid10: "Y601",
            descricao: "Corte, punção, perfuração ou hemorragia acidentais durante infusão ou transfusão",
            favorito: false
        },
        {
            cid10: "Y602",
            descricao: "Corte, punção, perfuração ou hemorragia acidentais durante hemodiálise ou outras perfusões",
            favorito: false
        },
        {
            cid10: "Y603",
            descricao: "Corte, punção, perfuração ou hemorragia acidentais durante injeção ou vacinação (imunização)",
            favorito: false
        },
        {
            cid10: "Y604",
            descricao: "Corte, punção, perfuração ou hemorragia acidentais durante exame endoscópico",
            favorito: false
        },
        {
            cid10: "Y605",
            descricao: "Corte, punção, perfuração ou hemorragia acidentais durante cateterismo cardíaco",
            favorito: false
        },
        {
            cid10: "Y606",
            descricao: "Corte, punção, perfuração ou hemorragia acidentais durante aspiração, punção ou outro tipo de cateterização",
            favorito: false
        },
        {
            cid10: "Y607",
            descricao: "Corte, punção, perfuração ou hemorragia acidentais durante a administração de enema",
            favorito: false
        },
        {
            cid10: "Y608",
            descricao: "Corte, punção, perfuração ou hemorragia acidentais durante a prestação de outros cuidados cirúrgicos e médicos",
            favorito: false
        },
        {
            cid10: "Y609",
            descricao: "Corte, punção, perfuração ou hemorragia acidentais durante a prestação de cuidado cirúrgico e médico, não especificados",
            favorito: false
        },
        {
            cid10: "Y610",
            descricao: "Objeto estranho deixado acidentalmente no corpo durante intervenção cirúrgica",
            favorito: false
        },
        {
            cid10: "Y611",
            descricao: "Objeto estranho deixado acidentalmente no corpo durante infusão ou transfusão",
            favorito: false
        },
        {
            cid10: "Y612",
            descricao: "Objeto estranho deixado acidentalmente no corpo durante hemodiálise ou outras perfusões",
            favorito: false
        },
        {
            cid10: "Y613",
            descricao: "Objeto estranho deixado acidentalmente no corpo durante injeção ou vacinação (imunização)",
            favorito: false
        },
        {
            cid10: "Y614",
            descricao: "Objeto estranho deixado acidentalmente no corpo durante exame endoscópico",
            favorito: false
        },
        {
            cid10: "Y615",
            descricao: "Objeto estranho deixado acidentalmente no corpo durante cateterismo cardíaco",
            favorito: false
        },
        {
            cid10: "Y616",
            descricao: "Objeto estranho deixado acidentalmente no corpo durante aspiração, punção ou outro tipo de cateterização",
            favorito: false
        },
        {
            cid10: "Y617",
            descricao: "Objeto estranho deixado acidentalmente no corpo durante a extração de um cateter ou de compressas",
            favorito: false
        },
        {
            cid10: "Y618",
            descricao: "Objeto estranho deixado acidentalmente no corpo durante a prestação de outro cuidado cirúrgico e médico",
            favorito: false
        },
        {
            cid10: "Y619",
            descricao: "Objeto estranho deixado acidentalmente no corpo durante a prestação de cuidado cirúrgico e médico não especificados",
            favorito: false
        },
        {
            cid10: "Y620",
            descricao: "Assepsia insuficiente durante intervenção cirúrgica",
            favorito: false
        },
        {
            cid10: "Y621",
            descricao: "Assepsia insuficiente durante infusão ou transfusão",
            favorito: false
        },
        {
            cid10: "Y622",
            descricao: "Assepsia insuficiente durante hemodiálise ou outras perfusões",
            favorito: false
        },
        {
            cid10: "Y623",
            descricao: "Assepsia insuficiente durante injeção ou vacinação (imunização)",
            favorito: false
        },
        {
            cid10: "Y624",
            descricao: "Assepsia insuficiente durante exame endoscópico",
            favorito: false
        },
        {
            cid10: "Y625",
            descricao: "Assepsia insuficiente durante cateterismo cardíaco",
            favorito: false
        },
        {
            cid10: "Y626",
            descricao: "Assepsia insuficiente durante aspiração, punção ou outro tipo de cateterização",
            favorito: false
        },
        {
            cid10: "Y628",
            descricao: "Assepsia insuficiente durante a prestação de outro cuidado cirúrgico e médico",
            favorito: false
        },
        {
            cid10: "Y629",
            descricao: "Assepsia insuficiente durante a prestação de cuidado cirúrgico e médico não especificados",
            favorito: false
        },
        {
            cid10: "Y630",
            descricao: "Administração de quantidade excessiva de sangue ou de um outro líquido durante transfusão ou infusão",
            favorito: false
        },
        {
            cid10: "Y631",
            descricao: "Diluição incorreta de líquido usado durante a infusão",
            favorito: false
        },
        {
            cid10: "Y632",
            descricao: "Superdosagem de radiação administrada durante a terapia",
            favorito: false
        },
        {
            cid10: "Y633",
            descricao: "Exposição inadvertida de paciente a radiação durante a prestação de cuidado médico",
            favorito: false
        },
        {
            cid10: "Y634",
            descricao: "Erro de dosagem na terapia por eletrochoque ou choque insulínico",
            favorito: false
        },
        {
            cid10: "Y635",
            descricao: "Temperatura inadequada durante aplicação local ou curativo",
            favorito: false
        },
        {
            cid10: "Y636",
            descricao: "Não administração de droga, medicamento ou substância biológica necessária",
            favorito: false
        },
        {
            cid10: "Y638",
            descricao: "Erros de dosagem durante a prestação de outro cuidado médico e cirúrgico",
            favorito: false
        },
        {
            cid10: "Y639",
            descricao: "Erros de dosagem durante a prestação de cuidados médico e cirúrgico, não especificados",
            favorito: false
        },
        {
            cid10: "Y640",
            descricao: "Transfusão ou infusão de medicamento ou substância biológica contaminados",
            favorito: false
        },
        {
            cid10: "Y641",
            descricao: "Injeção ou vacinação com medicamento ou substância biológica contaminados",
            favorito: false
        },
        {
            cid10: "Y648",
            descricao: "Administração por outros meios de medicamento ou substância biológicas contaminados",
            favorito: false
        },
        {
            cid10: "Y649",
            descricao: "Medicamento e substância biológica contaminados, administrada por meios não especificados",
            favorito: false
        },
        {
            cid10: "Y650",
            descricao: "Uso de sangue incompatível em transfusão",
            favorito: false
        },
        {
            cid10: "Y651",
            descricao: "Uso de líquido errado em infusão",
            favorito: false
        },
        {
            cid10: "Y652",
            descricao: "Falha de sutura ou de ligadura durante intervenção cirúrgica",
            favorito: false
        },
        {
            cid10: "Y653",
            descricao: "Cânula endotraqueal mal posicionada durante procedimento anestésico",
            favorito: false
        },
        {
            cid10: "Y654",
            descricao: "Falha na introdução ou na remoção de outras cânulas ou instrumentos",
            favorito: false
        },
        {
            cid10: "Y655",
            descricao: "Realização de uma operação inapropriada",
            favorito: false
        },
        {
            cid10: "Y658",
            descricao: "Outros acidentes especificados durante a prestação de cuidado médico e cirúrgico",
            favorito: false
        },
        {
            cid10: "Y66",
            descricao: "Não administração de cuidado médico e cirúrgico",
            favorito: false
        },
        {
            cid10: "Y69",
            descricao: "Acidente não especificado durante a prestação de cuidado médico e cirúrgico",
            favorito: false
        },
        {
            cid10: "Y700",
            descricao: "Dispositivos (aparelhos) de anestesiologia, associados a incidentes adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização",
            favorito: false
        },
        {
            cid10: "Y701",
            descricao: "Dispositivos (aparelhos) de anestesiologia, associados a incidentes adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação",
            favorito: false
        },
        {
            cid10: "Y702",
            descricao: "Dispositivos (aparelhos) de anestesiologia, associados a incidentes adversos - próteses e outros implantes, incluindo materiais e acessórios",
            favorito: false
        },
        {
            cid10: "Y703",
            descricao: "Dispositivos (aparelhos) de anestesiologia, associados a incidentes adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)",
            favorito: false
        },
        {
            cid10: "Y708",
            descricao: "Dispositivos (aparelhos) de anestesiologia, associados a incidentes adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "Y710",
            descricao: "Dispositivos (aparelhos) cardiovasculares, associados a incidentes adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização",
            favorito: false
        },
        {
            cid10: "Y711",
            descricao: "Dispositivos (aparelhos) cardiovasculares, associados a incidentes adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação",
            favorito: false
        },
        {
            cid10: "Y712",
            descricao: "Dispositivos (aparelhos) cardiovasculares, associados a incidentes adversos - próteses e outros implantes, incluindo materiais e acessórios",
            favorito: false
        },
        {
            cid10: "Y713",
            descricao: "Dispositivos (aparelhos) cardiovasculares, associados a incidentes adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)",
            favorito: false
        },
        {
            cid10: "Y718",
            descricao: "Dispositivos (aparelhos) cardiovasculares, associados a incidentes adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "Y720",
            descricao: "Dispositivos (aparelhos) utilizados em otorrinolaringologia, associados a incidentes adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização",
            favorito: false
        },
        {
            cid10: "Y721",
            descricao: "Dispositivos (aparelhos) utilizados em otorrinolaringologia, associados a incidentes adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação",
            favorito: false
        },
        {
            cid10: "Y722",
            descricao: "Dispositivos (aparelhos) utilizados em otorrinolaringologia, associados a incidentes adversos - próteses e outros implantes, incluindo materiais e acessórios",
            favorito: false
        },
        {
            cid10: "Y723",
            descricao: "Dispositivos (aparelhos) utilizados em otorrinolaringologia, associados a incidentes adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)",
            favorito: false
        },
        {
            cid10: "Y728",
            descricao: "Dispositivos (aparelhos) utilizados em otorrinolaringologia, associados a incidentes adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "Y730",
            descricao: "Dispositivos (aparelhos) usados em gastroenterologia e em urologia, associados a incidentes adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização",
            favorito: false
        },
        {
            cid10: "Y731",
            descricao: "Dispositivos (aparelhos) usados em gastroenterologia e em urologia, associados a incidentes adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação",
            favorito: false
        },
        {
            cid10: "Y732",
            descricao: "Dispositivos (aparelhos) usados em gastroenterologia e em urologia, associados a incidentes adversos - próteses e outros implantes, incluindo materiais e acessórios",
            favorito: false
        },
        {
            cid10: "Y733",
            descricao: "Dispositivos (aparelhos) usados em gastroenterologia e em urologia, associados a incidentes adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)",
            favorito: false
        },
        {
            cid10: "Y738",
            descricao: "Dispositivos (aparelhos) usados em gastroenterologia e em urologia, associados a incidentes adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "Y740",
            descricao: "Dispositivos (aparelhos) gerais de uso hospitalar ou pessoal, associados a incidentes adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização",
            favorito: false
        },
        {
            cid10: "Y741",
            descricao: "Dispositivos (aparelhos) gerais de uso hospitalar ou pessoal, associados a incidentes adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação",
            favorito: false
        },
        {
            cid10: "Y742",
            descricao: "Dispositivos (aparelhos) gerais de uso hospitalar ou pessoal, associados a incidentes adversos - próteses e outros implantes, incluindo materiais e acessórios",
            favorito: false
        },
        {
            cid10: "Y743",
            descricao: "Dispositivos (aparelhos) gerais de uso hospitalar ou pessoal, associados a incidentes adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)",
            favorito: false
        },
        {
            cid10: "Y748",
            descricao: "Dispositivos (aparelhos) gerais de uso hospitalar ou pessoal, associados a incidentes adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "Y750",
            descricao: "Dispositivos (aparelhos) utilizados em neurologia, associados a incidentes adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização",
            favorito: false
        },
        {
            cid10: "Y751",
            descricao: "Dispositivos (aparelhos) utilizados em neurologia, associados a incidentes adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação",
            favorito: false
        },
        {
            cid10: "Y752",
            descricao: "Dispositivos (aparelhos) utilizados em neurologia, associados a incidentes adversos - próteses e outros implantes, incluindo materiais e acessórios",
            favorito: false
        },
        {
            cid10: "Y753",
            descricao: "Dispositivos (aparelhos) utilizados em neurologia, associados a incidentes adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)",
            favorito: false
        },
        {
            cid10: "Y758",
            descricao: "Dispositivos (aparelhos) utilizados em neurologia, associados a incidentes adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "Y760",
            descricao: "Dispositivos (aparelhos) utilizados em obstetrícia e em ginecologia, associados a incidentes adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização",
            favorito: false
        },
        {
            cid10: "Y761",
            descricao: "Dispositivos (aparelhos) utilizados em obstetrícia e em ginecologia, associados a incidentes adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação",
            favorito: false
        },
        {
            cid10: "Y762",
            descricao: "Dispositivos (aparelhos) utilizados em obstetrícia e em ginecologia, associados a incidentes adversos - próteses e outros implantes, incluindo materiais e acessórios",
            favorito: false
        },
        {
            cid10: "Y763",
            descricao: "Dispositivos (aparelhos) utilizados em obstetrícia e em ginecologia, associados a incidentes adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)",
            favorito: false
        },
        {
            cid10: "Y768",
            descricao: "Dispositivos (aparelhos) utilizados em obstetrícia e em ginecologia, associados a incidentes adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "Y770",
            descricao: "Dispositivos (aparelhos) utilizados em oftalmologia, associados a incidentes adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização",
            favorito: false
        },
        {
            cid10: "Y771",
            descricao: "Dispositivos (aparelhos) utilizados em oftalmologia, associados a incidentes adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação",
            favorito: false
        },
        {
            cid10: "Y772",
            descricao: "Dispositivos (aparelhos) utilizados em oftalmologia, associados a incidentes adversos - próteses e outros implantes, incluindo materiais e acessórios",
            favorito: false
        },
        {
            cid10: "Y773",
            descricao: "Dispositivos (aparelhos) utilizados em oftalmologia, associados a incidentes adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)",
            favorito: false
        },
        {
            cid10: "Y778",
            descricao: "Dispositivos (aparelhos) utilizados em oftalmologia, associados a incidentes adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "Y780",
            descricao: "Dispositivos (aparelhos) utilizados em radiologia, associados a incidentes adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização",
            favorito: false
        },
        {
            cid10: "Y781",
            descricao: "Dispositivos (aparelhos) utilizados em radiologia, associados a incidentes adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação",
            favorito: false
        },
        {
            cid10: "Y782",
            descricao: "Dispositivos (aparelhos) utilizados em radiologia, associados a incidentes adversos - próteses e outros implantes, incluindo materiais e acessórios",
            favorito: false
        },
        {
            cid10: "Y783",
            descricao: "Dispositivos (aparelhos) utilizados em radiologia, associados a incidentes adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)",
            favorito: false
        },
        {
            cid10: "Y788",
            descricao: "Dispositivos (aparelhos) utilizados em radiologia, associados a incidentes adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "Y790",
            descricao: "Dispositivos (aparelhos) ortopédicos, associado a incidentes adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização",
            favorito: false
        },
        {
            cid10: "Y791",
            descricao: "Dispositivos (aparelhos) ortopédicos, associado a incidentes adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação",
            favorito: false
        },
        {
            cid10: "Y792",
            descricao: "Dispositivos (aparelhos) ortopédicos, associado a incidentes adversos - próteses e outros implantes, incluindo materiais e acessórios",
            favorito: false
        },
        {
            cid10: "Y793",
            descricao: "Dispositivos (aparelhos) ortopédicos, associado a incidentes adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)",
            favorito: false
        },
        {
            cid10: "Y798",
            descricao: "Dispositivos (aparelhos) ortopédicos, associado a incidentes adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "Y800",
            descricao: "Dispositivos (aparelhos) utilizados em medicina física (fisiatria), associado a incidentes adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização",
            favorito: false
        },
        {
            cid10: "Y801",
            descricao: "Dispositivos (aparelhos) utilizados em medicina física (fisiatria), associado a incidentes adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação",
            favorito: false
        },
        {
            cid10: "Y802",
            descricao: "Dispositivos (aparelhos) utilizados em medicina física (fisiatria), associado a incidentes adversos - próteses e outros implantes, incluindo materiais e acessórios",
            favorito: false
        },
        {
            cid10: "Y803",
            descricao: "Dispositivos (aparelhos) utilizados em medicina física (fisiatria), associado a incidentes adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)",
            favorito: false
        },
        {
            cid10: "Y808",
            descricao: "Dispositivos (aparelhos) utilizados em medicina física (fisiatria), associado a incidentes adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "Y810",
            descricao: "Dispositivos (aparelhos) utilizados em cirurgia geral ou cirurgia plástica, associados a incidente adversos - dispositivos (aparelhos) para fins diagnósticos ou de monitorização",
            favorito: false
        },
        {
            cid10: "Y811",
            descricao: "Dispositivos (aparelhos) utilizados em cirurgia geral ou cirurgia plástica, associados a incidente adversos - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação",
            favorito: false
        },
        {
            cid10: "Y812",
            descricao: "Dispositivos (aparelhos) utilizados em cirurgia geral ou cirurgia plástica, associados a incidente adversos - próteses e outros implantes, incluindo materiais e acessórios",
            favorito: false
        },
        {
            cid10: "Y813",
            descricao: "Dispositivos (aparelhos) utilizados em cirurgia geral ou cirurgia plástica, associados a incidente adversos - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)",
            favorito: false
        },
        {
            cid10: "Y818",
            descricao: "Dispositivos (aparelhos) utilizados em cirurgia geral ou cirurgia plástica, associados a incidente adversos - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "Y820",
            descricao: "Outros dispositivos (aparelhos) associados a incidentes adversos e os não especificados - dispositivos (aparelhos) para fins diagnósticos ou de monitorização",
            favorito: false
        },
        {
            cid10: "Y821",
            descricao: "Outros dispositivos (aparelhos) associados a incidentes adversos e os não especificados - dispositivos (aparelhos) para fins terapêuticos (não-cirúrgicos) e aparelhagem de reabilitação",
            favorito: false
        },
        {
            cid10: "Y822",
            descricao: "Outros dispositivos (aparelhos) associados a incidentes adversos e os não especificados - próteses e outros implantes, incluindo materiais e acessórios",
            favorito: false
        },
        {
            cid10: "Y823",
            descricao: "Outros dispositivos (aparelhos) associados a incidentes adversos e os não especificados - instrumentos, materiais e aparelhos cirúrgicos (incluindo suturas)",
            favorito: false
        },
        {
            cid10: "Y828",
            descricao: "Outros dispositivos (aparelhos) associados a incidentes adversos e os não especificados - dispositivos (aparelhos) diversos, inclusive combinações, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "Y830",
            descricao: "Reação anormal em paciente ou complicação tardia, causadas por intervenção cirúrgica com transplante de todo o órgão, sem menção de acidente durante a intervenção",
            favorito: false
        },
        {
            cid10: "Y831",
            descricao: "Reação anormal em paciente ou complicação tardia, causadas por intervenção cirúrgica com implante de uma prótese interna, sem menção de acidente durante a intervenção",
            favorito: false
        },
        {
            cid10: "Y832",
            descricao: "Reação anormal em paciente ou complicação tardia, causadas por intervenção cirúrgica com anastomose, derivação bypass ou enxerto, sem menção de acidente durante a intervenção",
            favorito: false
        },
        {
            cid10: "Y833",
            descricao: "Reação anormal em paciente ou complicação tardia, causadas por intervenção cirúrgica com formação de estoma externo, sem menção de acidente durante a intervenção",
            favorito: false
        },
        {
            cid10: "Y834",
            descricao: "Reação anormal em paciente ou complicação tardia, causadas por outras cirurgias reparadoras, sem menção de acidente durante a intervenção",
            favorito: false
        },
        {
            cid10: "Y835",
            descricao: "Reação anormal em paciente ou complicação tardia, causadas por amputação de membro(s), sem menção de acidente durante a intervenção",
            favorito: false
        },
        {
            cid10: "Y836",
            descricao: "Reação anormal em paciente ou complicação tardia, causadas por remoção de um outro órgão (parcial) (total), sem menção de acidente durante a intervenção",
            favorito: false
        },
        {
            cid10: "Y838",
            descricao: "Reação anormal em paciente ou complicação tardia, causadas por outras intervenções ou procedimentos cirúrgicos, sem menção de acidente durante a intervenção",
            favorito: false
        },
        {
            cid10: "Y839",
            descricao: "Reação anormal em paciente ou complicação tardia, causadas por intervenção cirúrgica, não especificada, sem menção de acidente durante a intervenção",
            favorito: false
        },
        {
            cid10: "Y840",
            descricao: "Reação anormal em paciente ou complicação tardia, causadas por cateterismo cardíaco, sem menção de acidente durante o procedimento",
            favorito: false
        },
        {
            cid10: "Y841",
            descricao: "Reação anormal em paciente ou complicação tardia, causadas por hemodiálise, sem menção de acidente durante o procedimento",
            favorito: false
        },
        {
            cid10: "Y842",
            descricao: "Reação anormal em paciente ou complicação tardia, causadas por procedimento radiológico e radioterapia, sem menção de acidente durante o procedimento",
            favorito: false
        },
        {
            cid10: "Y843",
            descricao: "Reação anormal em paciente ou complicação tardia, causadas por terapia por choque, sem menção de acidente durante o procedimento",
            favorito: false
        },
        {
            cid10: "Y844",
            descricao: "Reação anormal em paciente ou complicação tardia, causadas por aspiração de líquido, sem menção de acidente durante o procedimento",
            favorito: false
        },
        {
            cid10: "Y845",
            descricao: "Reação anormal em paciente ou complicação tardia, causadas por colocação de sonda gástrica ou duodenal, sem menção de acidente durante o procedimento",
            favorito: false
        },
        {
            cid10: "Y846",
            descricao: "Reação anormal em paciente ou complicação tardia, causadas por cateterismo urinário, sem menção de acidente durante o procedimento",
            favorito: false
        },
        {
            cid10: "Y847",
            descricao: "Reação anormal em paciente ou complicação tardia, causadas por amostra de sangue, sem menção de acidente durante o procedimento",
            favorito: false
        },
        {
            cid10: "Y848",
            descricao: "Reação anormal em paciente ou complicação tardia, causadas por outros procedimentos médicos, sem menção de acidente durante o procedimento",
            favorito: false
        },
        {
            cid10: "Y849",
            descricao: "Reação anormal em paciente ou complicação tardia, causadas por procedimento médico, não especificado, sem menção de acidente durante o procedimento",
            favorito: false
        },
        {
            cid10: "Y850",
            descricao: "Seqüelas de um acidente de veículo a motor",
            favorito: false
        },
        {
            cid10: "Y859",
            descricao: "Seqüelas de outros acidentes de transporte e dos não especificados",
            favorito: false
        },
        {
            cid10: "Y86",
            descricao: "Seqüelas de outros acidentes",
            favorito: false
        },
        {
            cid10: "Y870",
            descricao: "Seqüelas de lesões autoprovocadas intencionalmente",
            favorito: false
        },
        {
            cid10: "Y871",
            descricao: "Seqüelas de uma agressão",
            favorito: false
        },
        {
            cid10: "Y872",
            descricao: "Seqüelas de um fato cuja intenção é indeterminada",
            favorito: false
        },
        {
            cid10: "Y880",
            descricao: "Seqüelas de efeitos adversos causados por drogas, medicamentos ou substâncias biológicas usados com finalidade terapêutica",
            favorito: false
        },
        {
            cid10: "Y881",
            descricao: "Seqüelas de acidentes durante a prestação de cuidado médico e cirúrgico",
            favorito: false
        },
        {
            cid10: "Y882",
            descricao: "Seqüelas de incidentes adversos associados com o uso de dispositivos (aparelhos) médicos durante atos diagnósticos ou terapêuticos",
            favorito: false
        },
        {
            cid10: "Y883",
            descricao: "Seqüelas de reação anormal em paciente ou complicação tardia causada por procedimento médico e cirúrgico sem menção de acidente durante o procedimento",
            favorito: false
        },
        {
            cid10: "Y890",
            descricao: "Seqüelas de intervenção legal",
            favorito: false
        },
        {
            cid10: "Y891",
            descricao: "Seqüelas de operações de guerra",
            favorito: false
        },
        {
            cid10: "Y899",
            descricao: "Seqüelas de causa externa não especificada",
            favorito: false
        },
        {
            cid10: "Y900",
            descricao: "Alcoolemia inferior a 20 mg/100 ml",
            favorito: false
        },
        {
            cid10: "Y901",
            descricao: "Alcoolemia de 20-39 mg/100ml",
            favorito: false
        },
        {
            cid10: "Y902",
            descricao: "Alcoolemia de 40-59 mg/100ml",
            favorito: false
        },
        {
            cid10: "Y903",
            descricao: "Alcoolemia de 60-79 mg/100ml",
            favorito: false
        },
        {
            cid10: "Y904",
            descricao: "Alcoolemia de 80-99 mg/100ml",
            favorito: false
        },
        {
            cid10: "Y905",
            descricao: "Alcoolemia de 100-119 mg/100ml",
            favorito: false
        },
        {
            cid10: "Y906",
            descricao: "Alcoolemia de 120-199 mg/100ml",
            favorito: false
        },
        {
            cid10: "Y907",
            descricao: "Alcoolemia de 200-239 mg/100ml",
            favorito: false
        },
        {
            cid10: "Y908",
            descricao: "Alcoolemia igual ou superior a 240 mg/100ml",
            favorito: false
        },
        {
            cid10: "Y909",
            descricao: "Presença de álcool no sangue, taxa não especificada",
            favorito: false
        },
        {
            cid10: "Y910",
            descricao: "Intoxicação alcoólica leve",
            favorito: false
        },
        {
            cid10: "Y911",
            descricao: "Intoxicação alcoólica moderada",
            favorito: false
        },
        {
            cid10: "Y912",
            descricao: "Intoxicação alcoólica grave",
            favorito: false
        },
        {
            cid10: "Y913",
            descricao: "Intoxicação alcoólica muito grave",
            favorito: false
        },
        {
            cid10: "Y919",
            descricao: "Envolvimento com álcool não especificado de outra forma",
            favorito: false
        },
        {
            cid10: "Y95",
            descricao: "Circunstância relativa as condições nosocomiais (hospitalares)",
            favorito: false
        },
        {
            cid10: "Y96",
            descricao: "Circunstância relativa às condições de trabalho",
            favorito: false
        },
        {
            cid10: "Y97",
            descricao: "Circunstâncias relativas a condições de poluição ambiental",
            favorito: false
        },
        {
            cid10: "Y98",
            descricao: "Circunstâncias relativas a condições do modo de vida",
            favorito: false
        },
        {
            cid10: "Z000",
            descricao: "Exame médico geral",
            favorito: false
        },
        {
            cid10: "Z001",
            descricao: "Exame de rotina de saúde da criança",
            favorito: false
        },
        {
            cid10: "Z002",
            descricao: "Exame no período de crescimento rápido na infância",
            favorito: false
        },
        {
            cid10: "Z003",
            descricao: "Exame do adolescente durante o crescimento na puberdade",
            favorito: false
        },
        {
            cid10: "Z004",
            descricao: "Exame psiquiátrico geral não classificado em outra parte",
            favorito: false
        },
        {
            cid10: "Z005",
            descricao: "Exame de doador potencial de órgão e tecido",
            favorito: false
        },
        {
            cid10: "Z006",
            descricao: "Exame para comparação ou de controle de normalidade num programa de investigação clínica",
            favorito: false
        },
        {
            cid10: "Z008",
            descricao: "Outros exames gerais",
            favorito: false
        },
        {
            cid10: "Z010",
            descricao: "Exame dos olhos e da visão",
            favorito: false
        },
        {
            cid10: "Z011",
            descricao: "Exame dos ouvidos e da audição",
            favorito: false
        },
        {
            cid10: "Z012",
            descricao: "Exame dentário",
            favorito: false
        },
        {
            cid10: "Z013",
            descricao: "Exame da pressão arterial",
            favorito: false
        },
        {
            cid10: "Z014",
            descricao: "Exame ginecológico (geral) (de rotina)",
            favorito: false
        },
        {
            cid10: "Z015",
            descricao: "Testes cutâneos de diagnóstico e sensibilização",
            favorito: false
        },
        {
            cid10: "Z016",
            descricao: "Exame radiológico não classificado em outra parte",
            favorito: false
        },
        {
            cid10: "Z017",
            descricao: "Exame de laboratório",
            favorito: false
        },
        {
            cid10: "Z018",
            descricao: "Outros exames especiais especificados",
            favorito: false
        },
        {
            cid10: "Z019",
            descricao: "Exame especial não especificado",
            favorito: false
        },
        {
            cid10: "Z020",
            descricao: "Exame para admissão a instituição educacional",
            favorito: false
        },
        {
            cid10: "Z021",
            descricao: "Exame pré-emprego (pré-admissional)",
            favorito: false
        },
        {
            cid10: "Z022",
            descricao: "Exame para admissão em instituição residencial",
            favorito: false
        },
        {
            cid10: "Z023",
            descricao: "Exame para a incorporação nas forças armadas",
            favorito: false
        },
        {
            cid10: "Z024",
            descricao: "Exame para licença para a condução de veículos (carteira de motorista)",
            favorito: false
        },
        {
            cid10: "Z025",
            descricao: "Exame para participação em esporte",
            favorito: false
        },
        {
            cid10: "Z026",
            descricao: "Exame para fins de seguro",
            favorito: false
        },
        {
            cid10: "Z027",
            descricao: "Obtenção de atestado médico",
            favorito: false
        },
        {
            cid10: "Z028",
            descricao: "Outros exames para propósitos administrativos",
            favorito: false
        },
        {
            cid10: "Z029",
            descricao: "Exame não especificado com finalidades administrativas",
            favorito: false
        },
        {
            cid10: "Z030",
            descricao: "Observação por suspeita de tuberculose",
            favorito: false
        },
        {
            cid10: "Z031",
            descricao: "Observação por suspeita de neoplasia maligna",
            favorito: false
        },
        {
            cid10: "Z032",
            descricao: "Observação por suspeita de transtornos mentais e do comportamento",
            favorito: false
        },
        {
            cid10: "Z033",
            descricao: "Observação por suspeita de transtorno do sistema nervoso",
            favorito: false
        },
        {
            cid10: "Z034",
            descricao: "Observação por suspeita de infarto do miocárdio",
            favorito: false
        },
        {
            cid10: "Z035",
            descricao: "Observação por suspeita de outras doenças cardiovasculares",
            favorito: false
        },
        {
            cid10: "Z036",
            descricao: "Observação por suspeita de efeito tóxico de substância ingerida",
            favorito: false
        },
        {
            cid10: "Z038",
            descricao: "Observação por suspeita de outras doenças e afecções",
            favorito: false
        },
        {
            cid10: "Z039",
            descricao: "Observação por suspeita de doença ou afecção não especificada",
            favorito: false
        },
        {
            cid10: "Z040",
            descricao: "Teste para álcool e para drogas no sangue",
            favorito: false
        },
        {
            cid10: "Z041",
            descricao: "Exame e observação após acidente de transporte",
            favorito: false
        },
        {
            cid10: "Z042",
            descricao: "Exame e observação após acidente de trabalho",
            favorito: false
        },
        {
            cid10: "Z043",
            descricao: "Exame e observação após outro acidente",
            favorito: false
        },
        {
            cid10: "Z044",
            descricao: "Exame e observação após alegação de estupro e sedução",
            favorito: false
        },
        {
            cid10: "Z045",
            descricao: "Exame e observação após outros ferimentos infligidos",
            favorito: false
        },
        {
            cid10: "Z046",
            descricao: "Exame psiquiátrico geral, requerido por autoridade",
            favorito: false
        },
        {
            cid10: "Z048",
            descricao: "Exame e observação por outras razões especificadas",
            favorito: false
        },
        {
            cid10: "Z049",
            descricao: "Exame e observação por razão não especificada",
            favorito: false
        },
        {
            cid10: "Z080",
            descricao: "Exame de seguimento após tratamento cirúrgico de neoplasia maligna",
            favorito: false
        },
        {
            cid10: "Z081",
            descricao: "Exame de seguimento após radioterapia por neoplasia maligna",
            favorito: false
        },
        {
            cid10: "Z082",
            descricao: "Exame de seguimento após quimioterapia por neoplasia maligna",
            favorito: false
        },
        {
            cid10: "Z087",
            descricao: "Exame de seguimento após tratamento misto por neoplasia maligna",
            favorito: false
        },
        {
            cid10: "Z088",
            descricao: "Exame de seguimento após outro tratamento por neoplasia maligna",
            favorito: false
        },
        {
            cid10: "Z089",
            descricao: "Exame de seguimento após tratamento não especificado por neoplasia maligna",
            favorito: false
        },
        {
            cid10: "Z090",
            descricao: "Exame de seguimento após cirurgia por outras afecções",
            favorito: false
        },
        {
            cid10: "Z091",
            descricao: "Exame de seguimento após radioterapia por outras afecções",
            favorito: false
        },
        {
            cid10: "Z092",
            descricao: "Exame de seguimento após quimioterapia por outras afecções",
            favorito: false
        },
        {
            cid10: "Z093",
            descricao: "Exame de seguimento após psicoterapia",
            favorito: false
        },
        {
            cid10: "Z094",
            descricao: "Exame de seguimento após tratamento de fratura",
            favorito: false
        },
        {
            cid10: "Z097",
            descricao: "Exame de seguimento após tratamento combinado por outras afecções",
            favorito: false
        },
        {
            cid10: "Z098",
            descricao: "Exame de seguimento após outro tratamento por outras afecções",
            favorito: false
        },
        {
            cid10: "Z099",
            descricao: "Exame de seguimento após tratamento não especificado por outras afecções",
            favorito: false
        },
        {
            cid10: "Z100",
            descricao: "Exame de saúde ocupacional",
            favorito: false
        },
        {
            cid10: "Z101",
            descricao: "Exame geral de rotina de residentes de instituições",
            favorito: false
        },
        {
            cid10: "Z102",
            descricao: "Exame geral de rotina de pessoas das forças armadas",
            favorito: false
        },
        {
            cid10: "Z103",
            descricao: "Exame geral de rotina de equipe esportiva",
            favorito: false
        },
        {
            cid10: "Z108",
            descricao: "Exame geral de rotina de outra subpopulação definida",
            favorito: false
        },
        {
            cid10: "Z110",
            descricao: "Exame especial de rastreamento de doenças infecciosas intestinais",
            favorito: false
        },
        {
            cid10: "Z111",
            descricao: "Exame especial de rastreamento de tuberculose pulmonar",
            favorito: false
        },
        {
            cid10: "Z112",
            descricao: "Exame especial de rastreamento de outras doenças bacterianas",
            favorito: false
        },
        {
            cid10: "Z113",
            descricao: "Exame especial de rastreamento de infecções de transmissão predominantemente sexual",
            favorito: false
        },
        {
            cid10: "Z114",
            descricao: "Exame especial de rastreamento de vírus da imunodeficiência humana [HIV]",
            favorito: false
        },
        {
            cid10: "Z115",
            descricao: "Exame especial de rastreamento de outras doenças virais",
            favorito: false
        },
        {
            cid10: "Z116",
            descricao: "Exame especial de rastreamento de outras doenças por protozoários e por helmintos",
            favorito: false
        },
        {
            cid10: "Z118",
            descricao: "Exame especial de rastreamento de outras doenças infecciosas e parasitárias",
            favorito: false
        },
        {
            cid10: "Z119",
            descricao: "Exame especial de rastreamento para doença infecciosa e parasitária não especificada",
            favorito: false
        },
        {
            cid10: "Z120",
            descricao: "Exame especial de rastreamento de neoplasia do estômago",
            favorito: false
        },
        {
            cid10: "Z121",
            descricao: "Exame especial de rastreamento de neoplasia do trato intestinal",
            favorito: false
        },
        {
            cid10: "Z122",
            descricao: "Exame especial de rastreamento de neoplasia de órgãos respiratórios",
            favorito: false
        },
        {
            cid10: "Z123",
            descricao: "Exame especial de rastreamento de neoplasia de mama",
            favorito: false
        },
        {
            cid10: "Z124",
            descricao: "Exame especial de rastreamento de neoplasia do colo do útero",
            favorito: false
        },
        {
            cid10: "Z125",
            descricao: "Exame especial de rastreamento de neoplasia da próstata",
            favorito: false
        },
        {
            cid10: "Z126",
            descricao: "Exame especial de rastreamento de neoplasia da bexiga",
            favorito: false
        },
        {
            cid10: "Z128",
            descricao: "Exame especial de rastreamento de neoplasias de outras localizações",
            favorito: false
        },
        {
            cid10: "Z129",
            descricao: "Exame especial de rastreamento de neoplasia não especificada",
            favorito: false
        },
        {
            cid10: "Z130",
            descricao: "Exame especial de rastreamento de doenças do sangue e dos órgãos hematopoéticos de alguns transtornos imunitários",
            favorito: false
        },
        {
            cid10: "Z131",
            descricao: "Exame especial de rastreamento de diabetes mellitus",
            favorito: false
        },
        {
            cid10: "Z132",
            descricao: "Exame especial de rastreamento de transtornos nutricionais",
            favorito: false
        },
        {
            cid10: "Z133",
            descricao: "Exame especial de rastreamento de transtornos mentais e do comportamento",
            favorito: false
        },
        {
            cid10: "Z134",
            descricao: "Exame especial de rastreamento de alguns transtornos do desenvolvimento na infância",
            favorito: false
        },
        {
            cid10: "Z135",
            descricao: "Exame especial de rastreamento de doenças dos ouvidos e dos olhos",
            favorito: false
        },
        {
            cid10: "Z136",
            descricao: "Exame especial de rastreamento de doenças cardiovasculares",
            favorito: false
        },
        {
            cid10: "Z137",
            descricao: "Exame especial de rastreamento de malformações congênitas, deformidades e anomalias cromossômicas",
            favorito: false
        },
        {
            cid10: "Z138",
            descricao: "Exame especial de rastreamento de outras doenças e transtornos especificados",
            favorito: false
        },
        {
            cid10: "Z139",
            descricao: "Exame especial de rastreamento não especificado",
            favorito: false
        },
        {
            cid10: "Z200",
            descricao: "Contato com e exposição a doenças infecciosas intestinais",
            favorito: false
        },
        {
            cid10: "Z201",
            descricao: "Contato com e exposição à tuberculose",
            favorito: false
        },
        {
            cid10: "Z202",
            descricao: "Contato com e exposição a infecções de transmissão predominantemente sexual",
            favorito: false
        },
        {
            cid10: "Z203",
            descricao: "Contato com e exposição à raiva",
            favorito: false
        },
        {
            cid10: "Z204",
            descricao: "Contato com e exposição à rubéola",
            favorito: false
        },
        {
            cid10: "Z205",
            descricao: "Contato com e exposição à hepatite viral",
            favorito: false
        },
        {
            cid10: "Z206",
            descricao: "Contato com e exposição ao vírus da imunodeficiência humana [HIV]",
            favorito: false
        },
        {
            cid10: "Z207",
            descricao: "Contato com e exposição à pediculose, acaríase e outras infestações",
            favorito: false
        },
        {
            cid10: "Z208",
            descricao: "Contato com e exposição a outras doenças transmissíveis",
            favorito: false
        },
        {
            cid10: "Z209",
            descricao: "Contato com e exposição a doença transmissível não especificada",
            favorito: false
        },
        {
            cid10: "Z21",
            descricao: "Estado de infecção assintomática pelo vírus da imunodeficiência humana [HIV]",
            favorito: false
        },
        {
            cid10: "Z220",
            descricao: "Portador de febre tifóide",
            favorito: false
        },
        {
            cid10: "Z221",
            descricao: "Portador de outras doenças infecciosas intestinais",
            favorito: false
        },
        {
            cid10: "Z222",
            descricao: "Portador de difteria",
            favorito: false
        },
        {
            cid10: "Z223",
            descricao: "Portador de outras doenças bacterianas especificadas",
            favorito: false
        },
        {
            cid10: "Z224",
            descricao: "Portador de infecções com modo de transmissão predominantemente sexual",
            favorito: false
        },
        {
            cid10: "Z225",
            descricao: "Portador de hepatite viral",
            favorito: false
        },
        {
            cid10: "Z226",
            descricao: "Portador de infecção pelo vírus T-linfotrópico tipo 1 [HTLV-1]",
            favorito: false
        },
        {
            cid10: "Z228",
            descricao: "Portador de outras doenças infecciosas",
            favorito: false
        },
        {
            cid10: "Z229",
            descricao: "Portador de doença infecciosa não especificada",
            favorito: false
        },
        {
            cid10: "Z230",
            descricao: "Necessidade de imunização somente contra cólera",
            favorito: false
        },
        {
            cid10: "Z231",
            descricao: "Necessidade de imunização somente contra febre tifóide e paratifóide [TAB]",
            favorito: false
        },
        {
            cid10: "Z232",
            descricao: "Necessidade de imunização contra tuberculose [BCG]",
            favorito: false
        },
        {
            cid10: "Z233",
            descricao: "Necessidade de imunização contra a peste",
            favorito: false
        },
        {
            cid10: "Z234",
            descricao: "Necessidade de imunização contra a tularemia",
            favorito: false
        },
        {
            cid10: "Z235",
            descricao: "Necessidade de imunização somente contra o tétano",
            favorito: false
        },
        {
            cid10: "Z236",
            descricao: "Necessidade de imunização somente contra a difteria",
            favorito: false
        },
        {
            cid10: "Z237",
            descricao: "Necessidade de imunização somente contra a coqueluche",
            favorito: false
        },
        {
            cid10: "Z238",
            descricao: "Vacinação contra outras doenças bacterianas únicas",
            favorito: false
        },
        {
            cid10: "Z240",
            descricao: "Necessidade de imunização contra a poliomielite",
            favorito: false
        },
        {
            cid10: "Z241",
            descricao: "Necessidade de imunização contra a encefalite viral transmitida por artrópodes",
            favorito: false
        },
        {
            cid10: "Z242",
            descricao: "Necessidade de imunização contra a raiva",
            favorito: false
        },
        {
            cid10: "Z243",
            descricao: "Necessidade de imunização contra a febre amarela",
            favorito: false
        },
        {
            cid10: "Z244",
            descricao: "Necessidade de imunização somente contra o sarampo",
            favorito: false
        },
        {
            cid10: "Z245",
            descricao: "Necessidade de imunização somente contra a rubéola",
            favorito: false
        },
        {
            cid10: "Z246",
            descricao: "Necessidade de imunização somente contra a hepatite viral",
            favorito: false
        },
        {
            cid10: "Z250",
            descricao: "Necessidade de imunização somente contra a caxumba [parotidite epidêmica]",
            favorito: false
        },
        {
            cid10: "Z251",
            descricao: "Necessidade de imunização somente contra a influenza [gripe]",
            favorito: false
        },
        {
            cid10: "Z258",
            descricao: "Necessidade de imunização contra outras doenças virais únicas especificadas",
            favorito: false
        },
        {
            cid10: "Z260",
            descricao: "Necessidade de imunização contra a leishmaniose",
            favorito: false
        },
        {
            cid10: "Z268",
            descricao: "Necessidade de imunização contra outras doenças infecciosas especificadas únicas",
            favorito: false
        },
        {
            cid10: "Z269",
            descricao: "Necessidade de imunização contra doença infecciosa não especificada",
            favorito: false
        },
        {
            cid10: "Z270",
            descricao: "Necessidade de imunização contra a cólera e as febres tifóide e paratifóides [Cólera + TAB]",
            favorito: false
        },
        {
            cid10: "Z271",
            descricao: "Necessidade de imunização associada contra a difteria-pertussis-tétano, combinada [DPT]",
            favorito: false
        },
        {
            cid10: "Z272",
            descricao: "Necessidade de imunização contra a difteria-pertussis-tétano com febres tifóide e paratifóides [DPT + TAB]",
            favorito: false
        },
        {
            cid10: "Z273",
            descricao: "Necessidade de imunização contra a difteria-pertussis-tétano com poliomielite [DPT + pólio]",
            favorito: false
        },
        {
            cid10: "Z274",
            descricao: "Necessidade de imunização contra o sarampo-caxumba-rubéola",
            favorito: false
        },
        {
            cid10: "Z278",
            descricao: "Necessidade de imunização contra outras combinações de doenças infecciosas",
            favorito: false
        },
        {
            cid10: "Z279",
            descricao: "Necessidade de imunização contra combinações não especificadas de doenças infecciosas",
            favorito: false
        },
        {
            cid10: "Z280",
            descricao: "Imunização não realizada por contra-indicação",
            favorito: false
        },
        {
            cid10: "Z281",
            descricao: "Imunização não realizada por decisão do paciente devida à crença ou a grupo de pressão",
            favorito: false
        },
        {
            cid10: "Z282",
            descricao: "Imunização não realizada por decisão do paciente devida a razões outras e às não especificadas",
            favorito: false
        },
        {
            cid10: "Z288",
            descricao: "Imunização não realizada por outras razões",
            favorito: false
        },
        {
            cid10: "Z289",
            descricao: "Imunização não realizada por razões não especificadas",
            favorito: false
        },
        {
            cid10: "Z290",
            descricao: "Isolamento",
            favorito: false
        },
        {
            cid10: "Z291",
            descricao: "Imunoterapia profilática",
            favorito: false
        },
        {
            cid10: "Z292",
            descricao: "Outras quimioterapias profiláticas",
            favorito: false
        },
        {
            cid10: "Z298",
            descricao: "Outras medidas profiláticas especificadas",
            favorito: false
        },
        {
            cid10: "Z299",
            descricao: "Medida profilática não especificada",
            favorito: false
        },
        {
            cid10: "Z300",
            descricao: "Aconselhamento geral sobre contracepção",
            favorito: false
        },
        {
            cid10: "Z301",
            descricao: "Inserção de dispositivo anticoncepcional (intra-uterino)",
            favorito: false
        },
        {
            cid10: "Z302",
            descricao: "Esterilização",
            favorito: false
        },
        {
            cid10: "Z303",
            descricao: "Extração menstrual",
            favorito: false
        },
        {
            cid10: "Z304",
            descricao: "Supervisão do uso de medicamentos anticoncepcionais",
            favorito: false
        },
        {
            cid10: "Z305",
            descricao: "Supervisão de dispositivo anticoncepcional (intra-uterino)",
            favorito: false
        },
        {
            cid10: "Z308",
            descricao: "Outro procedimento anticoncepcional",
            favorito: false
        },
        {
            cid10: "Z309",
            descricao: "Procedimento anticoncepcional não especificado",
            favorito: false
        },
        {
            cid10: "Z310",
            descricao: "Tuboplastia ou vasoplastia após esterilização prévia",
            favorito: false
        },
        {
            cid10: "Z311",
            descricao: "Inseminação artificial",
            favorito: false
        },
        {
            cid10: "Z312",
            descricao: "Fecundação in vitro",
            favorito: false
        },
        {
            cid10: "Z313",
            descricao: "Outros métodos assistidos de fertilização",
            favorito: false
        },
        {
            cid10: "Z314",
            descricao: "Investigação e testes com relação à procriação",
            favorito: false
        },
        {
            cid10: "Z315",
            descricao: "Aconselhamento genético",
            favorito: false
        },
        {
            cid10: "Z316",
            descricao: "Aconselhamento geral sobre a procriação",
            favorito: false
        },
        {
            cid10: "Z318",
            descricao: "Outra medida especificada de procriação",
            favorito: false
        },
        {
            cid10: "Z319",
            descricao: "Medida procriativa não especificada",
            favorito: false
        },
        {
            cid10: "Z320",
            descricao: "Gravidez (ainda) não confirmada",
            favorito: false
        },
        {
            cid10: "Z321",
            descricao: "Gravidez confirmada",
            favorito: false
        },
        {
            cid10: "Z33",
            descricao: "Gravidez como achado casual",
            favorito: false
        },
        {
            cid10: "Z340",
            descricao: "Supervisão de primeira gravidez normal",
            favorito: false
        },
        {
            cid10: "Z348",
            descricao: "Supervisão de outra gravidez normal",
            favorito: false
        },
        {
            cid10: "Z349",
            descricao: "Supervisão de gravidez normal, não especificada",
            favorito: false
        },
        {
            cid10: "Z350",
            descricao: "Supervisão de gravidez com história de esterilidade",
            favorito: false
        },
        {
            cid10: "Z351",
            descricao: "Supervisão de gravidez com história de aborto",
            favorito: false
        },
        {
            cid10: "Z352",
            descricao: "Supervisão de gravidez com outros antecedentes de procriação problemática",
            favorito: false
        },
        {
            cid10: "Z353",
            descricao: "Supervisão de gravidez com história de assistência pré-natal insuficiente",
            favorito: false
        },
        {
            cid10: "Z354",
            descricao: "Supervisão de gravidez com grande multiparidade",
            favorito: false
        },
        {
            cid10: "Z355",
            descricao: "Supervisão de primigesta idosa",
            favorito: false
        },
        {
            cid10: "Z356",
            descricao: "Supervisão de primigesta muito jovem",
            favorito: false
        },
        {
            cid10: "Z357",
            descricao: "Supervisão de gravidez de alto risco devido a problemas sociais",
            favorito: false
        },
        {
            cid10: "Z358",
            descricao: "Supervisão de outras gravidezes de alto risco",
            favorito: false
        },
        {
            cid10: "Z359",
            descricao: "Supervisão não especificada de gravidez de alto risco",
            favorito: false
        },
        {
            cid10: "Z360",
            descricao: "Rastreamento de anomalias cromossômicas",
            favorito: false
        },
        {
            cid10: "Z361",
            descricao: "Rastreamento pré-natal de taxa elevada de alfa-fetoproteínas",
            favorito: false
        },
        {
            cid10: "Z362",
            descricao: "Outros rastreamentos pré-natais por amniocentese",
            favorito: false
        },
        {
            cid10: "Z363",
            descricao: "Rastreamento pré-natal de malformações por ultrassom e outros meios físicos",
            favorito: false
        },
        {
            cid10: "Z364",
            descricao: "Rastreamento pré-natal do retardo de crescimento do feto por ultrassom e outros métodos físicos",
            favorito: false
        },
        {
            cid10: "Z365",
            descricao: "Rastreamento pré-natal de isoimunização",
            favorito: false
        },
        {
            cid10: "Z368",
            descricao: "Outros rastreamentos pré-natais",
            favorito: false
        },
        {
            cid10: "Z369",
            descricao: "Rastreamento pré-natal não especificado",
            favorito: false
        },
        {
            cid10: "Z370",
            descricao: "Nascimento único, nativivo [nado-vivo]",
            favorito: false
        },
        {
            cid10: "Z371",
            descricao: "Nascimento único, natimorto [feto-morto]",
            favorito: false
        },
        {
            cid10: "Z372",
            descricao: "Nascimento gemelar, gêmeos (ambos) nativivos [nado-vivos]",
            favorito: false
        },
        {
            cid10: "Z373",
            descricao: "Nascimento gemelar, um dos gêmeos, nativivo [nado-vivo], o outro, natimorto [feto-morto]",
            favorito: false
        },
        {
            cid10: "Z374",
            descricao: "Nascimento gemelar, gêmeos natimortos [feto-mortos]",
            favorito: false
        },
        {
            cid10: "Z375",
            descricao: "Outros nascimentos múltiplos, todos nativivos [nado-vivos]",
            favorito: false
        },
        {
            cid10: "Z376",
            descricao: "Outros nascimentos múltiplos, alguns nativivos [nado-vivos]",
            favorito: false
        },
        {
            cid10: "Z377",
            descricao: "Outros nascimentos múltiplos, todos natimortos [feto-mortos]",
            favorito: false
        },
        {
            cid10: "Z379",
            descricao: "Nascimento não especificado",
            favorito: false
        },
        {
            cid10: "Z380",
            descricao: "Criança única, nascida em hospital",
            favorito: false
        },
        {
            cid10: "Z381",
            descricao: "Criança única, nascida fora do hospital",
            favorito: false
        },
        {
            cid10: "Z382",
            descricao: "Criança única, não especificado quanto ao local de nascimento",
            favorito: false
        },
        {
            cid10: "Z383",
            descricao: "Gêmeos (duplos), nascidos em hospital",
            favorito: false
        },
        {
            cid10: "Z384",
            descricao: "Gêmeos (duplos), nascidos fora de hospital",
            favorito: false
        },
        {
            cid10: "Z385",
            descricao: "Gêmeos, não especificados quanto ao local de nascimento",
            favorito: false
        },
        {
            cid10: "Z386",
            descricao: "Outros nascimentos múltiplos, em hospital",
            favorito: false
        },
        {
            cid10: "Z387",
            descricao: "Outros nascimentos múltiplos, fora de hospital",
            favorito: false
        },
        {
            cid10: "Z388",
            descricao: "Outros nascimentos múltiplos, não especificados quanto ao local de nascimento",
            favorito: false
        },
        {
            cid10: "Z390",
            descricao: "Assistência e exame imediatamente após o parto",
            favorito: false
        },
        {
            cid10: "Z391",
            descricao: "Assistência e exame da mãe nutriz",
            favorito: false
        },
        {
            cid10: "Z392",
            descricao: "Seguimento pós-parto de rotina",
            favorito: false
        },
        {
            cid10: "Z400",
            descricao: "Cirurgia profilática para fatores de risco relacionados com neoplasias malignas",
            favorito: false
        },
        {
            cid10: "Z408",
            descricao: "Outra cirurgia profilática",
            favorito: false
        },
        {
            cid10: "Z409",
            descricao: "Cirurgia profilática não especificada",
            favorito: false
        },
        {
            cid10: "Z410",
            descricao: "Transplante de cabelo",
            favorito: false
        },
        {
            cid10: "Z411",
            descricao: "Outras intervenções de cirurgia plástica por razões estéticas",
            favorito: false
        },
        {
            cid10: "Z412",
            descricao: "Circuncisão ritual e de rotina",
            favorito: false
        },
        {
            cid10: "Z413",
            descricao: "Perfuração do lobo da orelha",
            favorito: false
        },
        {
            cid10: "Z418",
            descricao: "Outros procedimentos para outros propósitos exceto cuidados de saúde",
            favorito: false
        },
        {
            cid10: "Z419",
            descricao: "Procedimentos não especificado para outros propósitos exceto cuidados de saúde",
            favorito: false
        },
        {
            cid10: "Z420",
            descricao: "Seguimento envolvendo cirurgia plástica da cabeça e do pescoço",
            favorito: false
        },
        {
            cid10: "Z421",
            descricao: "Seguimento envolvendo cirurgia plástica de mama",
            favorito: false
        },
        {
            cid10: "Z422",
            descricao: "Seguimento envolvendo cirurgia plástica de outras partes do tronco",
            favorito: false
        },
        {
            cid10: "Z423",
            descricao: "Seguimento envolvendo cirurgia plástica das extremidades superiores",
            favorito: false
        },
        {
            cid10: "Z424",
            descricao: "Seguimento envolvendo cirurgia plástica das extremidades inferiores",
            favorito: false
        },
        {
            cid10: "Z428",
            descricao: "Seguimento envolvendo cirurgia plástica de outras partes do corpo",
            favorito: false
        },
        {
            cid10: "Z429",
            descricao: "Seguimento envolvendo cirurgia plástica não especificada",
            favorito: false
        },
        {
            cid10: "Z430",
            descricao: "Cuidados à traqueostomia",
            favorito: false
        },
        {
            cid10: "Z431",
            descricao: "Cuidados à gastrostomia",
            favorito: false
        },
        {
            cid10: "Z432",
            descricao: "Cuidados à ileostomia",
            favorito: false
        },
        {
            cid10: "Z433",
            descricao: "Cuidados à colostomia",
            favorito: false
        },
        {
            cid10: "Z434",
            descricao: "Cuidados a outros orifícios artificiais do tubo digestivo",
            favorito: false
        },
        {
            cid10: "Z435",
            descricao: "Cuidados à cistostomia",
            favorito: false
        },
        {
            cid10: "Z436",
            descricao: "Cuidados a outros orifícios artificiais das vias urinárias",
            favorito: false
        },
        {
            cid10: "Z437",
            descricao: "Cuidados à vagina artificial",
            favorito: false
        },
        {
            cid10: "Z438",
            descricao: "Cuidados a outros orifícios artificiais",
            favorito: false
        },
        {
            cid10: "Z439",
            descricao: "Cuidados a orifício artificial não especificado",
            favorito: false
        },
        {
            cid10: "Z440",
            descricao: "Colocação e ajustamento de braço artificial (parcial) (total)",
            favorito: false
        },
        {
            cid10: "Z441",
            descricao: "Colocação e ajustamento de perna artificial (parcial) (total)",
            favorito: false
        },
        {
            cid10: "Z442",
            descricao: "Colocação e ajustamento de olho artificial",
            favorito: false
        },
        {
            cid10: "Z443",
            descricao: "Colocação e ajustamento de prótese externa de mama",
            favorito: false
        },
        {
            cid10: "Z448",
            descricao: "Colocação e ajustamento de outros aparelhos de prótese externa",
            favorito: false
        },
        {
            cid10: "Z449",
            descricao: "Colocação e ajustamento de aparelho de prótese externa não especificado",
            favorito: false
        },
        {
            cid10: "Z450",
            descricao: "Ajustamento e manuseio de marca-passo cardíaco",
            favorito: false
        },
        {
            cid10: "Z451",
            descricao: "Ajustamento e manuseio de bomba de infusão",
            favorito: false
        },
        {
            cid10: "Z452",
            descricao: "Ajustamento e manuseio de dispositivo de acesso vascular",
            favorito: false
        },
        {
            cid10: "Z453",
            descricao: "Ajustamento e manuseio de dispositivo implantado de audição",
            favorito: false
        },
        {
            cid10: "Z458",
            descricao: "Ajustamento e manuseio de outros dispositivos implantados",
            favorito: false
        },
        {
            cid10: "Z459",
            descricao: "Ajustamento e manuseio de dispositivo implantado não especificado",
            favorito: false
        },
        {
            cid10: "Z460",
            descricao: "Colocação e ajustamento de óculos e lentes de contato",
            favorito: false
        },
        {
            cid10: "Z461",
            descricao: "Colocação e ajustamento de aparelho auditivo",
            favorito: false
        },
        {
            cid10: "Z462",
            descricao: "Colocação e ajustamento de outros aparelhos relacionados com o sistema nervoso e órgãos dos sentidos",
            favorito: false
        },
        {
            cid10: "Z463",
            descricao: "Colocação e ajustamento de dispositivo de prótese dentária",
            favorito: false
        },
        {
            cid10: "Z464",
            descricao: "Colocação e ajustamento de aparelho ortodôntico",
            favorito: false
        },
        {
            cid10: "Z465",
            descricao: "Colocação e ajustamento de ileostomia e de outros dispositivos intestinais",
            favorito: false
        },
        {
            cid10: "Z466",
            descricao: "Colocação e ajustamento de prótese urinária",
            favorito: false
        },
        {
            cid10: "Z467",
            descricao: "Colocação e ajustamento de aparelho ortopédico",
            favorito: false
        },
        {
            cid10: "Z468",
            descricao: "Colocação e ajustamento de outros aparelhos especificados",
            favorito: false
        },
        {
            cid10: "Z469",
            descricao: "Colocação e ajustamento de aparelho não especificado",
            favorito: false
        },
        {
            cid10: "Z470",
            descricao: "Seguimento envolvendo remoção de placa de fratura e outros dispositivos de fixação interna",
            favorito: false
        },
        {
            cid10: "Z478",
            descricao: "Outros seguimentos ortopédicos especificados",
            favorito: false
        },
        {
            cid10: "Z479",
            descricao: "Seguimento ortopédico não especificado",
            favorito: false
        },
        {
            cid10: "Z480",
            descricao: "Cuidados a curativos e suturas cirúrgicas",
            favorito: false
        },
        {
            cid10: "Z488",
            descricao: "Outro seguimento cirúrgico especificado",
            favorito: false
        },
        {
            cid10: "Z489",
            descricao: "Seguimento cirúrgico não especificado",
            favorito: false
        },
        {
            cid10: "Z490",
            descricao: "Cuidado preparatório para diálise",
            favorito: false
        },
        {
            cid10: "Z491",
            descricao: "Diálise extracorpórea",
            favorito: false
        },
        {
            cid10: "Z492",
            descricao: "Outras diálises",
            favorito: false
        },
        {
            cid10: "Z500",
            descricao: "Reabilitação cardíaca",
            favorito: false
        },
        {
            cid10: "Z501",
            descricao: "Outra fisioterapia",
            favorito: false
        },
        {
            cid10: "Z502",
            descricao: "Reabilitação de alcoólatra",
            favorito: false
        },
        {
            cid10: "Z503",
            descricao: "Reabilitação de toxicodependentes",
            favorito: false
        },
        {
            cid10: "Z504",
            descricao: "Psicoterapia, não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "Z505",
            descricao: "Reabilitação da linguagem",
            favorito: false
        },
        {
            cid10: "Z506",
            descricao: "Treinamento ortóptico",
            favorito: false
        },
        {
            cid10: "Z507",
            descricao: "Terapia ocupacional e reabilitação vocacional não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "Z508",
            descricao: "Cuidados envolvendo uso de outros procedimentos de reabilitação",
            favorito: false
        },
        {
            cid10: "Z509",
            descricao: "Cuidados envolvendo uso de procedimento de reabilitação não especificado",
            favorito: false
        },
        {
            cid10: "Z510",
            descricao: "Sessão de radioterapia",
            favorito: false
        },
        {
            cid10: "Z511",
            descricao: "Sessão de quimioterapia por neoplasia",
            favorito: false
        },
        {
            cid10: "Z512",
            descricao: "Outra quimioterapia",
            favorito: false
        },
        {
            cid10: "Z513",
            descricao: "Transfusão de sangue, sem diagnóstico registrado",
            favorito: false
        },
        {
            cid10: "Z514",
            descricao: "Cuidado preparatório para tratamento subsequente não classificado em outra parte",
            favorito: false
        },
        {
            cid10: "Z515",
            descricao: "Cuidado paliativo",
            favorito: false
        },
        {
            cid10: "Z516",
            descricao: "Dessensibilização a alérgenos",
            favorito: false
        },
        {
            cid10: "Z518",
            descricao: "Outro cuidado médico especificado",
            favorito: false
        },
        {
            cid10: "Z519",
            descricao: "Cuidado médico não especificado",
            favorito: false
        },
        {
            cid10: "Z520",
            descricao: "Doador de sangue",
            favorito: false
        },
        {
            cid10: "Z521",
            descricao: "Doador de pele",
            favorito: false
        },
        {
            cid10: "Z522",
            descricao: "Doador de osso",
            favorito: false
        },
        {
            cid10: "Z523",
            descricao: "Doador de medula óssea",
            favorito: false
        },
        {
            cid10: "Z524",
            descricao: "Doador de rim",
            favorito: false
        },
        {
            cid10: "Z525",
            descricao: "Doador de córnea",
            favorito: false
        },
        {
            cid10: "Z526",
            descricao: "Doador de fígado",
            favorito: false
        },
        {
            cid10: "Z527",
            descricao: "Doador de coração",
            favorito: false
        },
        {
            cid10: "Z528",
            descricao: "Doador de outros órgãos ou tecidos",
            favorito: false
        },
        {
            cid10: "Z529",
            descricao: "Doador de órgão ou tecido não especificado",
            favorito: false
        },
        {
            cid10: "Z530",
            descricao: "Procedimento não realizado devido à contra-indicação",
            favorito: false
        },
        {
            cid10: "Z531",
            descricao: "Procedimento não realizado devido à decisão do paciente por razões de crença ou grupo de pressão",
            favorito: false
        },
        {
            cid10: "Z532",
            descricao: "Procedimento não realizado devido à decisão do paciente por outras razões e as não especificadas",
            favorito: false
        },
        {
            cid10: "Z538",
            descricao: "Procedimento não realizado por outras razões",
            favorito: false
        },
        {
            cid10: "Z539",
            descricao: "Procedimento não realizado por razão não especificada",
            favorito: false
        },
        {
            cid10: "Z540",
            descricao: "Convalescença após cirurgia",
            favorito: false
        },
        {
            cid10: "Z541",
            descricao: "Convalescença após radioterapia",
            favorito: false
        },
        {
            cid10: "Z542",
            descricao: "Convalescença após quimioterapia",
            favorito: false
        },
        {
            cid10: "Z543",
            descricao: "Convalescença após psicoterapia",
            favorito: false
        },
        {
            cid10: "Z544",
            descricao: "Convalescença após tratamento de fratura",
            favorito: false
        },
        {
            cid10: "Z547",
            descricao: "Convalescença após tratamento combinado",
            favorito: false
        },
        {
            cid10: "Z548",
            descricao: "Convalescença após outro tratamento",
            favorito: false
        },
        {
            cid10: "Z549",
            descricao: "Convalescença após tratamento não especificado",
            favorito: false
        },
        {
            cid10: "Z550",
            descricao: "Analfabetismo e baixa escolaridade",
            favorito: false
        },
        {
            cid10: "Z551",
            descricao: "Escolarização não disponível e impossível",
            favorito: false
        },
        {
            cid10: "Z552",
            descricao: "Reprovação em exames",
            favorito: false
        },
        {
            cid10: "Z553",
            descricao: "Maus resultados escolares",
            favorito: false
        },
        {
            cid10: "Z554",
            descricao: "Má adaptação escolar e dificuldades com professores e colegas",
            favorito: false
        },
        {
            cid10: "Z558",
            descricao: "Outros problemas relacionados com a educação e com a alfabetização",
            favorito: false
        },
        {
            cid10: "Z559",
            descricao: "Problemas não especificados relacionados com a educação e com a alfabetização",
            favorito: false
        },
        {
            cid10: "Z560",
            descricao: "Desemprego não especificado",
            favorito: false
        },
        {
            cid10: "Z561",
            descricao: "Mudança de emprego",
            favorito: false
        },
        {
            cid10: "Z562",
            descricao: "Ameaça de perda de emprego",
            favorito: false
        },
        {
            cid10: "Z563",
            descricao: "Ritmo de trabalho penoso",
            favorito: false
        },
        {
            cid10: "Z564",
            descricao: "Desacordo com patrão e colegas de trabalho",
            favorito: false
        },
        {
            cid10: "Z565",
            descricao: "Má adaptação ao trabalho",
            favorito: false
        },
        {
            cid10: "Z566",
            descricao: "Outras dificuldades físicas e mentais relacionadas ao trabalho",
            favorito: false
        },
        {
            cid10: "Z567",
            descricao: "Outros problemas e os não especificados relacionados com o emprego",
            favorito: false
        },
        {
            cid10: "Z570",
            descricao: "Exposição ocupacional ao ruído",
            favorito: false
        },
        {
            cid10: "Z571",
            descricao: "Exposição ocupacional à radiação",
            favorito: false
        },
        {
            cid10: "Z572",
            descricao: "Exposição ocupacional à poeira (pó)",
            favorito: false
        },
        {
            cid10: "Z573",
            descricao: "Exposição ocupacional a outros contaminantes do ar",
            favorito: false
        },
        {
            cid10: "Z574",
            descricao: "Exposição ocupacional a agentes tóxicos na agricultura",
            favorito: false
        },
        {
            cid10: "Z575",
            descricao: "Exposição ocupacional a agentes tóxicos em outras indústrias",
            favorito: false
        },
        {
            cid10: "Z576",
            descricao: "Exposição ocupacional a temperaturas extremas",
            favorito: false
        },
        {
            cid10: "Z577",
            descricao: "Exposição ocupacional à vibração",
            favorito: false
        },
        {
            cid10: "Z578",
            descricao: "Exposição ocupacional a outros fatores de risco",
            favorito: false
        },
        {
            cid10: "Z579",
            descricao: "Exposição ocupacional a fator de risco não especificado",
            favorito: false
        },
        {
            cid10: "Z580",
            descricao: "Exposição ao ruído",
            favorito: false
        },
        {
            cid10: "Z581",
            descricao: "Exposição à poluição atmosférica",
            favorito: false
        },
        {
            cid10: "Z582",
            descricao: "Exposição à poluição da água",
            favorito: false
        },
        {
            cid10: "Z583",
            descricao: "Exposição à poluição do solo",
            favorito: false
        },
        {
            cid10: "Z584",
            descricao: "Exposição à radiação",
            favorito: false
        },
        {
            cid10: "Z585",
            descricao: "Exposição a outras poluições",
            favorito: false
        },
        {
            cid10: "Z586",
            descricao: "Água potável insuficiente",
            favorito: false
        },
        {
            cid10: "Z587",
            descricao: "Exposição à fumaça de tabaco",
            favorito: false
        },
        {
            cid10: "Z588",
            descricao: "Outros problemas relacionados com o ambiente físico",
            favorito: false
        },
        {
            cid10: "Z589",
            descricao: "Problema não especificado relacionado com o ambiente físico",
            favorito: false
        },
        {
            cid10: "Z590",
            descricao: "Falta de domicílio fixo",
            favorito: false
        },
        {
            cid10: "Z591",
            descricao: "Habitação inadequada",
            favorito: false
        },
        {
            cid10: "Z592",
            descricao: "Desacordo com vizinhos, locatários e proprietários",
            favorito: false
        },
        {
            cid10: "Z593",
            descricao: "Problemas relacionados com a vida em instituição residencial",
            favorito: false
        },
        {
            cid10: "Z594",
            descricao: "Falta de alimentação adequada",
            favorito: false
        },
        {
            cid10: "Z595",
            descricao: "Pobreza extrema",
            favorito: false
        },
        {
            cid10: "Z596",
            descricao: "Baixo rendimento",
            favorito: false
        },
        {
            cid10: "Z597",
            descricao: "Seguro social e medidas de bem-estar social insuficientes",
            favorito: false
        },
        {
            cid10: "Z598",
            descricao: "Outros problemas relacionados com a habitação e com as circunstâncias econômicas",
            favorito: false
        },
        {
            cid10: "Z599",
            descricao: "Circunstâncias não especificadas econômicas ou de habitação",
            favorito: false
        },
        {
            cid10: "Z600",
            descricao: "Problemas de adaptação às transições do ciclo de vida",
            favorito: false
        },
        {
            cid10: "Z601",
            descricao: "Situação parental atípica",
            favorito: false
        },
        {
            cid10: "Z602",
            descricao: "Viver só",
            favorito: false
        },
        {
            cid10: "Z603",
            descricao: "Dificuldade de aculturação",
            favorito: false
        },
        {
            cid10: "Z604",
            descricao: "Exclusão e rejeição sociais",
            favorito: false
        },
        {
            cid10: "Z605",
            descricao: "Objeto de discriminação e perseguição percebidas",
            favorito: false
        },
        {
            cid10: "Z608",
            descricao: "Outros problemas relacionados com o meio social",
            favorito: false
        },
        {
            cid10: "Z609",
            descricao: "Problema não especificado relacionado com o meio social",
            favorito: false
        },
        {
            cid10: "Z610",
            descricao: "Perda de relação afetiva na infância",
            favorito: false
        },
        {
            cid10: "Z611",
            descricao: "Remoção do lar na infância",
            favorito: false
        },
        {
            cid10: "Z612",
            descricao: "Padrão alterado de relações familiares na infância",
            favorito: false
        },
        {
            cid10: "Z613",
            descricao: "Eventos que originam a perda de auto-estima na infância",
            favorito: false
        },
        {
            cid10: "Z614",
            descricao: "Problemas relacionados com abuso sexual alegado de uma criança por uma pessoa de dentro de seu grupo",
            favorito: false
        },
        {
            cid10: "Z615",
            descricao: "Problemas relacionados com abuso sexual alegado de uma criança por pessoa de fora de seu grupo",
            favorito: false
        },
        {
            cid10: "Z616",
            descricao: "Problemas relacionados com abuso físico alegado da criança",
            favorito: false
        },
        {
            cid10: "Z617",
            descricao: "Experiência pessoal amedrontadora na infância",
            favorito: false
        },
        {
            cid10: "Z618",
            descricao: "Outros eventos da vida pessoal negativos na infância",
            favorito: false
        },
        {
            cid10: "Z619",
            descricao: "Evento pessoal negativo não especificado na infância",
            favorito: false
        },
        {
            cid10: "Z620",
            descricao: "Supervisão e controle inadequado por parte dos pais",
            favorito: false
        },
        {
            cid10: "Z621",
            descricao: "Superproteção por parte dos pais",
            favorito: false
        },
        {
            cid10: "Z622",
            descricao: "Educação numa instituição",
            favorito: false
        },
        {
            cid10: "Z623",
            descricao: "Hostilidade com relação a uma criança, transformada em bode expiatório",
            favorito: false
        },
        {
            cid10: "Z624",
            descricao: "Negligência emocional da criança",
            favorito: false
        },
        {
            cid10: "Z625",
            descricao: "Outros problemas relacionados com negligência na educação dos filhos",
            favorito: false
        },
        {
            cid10: "Z626",
            descricao: "Pressões não apropriadas por parte dos pais e outras falhas de educação",
            favorito: false
        },
        {
            cid10: "Z628",
            descricao: "Outros problemas especificados relacionados com a educação dos filhos",
            favorito: false
        },
        {
            cid10: "Z629",
            descricao: "Problema não especificado relacionado com a educação dos filhos",
            favorito: false
        },
        {
            cid10: "Z630",
            descricao: "Problemas nas relações com cônjuge ou parceiro",
            favorito: false
        },
        {
            cid10: "Z631",
            descricao: "Problemas nas relações com os pais ou com os sogros",
            favorito: false
        },
        {
            cid10: "Z632",
            descricao: "Suporte familiar inadequado",
            favorito: false
        },
        {
            cid10: "Z633",
            descricao: "Ausência de um dos membros da família",
            favorito: false
        },
        {
            cid10: "Z634",
            descricao: "Desaparecimento ou falecimento de um membro da família",
            favorito: false
        },
        {
            cid10: "Z635",
            descricao: "Rompimento da família por separação ou divórcio",
            favorito: false
        },
        {
            cid10: "Z636",
            descricao: "Parente dependente de cuidados, residente no domicílio",
            favorito: false
        },
        {
            cid10: "Z637",
            descricao: "Outros acontecimentos difíceis com incidência na família ou no lar",
            favorito: false
        },
        {
            cid10: "Z638",
            descricao: "Outros problemas especificados relacionados com o grupo primário de suporte",
            favorito: false
        },
        {
            cid10: "Z639",
            descricao: "Problema não especificado relacionado com o grupo primário de suporte",
            favorito: false
        },
        {
            cid10: "Z640",
            descricao: "Problemas relacionados com uma gravidez não desejada",
            favorito: false
        },
        {
            cid10: "Z641",
            descricao: "Problemas relacionados com multiparidade",
            favorito: false
        },
        {
            cid10: "Z642",
            descricao: "Procura e aceitação de intervenções físicas, nutricionais, ou químicas conhecidas como perigosas ou nocivas",
            favorito: false
        },
        {
            cid10: "Z643",
            descricao: "Procura e aceitação de intervenções comportamentais ou psicológicas conhecidas como perigosas ou nocivas",
            favorito: false
        },
        {
            cid10: "Z644",
            descricao: "Desacordo com conselheiros",
            favorito: false
        },
        {
            cid10: "Z650",
            descricao: "Condenação, sem prisão, por tribunal civil ou criminal",
            favorito: false
        },
        {
            cid10: "Z651",
            descricao: "Prisão ou encarceramento",
            favorito: false
        },
        {
            cid10: "Z652",
            descricao: "Problemas ligados à libertação de prisão",
            favorito: false
        },
        {
            cid10: "Z653",
            descricao: "Problemas ligados à outras circunstâncias legais",
            favorito: false
        },
        {
            cid10: "Z654",
            descricao: "Vítima de crime ou de atos terroristas",
            favorito: false
        },
        {
            cid10: "Z655",
            descricao: "Exposição a catástrofe, guerra e outras hostilidades",
            favorito: false
        },
        {
            cid10: "Z658",
            descricao: "Outros problemas especificados relacionados com circunstâncias psicossociais",
            favorito: false
        },
        {
            cid10: "Z659",
            descricao: "Problemas relacionados com circunstâncias psicossociais não especificadas",
            favorito: false
        },
        {
            cid10: "Z700",
            descricao: "Acompanhamento relativo às atitudes em matéria de sexualidade",
            favorito: false
        },
        {
            cid10: "Z701",
            descricao: "Aconselhamento relativo ao comportamento e à orientação sexual do sujeito",
            favorito: false
        },
        {
            cid10: "Z702",
            descricao: "Aconselhamento relativo ao comportamento e à orientação sexual de um terceiro",
            favorito: false
        },
        {
            cid10: "Z703",
            descricao: "Aconselhamento relativo a preocupações associadas relacionadas com as atitudes, o comportamento e orientação em matéria de sexualidade",
            favorito: false
        },
        {
            cid10: "Z708",
            descricao: "Outros aconselhamentos em matéria de sexualidade",
            favorito: false
        },
        {
            cid10: "Z709",
            descricao: "Aconselhamento não especificado em matéria de sexualidade",
            favorito: false
        },
        {
            cid10: "Z710",
            descricao: "Pessoa que consulta no interesse de um terceiro",
            favorito: false
        },
        {
            cid10: "Z711",
            descricao: "Pessoa com medo de uma queixa para a qual não foi feito diagnóstico",
            favorito: false
        },
        {
            cid10: "Z712",
            descricao: "Pessoa que consulta para explicação de achados de exame",
            favorito: false
        },
        {
            cid10: "Z713",
            descricao: "Aconselhamento e supervisão dietéticos",
            favorito: false
        },
        {
            cid10: "Z714",
            descricao: "Aconselhamento e supervisão para abuso de álcool",
            favorito: false
        },
        {
            cid10: "Z715",
            descricao: "Aconselhamento e supervisão para abuso de drogas",
            favorito: false
        },
        {
            cid10: "Z716",
            descricao: "Aconselhamento para o abuso de fumo",
            favorito: false
        },
        {
            cid10: "Z717",
            descricao: "Aconselhamento a propósito do vírus da imunodeficiência humana [HIV]",
            favorito: false
        },
        {
            cid10: "Z718",
            descricao: "Outros aconselhamentos especificados",
            favorito: false
        },
        {
            cid10: "Z719",
            descricao: "Aconselhamento não especificado",
            favorito: false
        },
        {
            cid10: "Z720",
            descricao: "Uso do tabaco",
            favorito: false
        },
        {
            cid10: "Z721",
            descricao: "Uso de álcool",
            favorito: false
        },
        {
            cid10: "Z722",
            descricao: "Uso de droga",
            favorito: false
        },
        {
            cid10: "Z723",
            descricao: "Falta de exercício físico",
            favorito: false
        },
        {
            cid10: "Z724",
            descricao: "Regime e hábitos alimentares inadequados",
            favorito: false
        },
        {
            cid10: "Z725",
            descricao: "Comportamento sexual de alto risco",
            favorito: false
        },
        {
            cid10: "Z726",
            descricao: "Mania de jogo e apostas",
            favorito: false
        },
        {
            cid10: "Z728",
            descricao: "Outros problemas relacionados com o estilo de vida",
            favorito: false
        },
        {
            cid10: "Z729",
            descricao: "Problema não especificado relacionado com o estilo de vida",
            favorito: false
        },
        {
            cid10: "Z730",
            descricao: "Esgotamento",
            favorito: false
        },
        {
            cid10: "Z731",
            descricao: "Acentuação de traços de personalidade",
            favorito: false
        },
        {
            cid10: "Z732",
            descricao: "Falta de repouso e de lazer",
            favorito: false
        },
        {
            cid10: "Z733",
            descricao: "Stress não classificado em outra parte",
            favorito: false
        },
        {
            cid10: "Z734",
            descricao: "Habilidades sociais inadequadas não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "Z735",
            descricao: "Conflito sobre o papel social, não classificado em outra parte",
            favorito: false
        },
        {
            cid10: "Z736",
            descricao: "Limites impostos às atividades por invalidez",
            favorito: false
        },
        {
            cid10: "Z738",
            descricao: "Outros problemas relacionados com a organização do seu modo de vida",
            favorito: false
        },
        {
            cid10: "Z739",
            descricao: "Problema relacionado com a organização de seu modo de vida não especificado",
            favorito: false
        },
        {
            cid10: "Z740",
            descricao: "Mobilidade reduzida",
            favorito: false
        },
        {
            cid10: "Z741",
            descricao: "Necessidade de assistência com cuidados pessoais",
            favorito: false
        },
        {
            cid10: "Z742",
            descricao: "Necessidade de assistência a domicílio, sendo que nenhuma pessoa do lar é capaz de assegurar os cuidados",
            favorito: false
        },
        {
            cid10: "Z743",
            descricao: "Necessidade de supervisão contínua",
            favorito: false
        },
        {
            cid10: "Z748",
            descricao: "Outros problemas relacionados com a dependência de pessoa que oferece cuidados de saúde",
            favorito: false
        },
        {
            cid10: "Z749",
            descricao: "Problema não especificado relacionado com a dependência de pessoa que oferece cuidados de saúde",
            favorito: false
        },
        {
            cid10: "Z750",
            descricao: "Falta de disponibilidade de serviços médicos no domicílio",
            favorito: false
        },
        {
            cid10: "Z751",
            descricao: "Pessoa esperando ser admitida em estabelecimento médico adequado em outra parte",
            favorito: false
        },
        {
            cid10: "Z752",
            descricao: "Outro período de espera para investigação e tratamento",
            favorito: false
        },
        {
            cid10: "Z753",
            descricao: "Facilidades de saúde não disponíveis ou não acessíveis",
            favorito: false
        },
        {
            cid10: "Z754",
            descricao: "Outras organizações de cuidados não disponíveis ou não acessíveis",
            favorito: false
        },
        {
            cid10: "Z755",
            descricao: "Cuidados dispensados para ajudar às famílias durante as férias",
            favorito: false
        },
        {
            cid10: "Z758",
            descricao: "Outras dificuldades especificadas relacionadas com facilidades médicas e com outros cuidados de saúde",
            favorito: false
        },
        {
            cid10: "Z759",
            descricao: "Problema não especificado relacionado com facilidades médicas e com outros cuidados de saúde",
            favorito: false
        },
        {
            cid10: "Z760",
            descricao: "Emissão de prescrição de repetição",
            favorito: false
        },
        {
            cid10: "Z761",
            descricao: "Supervisão e cuidado de saúde de crianças assistidas",
            favorito: false
        },
        {
            cid10: "Z762",
            descricao: "Supervisão de cuidado de saúde de outras crianças ou recém-nascidos sadios",
            favorito: false
        },
        {
            cid10: "Z763",
            descricao: "Pessoa em boa saúde acompanhando pessoa doente",
            favorito: false
        },
        {
            cid10: "Z764",
            descricao: "Outros pensionistas em estabelecimentos de cuidados de saúde",
            favorito: false
        },
        {
            cid10: "Z765",
            descricao: "Pessoa fingindo ser doente [simulação consciente]",
            favorito: false
        },
        {
            cid10: "Z768",
            descricao: "Contatos com serviços de saúde por outras circunstâncias especificadas",
            favorito: false
        },
        {
            cid10: "Z769",
            descricao: "Pessoa em contato com serviços de saúde em circunstâncias não especificadas",
            favorito: false
        },
        {
            cid10: "Z800",
            descricao: "História familiar de neoplasia maligna de órgãos digestivos",
            favorito: false
        },
        {
            cid10: "Z801",
            descricao: "História familiar de neoplasia maligna de traquéia, brônquio e pulmão",
            favorito: false
        },
        {
            cid10: "Z802",
            descricao: "História familiar de neoplasia maligna de outros órgãos respiratórios e intratorácicos",
            favorito: false
        },
        {
            cid10: "Z803",
            descricao: "História familiar de neoplasia de mama",
            favorito: false
        },
        {
            cid10: "Z804",
            descricao: "História familiar de neoplasia maligna de órgãos genitais",
            favorito: false
        },
        {
            cid10: "Z805",
            descricao: "História familiar de neoplasia maligna do trato urinário",
            favorito: false
        },
        {
            cid10: "Z806",
            descricao: "História familiar de leucemia",
            favorito: false
        },
        {
            cid10: "Z807",
            descricao: "História familiar de outras neoplasias malignas dos tecidos linfático, hematopoético e correlacionados",
            favorito: false
        },
        {
            cid10: "Z808",
            descricao: "História familiar de neoplasia maligna de outros órgãos ou aparelhos",
            favorito: false
        },
        {
            cid10: "Z809",
            descricao: "História familiar de neoplasia maligna não especificada",
            favorito: false
        },
        {
            cid10: "Z810",
            descricao: "História familiar de retardo mental",
            favorito: false
        },
        {
            cid10: "Z811",
            descricao: "História familiar de abuso de álcool",
            favorito: false
        },
        {
            cid10: "Z812",
            descricao: "História familiar de abuso de fumo",
            favorito: false
        },
        {
            cid10: "Z813",
            descricao: "História familiar de abuso de outra substância psicoativa",
            favorito: false
        },
        {
            cid10: "Z814",
            descricao: "História familiar de abuso de outra substância",
            favorito: false
        },
        {
            cid10: "Z818",
            descricao: "História familiar de outros transtornos mentais e comportamentais",
            favorito: false
        },
        {
            cid10: "Z820",
            descricao: "História familiar de epilepsia e de outras doenças do sistema nervoso",
            favorito: false
        },
        {
            cid10: "Z821",
            descricao: "História familiar de cegueira e perda de visão",
            favorito: false
        },
        {
            cid10: "Z822",
            descricao: "História familiar de surdez e perda de audição",
            favorito: false
        },
        {
            cid10: "Z823",
            descricao: "História familiar de acidente vascular cerebral",
            favorito: false
        },
        {
            cid10: "Z824",
            descricao: "História familiar de doença isquêmica do coração e outras doenças do aparelho circulatório",
            favorito: false
        },
        {
            cid10: "Z825",
            descricao: "História familiar de asma e outras doenças respiratórias inferiores crônicas",
            favorito: false
        },
        {
            cid10: "Z826",
            descricao: "História familiar de artrite e outras doenças do sistema osteomuscular e tecido conjuntivo",
            favorito: false
        },
        {
            cid10: "Z827",
            descricao: "História familiar de malformações e deformações congênitas e anomalias cromossômicas",
            favorito: false
        },
        {
            cid10: "Z828",
            descricao: "História familiar de outras incapacidades e doenças crônicas que conduzem a incapacitação, não classificadas em outra parte",
            favorito: false
        },
        {
            cid10: "Z830",
            descricao: "História familiar de doença pelo vírus da imunodeficiência humana [HIV]",
            favorito: false
        },
        {
            cid10: "Z831",
            descricao: "História familiar de outras doenças infecciosas e parasitárias",
            favorito: false
        },
        {
            cid10: "Z832",
            descricao: "História familiar de doenças do sangue e dos órgãos hematopoéticos e alguns transtornos imunitários",
            favorito: false
        },
        {
            cid10: "Z833",
            descricao: "História familiar de diabetes mellitus",
            favorito: false
        },
        {
            cid10: "Z834",
            descricao: "História familiar de outras doenças endócrinas, nutricionais e metabólicas",
            favorito: false
        },
        {
            cid10: "Z835",
            descricao: "História familiar de outros transtornos dos olhos e dos ouvidos",
            favorito: false
        },
        {
            cid10: "Z836",
            descricao: "História familiar de doenças do aparelho respiratório",
            favorito: false
        },
        {
            cid10: "Z837",
            descricao: "História familiar das doenças do aparelho digestivo",
            favorito: false
        },
        {
            cid10: "Z840",
            descricao: "História familiar de doenças da pele e do tecido subcutâneo",
            favorito: false
        },
        {
            cid10: "Z841",
            descricao: "História familiar de transtornos do rim e ureter",
            favorito: false
        },
        {
            cid10: "Z842",
            descricao: "História familiar de outras doenças do aparelho geniturinário",
            favorito: false
        },
        {
            cid10: "Z843",
            descricao: "História familiar de consangüinidade",
            favorito: false
        },
        {
            cid10: "Z848",
            descricao: "História familiar de outras afecções especificadas",
            favorito: false
        },
        {
            cid10: "Z850",
            descricao: "História pessoal de neoplasia maligna de órgãos digestivos",
            favorito: false
        },
        {
            cid10: "Z851",
            descricao: "História pessoal de neoplasia maligna de traquéia, brônquio e pulmão",
            favorito: false
        },
        {
            cid10: "Z852",
            descricao: "História pessoal de neoplasia maligna de outros órgãos respiratórios e intratorácicos",
            favorito: false
        },
        {
            cid10: "Z853",
            descricao: "História pessoal de neoplasia maligna de mama",
            favorito: false
        },
        {
            cid10: "Z854",
            descricao: "História pessoal de neoplasia maligna de órgãos genitais",
            favorito: false
        },
        {
            cid10: "Z855",
            descricao: "História pessoal de neoplasia maligna de trato urinário",
            favorito: false
        },
        {
            cid10: "Z856",
            descricao: "História pessoal de leucemia",
            favorito: false
        },
        {
            cid10: "Z857",
            descricao: "História pessoal de outras neoplasias malignas do tecido linfático, hematopoético e tecidos correlatos",
            favorito: false
        },
        {
            cid10: "Z858",
            descricao: "História pessoal de neoplasias malignas de outros órgãos ou aparelhos",
            favorito: false
        },
        {
            cid10: "Z859",
            descricao: "História pessoal de neoplasia maligna não especificada",
            favorito: false
        },
        {
            cid10: "Z860",
            descricao: "História pessoal de outras neoplasias",
            favorito: false
        },
        {
            cid10: "Z861",
            descricao: "História pessoal de doenças infecciosas e parasitárias",
            favorito: false
        },
        {
            cid10: "Z862",
            descricao: "História pessoal de doenças do sangue e dos órgãos hematopoéticos e alguns transtornos imunitários",
            favorito: false
        },
        {
            cid10: "Z863",
            descricao: "História pessoal de doenças endócrinas, nutricionais e metabólicas",
            favorito: false
        },
        {
            cid10: "Z864",
            descricao: "História pessoal de abuso de substâncias psicoativas",
            favorito: false
        },
        {
            cid10: "Z865",
            descricao: "História pessoal de outros transtornos mentais e comportamentais",
            favorito: false
        },
        {
            cid10: "Z866",
            descricao: "História pessoal de doenças do sistema nervoso e dos órgãos dos sentidos",
            favorito: false
        },
        {
            cid10: "Z867",
            descricao: "História pessoal de doenças do aparelho circulatório",
            favorito: false
        },
        {
            cid10: "Z870",
            descricao: "História pessoal de doenças do aparelho respiratório",
            favorito: false
        },
        {
            cid10: "Z871",
            descricao: "História pessoal de doenças do aparelho digestivo",
            favorito: false
        },
        {
            cid10: "Z872",
            descricao: "História pessoal de doenças da pele e tecido subcutâneo",
            favorito: false
        },
        {
            cid10: "Z873",
            descricao: "História pessoal de doenças do sistema osteomuscular e tecido conjuntivo",
            favorito: false
        },
        {
            cid10: "Z874",
            descricao: "História pessoal de doenças do aparelho geniturinário",
            favorito: false
        },
        {
            cid10: "Z875",
            descricao: "História pessoal de complicações da gravidez, parto e puerpério",
            favorito: false
        },
        {
            cid10: "Z876",
            descricao: "História pessoal de algumas afecções originadas no período perinatal",
            favorito: false
        },
        {
            cid10: "Z877",
            descricao: "História pessoal de malformações e deformidades congênitas e anomalias cromossômicas",
            favorito: false
        },
        {
            cid10: "Z878",
            descricao: "História pessoal de outras afecções especificadas",
            favorito: false
        },
        {
            cid10: "Z880",
            descricao: "História pessoal de alergia à penicilina",
            favorito: false
        },
        {
            cid10: "Z881",
            descricao: "História pessoal de alergia a outro agente antibiótico",
            favorito: false
        },
        {
            cid10: "Z882",
            descricao: "História pessoal de alergia às sulfonamidas",
            favorito: false
        },
        {
            cid10: "Z883",
            descricao: "História pessoal de alergia a outros agentes antiinfecciosos",
            favorito: false
        },
        {
            cid10: "Z884",
            descricao: "História pessoal de alergia a agente anestésico",
            favorito: false
        },
        {
            cid10: "Z885",
            descricao: "História pessoal de alergia a agente narcótico",
            favorito: false
        },
        {
            cid10: "Z886",
            descricao: "História pessoal de alergia a agente analgésico",
            favorito: false
        },
        {
            cid10: "Z887",
            descricao: "História pessoal de alergia a soro e à vacina",
            favorito: false
        },
        {
            cid10: "Z888",
            descricao: "História pessoal de alergia a outros drogas, medicamentos e substâncias biológicas",
            favorito: false
        },
        {
            cid10: "Z889",
            descricao: "História pessoal de alergia a drogas, medicamentos e substâncias biológicas não especificadas",
            favorito: false
        },
        {
            cid10: "Z890",
            descricao: "Ausência adquirida de dedo(s) da mão [inclusive polegar] unilateral",
            favorito: false
        },
        {
            cid10: "Z891",
            descricao: "Ausência adquirida de mão e punho",
            favorito: false
        },
        {
            cid10: "Z892",
            descricao: "Ausência adquirida de braço acima do punho",
            favorito: false
        },
        {
            cid10: "Z893",
            descricao: "Ausência adquirida de ambos membros superiores [qualquer nível]",
            favorito: false
        },
        {
            cid10: "Z894",
            descricao: "Ausência adquirida de pé e tornozelo",
            favorito: false
        },
        {
            cid10: "Z895",
            descricao: "Ausência adquirida da perna ao nível ou abaixo do joelho",
            favorito: false
        },
        {
            cid10: "Z896",
            descricao: "Ausência adquirida da perna acima do joelho",
            favorito: false
        },
        {
            cid10: "Z897",
            descricao: "Ausência adquirida de ambos membros inferiores [qualquer nível, exceto somente artelhos]",
            favorito: false
        },
        {
            cid10: "Z898",
            descricao: "Ausência adquirida dos membros superiores e inferiores [qualquer nível]",
            favorito: false
        },
        {
            cid10: "Z899",
            descricao: "Ausência adquirida de membro não especificado",
            favorito: false
        },
        {
            cid10: "Z900",
            descricao: "Ausência adquirida de parte da cabeça e do pescoço",
            favorito: false
        },
        {
            cid10: "Z901",
            descricao: "Ausência adquirida da(s) mama(s)",
            favorito: false
        },
        {
            cid10: "Z902",
            descricao: "Ausência adquirida [de parte] do pulmão",
            favorito: false
        },
        {
            cid10: "Z903",
            descricao: "Ausência adquirida de parte do estômago",
            favorito: false
        },
        {
            cid10: "Z904",
            descricao: "Ausência adquirida de outras partes do trato digestivo",
            favorito: false
        },
        {
            cid10: "Z905",
            descricao: "Ausência adquirida do rim",
            favorito: false
        },
        {
            cid10: "Z906",
            descricao: "Ausência adquirida de outras partes do trato urinário",
            favorito: false
        },
        {
            cid10: "Z907",
            descricao: "Ausência adquirida de órgão(s) genital(is)",
            favorito: false
        },
        {
            cid10: "Z908",
            descricao: "Ausência adquirida de outros órgãos",
            favorito: false
        },
        {
            cid10: "Z910",
            descricao: "História pessoal de alergia exceto a drogas e a substâncias biológicas",
            favorito: false
        },
        {
            cid10: "Z911",
            descricao: "História pessoal de não aderência a tratamento ou regime médico",
            favorito: false
        },
        {
            cid10: "Z912",
            descricao: "História pessoal de má higiene pessoal",
            favorito: false
        },
        {
            cid10: "Z913",
            descricao: "História pessoal de ciclo vigília-sono anormal",
            favorito: false
        },
        {
            cid10: "Z914",
            descricao: "História pessoal de trauma psicológico não classificado em outra parte",
            favorito: false
        },
        {
            cid10: "Z915",
            descricao: "História pessoal de auto agressão",
            favorito: false
        },
        {
            cid10: "Z916",
            descricao: "História pessoal de outros traumas físicos",
            favorito: false
        },
        {
            cid10: "Z918",
            descricao: "História pessoal de outros fatores de risco especificados não classificados em outra parte",
            favorito: false
        },
        {
            cid10: "Z920",
            descricao: "História pessoal de anticoncepção",
            favorito: false
        },
        {
            cid10: "Z921",
            descricao: "História pessoal de uso de longo prazo (atual) de anticoagulantes",
            favorito: false
        },
        {
            cid10: "Z922",
            descricao: "História pessoal de uso de longo prazo (atual) de outros medicamentos",
            favorito: false
        },
        {
            cid10: "Z923",
            descricao: "História pessoal de irradiação",
            favorito: false
        },
        {
            cid10: "Z924",
            descricao: "História pessoal de cirurgia de grande porte não classificada em outra parte",
            favorito: false
        },
        {
            cid10: "Z925",
            descricao: "História pessoal de medidas de reabilitação",
            favorito: false
        },
        {
            cid10: "Z926",
            descricao: "História pessoal de quimioterapia para doença neoplásica",
            favorito: false
        },
        {
            cid10: "Z928",
            descricao: "História pessoal de outros tratamentos médicos",
            favorito: false
        },
        {
            cid10: "Z929",
            descricao: "História pessoal de tratamento médico não especificado",
            favorito: false
        },
        {
            cid10: "Z930",
            descricao: "Traqueostomia",
            favorito: false
        },
        {
            cid10: "Z931",
            descricao: "Gastrostomia",
            favorito: false
        },
        {
            cid10: "Z932",
            descricao: "Ileostomia",
            favorito: false
        },
        {
            cid10: "Z933",
            descricao: "Colostomia",
            favorito: false
        },
        {
            cid10: "Z934",
            descricao: "Outros orifícios artificiais do trato gastrointestinal",
            favorito: false
        },
        {
            cid10: "Z935",
            descricao: "Cistostomia",
            favorito: false
        },
        {
            cid10: "Z936",
            descricao: "Outros orifícios artificiais do aparelho urinário",
            favorito: false
        },
        {
            cid10: "Z938",
            descricao: "Outros orifícios artificiais",
            favorito: false
        },
        {
            cid10: "Z939",
            descricao: "Orifício artificial não especificado",
            favorito: false
        },
        {
            cid10: "Z940",
            descricao: "Rim transplantado",
            favorito: false
        },
        {
            cid10: "Z941",
            descricao: "Coração transplantado",
            favorito: false
        },
        {
            cid10: "Z942",
            descricao: "Pulmão transplantado",
            favorito: false
        },
        {
            cid10: "Z943",
            descricao: "Coração e pulmões transplantados",
            favorito: false
        },
        {
            cid10: "Z944",
            descricao: "Fígado transplantado",
            favorito: false
        },
        {
            cid10: "Z945",
            descricao: "Pele transplantada",
            favorito: false
        },
        {
            cid10: "Z946",
            descricao: "Osso transplantado",
            favorito: false
        },
        {
            cid10: "Z947",
            descricao: "Córnea transplantada",
            favorito: false
        },
        {
            cid10: "Z948",
            descricao: "Outros órgãos e tecidos transplantados",
            favorito: false
        },
        {
            cid10: "Z949",
            descricao: "Órgão e tecido não especificado transplantado",
            favorito: false
        },
        {
            cid10: "Z950",
            descricao: "Presença de marca-passo cardíaco",
            favorito: false
        },
        {
            cid10: "Z951",
            descricao: "Presença de enxerto de ponte [bypass] aortocoronária",
            favorito: false
        },
        {
            cid10: "Z952",
            descricao: "Presença de prótese de válvula cardíaca",
            favorito: false
        },
        {
            cid10: "Z953",
            descricao: "Presença de valva cardíaca xenógena",
            favorito: false
        },
        {
            cid10: "Z954",
            descricao: "Presença de outras substituições valvares cardíacas",
            favorito: false
        },
        {
            cid10: "Z955",
            descricao: "Presença de implante e enxerto de angioplastia coronária",
            favorito: false
        },
        {
            cid10: "Z958",
            descricao: "Presença de outros implantes e enxertos cardíacos e vasculares",
            favorito: false
        },
        {
            cid10: "Z959",
            descricao: "Presença de implante e enxerto cardíaco e vascular não especificado",
            favorito: false
        },
        {
            cid10: "Z960",
            descricao: "Presença de implantes urogenitais",
            favorito: false
        },
        {
            cid10: "Z961",
            descricao: "Presença de lente intra-ocular",
            favorito: false
        },
        {
            cid10: "Z962",
            descricao: "Presença de implante otológicos e audiológicos",
            favorito: false
        },
        {
            cid10: "Z963",
            descricao: "Presença de laringe artificial",
            favorito: false
        },
        {
            cid10: "Z964",
            descricao: "Presença de implantes endócrinos",
            favorito: false
        },
        {
            cid10: "Z965",
            descricao: "Presença de raiz dentária e implantes mandibulares",
            favorito: false
        },
        {
            cid10: "Z966",
            descricao: "Presença de implantes articulares ortopédicos",
            favorito: false
        },
        {
            cid10: "Z967",
            descricao: "Presença de outros implantes de osso e tendão",
            favorito: false
        },
        {
            cid10: "Z968",
            descricao: "Presença de outros implantes funcionais especificados",
            favorito: false
        },
        {
            cid10: "Z969",
            descricao: "Presença de implante funcional não especificado",
            favorito: false
        },
        {
            cid10: "Z970",
            descricao: "Presença de olho artificial",
            favorito: false
        },
        {
            cid10: "Z971",
            descricao: "Presença de membro artificial (completo) (parcial)",
            favorito: false
        },
        {
            cid10: "Z972",
            descricao: "Presença de dispositivo protético dentário (completo) (parcial)",
            favorito: false
        },
        {
            cid10: "Z973",
            descricao: "Presença de óculos e de lentes de contato",
            favorito: false
        },
        {
            cid10: "Z974",
            descricao: "Presença de aparelho externo de surdez",
            favorito: false
        },
        {
            cid10: "Z975",
            descricao: "Presença de dispositivo anticoncepcional intra-uterino [DIU]",
            favorito: false
        },
        {
            cid10: "Z978",
            descricao: "Presença de outros dispositivos especificados",
            favorito: false
        },
        {
            cid10: "Z980",
            descricao: "Derivação intestinal ou anastomose intestinal",
            favorito: false
        },
        {
            cid10: "Z981",
            descricao: "Artrodese",
            favorito: false
        },
        {
            cid10: "Z982",
            descricao: "Presença de dispositivo de drenagem do líquor",
            favorito: false
        },
        {
            cid10: "Z988",
            descricao: "Outros estados pós-cirúrgicos especificados",
            favorito: false
        },
        {
            cid10: "Z990",
            descricao: "Dependência de aspirador",
            favorito: false
        },
        {
            cid10: "Z991",
            descricao: "Dependência de respirador",
            favorito: false
        },
        {
            cid10: "Z992",
            descricao: "Dependência de diálise renal",
            favorito: false
        },
        {
            cid10: "Z993",
            descricao: "Dependência de cadeira de rodas",
            favorito: false
        },
        {
            cid10: "Z998",
            descricao: "Dependência de outras máquinas e aparelhos capacitantes",
            favorito: false
        },
        {
            cid10: "Z999",
            descricao: "Dependência de máquina e aparelho capacitante não especificado",
            favorito: false
        },
        {
            cid10: "U049",
            descricao: "Síndrome respiratória aguda grave [Severe acute respiratory syndrome) [SARS], não especificada",
            favorito: false
        },
        {
            cid10: "U800",
            descricao: "Agente resistente à penicilina",
            favorito: false
        },
        {
            cid10: "U801",
            descricao: "Agente resistente à meticilina",
            favorito: false
        },
        {
            cid10: "U808",
            descricao: "Agente resistente a outro antibiótico relacionado à penicilina",
            favorito: false
        },
        {
            cid10: "U810",
            descricao: "Agente resistente à vancomicina",
            favorito: false
        },
        {
            cid10: "U818",
            descricao: "Agente resistente a outros antibióticos relacionado à vancomicina",
            favorito: false
        },
        {
            cid10: "U88",
            descricao: "Agente resistente a múltiplos antibióticos",
            favorito: false
        },
        {
            cid10: "U898",
            descricao: "Agente resistente a outro antibiótico especificado único",
            favorito: false
        },
        {
            cid10: "U899",
            descricao: "Agente resistente a antibiótico não especificado",
            favorito: false
        },
        {
            cid10: "U99",
            descricao: "CID 10ª Revisão não disponível",
            favorito: false
        }

    ]

    return cids

}

export default Cid10