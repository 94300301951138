export default function InitialMedicamento() {

    const initial = {
        farmaco: '',
        orientacoes: '',
        abreviatura: '',
        lme: false,
        controlado: false,
        favorito: false,
        classe: '',
        nomescomerciais: [],
        apresentacoes: [],
        posologias: [],
    }

    return initial
}