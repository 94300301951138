import { Box, Grid, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { ClienteContext } from '../../../../../App';

const Linha4LME = () => {

    const { clienteContext } = useContext(ClienteContext)

    return <>
        <Box mt={1}>
            <Grid container spacing={1}>
                <Grid container item xs={10}  >
                    <Box width={1} border={1} borderColor="black">
                        <Grid container direction="column" justifyContent="flex-end" alignItems="stretch">
                            <Grid item>
                                <Box mt={-1} ml={2} display="flex" >
                                    <Typography component={'span'} variant="caption" noWrap={true} >
                                        <Box bgcolor="white" px={1}>4 - Nome da Mãe do Paciente</Box>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box ml={1}>
                                    <Typography component={'span'} variant={'h6'} align={'left'}>
                                        {clienteContext.mae ? <Box>{clienteContext.mae}</Box> : <Box style={{ color: "white" }}>-</Box>}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid container item xs={2}>
                    <Box width={1} border={1} borderColor="black">
                        <Grid container direction="column" justifyContent="center" alignItems="center">
                            <Grid item>
                                <Box mt={-1} display="flex">
                                    <Typography component={'span'} variant="caption" noWrap={true} >
                                        <Box bgcolor="white" px={1}>6 - Altura</Box>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box>
                                    <Typography component={'span'} variant={'h6'} align={'center'}>
                                        <Box>{clienteContext.altura && clienteContext.altura + " cm"}</Box>
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </>;
}

export default Linha4LME